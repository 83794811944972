import React, { Component } from 'react'
import { Form, Input, Row, Col, Card, Divider, AutoComplete  } from 'antd'
import Notes from './notes'
import './index.css'
import Http from '../../utils/http'
import moment from 'moment'
import { COOKIE, getCookie } from '../../utils/cookie'

const { Search } = Input

class NotesContainer extends Component {
  constructor() {
    super()
    this.state = {
      comments: [],
      submitting: false,
      value: '',
      like: 0,
      dislike: 0,
      action: null,
      search: '',
      setSearch: '',
      latestTimestamp: '',
      dataSource: [],
    }
  }

  search ()
  {
    this.setState({ setSearch: this.state.search.replace('-', '') })
  }
  setSearchValue (e){
    this.setState({ search: e.target.value },()=>{
      if( !e.target.value || e.target.value === null || e.target.value ==='' ){
        this.search()
      }
    })
  }

  setAutoSearchValue (e){
    this.setState({
      search: e,
      dataSource: []
    },()=>{
      this.search()
    })
  }

  handleSearch = async(value) => {
    let temp_timestamp = moment().valueOf()
    this.setState({ latestTimestamp: temp_timestamp }, async () => {
      if(value) {
        let options = { headers: { station: getCookie(COOKIE.STATION) } }
        value = value.replace('-', '')
        setTimeout(async () => {
          if(this.state.latestTimestamp === temp_timestamp) {
            await Http.get(`/notes_search?search=${value}`, options)
              .then((data) => {
                if(this.state.latestTimestamp === temp_timestamp) {
                  if (data && data?.status === 200) {
                    let set_data = data?.data?.data.map((item, index) => {
                      return {
                        ...item,
                        key: index
                      }
                    })
                    
                    this.setState({ dataSource: [] },()=>
                      this.setState({ dataSource: set_data })
                    )
                  } else{
                    this.setState({ dataSource: [] })
                  }
                }
              })
          }
        }, 500)
      } else {
        this.setState({ dataSource: [] })
      }
    })

  }

  render() {
    const { dataSource } = this.state
    return (
      <>
        <Row gutter={[{
          xs: 0,
          sm: 12,
          md: 24,
          lg: 24
        }, {
          xs: 0,
          sm: 0,
          md: 0,
          lg: 0
        },]}
        >
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card notes-card'>
              <Row gutter={[{
                xs: 12,
                sm: 12,
                md: 24,
                lg: 24
              }, {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0
              },]}
              >
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className='px-2'>
                    <Form.Item label='Enter Flight' className='mb-0'>
                      {/* <Search allowClear className='search-input' placeholder='Search' style={{ width: 250 }}
                        value={this.state.search}
                        onChange={(e) => this.setSearchValue(e)}
                        onSearch={() => this.search()}
                      /> */}
                      <AutoComplete
                        options={dataSource}
                        style={{ width: 'clamp(200px, 30%, 350px)' }}
                        onSelect={(e) => this.setAutoSearchValue(e)}
                        onSearch={this.handleSearch}
                      >
                        <Search allowClear className='search-input pl-2' placeholder='Eg: DL123/01NOV22'
                          value={this.state.search}
                          onChange={(e) => this.setSearchValue(e)}
                          onSearch={() => this.search()}
                        />
                      </AutoComplete>
                    </Form.Item>
                  </div>
                </Col>
                <Divider />
                <Col
                  xs={{
                    span: 22,
                    offset: 1
                  }}
                  md={{
                    span: 22,
                    offset: 1
                  }}
                  lg={{
                    span: 18,
                    offset: 3
                  }}>
                  <Notes search={this.state.setSearch} className='notes-section' />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}


export default NotesContainer
