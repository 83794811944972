import React, { Component } from 'react'
import {
  Row,
  Col,
  Form,
  Select,
  Typography,
  Card,
  Table,
  Tooltip,
  Popconfirm,
  Badge,
  Button,
  Modal,
  Spin,
  notification,
  //Statistic,
} from 'antd'
import documentIcon from '../../../assets/images/document.svg'
import markcompletedIcon from '../../../assets/images/right_arrow.svg'
import RestoreIcon from '../../../assets/images/restore.svg'
import DeleteIcon from '../../../assets/images/delete.svg'
import cssconstants from '../../../constants/cssconstants'
import constants, { pages as PAGE, rebookingQueueTabs, timezone } from '../../../constants/constants'
import { weightCalculator, formatWeight } from '../../../utils/common'
import { CountUp, CountDown } from '../../../utils/timer'
import Http from '../../../utils/http'
import Notes from '../../notes/notes'
import moment from 'moment'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'
import Cookies from 'universal-cookie'
import { cloneDeep } from 'lodash'

const { Option } = Select
const { Title } = Typography
let dateFormat = 'DDMMMYY HH:mm'
let dateFormatOnly = 'YYYY-MM-DD'

//FORMAT CURRENT BOOKING FIELD
function formatBookingInfo(segmentIn, segmentOut) {
  if(segmentIn && Object.keys(segmentIn)?.length === 0) {
    segmentIn = null
  }
  if(segmentOut && Object.keys(segmentOut)?.length === 0) {
    segmentOut = null
  }
  let UTC = '', return_string = '', segInDate = '', segOutDate = '', segInDate2 = '', segOutDate2 = '', segInDept = [], segInArr = [], segOutDept = [], segOutArr = []
  if(segmentIn) {
    let segIn = moment(segmentIn?.flight_date).format(dateFormatOnly)
    let segIn2 = moment(segmentIn?.flight_date).format(dateFormatOnly)
    if(segmentIn?.dept_time) {
      segInDept = segmentIn.dept_time.match(/.{1,2}/g)
      segIn = moment(`${segIn}+' '+${segInDept[0]}+':'+${segInDept[1]}`, 'YYYY-MM-DD HH:mm')
    }
    if(segmentIn?.arr_time) {
      segInArr = segmentIn.arr_time.match(/.{1,2}/g)
      segIn2 = moment(`${segIn2}+' '+${segInArr[0]}+':'+${segInArr[1]}`, 'YYYY-MM-DD HH:mm')
    }
    segInDate = moment(segIn).format(dateFormat)
    segInDate2 = moment(segIn2).format('HH:mm')
    return_string = return_string +
    `${segmentIn?.flight_no}/${segInDate} ${UTC} ${segInDate2} ${UTC} ${segmentIn?.org}-${segmentIn?.dest}`
  }
  if(segmentOut) {
    let segOut = moment(segmentOut?.flight_date).format(dateFormatOnly)
    let segOut2 = moment(segmentOut?.flight_date).format(dateFormatOnly)
    if(segmentOut?.dept_time) {
      segOutDept = segmentOut.dept_time.match(/.{1,2}/g)
      segOut = moment(`${segOut}+' '+${segOutDept[0]}+':'+${segOutDept[1]}`, 'YYYY-MM-DD HH:mm')
    }
    if(segmentOut?.arr_time) {
      segOutArr = segmentOut.arr_time.match(/.{1,2}/g)
      segOut2 = moment(`${segOut2}+' '+${segOutArr[0]}+':'+${segOutArr[1]}`, 'YYYY-MM-DD HH:mm')
    }
    segOutDate = moment(segOut).format(dateFormat)
    segOutDate2 = moment(segOut2).format('HH:mm')
    return_string = return_string +
    `${segmentOut?.flight_no}/${segOutDate} ${UTC} ${segOutDate2} ${UTC} ${segmentOut?.org}-${segmentOut?.dest}`
  }
  return return_string
}
//FORMAT CURRENT BOOKING FIELD
function formatPrevNewBooking(segmentIn) {
  if(segmentIn && segmentIn === {}) {
    segmentIn = null
  }
  let UTC = '', return_string = ''
  if(segmentIn){
    let segInDate = '', segInDate2 = '', segInDept = [], segInArr = []
    segmentIn?.sort(function(a, b){return a.seq-b.seq})
    segmentIn && segmentIn.map((segment) => {
      let segIn = moment(segment?.flight_date).format(dateFormatOnly),
        segIn2 = moment(segment?.flight_date).format(dateFormatOnly)
      if(segment?.dept_time) {
        segInDept = segment.dept_time.match(/.{1,2}/g)
        segIn = moment(`${segIn}+' '+${segInDept[0]}+':'+${segInDept[1]}`, 'YYYY-MM-DD HH:mm')
      }
      if(segment?.arr_time) {
        segInArr = segment.arr_time.match(/.{1,2}/g)
        segIn2 = moment(`${segIn2}+' '+${segInArr[0]}+':'+${segInArr[1]}`, 'YYYY-MM-DD HH:mm')
      }
      segInDate = moment(segIn).format(dateFormat)
      segInDate2 = moment(segIn2).format('HH:mm')

      return_string = return_string +
      `${segment?.flight_no}/${segInDate} ${UTC} ${segInDate2} ${UTC} ${segment?.org}-${segment?.dest} `
    })
  }
  return return_string
}
//FORMAT TTA FOR SUCCESSFUL REBOOKING
function formatElapsedTime(object, object_two) {
  let today = moment().utc(), input_date = '', dept_time = []
  if(object) {
    input_date = moment(object?.flight_date).format('YYYY-MM-DD')
    if(object.dept_time) {
      dept_time = object.dept_time.match(/.{1,2}/g)
      input_date = moment(`${input_date}+' '+${dept_time[0]}+':'+${dept_time[1]}`, 'YYYY-MM-DD HH:mm:ss')
    }
  } else if(object_two) {
    input_date = moment.utc(object_two)
  }

  if(input_date) {
    let time = Math.abs(moment(today).diff(input_date, 'minutes'))
    var hours = Math.floor(time / 60)
    var minutes = time % 60
    var seconds = minutes % 60
    //ADD EXACT HOURS AND MINUTES TO DATE OBJECT FOR COUNTDOWN
    today.add(hours, 'hours').add(minutes, 'minutes').add(seconds, 'seconds')
    let return_date = today.local().format('YYYY-MM-DD HH:mm:ss')
    return return_date
  } else return ''
}
function returnDel(object) {
  let value = moment(object?.flight_date).diff(moment(), 'minutes') < 0 ? '-' : ''
  return value
}
export default class RebookingTabs extends Component {
  constructor() {
    super()
    this.state = {
      weight_unit: 'KG',
      currentTab: rebookingQueueTabs.pending,
      loading: false,
      moveCompleted: false,
      record: null,
      searchString: '',
      showNotes: false,
      pendingData: [],
      completedData: [],
      successfulData: [],
      recoveryTeams: [],
      team: '',
      sort: {
        Pending: null,
        Completed: null,
        Successful: null,
      },
      station: new Cookies().get('station'),
      timezone: new Cookies().get('timezone'),
      user_id: new Cookies().get('id'),
      ext: timezone.find((item) => item.value === new Cookies().get('station'))?.ext || 'EST',
      isListening: false,
    }
    this._queue = []
    this._isBusy = false
  }
  //CDM
  componentDidMount() {
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=rb&stn=${this.state.station}`)
    // const events2 = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${this.state.station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        this.enqueue(myFakeAsyncJob)
      }
    }
    // events2.onmessage = (event) => {
    //   const parsedData = JSON.parse(event.data)
    //   if(!this.state.isListening) this.setState({ isListening: true })
    //   else {
    //     const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
    //     this.enqueue(myFakeAsyncJob)
    //   }
    // }
    this.setState({
      currentTab: this.props.prop,
      team: this.props.team,
    })
    this.fetchRecoveryTeams()
  }
  //CDU
  componentDidUpdate() {
    if(this.props?.team !== this.state.team) {
      this.setState({ team: this.props.team || '' }, () => this.fetchRebookingData(this.state.currentTab))
    }
  }
  eventList = (parsedData) => {
    if(parsedData?.TYPE === 'REBOOKING') {
      this.rebookingHandler(parsedData?.DETAILS, parsedData?.msg, parsedData?.Action)
    } else if(parsedData?.TYPE === 'RECOVERY_TEAM') {
      this.fetchRecoveryTeams('EVENT')
    }
  }

  /*************************************************************** */

  enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      this._queue.push({
        job,
        resolve,
        reject
      })
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    // if the array is empty shift() will return undefined
    if (next) {
      this._isBusy = true
      next
        .job()
        .then((value) => {
          this.eventList(value)
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 600)
      })
  }

  /*************************************************************** */
  //FETCH RECOVERY TEAMS
  fetchRecoveryTeams = async (event) => {
    if(!event) this.setState({ loading: true })
    let url = 'recovery_teams'
    let headers = { station: this.state.station }
    await Http.get(url, { headers }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        this.setState({ loading: false })
        let data = resp?.data?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ recoveryTeams: data })
      } else {
        this.setState({
          recoveryTeams: [],
          loading: false
        }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        })
      }
      if(!event) this.fetchRebookingData(this.props.prop)
      else {
        this.processData()
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ loading: false })
    })
  }

  rebookingHandler = (DETAILS, TYPE, ACTION) => {
    let { pendingData, completedData, successfulData, currentTab } = this.state
    if(TYPE === rebookingQueueTabs.pending.toUpperCase()) {
      if(ACTION === 'DELETE') {
        pendingData?.map((item, index) => {
          if(parseInt(item.id) === parseInt(DETAILS?.id)) {
            pendingData.splice(index, 1)
          }
        })
      } else {
        let flag = true
        pendingData?.map((item, index) => {
          if(parseInt(item.id) === parseInt(DETAILS?.data?.id)) {
            if(!this.state.team || (this.state.team && this.state.team === parseInt(DETAILS?.data?.recovery_action?.recovery_teams_id))) {
              pendingData[index] = this.updateIndividualRecord(DETAILS.data)
              flag = false
            } else if(this.state.team && this.state.team !== parseInt(DETAILS?.data?.recovery_action?.recovery_teams_id)) {
              pendingData?.splice(index, 1)
              flag = false
            }
          }
        })

        if(flag) {
          if(!this.state.team || (this.state.team && this.state.team === parseInt(DETAILS?.data?.recovery_action?.recovery_teams_id))) {
            pendingData.push(this.updateIndividualRecord(DETAILS.data))
          }
        }
      }
      this.sort(pendingData)
      this.setState({ pendingData: [] }, () => this.setState({ pendingData: pendingData }))
    }
    if(TYPE === rebookingQueueTabs.completed.toUpperCase()) {
      if(ACTION === 'DELETE') {
        completedData?.map((item, index) => {
          if(parseInt(item.id) === parseInt(DETAILS?.id)) {
            completedData.splice(index, 1)
          }
        })
      }
      this.sort(completedData)
      this.setState({ completedData: [] }, () => this.setState({ completedData: completedData }))
    }
    if(TYPE === rebookingQueueTabs.successful.toUpperCase()) {
      if(currentTab.toUpperCase() === rebookingQueueTabs.pending.toUpperCase() && DETAILS?.prev?.toUpperCase() === rebookingQueueTabs.pending.toUpperCase()) {
        pendingData?.map((item, index) => {
          if(parseInt(item.id) === parseInt(DETAILS?.data?.id)) {
            pendingData.splice(index, 1)
          }
        })
        this.sort(pendingData)
        this.setState({ pendingData: [] }, () => this.setState({ pendingData : pendingData }))
      }

      if(currentTab.toUpperCase() === rebookingQueueTabs.completed.toUpperCase() && DETAILS?.prev?.toUpperCase() === rebookingQueueTabs.completed.toUpperCase()) {
        completedData?.map((item, index) => {
          if(parseInt(item.id) === parseInt(DETAILS?.data?.id)) {
            completedData.splice(index, 1)
          }
        })
        this.sort(completedData)
        this.setState({ completedData: [] }, () => this.setState({ completedData : completedData }))
      }

      if(currentTab.toUpperCase() === rebookingQueueTabs.successful.toUpperCase()) {
        if(!this.state.team || (this.state.team && this.state.team === parseInt(DETAILS?.data?.recovery_action?.recovery_teams_id))) {
          successfulData.push(this.updateIndividualRecord(DETAILS.data))
          this.sort(successfulData)
          this.setState({ successfulData: [] }, () => this.setState({ successfulData : successfulData }))
        }
      }
    }
    if(TYPE === 'UPDATE') {
      //CURRENT SELECTED TAB IS PENDING && EVENT.PREV IS PENDING
      if(currentTab.toUpperCase() === rebookingQueueTabs.pending.toUpperCase() && DETAILS?.prev?.toUpperCase() === rebookingQueueTabs.pending.toUpperCase()) {
        pendingData?.map((item, index) => {
          if(parseInt(item.id) === parseInt(DETAILS?.data?.id)) {
            pendingData.splice(index, 1)
          }
        })
        this.sort(pendingData)
        this.setState({ pendingData: [] }, () => {
          this.setState({ pendingData : pendingData })
        })
      }
      if(currentTab.toUpperCase() === rebookingQueueTabs.completed.toUpperCase() && DETAILS?.cur?.toUpperCase() === rebookingQueueTabs.completed.toUpperCase()) {
        if(!this.state.team || (this.state.team && this.state.team === parseInt(DETAILS?.data?.recovery_action?.recovery_teams_id))) {
          completedData.push(this.updateIndividualRecord(DETAILS.data))
          this.sort(completedData)
          this.setState({ completedData: [] }, () => {
            this.setState({ completedData : completedData })
          })
        }
      }

      if(currentTab.toUpperCase() === rebookingQueueTabs.completed.toUpperCase() && DETAILS?.prev?.toUpperCase() === rebookingQueueTabs.completed.toUpperCase()) {
        completedData?.map((item, index) => {
          if(parseInt(item.id) === parseInt(DETAILS?.data?.id)) {
            completedData.splice(index, 1)
          }
        })
        this.sort(completedData)
        this.setState({ completedData: [] }, () => this.setState({ completedData : completedData }))
      }
      if(currentTab.toUpperCase() === rebookingQueueTabs.pending.toUpperCase() && DETAILS?.cur?.toUpperCase() === rebookingQueueTabs.pending.toUpperCase()) {
        if(!this.state.team || (this.state.team && this.state.team === parseInt(DETAILS?.data?.recovery_action?.recovery_teams_id))) {
          pendingData.push(this.updateIndividualRecord(DETAILS.data))
          this.sort(pendingData)
          this.setState({ pendingData: [] }, () => this.setState({ pendingData : pendingData }))
        }
      }
    }
    if(TYPE === 'DELETE') {
      if(currentTab.toUpperCase() === rebookingQueueTabs.successful.toUpperCase()) {
        successfulData?.map((item, index) => {
          if(parseInt(item.id) === parseInt(DETAILS?.id)) {
            successfulData.splice(index, 1)
          }
        })
        this.sort(successfulData)
        this.setState({ successfulData: [] }, () => this.setState({ successfulData : successfulData }))
      }
    }
  }

  /********************************************************************** */

  //FETCH DATA
  fetchRebookingData = async (type) => {
    this.setState({ loading: true })
    let url = `rebookings?type=${type}&team=${this.state.team}`
    let headers = { station: this.state.station }
    await Http.get(url, { headers }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        this.setState({ loading: false })
        //UPDATE DATA
        let data = resp.data.data.map((item, index) => {
          return {
            ...this.updateIndividualRecord(item),
            key: index
          }
        })
        this.sort(data)
        if(type === rebookingQueueTabs.pending) this.setState({ pendingData: [] }, () => this.setState({ pendingData : data }))
        else if(type === rebookingQueueTabs.completed) this.setState({ completedData: [] }, () => this.setState({ completedData : data }))
        else if(type === rebookingQueueTabs.successful) this.setState({ successfulData: [] }, () => this.setState({ successfulData : data }))
      } else {
        this.setState({
          pendingData: [],
          completedData: [],
          successfulData: [],
          loading: false
        }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        })
      }
    })
  }
  //PROCESS REBOOKING DATA
  processData = () => {
    let tab =
    this.state.currentTab === rebookingQueueTabs.pending ? 'pendingData' :
      this.state.currentTab === rebookingQueueTabs.completed ? 'completedData' :
        this.state.currentTab === rebookingQueueTabs.successful ? 'successfulData' : ''

    let rebookingData = cloneDeep(this.state[tab])
    if(rebookingData?.length > 0) {
      this.setState({ [tab]: [] }, () => this.setState({
        [tab]: rebookingData.map((item) => {
          return { ...this.updateIndividualRecord(item) }
        })
      }))
    }
  }
  //ADD INFOS OBJECT FOR SORTING
  updateIndividualRecord = (item) => {
    let type =
    this.state.currentTab === rebookingQueueTabs.pending ? 'rebook_req_datetime' :
      this.state.currentTab === rebookingQueueTabs.completed ? 'rebook_comp_datetime' :
        this.state.currentTab === rebookingQueueTabs.successful ? 'rebook_sucess_datetime' : ''

    if(item[type]) {
      item[type] = moment.utc(item[type]).format('YYYY-MM-DD HH:mm:ss')
    }

    item.rebook_req_datetime = item?.rebook_req_datetime ? moment.utc(item.rebook_req_datetime).format('YYYY-MM-DD HH:mm') : ''
    item.rebook_comp_datetime = item?.rebook_comp_datetime ? moment.utc(item.rebook_comp_datetime).format('YYYY-MM-DD HH:mm') : ''
    item.rebook_sucess_datetime = item?.rebook_sucess_datetime ? moment.utc(item.rebook_sucess_datetime).format('YYYY-MM-DD HH:mm') : ''

    item.sort_infos = {}
    item.sort_infos.sla_time = this.formatSlaTime(item) //DONE: sla_time
    item.sort_infos.prod_code = item?.goods_info?.awb?.prod_code //DONE: prod_code
    item.sort_infos.station = item?.station //DONE: station
    item.sort_infos.current_flight_departure = formatElapsedTime(item?.goods_info?.segs_out, null) //DONE: current_flight_departure
    item.sort_infos.time_of_request = item?.rebook_req_datetime ? item?.rebook_req_datetime : '' //TODO: time_of_request
    item.sort_infos.time_to_departure = formatElapsedTime(item?.goods_info?.segs_out, null) //DONE: time_to_departure
    item.sort_infos.time_since_completed = formatElapsedTime(null, item?.rebook_comp_datetime) //DONE: time_since_completed
    item.sort_infos[type] = item?.[type] //DONE: book_time
    if(item?.goods_info?.wgt.unit === 'LBS' || item?.goods_info?.wgt.unit === 'LBR') {
      item.weight_lbs = item.goods_info.wgt.value //ROUNDED TO NEAREST INTEGER
      item.weight_kg = weightCalculator(item.goods_info.wgt.value, 'LBS')
    } else if(item?.goods_info?.wgt.unit === 'KGM') {
      item.weight_kg = item.goods_info.wgt.value //ROUNDED TO NEAREST INTEGER
      item.weight_lbs = weightCalculator(item.goods_info.wgt.value, 'KGM')
    }
    return item
  }
  //UPDATE MODAL
  moveModal = () => {
    let { record, moveCompleted, currentTab } = this.state
    let type = record?.goods_info?.awb?.awb_no //TO BE AWB_NO NOT AWN_NO
    let move = currentTab === rebookingQueueTabs.pending ? 'to completed list' : currentTab === rebookingQueueTabs.completed ? 'back to rebooking queue?' : ''
    return(
      <Modal
        title= {false}
        visible={moveCompleted}
        onOk={this.notes}
        onCancel={() => this.setState({ moveCompleted: false })}
        footer={false}
        width={'40%'}
        centered
        className='custom-modal pb-0 notitle-modal'
      >
        <Row gutter={[{
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },
        {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} className='with-table'>
          <Col xs={24} sm={24} lg={24} xl={24} className='' >
            <p className='fs-18 text-center'>Move rebooking request for {type} {move}</p>
          </Col>
          <Col xs={24} className='f-spacebtw'>
            <Button className='custom-button' onClick={() => this.updateStatus(record, currentTab)}>Move</Button>
            <Button className='custom-button' onClick={() => this.setState({ moveCompleted: false })}>Cancel</Button>
          </Col>
        </Row>
      </Modal>
    )
  }
  //SHOW NOTES
  showNotes = (record) => {
    let note = record?.goods_info ? record.goods_info.awb_no : ''
    this.setState({
      searchString: note,
      showNotes: true
    })
  }
  //HIDE NOTES
  hideNotes = () => {
    this.setState({
      showNotes: false,
      searchString: ''
    })
  }
  //UPDATE REBOOKING STATUS
  updateStatus = async ({ id }, status) => {
    //let { currentTab, sort } = this.state
    if(status === rebookingQueueTabs.pending) status = rebookingQueueTabs.completed
    else if (status === rebookingQueueTabs.completed) status = rebookingQueueTabs.pending
    let formData = { status: status }
    let headers = {
      user_id: this.state.user_id,
      role: this.state.role,
      page_title: PAGE.rebooking_queue,
    }
    await Http.put(`/rebooking/${parseInt(id)}`, formData, { headers }) //DUMMY FOR NOW
      .then((data) => {
        if (data && data?.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
          this.setState({
            record: null,
            moveCompleted: false
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
  }
  //DELETE REBOOKING
  handleDelete = async ({ id }) => {
    let headers = {
      user_id: this.state.user_id,
      role: this.state.role,
      page_title: PAGE.rebooking_queue,
    }
    await Http.put(`/rebooking/${parseInt(id)}`, { is_delete: true }, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
          this.setState({
            record: null,
            moveCompleted: false
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
  }
  //SORTER
  sortGrid = (array, sort, isDate, type) => {
    if(!sort) this.fetchRebookingData(type)
    else {
      array && array.sort(function(a, b) {
        let objA = a.sort_infos
        let objB = b.sort_infos
        let keyA = isDate ? moment(objA[sort]) : objA[sort]
        let keyB = isDate ? moment(objB[sort]) : objB[sort]
        // Compare the fields
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
      })
      if(type === rebookingQueueTabs.pending) this.setState({ pendingData: [] }, () => this.setState({ pendingData: array }))
      else if(type === rebookingQueueTabs.completed) this.setState({ completedData: [] }, () => this.setState({ completedData: array }))
      else if(type === rebookingQueueTabs.successful) this.setState({ successfulData: [] }, () => this.setState({ successfulData: array }))
    }
  }
  sort = (array) => {
    let type =
    this.state.currentTab === rebookingQueueTabs.pending ? 'rebook_req_datetime' :
      this.state.currentTab === rebookingQueueTabs.completed ? 'rebook_comp_datetime' :
        this.state.currentTab === rebookingQueueTabs.successful ? 'rebook_sucess_datetime' : ''
    array && array.sort(function(a, b) {
      var keyA = moment(a[type]).valueOf()
      var keyB = moment(b[type]).valueOf()
      // Compare the fields
      if (keyA > keyB) return -1
      if (keyA < keyB) return 1
      return 0
    })
  }
  //FORMAT SLA TIME
  formatSlaTime = (record) => {
    let type =
    this.state.currentTab === rebookingQueueTabs.pending ? 'rebook_req_datetime' :
      this.state.currentTab === rebookingQueueTabs.completed ? 'rebook_comp_datetime' :
        this.state.currentTab === rebookingQueueTabs.successful ? 'rebook_sucess_datetime' : ''

    let temp = 'N/A', display = 'N/A'
    let date = moment(record[type]).format('YYYY-MM-DD HH:mm')
    if(!this.state.recoveryTeams) return 'N/A'
    else if(!record?.recovery_action) return 'N/A'
    else {
      let flag = false
      this.state.recoveryTeams.map(({ details }) => {
        if(flag === false && details.rebook_team === record?.recovery_action?.details?.q_to && details.q_priority === record?.recovery_action?.details?.q_priority) {
          flag = true
          temp = details.sla_time
          let hours = Math.floor(details.sla_time / 60)
          let minutes = details.sla_time % 60
          let date2 = moment(date).add(hours, 'hours').add(minutes, 'minutes')
          display = moment(moment.utc(date2.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm'))
        }
      })
    }
    this.statusIndicator(record, temp)
    return display
  }
  //STATUS INDICATOR LOGIC
  statusIndicator = (record, sla) => {
    let type =
    this.state.currentTab === rebookingQueueTabs.pending ? 'rebook_req_datetime' :
      this.state.currentTab === rebookingQueueTabs.completed ? 'rebook_comp_datetime' :
        this.state.currentTab === rebookingQueueTabs.successful ? 'rebook_sucess_datetime' : ''
    if(sla !== 'N/A') {
      let created_date = moment(record[type]).format('YYYY-MM-DD HH:mm')
      let action_time = moment(created_date).add(sla, 'minutes')
      let today = moment.utc(moment()).format('YYYY-MM-DD HH:mm')
      //GET DIFF IN MINUTES BETWEEN TWO DATES
      let action_time_minutes = action_time.diff(today, 'minutes')
      if(action_time_minutes > 10) record.indicator = 'success'
      else if(action_time_minutes < 10) record.indicator = 'error'
      else record.indicator = 'warning'
    } else record.indicator = 'error'
  }
  //FORMAT AWB NUMBER FIELD
  formatAWBNumber = (object) => {
    let goods_info = object?.goods_info
    let return_string = ''
    let weight = this.state.weight_unit === 'KG' ? object?.weight_kg ? formatWeight(object?.weight_kg) : object?.weight_lbs ? formatWeight(object?.weight_lbs) : 'N/A' : 'N/A'
    if(goods_info) {
      let awbNo = ''
      if(goods_info?.awb_no) {
        var b = '-'
        var position = 3
        awbNo = [goods_info.awb_no.slice(0, position), b, goods_info.awb_no.slice(position)].join('')
      }
      return_string = return_string + `${awbNo} | ${goods_info?.pcs} | ${weight+this.state.weight_unit} | ${goods_info.org}-${goods_info.dest}`
    }
    return return_string
  }

  render() {
    let { currentTab, moveCompleted, showNotes, loading, pendingData, completedData, successfulData, searchString, sort } = this.state

    const handlingcolumns = [
      {
        title: 'Date / Time: (L)',
        dataIndex: 'rebook_req_datetime',
        key: 'rebook_req_datetime',
        render: (_, record) => (
          <span>{record?.rebook_req_datetime ? moment.utc(record.rebook_req_datetime).tz(this.state.timezone).format(dateFormat) : 'N/A'}</span>
        ),
      },
      {
        title: 'Station',
        dataIndex: 'station',
        key: 'station',
        render: (_, record) => (
          <span>{record?.station ? record.station : 'N/A'}</span>
        ),
      },
      {
        title: 'Awb #',
        dataIndex: 'awb_no',
        key: 'awb_no',
        render: (_, record) => (
          <span>{this.formatAWBNumber(record)}</span>
        ),
      },
      {
        title: 'Product',
        dataIndex: 'product',
        key: 'product',
        render: (_, record) => (
          <span>{record?.goods_info?.awb?.prod_code ? record.goods_info.awb.prod_code : 'N/A'}</span>
        ),
      },
      {
        title: 'Specials',
        dataIndex: 'spl_code',
        key: 'spl_code',
        render: (_, record) => (
          <span>{record?.goods_info?.awb?.spl_code ? record.goods_info.awb.spl_code : 'N/A'}</span>
        ),
      },
      {
        title: `Current Booking (in ${this.state.ext})`,
        dataIndex: 'currentbooking',
        key: 'currentbooking',
        width: 300,
        render: (_, record) => (
          <span>{formatBookingInfo(record?.goods_info?.segs_in, record?.goods_info?.segs_out)}
          </span>
        ),
      },
      {
        title: 'Rebooking Reason',
        dataIndex: 'reason',
        key: 'reason',
        render: (_, record) => (
          <span>{record?.goods_info?.replacement_remark ? record?.goods_info?.replacement_remark || 'N/A' : record?.remark ? `${record.reason} - ${record.remark}`: record.reason || 'N/A'}</span>
        ),
      },
      {
        title: 'SLA',
        dataIndex: 'sla_time',
        key: 'sla_time',
        render: (_, record) => (
          <span>{record?.sort_infos?.sla_time ? <CountDown expiryTimestamp={record?.sort_infos?.sla_time} format='HH:mm' /> : 'N/A'}</span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        render: (_, record) => (
          <span>{<Badge status={record?.indicator} />}</span>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (_, record) => (
          <div className='action-column'>
            <Tooltip
              title='Move to Completed'
              overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
              onClick={() => {
                this.setState({
                  moveCompleted: true,
                  record: record
                })
              }}
            >
              <img src={markcompletedIcon} alt='Mark completed' />
            </Tooltip>
            <Tooltip
              title='Notes'
              overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
              onClick={() => this.showNotes(record)}
            >
              <img src={documentIcon} alt='' />
            </Tooltip>
            <Popconfirm
              placement='top'
              title={constants.DeleteText}
              okText='Yes'
              cancelText='No'
            >
              <Tooltip
                title='Delete'
                overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
              >
                {/* <img src={deleteIcon} alt='delete' /> */}
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      },
    ]
    const completedColumns = [
      {
        title: 'Date / Time: (L)',
        dataIndex: 'rebook_comp_datetime',
        key: 'rebook_comp_datetime',
        render: (_, record) => (
          <span>{record?.rebook_comp_datetime ? moment.utc(record.rebook_comp_datetime).tz(this.state.timezone).format(dateFormat) : 'N/A'}</span>
        ),
      },
      {
        title: 'Station',
        dataIndex: 'station',
        key: 'station',
        render: (_, record) => (
          <span>{record?.station ? record.station : 'N/A'}</span>
        ),
      },
      {
        title: 'Awb #',
        dataIndex: 'awbno',
        key: 'awbno',
        render: (_, record) => (
          <span>{this.formatAWBNumber(record)}</span>
        ),
      },
      {
        title: 'Product',
        dataIndex: 'prod_code',
        key: 'prod_code',
        render: (_, record) => (
          <span>{record?.goods_info?.awb?.prod_code ? record.goods_info.awb.prod_code : 'N/A'}</span>
        ),
      },
      {
        title: 'Specials',
        dataIndex: 'spl_code',
        key: 'spl_code',
        render: (_, record) => (
          <span>{record?.goods_info?.awb?.spl_code ? record.goods_info.awb.spl_code : 'N/A'}</span>
        ),
      },
      {
        title: `Current Booking (in ${this.state.ext})`,
        dataIndex: 'currentbooking',
        key: 'currentbooking',
        width: 300,
        render: (_, record) => (
          <span>{formatBookingInfo(record?.goods_info?.segs_in, record?.goods_info?.segs_out)}
          </span>
        ),
      },
      {
        title: 'Rebooking Reason',
        dataIndex: 'reason',
        key: 'reason',
        render: (_, record) => (
          <span>{record?.goods_info?.replacement_remark ? record?.goods_info?.replacement_remark || 'N/A' : record.reason || 'N/A'}</span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'time',
        key: 'time',
        render: (_, record) => (
          <span className='countdown'>
            <span>Done </span>
            {record?.sort_infos?.time_since_completed ?
              <CountUp expiryTimestamp={moment(record?.sort_infos?.time_since_completed)} className='' /> : 'N/A'}
          </span>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (_, record) => (
          <div className='action-column'>
            <Tooltip
              title='Move to Pending'
              overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
              onClick={() => {
                this.setState({
                  moveCompleted: true,
                  record: record
                })
              }}
            >
              <img src={RestoreIcon} alt='' />
            </Tooltip>
            <Tooltip
              title='Notes'
              overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
              onClick={() => this.showNotes(record)}
            >
              <img src={documentIcon} alt='' />
            </Tooltip>
          </div>
        ),
      },
    ]
    const successfulColumns = [
      {
        title: 'Date / Time: (L)',
        dataIndex: 'rebook_sucess_datetime',
        key: 'rebook_sucess_datetime',
        render: (_, record) => (
          <span>{record?.rebook_sucess_datetime ? moment.utc(record.rebook_sucess_datetime).tz(this.state.timezone).format(dateFormat) : 'N/A'}</span>
        ),
      },
      {
        title: 'Station',
        dataIndex: 'station',
        key: 'station',
        render: (_, record) => (
          <span>{record?.station ? record.station : 'N/A'}</span>
        ),
      },
      {
        title: 'Awb #',
        dataIndex: 'awbno',
        key: 'awbno',
        render: (_, record) => (
          <span>{this.formatAWBNumber(record)}</span>
        ),
      },
      {
        title: 'Product',
        dataIndex: 'prod_code',
        key: 'prod_code',
        render: (_, record) => (
          <span>{record?.goods_info?.awb?.prod_code ? record.goods_info.awb.prod_code : 'N/A'}</span>
        ),
      },
      {
        title: 'Specials',
        dataIndex: 'spl_code',
        key: 'spl_code',
        render: (_, record) => (
          <span>{record?.goods_info?.awb?.spl_code ? record.goods_info.awb.spl_code : 'N/A'}</span>
        ),
      },
      {
        title: `Previous Booking (in ${this.state.ext})`,
        dataIndex: 'previousbooking',
        key: 'previousbooking',
        width: 300,
        render: (_, record) => (
          <span>{record?.prev_booking ? formatPrevNewBooking(record?.prev_booking, null) : 'N/A'}</span>
        ),
      },
      {
        title: `New Booking (in ${this.state.ext})`,
        dataIndex: 'newbooking',
        key: 'newbooking',
        width: 300,
        render: (_, record) => (
          <span>{record?.goods_info?.awbsegments?.length > 0 ? formatPrevNewBooking(record?.goods_info?.awbsegments, null) : 'N/A'}</span>
        ),
      },
      {
        title: 'Will Depart In..',
        dataIndex: 'time',
        key: 'time',
        align: 'center',
        render: (_, record) => (
          <span>
            <span>{record?.goods_info?.segs_out ? returnDel(record?.goods_info?.segs_out) : ''}</span>
            <span className='countdown'>{record?.goods_info?.segs_out ? returnDel(record?.goods_info?.segs_out) === '' ?
              <CountDown expiryTimestamp={moment(formatElapsedTime(record?.goods_info?.segs_out, null))} className='' /> :
              <CountUp expiryTimestamp={moment(formatElapsedTime(record?.goods_info?.segs_out, null))} /> : 'N/A'}</span>
          </span>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (_, record) => (
          <div className='action-column'>
            <Tooltip
              title='Notes'
              overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
              onClick={() => this.showNotes(record)}
            >
              <img src={documentIcon} alt='' />
            </Tooltip>
            <Popconfirm
              placement='top'
              title={constants.DeleteText}
              okText='Yes'
              cancelText='No'
              onConfirm={() => this.handleDelete(record)}
            >
              <Tooltip
                title='Delete'
                overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
              >
                <img src={DeleteIcon} alt='delete' />
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      },
    ]

    return (
      <div className=''>
        {loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        {/* <Select allowClear className='rebooking-select' placeholder='Select' onChange={(e) => this.setState({ team: e == undefined ? '' : e }, () => this.fetchRebookingData(this.state.currentTab))}>
          {
            this.state.recoveryTeams?.map((item) => (
              <Option key={item?.id} value={item?.id}>{`${item?.details?.rebook_team} - ${item?.details?.q_priority}`}</Option>
            ))
          }
        </Select> */}
        {currentTab === rebookingQueueTabs.pending ? <Card className='custom-card'>
          <Row
            gutter={[
              {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
              },
              {
                xs: 24,
                sm: 24,
                md: 0,
                lg: 0,
              },
            ]}
            className='with-table'
          >
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className=''>
              <Title level={5} className='mb-0'>
                Rebooking Queue
              </Title>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={10} className=''>
              <p className='mb-0 grey-text text-center'>
                Total Rebooking Requests: <span className='green-text'>{pendingData.length}</span>
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={6}
              className='text-center'
            >
              <Form.Item label='Sort By'>
                <Select
                  allowClear
                  size='small'
                  placeholder='Sort By'
                  className='br-input'
                  onChange={(e, { type }) => {
                    sort[currentTab] = e
                    this.setState({ sort })
                    this.sortGrid(pendingData, e, type, rebookingQueueTabs.pending) //UPDATE SECOND PARAM BASED ON DATE COLUMN NAME
                  }}
                >
                  <Option key='1' value='sla_time' type=''>SLA Time</Option>
                  <Option key='2' value='prod_code' type=''>Product</Option>
                  <Option key='3' value='station' type=''>Requesting Station</Option>
                  <Option key='4' value='current_flight_departure' type='date'>Current Flight Departure</Option>
                  <Option key='5' value='time_of_request' type='date'>Time Of Request</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='table-overflow'
            >
              <div className='table-outer'>
                <Table
                  className='custom-table w-100 rebk-table'
                  dataSource={pendingData}
                  columns={handlingcolumns}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Card> : null}
        {currentTab === rebookingQueueTabs.completed ? <Card className='custom-card'>
          <Row
            gutter={[
              {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
              },
              {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
              },
            ]}
            className='with-table'
          >
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className=''>
              <Title level={5} className='mb-0'>
              Completed Rebooking Requests
              </Title>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={10} className=''>
              <p className='mb-0 grey-text text-center'>
              Total Completed  Requests: <span className='green-text'>{completedData.length}</span>
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={6}
              className='text-center'
            >
              <Form.Item label='Sort By' colon={true}>
                <Select
                  allowClear
                  size='small'
                  placeholder='Sort By'
                  className='br-input'
                  onChange={(e, { type }) => {
                    sort[currentTab] = e
                    this.setState({ sort })
                    this.sortGrid(completedData, e, type, rebookingQueueTabs.completed) //UPDATE SECOND PARAM BASED ON DATE COLUMN NAME
                  }}
                >
                  <Option key='1' value='time_since_completed' type='date'>Time Since Completed</Option>
                  <Option key='2' value='station' type=''>Requesting Station</Option>
                  <Option key='3' value='current_flight_departure' type='date'>Current Flight Departure</Option>
                  <Option key='4' value='time_of_request' type='date'>Time Of Request</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='table-overflow'
            >
              <div className='table-outer'>
                <Table
                  className='custom-table w-100 rebk-table'
                  dataSource={completedData}
                  columns={completedColumns}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Card> : null}
        {currentTab === rebookingQueueTabs.successful ? <Card className='custom-card'>
          <Row
            gutter={[
              {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
              },
              {
                xs: 0,
                sm: 0,
                md: 0,
                lg: 0,
              },
            ]}
            className='with-table'
          >
            <Col xs={24} sm={24} md={8} lg={8} xl={8} className=''>
              <Title level={5} className='mb-0'>
              Successful Rebookings
              </Title>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={10} className=''>
              <p className='mb-0 grey-text text-center'>
              Total Completed  Requests: <span className='green-text'>{successfulData.length}</span>
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={6}
              className='text-center'
            >
              <Form.Item label='Sort By'>
                <Select
                  allowClear
                  size='small'
                  placeholder='Sort By'
                  className='br-input'
                  onChange={(e, { type }) => {
                    sort[currentTab] = e
                    this.setState({ sort })
                    this.sortGrid(successfulData, e, type, rebookingQueueTabs.successful) //UPDATE SECOND PARAM BASED ON DATE COLUMN NAME
                  }}
                >
                  <Option key='1' value='time_to_departure' type='date'>Time To Departure</Option>
                  <Option key='2' value='prod_code' type=''>Product</Option>
                  <Option key='3' value='station' type=''>Requesting Station</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='table-overflow'
            >
              <div className='table-outer'>
                <Table
                  className='custom-table w-100 rebk-table'
                  dataSource={successfulData}
                  columns={successfulColumns}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Card> : null}
        <Modal
          title='Notes'
          visible={showNotes}
          onOk={this.notes}
          onCancel={this.hideNotes}
          footer={false}
          width={'80%'}
          centered
          className='custom-modal pb-0'
          destroyOnClose={true}
        >
          <Notes search={searchString} />
        </Modal>
        {moveCompleted ? this.moveModal() : null}
      </div>
    )
  }
}
