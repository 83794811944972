import React, { Component } from 'react'
import { Col, Row, Typography, Spin } from 'antd'
//import { COOKIE, getCookie, pageHandler } from '../../utils/cookie'
import Logo from '../../assets/images/logo.png'
import '../login/login.css'
import { COOKIE, deleteCookie } from '../../utils/cookie'

const { Title } = Typography
const RedirectingMsg = 'Redirecting to Delta single signon login page'

class Logout extends Component {
  state = { iscode: RedirectingMsg } 

  componentDidMount() {

  }
  onLoadHandler () {
    deleteCookie(COOKIE.OTP)
    deleteCookie(COOKIE.STATION)
    deleteCookie(COOKIE.TIMEZONE)
    deleteCookie(COOKIE.ROLE)
    deleteCookie(COOKIE.JOB_TITLE)
    deleteCookie(COOKIE.NAME)
    deleteCookie(COOKIE.USERNAME)
    deleteCookie(COOKIE.WEIGHT)
    deleteCookie(COOKIE.ID)
    deleteCookie(COOKIE.PPR)
    deleteCookie(COOKIE.EXPIRY)
    deleteCookie(COOKIE.LOGINTYPE)
    deleteCookie(COOKIE.LOGINPAGE)
    window.location.href = '/dashboard'
  }
  render() {
    return <div>
      <iframe src={'https://ssaasi.delta.com/idp/startSLO.ping'} style={{ display: 'none' }} width='0px' height={'0px'} onLoad={this.onLoadHandler} /> 
      <div className='login-container-outer text-center m-auto delta-login'>
        <Row className='login-container'>
          <Col md={24} lg={12} className='login-content'>
            <Title level={2} className='text-left login-heading'>Welcome</Title>
            <Title level={2} className='login-heading delta-to mt-0'>To</Title>
            <div>
              <div>
                <Title level={1} className='login-company-title text-center'>HUB CONTROL SYSTEM</Title>
                <Title level={5} className='login-company-subtitle text-right'>CARGO</Title>
              </div>
              <div className='divider my-4 w-100'></div>
              <Row className='w-100 fv-middle logo-wrap-gap'>
                <Col flex='100px'>
                  <img src={Logo} alt='Logo' className='login-logo' />
                </Col>
                <Col className='login-form-width'>
                  <Title level={4} className='text-left redirecting-subheading mb-0'><Spin />{this.state.iscode}</Title>
                </Col>
              </Row>
            </div>
          </Col>
          <Col flex='auto'lg={12}className='f-middle bck-img'>
          </Col>
        </Row>
      </div>
    </div>
  }

}
 
export default Logout