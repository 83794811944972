import React, { Component } from 'react'
import Http from '../../utils/http'
import { COOKIE, createCookie } from '../../utils/cookie'
import { notification } from 'antd'
import { timezones } from '../../constants/constants'
import moment from 'moment'

class Redirect extends Component {
  state = {  } 

  componentDidMount() {
    this.loginDash()
  }

  loginDash = async () => {
    let url = window.location.href
    let splitUrl = url.split('/')
    //get last url string
    let lastUrl = splitUrl[splitUrl.length - 1]
    let vararr = lastUrl.split('-')
    await Http.get(`/login_dash/${vararr[vararr.length - 1]}`)
      .then(async (resp) => {
        if (resp.status === 200) {
          let data = resp?.data?.data
          if(data?.role /*=== 'Warehouse TV'*/){
            let station = vararr[0]
            let ttl = 24 * 365
            let days = 365
            createCookie(COOKIE.OTP, this.state.otp, ttl,'/')
            createCookie(COOKIE.STATION, station, ttl,'/')
            createCookie(COOKIE.TIMEZONE, timezones[station], ttl,'/')
            createCookie(COOKIE.NAME, `${data?.name?.first_name} ${data?.name?.last_name}` , ttl,'/')
            createCookie(COOKIE.ROLE, data?.role, ttl,'/')
            createCookie(COOKIE.JOB_TITLE, data?.job_title, ttl,'/')
            createCookie(COOKIE.USERNAME, data?.username, ttl,'/')
            createCookie(COOKIE.ID, data?.id, ttl,'/')
            createCookie(COOKIE.WEIGHT, 'LB', ttl,'/')
            createCookie(COOKIE.EXPIRY, moment().add(days, 'days').format('YYYY-MM-DD HH:mm:ss'), ttl,'/')
            createCookie(COOKIE.PPR, data?.ppr, ttl,'/')
            createCookie(COOKIE.LOGINTYPE, 'DashTV', ttl,'/')
            createCookie(COOKIE.LOGINPAGE, vararr[1], ttl,'/')
            switch(vararr[1])
            {
            case 'BRKDWN_DSH':
              window.location.href = '/breakdown-dashboard'
              break
            case 'REC_DSH':
              window.location.href = '/recovery-dashboard'
              break
            case 'BLDUP_DSH':
              window.location.href = '/buildup-dashboard'
              break
            case 'HLT_DSH':
              window.location.href = '/haulout-dashboard'
              break
            }
          } else {
            notification.destroy()
            notification.error({
              message: 'Access Denied',
              description: data.data.message,
            })
            window.location.href = '/login'
          }
        }
      })
  }

  render() { 
    return (
      <></>
    )
  }
}
 
export default Redirect