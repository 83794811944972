import React, { Component } from 'react'
import { Col, Modal, Row } from 'antd'
import delta from '../../../../../assets/images/delta.png'
import deltaLogo from '../../../../../assets/images/DL-red.png'
import AcDetails from './acdetails'
import ContainerDetails from './containerdetails'
import AcType359 from './actype359'
import AcType764 from './actype764'
import AcType763 from './actype763'
import AcType333 from './actype333'
import AcType339 from './actype339'
import AcType332 from './actype332'
import http from '../../../../../utils/http'

class WeightAndBalance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      recordid: null,
      record: null,
      wgt_bal_status: null,
      aft_index: null,
      cargo_margin: null,
      ship: null,
      type: 'Domestic',
      pax: {
        c: 0,
        f: 0,
        w: 0,
        y: 0
      },
      fuel: 0,
      position: {
        pallet: {},
        cargo: {},
        bulk: 0
      },
      season: 'Summer',
      acdetails: {},
      ac_title: ''
    }
    this.acTypeText = ''
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    this.setState({
      recordid: this.props.editRecordId,
      record: this.props.editRecord,
    })
    if (this.props.editRecord?.wgt_bal_plan) {
      this.setState({
        wgt_bal_status: this.props.editRecord?.wgt_bal_status,
        aft_index: this.props.editRecord?.aft_index,
        ship: this.props.editRecord?.wgt_bal_plan?.ship,
        type: this.props.editRecord?.wgt_bal_plan?.type,
        pax: this.props.editRecord?.wgt_bal_plan?.pax,
        fuel: this.props.editRecord?.wgt_bal_plan?.fuel,
        season: this.props.editRecord?.wgt_bal_plan?.season,
        position: this.props.editRecord?.wgt_bal_plan?.position,
        cargo_margin: this.props.editRecord?.wgt_bal_plan?.cargo_margin
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.editRecordId !== prevProps.editRecordId) {
      this.setState({ recordid: this.props.editRecordId })
    }
    if (this.props.editRecord !== prevProps.editRecord) {
      this.setState({ position: this.props.editRecord?.wgt_bal_plan?.position })
    }
  }

  onSubmit = () => {
    http.post(`/wgt_bal_plan/${this.state.recordid}`, {
      ship: this.state.ship,
      type: this.state.type,
      pax: this.state.pax,
      fuel: this.state.fuel,
      position: this.state.position,
      season: this.state.season,
    })
      .then(({ data }) => {
        if (data.data) {
          this.setState({
            wgt_bal_plan: data.data.wgt_bal_plan,
            wgt_bal_status: data.data.wgt_bal_status,
            aft_index: data.data.aft_index,
            position: data.data?.wgt_bal_plan?.position
          })
        }
      }).catch(() => {
      })
  }

  onChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState({ [name]: value }, () => {
      this.onSubmit()
    })

  }

  onChangePax = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState({
      pax: {
        ...this.state.pax,
        [name]: value
      }
    }, () => {
      this.onSubmit()
    })
  }

  onChangePosition = (e, i, t) => {
    if (t === 'bulk') {
      this.setState({
        position: {
          ...this.state.position,
          [t]: e
        },
      }, () => {
        this.onSubmit()
      })
    }
    else {
      this.setState({
        position: {
          ...this.state.position,
          [t]: {
            ...this.state.position[t],
            [i]: e
          }
        },
      }, () => {
        this.onSubmit()
      })
    }
  }

  aftStatus = (aftIndex) => {
    let aftStatus = ''
    if (aftIndex) {
      if (aftIndex > 81 && aftIndex < 10000) {
        aftStatus = 'Tail Heavy'
      }
      else if (aftIndex < 19) {
        aftStatus = 'Nose Heavy'
      }
      return aftStatus
    }
  }

  aftIndexStyle = (aftIndex) => {
    if (aftIndex) {
      if (aftIndex <= 0 || aftIndex >= 100) {
        return {
          backgroundColor: 'var(--danger)',
          color: 'white',
          fontWeight: 'bold'
        }
      } else if ((aftIndex >= 1 && aftIndex <= 19) || (aftIndex >= 81 && aftIndex <= 99)) {
        return {
          backgroundColor: '#d9d301',
          color: 'black'
        }
      } else {
        return {
          backgroundColor: 'var(--success)',
          color: 'white',
          fontWeight: 'bold'
        }
      }
    } else {
      return { color: 'black' }
    }
  }

  clearBin = () => {
    this.setState({
      position: {
        pallet: {},
        cargo: {},
        bulk: 0
      }
    }, () => {
      this.onSubmit()
    })
  }

  acTypeChange = () => {
    let optimal = true
    switch (this.props.editRecord?.fleet) {
    case '359':
      return (
        (!this.state.position?.pallet?.p34 && this.state.position?.pallet?.p33) || (this.state.position?.pallet?.p34 && !this.state.position?.pallet?.p33) || (!this.state.position?.pallet?.p32 && this.state.position?.pallet?.p31) || (this.state.position?.pallet?.p32 && !this.state.position?.pallet?.p31) || (!this.state.position?.pallet?.p25 && this.state.position?.pallet?.p24) || (this.state.position?.pallet?.p25 && !this.state.position?.pallet?.p24) || (!this.state.position?.pallet?.p23 && this.state.position?.pallet?.p22) || (this.state.position?.pallet?.p23 && !this.state.position?.pallet?.p22) || (!this.state.position?.pallet?.p21 && this.state.position?.pallet?.p11) || (this.state.position?.pallet?.p21 && !this.state.position?.pallet?.p11) || (this.state.position?.pallet?.p41)? optimal = false : optimal = true,
        <AcType359
          optimal={optimal}
          onChange={this.onChangePosition}
          position={this.state.position}
          clearBin={this.clearBin}
        />
      )
    case '764':
      optimal = this.props.editRecord?.build_uld?.find((u) => u.type === 'LD3')? false : true
      return (
        <AcType764
          optimal={optimal}
          onChange={this.onChangePosition}
          position={this.state.position}
          clearBin={this.clearBin}
        />
      )
    case '763':
      optimal = this.props.editRecord?.build_uld?.find((u) => u.type === 'LD3')? false : true
      return (
        <AcType763
          optimal={optimal}
          onChange={this.onChangePosition}
          position={this.state.position}
          clearBin={this.clearBin}
        />
      )
    case '333':
      (!this.state.position?.pallet?.p41 && this.state.position?.pallet?.p34) || (this.state.position?.pallet?.p41 && !this.state.position?.pallet?.p34) || (!this.state.position?.pallet?.p33 && this.state.position?.pallet?.p32) || (this.state.position?.pallet?.p33 && !this.state.position?.pallet?.p32) || (!this.state.position?.pallet?.p25 && this.state.position?.pallet?.p24) || (this.state.position?.pallet?.p25 && !this.state.position?.pallet?.p24) || (!this.state.position?.pallet?.p23 && this.state.position?.pallet?.p22) || (this.state.position?.pallet?.p23 && !this.state.position?.pallet?.p22) || (!this.state.position?.pallet?.p21 && this.state.position?.pallet?.p11) || (this.state.position?.pallet?.p21 && !this.state.position?.pallet?.p11)? optimal = false : optimal = true
      return (
        <AcType333
          optimal={optimal}
          onChange={this.onChangePosition}
          position={this.state.position}
          clearBin={this.clearBin}
        />
      )
    case '339':
      (!this.state.position?.pallet?.p41 && this.state.position?.pallet?.p34) || (this.state.position?.pallet?.p41 && !this.state.position?.pallet?.p34) || (!this.state.position?.pallet?.p25 && this.state.position?.pallet?.p24) || (this.state.position?.pallet?.p25 && !this.state.position?.pallet?.p24) || (!this.state.position?.pallet?.p23 && this.state.position?.pallet?.p22) || (this.state.position?.pallet?.p23 && !this.state.position?.pallet?.p22) || (!this.state.position?.pallet?.p21 && this.state.position?.pallet?.p11) || (this.state.position?.pallet?.p21 && !this.state.position?.pallet?.p11) || (this.state.position?.pallet?.p33)? optimal = false : optimal = true
      return (
        <AcType339
          optimal={optimal}
          onChange={this.onChangePosition}
          position={this.state.position}
          clearBin={this.clearBin}
        />
      )
    case '332':
      (!this.state.position?.pallet?.p41 && this.state.position?.pallet?.p33) || (this.state.position?.pallet?.p41 && !this.state.position?.pallet?.p33) || (!this.state.position?.pallet?.p32 && this.state.position?.pallet?.p31) || (this.state.position?.pallet?.p32 && !this.state.position?.pallet?.p31) || (!this.state.position?.pallet?.p24 && this.state.position?.pallet?.p23) || (this.state.position?.pallet?.p24 && !this.state.position?.pallet?.p23) || (!this.state.position?.pallet?.p22 && this.state.position?.pallet?.p21) || (this.state.position?.pallet?.p22 && !this.state.position?.pallet?.p21)? optimal = false : optimal = true
      return (
        <AcType332
          optimal={optimal}
          onChange={this.onChangePosition}
          position={this.state.position}
          clearBin={this.clearBin}
        />
      )
    default:
      return null
    }
  }

  acTypeName = () => {
    let acTypeText = ''
    switch (this.props.editRecord?.fleet) {
    case '359':
      acTypeText = 'AIRBUS A350-900B'
      break
    case '764':
      acTypeText = 'BOEING 767-400ER'
      break
    case '763':
      acTypeText = 'BOEING 767-300ER'
      break
    case '333':
      acTypeText = 'AIRBUS A330-300'
      break
    case '339':
      acTypeText = 'AIRBUS A330-900NEO'
      break
    case '332':
      acTypeText = 'AIRBUS A330-200'
      break
    default:
      return null
    }
    return acTypeText
  }

  getULD = () => {
    let ulds = []
    if (this.props.editRecord?.build_uld && this.props.editRecord?.build_uld?.length > 0) {
      this.props.editRecord?.build_uld?.map((u) => {
        let wgt = u?.data?.tare_wgt || 0
        let remark = ''
        let pri = []
        if (this.props.editRecord && this.props.editRecord?.build_plan && this.props.editRecord?.build_plan?.length > 0) {
          this.props.editRecord?.build_plan?.map((item) => {
            if (item?.ulds && item?.ulds?.length > 0) {
              item?.ulds?.map((uld) => {
                // eslint-disable-next-line
                console.log(uld, item)
                if (uld.id && uld.id == u.id) {
                  wgt = wgt + uld.wgt,
                  uld.remark ? pri.push(uld.remark) : {}
                }
              }
              )
            }
          })
        }
        // sort priority  
        if (pri && pri.length > 0) {
          pri.sort((a, b) => a - b)
          remark = pri[0]
        }
        ulds.push({
          uld_type: u.type + (u.uld_no ? u.uld_no : ''),
          wgt: wgt,
          remark: remark
        })
      })
    }
    return ulds
  }

  render() {
    const acType = this.acTypeChange()
    const acName = this.acTypeName()
    const modalTitle = 
    <Row gutter={[{
      xs: 0,
      sm: 0,
      md: 24,
      lg: 24
    }, {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24
    },]} className='modal-title fv-middle'>
      <Col
        xs={{
          span: 12,
          order: 1,
        }}
        sm={{
          span: 12,
          order: 1,
        }}
        md={{
          span: 12,
          order: 1,
        }}
        lg={{
          span: 5,
          order: 1,
        }}
        xl={{
          span: 5,
          order: 1,
        }}
        xxl={{
          span: 8,
          order: 1,
        }}
        className=''
      ><div className='dl-logo'><img src={delta} /> </div></Col>
      <Col
        xs={{
          span: 24,
          order: 3,
        }}
        sm={{
          span: 24,
          order: 3,
        }}
        md={{
          span: 24,
          order: 3,
        }}
        lg={{
          span: 14,
          order: 2,
        }}
        xl={{
          span: 14,
          order: 2,
        }}
        xxl={{
          span: 8,
          order: 2,
        }}
        className='content-ht-half '
      ><div className='center-ac text-center'><span className='center-title'>Weight & Balance Planner</span>
          <span className='ac-name'>{acName}</span>
        </div></Col>
      <Col
        xs={{
          span: 12,
          order: 2,
        }}
        sm={{
          span: 12,
          order: 2,
        }}
        md={{
          span: 12,
          order: 2,
        }}
        lg={{
          span: 5,
          order: 3,
        }}
        xl={{
          span: 5,
          order: 3,
        }}
        xxl={{
          span: 8,
          order: 3,
        }}
        className='content-ht-half'
      > <div className='my-2 logo'><img src={deltaLogo} /></div></Col>
    </Row>

    return (
      <Modal
        title={modalTitle}
        open={this.props.openPlanner}
        closable={true}
        onCancel={() => this.props.openWB(false)}
        footer={false}
        width='95%'
        centered
        className='custom-modal pb-0'
      >
        <AcDetails
          onChange={this.onChange}
          onChangePax={this.onChangePax}
          ship={this.state.ship}
          type={this.state.type}
          pax={this.state.pax}
          fuel={this.state.fuel}
          season={this.state.season}
          aft_index={this.state.aft_index}
          wgt_bal_status={this.state.wgt_bal_status}
          aftIndexStyle={this.aftIndexStyle}
          aftStatus={this.aftStatus}
          cargo_margin={this.state.cargo_margin}
        />

        {acType}

        <ContainerDetails data={this.props.editRecord.wgt_bal_plan || {}} ulds={this.getULD()} />

      </Modal>
    )
  }
}
export default WeightAndBalance
