import React from 'react'
import { Row, Col, Table } from 'antd'

const { Column, ColumnGroup } = Table

const ContainerDetails = (props) => {

  const getData = () => {
    return [
      {
        key: '1',
        positions: props.data?.bag_pos || 0,
        bulk: props.data?.bulk_bag || 0
      },
      {
        key: '2',
        positions: `${props?.data?.bag_pos_wgt || 0} lbs per pos`,
        bulk: `${props?.data?.bulk_bag_wgt || 0} lbs`
      }
    ]
  }

  return (
    <Row className='wb-tables' gutter={[32, 0]}>
      <Col xs={12} >
        <Table
          className='custom-table w-100 multi-head fc-table'
          dataSource={getData()}
          pagination={false}
        >
          <ColumnGroup title='Estimated Bags' align='center'>
            <Column
              title='Bag Positions'
              dataIndex='positions'
              key='positions'
              align='center'
              className='checklist-cols'
            />
            <Column
              title='Bulk Bags'
              dataIndex='bulk'
              key='bulk'
              align='center'
              className='checklist-cols'
            />
          </ColumnGroup>
        </Table>
      </Col>

      {/* <Col xs={4}></Col> */}

      <Col xs={12}>
        <Table
          className='custom-table w-100 multi-head fc-table'
          dataSource={props.ulds}
          pagination={false}
        >
          <ColumnGroup title='Containers in Staging' align='center'>
            <Column
              title='Containter type'
              dataIndex='uld_type'
              key='uld_type'
              align='center'
              className='checklist-cols'
            />
            <Column
              title='Est. Conv Weight'
              dataIndex='wgt'
              key='wgt'
              align='center'
              className='checklist-cols'
            />
            <Column
              title='Priority'
              dataIndex='remark'
              key='remark'
              align='center'
              className='checklist-cols'
            />
          </ColumnGroup>
        </Table>
      </Col>
    </Row>
  )
}
export default ContainerDetails
