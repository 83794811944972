import React, { Component, Fragment } from 'react'
import { Layout } from 'antd'

import './layout.css'

const { Content } = Layout

class DashboardLayout2 extends Component {
  render() {
    return (
      <Fragment>
        <Content className='site-layout-background content'>
          <div >
            {React.cloneElement(this.props.children, { ...this.props })} </div>
        </Content>
      </Fragment>
    )
  }
}

export default DashboardLayout2
