import React, { Component } from 'react'
import './index.css'
class User extends Component {
  // constructor() {
  //   super()
  // }

  render() {
    return (<div className={window.navigator.userAgentData.platform.includes('macOS') ? 'chcframe-outer chcframe-mac' : 'chcframe-outer'}>
      <iframe className='chcframe' src='https://analytics.orgadynamics.com/index.php?module=Widgetize&action=iframe&moduleToWidgetize=Dashboard&actionToWidgetize=index&idSite=3&period=week&date=yesterday&token_auth=d0ba5829540ea7f00dff1c06d0a589d3' frameBorder='0' marginHeight='0' marginWidth='0' width='100%' />
    </div>)
  }
}
export default User