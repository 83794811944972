import React, { Component } from 'react'
import { Col, Row, DatePicker, TimePicker, Form, Select, Card } from 'antd'
import './index.css'

const { Option } = Select
// const rowGutter = '16px'
// const outerPadding =   rowGutter / 2 

class Dashboard extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super()
  }

  render() {
    return <div>
      <Card className='custom-card'>
        <Form
          name='wrap'
          labelCol={{ span: 24 }}
          labelAlign='left'
          colon={false}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} className=''>
              <Form.Item label='From' className='date-wrapper' name=''>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className=''>
                    <DatePicker
                      allowClear={false}
                      size='small' />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className=''>
                    <TimePicker />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Form.Item label='To' className='date-wrapper' name=''>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className=''>
                    <DatePicker
                      allowClear={false}
                      size='small' />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className=''>
                    <TimePicker />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <Row gutter={[24, 24]} style={{ padding: '12px' }}>
        <Col xs={24} className='dash-row d-flex p-0'>
          <span className='dash-inner-title'>Input</span>
          <Row gutter={[16, 0]} wrap={true} style={{ padding: '8px' }} justify='center' className='w-100'>           
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div  className='data-wrapper'>
                <p className='title'>Tons (Flights)</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Tons (RFS)</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Tons (Local)</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Transit ULDS</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Local ULDS</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Transit AWBS</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Local AWBS</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Col>
        <Col span={24} className='dash-row d-flex p-0'>
          <span className='dash-inner-title'>OUTPUT</span>
          <Row gutter={[16, 16]} wrap style={{ padding: '8px' }} className='w-100' justify='center'>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Tons (Flights)</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Tons (RFS)</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Tons (Local)</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Built Up ULDS</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Pure ULDS</p>
                <p className='data'>26</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={6} xl={3} xxl={3} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>AWBS</p>
                <p className='data'>1126</p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} xl={12} className='dash-row d-flex p-0'>
          <span className='dash-inner-title'>EFFICIENCY</span>
          <Row gutter={[16, 16]} wrap style={{ padding: '8px' }} className='w-100' justify='center' >
            <Col xs={12} sm={6} md={6} lg={8} xl={8} xxl={8} className='dash-inner-wrapper'>
              <div  className='data-wrapper'>
                <p className='title'>Tons / Hr</p>
                <p className='data'>30</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={8} xl={8} xxl={8} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>AWBS / Hr</p>
                <p className='data'>326</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={8} xl={8} xxl={8} className='dash-inner-wrapper'>
              <div className='data-wrapper'>
                <p className='title'>Storage Cap</p>
                <p className='data'>82%</p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} xl={12} className='xl-pl-4 pr-0'>
          <div className='dash-row d-flex'>
            <span className='dash-inner-title'>DISRUPTIONS</span>
            <Row gutter={[16, 16]} wrap style={{ padding: '8px' }} className='w-100' justify='center'>
              <Col xs={12} sm={6} md={6} lg={12} xl={16} xxl={18} className='f-middle'>
                <Form.Item label='Reason' className='mb-0 flex' labelCol={{ span: 24 }}>
                  <Select size='small'
                    placeholder='Select reason'
                    className='w-100'
                  >
                    <Option value='1'>All</Option>
                    <Option value='2'>Incoming Flight Delay</Option>
                    <Option value='3'>Wrong Booking Ex Origin</Option>
                    <Option value='4'>Missing at Build Up</Option>
                    <Option value='5'>Lack of staff at build up</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} className='dash-inner-wrapper'>
                <div className='data-wrapper'>
                  <p className='title'>AWBS</p>
                  <p className='data'>326</p>
                </div>
              </Col>
            </Row>
          </div>
          
        </Col>
      </Row>
    </div>
  }
}

export default Dashboard
