/* eslint-disable no-console */
import React, { Component, Fragment } from 'react'
import {
  Row,
  Col,
  Button,
  Card,
  Input,
  Form,
  Popconfirm,
  Table,
  Modal,
  Checkbox,
  notification,
  Segmented,
  //   DatePicker,
  // Space,
  // Typography,
  //   Form,
  // Radio,
  Tooltip,
  //   notification,
  //   Progress,
  Select,
  InputNumber,
  Space,
  Tag,
  // ConfigProvider
} from 'antd'
// import { EnterOutlined } from '@ant-design/icons'
// import { /* CloseOutlined,  CheckOutlined */  } from '@ant-design/icons'
import addIcon from '../../../assets/images/add.svg'
import upArrowIcon from '../../../assets/images/up-arrow.svg'
import downArrowIcon from '../../../assets/images/down-arrow.svg'
import mailIcon from '../../../assets/images/mail.svg'
import { cloneDeep } from 'lodash'
const { Option } = Select
// import moment from 'moment'
import constants, { systemParameterTabs as titles } from '../../../constants/constants'
import '../index.css'
// import en_GB from 'antd/lib/locale-provider/en_GB'
import cssconstants from '../../../constants/cssconstants'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import Update from '../../../assets/images/input-tick.svg'
import { SaveOutlined } from '@ant-design/icons'
import Cookies from 'universal-cookie'
import { cacheRetrieve } from '../../../utils/common'
import { Http } from '../../../utils'
let cookies = new Cookies()
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'
const station = cookies.get('station')
// const { Title } = Typography

// const { Text } = Typography
// const { Search } = Input
// const stationTooltip = 'Select the dropdown in the header to change station'
const emailregex = '^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*.[a-z]{2,})(,([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*.[a-z]{2,}))*$'
class DoDMilestones extends Component {
  constructor() {
    super()
    // this.next = this.next.bind(this),
    // this.previous = this.previous.bind(this),
    this.state = { 
      selectedOption: '', 
      disabledInput: true,
      milestoneEdit: null,
      milestoneAdded: '',
      depTime: null, 
      type: null,
      duplicate: null,
      selMilestone: null ,
      questions: [],
      order: null ,
      orderIndex: null ,
      isEdit: false,
      selectedMilestone: '',
      questionType: 'yes/no',
      comment: 'None',
      actionout: 'None',
      actiontype: null,
      actionms: [],
      nested: 'None',
      addQuestion: {},
      nestedQuestionType: 'yes/no',
      nestedQuestion: null,
      nestedComment: 'None',
      nestedActionOut: 'None',
      nestedActionType: null,
      nestedActionMs: [],
      nestedDataSource: [],
      value: null,
      setValue: null,
      dummyMilestones: [],
      allMilestones: [],
      showOptions: false,
      optionsDisabled: true,
      amber: null,
      showNested: null,
      parent_id: null,
      commentsRequired: false,
      actionOn: false,
      actionType: null,
      actionMS: [],
      option: null,
      options: [],
      nestedoptions: [],
      question_id: null,
      question: null,
      optionType: null,

      editRecord: null,
      editMilestoneRecord: null,
      editMilestone: null,
      editCutoff: null,
      editType: null,

      editQuestion: null,
      editComments: null,
      editActionOut: null,
      editActionType: null,
      editNested: null,

      editOption: null,
      editOptionName: null,
      editIndex: null,
      editCommentsReq: null,
      editActionOn: null,
      editActionMs: [],

      editNestedRecord: null, 
      editNestedQuestion: null, 
      editNestedComments: null,

      openEmail: false,
      emailAdded: null,
      addedEmails: [],
      existingEmails:[],
      emailMSId: null,
      emailSectionDisplayed: false,

      openQuestionForm: false, 

      global_details: null,
      showEmails: false,
      nrEmailIn: null,
      addedNrEmails: [],
      existingNrEmails:[],
      faEmailIn: null,
      addedFaEmails: [],
      existingFaEmails:[],
      selSegment: 'No-Ride',
      bpEmailIn: null,
      addedBpEmails: [],
      existingBpEmails: [],
      fdbkEmailIn: null,
      addedFdbkEmails: [],
      existingFdbkEmails: []
    }
  }
  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      // eslint-disable-next-line no-console
      console.log('parsedData',parsedData)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData.TYPE === 'MILESTONES'){
          this.fetchMilestones()
        }
        if(parsedData.TYPE === 'QUESTION'){
          if(parsedData.DETAILS.ms_id === this.state.selMilestone?.id){
            this.fetchQuestions()
          }
        }
        if(parsedData.TYPE === 'NESTED_QUESTION'){
          if(parsedData.DETAILS.ms_id === this.state.selMilestone?.id){
            if(parsedData.Action === 'DELETE' && parsedData.DETAILS.p_id){
              this.deleteNestedQEvent(parsedData.DETAILS)
            } else {
              this.fetchNestedQuestions()
            }
          }
        }
      }
    }
    // this.setState({ dummyMilestones : dummyMilestones.sort((a,b) => {return a.order - b.order}) })
    this.fetchMilestones()
  }

  //FETCH GLOBAL DETAILS
  fetchGlobalDetails = async () => { 
    let callback = this.fetchGlobalDetailsFn
    let pageURL =  'global_details'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchGlobalDetailsFn = async(response) => {
    const dataResp = await response.json()
    if(response?.status === 200) {
      if (dataResp?.data?.length > 0) {
        this.setState({ 
          global_details: dataResp?.data[0],
          existingNrEmails : dataResp?.data[0]?.nremails || [],
          existingFaEmails : dataResp?.data[0]?.faemails || [],
          existingBpEmails : dataResp?.data[0]?.bpemails || [],
          existingFdbkEmails : dataResp?.data[0]?.fdbkemails || [],
          showEmails : true
        })

      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: dataResp?.message ? dataResp?.message : constants.SERVER_CONNECTION_ERROR,
      })
    }
  }

  fetchMilestones = async () => {
    let callback = this.fetchMilestonesFn
    let pageURL =  'milestones'
    let headers = { station: station }
    cacheRetrieve(callback, pageURL, headers )
  }

  fetchMilestonesFn = async(resp) => {
    const respData = await resp.json()
    if (respData && resp?.status == 200) {
      let allMilestones = cloneDeep(respData?.data)
      allMilestones.push({
        id: 'No Ride Alert',
        is_deleted: false,
        title: 'No Ride Alert',
        type: 'No Rides'
      })
      // allMilestones.push({
      //   id: 'Interacted with LDD',
      //   is_deleted: false,
      //   title: 'Interacted with LDD',
      //   type: 'Interacted with LDD'
      // })
      // allMilestones.push({
      //   id: 'Flight Audit',
      //   is_deleted: false,
      //   title: 'Flight Audit',
      //   type: 'Flight Audit'
      // })
      this.setState({ 
        dummyMilestones: respData?.data,
        allMilestones: allMilestones
      },()=>('tableData',this.state.dummyMilestones))
    }
  }

  fetchQuestions = async () => {
    let callback = this.fetchQuestionsFn
    let url = 'questions'
    if(this.state.selMilestone?.id) {
      url= url+'?ms_id='+this.state.selMilestone?.id
    }
    let headers = { station: station }
    cacheRetrieve(callback, url, headers )
  }

  fetchQuestionsFn = async(resp) => {
    const respData = await resp.json()
    // eslint-disable-next-line no-console
    console.log('respData',respData)
    if(respData && respData.data.length>0){
      this.setState({ questions : respData.data }) 
    } else {
      this.setState({ questions : [] })
    }
  }

  fetchNestedQuestions = async () => {
    Http.get(`/nested_questions/${this.state.parent_id}`)
      .then((data) => {
        if (data && data.status === 200) {
          //updated options for nested questions
          if(this.state.showOptions){
            let questionIndex = data.data.data.findIndex((item) => item.id === this.state.question_id)
            this.setState({
              nestedDataSource: data.data.data ,
              nestedoptions: data.data.data[questionIndex].details.options,
            })
          } else {
            this.setState({ nestedDataSource: data.data.data })
          }
        }
      })
  }

  editHandler = ( index, operation) =>{
    let dummyMilestones = cloneDeep(this.state.dummyMilestones)
    let url = `change_priority/${dummyMilestones[index].id}`
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dod_milestones,
      station: station
    }
    let formdata = new FormData()
    formdata.append('direction', operation)
    this.setState({ loading: true })
    Http.put(url, formdata, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.setState({ 
            depTime: null ,
            milestoneAdded: ''
          })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
        this.setState({ loading: false })
      })

  }

  radioSelect = (e) => {
    this.setState({ questionType: e })
    // eslint-disable-next-line no-console
    console.log('radio checked', this.state.questionType)
  } 

  
  addMilestone = ()  =>  {
    console.log(this.state.milestoneAdded, this.state.depTime, 'data')
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dod_milestones,
      station: station
    }
    let formdata = new FormData()
    formdata.append('title', this.state.milestoneAdded || '')
    formdata.append('cutoff', this.state.depTime || '')
    formdata.append('type', this.state.type || '')
    this.setState({ loading: true })
    Http.post('/milestone', formdata, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          this.setState({ 
            depTime: null ,
            milestoneAdded: ''
          })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
        this.setState({ loading: false })
      })
  }

  openQuestionForm = () => {
    this.setState({ openQuestionForm: true })
  }

  updateMilestone = (operator=null) => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dod_milestones,
      station: station
    }
    let formdata = {}
    let id = null
    if (operator === 'amber') {
      id = this.state.selMilestone?.id
      formdata.amber= this.state.amber
    } else if(operator === 'email'){
      let emails = this.state.existingEmails.concat(this.state.addedEmails)
      let uniqueEmails = [...new Set(emails)]
      id = this.state.emailMSId
      formdata.emails= uniqueEmails
    } else {
      id = this.state.editMilestoneRecord?.id
      formdata.title= this.state.editMilestone || ''
      formdata.cutoff= this.state.editCutoff || ''
      formdata.type= this.state.editType || ''
    }
    this.setState({ loading: true })
    Http.put(`/milestone/${id}`, formdata, { headers })
      .then((data) => {
        if (data && data.status === 200) {
          // this.clearForm()
          this.clearTableForm()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
          this.setState({ emailSectionDisplayed: false })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
        this.setState({ loading: false })
      })
  }

  updateEmails = () => {
    //store email data inside global_details table.
    console.log('this.state.global_details',this.state.global_details?.details?.stations)
    let headers = {
      station: this.state.station,
      user_id: this.state.user_id,
    }
    let formdata = {
      show_md: this.state.global_details.hideMD,
      recovery_cleartoggle: this.state.global_details.recovery_cleartoggle,
      bd_cleartoggle: this.state.global_details.bd_cleartoggle,
      stations: this.state.global_details?.details?.stations ? this.state.global_details?.details?.stations?.toString() : '',
    }
    let nremails = this.state.existingNrEmails.concat(this.state.addedNrEmails)
    let uniquenrEmails = [...new Set(nremails)]
    formdata.nremails= uniquenrEmails
    let faemails = this.state.existingFaEmails.concat(this.state.addedFaEmails)
    let uniquefaEmails = [...new Set(faemails)]
    formdata.faemails= uniquefaEmails
    let bpemails = this.state.existingBpEmails.concat(this.state.addedBpEmails)
    let uniquebpEmails = [...new Set(bpemails)]
    formdata.bpemails= uniquebpEmails
    let fdbkemails = this.state.existingFdbkEmails.concat(this.state.addedFdbkEmails)
    let uniquefdbkEmails = [...new Set(fdbkemails)]
    formdata.fdbkemails= uniquefdbkEmails

    Http.put('/global_detail/1', formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.setState({
            showEmails: false,
            existingNrEmails: [],
            existingFaEmails: [],
            addedNrEmails: [],
            addedFaEmails: [],
            existingBpEmails: [],
            addedBpEmails: [],
            existingFdbkEmails:[],
            addedFdbkEmails: []
          })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
  }

  addQuestion = () => {
    let obj = { type: this.state.questionType }
    let milestones_id = this.state.selMilestone?.id
    obj.milestones_id = milestones_id
    if(this.state.questionType === 'yes/no'){
      obj.question = this.state.addQuestion?.question
      obj.comment = this.state.comment
      obj.actionout = this.state.actionout
      obj.actiontype = this.state.actiontype
      obj.actionms = this.state.actionms
      obj.nested = this.state.nested
    } else if(this.state.questionType === 'mcq'){
      obj.question = this.state.addQuestion?.question
      obj.options = cloneDeep(this.state.options)
      obj.comment = null
      obj.actionout = null
      obj.actiontype = null
      obj.actionms = []
      obj.nested = null
    } else if(this.state.questionType === 'other'){
      obj.question = this.state.addQuestion?.question
      obj.comment = null
      obj.actionout = null
      obj.actiontype = null
      obj.actionms = []
      obj.nested = null
    }
    // eslint-disable-next-line no-console
    console.log('obj',obj)
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dod_milestones,
      station: station
    }
    Http.post('/question', obj , { headers })
      .then(() => {
        this.setState({
          addQuestion: {},
          questionType: 'yes/no',
          options: [],
          comment: 'None',
          actionout: 'None',
          actiontype: null,
          actionms: [],
          nested: 'None'
        },()=>this.fetchQuestions())
      })
  }

  deleteQuestion = (record) => {
    console.log('clicked')
    let obj = { is_deleted : true }
    
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dod_milestones,
      station: station
    }
    Http.put(`/question/${record.id}`, obj , { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.clearQTableForm()
          this.clearOTableForm()
          // this.fetchQuestions()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  deleteNestedQEvent = (DETAILS) => {
    let nestedDataSource = this.state.nestedDataSource
    let index = nestedDataSource.findIndex((item) => item.id === DETAILS.q_id)
    if(index > -1){
      nestedDataSource.splice(index, 1)
      this.setState({ nestedDataSource: [] },()=>this.setState({ nestedDataSource: nestedDataSource }))
    }
  }
  updateQuestion = () => {
    let editRecord = this.state.editRecord 
    if(this.state.question) {
      editRecord = this.state.question 
    } console.log(this.state.editRecord, 'editrecord' , this.state.question, 'question')
    let obj = { type: editRecord?.details.type }
    console.log(editRecord, 'editrecord', this.state.options)
    if(editRecord?.details.type === 'yes/no'){
      console.log('test')
      obj.question = this.state.editQuestion || editRecord?.details?.question
      obj.comment = this.state.editComments || editRecord?.details?.comment
      obj.actionout = this.state.editActionOut || editRecord?.details?.actionout
      obj.actiontype = this.state.editActionType || editRecord?.details?.actiontype
      obj.actionms = this.state.editActionMs || editRecord?.details?.actionms
      obj.nested = this.state.editNested || editRecord?.details?.nested
    } else if(editRecord?.details.type === 'mcq'){
      obj.question = this.state.editQuestion || editRecord.details?.question
      obj.options = cloneDeep(this.state.optionType === 'Nested' ? this.state.nestedoptions : this.state.options)
      obj.comment = this.state.editComments || editRecord?.details?.comment
      // obj.actionout = this.state.editActionOut || editRecord?.details?.actionout
      obj.actionout = null
      obj.actiontype = null
      obj.actionms = []
      obj.nested = null
    } else if(editRecord?.details.type === 'other'){
      obj.question = this.state.editQuestion || editRecord?.details?.question
      obj.comment = null
      obj.actionout = null
      obj.actiontype = null
      obj.actionms = []
      obj.nested = null

    }
    // eslint-disable-next-line no-console
    console.log('obj',obj)
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dod_milestones,
      station: station
    }
    Http.put(`/question/${this.state.editRecord?.id ? this.state.editRecord.id : this.state.question_id }`, obj , { headers })
      .then(() => {
        this.clearQTableForm()
        this.clearOTableForm()
        this.fetchQuestions()
      })
  }

  addNestedQuestion = () => {
    let obj = { 
      type: this.state.nestedQuestionType,
      parent_id: this.state.parent_id
    }
    let milestones_id = this.state.selMilestone?.id
    obj.milestones_id = milestones_id
    if(this.state.nestedQuestionType === 'yes/no'){
      obj.question = this.state.nestedQuestion
      obj.comment = this.state.nestedComment
      obj.actionout = this.state.nestedActionOut
      obj.actiontype = this.state.nestedActionType
      obj.actionms = this.state.nestedActionMs
      obj.nested = null
    } else if(this.state.nestedQuestionType === 'mcq'){
      obj.question = this.state.nestedQuestion
      obj.options = cloneDeep(this.state.nestedoptions)
      obj.comment = null
      obj.actionout = null
      obj.actiontype = null
      obj.actionms = []
      obj.nested = null
    } else if(this.state.nestedQuestionType === 'other'){
      obj.question = this.state.nestedQuestion
      obj.comment = null
      obj.actionout = null
      obj.actiontype = null
      obj.actionms = []
      obj.nested = null

    }
    // eslint-disable-next-line no-console
    console.log('obj',obj)
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dod_milestones,
      station: station
    }
    Http.post('/question', obj , { headers })
      .then(() => {
        this.setState({
          nestedQuestion: null,
          nestedQuestionType: 'yes/no',
          nestedoptions: [],
          nestedComment: 'None',
          nestedActionOut: 'None',
          nestedActionType: null,
          nestedActionMs: [],
        },()=>this.fetchNestedQuestions()) 
      })
  }
  // Add Options Modal
  showOptions = (optionType) => {
    this.setState({ 
      showOptions: true, 
      optionType: optionType
    })
  }
  optionsHide = () => {
    // this.clearExceptionForm()
    // this.clearExceptionTableForm()
    if(this.state.question_id){
      this.setState({ options: [] }) 
    } 
    this.setState({ 
      showOptions: false,
      question_id: null,
      question: null,
    })
  }

  openNested = (record) => {
    // eslint-disable-next-line no-console
    console.log('clicked')
    Http.get(`/nested_questions/${record.id}`)
      .then((data) => {
        if (data && data.status === 200) {
          this.setState({ 
            nestedDataSource: data.data.data,
            showNested: true,
            parent_id: record.id
          }) 
        }
      })
  }

  closeNested =() => {
    this.setState({ 
      showNested: false,
      parent_id: null
    })
  }

  addOptions = (operator, index) => {
    // eslint-disable-next-line no-console
    console.log(operator,'addOptions', index)
    if((!this.state.option || this.state.option === '') && operator === 'add') {
      //do nothing
    } else if(this.state.actionOn && ( this.state.actionMS.length === 0 || this.state.actionType === null )){
      //do nothing
    } else if(this.state.editActionOn && ( this.state.editActionMs.length === 0 || this.state.editActionType === null )){
      //do nothing
    } else {
      let option = {
        name: operator === 'add' ? this.state.option : this.state.editOptionName, 
        commentsReq: operator === 'add' ? this.state.commentsRequired : this.state.editCommentsReq,
        actionon: operator === 'add' ? this.state.actionOn : this.state.editActionOn,
        actiontype: operator === 'add' ? this.state.actionType : this.state.editActionType,
        actionms: operator === 'add' ? this.state.actionMS : this.state.editActionMs,
        actionmsarr: operator === 'add' ? this.state.actionMS.map((item) => {
          let depms = this.state.allMilestones.find((ms) => ms.id === item)
          if (depms) {
            return depms.title
          } else if (typeof item === 'string') {
            return item
          }
        }) : this.state.editActionMs.map((item) => {
          let depms = this.state.allMilestones.find((ms) => ms.id === item)
          if (depms) {
            return depms.title
          } else if (typeof item === 'string') {
            return item
          }
        })
      }
      let options = this.state.options || []
      let nestedoptions = this.state.nestedoptions
      if(this.state.optionType === 'Options'){
        if(operator === 'add') {
          options= [ ...options, option]
        } else if (operator === 'edit') {
          options[parseInt(index)] = option
        } 
      } else if(this.state.optionType === 'Nested'){
        if(operator === 'add') {
          nestedoptions = [ ...nestedoptions, option]
        } else if (operator === 'edit') {
          nestedoptions[index] = option
        }
      } console.log('test')
      this.setState({ 
        options: [ ],
        nestedoptions: nestedoptions,
        option: null,
        commentsRequired: false,
        actionOn: false,
        actionType: null,
        actionMS: [],
        editIndex: null,
        editOptionName: null,
        editCommentsReq: null,
        editActionOn: null,
        editActionMs: [],
        editQuestion: this.state.question?.details.question,
        commentsReq: this.state.question?.details.comment,
        actionon: this.state.question?.details.actionon
      },()=>{
        this.setState({ options: options }, () => 
        { 
          if(this.state.question_id) {
            this.updateQuestion()
          } 
        })
        
        // eslint-disable-next-line no-console
        console.log('options',this.state.options)
      })
    }
  }

  editTableForm = (record) => {
    this.setState({
      editMilestoneRecord: record,
      editMilestone: record.title,
      editCutoff: record.cutoff,
      editType: record.type,
    })
  }

  editOptions = (record, index) => {
    this.setState({
      editOption: record?.id ? record : null,
      editOptionName: record.name,
      editCommentsReq: record.commentsReq,
      editActionOn: record.actionon,
      editActionType: record.actiontype,
      editActionMs: record.actionms,
      editIndex: record?.id ? null : parseInt(index)
    }, () => console.log(this.state.editOptionName, 'name', this.state.editCommentsReq, 'comments', this.state.editOption, 'record', this.state.editIndex, 'index', record, 'record' )
    ) 
  }

  editForm = (record) => {
    console.log(record, 'record')
    this.setState({
      editRecord: record,
      editQuestion: record.details.question,
      editComments: record.details.comment,
      editActionOut: record.details.actionout,
      editActionType: record.details.actiontype,
      editActionMs: record.details.actionms,
      editNested: record.details.nested,
      options: record.details.options
    })
    
  }

  clearTableForm = () => {
    this.setState({
      editMilestoneRecord: null,
      editProdCode: '',
      editPriority: null,
      editPpindex: null,
      editName: null,
      editComments: null,
      editActionOut: null,
      editActionType: null,
      addedEmails: [],
      existingEmails:[],
      emailMSId: null,
      openEmail: false,
    })
  }

  clearQTableForm = () => {
    this.setState({
      editRecord: null,
      editQuestion: null,
      editComments: null,
      editActionOut: null,
      editActionType: null,
      editNested: null
    })
  }
  clearOTableForm = () => {
    this.setState({
      editOption: null,
      editOptionName: null,
      editCommentsReq: null,
      editActionOn: null,
      editActionMs: [],
      editIndex: null
    })
  }

  deleteMilestone = (record, index) => {
    console.log('called')
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.dod_milestones
    }
    let formdata= {
      station: station || null,
      index: index,
      is_delete: true
    }
    Http.put(`/milestone/${record.id}`, formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  deleteOption = (record, index) => {
    // console.log(record, 'record', index, 'index')
    if(this.state.optionType === 'Options'){
      let options = this.state.options
      options.splice(index, 1)
      this.setState({  options: options })
      this.state.question_id ? this.updateQuestion() : null
    } else {
      let nestedoptions = this.state.nestedoptions
      nestedoptions.splice(index, 1)
      this.setState({  nestedoptions: nestedoptions })
      this.state.question_id ? this.updateQuestion() : null
    }
    // let headers = {
    //   user_id: cookies.get('id'),
    //   page_title: titles.dod_milestones
    // }
    // let formdata= { 
    //   station: station || null,
    //   index: index,
    //   is_delete: true
    // }
    // Http.put(`/milestone/${this.state.editMilestoneRecord.id}`, formdata, { headers })
    //   .then((data) => {
    //     if(data && data.status === 200){
    //       notification.destroy()
    //       notification.success({
    //         message: 'Success',
    //         description: data.data.message,
    //       })
    //     } else {
    //       notification.destroy()
    //       notification.error({
    //         message: 'Failed',
    //         description: data.data.message,
    //       })
    //     }
    //   })
  }

  saveEmail = () =>  {
    this.setState({ 
      openEmail: false,
      emailSectionDisplayed: false
    })
  }

  addEmail = (type) => {
    if(type === 'ms'){
      const emailsAdded = this.state.addedEmails
      const toAdd = this.state.emailAdded?.trim() || null
      if(toAdd && emailsAdded.indexOf(toAdd) === -1 && toAdd.match(emailregex)){
        emailsAdded.push(toAdd.toLowerCase())
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'Invalid email',
        })
        return false
      }
      console.log(emailsAdded)
      this.setState({ 
        addedEmails : emailsAdded,
        emailAdded : null,
        emailSectionDisplayed : true
      })
    } else if (type === 'nr'){
      console.log('nr checked')
      const addedNrEmails = this.state.addedNrEmails
      const toAdd = this.state.nrEmailIn?.trim() || null
      if(toAdd && addedNrEmails.indexOf(toAdd) === -1 && toAdd.match(emailregex)){
        addedNrEmails.push(toAdd.toLowerCase())
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'Invalid email',
        })
        return false
      }
      console.log(addedNrEmails)
      this.setState({ 
        addedNrEmails : addedNrEmails,
        nrEmailIn : null,
        emailSectionDisplayed: true
      })
    } else if(type === 'fa'){
      const addedFaEmails = this.state.addedFaEmails
      const toAdd = this.state.faEmailIn?.trim() || null
      if(toAdd && addedFaEmails.indexOf(toAdd) === -1 && toAdd.match(emailregex)){
        addedFaEmails.push(toAdd.toLowerCase())
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'Invalid email',
        })
        return false
      }
      console.log(addedFaEmails)
      this.setState({ 
        addedFaEmails : addedFaEmails,
        faEmailIn : null,
        emailSectionDisplayed: true
      })
    } else if(type === 'bp'){
      const addedBpEmails = this.state.addedBpEmails
      const toAdd = this.state.bpEmailIn?.trim() || null
      if(toAdd && addedBpEmails.indexOf(toAdd) === -1 && toAdd.match(emailregex)){
        addedBpEmails.push(toAdd.toLowerCase())
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'Invalid email',
        })
        return false
      }
      this.setState({ 
        addedBpEmails : addedBpEmails,
        bpEmailIn : null,
        emailSectionDisplayed: true
      })
    }else if(type === 'fd'){
      const addedFdbkEmails = this.state.addedFdbkEmails
      const toAdd = this.state.fdbkEmailIn?.trim() || null
      if(toAdd && addedFdbkEmails.indexOf(toAdd) === -1 && toAdd.match(emailregex)){
        addedFdbkEmails.push(toAdd.toLowerCase())
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'Invalid email',
        })
        return false
      }
      this.setState({ 
        addedFdbkEmails : addedFdbkEmails,
        fdbkEmailIn : null,
        emailSectionDisplayed: true
      })
    }
  }  

  render() {

    const milestoneCols = [
      {
        title: 'Milestones',
        key: 'milestone',
        dataIndex: 'title',
        render: (index, record) => (
          this.state.editMilestoneRecord && this.state.editMilestoneRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editMilestone}
              onChange={(e) => this.setState({ editMilestone: e.target.value })}
            />
            :
            record.title ? record.title : '-'
        )
      },
      {
        title: 'Cutoff time ',
        key: 'dep',
        dataIndex: 'cutoff',
        render: (index, record) => (
          this.state.editMilestoneRecord && this.state.editMilestoneRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editCutoff}
              onChange={(e) => this.setState({ editCutoff: e.target.value })}
            />
            :
            record.cutoff ? record.cutoff : '-'
        )
      },
      {
        title: 'Type ',
        key: 'type',
        dataIndex: 'type',
        render: (index, record) => (
          this.state.editMilestoneRecord && this.state.editMilestoneRecord.id === record.id ?
            <Select size='small' placeholder='Select type' className='w-100' 
              onChange={(e) => this.setState({ editType: e })}
              value={this.state.editType}
            >
              <Option value='Dod'>DoD</Option>
              <Option value='Rebookings'>Rebookings</Option>
              {/* <Option value='No Rides' >No Rides</Option> */}
            </Select>
            :
            record.type ? record.type : '-'
        )
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: ( _, record, index) =>
          this.state.editMilestoneRecord && this.state.editMilestoneRecord.id === record.id  ?
            <div className='action-column'>
              <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateMilestone()} ><img src={Update} alt='Save' /></Tooltip>
              <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
            </div>
            :
            <div className='action-column'>
              {  record.priority !== 0 ? (
                <Tooltip title='Move up' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={upArrowIcon} alt='up-arrow' onClick={()=>this.editHandler( index, 'UP')} /></Tooltip>
              ): <Tooltip><img className='hidden-table-icon' src={''} /></Tooltip> }
              { this.state.dummyMilestones.length -1 !== record.prority ?
                <Tooltip title='Move down' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={downArrowIcon} alt='down-arrow' onClick={()=>this.editHandler( index, 'DOWN')} /></Tooltip>
                : <Tooltip><img className='hidden-table-icon' src={''} /></Tooltip> }
              <Tooltip title='Configure Reminder Email' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>  
                <img src={mailIcon} alt='configure-mail' 
                  onClick={()=> this.setState({ 
                    openEmail: true, 
                    existingEmails:record?.emails || [],
                    emailMSId: record?.id
                  })} className='email-icon' style={{ fontSize: '150%' }} /> </Tooltip>
              <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  ><img src={editIcon} alt='edit' onClick={()=>this.editTableForm(record, index)} /></Tooltip>
              <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteMilestone(record, index)} >
                <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
              </Popconfirm>
            </div>
          
      },
    ]

    const optionsCols = [
      {
        title: 'Options added',
        key: 'name',
        dataIndex: 'name',
        render: (_, record, index) => (
          /* (this.state.editOption && this.state.editOption.id === record.id) || */ (this.state.editIndex !== null && 
            parseInt(this.state.editIndex) === parseInt(index)) ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editOptionName}
              onChange={(e) => this.setState({ editOptionName: e.target.value })}
            />
            :
            record.name ? record.name : '-'
        )
      },
      {
        title: 'Comments required ',
        key: 'commentsReq',
        dataIndex: 'commentsReq',
        render: (_, record, index) => (
          (this.state.editOption && this.state.editOption.id === record.id) || (this.state.editIndex !== null && 
            parseInt(this.state.editIndex) === parseInt(index)) ?
            <Checkbox 
              onChange={(e) => this.setState({ editCommentsReq: e.target.checked }, console.log(e.target.checked) )}
              checked={this.state.editCommentsReq}
            /> :
            <span >
              {record?.commentsReq && 'Yes'}
              {!record?.commentsReq && 'No'}
            </span>
        )      
      },
      {
        title: 'Action required',
        key: 'actionon',
        dataIndex: 'actionon',
        render: (_, record, index) => (
          (this.state.editOption && this.state.editOption.id === record.id) || (this.state.editIndex !== null && 
            parseInt(this.state.editIndex) === parseInt(index)) ?
            <Checkbox 
              onChange={(e) => this.setState({ 
                editActionOn: e.target.checked,
                editActionMs: !e.target.checked ? [] : this.state.editActionMs,
                editActionType: !e.target.checked ? null : this.state.editActionType,
              }, console.log(e.target.checked) )}
              checked={this.state.editActionOn}
            /> :
            <span >
              {record?.actionon && 'Yes'}
              {!record?.actionon && 'No'}
            </span>
        )      
      },
      {
        title: 'Dependent Milestones',
        key: 'dependentms',
        dataIndex: 'actionms',
        render: (_, record, index) => (
          (this.state.editOption && this.state.editOption.id === record.id) || (this.state.editIndex !== null && 
            parseInt(this.state.editIndex) === parseInt(index)) ?
            <Select size='small' placeholder='Select Milestones' className='w-100'
              mode= 'multiple'
              onChange={(e) => this.setState({ editActionMs: e?.includes('RESET') ? [] : e })}
              value={this.state.editActionMs}
              disabled={!this.state.editActionOn}
            >
              {
                this.state.allMilestones?.map((item, index) => {
                  return (
                    <Option key={index} value={item.id} >{item.title}</Option>
                  )
                })
              }
            </Select> :
            <div>
              {
                record?.actionmsarr && record?.actionmsarr?.length > 0 ? record?.actionmsarr?.map((item, index) => {
                  return (
                    <p className='mb-0' key={index} >{item}</p>
                  )
                }): '-'
              }
            </div>
        )      
      },
      //ActionType Here
      {
        title: 'Action Type',
        key: 'actiontype',
        dataIndex: 'actiontype',
        render: (_, record, index) => (
          (this.state.editOption && this.state.editOption.id === record.id) || (this.state.editIndex !== null && 
            parseInt(this.state.editIndex) === parseInt(index)) ?
            <Select size='small' placeholder='Select Action Type' className='w-100'
              onChange={(e) => this.setState({ editActionType: e })}
              value={this.state.editActionType}
              disabled={!this.state.editActionOn}
            >
              <Option value='Turn Grey'>Turn Grey</Option>
              <Option value='Do Nothing'>Do Nothing</Option>
            </Select> :
            <span >
              {record?.actiontype || '-' }
            </span>
        )      
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (_, record, index) => (
          (this.state.editOption && this.state.editOption.id === record.id) || (this.state.editIndex !== null && 
            parseInt(this.state.editIndex) === parseInt(index)) ?
            <div className='action-column'>
              <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  onClick={()=>  this.addOptions('edit', index)}  ><img src={Update} alt='Save' /></Tooltip>
              <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  onClick={()=>this.clearOTableForm()}  ><span className='form-clear'>x</span></Tooltip>
            </div> :
            <div className='action-column'>
              <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} ><img src={editIcon} alt='edit' onClick={()=>this.editOptions(record, index)} /></Tooltip>
              <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No'  onConfirm={()=>this.deleteOption(record, index)}  >
                <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
              </Popconfirm>
            </div>
        )
      },
    ]

    const tableCols = [
      {
        title: 'Question',
        key: 'question',
        dataIndex: 'question',
        className: 'min-width-200',
        render: (index, record) =>(
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editQuestion}
              onChange={(e) => this.setState({ editQuestion: e.target.value })}
            /> : 
            <span >{
              record.details.question ? record.details.question : '-' }
            </span>
        )
      },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        render: (_, record) =>(
          <span >{
            record.details.type ? record.details.type : '-' }
          </span>
        )
      },
      {
        title: 'MCQ options',
        key: 'mcq',
        // dataIndex: 'mcq',
        render: ( _, record) => 
          <div>
            { record.details?.type === 'mcq' ? 
              <a className='view-options' onClick={() => this.setState({ 
                options: record.details.options, 
                question_id: record.id,
                question: record,
                // editRecord: record
              },()=>this.showOptions('Options')) } >View Options</a> 
              : 
              <p className='empty'> - </p> }
          </div>
      },
      {
        title: 'Comments req for',
        key: 'comments',
        dataIndex: 'comments',
        render: (_, record) => 
          this.state.editRecord && this.state.editRecord.id === record.id && (record?.details?.type !== 'mcq' && record.details?.type !== 'other') ?
            <Select size='small' placeholder='Comment req for' className='w-100' 
              onChange={(e) => this.setState({ editComments: e })}
              value={this.state.editComments}
            >
              <Option value='None'>None</Option>
              <Option value='Yes'>Yes</Option>
              <Option value='No'>No</Option>
            </Select>
            :
            <div>
              {record?.details.comment === 'Yes' ? <span>Yes</span> :
                record.details.comment === 'No' ? <span>No</span> :
                  record.details.comment === 'None' ? <span>-</span> :
                    '-' }
            </div>
          
      },
      {
        title: 'Nested questions for',
        key: 'nestedQues',
        dataIndex: 'nestedQues',
        render: (_, record) => 
          this.state.editRecord && this.state.editRecord.id === record.id && (record?.details?.type !== 'mcq' && record.details?.type !== 'other') ?
            <Select size='small' placeholder='Nested ques for' className='w-100' 
              onChange={(e) => this.setState({ editNested: e })}
              value={this.state.editNested}
            >
              <Option value='None'>None</Option>
              <Option value='Yes'>Yes</Option>
              <Option value='No'>No</Option>
            </Select>
            :
            <div>
              {record?.details.nested === 'Yes' && <Tooltip title='Add Nested Questions' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><span className='view-options' onClick={()=>this.openNested(record)} > Yes   </span></Tooltip>  }
              {record.details.nested === 'No' && <Tooltip title='Add Nested Questions' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><span className='view-options' onClick={()=>this.openNested(record)} > No   </span></Tooltip>}
              {(!record?.details.nested || (record.details.nested && record.details.nested === 'None' )) && <span> - </span> }
            </div>
      },
      {
        title: 'Milestone Action on',
        key: 'actionout',
        dataIndex: 'actionout',
        render: (_, record) => 
          this.state.editRecord && this.state.editRecord.id === record.id && (record?.details?.type !== 'mcq' && record.details?.type !== 'other') ?
            <Select size='small' placeholder='Mileston Action on' className='w-100' 
              onChange={(e) => this.setState({ editActionOut: e },()=>{
                if(e === 'None'){
                  this.setState({ 
                    editActionType: null, 
                    editActionMs: [] 
                  })
                }
              })}
              value={this.state.editActionOut}
            >
              <Option value='None'>None</Option>
              <Option value='Yes'>Yes</Option>
              <Option value='No'>No</Option>
            </Select>
            :
            <div>
              {record?.details.actionout === 'Yes' ? <span>Yes</span> :
                record.details.actionout === 'No' ? <span>No</span> :
                  record.details.actionout === 'None' ? <span>-</span> :
                    '-' }
            </div>
          
      },
      {
        title: 'Dependent Milestones',
        key: 'dependentms',
        dataIndex: 'actionms',
        className: 'min-width-200',
        render: (_, record) => 
          this.state.editRecord && this.state.editRecord.id === record.id && (record?.details?.type !== 'mcq' && record.details?.type !== 'other') ?
            <Select size='small' placeholder='Select Milestones' className='w-100'
              mode= 'multiple'
              onChange={(e) => this.setState({ editActionMs: e?.includes('RESET') ? [] : e })}
              value={this.state.editActionMs}
              disabled={this.state.editActionOut === 'None'}
            >
              {
                this.state.allMilestones?.map((item, index) => {
                  return (
                    <Option key={index} value={item.id} >{item.title}</Option>
                  )
                })
              }
            </Select>
            :
            <div>
              {
                record?.details.actionmsarr && record?.details.actionmsarr?.length > 0 ? record?.details.actionmsarr?.map((item, index) => {
                  return (
                    <p key={index} >{item}</p>
                  )
                }): '-'
              }
            </div>
      },
      {
        title: 'Action Type',
        key: 'actiontype',
        dataIndex: 'actiontype',
        render: (_, record) => 
          this.state.editRecord && this.state.editRecord.id === record.id && (record?.details?.type !== 'mcq' && record.details?.type !== 'other') ?
            <Select size='small' placeholder='Action' className='w-100' 
              onChange={(e) => this.setState({ editActionType: e })}
              value={this.state.editActionType}
              disabled={this.state.editActionOut === 'None'}
            >
              <Option value='Turn Grey'>Turn Grey</Option>
              <Option value='Do Nothing'>Do Nothing</Option>
            </Select>
            :
            <div>
              {record?.details.actiontype || '-' }
            </div>
          
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (record , index   ) =>
          this.state.editRecord && this.state.editRecord.id === record.id  ?
            <div className='action-column'>
              <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  onClick={()=>this.updateQuestion()}  ><img src={Update} alt='Save' /></Tooltip>
              <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  onClick={()=>this.clearQTableForm()}  ><span className='form-clear'>x</span></Tooltip>
            </div>
            :
            <div className='action-column'>
              <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  ><img src={editIcon} alt='edit' onClick={()=>this.editForm(record, index)}  /></Tooltip>
              <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteQuestion(record)} >
                <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
              </Popconfirm>
            </div>
          
      },
    ]

    const nestedTableCols = [
      {
        title: 'Question',
        key: 'question',
        dataIndex: 'question',
        className: 'min-width-200',
        render: (index, record) =>(
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editQuestion} 
              onChange={(e) => this.setState({ editQuestion: e.target.value })}
            /> : 
            <span >{
              record.details.question ? record.details.question : '-' }
            </span>
        )
      },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        render: (_, record) =>(
          <span >{
            record.details.type ? record.details.type : '-' }
          </span>
        )
      },
      {
        title: 'MCQ options',
        key: 'mcq',
        // dataIndex: 'mcq',
        render: ( _, record) => 
          <div>
            { record.details?.type === 'mcq' ? 
              <a className='view-options' onClick={() => this.setState({ 
                nestedoptions: record.details.options, 
                question_id: record.id,
                question: record 
              },()=>this.showOptions('Nested')) } >View Options</a> 
              : 
              <p className='empty'> - </p> }
          </div>
      },
      {
        title: 'Comments req for',
        key: 'comments',
        dataIndex: 'comments',
        render: (_, record) => 
          this.state.editRecord && this.state.editRecord.id === record.id && (record?.details?.type !== 'mcq' && record?.details?.type !== 'other' ) ?
            <Select size='small' placeholder='Comment req for' className='w-100' 
              onChange={(e) => this.setState({ editComments: e })}
              value={this.state.editComments}
            >
              <Option value='None'>None</Option>
              <Option value='Yes'>Yes</Option>
              <Option value='No'>No</Option>
            </Select>
            :
            <div>
              {record?.details.comment === 'Yes' ? <span>Yes</span> :
                record.details.comment === 'No' ? <span>No</span> :
                  record.details.comment === 'None' ? <span>-</span> :
                    '-' }
            </div>
      },
      {
        title: 'Milestone Action on',
        key: 'actionout',
        dataIndex: 'actionout',
        render: (_, record) => 
          this.state.editRecord && this.state.editRecord.id === record.id && (record?.details?.type !== 'mcq' && record?.details?.type !== 'other' ) ?
            <Select size='small' placeholder='Milestone Action on' className='w-100' 
              onChange={(e) => this.setState({ editActionOut: e })}
              value={this.state.editActionOut}
            >
              <Option value='None'>None</Option>
              <Option value='Yes'>Yes</Option>
              <Option value='No'>No</Option>
            </Select>
            :
            <div>
              {record?.details.actionout === 'Yes' ? <span>Yes</span> :
                record.details.actionout === 'No' ? <span>No</span> :
                  record.details.actionout === 'None' ? <span>-</span> :
                    '-' }
            </div>
      },
      {
        title: 'Dependent Milestones',
        key: 'dependentms',
        dataIndex: 'actionms',
        render: (_, record) => 
          this.state.editRecord && this.state.editRecord.id === record.id && (record?.details?.type !== 'mcq' && record?.details?.type !== 'other' ) ?
            <Select size='small' placeholder='Select Milestones' className='w-100'
              mode= 'multiple'
              onChange={(e) => this.setState({ editActionMs: e?.includes('RESET') ? [] : e })}
              value={this.state.editActionMs}
            >
              {
                this.state.allMilestones?.map((item, index) => {
                  return (
                    <Option key={index} value={item.id} >{item.title}</Option>
                  )
                })
              }
            </Select>
            :
            <div>
              {
                record?.details.actionmsarr && record?.details.actionmsarr?.length > 0 ? record?.details.actionmsarr?.map((item, index) => {
                  return (
                    <p key={index} >{item}</p>
                  )
                }): '-'
              }
            </div>
      },
      {
        title: 'Action Type',
        key: 'actiontype',
        dataIndex: 'actiontype',
        render: (_, record) => 
          this.state.editRecord && this.state.editRecord.id === record.id && (record?.details?.type !== 'mcq' && record?.details?.type !== 'other' ) ?
            <Select size='small' placeholder='Milestone Action on' className='w-100' 
              onChange={(e) => this.setState({ editActionType: e })}
              value={this.state.editActionType}
            >
              <Option value='Turn Grey'>Turn Grey</Option>
              <Option value='Do Nothing'>Do Nothing</Option>
            </Select>
            :
            <div>
              {record?.details.actiontype || '-' }
            </div>
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (record , index   ) =>
          this.state.editRecord && this.state.editRecord.id === record.id  ?
            <div className='action-column'>
              <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  onClick={()=>this.updateQuestion()}  ><img src={Update} alt='Save' /></Tooltip>
              <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  onClick={()=>this.clearQTableForm()}  ><span className='form-clear'>x</span></Tooltip>
            </div>
            :
            <div className='action-column'>
              <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  ><img src={editIcon} alt='edit' onClick={()=>this.editForm(record, index)} /></Tooltip>
              <Popconfirm placement='top' title={constants.DeleteText}  okText='Yes' cancelText='No' onConfirm={()=>this.deleteQuestion(record)}>
                <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  ><img src={deleteIcon} alt='delete' /></Tooltip>
              </Popconfirm>
            </div>
      },
    ]

    // const nestedCols = [
    //   {
    //     title: 'Question',
    //     key: 'question',
    //     dataIndex: 'question'
    //   },
    //   {
    //     title: 'Type',
    //     key: 'type',
    //     dataIndex: 'type' 
    //   },
    //   {
    //     title: 'MCQ options',
    //     key: 'mcq',
    //     dataIndex: 'mcq',
    //     render: ( _, record) => 
    //       <div>
    //         { record.mcq === true || record.type === 'mcq' ? 
    //           <a className='view-options' onClick={() => this.setState({ 
    //             nestedoptions: record.details.options, 
    //             question_id: record.id,
    //             question: record 
    //           },()=>this.showOptions('Nested')) } >View Options</a> 
    //           : 
    //           <p className='empty'> - </p> }
    //       </div>
    //   },
    //   {
    //     title: 'Comments req for',
    //     key: 'comments',
    //     dataIndex: 'comments',
    //     render: (_, record) => 
    //       <div>
    //         { record.details.comment === 'Yes' ? <p className='empty'> Yes </p> : record.details.comment === 'No' ? <p className='empty'>No</p> : 
                 
    //           <span className='empty'>
               
    //             <CloseOutlined />
    //           </span>
    //         }
    //       </div>
    //   },
    //   {
    //     title: 'Action',
    //     key: 'action',
    //     align: 'center',
    //     render: () =>
    //       <div>
    //         <div className='action-column'>
    //           <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  ><img src={editIcon} alt='edit' /></Tooltip>
    //           <Popconfirm placement='top' title={constants.DeleteText}  okText='Yes' cancelText='No'>
    //             <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} ><img src={deleteIcon} alt='delete' /></Tooltip>
    //           </Popconfirm>
    //         </div>
    //       </div>
    //   },
    // ]

    // const nestedData = [
    //   {    
    //     key:1, 
    //     question: 'Was a Build Plan completed ?',
    //     type: 'yes_no' ,
    //     mcq: false,
    //     comments: '-',
    //     commentsFor: 'No'
    //   }
    // ]

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // eslint-disable-next-line no-console
        console.log(selectedRows[0], 'dummym')
        this.setState({
          selMilestone: cloneDeep(selectedRows[0]),
          questions:  selectedRows[0]?.questions,
          selectedMilestone: cloneDeep(selectedRows[0]?.title),
          amber: cloneDeep(selectedRows[0]?.amber)
          // prodWorkArea: station+' / '+cloneDeep(selectedRows[0]).area_name,
          // locWorkArea: station+' / '+cloneDeep(selectedRows[0]).area_name
        }, () => {
          // eslint-disable-next-line no-console
          console.log(this.state.questions, 'dummy')
          this.fetchQuestions()
        })
      },
    
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      })
    }

    return (
      <div>
        <Row gutter={[24, 24]}  className='contentrow-height'>
          <Col xs={24} sm={24} md={24} lg={24} xl={10} className=''>
            <Card
              title={'Flight Capacity Planning Milestones'}
              className='custom-card h-100 milestone-card'
              extra = {<Button type='primary' className='custom-button mr-0' onClick={ this.fetchGlobalDetails }>Configure Emails</Button>}
              headStyle ={{ 
                paddingTop: '24px',
                paddingBottom: '0px' 
              }}
            >
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw'>
                <Title level={5} className='mb-0'>Flight Capacity Planning Milestones</Title>
                <Button type='primary' className='custom-button mr-0' onClick={ this.fetchGlobalDetails }>Configure Emails</Button>
              </Col> */}
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Form
                    layout= 'vertical'
                    className='position-relative'
                  >
                    <Row gutter={[18, {
                      xs: 24,
                      lg: 0 
                    }]} className='mb-4'>
                      {/* <label className='custom-label label-border radio-text label-1'>Select Milestone</label> */}
                      <Col xs={24} sm={10} md={8} className='fields'>
                        <label>Enter Milestone</label>
                        <Input 
                          style={{ width: '100%' }}
                          name='name'
                          value={this.state.milestoneAdded}
                          onChange={(e) => this.setState({ milestoneAdded: e.target.value })}
                        />
                      </Col>
                      <Col xs={24} flex='1 0 28%' className='fields'>
                        <label className='space-nowrap d-inlineblock'>Cutoff time (mins-dept)</label>
                        <Input
                          size='small'
                          placeholder=''
                          value={this.state.depTime}
                          onChange={(e) => this.setState({ depTime: e.target.value })}
                        />
                  
                      </Col>
                      <Col xs={24} flex='1 0 24%' className='fields'>
                        <label>Type</label>
                        <Select size='small' placeholder='Select type' className='w-100' 
                          onChange={(e) => this.setState({ type: e })}
                          value={this.state.type}
                        >
                          <Option value='Dod'>DoD</Option>
                          <Option value='Rebookings'>Rebookings</Option>
                          {/* <Option value='No Rides' >No Rides</Option> */}
                        </Select>
                  
                      </Col>
                      <Col flex='0 0 50px' className='text-center ml-auto pl-0'>
                        <label className='custom-label transparent'>-</label>
                        <Button htmlType='submit' type='ghost' className='image-btn' onClick={() => this.addMilestone()}>
                          <Tooltip title='Add a Milestone' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      rowKey='id'
                      className='custom-table milestone-table w-100'
                      dataSource={this.state.dummyMilestones}
                      columns={milestoneCols}
                      pagination={false}
                      rowSelection={{
                        type: 'radio',
                        ...rowSelection,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={14} className=''>
            <Row gutter={[24, 24]} className='h-100 qust-wrapper'>
              {/* <Col xs={24} className='checklist-col'>
                <Card
                  title={'Define checklist' + (this.state.selectedMilestone ? ` - ${this.state.selectedMilestone}` : '') }
                  className='custom-card h-100'
                >
                  <Row gutter={[24, 12]} className=''>
                    <Col xs={24} xl={'500px'} className='mb-3'>
                      <label className='custom-label label-border'>Choose a Type of Question</label>
                      <Radio.Group className='f-spacebtw f-wrap radio-nowrap' disabled={!this.state.selMilestone} onChange={(e) => this.radioSelect(e.target.value)} value={this.state.questionType} flex='auto'>
                        <Radio defaultChecked={false} value={'yes/no'}>Yes/No</Radio>
                        <Radio value={'mcq'} className='radio-space'>Multiple Choice Question</Radio>
                        <Radio value={'other'} className='radio-space'> Other</Radio>
                      </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={this.state.questionType && this.state.questionType === 'yes/no'  ? 10 : 8}  xl={10} xxl={12} className='fields'>
                      <label>Enter Question</label>
                      <Input
                        size='small'
                        placeholder=''
                        disabled={!this.state.selMilestone}
                        value={this.state?.addQuestion?.question}
                        onChange={
                          (e)=> this.setState({ 
                            addQuestion: {
                              ...this.state.addQuestion, 
                              question: e.target.value 
                            } 
                          })
                        }
                      />
                    </Col>  */}
              {/* { this.state.questionType && this.state.questionType === 'yes/no' ?
                  <Col xs={8} className='fields'>
                  </Col> 
                  : '' } */}
              {/* { this.state.questionType && this.state.questionType === 'yes/no' ?
                      <Fragment>
                        <Col xs={24} flex='1 0 20%' xxl={4} className='fields'>
                          <label className='space-nowrap d-inlineblock'>Comments req for </label>
                          <Select size='small' placeholder='Select process' className='w-100'
                            onChange={(e) => this.setState({ comment: e })}
                            value={this.state.comment}
                            disabled={!this.state.selMilestone}
                          >
                            <Option value='None' >None</Option>
                            <Option value='Yes' >Yes</Option>
                            <Option value='No' >No</Option>
                          </Select>
                        </Col>
                        <Col xs={24} flex='1 0 18%' xxl={4} className='fields'>
                          <label className='space-nowrap d-inlineblock'>Nested questions on </label>
                          <Select size='small' placeholder='Select process' className='w-100' 
                            onChange={(e) => this.setState({ nested: e })}
                            value={this.state.nested}
                            disabled={!this.state.selMilestone}
                          >
                            <Option value='None' >None</Option>
                            <Option value='Yes' >Yes</Option>
                            <Option value='No' >No</Option>
                          </Select>
                        </Col> 
                        <Col xs={24} md={6} flex='1 0 18%' xxl={4} className='fields'>
                          <label className='space-nowrap d-inlineblock'>Milestone Action on</label>
                          <Select size='small' placeholder='Select process' className='w-100'
                            onChange={(e) => this.setState({ 
                              actionout: e,
                              actionMS: !e || e === 'None' ? [] : this.state.actionMS,
                              actiontype: !e || e === 'None'? null : this.state.actiontype,
                            })}
                            value={this.state.actionout}
                            disabled={!this.state.selMilestone}
                          >
                            <Option value='None' >None</Option>
                            <Option value='Yes' >Yes</Option>
                            <Option value='No' >No</Option>
                          </Select>
                        </Col>
                        <Col xs={24} sm={24} md={10} xl={10} xxl={12} className='fields'>
                          <label>Dependent Milestones</label>
                          <Select size='small' placeholder='Select Milestones' className='w-100'
                            mode= 'multiple'
                            onChange={(e) => this.setState({ actionms: e?.includes('RESET') ? [] : e })}
                            value={this.state.actionms}
                            disabled={!this.state.selMilestone || (!this.state.actionout || this.state.actionout === 'None')}
                          >
                            {
                              this.state.allMilestones?.map((item, index) => {
                                return (
                                  <Option key={index} value={item.id} >{item.title}</Option>
                                )
                              })
                            }
                          </Select>
                        </Col>
                        <Col xs={24} md={5} flex='1 0 19.5%' xl={5} xxl={4} className='fields'>
                          <label>Action Type</label>
                          <Select size='small' placeholder='Select Action Type' className='w-100'
                            onChange={(e) => this.setState({ actiontype: e })}
                            value={this.state.actiontype}
                            disabled={!this.state.selMilestone || (!this.state.actionout || this.state.actionout === 'None')}
                          >
                            <Option value='Turn Grey'>Turn Grey</Option>
                            <Option value='Do Nothing'>Do Nothing</Option>
                          </Select>
                        </Col>
                      </Fragment>
                      : '' } */}
              {/* <Col className='addOps'>
                  { this.state.questionType && this.state.questionType === 'yes/no' ?
                    <Button type='primary' className='custom-button mr-0' onClick={this.openNested} disabled={this.state.nested !== 'Yes'} >Add Nested Question</Button>
                    : '' }
                </Col> */}
                
              {/* { this.state.questionType && this.state.questionType === 'mcq' ?
                      <Col xs  className='addOps'>
                        <Button type='primary' className='custom-button mr-0' onClick={()=>this.showOptions('Options')} disabled={!this.state.selMilestone}>Add Options</Button>
                      </Col>
                      : '' }
                
                    <Col flex='50px' className='text-center ml-auto'>
                      <label className='custom-label transparent'>-</label>
                      <Button htmlType='submit' type='ghost' className='image-btn' onClick={() => this.addQuestion()} disabled={!this.state.selMilestone}>
                        <Tooltip title='Add a Question' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                      </Button>
                    </Col>
                  </Row>
                </Card> 
              </Col> */}
              <Col xs={24} className='qust-col'>
                <Card
                  title={'Define Checklist Questions' + (this.state.selectedMilestone ? ` - ${this.state.selectedMilestone}` : '') }
                  className='custom-card flex-auto qust-card'
                  visibility= {!this.state.disabledInput}
                  extra = {<Button type='primary' className='custom-button mr-0' disabled={!this.state.selMilestone} onClick={ () => this.openQuestionForm() }>Add Question</Button>}
                >
                  <Row className=''> 
                    <Col xs flex={'auto'} className='amber-indicator f-justify-end'>
                      <span> Amber Indicator:
                        <InputNumber
                          size='small'
                          min={0}
                          className='w-50px small-input mx-1'
                          value={this.state.amber}
                          onChange={(e) => this.setState({ amber: e })}
                          controls={false}
                          disabled= {!this.state.selMilestone}
                        />
                        <span className='mr-3'> mins before cutoff time</span></span>
                      {/* </Col>
                  <Col > */}
                      <Button className='custom-icon-button custom-sm-icon-button ml-2' icon={<SaveOutlined />} onClick={ () => this.updateMilestone('amber')}></Button>
                    </Col>
                    <Col xs={24} className='border-card'>
                      <div className='table-outer'>
                        <Table
                          className='custom-table qust-table'
                          dataSource={this.state.questions}
                          columns={tableCols}
                          pagination={false}
                          // expandable={{
                          //   // eslint-disable-next-line no-unused-vars
                          //   expandedRowRender: record => <Table className='custom-table nested' columns={nestedCols} dataSource={nestedData} pagination={false} />,
                          //   rowExpandable: (record) => record.nested
                          // }}
                        />
                      </div>
                  
                    </Col>
                  </Row>
                </Card>    
              </Col>
            </Row>
          </Col>
          {/* <Col xs={24} sm={24} md={12} className='f-column ' > */}
          {/* <Card
              //   title={'Added Questions'}
              className='custom-card flex-auto'
              style={{ marginTop: '15px' }}
              visibility= {!this.state.disabledInput}
            >
              <Row className='mb-4'> 
                <div className='f-spacebtw'>
                  <span className='custom-table-header'>
                  Table
                  </span>
                  <div className='amber-indicator'>
                    <span> Amber Indicator:
                      <InputNumber
                        size='small'
                        min={0}
                        className='w-50px small-input mx-1'
                        value={this.state.amber}
                      // onChange={(e) => this.setState({ amber2: e })}
                      // controls={false}
                      />
                      <span className=''> mins before cutoff time</span></span>
                  </div>
                </div>
                <Col xs={24} className='border-card'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table rebooking-table'
                      dataSource={this.state.questions}
                      columns={tableCols}
                      pagination={false}
                      expandable={{
                        // eslint-disable-next-line no-unused-vars
                        expandedRowRender: record => <Table className='custom-table nested' columns={nestedCols} dataSource={nestedData} pagination={false} />,
                        rowExpandable: (record) => record.nested
                      }}
                    />
                  </div>
                  
                </Col>
              </Row>
            </Card>     */}
          {/* </Col> */}
          
        </Row>
        <Modal
          title='Add Multiple Choice Options'
          open={this.state.showOptions}
          // onOk={this.optionsHide}
          onCancel={this.optionsHide}
          className='custom-modal pb-0 add-mcq'
          width={'50%'}
          centered
          footer={false}
          zIndex={1001}
        >
          <Row gutter={[24, 24]} className='with-table'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
              <Form
                layout='vertical'
                className='position-relative'
              >
                <Row
                  gutter={[{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 24
                  }, {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12
                  },]} className='modal-fields'>
                  <Col xs={24} sm={24} md={22} >
                    <label>Enter an option</label>
                    <Input
                      size='small'
                      placeholder=''
                      onChange={(e) => this.setState({ option: e.target.value })}
                      value={this.state.option}
                    />
                  </Col> 
                  <Col xs={24} sm={24} md={12}  className='mt-auto'>

                    <Checkbox
                      // checked={this.state.showRedFlights}
                      // onChange={(e) => this.setState({
                      //   showRedFlights: e.target.checked,
                      //   flightRow: null,
                      //   awbRow: null,
                      //   ULDGridData: [],
                      //   locationFilters: [],
                      //   locationValue: '',
                      //   prodFilters: [],
                      //   prodValue: '',
                      //   productDropdownData: [],
                      //   locationDropdownData: [],
                      // })}
                      onChange={() => this.setState({ commentsRequired: !this.state.commentsRequired })}
                      checked={this.state.commentsRequired}
                    >
                    Additional Comments required
                    </Checkbox>
                  </Col>
                  <Col xs={24} sm={24} md={12}  className='mt-auto'>
                    <Checkbox
                      onChange={() => this.setState({ 
                        actionOn: !this.state.actionOn,
                        actionMS: !this.state.actionOn === false ? [] : this.state.actionMS,
                        actionType: !this.state.actionOn === false ? null : this.state.actionType,
                      })}
                      checked={this.state.actionOn}
                    >
                    Action on milestone
                    </Checkbox>
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <label>Select Milestone</label>
                    <Select size='small' placeholder='Select Milestones' className='w-100'
                      mode= 'multiple'
                      onChange={(e) => this.setState({ actionMS: e?.includes('RESET') ? [] : e })}
                      value={this.state.actionMS}
                      disabled={!this.state.actionOn}
                    >
                      {
                        this.state.allMilestones?.map((item, index) => {
                          return (
                            <Option key={index} value={item.id} >{item.title}</Option>
                          )
                        })
                      }
                    </Select>
                  </Col>
                  <Col xs={24} sm={20} md={10}>
                    <label>Select Action Type</label>
                    <Select size='small' placeholder='Select Action Type' className='w-100'
                      onChange={(e) => this.setState({ actionType: e })}
                      value={this.state.actionType}
                      disabled={!this.state.actionOn}
                    >
                      <Option value='Turn Grey'>Turn Grey</Option>
                      <Option value='Do Nothing'>Do Nothing</Option>
                    </Select>
                  </Col>
                  <Col flex='40px' className='text-center ml-auto pl-0'>
                    <label className='custom-label transparent'>-</label>
                    <Button type='ghost' className='ml-auto image-btn' >
                      <Tooltip title={'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' onClick={ () => this.addOptions('add', null) } /></Tooltip>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div className='table-outer'>
                <Table
                  className='custom-table exceptionlist-table w-100'
                  dataSource={this.state.optionType === 'Options' ? this.state.options : this.state.nestedoptions}
                  columns={optionsCols}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal
          title='Add Nested Questions'
          visible={this.state.showNested}
          onOk={this.closeNested}
          onCancel={this.closeNested}
          className='custom-modal pb-0'
          width={'60%'}
          centered
          footer={false}
        >
          <Row gutter={[{
            xs: 0,
            sm: 0,
            md: 24 ,
            lg: 24
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 48
          },]} className='with-table'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
              <Row gutter={[24, 12]}>
                {/* <Col xs={24}>
                  <label className='custom-label label-border'>Choose a Type of Question</label>
                  <Radio.Group onChange={(e) => this.setState({ nestedQuestionType: e.target.value }) } value={this.state.nestedQuestionType}  className='radio-nowrap w-100'>  
                    <Space size='large' wrap className='f-spacebtw'>
                      <Radio defaultChecked={false} value={'yes/no'}>Yes/No</Radio>
                      <Radio value={'mcq'} className='radio-space'>Multiple Choice Question</Radio>
                      <Radio value={'other'} className='radio-space'> Other</Radio>
                    </Space>
                  </Radio.Group>
                </Col> */}
                <Col xs={24}>
                  <Segmented 
                    options={[ 
                      {
                        label: 'Yes/No',
                        value: 'yes/no'
                      },
                      {
                        label: 'Multiple Choice Question',
                        value: 'mcq'
                      },
                      {
                        label: 'Other',
                        value: 'other'
                      }
                    ]} 
                    defaultValue='Yes/No' 
                    value={this.state.nestedQuestionType} 
                    onChange={(e) => this.setState({ nestedQuestionType: e }) } 
                    className='email-segs' 
                    block 
                  />
                </Col>
                <Col xs={24} sm={24} md={this.state.nestedQuestionType && this.state.nestedQuestionType === 'yes/no'  ? 12 : 18} className='fields'>
                  <label>Enter Question</label>
                  <Input
                    size='small'
                    placeholder=''
                    value={this.state?.nestedQuestion }
                    onChange={(e)=> this.setState({  nestedQuestion: e.target.value }) }
                  />
                </Col> 
                { this.state.nestedQuestionType && this.state.nestedQuestionType === 'yes/no' ?
                  <Col xs={24} sm={24} md={5} flex='1 0 145px'  /*xxl={5}*/ className='fields'>
                    <label className='space-nowrap'>Comments req for</label>
                    <Select size='small' placeholder='Select process' className='w-100' 
                      onChange={(e) => this.setState({ nestedComment: e })}
                      value={this.state.nestedComment}
                    >
                      <Option value='None' >None</Option>
                      <Option value='Yes' >Yes</Option>
                      <Option calue='No' >No</Option>
                    </Select>
                  </Col> 
                  : '' }
                { this.state.nestedQuestionType && this.state.nestedQuestionType === 'yes/no' ?
                  <Col xs={24} sm={24} md={5} flex='1 0 145px'  /*xxl={5}*/ className='fields'>
                    <label className='space-nowrap'>Milestone Action on</label>
                    <Select size='small' placeholder='Select Option' className='w-100' 
                      onChange={(e) => this.setState({ nestedActionOut: e })}
                      value={this.state.nestedActionOut}
                    >
                      <Option value='None' >None</Option>
                      <Option value='Yes' >Yes</Option>
                      <Option calue='No' >No</Option>
                    </Select>
                  </Col> 
                  : '' }
                { this.state.nestedQuestionType && this.state.nestedQuestionType === 'yes/no' ?
                  <Col xs={24} sm={24} md={12} className='fields'>
                    <label className='space-nowrap'>Dependent Milestones</label>
                    <Select size='small' placeholder='Select Milestones' className='w-100'
                      mode= 'multiple'
                      onChange={(e) => this.setState({ nestedActionMs: e?.includes('RESET') ? [] : e })}
                      value={this.state.nestedActionMs}
                    >
                      {
                        this.state.allMilestones?.map((item, index) => {
                          return (
                            <Option key={index} value={item.id} >{item.title}</Option>
                          )
                        })
                      }
                    </Select>
                  </Col> 
                  : '' }
                { this.state.nestedQuestionType && this.state.nestedQuestionType === 'yes/no' ?
                  <Col xs={24} sm={20} md={5} flex='1 0 130px'  className='fields'>
                    <label className='space-nowrap'>Action Type</label>
                    <Select size='small' placeholder='Select Action Type' className='w-100' 
                      onChange={(e) => this.setState({ nestedActionType: e })}
                      value={this.state.nestedActionType}
                    >
                      <Option value='Turn Grey'>Turn Grey</Option>
                      <Option value='Do Nothing'>Do Nothing</Option>
                    </Select>
                  </Col> 
                  : '' }
                { this.state.nestedQuestionType && this.state.nestedQuestionType === 'mcq' ?
                  <Col xs className='addOps'>
                    <Button type='primary' className='custom-button mr-0' onClick={()=>this.showOptions('Nested')}>Add Options</Button>
                  </Col>
                  : '' }
                <Col flex='25px' className='text-center ml-auto'>
                  <label className='custom-label transparent'>-</label>
                  <Button type='ghost' className='ml-auto image-btn' >
                    <Tooltip title={'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' onClick={() => this.addNestedQuestion()} /></Tooltip>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div className='table-outer'>
                <Table
                  className='custom-table exceptionlist-table w-100'
                  dataSource={this.state.nestedDataSource}
                  columns={nestedTableCols}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal
          title='Configure Reminder Email Address'
          destroyOnClose
          visible={this.state.openEmail}
          footer={[
            <>
              <Space size={'middle'} className='py-2 pr-3 mr-1'>
                <Button onClick={() => this.updateMilestone('email')} className='custom-button w-40'>Save</Button>
                <Button onClick={this.saveEmail} className='custom-button w-40'>Close</Button>
              </Space>
            </>
          ]}
          width={'600px'}
          centered
          className='custom-modal pb-0'
          onCancel={this.saveEmail}
        >
          <Row gutter={[{
            xs: 0,
            sm: 12,
            md: 12,
            lg: 12
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24
          },]} className='with-table'>
            <Col
              xs={22}
              className=''
            >
              <label className='mb-0'>
                Add Email address
              </label>
              <Input
                // mode='tags'
                // onSearch={(e) => this.setState({ exclusionSearch: e?.toUpperCase() })}
                // onSelect={(e) => this.handleOnSelectExclusion(e)}
                onChange={(e) => this.setState({ emailAdded: e.target.value })}
                // onDeselect={(e) => this.popExclusion(e)}
                // tokenSeparators={[',',' ','\t']}
                style={{ width: '100%' }}
                value= {this.state.emailAdded}
                // searchValue={this.state.exclusionSearch}
              />
            </Col>
            <Col xs={2} className='text-center ml-auto'>
              <label className='custom-label transparent'>-</label>
              <Button htmlType='submit' type='ghost' className='image-btn' onClick={() => this.addEmail('ms') }>
                <Tooltip title='Add email' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
              </Button>
            </Col>
            {this.state.emailSectionDisplayed ? 
              <Col xs={24}>
                <label className='d-block mb-1'>Click the save button to save the emails: </label>
                {this.state.addedEmails.map((email, index) => 
                  <Tag bordered={false} closable key = {index} onClose={(e)=>{
                    e.preventDefault()
                    const addedEmails = this.state.addedEmails.filter((item) => item !== email)
                    this.setState({ addedEmails })
                  }}> {email} </Tag>
                ) 
                } 
              </Col> : '' }
            {/* <Col xs={24}>
              <Button onClick={() => this.updateMilestone('email')} className='custom-button w-100'>Save</Button>
            </Col> */}
          </Row>
          <div className='email-div'>
            <Col xs={24} className='mb-0 added-emails '>
              <label>Added emails:</label>
            </Col>
            <Space className='tag-holder1'>
              {this.state.existingEmails.map((email, index) => 
                <Tag bordered={false} closable key = {index} onClose={(e)=>{
                  e.preventDefault()
                  const existingEmails = this.state.existingEmails.filter((item) => item !== email)
                  this.setState({ existingEmails })
                }} > {email} </Tag>
              )} 
            </Space>
          </div>

        </Modal>
        <Modal
          title='Configure DoD Command Center Emails'
          visible={this.state.showEmails}
          // onOk={this.optionsHide}
          onCancel={()=> this.setState({ 
            showEmails : false,
            emailSectionDisplayed: false
          })}
          className='custom-modal pb-0 add-mcq'
          width={'700px'}
          centered
          footer={[
            <>
              <Space size={'middle'} className='py-2 pr-3 mr-1'>
                <Button onClick={() => this.updateEmails()} className='custom-button w-40'>Save</Button>
                <Button onClick={()=> this.setState({ showEmails : false })} className='custom-button w-40'>Close</Button>
              </Space>
            </>
          ]}
        >
          <Row className='with-table'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
              <Form
                layout='vertical'
                className='position-relative'
              >
                <Row
                  gutter={[0,0]} className='modal-fields'>
                  <Col
                    xs={24}
                    className=''
                  >
                    {/* <ConfigProvider
                      theme={{
                        components: {
                          Segmented: {
                            itemSelectedBg : 'var(--primaryblue)',
                            itemSelectedColor: 'white'
                          },
                        },
                      }}
                    > */}
                    <Segmented options={['Build Plan', 'No-Ride', 'Flight Audit', 'Feedback Form']} defaultValue='No-Ride' value={this.state.selSegment} onChange={(e) => this.setState({ 
                      selSegment: e,
                      emailSectionDisplayed:false  
                    }) } className='email-segs' block />
                    {/* </ConfigProvider> */}
                  </Col>
                  {this.state.selSegment === 'No-Ride' ? 
                    <Row gutter ={[0,0]} className='email-row'>
                      <Col xs={22} >
                        <label className='mb-0'>
                      Add No-Ride Email address
                        </label>
                        <Input
                          onChange={(e) => this.setState({ nrEmailIn: e.target.value })}
                          style={{ width: '100%' }}
                          value= {this.state.nrEmailIn}
                        />
                      </Col>
                      <Col xs={2} className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button htmlType='submit' type='ghost' className='image-btn' onClick={() => this.addEmail('nr') }>
                          <Tooltip title='Add email' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                      
                      { this.state.emailSectionDisplayed ? 
                        <Col xs={24} className='save-section'>
                          <label className='d-block mb-1 save-text'>Click the save button to save the emails: </label>
                          {this.state.addedNrEmails.map((email, index) => 
                            <Tag bordered={false} closable key = {index} onClose={(e)=>{
                              e.preventDefault()
                              const addedNrEmails = this.state.addedNrEmails.filter((item) => item !== email)
                              this.setState({ addedNrEmails })
                            }}> {email} </Tag>
                          ) 
                          } </Col> 
                        : '' }
                      
                      {/* <Col xs className='noride-submit update-btn'>
                        <Button onClick={() => this.updateEmails()} className='custom-button w-40'>Update</Button>
                      </Col> */}
                      <div className='email-div'>
                        <Col xs={24} className='added-emails '>
                          <label>Added emails:</label>
                        </Col>
                        <Space className='tag-holder1'>
                          {this.state.existingNrEmails.map((email, index) => 
                            <Tag bordered={false} closable key = {index} onClose={(e)=>{
                              e.preventDefault()
                              const existingNrEmails = this.state.existingNrEmails.filter((item) => item !== email)
                              this.setState({ existingNrEmails })
                            }} > {email} </Tag>
                          )} 
                        </Space> </div>
                    </Row> : '' }
                  {this.state.selSegment === 'Flight Audit' ? 
                    <Row gutter ={[0,0]} className='email-row'>
                      <Col xs={22} >
                        <label className='mb-0'>
                        Add Flight Audit Email address
                        </label>
                        <Input
                          onChange={(e) => this.setState({ faEmailIn: e.target.value })}
                          style={{ width: '100%' }}
                          value= {this.state.faEmailIn}
                        />
                      </Col>
                      <Col xs={2} className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button htmlType='submit' type='ghost' className='image-btn' onClick={() => this.addEmail('fa') }>
                          <Tooltip title='Add email' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                  
                      { this.state.emailSectionDisplayed ? 
                        <Col xs={24} className='save-section'>
                          <label className='d-block mb-1 save-text'>Click the save button to save the emails: </label>
                          {this.state.addedFaEmails.map((email, index) => 
                            <Tag bordered={false} closable key = {index} onClose={(e)=>{
                              e.preventDefault()
                              const addedFaEmails = this.state.addedFaEmails.filter((item) => item !== email)
                              this.setState({ addedFaEmails })
                            }}> {email} </Tag>
                          ) 
                          } </Col> 
                        : '' }
                  
                      {/* <Col xs className='noride-submit update-btn'>
                    <Button onClick={() => this.updateEmails()} className='custom-button w-40'>Update</Button>
                  </Col> */}
                      <div className='email-div'>
                        <Col xs={24} className='added-emails '>
                          <label>Added emails:</label>
                        </Col>
                        <Space className='tag-holder1'>
                          {this.state.existingFaEmails.map((email, index) => 
                            <Tag bordered={false} closable key = {index} onClose={(e)=>{
                              e.preventDefault()
                              const existingFaEmails = this.state.existingFaEmails.filter((item) => item !== email)
                              this.setState({ existingFaEmails })
                            }} > {email} </Tag>
                          )} 
                        </Space> </div>
                    </Row>
                    : '' }
                  {this.state.selSegment === 'Build Plan' ? 
                    <Row gutter ={[0,0]} className='email-row'>
                      <Col xs={22} >
                        <label className='mb-0'>
                        Add Build Plan Email address
                        </label>
                        <Input
                          onChange={(e) => this.setState({ bpEmailIn: e.target.value })}
                          style={{ width: '100%' }}
                          value= {this.state.bpEmailIn}
                        />
                      </Col>
                      <Col xs={2} className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button htmlType='submit' type='ghost' className='image-btn' onClick={() => this.addEmail('bp') }>
                          <Tooltip title='Add email' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                  
                      { this.state.emailSectionDisplayed ? 
                        <Col xs={24} className='save-section'>
                          <label className='d-block mb-1 save-text'>Click the save button to save the emails: </label>
                          {this.state.addedBpEmails.map((email, index) => 
                            <Tag bordered={false} closable key = {index} onClose={(e)=>{
                              e.preventDefault()
                              const addedBpEmails = this.state.addedBpEmails.filter((item) => item !== email)
                              this.setState({ addedBpEmails })
                            }}> {email} </Tag>
                          ) 
                          } </Col> 
                        : '' }
                  
                      {/* <Col xs className='noride-submit update-btn'>
                    <Button onClick={() => this.updateEmails()} className='custom-button w-40'>Update</Button>
                  </Col> */}
                      <div className='email-div'>
                        <Col xs={24} className='added-emails '>
                          <label>Added emails:</label>
                        </Col>
                        <Space className='tag-holder1'>
                          {this.state.existingBpEmails.map((email, index) => 
                            <Tag bordered={false} closable key = {index} onClose={(e)=>{
                              e.preventDefault()
                              const existingBpEmails = this.state.existingBpEmails.filter((item) => item !== email)
                              this.setState({ existingBpEmails })
                            }} > {email} </Tag>
                          )} 
                        </Space> </div>
                    </Row>
                    : '' }
                  {this.state.selSegment === 'Feedback Form' ? 
                    <Row gutter ={[0,0]} className='email-row'>
                      <Col xs={22} >
                        <label className='mb-0'>
                      Add Feedback Form Email address
                        </label>
                        <Input
                          onChange={(e) => this.setState({ fdbkEmailIn: e.target.value })}
                          style={{ width: '100%' }}
                          value= {this.state.fdbkEmailIn}
                        />
                      </Col>
                      <Col xs={2} className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button htmlType='submit' type='ghost' className='image-btn' onClick={() => this.addEmail('fd') }>
                          <Tooltip title='Add email' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                      
                      { this.state.emailSectionDisplayed ? 
                        <Col xs={24} className='save-section'>
                          <label className='d-block mb-1 save-text'>Click the save button to save the emails: </label>
                          {this.state.addedFdbkEmails.map((email, index) => 
                            <Tag bordered={false} closable key = {index} onClose={(e)=>{
                              e.preventDefault()
                              const addedFdbkEmails = this.state.addedFdbkEmails.filter((item) => item !== email)
                              this.setState({ addedFdbkEmails })
                            }}> {email} </Tag>
                          ) 
                          } </Col> 
                        : '' }
                      
                      {/* <Col xs className='noride-submit update-btn'>
                        <Button onClick={() => this.updateEmails()} className='custom-button w-40'>Update</Button>
                      </Col> */}
                      <div className='email-div'>
                        <Col xs={24} className='added-emails '>
                          <label>Added emails:</label>
                        </Col>
                        <Space className='tag-holder1'>
                          {this.state.existingFdbkEmails.map((email, index) => 
                            <Tag bordered={false} closable key = {index} onClose={(e)=>{
                              e.preventDefault()
                              const existingFdbkEmails = this.state.existingFdbkEmails.filter((item) => item !== email)
                              this.setState({ existingFdbkEmails })
                            }} > {email} </Tag>
                          )} 
                        </Space> </div>
                    </Row> : '' }
                  
                  
                </Row>
              </Form>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={'Add Checklist Question' + (this.state.selectedMilestone ? ` - ${this.state.selectedMilestone}` : '') }
          visible={this.state.openQuestionForm}
          width={'800px'}
          centered
          className='custom-modal pb-1'
          onCancel = { () => this.setState({ 
            openQuestionForm: false,
            addQuestion: {},
            questionType: 'yes/no',
            options: [],
            comment: 'None',
            actionout: 'None',
            actiontype: null,
            actionms: [],
            nested: 'None'
          })}
          footer={[
            <>
              <Space size={'middle'} className='py-2 pr-3 mr-1'>
                <Button onClick={() => this.addQuestion()} className='custom-button w-40'>Add</Button>
                <Button onClick={()=> this.setState({ 
                  openQuestionForm: false,
                  addQuestion: {},
                  questionType: 'yes/no',
                  options: [],
                  comment: 'None',
                  actionout: 'None',
                  actiontype: null,
                  actionms: [],
                  nested: 'None'
                })} 
                className='custom-button w-40'>
             Close
                </Button>
              </Space>
            </>
          ]}
          // onCancel={this.saveEmail}
        >
          <Col xs={24} className='checklist-col'>
            {/* <Card
              className='custom-card h-100'
            > */}
            <Row gutter={[24, 12]} className=''>
              {/* <Col xs={24} xl={'500px'} className='mb-3'>
                <label className='custom-label label-border'>Choose a Type of Question</label>
                <Radio.Group className='f-spacebtw f-wrap radio-nowrap' disabled={!this.state.selMilestone} onChange={(e) => this.radioSelect(e.target.value)} value={this.state.questionType} flex='auto'>
                  <Radio defaultChecked={false} value={'yes/no'}>Yes/No</Radio>
                  <Radio value={'mcq'} className='radio-space'>Multiple Choice Question</Radio>
                  <Radio value={'other'} className='radio-space'> Other</Radio>
                </Radio.Group>
              </Col> */}
              <Col xs={24}>
                <Segmented 
                  options={[ 
                    {
                      label: 'Yes/No',
                      value: 'yes/no'
                    },
                    {
                      label: 'Multiple Choice Question',
                      value: 'mcq'
                    },
                    {
                      label: 'Other',
                      value: 'other'
                    }
                  ]} 
                  defaultValue='Yes/No' 
                  value={this.state.questionType} 
                  onChange={(e) => this.setState({ questionType: e }) } 
                  className='email-segs' 
                  block 
                />
              </Col>
              <Col xs={24} sm={24} md={this.state.questionType && this.state.questionType === 'yes/no'  ? 12 : 18}   className='fields'>
                <label>Enter Question</label>
                <Input
                  size='small'
                  placeholder=''
                  disabled={!this.state.selMilestone}
                  value={this.state?.addQuestion?.question}
                  onChange={
                    (e)=> this.setState({ 
                      addQuestion: {
                        ...this.state.addQuestion, 
                        question: e.target.value 
                      } 
                    })
                  }
                />
              </Col> 
              {/* { this.state.questionType && this.state.questionType === 'yes/no' ?
                  <Col xs={8} className='fields'>
                  </Col> 
                  : '' } */}
              { this.state.questionType && this.state.questionType === 'yes/no' ?
                <Fragment>
                  
                  <Col xs={24} md={6} /*flex='1 0 18%'*/  className='fields'>
                    <label className='space-nowrap d-inlineblock'>Nested questions on </label>
                    <Select size='small' placeholder='Select process' className='w-100' 
                      onChange={(e) => this.setState({ nested: e })}
                      value={this.state.nested}
                      disabled={!this.state.selMilestone}
                    >
                      <Option value='None' >None</Option>
                      <Option value='Yes' >Yes</Option>
                      <Option value='No' >No</Option>
                    </Select>
                  </Col> 
                  <Col xs={24} md={6} /*flex='1 0 18%'*/  className='fields'>
                    <label className='space-nowrap d-inlineblock'>Milestone Action on</label>
                    <Select size='small' placeholder='Select process' className='w-100'
                      onChange={(e) => this.setState({ 
                        actionout: e,
                        actionMS: !e || e === 'None' ? [] : this.state.actionMS,
                        actiontype: !e || e === 'None'? null : this.state.actiontype,
                      })}
                      value={this.state.actionout}
                      disabled={!this.state.selMilestone}
                    >
                      <Option value='None' >None</Option>
                      <Option value='Yes' >Yes</Option>
                      <Option value='No' >No</Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={12}  className='fields'>
                    <label>Dependent Milestones</label>
                    <Select size='small' placeholder='Select Milestones' className='w-100'
                      mode= 'multiple'
                      onChange={(e) => this.setState({ actionms: e?.includes('RESET') ? [] : e })}
                      value={this.state.actionms}
                      disabled={!this.state.selMilestone || (!this.state.actionout || this.state.actionout === 'None')}
                    >
                      {
                        this.state.allMilestones?.map((item, index) => {
                          return (
                            <Option key={index} value={item.id} >{item.title}</Option>
                          )
                        })
                      }
                    </Select>
                  </Col>
                  <Col xs={24} md={6} /*flex='1 0 19.5%'*/  className='fields'>
                    <label>Action Type</label>
                    <Select size='small' placeholder='Select Action Type' className='w-100'
                      onChange={(e) => this.setState({ actiontype: e })}
                      value={this.state.actiontype}
                      disabled={!this.state.selMilestone || (!this.state.actionout || this.state.actionout === 'None')}
                    >
                      <Option value='Turn Grey'>Turn Grey</Option>
                      <Option value='Do Nothing'>Do Nothing</Option>
                    </Select>
                  </Col>
                  <Col xs={24} md={6} /*flex='1 0 20%'*/ className='fields'>
                    <label className='space-nowrap d-inlineblock'>Comments req for </label>
                    <Select size='small' placeholder='Select process' className='w-100'
                      onChange={(e) => this.setState({ comment: e })}
                      value={this.state.comment}
                      disabled={!this.state.selMilestone}
                    >
                      <Option value='None' >None</Option>
                      <Option value='Yes' >Yes</Option>
                      <Option value='No' >No</Option>
                    </Select>
                  </Col>
                </Fragment>
                : '' }
              {/* <Col className='addOps'>
                  { this.state.questionType && this.state.questionType === 'yes/no' ?
                    <Button type='primary' className='custom-button mr-0' onClick={this.openNested} disabled={this.state.nested !== 'Yes'} >Add Nested Question</Button>
                    : '' }
                </Col> */}
                
              { this.state.questionType && this.state.questionType === 'mcq' ?
                <Col xs  className='addOps ml-auto'>
                  <Button type='primary' className='custom-button mr-0' onClick={()=>this.showOptions('Options')} disabled={!this.state.selMilestone}>Add Options</Button>
                </Col>
                : '' }
                
              {/* <Col flex='50px' className='text-center ml-auto'>
                <label className='custom-label transparent'>-</label>
                <Button htmlType='submit' type='ghost' className='image-btn' onClick={() => this.addQuestion()} disabled={!this.state.selMilestone}>
                  <Tooltip title='Add a Question' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                </Button>
              </Col> */}
            </Row>
            {/* </Card>  */}
          </Col>
        </Modal>
      </div>
      // </ConfigProvider>
    )
  }
}

export default DoDMilestones
