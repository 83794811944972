import React, { Component } from 'react'
import { Row, Col, Typography, Card, Table, Divider } from 'antd'
import './index.css'
import JSONTree from 'react-json-tree'
import { Map } from 'immutable'

const { Title } = Typography
const json = {
  array: [1, 2, 3],
  bool: true,
  object: { foo: 'bar', },
  immutable: Map({ key: 'value' }),
}
const theme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#0000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
}

class Gatekeeper extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const awbDataSource = [
      {
        key: '1',
        timestamp: 'Rcvd: 21 OCT 21  1140 UTC',
        type: 'MVT',
        details: 'LAX-ICN',
        applied: 'Departure time for flight DL123 Updated',
      },
    ]

    const awbColumns = [
      {
        title: 'Timestamp',
        dataIndex: 'timestamp',
        key: 'timestamp',
        className: 'space-nowrap',
      },

      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        className:'json-tree-td',
        render: () => (
          <>
            <JSONTree data={json} theme={theme} invertTheme={true} className='json-tree' />
          </>
        )
      },
      {
        title: 'Applied to HubControl',
        dataIndex: 'applied',
        key: 'applied',
      },
    ]


    // rowSelection object indicates the need for row selection

    return (
      <div>
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 24,
              lg: 24,
            },
            {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
            },
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
            <Card className='custom-card h-100'>
              
              <Row
                gutter={[
                  {
                    xs: 0,
                    sm: 0,
                    md: 24,
                    lg: 24,
                  },
                  {
                    xs: 24,
                    sm: 24,
                    md: 24,
                    lg: 24,
                  },
                ]}
                className='with-table'
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>
                    <span>Messages currently in queue: <span className='queued-messages'>300</span></span>
                  </Title>
                  <Divider className='mb-0' />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>
                    Messages Processed
                  </Title>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className='table-overflow'
                >
                  <Table
                    className='custom-table td-top'
                    dataSource={awbDataSource}
                    columns={awbColumns}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Gatekeeper
