import React, { Component } from 'react'
import { Button, Input, Row, Col, Typography, Form, Card, Table, Tooltip, Popconfirm, message, notification } from 'antd'
import addIcon from '../../../assets/images/add.svg'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import upArrowIcon from '../../../assets/images/up-arrow.svg'
import downArrowIcon from '../../../assets/images/down-arrow.svg'
import Update from '../../../assets/images/input-tick.svg'
import cssconstants from '../../../constants/cssconstants'
import constants, { systemParameterTabs as titles } from '../../../constants/constants'
import './tabs.css'
import Http from '../../../utils/http'
import Cookies from 'universal-cookie'
import { cacheRetrieve } from '../../../utils/common'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'

const cookies = new Cookies()
const station = cookies.get('station')
const { Title } = Typography

// Delete Popconfirm
// eslint-disable-next-line no-unused-vars
function confirm() {
  message.info('Clicked on Yes.')
}

class ProductBuildUpPriority extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      prodCode: '',
      eprodCode: '',
      priority: null,
      prodPrioritData: null,
      tableData: [],
      isEdit: false,
      ppindex: null,

      editRecord: null,
      editProdCode: '',
      editPriority: null,
      editPpindex: null,
    }
  }
  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      // eslint-disable-next-line no-console
      console.log('parsedData',parsedData)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData.TYPE === 'PRODUCT_PRIORITY'){
          this.fetchProductPriority()
        }
      }
    }
    this.fetchProductPriority()
  }

  fetchProductPriority = async () => {
    let callback = this.fetchProductPriorityFn
    let pageURL =  `products_priority?station=${station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }

  fetchProductPriorityFn = async(resp) => {
    const respData = await resp.json()
    if(respData && respData.data[0] && respData.data[0].prod_prior_list.length>0){
      this.setState({
        prodPrioritData : respData.data[0],
        tableData : respData.data[0].prod_prior_list
      })
    } else {
      this.setState({
        prodPrioritData : null,
        tableData: []
      },()=>{notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'No Data Found',
        })})
    }

  }
  
  editHandler = (record, index, operation) =>{
    let tempp = record.priority
    if(operation === 'UP'){
      tempp = tempp-1
    } else if(operation === 'DOWN') {
      tempp = tempp+1
    }
    this.setState({
      isEdit: true,
      //prodCode: record.p_code,
      priority: tempp,
      ppindex: index,
    },()=>{
      if(operation === 'UP' || operation === 'DOWN'){
        this.setState({ eprodCode: record.p_code },()=>this.updateProductPriority())
      }
      else {
        this.setState({ prodCode: record.p_code })
      }
    })
  }

  addProductPriority = () => {
    //eslint-disable-next-line
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_buildup_priority
    }
    let formdata= {
      p_code : this.state.prodCode,
      priority : this.state.priority,
      station:  station || null,
    }
    Http.post('/products_priority', formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.setState({
            prodCode: '',
            priority: null,
            eprodCode: ''
          },()=>{
            this.clearTableForm()
            this.fetchProductPriority()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  updateProductPriority = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_buildup_priority
    }
    let formdata= {
      p_code : this.state.eprodCode ? this.state.eprodCode : this.state.prodCode,
      priority : this.state.priority,
      station:  station || null,
      index: this.state.ppindex
    }
    Http.put('/products_priority', formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.setState({
            prodCode: '',
            eprodCode: '',
            priority: null,
            isEdit: false,
            index: null
          },()=>{
            this.clearTableForm()
            this.fetchProductPriority()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          })
        } else {
          this.setState({ eprodCode: '' })
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  deleteProductPriority = (index) => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_buildup_priority
    }
    let formdata= {
      station: station || null,
      index: index
    }
    Http.put('/products_priority_del', formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.clearForm()
          this.fetchProductPriority()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  clearForm = () =>{
    this.setState({
      prodCode: '',
      eprodCode: '',
      priority: null,
      isEdit: false,
      index: null
    })
  }

  updateEditProductPriority = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_buildup_priority
    }
    let formdata= {
      p_code : this.state.editProdCode,
      priority : this.state.editPriority,
      station:  station || null,
      index: this.state.editPpindex
    }
    Http.put('/products_priority', formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.clearTableForm()
          this.fetchProductPriority()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          this.setState({ eprodCode: '' })
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  editTableForm = (record, index) => {
    this.setState({
      editRecord: record,
      editProdCode: record.p_code,
      editPriority: record.priority,
      editPpindex: index,
    })
  }

  clearTableForm = () => {
    this.setState({
      editRecord: null,
      editProdCode: '',
      editPriority: null,
      editPpindex: null,
    })
  }

  render() {
    const prodcolumns = [
      {
        title: 'Product Code',
        //dataIndex: 'p_code',
        key: 'p_code',
        render: (text, record) => (
          this.state.editRecord && this.state.editRecord.p_code === record.p_code ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editProdCode}
              onChange={(e) => this.setState({ editProdCode: e.target.value.toUpperCase() })}
            />
            :
            record.p_code ? record.p_code : '-'
        )
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',


        render: (text, record, index) =>
          this.state.editRecord && this.state.editRecord.p_code === record.p_code ?
            <div className='action-column'>
              <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateEditProductPriority()} ><img src={Update} alt='Save' /></Tooltip>
              <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
            </div>
            :
            <div className='action-column'>
              {  record.priority !== 1 ? (
                <Tooltip title='Increase Priority' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={upArrowIcon} alt='up-arrow'  onClick={()=>this.editHandler(record, index, 'UP')} /></Tooltip>
              ): <Tooltip><img className='hidden-table-icon' src={''} /></Tooltip> }
              <Tooltip title='Decrease Priority' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={downArrowIcon} alt='down-arrow' onClick={()=>this.editHandler(record, index, 'DOWN')} /></Tooltip>
              <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record, index)} ><img src={editIcon} alt='edit' /></Tooltip>
              <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteProductPriority(index)} >
                <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
              </Popconfirm>
            </div>
      },
    ]
    return (
      <div>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={{
            span: 24,
            offset: 0
          }} lg={{
            span: 12,
            offset: 6
          }} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} className=''>
                  <Title level={5} className=''>Add Product Prio</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className='p-12'>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]}
                      className='form-width'
                    >

                      <Col
                        xs={{ span: 20 }}
                        md={{ span: 18 }}
                        lg={{ span: 18 }}
                        xl={{ span: 18 }}
                        className=''>
                        <Form.Item label='Enter Product Code'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.prodCode}
                            onChange={(e) => this.setState({ prodCode: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col xs={24} sm={24} flex='auto' className=''>
                        <Form.Item label='Enter unique numeric priority'>
                          <InputNumber
                            min={0}
                            precision={0}
                            size='small'
                            placeholder=''
                            value={this.state.priority}
                            onChange={(e) => this.setState({ priority: e })}
                          />
                        </Form.Item>
                      </Col> */}
                      <Col flex='30px' className='ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='ml-auto image-btn'onClick={() => this.state.isEdit ? this.updateProductPriority() : this.addProductPriority()}>
                          <Tooltip title= {this.state.isEdit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                    { this.state.isEdit ? (
                      <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Button type='ghost' className='ml-auto image-btn clear-btn product-btn-placement' onClick={() => this.clearForm()}>
                          <span className='close-icon'>x</span>
                        </Button>
                      </Tooltip>
                    )
                      : null
                    }
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={{
            span: 24,
            offset: 0
          }} lg={{
            span: 12,
            offset: 6
          }} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className=''>List of Product Codes & Priority</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table pc-table'
                      dataSource={this.state.tableData}
                      columns={prodcolumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ProductBuildUpPriority
