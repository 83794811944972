//import React, { createContext, useState, useEffect } from 'react'
import React, { createContext } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { Redirect as BRedirect, RedirectDash, Logout, SystemParameter, InboundFlight, RebookingQueue, Login, BreakdownControl, NotesContainer, ChangePassword, FlightControl, BuildupControl, IncomingMessages, DeltaLogin, ManageUsers, Warehouse, BreakdownControlUnknown, ChangeLog, RecoveryDashboard, BuildUpDashboard, HauloutDashboard, DeveloperDomain, Dashboard, LandingPage, Reports } from './pages'
import RouteWithLayout from './routeHandler'
import DashboardLayout from './Components/layout'
import DashboardLayout2 from './Components/layout2'
import 'antd/dist/antd.min.css'
import './App.css'
//import Notifications from './Components/Notifications/Notifications'
import { title } from './constants/constants'

export const State = createContext()


const initialState = {}
function App(...rest) {
  // const [ facts, setFacts ] = useState([])
  // const [ listening, setListening ] = useState(false)

  // useEffect( () => {
  //   if (!listening) {
  //     const events = new EventSource(process.env.REACT_APP_API_URL+'/events')
  //     events.onmessage = (event) => {
  //       const parsedData = JSON.parse(event.data)
  //       // eslint-disable-next-line no-console
  //       console.log('test',event.data)
  //       setFacts((facts) => facts.concat(parsedData))
  //     }
  //     setListening(true)
  //   }
  // }, [listening, facts])
  
  const pathIncludes = (path) => window.location.pathname.includes(path) 
  const documentTitle = (doc) => document.title = (doc + ' | ') + title

  switch(true) {
  case pathIncludes('/system-parameter'):
    documentTitle('System Parameters')
    break
  case pathIncludes('/inbound-flight'):
    documentTitle('Inbound Flight Preparation')
    break
  case pathIncludes('/rebooking-queue'):
    documentTitle('Rebooking Queue')
    break
  case pathIncludes('/breakdown-control'):
    documentTitle('BreakdownControl')
    break
  case pathIncludes('/unknown-breakdown-control'):
    documentTitle('BreakdownControlUnknown')
    break
  case pathIncludes('/notes'):
    documentTitle('Notes')
    break
  case pathIncludes('/change-password'):
    documentTitle('Change Password')
    break
  case pathIncludes('/dod-command-center'):
    documentTitle('DoD Command Center')
    break
  case pathIncludes('/buildup-control'):
    documentTitle('Buildup Control')
    break
  case pathIncludes('/gatekeeper'):
    documentTitle('Gatekeeper')
    break
  case pathIncludes('/dashboard'):
    documentTitle('')
    break
  case pathIncludes('/change-log'):
    documentTitle('Change Log')
    break
  case pathIncludes('/manage-users'):
    documentTitle('Manage Users')
    break
  case pathIncludes('/developer-domain'):
    documentTitle('Developer Domain')
    break
  case pathIncludes('/manage-dashboard'):
    documentTitle('Dashboard')
    break
  case pathIncludes('/reports'):
    documentTitle('Reports')
    break
  case pathIncludes('/landing-page'):
    documentTitle('Landing Page')
    break
  case pathIncludes('/login'):
    documentTitle('Login')
    break
  case pathIncludes('/breakdown-dashboard'):
    documentTitle('Breakdown Dashboard')
    break
  case pathIncludes('/redirect'):
    documentTitle('Redirect')
    break
  case pathIncludes('/logout'):
    documentTitle('Logout')
    break
  case pathIncludes('/recovery-dashboard'):
    documentTitle('Recovery Dashboard')
    break
  case pathIncludes('/buildup-dashboard'):
    documentTitle('BuildUp Dashboard')
    break
  case pathIncludes('/haulout-dashboard'):
    documentTitle('Haulout Dashboard')
    break
  default:
    documentTitle('')
  }

  return (
    <State.Provider value={initialState}>
      <Router>
        <Switch>
          <RouteWithLayout
            exact
            path='/system-parameter/:tab?'
            layout={DashboardLayout}
            title='System Parameters'
            component={SystemParameter}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/inbound-flight'
            layout={DashboardLayout}
            title='Inbound Flight Preparation'
            component={InboundFlight}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/rebooking-queue'
            layout={DashboardLayout}
            title='Rebooking Queue'
            component={RebookingQueue}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/breakdown-control'
            layout={DashboardLayout}
            title='BreakdownControl'
            component={BreakdownControl}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/unknown-breakdown-control'
            layout={DashboardLayout}
            title='BreakdownControlUnknown'
            component={BreakdownControlUnknown}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/notes'
            layout={DashboardLayout}
            title='Notes'
            component={NotesContainer}
            {...rest}
          />
          {/* <RouteWithLayout
            exact
            path='/gatekeeper'
            layout={DashboardLayout}
            title='Gatekeeper'
            component={Gatekeeper}
            {...rest}
          /> */}
          <RouteWithLayout
            exact
            path='/change-password'
            layout={DashboardLayout}
            title='Change Password'
            component={ChangePassword}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/dod-command-center'
            layout={DashboardLayout}
            title='DoD Command Center'
            component={FlightControl}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/buildup-control'
            layout={DashboardLayout}
            title='Buildup Control'
            component={BuildupControl}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/gatekeeper'
            layout={DashboardLayout}
            title='Gatekeeper'
            component={IncomingMessages}
            {...rest}
          />
          <Route
            exact
            path='/dashboard'
            title='Logging In'
            component={DeltaLogin}
          />
          <RouteWithLayout
            exact
            path='/change-log'
            layout={DashboardLayout}
            title='Change Log'
            component={ChangeLog}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/manage-users'
            layout={DashboardLayout}
            title='Manage Users'
            component={ManageUsers}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/developer-domain'
            layout={DashboardLayout}
            title='Developer Domain'
            component={DeveloperDomain}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/manage-dashboard'
            layout={DashboardLayout}
            title='dashboard'
            component={Dashboard}
            {...rest}
          />
          <RouteWithLayout
            exact
            path='/reports'
            layout={DashboardLayout}
            title='Reports'
            component={Reports}
            {...rest}
          />
          <Route
            exact
            path='/landing-page'
            component={LandingPage}
          />
          <Route
            exact
            path='/login'
            component={Login}
          />
          <Route
            exact
            path='/breakdown-dashboard'
            component={Warehouse}
          />
          <RouteWithLayout
            path='/dashboard-redirect/:tab?'
            component={RedirectDash}
            title='dashboard'
            layout={DashboardLayout2}
            {...rest}
          />
          <Route
            exact
            path='/redirect'
            component={BRedirect}
          />
          <Route
            exact
            path='/logout'
            component={Logout}
          />
          <Route
            exact
            path='/recovery-dashboard'
            component={RecoveryDashboard}
          />
          <Route
            exact
            path='/buildup-dashboard'
            component={BuildUpDashboard}
          />
          <Route
            exact
            path='/haulout-dashboard'
            component={HauloutDashboard}
          />
          <Redirect from='/*' to='/redirect' />
        </Switch>
      </Router>
      {/* <Notifications /> */}
    </State.Provider>
  )
}

export default App
