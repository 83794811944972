import React from 'react'
import '../index.css'
import { useTimer, useStopwatch, useTime } from 'react-timer-hook'

//FUNCTION TAKES IN THE TIMESTAMP AND CLASS NAME IF ANY AND RETURNS DESCENDING TIME
function CountDown ({ expiryTimestamp, className }) {
  let {
    minutes,
    hours,
    days,
  } = useTimer({ expiryTimestamp })

  let time = ''
  if(days > 0) time = `${days}d${hours}h${minutes}m`
  if(hours > 0) time = `${hours}h${minutes}m`
  else time = `${minutes}m`

  return (
    <span className={className ? className : ''}>{time}</span>
  )
}

//FUNCTION TAKES IN THE TIMESTAMP AND RETURNS ASCENDING TIME
function CountUp ({ expiryTimestamp }) {
  let {
    minutes,
    hours,
    days,
  } = useStopwatch({ 
    autoStart: true,
    offsetTimestamp: expiryTimestamp
  })

  let time = ''
  if(days > 0) time = `${days}d${hours}h${minutes}m`
  else time = `${hours}h${minutes}m`

  return (
    <span>{time}</span>
  )
}

//FUNCTION TAKES IN THE TIMESTAMP AND RETURNS ASCENDING TIME
function Time () {
  let {
    minutes,
    hours,
    days,
  } = useTime({ format: '24-hour' })

  let time = ''
  if(days > 0) time = `${days}d${hours}h${minutes}m`
  else time = `${hours}h${minutes}m`

  return (
    <span>{time}</span>
  )
}

export { CountDown, CountUp, Time }