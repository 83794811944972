import Cookies from 'universal-cookie'
import moment from 'moment-timezone'
import { notification } from 'antd'
import { events as EVENT, timezone as timeZone } from '../constants/constants'
const station = new Cookies().get('station')
const timezone = new Cookies().get('timezone')
const ext = timeZone.find((item) => item.value === new Cookies().get('station'))?.ext || 'EST'
const timezone_cty = timeZone.find((item) => item.value === new Cookies().get('station'))?.zone || 'America/New_York'
const dateFormat = 'DDMMMYY HH:mm'
const format = 'YYYY-MM-DD HH:mm'
const timeFormat = 'HH:mm'
const URL = process.env.REACT_APP_API_URL 

//UPDATE FLIGHT DATA ONLY
const updateFlightData = function(data, dynamic_info) {
  data &&
    data.map((flight) => {
      flight.flights_historys = flightHistoryCalculator(flight)
      flight.history_status = flight.history ? flightHistoryStatus(flight) : null
      flight.arriving_datetime = getFlightDateTime(flight, flight?.arriv_date)
      flight.is_dynamic_info = dynamicCheck(dynamic_info, flight)
      flight.dynamic_info = getDynamicCheck(dynamic_info, flight)

      flight.awbs = handlingTimesForFlight(flight)
      let least_awb = getLeastAWB(flight.awbs)
      flight.show_handling_time_after_tta = least_awb.flag
      flight.handling_time_as_timestamp = least_awb.time
      flight.handling_time = least_awb.ht
      flight.lowest_awb_no = least_awb.lowest_awb_no

      flight.weight_kg = 0
      flight.weight_lbs = 0
      let awbs = flight.awbs_in ? flight.awbs_in : []
      if (awbs?.length > 0) {
        awbs.map((awb) => {
          if(awb.wgt.unit === 'LBS') {
            awb.weight_lbs = awb.wgt.value //ROUNDED TO NEAREST INTEGER
            awb.weight_kg = weightCalculator(awb.wgt.value, 'LBS')
          } else if(awb.wgt.unit === 'KGM') {
            awb.weight_kg = awb.wgt.value //ROUNDED TO NEAREST INTEGER
            awb.weight_lbs = weightCalculator(awb.wgt.value, 'KGM')
          }
          flight.weight_lbs+=awb.weight_lbs
          flight.weight_kg+=awb.weight_kg
        })
      }
    })
  sort(data, 'id')
  sort(data, 'arriv_date')
  sort(data, 'arriving_datetime')
  return data
}
//UPDATE INDIVIDUAL FLIGHT
const processIndividualFlight = (flight, dynamic_info) => {
  flight.flights_historys = flightHistoryCalculator(flight)
  flight.history_status = flight.history ? flightHistoryStatus(flight) : null

  flight.arriving_datetime = getFlightDateTime(flight, flight?.arriv_date)
  flight.is_dynamic_info = dynamicCheck(dynamic_info, flight)
  flight.dynamic_info = getDynamicCheck(dynamic_info, flight)

  flight.awbs = handlingTimesForFlight(flight)
  let least_awb = getLeastAWB(flight.awbs)
  flight.show_handling_time_after_tta = least_awb.flag
  flight.handling_time_as_timestamp = least_awb.time
  flight.handling_time = least_awb.ht
  flight.lowest_awb_no = least_awb.lowest_awb_no

  flight.weight_kg = 0
  flight.weight_lbs = 0
  let awbs = flight.awbs_in ? flight.awbs_in : []
  if (awbs?.length > 0) {
    awbs.map((awb) => {
      if(awb.wgt.unit === 'LBS') {
        awb.weight_lbs = awb.wgt.value //ROUNDED TO NEAREST INTEGER
        awb.weight_kg = weightCalculator(awb.wgt.value, 'LBS')
      } else if(awb.wgt.unit === 'KGM') {
        awb.weight_kg = awb.wgt.value //ROUNDED TO NEAREST INTEGER
        awb.weight_lbs = weightCalculator(awb.wgt.value, 'KGM')
      }
      flight.weight_lbs+=awb.weight_lbs
      flight.weight_kg+=awb.weight_kg
    })
  }
  return flight
}
//UPDATE ULDS/AWBS DATA ONLY
const updateUldData = function(data, flight, system_params, default_system_params, stations) {
  data.map((uld) => {
    uld.uld_type = uld?.uld_type?.toUpperCase() === 'TRANSIT-POTENTIAL' ? 'Potential-Through' : uld.uld_type
    uld.weight_kg = 0
    uld.weight_lbs = 0
    uld.pcs = 0
    let awbs = uld.awbs_in ? uld.awbs_in : []
    let array_of_dates = []
    if (awbs.length > 0) {
      awbs.map((awb) => {
        handlingTimes(awb,
          system_params && system_params.filter((item) => item.station === station),
          default_system_params && default_system_params.filter((item) => item.station === station), stations, 'INBOUND') //HANDLING TIME CALCULATOR

        //IF ULD DELIVER TO IS EMPTY UPDATE AWB STATUS COLOR/MESSAGE
        if(!uld?.work_areas_id) {
          //awb.handling_time_color = 'red'
          //awb.message = '\'Deliver To\' unavailable'
        }

        if(awb.handling_time) array_of_dates.push(awb.handling_time)
        if(awb.wgt.unit === 'LBS') {
          awb.weight_lbs = awb.wgt.value //ROUNDED TO NEAREST INTEGER
          awb.weight_kg = weightCalculator(awb.wgt.value, 'LBS')
        } else if(awb.wgt.unit === 'KGM') {
          awb.weight_kg = awb.wgt.value //ROUNDED TO NEAREST INTEGER
          awb.weight_lbs = weightCalculator(awb.wgt.value, 'KGM')
        }
        //COMPUTE AWB HISTORY
        uldawbHistoryCalculator(awb.goods_infos_histories, flight, null, awb)
        if(awb?.segs_out && !['KK', 'FC', 'MK', 'RC', 'CFM']?.includes(awb?.segs_out?.status)) {
          awb.message = 'Booking Unconfirmed'
        }
        uld.weight_lbs+=awb.weight_lbs
        uld.weight_kg+=awb.weight_kg
        uld.pcs+=awb.pcs //ADD ULD PCS
      })
    }
    sort(awbs, 'id')
    sort(awbs, 'handling_time')
    //TO SET EARLIEST ULD TIME ON FRONT END
    let least_awb = getLeastAWB(awbs)
    uld.show_handling_time_after_tta = least_awb.flag
    uld.handling_time_as_timestamp = least_awb.time
    uld.handling_time = least_awb.ht
    uld.handling_time_color = awbs?.length > 0 ? (awbs.filter((item) => item.handling_time_color !== 'green'))?.length > 0 ? false : true : 'grey' //RETURN OBJECT IF ANY GREEN RECORD EXIST
    uld.lowest_awb_message = least_awb.message
    //COMPUTE ULD HISTORY
    uldawbHistoryCalculator(uld.ulds_historys, flight, uld, null)
    uld.message = handleULDStatusMessage(uld)
  })
  sort(data, 'id')
  sort(data, 'handling_time')
  return data
}
//GENERATE ULD STATUS MESSAGE // ULDs Status Msg
const handleULDStatusMessage = (uld) => {
  let message = ''

  //FIRST PRIORITY FOR DELIVER_TO OF ULD
  // if(!uld?.work_areas_id) {
  //   message = '\'Deliver To\' unavailable'
  // }
  // else {
  let awbs = uld?.awbs_in || []
  if(awbs?.length > 0) {
    awbs = awbs.filter((item) => item.handling_time_color !== 'green')

    if(awbs?.length === 0) {
      message = 'Handling times OK'
    } else if(awbs?.length > 0) {
      let isLocal = uld?.flights_in?.dest?.arriv === station && uld?.flights_in?.dest?.arriv === awbs[0]?.dest ? true : false 
      if(!isLocal && awbs[0]?.info?.flights_out_date) {
        if(moment.utc(awbs[0]?.info?.flights_out_date).tz(timezone).valueOf() < moment().valueOf()) {
          message = 'Oops! This flight has departed'
        } else {
          if(!awbs[0]?.booking_confirmed){
            message = 'Booking Unconfirmed'
          } else {
            message = 'Insufficient handling time'
          }
        }
      } else if(!awbs[0]?.booking_confirmed){
        message = 'Booking Unconfirmed'
      } else if(isLocal && awbs[0]?.flights_out || awbs[0]?.segs_out ) {
        message = 'Insufficient handling time'
      } else {
        message = 'Flight Info Missing'
      }
    }
  }

  return message
}
//UPDATE INBOUND FLIGHTS DATA
const updateData = function (data, system_params, default_system_params, dynamic_info, stations) {
  data &&
    data.map((flight) => {
      flight.flights_historys = flightHistoryCalculator(flight)
      flight.arriving_datetime = getFlightDateTime(flight, flight?.arriv_date)
      flight.is_dynamic_info = dynamicCheck(dynamic_info, flight)
      flight.dynamic_info = getDynamicCheck(dynamic_info, flight)

      flight.awbs = handlingTimesForFlight(flight)
      let least_awb = getLeastAWB(flight.awbs)
      flight.show_handling_time_after_tta = least_awb.flag
      flight.handling_time_as_timestamp = least_awb.time
      flight.handling_time = least_awb.ht
      flight.lowest_awb_no = least_awb.lowest_awb_no

      if (flight?.uld_data?.length > 0) {
        flight.uld_data = updateUldData(flight.uld_data, flight, system_params, default_system_params, stations)
      }
      sort(data, 'id')
      sort(data, 'arriving_datetime')
    })
  return data
}
//CHECK BOOKING CONFIRMED FOR ULD STATUS
// const checkBookingConfirmed = function (awbs) {
//   let flag = false
//   if(awbs?.length > 0) {
//     awbs.map((awb) => {
//       if(!['KK', 'FC', 'MK', 'RC']?.includes(awb?.segs_out?.status)) {
//         flag = true
//       }
//     })
//   }
//   return flag
// }
//SORT DATA ID, HT
const sort = (array, type) => {
  array && array.sort(function(a, b) {
    var keyA = type === 'id' ? a[type] : moment(a[type]).valueOf()
    var keyB = type === 'id' ? b[type] : moment(b[type]).valueOf()
    // Compare the fields
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  })
}
//GET LEAST AWB TIME
const getLeastAWB = (awbs) => {
  let temp = null, flag = false, time = '', ht = '', lowest_awb_no = '', message = '', amber_countdown = '', amber_flag = false, flights_in = ''
  if(awbs?.length > 1) {
    let isEmpty = 0
    awbs?.map((awb) => {
      if(awb?.recovery_actions_id === null) {
        if(awb.ht_minutes < temp || temp === null) {
          temp = awb.ht_minutes
          flag = awb.show_handling_time_after_tta
          time = awb.handling_time_as_timestamp
          ht = awb.handling_time
          lowest_awb_no = awb.awb_no
          amber_countdown = awb?.amber_countdown
          amber_flag = awb?.amber_flag
          flights_in = awb?.flights_in || ''
          message = !awb.booking_confirmed ? 'Booking Unconfirmed' : awb.handling_time_color === 'green' ? 'Handling times OK' : 'Insufficient handling time'
          //message = awb.handling_time_color === 'green' ? 'Handling times OK' : 'Insufficient handling time'
        }
      } else {
        isEmpty = isEmpty + 1
      }

      if(isEmpty === awbs?.length) {
        temp = awbs[0].ht_minutes
        flag = awbs[0].show_handling_time_after_tta
        time = awbs[0].handling_time_as_timestamp
        ht = awbs[0].handling_time
        lowest_awb_no = awbs[0].awb_no
        amber_countdown = awbs[0]?.amber_countdown
        amber_flag = awbs[0]?.amber_flag
        flights_in = awbs[0]?.flights_in || ''
        message = !awbs[0].booking_confirmed ? 'Booking Unconfirmed' : awbs[0].handling_time_color === 'green' ? 'Handling times OK' : 'Insufficient handling time'
        //message = awb[0].handling_time_color === 'green' ? 'Handling times OK' : 'Insufficient handling time'
      }
    })
  } else {
    awbs?.map((awb) => {
      if(awb.ht_minutes < temp || temp === null) {
        temp = awb.ht_minutes
        flag = awb.show_handling_time_after_tta
        time = awb.handling_time_as_timestamp
        ht = awb.handling_time
        lowest_awb_no = awb.awb_no
        amber_countdown = awb?.amber_countdown
        amber_flag = awb?.amber_flag
        flights_in = awb?.flights_in || ''
        message = !awb.booking_confirmed ? 'Booking Unconfirmed' : awb.handling_time_color === 'green' ? 'Handling times OK' : 'Insufficient handling time'
        //message = awb.handling_time_color === 'green' ? 'Handling times OK' : 'Insufficient handling time'
      }
    })
  }
  return {
    temp: temp,
    flag: flag,
    time: time,
    ht: ht,
    lowest_awb_no: lowest_awb_no,
    amber_countdown: amber_countdown,
    amber_flag: amber_flag,
    flights_in: flights_in,
    message: message,
  }
}
//CHECK FOR DYNAMIC INFO AVAILABILITY
const dynamicCheck = function (data, flight) {
  let flag = []
  let dinfo = []
  if(data?.length > 0) {
    data.map((d) => {
      let item = d?.details
      let check = []
      let checkdinfo = null
      //CHECK STATION
      if(item.station == flight?.dest?.arriv || item.station == null || item.station == '') {
        check.push('true')
        checkdinfo = d
      } else {
        check.push('false')
        checkdinfo = null
      }
      //CHECK AC TYPE
      if(item.ac_type == flight.ac_type || item.ac_type == null || item.ac_type == '') {
        check.push('true')
        checkdinfo = d
      } else {
        check.push('false')
        checkdinfo = null
      }
      //CHECK FLIGHT NO
      if(item.flightno == null || item.flightno == '') {
        check.push('true')
        checkdinfo = d
      } else if((!item.flightno?.includes('*') && item.flightno == flight.flight_no)) {
        check.push('true')
        checkdinfo = d
      } else if (item.flightno?.includes('*')) {
        let itemCode = item.flightno.replace(/[0-9]/g, '').replace('*','')?.toUpperCase()
        let itemNumber = item.flightno.replace(/\D/g, '')
        let flightNo = flight.flight_no.replace(/\D/g, '')?.[0]
        if(itemCode == flight.carrier_code.trim() && itemNumber == flightNo) {
          check.push('true')
          checkdinfo = d
        } else {
          check.push('false')
          checkdinfo = null
        }
      } else {
        check.push('false')
        checkdinfo = null
      }

      if(item.uld_type == null || item.uld_type == '') {
        check.push('true')
        checkdinfo = d
      } else {
        if(flight?.ulds.length > 0) {
          let ulds = flight?.ulds
          let uldCheck = []
          ulds.map((uld) => {
            //CHECK ULD TYPE
            // if(uld.uld_type && item.uld_type?.includes(uld.uld_type)) {
            if(uld && uld.uld_no && item.uld_type && uld.uld_no.includes(item.uld_type)) {
              uldCheck.push('true')
            } else {
              uldCheck.push('false')
            }
          })

          if(uldCheck?.includes('true')) {
            check.push('true')
            checkdinfo = d
          } else {
            check.push('false')
            checkdinfo = null
          }
        } else {
          check.push('false')
          checkdinfo = null
        }
      }

      if(item.prod_code == null || item.prod_code == '') {
        check.push('true')
        checkdinfo = d
      } else {
        if(flight?.awbs_in?.length > 0) {
          let awbs = flight?.awbs_in
          let pCheck = []
          awbs.map((awb) => {
            //CHECK PROD CODE
            if(item.prod_code == awb?.awb?.prod_code) {
              pCheck.push('true')
              checkdinfo = d
            } else {
              pCheck.push('false')
            }
          })

          if(pCheck?.includes('true')) {
            check.push('true')
            checkdinfo = d
          } else {
            check.push('false')
            checkdinfo = null
          }
        } else {
          check.push('false')
          checkdinfo = null
        }
      }

      if(item.spl_code == null || item.spl_code == '') {
        check.push('true')
        checkdinfo = d
      } else {
        if(flight?.awbs_in?.length > 0) {
          let awbs = flight?.awbs_in
          let sCheck = []
          awbs.map((awb) => {
            //CHECK SPECIAL CODE
            let awbSplCode = awb?.awb?.spl_code?.trim()?.split(',')
            let itemSplCode = item?.spl_code?.trim()?.split(',')
            const intersection = awbSplCode?.filter(element => itemSplCode?.includes(element))
            if(intersection?.length > 0) {
              sCheck.push('true')
            } else {
              sCheck.push('false')
            }
          })

          if(sCheck?.includes('true')) {
            check.push('true')
            checkdinfo = d
          } else {
            check.push('false')
            checkdinfo = null
          }
        } else {
          check.push('false')
          checkdinfo = null
        }
      }

      if(item.startdate == null || item.startdate == '') {
        check.push('true')
        checkdinfo = d
      } else {
        let itemSDate = moment.utc(item.startdate).format('YYYY-MM-DD').toUpperCase()
        let flightSDate = moment.utc(flight.flight_date).format('YYYY-MM-DD').toUpperCase()
        if(new Date(itemSDate) <= new Date(flightSDate)) {
          check.push('true')
          checkdinfo = d
        } else {
          check.push('false')
          checkdinfo = null
        }
      }
      if(item.enddate == null || item.enddate == '') {
        check.push('true')
        checkdinfo = d
      } else {
        let itemEDate = moment.utc(item.enddate).format('YYYY-MM-DD').toUpperCase()
        let flightEDate = moment.utc(flight.flight_date).format('YYYY-MM-DD').toUpperCase()
        if(new Date(itemEDate) >= new Date(flightEDate)) {
          check.push('true')
          checkdinfo = d
        } else {
          check.push('false')
          checkdinfo = null
        }
      }
      if(check.length > 0) {
        if(check?.includes('false')) {
          flag.push('false')
          dinfo.push(checkdinfo)
        } else {
          flag.push('true')
          dinfo.push(checkdinfo)
        }
      } else {
        flag.push('false')
        dinfo.push(checkdinfo)
      }
    })
  }
  let finaldata = []
  flag.map((item, index) => {
    if(item === 'true'){
      finaldata.push(dinfo[index])
    }
  })
  if(flag?.includes('true')) {
    return finaldata
  } else {
    return false
  }
}
//RETURN DYNAMIC INFO ARRAY
const getDynamicCheck = function (data, flight) {
  let dinfoarr = []
  if(data?.length > 0) {
    data.map((d) => {
      let item = d?.details
      let check = []

      // let flightcase1 = false //  check if dynamic info and flight data match
      let flightcase2 = false  //  check if dynamic info for uld and awb are null

      // if((d.station == flight?.dest?.arriv || d.station == null || d.station == '') &&
      //   (item.ac_type == flight.ac_type || item.ac_type == null || item.ac_type == '') &&
      //   // ((item.flightno == null || item.flightno == '') || (!item.flightno?.includes('*') && item.flightno == flight.flight_no) || (item.flightno?.includes('*') && itemCode == flight.carrier_code.trim() && itemNumber == flightNo ) ) &&
      //   ((item.startdate == null || item.startdate == '') || (new Date(itemSDate) <= new Date(flightSDate)) ) &&
      //   ((item.enddate == null || item.enddate == '') || new Date(itemEDate) >= new Date(flightEDate) )
      // ){
      //   flightcase1 = true
      // }
      if((item.uld_type == null || item.uld_type == '') && (item.prod_code == null || item.prod_code == '') && (item.spl_code == null || item.spl_code == '')){
        flightcase2 = true
      }
      // if(flightcase1 && flightcase2) {
      //   let obj = {
      //     trigger: 'Flight',
      //     reference:  flight.flight_no,
      //     dinfo:  d
      //   }
      //   dinfoarr.push(obj)
      // }

      if(d.station == flight?.dest?.arriv || d.station == null || d.station == '') {
        check.push('true')
      } else {
        check.push('false')
      }
      //CHECK AC TYPE
      if(item.ac_type == flight.ac_type || item.ac_type == null || item.ac_type == '') {
        check.push('true')
      } else {
        check.push('false')
      }
      //CHECK FLIGHT NO
      if(item.flightno == null || item.flightno == '') {
        check.push('true')
      } else if((!item.flightno?.includes('*') && item.flightno == flight.flight_no)) {
        check.push('true')
      } else if (item.flightno?.includes('*')) {
        let itemCode = item.flightno.replace(/[0-9]/g, '').replace('*','')?.toUpperCase()
        let itemNumber = item.flightno.replace(/\D/g, '')
        let flightNo = flight.flight_no.replace(/\D/g, '')?.[0]
        if(itemCode == flight.carrier_code.trim() && itemNumber == flightNo) {
          check.push('true')
        } else {
          check.push('false')
        }
      } else {
        check.push('false')
      }
      if(item.startdate == null || item.startdate == '') {
        check.push('true')
      } else {
        let itemSDate = moment.utc(item.startdate).format('YYYY-MM-DD').toUpperCase()
        let flightSDate = moment.utc(flight.flight_date).format('YYYY-MM-DD').toUpperCase()
        if(new Date(itemSDate) <= new Date(flightSDate)) {
          check.push('true')
        } else {
          check.push('false')
        }
      }
      if(item.enddate == null || item.enddate == '') {
        check.push('true')
      } else {
        let itemEDate = moment.utc(item.enddate).format('YYYY-MM-DD').toUpperCase()
        let flightEDate = moment.utc(flight.flight_date).format('YYYY-MM-DD').toUpperCase()
        if(new Date(itemEDate) >= new Date(flightEDate)) {
          check.push('true')
        } else {
          check.push('false')
        }
      }

      if(check.length > 0) {
        if(check?.includes('false')) {
          //do nothing
        } else {
          if(flightcase2){
            let obj = {
              trigger: 'Flight',
              reference:  flight.flight_no,
              dinfo:  d
            }
            dinfoarr.push(obj)
          } else {
            if(flight?.ulds.length > 0) {
              let ulds = flight?.ulds
              ulds.map((uld) => {
                let uldcase1 = false //  check if dynamic info and uld data match
                let uldcase2 = false  //  check if dynamic info for awb are null
                //if(uld.uld_type && item.uld_type?.includes(uld.uld_type)) {
                if(uld && uld.uld_no && item.uld_type && uld.uld_no.includes(item.uld_type)) {
                  uldcase1 = true
                }
                if((item.prod_code == null || item.prod_code == '') && (item.spl_code == null || item.spl_code == '')){
                  uldcase2 = true
                }
                if(uldcase1 && uldcase2){
                  let obj2 = {
                    trigger: 'ULD',
                    reference:  uld.uld_no,
                    dinfo:  d
                  }
                  dinfoarr.push(obj2)
                }
              })
            }
            if(flight?.awbs_in?.length > 0) {
              let awbs = flight?.awbs_in
              awbs.map((awb) => {
                let awbSplCode = awb?.awb?.spl_code?.trim()?.split(',')
                let itemSplCode = item?.spl_code?.trim()?.split(',')
                const intersection = awbSplCode?.filter(element => itemSplCode?.includes(element))
                if( ((item.uld_type == null || item.uld_type == '') || (item.uld_type && awb?.ulds_in?.uld_no &&  awb?.ulds_in?.uld_no.includes(item.uld_type))) && ((item.prod_code  && item.prod_code == awb?.awb?.prod_code) || (item.spl_code && intersection?.length > 0))){
                  if(awb?.ulds_in?.uld_no && item.uld_type && awb?.ulds_in?.uld_no.includes(item.uld_type) ) {
                    var index = dinfoarr?.findIndex(p => p.reference == awb?.ulds_in?.uld_no)
                    if(index > -1) {
                      dinfoarr.splice(index, 1)
                    }
                  }
                  let obj3 = {
                    trigger: 'AWB',
                    reference:  awb.awb_no,
                    dinfo:  d
                  }
                  dinfoarr.push(obj3)
                }
              })
            }
          }
        }
      }

    })
    return dinfoarr
  } else {
    return dinfoarr
  }
}
//CONSTRUCT FLIGHT DATE TIME AS MOMENT OBJECT
const getFlightDateTime = ({ arr }, arriv_date) => {
  let time = false
  if(arr) {
    if(arr?.act) time = parseInt(arr.act)
    else if(arr?.eta) time = parseInt(arr.eta)
    else if(arr?.schdl) time = parseInt(arr.schdl)
    // else if(arr?.utc && arr?.utc !== 'INVALID DATE') time = parseInt(moment.utc(arr.utc).valueOf())
    else time = false
  } else time = false
  return moment(time ? moment.utc(time).local().format(format) : (arr?.utc && arr?.utc !== 'INVALID DATE') ? moment.utc(arr.utc).local().format(format) : moment.utc(arriv_date).local().format(format))
}
//TAKES IN WEIGHT, UNIT AND RETURNS WEIGHT ROUNDED TO NEAREST INTEGER
const weightCalculator = function (weight, unit) {
  switch (unit) {
  case 'KGM':
    weight = weight * 2.204623
    break
  case 'LBS':
    weight = weight * 0.4535924
    break
  default:
    break
  }
  return weight
}
//TAKES IN WEIGHT, RETURN FIXED TO 1 VALUE
const formatWeight = function (weight) {
  if(weight) return parseFloat(weight)?.toFixed(1)
  else return ''
}
//CALC HANDLING TIMES FOR FLIGHT COLUMN
const handlingTimesForFlight = (flight) => {
  let eta = true
  let awbs = []

  if(flight?.arr?.act || flight?.arr?.eta || flight?.arr?.schdl) {
    eta = flight?.arr?.act ? parseInt(flight.arr.act) :
      flight?.arr?.eta ? parseInt(flight.arr.eta) :
        flight?.arr?.schdl ? parseInt(flight.arr.schdl) : false
  } else {
    eta = flight?.arriv_date ? moment.utc(flight?.arriv_date).tz(timezone).valueOf() : false
  }

  if(flight?.awbs_in?.length > 0) {
    flight.awbs_in.map((awb) => {
      let flights_out_date = false, handling_time = 0, conn = false
      if(awb?.info?.flights_out_date !== false) {
        flights_out_date = moment(awb?.info?.flights_out_date).format('YYYY-MM-DD HH:mm')
      }

      if(awb?.info?.handling_time) {
        handling_time = awb.info.handling_time
      }

      awb.show_handling_time_after_tta = false
      if(eta === false) {
        if(moment(moment().utc().format('YYYY-MM-DD HH:mm')).valueOf() > moment(moment(flight?.arr?.utc).format('YYYY-MM-DD HH:mm')).valueOf()) {
          awb.show_handling_time_after_tta = true
        }
      } else {
        if(moment(moment().utc().format('YYYY-MM-DD HH:mm')).valueOf() > moment(moment(moment(eta).utc().format('YYYY-MM-DD HH:mm')).format('YYYY-MM-DD HH:mm')).valueOf()) {
          awb.show_handling_time_after_tta = true
        }
      }

      awb.handling_time_as_timestamp = handling_time > 0 ? formatTimestamp(handling_time) : '00:00'
      awb.handling_time = handling_time > 0 ? formatTimestamp(handling_time) : '00:00'
      awb.ht_minutes = handling_time

      if(eta) {
        conn = moment(eta).utc().add(handling_time, 'minutes').format('YYYY-MM-DD HH:mm')
      } else {
        conn = moment.tz(flight?.arr?.utc, timezone_cty).utc().add(handling_time, 'minutes').format('YYYY-MM-DD HH:mm')
      }

      if (flights_out_date !== false) {
        var minutesDiff = moment(flights_out_date).diff(moment(moment(eta).utc().format('YYYY-MM-DD HH:mm') || flight?.arr?.utc), 'minutes')
        awb.handling_time_as_timestamp = minutesDiff > 0 ? formatTimestamp(minutesDiff) : '00:00'
        awb.ht_minutes = minutesDiff > 0 ? minutesDiff : 0
        if (moment(conn).valueOf() > moment(flights_out_date).valueOf()) {
          awb.handling_time_after_tta = moment.utc(flights_out_date).tz(timezone).local().format('YYYY-MM-DD HH:mm')
          awb.handling_time = moment.utc(flights_out_date).tz(timezone).local().format('YYYY-MM-DD HH:mm')
        } else {
          awb.handling_time_after_tta = moment.utc(flights_out_date).tz(timezone).local().format('YYYY-MM-DD HH:mm')
          awb.handling_time = moment.utc(flights_out_date).tz(timezone).local().format('YYYY-MM-DD HH:mm')
        }

        //IF RECOVERY ACTION MANUALLY TAKEN, DO NOT CHECK FOR BOOKING UNCONFIRMED CHECK.
        if(!awb?.recovery_actions_id) {
          if(awb?.info?.msg && awb?.info?.msg?.toUpperCase() === 'BOOKING UNCONFIRMED') {
            // awb.ht_minutes = 0
            // awb.handling_time_as_timestamp = '00:00'
            // awb.handling_time = moment.utc().local().format('YYYY-MM-DD HH:mm')
            // awb.handling_time_after_tta = moment.utc().local().format('YYYY-MM-DD HH:mm')
          }
        }

      } else {
        awb.handling_time = moment.utc().local().format('YYYY-MM-DD HH:mm')
        awb.handling_time_after_tta = moment.utc().local().format('YYYY-MM-DD HH:mm')
        if (awb?.info?.local) {
          var minutesDiffLocal = moment(conn).diff(moment(moment(eta).utc().format('YYYY-MM-DD HH:mm') || flight?.arr?.utc), 'minutes')
          awb.ht_minutes = minutesDiffLocal > 0 ? minutesDiffLocal : 0
          awb.handling_time_after_tta = moment(moment.utc(conn).local().format('YYYY-MM-DD HH:mm'))
          awb.handling_time = moment.utc(conn).local().format('YYYY-MM-DD HH:mm')
        } else {
          awb.ht_minutes = 0
          awb.handling_time_as_timestamp = '00:00'
        }
      }
      awbs.push(awb)
    })
  }
  return awbs
}
//TAKES IN AWB OBJECT AND FILTERED SYSTEM PARAMETER(BASE ON STATION) AND CALCULATE HANDLING TIME (NEW)
const handlingTimes = (awb, system_params, default_system_params, stations, page) => {
  //CHECK IF SHIPMENT IS EXPORT, BY CHECKING ORG AND DISPLAY FROM INFO START, ELSE TAKE FROM FLIGHTS IN
  let temp_time = awb?.flights_in?.arr?.utc || ''
  if(!temp_time) {
    if(awb?.org === station && awb?.info?.start) {
      temp_time = awb?.info?.start
    }
  }

  //SET IS BOOKING FLAG TRUE
  awb.booking_confirmed = true
  //GET ESTIMATED TIME OF ARRIVAL VALUE
  let eta = true
  if(awb?.flights_in?.arr?.act || awb?.flights_in?.arr?.eta || awb?.flights_in?.arr?.schdl) {
    eta = awb?.flights_in?.arr?.act ? parseInt(awb.flights_in.arr.act) :
      awb?.flights_in?.arr?.eta ? parseInt(awb.flights_in.arr.eta) :
        awb?.flights_in?.arr?.schdl ? parseInt(awb.flights_in.arr.schdl) : false
  } else {
    eta = awb?.flights_in?.arriv_date ? moment.utc(awb?.flights_in?.arriv_date).tz(timezone).valueOf() : false
  }
  //GET FLIGHT OUT VALUE
  let flights_out_date = false
  if(awb?.flights_out?.flight_date) {
    //ADD FLIGHTS OUT DATE
    flights_out_date = moment.tz(`${moment(awb?.flights_out?.flight_date).format('YYYY-MM-DD')} ${awb?.flights_out?.org?.scheduled_dept_time?.substr(0, 2)}:${awb?.flights_out?.org?.scheduled_dept_time?.substr(2)}:00`, timezone_cty).utc().format('YYYY-MM-DD HH:mm')
  } else if(awb?.segs_out?.flight_date) {
    //ADD SEG OUT DATE
    flights_out_date = awb?.segs_out ? moment.tz(`${moment(awb?.segs_out?.flight_date).format('YYYY-MM-DD')} ${awb?.segs_out?.dept_time?.substr(0, 2)}:${awb?.segs_out?.dept_time?.substr(2)}:00`, timezone_cty).utc().format('YYYY-MM-DD HH:mm') : false
  }

  //WHETHER TO ENALE HANDLING TIME COUNTDOWN OR NOT
  awb.show_handling_time_after_tta = false
  if(eta === false) {
    if(moment(moment().utc().format('YYYY-MM-DD HH:mm')).valueOf() > moment(moment(temp_time).format('YYYY-MM-DD HH:mm')).valueOf()) {
      awb.show_handling_time_after_tta = true
    }
  } else {
    if(moment(moment().utc().format('YYYY-MM-DD HH:mm')).valueOf() > moment(moment(moment(eta).utc().format('YYYY-MM-DD HH:mm')).format('YYYY-MM-DD HH:mm')).valueOf()) {
      awb.show_handling_time_after_tta = true
    }
  }
  //CALCULATE HANDLING TIME
  let handling_times_object = calc(awb, system_params, default_system_params, stations, page) 
  let handling_time = handling_times_object?.handling_time || 0
  let fpe = handling_times_object?.fpe || 0

  awb.handling_time_as_timestamp = handling_time > 0 ? formatTimestamp(handling_time) : '00:00'
  awb.handling_time = handling_time > 0 ? formatTimestamp(handling_time) : '00:00'
  awb.ht_minutes = handling_time
  //GET CONNECTION TIME
  let conn = false
  if(eta) {
    conn = moment(eta).utc().add(handling_time, 'minutes').format('YYYY-MM-DD HH:mm')
  } else {
    conn = moment.tz(temp_time, timezone_cty).utc().add(handling_time, 'minutes').format('YYYY-MM-DD HH:mm')
  }

  if(awb?.org === station && awb?.info?.start) {
    conn = moment.utc(awb?.info?.start).add(handling_time, 'minutes').format('YYYY-MM-DD HH:mm')
  }

  //GET DIFFERENCE BETWEEN FLIGHT OUT DATE AND CONNECTION TIME TO GET ACTUAL HANDLING TIME
  if (flights_out_date) {
    flights_out_date = moment(flights_out_date).add(-1*(fpe || 0), 'minutes').format('YYYY-MM-DD HH:mm')
    var minutesDiff = moment(flights_out_date).diff(moment(moment(eta).utc().format('YYYY-MM-DD HH:mm') || temp_time), 'minutes')
    awb.handling_time_as_timestamp = minutesDiff > 0 ? formatTimestamp(minutesDiff) : '00:00'
    awb.ht_minutes = minutesDiff > 0 ? minutesDiff : 0
    if (moment(conn).valueOf() > moment(flights_out_date).valueOf()) {
      awb.handling_time_after_tta = moment.utc(flights_out_date).tz(timezone).local().format('YYYY-MM-DD HH:mm')
      awb.handling_time = moment.utc(flights_out_date).tz(timezone).local().format('YYYY-MM-DD HH:mm')
      awb.handling_time_color = awb?.recovery_actions_id ? 'green' : 'red'
    } else {
      awb.handling_time_after_tta = moment.utc(flights_out_date).tz(timezone).local().format('YYYY-MM-DD HH:mm')
      awb.handling_time = moment.utc(flights_out_date).tz(timezone).local().format('YYYY-MM-DD HH:mm')
      if(moment(moment.utc().format('YYYY-MM-DD HH:mm')).valueOf() < moment(flights_out_date).valueOf()) {
        awb.handling_time_color = 'green'
      } else {
        if(awb?.status?.toUpperCase() === 'COMPLETED' || awb?.status_info?.build_up) {
          awb.handling_time_color = 'green'
        } else {
          awb.handling_time_color = awb?.recovery_actions_id ? 'green' : 'red'
        }
      }
    }
    //IF RECOVERY ACTION MANUALLY TAKEN, DO NOT CHECK FOR BOOKING UNCONFIRMED CHECK.
    if(page === 'INBOUND') {
      if(!awb?.recovery_actions_id) {
        if(awb?.segs_out && !['KK', 'FC', 'MK', 'RC', 'CFM']?.includes(awb?.segs_out?.status)) {
          awb.booking_confirmed = false
          awb.handling_time_color = 'red'

          // awb.ht_minutes = 0
          // awb.handling_time_as_timestamp = '00:00'
          // awb.handling_time = moment.utc().local().format('YYYY-MM-DD HH:mm')
          // awb.handling_time_after_tta = moment.utc().local().format('YYYY-MM-DD HH:mm')
        }
      }
    }
  } else {
    awb.handling_time = moment.utc().local().format('YYYY-MM-DD HH:mm')
    awb.handling_time_after_tta = moment.utc().local().format('YYYY-MM-DD HH:mm')
    if ((awb?.segs_in && awb?.segs_in?.dest != awb?.dest) || (awb?.flights_in && awb?.flights_in?.dest?.arriv != awb?.dest)) {
      awb.ht_minutes = 0
      awb.handling_time_as_timestamp = '00:00'
      if(page === 'INBOUND') {
        awb.handling_time_color = awb?.recovery_actions_id ? 'green' : 'red'
      } else {
        awb.handling_time_color = 'red'
      }
    } else {
      var minutesDiffLocal = moment(conn).diff(moment(moment(eta).utc().format('YYYY-MM-DD HH:mm') || temp_time), 'minutes')
      awb.ht_minutes = minutesDiffLocal > 0 ? minutesDiffLocal : 0
      awb.handling_time_after_tta = moment(moment.utc(conn).local().format('YYYY-MM-DD HH:mm')) //SO HT
      awb.handling_time = moment.utc(conn).local().format('YYYY-MM-DD HH:mm')
      awb.handling_time_color = 'green'
    }
  }
}
//CALC HANDLING TIMES BASED ON MODULE TYPE
const calc = (awb, system_params, default_system_params, stations, page, flight = null) => {
  //MAP LIST OF SYSTEM PARAMS WITH AWB PROD CODE AND RETURNS PARAM OBJECT
  const country = ['USA', 'U.S. VIRGIN ISLANDS', 'PUERTO RICO']
  let org = stations?.find((stn) => stn?.code == awb?.org)
  let dest = stations?.find((stn) => stn?.code == awb?.dest)

  let org_type = false, dest_type = false
  if(org) {
    if(country.includes(org?.country?.toUpperCase())) {
      org_type = 'D'
    } else {
      org_type = 'I'
    }
  }

  if(dest) {
    if(country.includes(dest?.country?.toUpperCase())) {
      dest_type = 'D'
    } else {
      dest_type = 'I'
    }
  }

  let filtered = null
  if(flight){
    dest = stations?.find((stn) => stn?.code == flight.dest_stn)
    if(dest) {
      if(country.includes(dest?.country?.toUpperCase())) {
        dest_type = 'D'
      } else {
        dest_type = 'I'
      }
    }
    let ht = default_system_params?.find((item) => {
      let mvt = item?.details?.process?.split('-')?.[1]?.trim()
      if(mvt == dest_type) {
        return item
      }
    })
    if(ht && ht.dest_list && ht.dest_list.length >0){
      let index = ht.dest_list.findIndex((p) => p.dest === flight?.dest?.arriv)
      if(index > -1){
        return ht.dest_list[index]
      } else {
        filtered = default_system_params.find((item) => {
          let mvt = item?.details?.process?.split('-')?.[1]?.trim()
          if(mvt == dest_type) {
            return item
          }
        })
      }
      
    } else {
      filtered = default_system_params.find((item) => {
        let mvt = item?.details?.process?.split('-')?.[1]?.trim()
        if(mvt == dest_type) {
          return item
        }
      })
    }
  }else {
    filtered = system_params.find((item) => {
      let type = 'Transfer'
      if(awb.dest === station) {
        type = 'Import'
      }
      if(awb.org === station) {
        type = 'Export'
      }

      if (item?.details?.p_code == awb?.awb?.prod_code && item?.details?.process?.includes(type)) {
        if(type == 'Import') {
          let mvt = item?.details?.process?.split('-')?.[1]?.trim()
          if(mvt == org_type) {
            return item
          }
        } else if(type == 'Transfer'){
          let process = item?.details?.process?.split(' ')?.[1]?.trim()
          let mvt = process?.split('/')?.[0]?.trim()
          let mvt2 = process?.split('/')?.[1]?.trim()

          if(mvt == org_type && mvt2 == dest_type) {
            return item
          }
        } else if(type === 'Export') {
          let mvt = item?.details?.process?.split('-')?.[1]?.trim()
          if(mvt == dest_type) {
            return item
          }
        }
      }
    })
    if(!filtered) {
      let type = 'Transfer'
      if(awb.dest === station) {
        type = 'Import'
      }
      if(awb.org === station) {
        type = 'Export'
      }

      filtered = default_system_params.find((item) => {
        if (item?.details?.process?.includes(type)) {
          if(type == 'Import') {
            let mvt = item?.details?.process?.split('-')?.[1]?.trim()
            if(mvt == org_type) {
              return item
            }
          } else if(type == 'Transfer'){
            let process = item?.details?.process?.split(' ')?.[1]?.trim()
            let mvt = process?.split('/')?.[0]?.trim()
            let mvt2 = process?.split('/')?.[1]?.trim()

            if(mvt == org_type && mvt2 == dest_type) {
              return item
            }
          } else if(type === 'Export') {
            let mvt = item?.details?.process?.split('-')?.[1]?.trim()
            if(mvt == dest_type) {
              return item
            }
          }
        }
      })
    }
  }

  //CALCULATE HANDLING TIMES BASED ON MODULE TYPE
  let handling_time = 0, fpe = 0
  if(filtered) {
    let { details } = filtered

    if(flight?.dest_stn === station || awb?.dest === station) { //IF DESTINATION IS ATLANTA (IMPORT)
      let ac_offld = details?.ac_offld ? details.ac_offld : 0
      let tfr_to_wh = details?.tfr_to_wh ? details.tfr_to_wh : 0
      let bd_store = details?.bd_store ? details.bd_store : 0
      handling_time = ac_offld + tfr_to_wh + bd_store
    } else if(flight?.org_stn === station || awb?.org === station) { //EXPORT
      let build_up = details?.build_up ? details.build_up : 0
      //IF REQUIREMENT CHANGES UNCOMMENT THESE LINES 4+5+6+7
      // let accpt = details?.exp_acpt ? details.exp_acpt : 0
      // let trf_to_ac = details?.trf_to_ac ? details.trf_to_ac : 0
      // let at_gate = details?.at_gate ? details.at_gate : 0
      handling_time = build_up

      if(details?.fpe) {
        fpe = details.fpe
      }
    } else { // (TRANSFER)
      switch(page) {
      case 'INBOUND':
        if(awb?.dest !== station) {
          let ac_offld = details?.ac_offld ? details.ac_offld : 0
          let tfr_to_wh = details?.tfr_to_wh ? details.tfr_to_wh : 0
          let bd_store = details?.bd_store ? details.bd_store : 0
          let build_up = details?.build_up ? details.build_up : 0
          let trf_to_ac = details?.trf_to_ac ? details.trf_to_ac : 0
          let at_gate = details?.at_gate ? details.at_gate : 0
          let ac_load = details?.ac_load ? details.ac_load : 0
          handling_time = ac_offld + tfr_to_wh + bd_store + build_up + trf_to_ac + at_gate + ac_load
        }
        break
      case 'BREAKDOWN':
        if(awb?.dest !== station) {
          let build_up = details?.build_up ? details.build_up : 0
          handling_time = build_up

          if(details?.fpe) {
            fpe = details.fpe
          }
        }
        break
      case 'BUILDUP':
        if(awb?.dest !== station) {
          let build_up = details?.build_up ? details.build_up : 0
          //IF REQUIREMENT CHANGES UNCOMMENT THESE LINES 4+5+6+7
          // let accpt = details?.exp_acpt ? details.exp_acpt : 0
          // let trf_to_ac = details?.trf_to_ac ? details.trf_to_ac : 0
          // let at_gate = details?.at_gate ? details.at_gate : 0
          handling_time = build_up

          if(details?.fpe) {
            fpe = details.fpe
          }
        }
        break
      }
    }
  }

  return {
    handling_time: handling_time,
    fpe: fpe,
  }
}
//FORMAT TIMESTAMP FOR HANDLING TIME
const formatTimestamp = (minutes_diff) => {
  var hours = Math.floor(minutes_diff/60)
  var minutes = Math.floor(minutes_diff%60)
  let timestamp = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
  return timestamp
}
//TAKES IN FLIGHT DATE, FLIGHT HISTORY AND AWB IF ANY AND RETURN FLIGHT HISTORY
const flightHistoryCalculator = function (flight) {
  let flightDate = flight?.arriv_date ? flight?.arriv_date : flight?.flight_date

  let history = flight?.flights_historys
  if(history?.length > 0) {
    for(var i=0;i<history.length;i++) {
      let event = history[i]
      let formatted_flight_date_dept = event?.status?.dept?.act ? moment.utc(parseInt(event?.status?.dept?.act)).format(format) :
        event?.status?.dept?.eta ? moment.utc(parseInt(event?.status?.dept?.eta)).format(format) :
          event?.status?.dept?.schdl ? moment.utc(parseInt(event?.status?.dept?.schdl)).format(format) : moment(flight.dept_date).format(format)
      if(event?.status?.flight_leg?.return === true || event?.status?.flight_leg?.return === 'true') {
        event.event_type = 'RETURN'
      }
      if(event?.status?.flight_leg?.cancel === true || event?.status?.flight_leg?.cancel === 'true') {
        event.event_type = 'CANCEL'
      }
      //ELSE CHECK EVENTS
      let actual_arr = '', arr = ''
      if(event?.status?.arr?.act) {
        actual_arr = moment.utc(parseInt(event?.status?.arr?.act)).format(format)
      } else {
        arr = event?.status?.arr?.eta ? moment.utc(parseInt(event?.status?.arr?.eta)).format(format) :
          event?.status?.arr?.schdl ? moment.utc(parseInt(event?.status?.arr?.schdl)).format(format) : moment(flightDate).format(format)
      }
      let schdl = event?.status?.arr?.schdl ? moment.utc(parseInt(event?.status?.arr?.schdl)).format(format) : moment(flightDate).format(format) //Scheduled Arrival time
      switch (event.event_type) {
      case EVENT.FLIGHT_EVENT:
        event.infos = parseFlightEventMessage(
          formatted_flight_date_dept,
          {
            is_act: actual_arr ? true : false,
            arr: actual_arr ? actual_arr : arr
          }, schdl)
        break
      case EVENT.FFM_RECEIVED:
        event.infos = parseFFMMessage(event, flight, null, null)
        break
      case EVENT.RETURN:
        event.infos = formatMessage(formatted_flight_date_dept, event.createdAt, 'RETURN')
        break
      case EVENT.CANCEL:
        event.infos = formatMessage(formatted_flight_date_dept, event.createdAt, 'CANCEL')
        break
      default:
        break
      }
    }
    return history
  }
}
//TAKES IN FLIGHT HISTORY OBJECT
const flightHistoryStatus = (flight) => {
  let event = flight?.history
  let flightDate = flight?.arriv_date ? flight?.arriv_date : flight?.flight_date
  let formatted_flight_date_dept = event?.status?.dept?.act ? moment.utc(parseInt(event?.status?.dept?.act)).format(format) :
    event?.status?.dept?.eta ? moment.utc(parseInt(event?.status?.dept?.eta)).format(format) :
      event?.status?.dept?.schdl ? moment.utc(parseInt(event?.status?.dept?.schdl)).format(format) : moment(flight.dept_date).format(format)
  if(event?.status?.flight_leg?.return === true || event?.status?.flight_leg?.return === 'true') {
    event.event_type = 'RETURN'
  }
  if(event?.status?.flight_leg?.cancel === true || event?.status?.flight_leg?.cancel === 'true') {
    event.event_type = 'CANCEL'
  }
  //ELSE CHECK EVENTS
  let actual_arr = '', arr = ''
  if(event?.status?.arr?.act) {
    actual_arr = moment.utc(parseInt(event?.status?.arr?.act)).format(format)
  } else {
    arr = event?.status?.arr?.eta ? moment.utc(parseInt(event?.status?.arr?.eta)).format(format) :
      event?.status?.arr?.schdl ? moment.utc(parseInt(event?.status?.arr?.schdl)).format(format) : moment(flightDate).format(format)
  }
  let schdl = event?.status?.arr?.schdl ? moment.utc(parseInt(event?.status?.arr?.schdl)).format(format) : moment(flightDate).format(format) //Scheduled Arrival time
  switch (event.event_type) {
  case EVENT.FLIGHT_EVENT:
    return parseFlightEventMessage(
      formatted_flight_date_dept,
      {
        is_act: actual_arr ? true : false,
        arr: actual_arr ? actual_arr : arr
      }, schdl)
  case EVENT.FFM_RECEIVED:
    return parseFFMMessage(event, flight, null, null)
  case EVENT.RETURN:
    return formatMessage(formatted_flight_date_dept, event.createdAt, 'RETURN')
  case EVENT.CANCEL:
    return formatMessage(formatted_flight_date_dept, event.createdAt, 'CANCEL')
  default:
    return null
  }
}
//TAKES IN ULD HISTORY AND AWB IF ANY AND RETURN RESP. HISTORY
const uldawbHistoryCalculator = function (flight_history, flightRec, uldRec, awbRec) {
  if(flight_history?.length > 0) {
    for(var i=0;i<flight_history.length;i++) {
      let event = flight_history[i]
      switch (event.event_type) {
      case EVENT.FFM_OUT_RECEIVED:
        event.infos = parseFFMMessage(event, flightRec, uldRec, awbRec)
        break
      case EVENT.FLIGHT_EVENT:
        event.infos = parseULDAWbEventMessage(event, flightRec, uldRec, awbRec)
        break
      case EVENT.ULD_UPDATED:
        event.infos = parseULDRecoveryActionMessage(event)
        break
      case EVENT.AWB_UPDATED:
        event.infos = parseRecoveryActionMessage(event, awbRec)
        break
      case EVENT.FFM_RECEIVED:
        event.infos = parseFFMMessage(event, flightRec, uldRec, awbRec)
        break
      case EVENT.BOOKING_MESSAGE:
        event.infos = parseULDAWbBookingMessage(event, awbRec ? true : false)
        break
      case EVENT.FSU:
        event.infos = awbRec ? parse_RCF_FSU_MAN_NFD_EventMessage(event) : null
        break
      case EVENT.SEGMENT:
        event.infos = awbRec ? parseSegmentMessage(event) : null
        break
      case EVENT.SYS_PARAMS:
        event.infos = awbRec ? parseSystemParamsUpdatedMessage(event) : null
        break
      default:
        break
      }
    }
  } else if(awbRec && flight_history?.length === 0) {
    //IF AWB DOES NOT HAVE ANY GOOD HISTORY, ADD OBJECT TO DISPLAY HANDLING TIME AT TOP
    awbRec.goods_infos_histories.push({ infos: { opt1: awbRec.handling_time_color === 'green' ? 'Handling times OK' : 'Insufficient handling time' } })
  }
}
//COMPARE ALL AWB DATES AND RETURNS THE EARLIEST DATE
const compareDates = function(array) {
  array.sort((a, b) => b < a ? 1: -1)
  return array[0]
}
//PARSE FLIGHT EVENT
const parseFlightEventMessage = function(flight_date_dept, { is_act, arr }, schdl) {
  if(arr) {
    //CALC DIFFERENCE IN HOURS AND MINUTES
    // let minutes = Math.abs(moment(arr).diff(flight_date_dept, 'minutes'))
    // var hours = Math.floor(minutes / 60)
    // var mins = minutes % 60

    let difference = moment(arr).diff(flight_date_dept, 'minutes')
    let dept_hour_format = moment().set({
      hours: 0,
      minutes: 0,
      seconds: 0,
    }).add(difference, 'minutes').format('HH:mm')

    //SHOW APPROPR MESSAGE
    if(moment(arr).valueOf() < moment(schdl).valueOf()) {
      return {
        display: is_act ? `ARRIVED ${moment.utc(arr).tz(timezone).format('HH:mm')}` : `DEP EARLY ${dept_hour_format}`,
        opt1: is_act ? `Flight arrived ${moment.utc(arr).tz(timezone).format(dateFormat)} ${ext}` : `Departed ${moment.utc(flight_date_dept).tz(timezone).format(dateFormat)} ${ext}`,
        opt2: !is_act ? `ETA: ${moment.utc(arr).tz(timezone).format(dateFormat)} ${ext}`: '',
        msg: `DEP EARLY ${dept_hour_format}`,
        status: !is_act ? 'Early' : ''
      }
    }else if(moment(arr).valueOf() > moment(schdl).valueOf()) {
      return {
        display: is_act ? `ARRIVED ${moment.utc(arr).tz(timezone).format('HH:mm')}` : `DEP LATE ${dept_hour_format}`,
        opt1: is_act ? `Flight arrived ${moment.utc(arr).tz(timezone).format(dateFormat)} ${ext}` : `Departed ${moment.utc(flight_date_dept).tz(timezone).format(dateFormat)} ${ext}`,
        opt2: !is_act ? `ETA: ${moment.utc(arr).tz(timezone).format(dateFormat)} ${ext}`: '',
        msg: `DEP LATE ${dept_hour_format}`,
        status: !is_act ? 'Delay' : ''
      }
    }else if(moment(arr).valueOf() === moment(schdl).valueOf()) {
      return {
        display: is_act ? `ARRIVED ${moment.utc(arr).tz(timezone).format('HH:mm')}` : 'DEP ON TIME',
        opt1: is_act ? `Flight arrived ${moment.utc(arr).tz(timezone).format(dateFormat)} ${ext}` : `Departed ${moment.utc(flight_date_dept).tz(timezone).format(dateFormat)} ${ext}`,
        opt2: !is_act ? `ETA: ${moment.utc(arr).tz(timezone).format(dateFormat)} ${ext}`: '',
        msg: 'DEP ON TIME',
        status: !is_act ? 'On time' : ''
      }
    }
  }
}
//PARSE ULD/AWB EVENT
const parseULDAWbEventMessage = function(event, flight, uld, awb) {
  if(awb) {
    if(!event?.status?.stat) {
      return {
        opt1: 'Handling times OK',
        opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      }
    } else {
      return {
        opt1: 'Insufficient handling time',
        opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      }
    }
  } else if(uld) {
    if(event?.status?.msgs?.length > 0) {
      if(event?.status?.msgs?.length === 1) {
        return {
          // opt1: event?.status?.msgs[0]?.msg?.msg?.toUpperCase() === 'DELIVER TO UNAVAILABLE' ? '\'Deliver To\' unavailable' : event?.status?.msgs[0]?.msg?.msg,
          opt1: event?.status?.msgs[0]?.msg?.msg,
          opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
        }
      } else {
        let flightObj = {}

        //POPULATE FLIGHT OBJECT
        flightObj.arr = flight.arr
        flightObj.awbs_in = []

        //CREATE AWBS ARRAY WITH HISTORY MESSAGES
        event?.status?.msgs?.map((item) => {
          let awbObj = { info: {} }
          if(item?.msg) {
            awbObj.info.flights_out_date = item?.msg?.flights_out_date
            awbObj.info.handling_time = item?.msg?.handling_time
            awbObj.info.local = item?.msg?.local
            awbObj.message = item?.msg?.msg
            flightObj.awbs_in.push(awbObj)
          }
        })
        //CALCULATE HANDLING TIME FOR ALL AWBS AND GET LEAST AWB
        let awbs = handlingTimesForFlight(flightObj)

        let temp = null, message = ''
        awbs?.map((awb) => {
          if(awb.ht_minutes < temp || temp === null) {
            temp = awb.ht_minutes
            message = awb.message
          }
        })

        return {
          // opt1: message?.toUpperCase() === 'DELIVER TO UNAVAILABLE' ? '\'Deliver To\' unavailable' : message,
          opt1: message,
          opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
        }
      }
    }
  }
}
//PARSE ULD/AWB BOOKING
const parseULDAWbBookingMessage = function(event, isAWb) {
  if(isAWb) {
    return {
      opt1: !event?.status?.status ? 'BOOKING RCVD' : 'Booking Unconfirmed',
      opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
    }
  } else {
    if(!event?.status?.stat) {
      return {
        opt1: 'Handling times OK',
        opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      }
    } else {
      return {
        opt1: 'Insufficient handling time',
        opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      }
    }
  }
}
//PARSE RECOVERY ACTION
const parseRecoveryActionMessage = function(event, awbRec) {
  if(awbRec.recovery_actions_id !== null) return {
    opt1: `${event?.event_name}`,
    opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
    msg: '',
    status: ''
  }
  else if(awbRec.handling_time_color === 'green') return {
    opt1: 'Handling times OK',
    opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
    msg: '',
    status: ''
  }
  else return {
    opt1: 'Insufficient handling time',
    opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
    msg: '',
    // msg: '',
    status: ''
  }
}
//PARSE RECOVERY ACTION
const parseULDRecoveryActionMessage = function(event) {
  return {
    opt1: `${event?.event_name}`,
    opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
    msg: '',
    status: ''
  }
}
//PARSE FFM MESSAGE   AWB status history
const parseFFMMessage = function(event, flightRec, uldRec, awbRec) {
  if(awbRec === null && uldRec === null) return {
    display: `${event?.event_name}`,
    opt1: `${event?.event_name}`,
    opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
    msg: '',
    status: ''
  }
  else if(event.event_name === 'OUT'){
    return {
      opt1: 'OUT FFM RCVD', //OR O/B DL123/08DEC
      opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      msg: '',
      status: ''
    }
  }
  else return {
    //opt1: (!awbRec || (awbRec && !awbRec?.flights_out || !awbRec?.segs_out )) ? 'Flight Info Missing' :  (flightRec?.flight_no && flightRec?.flight_date ? 'O/B'+flightRec?.flight_no +'/'+ moment.utc(flightRec?.flight_date).tz(timezone).format('DDMMM') : ''), //OR O/B DL123/08DEC
    opt1: (flightRec?.flight_no && flightRec?.flight_date ? 'O/B'+flightRec?.flight_no +'/'+ moment.utc(flightRec?.flight_date).format('DDMMM') : ''), //OR O/B DL123/08DEC
    opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
    msg: '',
    status: ''
  }
}
//PARSE RCF/FPS/MAN MESSAGE
const parse_RCF_FSU_MAN_NFD_EventMessage = function(event) { 
  if(event?.event_name?.toUpperCase() === 'RCF') {
    return {
      opt1: 'FSU RCF RCVD',
      opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      msg: '',
      status: ''
    }
  } else if(event?.event_name?.toUpperCase() === 'FPS') {
    return {
      opt1: 'FSU FPS RCVD',
      opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      msg: '',
      status: ''
    }
  } else if(event?.event_name?.toUpperCase() === 'MAN') {
    return {
      opt1: 'FSU MAN RCVD',
      opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      msg: '',
      status: ''
    }
  } else if(event?.event_name?.toUpperCase() === 'NFD') {
    return {
      opt1: 'FSU NFD RCVD',
      opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      msg: '',
      status: ''
    }
  } else if(event?.event_name?.toUpperCase() === 'DLV') {
    return {
      opt1: 'FSU DLV RCVD',
      opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
      msg: '',
      status: ''
    }
  }
}
//PARSE SEGMENT MESSAGE
const parseSegmentMessage = function(event) {
  return {
    opt1: 'Booking Unconfirmed',
    opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
    msg: '',
    status: ''
  }
}
//PARSE SYSTEM PARAMS UPDATED MESSAGE
const parseSystemParamsUpdatedMessage = function(event) {
  return {
    opt1: `${event?.event_name}`,
    opt2: `${event?.createdAt ? moment.utc(event.createdAt).tz(timezone).format(dateFormat) +' '+ ext : ''}`,
    msg: '',
    status: ''
  }
}
//FORMAT STATUS MESSAGE
const formatMessage = function(flight_date, flight_dep_can, type) {
  let flight_dep_date = moment.utc(flight_dep_can).tz(timezone).format(dateFormat)
  let flight_dep_time = moment.utc(flight_dep_can).tz(timezone).format(timeFormat)
  flight_date = `${moment.utc(flight_date).tz(timezone).format(dateFormat)} ${ext}`
  if(type === 'RETURN') {
    return {
      display: `RETURNED TO GATE RECEIVED ${flight_dep_time}`,
      opt1: `Departed ${flight_date}`,
      opt2: `Returned to gate received ${flight_dep_date} ${ext}`,
    }
  } else if(type === 'CANCEL') {
    return {
      display: `CANCEL RECEIVED ${flight_dep_time}`,
      opt1: `Departed ${flight_date}`,
      opt2: `Cancel received ${flight_dep_date} ${ext}`,
    }
  }
}
const formatAWBNo = (awb) => {
  let result = ''
  if(awb) {
    var b = '-'
    var position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}

const format_gate = (location = '') => {
  let formatted_gate = location
  if(location?.startsWith('0')) {
    if(location?.length === 3 && location?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}$/g)) {
      formatted_gate = location?.slice(1,2) + location?.slice(0,1) + location?.slice(2)
    } else if(location.length === 4 && location?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{2}$/g)) {
      formatted_gate = location?.slice(1)
    }  else if(location.length === 4 && location?.match(/^[0]{1}[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}$/g)) {
      formatted_gate = location?.slice(1)
    }
  }
  return formatted_gate
}
const cacheRetrieve = async (callback, pageURL, headers, event=null) => {
  let ResponseURL = `${URL}` + pageURL
  if ('caches' in window) {
    // Create a cache and add files to it
    const cache = await caches.open(pageURL.split('?')[0] + '-cache')
    await cache.add(new Request(ResponseURL, { headers }))
    // Retrieve a resource from the cache
    const response = await caches.match(ResponseURL, { headers })
    if (response) {
      callback(response, event)
    }
    else {
      // If the resource is not found in the cache, fetch it from the server
      const response = await fetch(ResponseURL, { headers })
      callback(response, event)
    }
  } else {
    notification.destroy()
    notification.error({
      message: 'Failed',
      description: 'Failed',
    })
    this.setState({
      loading: false,
      servererror : true  
    })
  }
}

export { calc, formatAWBNo, sort, updateData, getFlightDateTime, updateFlightData, updateUldData, weightCalculator, formatWeight, compareDates, getLeastAWB, handlingTimes, processIndividualFlight, flightHistoryCalculator, format_gate, cacheRetrieve }
