import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
// import { Tabs } from 'antd'
import Http from '../../utils/http'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.css'
import '../../pages/system-parameter/index.css'
import PropTypes from 'prop-types'
import { rebookingQueueTabs } from '../../constants/constants'
import RebookingTabs from '../rebooking-queue/rebooking-tabs/rebookingtabs'
import Cookies from 'universal-cookie'

const { Option } = Select
const cookies = new Cookies()
const station = cookies.get('station')
const RebookingQueue = ({ match }) => {

  const [tab, setTab] = useState()
  const [recoveryTeams, setRecoveryTeams] = useState([])
  const [team, setTeam] = useState('')

  useEffect(() => {
    if (match?.params.tab) {
      if (rebookingQueueTabs[match.params.tab]) {
        setTab(rebookingQueueTabs[match.params.tab])
      } else {
        setTab(rebookingQueueTabs.pending)
      }
    } else {
      setTab(rebookingQueueTabs.pending)
    }

    fetchRecoveryTeams()
  }, [])

  const fetchRecoveryTeams = async () => {
    let url = 'recovery_teams'
    let headers = { station: station }
    await Http.get(url, { headers }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        let data = resp?.data?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        setRecoveryTeams(data)
      } else {
        setRecoveryTeams([])
      }
    })
  }

  const tabHandler = (t) => {
    setTab(t)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  return (
    <div>
      <Select allowClear className='rebooking-select' placeholder='Select Rebooking Team' onChange={(e) => setTeam(e)}>
        {
          recoveryTeams?.map((item) => (
            <Option key={item?.id} value={item?.id}>{`${item?.details?.rebook_team} - ${item?.details?.q_priority}`}</Option>
          ))
        }
      </Select>

      <Slider {...settings} className='custom-slider mb-24px'>
        <div className={`tabs ${tab == rebookingQueueTabs.pending? 'active-tab' : ''}`} onClick={() => tabHandler(rebookingQueueTabs.pending)}>Pending Rebooking Requests</div>
        <div className={`tabs ${tab == rebookingQueueTabs.completed ? 'active-tab' : ''}`} onClick={() => tabHandler(rebookingQueueTabs.completed)}>Completed Rebooking Requests</div>
        <div className={`tabs ${tab == rebookingQueueTabs.successful ? 'active-tab' : ''}`} onClick={() => tabHandler(rebookingQueueTabs.successful)}>Successful Rebookings</div>
      </Slider>

      {tab == rebookingQueueTabs.pending &&
        <RebookingTabs prop={rebookingQueueTabs.pending} team={team || ''} />
      }

      {tab == rebookingQueueTabs.completed &&
        <RebookingTabs prop={rebookingQueueTabs.completed} team={team || ''} />
      }

      {tab == rebookingQueueTabs.successful &&
        <RebookingTabs prop={rebookingQueueTabs.successful} team={team || ''} />
      }
    </div>
  )
}

RebookingQueue.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default RebookingQueue