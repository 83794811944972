import React from 'react'
import { Row, Col, InputNumber, Button, Alert } from 'antd'
import planeTail from '../../../../../assets/images/plane-tail.png'
import planeFront from '../../../../../assets/images/plane-front.png'

const INPUTPROPS = {
  className: 'ac-ip',
  type: 'number',
  size: 'small',  
  bordered: false,
  // min: 1,
  // max: 10,
  controls: false,
}

const AcType333 = (props) => {
  const INPUTDISABLED = props.ccPlanModalVisible

  return (
    <div className='parent-div-333'>
      <div className='ac-alert'>{props?.optimal &&(<Alert type='success' message='Optimal Configuration' showIcon />)}{!props?.optimal &&(<Alert type='error' message='Suboptimal Configuration: Unpaired Pallet' classname ='alert-box' showIcon />)}</div>
      <Row gutter={[0]} >
        <div className='top-row-333'>
          <Col >
            <div className='empty-box'>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r41 || props.position?.cargo?.l41 || props.position?.cargo?.r42 || props.position?.cargo?.l42 ? 'disable-col' : ''}>
            <div className={`boxes bl-1 ${props.position?.pallet?.p41 && !props.position?.pallet?.p34? 'bg-danger': ''}`}>
              <span className='box-text'>41P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  disabled={INPUTDISABLED || props.position?.cargo?.r41 || props.position?.cargo?.l41 || props.position?.cargo?.r42 || props.position?.cargo?.l42}
                  value={props.position?.pallet?.p41}
                  onChange={(e) => props.onChange(e, 'p41', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r35 || props.position?.cargo?.l35 || props.position?.cargo?.r41 || props.position?.cargo?.l41? 'disable-col' : '' }>
            <div className={`boxes ${props.position?.pallet?.p34 && !props.position?.pallet?.p41? 'bg-danger': ''}`}>
              <span className='box-text'>34P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p34}
                  disabled={INPUTDISABLED || props.position?.cargo?.r35 || props.position?.cargo?.l35 || props.position?.cargo?.r41 || props.position?.cargo?.l41}
                  onChange={(e) => props.onChange(e, 'p34', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r33 || props.position?.cargo?.l33 || props.position?.cargo?.r34 || props.position?.cargo?.l34? 'disable-col' : '' }>
            <div className={`boxes ${props.position?.pallet?.p33 && !props.position?.pallet?.p32? 'bg-danger': ''}`}>
              <span className='box-text'>33P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p33}
                  disabled={INPUTDISABLED || props.position?.cargo?.r33 || props.position?.cargo?.l33 || props.position?.cargo?.r34 || props.position?.cargo?.l34}
                  onChange={(e) => props.onChange(e, 'p33', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r32 || props.position?.cargo?.l32 || props.position?.cargo?.r33 || props.position?.cargo?.l33? 'disable-col' : '' }>
            <div className={`boxes ${props.position?.pallet?.p32 && !props.position?.pallet?.p33? 'bg-danger': ''}`}>
              <span className='box-text'>32P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p32}
                  disabled={INPUTDISABLED || props.position?.cargo?.r32 || props.position?.cargo?.l32 || props.position?.cargo?.r33 || props.position?.cargo?.l33}
                  onChange={(e) => props.onChange(e, 'p32', 'pallet')}
                />
              </div>
            </div>
          </Col>        
          <Col span={2} className='f-middle'>
            <Button className='clear-bins h-100' onClick={props.clearBin}>
              Clear bins
            </Button>  
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r26 || props.position?.cargo?.l26 || props.position?.cargo?.r27 || props.position?.cargo?.l27? 'disable-col' : '' }>
            <div className={`boxes bl-1 ${props.position?.pallet?.p25 && !props.position?.pallet?.p24? 'bg-danger': ''}`}>
              <span className='box-text'>25P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p25}
                  disabled={INPUTDISABLED || props.position?.cargo?.r26 || props.position?.cargo?.l26 || props.position?.cargo?.r27 || props.position?.cargo?.l27}
                  onChange={(e) => props.onChange(e, 'p25', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.l25 || props.position?.cargo?.r25 || props.position?.cargo?.r26 || props.position?.cargo?.l26? 'disable-col' : '' }>
            <div className={`boxes ${props.position?.pallet?.p24 && !props.position?.pallet?.p25? 'bg-danger': ''}`}>
              <span className='box-text'>24P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p24}
                  disabled={INPUTDISABLED || props.position?.cargo?.r25 || props.position?.cargo?.l25 || props.position?.cargo?.r26 || props.position?.cargo?.l26}
                  onChange={(e) => props.onChange(e, 'p24', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r23 || props.position?.cargo?.l23 || props.position?.cargo?.l24 || props.position?.cargo?.r24? 'disable-col' : '' }>
            <div className={`boxes ${props.position?.pallet?.p23 && !props.position?.pallet?.p22? 'bg-danger': ''}`}>
              <span className='box-text'>23P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p23}
                  disabled={INPUTDISABLED || props.position?.cargo?.r23 || props.position?.cargo?.l23 || props.position?.cargo?.l24 || props.position?.cargo?.r24}
                  onChange={(e) => props.onChange(e, 'p23', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r22 || props.position?.cargo?.l22 || props.position?.cargo?.r23 || props.position?.cargo?.l23 ? 'disable-col' : '' } >
            <div className={`boxes ${props.position?.pallet?.p22 && !props.position?.pallet?.p23? 'bg-danger': ''}`}>
              <span className='box-text'>22P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p22}
                  disabled={INPUTDISABLED || props.position?.cargo?.r22 || props.position?.cargo?.l22 || props.position?.cargo?.r23 || props.position?.cargo?.l23 }
                  onChange={(e) => props.onChange(e, 'p22', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r12 || props.position?.cargo?.l12 || props.position?.cargo?.l21 || props.position?.cargo?.r21 ? 'disable-col' : '' }>
            <div className={`boxes ${props.position?.pallet?.p21 && !props.position?.pallet?.p11? 'bg-danger': ''}`}>
              <span className='box-text'>21P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p21}
                  disabled={INPUTDISABLED || props.position?.cargo?.r12 || props.position?.cargo?.l12 || props.position?.cargo?.l21 || props.position?.cargo?.r21}
                  onChange={(e) => props.onChange(e, 'p21', 'pallet')}
                />
              </div>
            </div>
          </Col>         
          <Col className={INPUTDISABLED || props.position?.cargo?.r11 || props.position?.cargo?.l11 || props.position?.cargo?.r12 || props.position?.cargo?.l12 ? 'disable-col' : ''}>
            <div className={`boxes ${props.position?.pallet?.p11 && !props.position?.pallet?.p21? 'bg-danger': ''}`}>
              <div className='box-text'>11P</div>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p11}
                  disabled={INPUTDISABLED || props.position?.cargo?.r11 || props.position?.cargo?.l11 || props.position?.cargo?.r12 || props.position?.cargo?.l12}
                  onChange={(e) => props.onChange(e, 'p11', 'pallet')}
                />
              </div>
            </div>
          </Col>
        </div>
        
      </Row> 
     
      <Row gutter={[0]}>
        <div className='middle-row a333'>
          <Col>
            <img className= 'plane-back' src={planeTail} />
          </Col>
          <Col className={INPUTDISABLED ? 'disable-col' : '' }>
            <div className='boxes1'>
              <span className='box-text'>Bulk</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={ props.position?.bulk} 
                  onChange={(e) => props.onChange(e, null, 'bulk')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
          </Col> 
          <Col className='disable-col'>
            <div className='top-box'>
              <span className='box-text'>43L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  disabled={true} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'></span>
              {/* <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r42} 
                  onChange={(e) => props.onChange(e, 'r42', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p41} 
                />
              </div> */}
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p41 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>42L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l42} 
                  onChange={(e) => props.onChange(e, 'l42', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p41} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>42R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r42} 
                  onChange={(e) => props.onChange(e, 'r42', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p41} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p34 || props.position?.pallet?.p41 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>41L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l41}
                  onChange={(e) => props.onChange(e, 'l41', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p34 || props.position?.pallet?.p41} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>41R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r41}
                  onChange={(e) => props.onChange(e, 'r41', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p34 || props.position?.pallet?.p41} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p34 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>35L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l35} 
                  onChange={(e) => props.onChange(e, 'l35', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p34} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>35R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r35} 
                  onChange={(e) => props.onChange(e, 'r35', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p34} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p33 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>34L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l34} 
                  onChange={(e) => props.onChange(e, 'l34', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p33} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>34R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r34} 
                  onChange={(e) => props.onChange(e, 'r34', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p33} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p32 || props.position?.pallet?.p33 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>33L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l33}
                  onChange={(e) => props.onChange(e, 'l33', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p32 || props.position?.pallet?.p33} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>33R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r33} 
                  onChange={(e) => props.onChange(e, 'r33', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p32 || props.position?.pallet?.p33} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p32 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>32L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l32} 
                  onChange={(e) => props.onChange(e, 'l32', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p32} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>32R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r32} 
                  onChange={(e) => props.onChange(e, 'r32', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p32} 
                />
              </div>
            </div>
          </Col>
          <Col >
            <div className='boxes2'>
            </div>
          </Col> 
          <Col className={INPUTDISABLED || props.position?.pallet?.p25 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>27L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l27}
                  onChange={(e) => props.onChange(e, 'l27', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p25} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>27R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r27} 
                  onChange={(e) => props.onChange(e, 'r27', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p25} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p24 || props.position?.pallet?.p25 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>26L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l26}
                  onChange={(e) => props.onChange(e, 'l26', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p24 || props.position?.pallet?.p25 } 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>26R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r26} 
                  onChange={(e) => props.onChange(e, 'r26', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p24 || props.position?.pallet?.p25} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p24 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>25L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l25} 
                  onChange={(e) => props.onChange(e, 'l25', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p24} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>25R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r25} 
                  onChange={(e) => props.onChange(e, 'r25', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p24} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p23 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>24L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l24} 
                  onChange={(e) => props.onChange(e, 'l24', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>24R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r24} 
                  onChange={(e) => props.onChange(e, 'r24', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p22 || props.position?.pallet?.p23 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>23L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l23} 
                  onChange={(e) => props.onChange(e, 'l23', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22 || props.position?.pallet?.p23 } 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>23R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r23} 
                  onChange={(e) => props.onChange(e, 'r23', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22 || props.position?.pallet?.p23} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p22 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>22L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l22} 
                  onChange={(e) => props.onChange(e, 'l22', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>22R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r22} 
                  onChange={(e) => props.onChange(e, 'r22', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>           
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p21 ? 'disable-col' : ''} >
            <div className='top-box'>
              <span className='box-text'>21L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l21} 
                  onChange={(e) => props.onChange(e, 'l21', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>         
            </div>
            <div className='bottom-box'>
              <span className='box-text'>21R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r21} 
                  onChange={(e) => props.onChange(e, 'r21', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>         
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p11 || props.position?.pallet?.p21 ? 'disable-col' : ''} >
            <div className='top-box'>
              <span className='box-text'>12L</span>
              <div className='box-line-bottom'>
                <InputNumber {...INPUTPROPS}
                  value={props.position?.cargo?.l12} 
                  onChange={(e) => props.onChange(e, 'l12', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p11 || props.position?.pallet?.p21} />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>12R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r12}
                  onChange={(e) => props.onChange(e, 'r12', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p11 || props.position?.pallet?.p21 } />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p11 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>11L</span>
              <div className='box-line-bottom'>
                <InputNumber {...INPUTPROPS}
                  value={props.position?.cargo?.l11}
                  onChange={(e) => props.onChange(e, 'l11', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>              
            </div>
            <div className='bottom-box'>
              <span className='box-text'>11R</span>
              <div className='box-line-bottom'>
                <InputNumber {...INPUTPROPS}
                  value={props.position?.cargo?.r11}
                  onChange={(e) => props.onChange(e, 'r11', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>
            </div>
          </Col> 
          <Col>
            <img className='plane-front' src={planeFront} />
          </Col>
        </div>
      </Row>
    </div>
  )
}
export default AcType333
