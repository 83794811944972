/* eslint-disable no-unused-vars */
import React, { Component, createRef }from 'react'
import { updateData, formatWeight, updateFlightData, updateUldData, processIndividualFlight, sort, flightHistoryCalculator, cacheRetrieve } from '../../utils/common'
import { pages as PAGE, constants, timezone } from '../../constants/constants'
import { CheckOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import {
  Button,
  Badge,
  DatePicker,
  TimePicker,
  Row,
  Col,
  Typography,
  Select,
  Form,
  Card,
  Table,
  Tooltip,
  Modal,
  notification,
  Spin,
  Checkbox,
  Statistic,
  Popover,
  Input,
} from 'antd'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import Cookies from 'universal-cookie'
import HeaderSearch from '../search/index'
import Notes from '../notes/notes'
import documentIcon from '../../assets/images/document.svg'
import thunderboltIcon from '../../assets/images/thunderbolttwo.svg'
import cssconstants from '../../constants/cssconstants'
import Http from '../../utils/http'
import './inboundflight.css'
import { pageHandler, getCookie, COOKIE } from '../../utils/cookie'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
const user_id = new Cookies().get('id')
const { Title } = Typography
const { Option } = Select
const { Countdown } = Statistic
const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm:ss'
const dateTimeFormat = 'DDMMMYY HH:mm'

let startdt = null
let enddt = null
//GET SYSTEM TIME
function getLocalTime(type) {
  Date.prototype.addHours = function(hour){
    this.setHours(this.getHours() + hour)
    return this
  }
  var today = new Date()
  if(!type) return moment(roundMinutes(today)).format(timeFormat)
  else return moment(roundMinutes(today).addHours(6)).format(timeFormat)
}
//GET TODAY'S DATE
function getDate() {
  var today = new Date()
  return today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()
}
//ROUND UP MINUTES AND RETURN DATE
function roundMinutes(date) {
  date.setHours((date.getHours() + Math.floor(date.getMinutes()/60)))
  date.setMinutes(0, 0, 0)
  return new Date(date)
}
//FORMAT FLIGHT NO/CONNEX NO
function formatFlightConnexNo(flight_no, flight_date, arriv_date) {
  let formatted = ''
  formatted = flight_no +'/'+ moment(flight_date).format('DDMMMYY')
  return formatted
}
//FORMAT AWB NO
function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    var b = '-'
    var position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}
class InboundFlight extends Component {
  container = React.createRef()
  _queue = []
  _isBusy = false

  constructor() {
    super()
    this.state = {
      servererror: false,
      weight_unit: 'KG',
      loading: false,
      checkedGreen: false,
      greenFlightsData: [],
      checkedRed: false,
      redFlightsData: [],
      completedFlightsCheck: false,
      mode: 'top',
      flightNotes: false,
      notesTitle: '',
      dynamicInfoModal: false,
      dynamicInfoTitle: '',
      dynamicInfoData: [],
      distPlan: false,
      distRecord: null,
      search: '',
      inboundFlightsData: [],
      recoveryActions: [],
      workAreaLocations: [],
      systemParameters: [],
      defaultSystemParameters: [],
      dynamicInfo: [],
      stationsData: [],
      ULDData: [],
      AWBData: [],
      flightRow: null,
      flightRowKey: null,
      ULDRow: null,
      ULDRowKey: null,
      AWBRowKey: null,

      searchString: '',
      noteType: '',
      noteID: '',
      show_otr_rsn: false,
      other_record: null,
      other_id: null,
      other_reason: '',

      isListening: false,
      pageCount: 1,
      pageFlag: false,
      //INITIALIZE TO TODAY'S DATE AND TIME(DO NOT CHANGE POSITIONS)
      filterArray: [
        { start: `start=${getDate()}&` },
        { end: `end=${getDate()}&` },
        { stime: `stime=${getLocalTime()}&` },
        { etime: `etime=${getLocalTime('type')}&` },
        { completed: false },
      ],
      start_time: null,
      end_time: null,
      chacheFliterArray: [],
      flightHistoryVisible: false,
      cookieInterval: null,
      ispreviewImgModal: false,
      latestTimestamp: '',
      spinnerForFlights: false,
      spinnerForEvents: false,
      skeletonLoad: true,
      filterOrg: '',
      isfetching: true,

      station: new Cookies().get('station'),
      timezone: new Cookies().get('timezone'),
      user_id: new Cookies().get('id'),
      ext: timezone.find((item) => item.value === new Cookies().get('station'))?.ext || 'EST',
    }
    this.formRef = createRef()
    this._queue = []
    this._isBusy = false
  }
  //CDM
  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log('DEPLOY: ONIX')
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    this.setState({ cookieInterval: setInterval(this.checkInterval, 1000) })

    startdt = moment().tz(this.state.timezone).set({
      minutes: 0,
      second: 0,
    })

    enddt = moment().tz(this.state.timezone).add(6, 'hours').set({
      minutes: 0,
      second:0
    })

    this.setDateTime()
    // const events2 = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=ib&stn=${this.state.station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        if(parsedData?.DETAILS?.user_id){
          this.enqueue(myFakeAsyncJob,1)
          //console.log('High Priority')
        } else {
          this.enqueue(myFakeAsyncJob,2)
          //console.log('Low Priority')
        }
      }
    }
    // events2.onmessage = (event) => {
    //   const parsedData = JSON.parse(event.data)
    //   if(!this.state.isListening) this.setState({ isListening: true })
    //   else {
    //     const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
    //     this.enqueue(myFakeAsyncJob)
    //   }
    // }
    events.onerror = (error) => {
      if(error?.type === 'error') {
        this.setState({ servererror : true })
      }
    }
    this.fetchSystemParameters()
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScrollEvent)

    this.setState({ cookieInterval: clearInterval(this.state.cookieInterval) })
  }
  //SET DATES DISABLED ON CONDITION
  disabledDate = (current) => {
    let prev = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
    let next = moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)
    return current && (current < moment(prev, dateFormat) || current > moment(next, dateFormat))
  }
  checkInterval = () => {
    let newCookie = new Cookies().get('weight')
    if(newCookie !== this.state.weight_unit) {
      this.setState({ weight_unit: newCookie })
    }
  }

  eventList = (parsedData) => {
    //console.log('this._queue.length', this._queue.length)
    // if(parsedData?.DETAILS?.user_id)
    //   console.log('High Priority Processesd')
    // else {
    //   console.log('Low Priority Processesd')
    // }
    if(parsedData?.TYPE === 'ULD_UPDATE') {
      this.fetchULDByID(parseInt(parsedData?.DETAILS?.flight_id), parseInt(parsedData?.DETAILS?.uld_id))
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE') {
      this.fetchAWBByID(parseInt(parsedData?.DETAILS?.flight_id), parseInt(parsedData?.DETAILS?.uld_id), parseInt(parsedData?.DETAILS?.goods_info_id))
    }
    else if(parsedData?.TYPE === 'BOOKING_UPDATE') {
      this.bookingCheck(parseInt(parsedData?.DETAILS?.flight_id), null, null)
    }
    else if(parsedData?.TYPE === 'FLIGHT_UPDATE') {
      this.bookingCheck(parseInt(parsedData?.DETAILS?.flight_id), parsedData?.DETAILS?.status, parsedData?.DETAILS?.stat)
    }
    else if(parsedData?.TYPE === 'FLIGHT_EVENT') {
      this.updateFlightonFlightEvent(parsedData?.DETAILS)
    }
    else if(parsedData?.TYPE === 'FLIGHT_MANIFEST') {
      this.updateFlightonManifest(parsedData?.DETAILS)
    }
    else if(parsedData?.TYPE === 'FLIGHT_STATUS_UPDATE') {
      this.fetchULDsByFlightID(parsedData?.DETAILS?.flight_id, '')
    }
    else if(parsedData?.TYPE === 'NOTES') {
      this.updateFlightAwbNotesCount(parsedData?.DETAILS)
    }
    else if(parsedData?.TYPE === 'DEFAULT_HANDLING_TIMES') {
      this.fetchDefaultSystemParameters('EVENT')
    }
    else if(parsedData?.TYPE === 'STATIONS') {
      this.fetchStations('EVENT')
    }
    else if(parsedData?.TYPE === 'HANDLING_TIMES') {
      this.fetchSystemParameters('EVENT')
    }
    else if(parsedData?.TYPE === 'DYNAMIC_INFO') {
      this.fetchDynamicInfo('EVENT')
    }
    else if(parsedData?.TYPE === 'RECOVERY_ACTION') {
      this.fetchRecoveryactions('EVENT')
    }
    else if(parsedData?.TYPE === 'WORK_AREA_LOCATION') {
      this.fetchWorkAreaLocations('EVENT')
    }
  }

  /*************************************************************** */

  enqueue = (job, type) => {
    //console.log('Enqueing', job)
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      if(type === 1){
        this._queue.unshift({
          job,
          resolve,
          reject
        })
      } else {
        this._queue.push({
          job,
          resolve,
          reject
        })
      }
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    //console.log('next ',next)
    // if the array is empty shift() will return undefined
    if (next) {
      this.setState({ spinnerForEvents: true })
      this._isBusy = true
      next
        .job()
        .then((value) => {
          this.eventList(value)
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          this.setState({ spinnerForEvents: false })
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    } else {
      this.setState({ spinnerForEvents: false })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 600)
      })
  }

  /*************************************************************** */

  //PROCESS DATA
  processData = () => {
    let { search, inboundFlightsData, systemParameters, defaultSystemParameters, dynamicInfo, stationsData } = this.state
    let data = updateData(inboundFlightsData, systemParameters, defaultSystemParameters, dynamicInfo, stationsData)
    if(search) this.getSearchedData(search, data)
    this.setState({ inboundFlightsData: data })
  }
  //SET DATE TIME
  setDateTime = () => {
    this.setState({
      filterArray : [
        { start: `start=${startdt.format(dateFormat) }&` },
        { end: `end=${enddt.format(dateFormat) }&` },
        { stime: `stime=${startdt.format(timeFormat)}&` },
        { etime: `etime=${enddt.format(timeFormat)}&` },
        { completed: false },
      ]
    })
  }
  //FETCH HANDLING TIMES
  fetchSystemParameters = async () => {
    let callback = this.fetchSystemParametersFn
    let pageURL =  `handling_times?station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchSystemParametersFn = async(resp, event) => {
    if(!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ systemParameters: data })
      } else {
        this.setState({ systemParameters: [] })
      }
      if(!event) this.fetchDefaultSystemParameters()
      else {
        this.processData()
        this.setState({ loading: false })
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH DEFAULT HANDLING TIMES
  fetchDefaultSystemParameters = async () => {
    let callback = this.fetchDefaultSystemParametersFn
    let pageURL =  `handling_times?default=true&station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchDefaultSystemParametersFn = async(resp, event) => {
    if(!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ defaultSystemParameters: data })
      } else {
        this.setState({ defaultSystemParameters: [] })
      }
      if(!event) this.fetchDynamicInfo()
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH DYNAMIC INFO
  fetchDynamicInfo = async () => {
    let callback = this.fetchDynamicInfoFn
    let pageURL =  'dynamic_infos'
    let options = {
      headers: {
        station: this.state.station,
        page: PAGE.INBOUND,
      }
    }
    cacheRetrieve(callback, pageURL, options.headers )
  }
  fetchDynamicInfoFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ dynamicInfo: data })
      } else {
        this.setState({ dynamicInfo: [] })
      }
      if(!event) this.fetchRecoveryactions()
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH RECOVERY ACTIONS DATA
  fetchRecoveryactions = async () => {
    let callback = this.fetchRecoveryactionsFn
    let pageURL =  'recovery_actions'
    let headers = {
      station: this.state.station,
      page: PAGE.INBOUND,
    }
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchRecoveryactionsFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ recoveryActions: data })
      } else {
        this.setState({ recoveryActions: [] })
      }
      if(!event) this.fetchWorkAreaLocations()
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH WORK AREA LOCATIONS
  fetchWorkAreaLocations = async () => {
    let callback = this.fetchWorkAreaLocationsFn
    let pageURL =  'work_areas'
    let headers = {
      station: this.state.station,
      page: PAGE.INBOUND,
    }
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchWorkAreaLocationsFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          let sorted_ids = item?.ids?.sort(function(a, b) {
            if (a < b) return -1
            if (a > b) return 1
            return 0
          }) || []
          return {
            ...item,
            ids: sorted_ids,
            key: index
          }
        })
        this.setState({ workAreaLocations: data })
      } else {
        this.setState({ workAreaLocations: [] })
      }
      if(!event) this.fetchStations()
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH STATIONS
  fetchStations = async () => {
    let callback = this.fetchStationsFn
    let pageURL =  'stations'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchStationsFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ stationsData: data })
      } else {
        this.setState({ stationsData: [] })
      }
      if(!event) this.handleInitialFilter(this.state.filterArray)
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  //SET DATE FILTER AND FETCH FLIGHTS
  handleInitialFilter = (array, flightRow, uldRow, search) => {
    let start = array[0]['start']
    let end = array[1]['end']
    let stime = array[2]['stime']
    let etime = array[3]['etime']
    start = moment(start.substring(start.indexOf('=') + 1).replace('&','')).format(dateFormat)
    end = moment(end.substring(end.indexOf('=') + 1).replace('&','')).format(dateFormat)
    stime = stime.substring(stime.indexOf('=') + 1).replace('&','')
    etime = etime.substring(etime.indexOf('=') + 1).replace('&','')

    let fromdate = moment.tz(start+' '+stime, this.state.timezone).utc()
    let todate = moment.tz(end+' '+etime, this.state.timezone).utc()

    let combined_string = `?start=${fromdate.format(dateFormat)}&end=${todate.format(dateFormat)}&stime=${fromdate.format(timeFormat)}&etime=${todate.format(timeFormat)} `
    //let combined_string = `?${start}${end}${stime}${etime}`
    let url = combined_string && combined_string.substring(0, combined_string.length - 1)
    this.fetchInboundFlights(url, search)
  }

  /********************************************************************************************** */

  fetchInboundFlights = async (filters, search = '') => {
    if(this.state.search && !search ){
      // do nothing
    } else {
      let temp_timestamp = moment().valueOf()
      this.setState({
        checkedGreen: this.state.checkedGreen ? true : false,
        checkedRed: this.state.checkedRed ? true : false,
        loading: this.state.search || this.state.pageCount === 1 ? true : false,
        spinnerForFlights: true,
        pageFlag: true,
        latestTimestamp: temp_timestamp,
        isfetching: true,
      })
      let url = search ? `inbound_flight_list${search}` : filters ? `inbound_flight_list${filters}&page=${this.state.pageCount}&count=25` : 'inbound_flight_list'
      await Http.get(url, {
        headers: {
          page: PAGE.INBOUND,
          station: this.state.station,
          timezone: this.state.timezone,
        }
      }).then(resp => {
        if(this.state.latestTimestamp === temp_timestamp) {
          if (resp && resp.status === 200) {
            if (resp && resp?.data && resp?.data?.data?.length > 0) {
              let data = resp?.data?.data
              if((search === '' || search === null)) {
                data = this.state.inboundFlightsData?.concat(resp?.data?.data)
              }
              let newData = []
              let orgArray = []
              data.map((item, index) => {
                orgArray.push(item.org.origin + '-' + this.state.station)
                orgArray = orgArray.filter((item, index) => orgArray.indexOf(item) === index)
                orgArray = orgArray.sort()
                var id = newData.findIndex(p => p.id == parseInt(item.id))
                if(id > -1) {
                  //DO NOTHING
                } else {
                  //item.key = index
                  newData.push({ ...processIndividualFlight(item, this.state.dynamicInfo) })
                }
              })
              sort(newData, 'id')
              sort(newData, 'arriv_date')
              sort(newData, 'arriving_datetime')
              //newData = updateFlightData(newData, this.state.dynamicInfo)
              this.setState({
                inboundFlightsData: newData,
                loading: false,
                pageCount: this.state.pageCount + 1,
                pageFlag: false,
                filterOrg: orgArray,
                isfetching: false,
              }, () => {
                if(this.state.checkedGreen) {
                  this.showGreenFlights()
                } else if(this.state.checkedRed) {
                  this.showRedFlights()
                }
                if(!this.state.search) {
                  this.formatURL(this.state.filterArray, null, null)
                }
  
                if(search) {
                  this.setState({ spinnerForFlights: false })
                }
              })
            } else {
              if(this.state.pageCount > 1) {
                //DO NOTHING
              } else {
                this.setState({ loading: false }, () => {
                  notification.destroy()
                  notification.error({
                    message: constants.NO_DATA_ERROR,
                    description: resp?.data?.message,
                  })
                })
              }
              this.setState({ 
                spinnerForFlights: false,
                isfetching: false,
              })
            }
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message ? resp?.data?.message : constants.SERVER_CONNECTION_ERROR,
            })
          }
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          spinnerForFlights: false,
          servererror : true
        })
      })
  
      this.setState({ loading: false })
    }
  }
  fetchULDsByFlightID = async (ID, TYPE) => {
    let { flightRow, ULDRow } = this.state
    let inboundData = cloneDeep(this.state.inboundFlightsData)

    //CHECK IF ULDS ARE PRESENT ELSE MAKE FETCH CALL
    if(TYPE === 'SELECT' && (flightRow?.ulds?.length === 0 || flightRow?.uld_data?.length > 0)) {
      this.setState({
        ULDData: flightRow.uld_data,
        AWBData: [],
      })
    } else {
      let url = `ulds?flights_id=${ID}`
      let headers = {
        station: this.state.station,
        page: PAGE.INBOUND,
      }
      await Http.get(url, { headers }).then((resp) => {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          //STORE ULD DATA IN FLIGHT OBJECT
          inboundData = this.state.inboundFlightsData
          var index = inboundData?.findIndex(p => p.id == parseInt(ID))
          if(index > -1) {
            if(inboundData[index]?.id === flightRow?.id) {
              inboundData[index].uld_data = updateUldData(data, flightRow, this.state.systemParameters, this.state.defaultSystemParameters, this.state.stationsData)
              let uld_index = inboundData[index].uld_data.findIndex(p => p.id === ULDRow?.id)
              if(uld_index > -1) {
                this.setState({
                  ULDData: [],
                  AWBData: [],
                }, () => {
                  // FILTERS CHECKED CHECK
                  this.setState({
                    inboundFlightsData: inboundData,
                    ULDData: inboundData[index].uld_data,
                    AWBData: inboundData[index].uld_data[uld_index].awbs_in,
                  }, () => {
                    if(this.state.checkedGreen) {
                      this.showGreenFlights()
                    } else if(this.state.checkedRed) {
                      this.showRedFlights()
                    }
                  })
                })
              } else {
                // FILTERS CHECKED CHECK
                this.setState({
                  ULDData: [],
                  AWBData: [],
                }, () => {
                  // FILTERS CHECKED CHECK
                  this.setState({
                    inboundFlightsData: inboundData,
                    ULDData: inboundData[index].uld_data,
                  }, () => {
                    if(this.state.checkedGreen) {
                      this.showGreenFlights()
                    } else if(this.state.checkedRed) {
                      this.showRedFlights()
                    }
                  })
                })
              }
            } else {
              if(data && data.length >0 && inboundData[index]?.uld_data){
                inboundData[index].uld_data = updateUldData(data, inboundData[index], this.state.systemParameters, this.state.defaultSystemParameters, this.state.stationsData)
              }
              this.setState({ inboundFlightsData: inboundData }, () => {
                if(this.state.checkedGreen) {
                  this.showGreenFlights()
                } else if(this.state.checkedRed) {
                  this.showRedFlights()
                }
              })
            }
          }
        } else {
          this.setState({
            ULDData: [],
            AWBData: [],
            loading: false,
            servererror : resp?.data?.message ? this.state.servererror : true
          }, () => {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message ? resp?.data?.message : constants.SERVER_CONNECTION_ERROR,
            })
          })
        }
      })
    }
  }
  fetchDistributionPlanByFlightID = async(flight) => {
    let url = `inbound_flights?id=${flight.id}`
    let headers = {
      station: this.state.station,
      page: PAGE.INBOUND,
    }
    this.setState({
      distRecord: '',
      skeletonLoad: true,
      distPlan: true,
    })
    await Http.get(url, { headers }).then((resp) => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        let data = resp?.data?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        data[0].uld_data = updateUldData(data[0].ulds, flight, this.state.systemParameters, this.state.defaultSystemParameters, this.state.stationsData)
        data = updateFlightData(data, this.state.dynamicInfo)
        this.setState({
          distRecord: data,
          distPlan: true,
          skeletonLoad : false,
        })
      } else {
        this.setState({
          distRecord: [],
          distPlan: false,
          skeletonLoad : false,
          servererror : resp?.data?.message ? this.state.servererror : true,
        }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message ? resp?.data?.message : constants.SERVER_CONNECTION_ERROR,
          })
        })
      }
    })
  }
  fetchFlightByID = async (FLIGHT_ID) => {
    let url = `inbound_flights?id=${parseInt(FLIGHT_ID)}`
    let headers = {
      station: this.state.station,
      page: PAGE.INBOUND,
    }
    await Http.get(url, { headers }).then((resp) => {
      if (resp?.data?.data?.length > 0) {
        if(resp?.data?.data[0]?.status !== 'COMPLETED') {
          let flightData = cloneDeep(this.state.inboundFlightsData)
          let flight_index = flightData?.findIndex((p) => p.id === parseInt(FLIGHT_ID))
          if(flight_index > -1) {
            flightData[flight_index] = processIndividualFlight(resp?.data?.data[0], this.state.dynamicInfo)

            if(this.state.flightRow?.id === parseInt(FLIGHT_ID)) {
              this.clearState()
            }

            this.setState({ inboundFlightsData: [] }, () => this.setState({ inboundFlightsData: flightData }, () => {
              if(this.state.checkedGreen) {
                this.showGreenFlights()
              } else if(this.state.checkedRed) {
                this.showRedFlights()
              }
            }))
          }
        }
      }
    })
  }
  fetchULDByID = async (FLIGHT_ID, ULD_ID) => {
    let { flightRow, ULDRow } = this.state
    let uldFlag = false, flightFlag = false
    //CHECK IF ULDS ARE PRESENT ELSE MAKE FETCH CALL
    let inboundData = cloneDeep(this.state.inboundFlightsData)
    var index = inboundData?.findIndex(p => p.id == parseInt(FLIGHT_ID))
    if(index > -1) {
      if(flightRow?.id === inboundData[index]?.id) {
        flightFlag = true
      }
      if(inboundData[index]?.uld_data?.length > 0) {
        let url = `ulds?id=${ULD_ID}`
        let headers = {
          station: this.state.station,
          page: PAGE.INBOUND,
        }
        await Http.get(url, { headers }).then((resp) => {
          if (resp && resp?.data && resp?.data?.data?.length > 0) {
            let data = resp?.data?.data

            inboundData = this.state.inboundFlightsData
            index = inboundData?.findIndex(p => p.id == parseInt(FLIGHT_ID))
            var uld_index = inboundData[index]?.uld_data?.findIndex(p => p.id == parseInt(ULD_ID))
            if(uld_index > -1) {
              if(inboundData[index].uld_data[uld_index].id === data[0].id) {
                if(ULDRow?.id === inboundData[index].uld_data[uld_index].id) {
                  uldFlag = true
                }
                let object = updateUldData(data, inboundData[index], this.state.systemParameters, this.state.defaultSystemParameters, this.state.stationsData)
                inboundData[index].uld_data[uld_index] = object[0]
                let awbs = object[0]?.awbs_in

                // FILTERS CHECKED CHECK
                this.setState({ inboundFlightsData: [] }, () => {
                  if(flightFlag) {
                    this.setState({ ULDData: [] }, () => this.setState({ ULDData: inboundData[index].uld_data }))
                  }
                  if(uldFlag) {
                    this.setState({ AWBData: [] }, () => this.setState({ AWBData: awbs }))
                  }
                  this.setState({ inboundFlightsData: inboundData }, () => {
                    if(this.state.checkedGreen) {
                      this.showGreenFlights()
                    } else if(this.state.checkedRed) {
                      this.showRedFlights()
                    }
                  })
                })
              }
            }
          }
        })
      }
    }
  }
  fetchAWBByID = async (FLIGHT_ID, ULD_ID, AWB_ID) => {
    let { flightRow, ULDRow } = this.state
    let uldFlag = false, flightFlag = false

    let inboundData = cloneDeep(this.state.inboundFlightsData)
    var flight_index = inboundData?.findIndex(p => p.id == parseInt(FLIGHT_ID))
    if(flight_index > -1) {
      if(flightRow?.id === inboundData[flight_index].id) {
        flightFlag = true
      }
      if(inboundData[flight_index]?.uld_data?.length > 0) {
        var uld_index = inboundData[flight_index]?.uld_data?.findIndex(p => p.id == parseInt(ULD_ID))
        if(uld_index > -1) {
          if(ULDRow?.id === inboundData[flight_index]?.uld_data[uld_index]?.id) {
            uldFlag = true
          }
          var awb_index = inboundData[flight_index]?.uld_data[uld_index]?.awbs_in?.findIndex(p => p.id == parseInt(AWB_ID))
          if(awb_index > -1) {
            if(inboundData[flight_index]?.uld_data[uld_index]?.awbs_in?.length > 0) {
              let url = `awbs?id=${AWB_ID}`
              let headers = {
                station: this.state.station,
                page: PAGE.INBOUND,
              }
              await Http.get(url, { headers }).then((resp) => {
                if (resp && resp?.data && resp?.data?.data?.length > 0) {
                  let data = resp?.data?.data
                  inboundData = this.state.inboundFlightsData
                  flight_index = inboundData?.findIndex(p => p.id == parseInt(FLIGHT_ID))
                  if(flight_index > -1) {
                    if(inboundData[flight_index]?.uld_data[uld_index]?.awbs_in[awb_index]?.awbs_id === data[0].awbs_id) {
                      inboundData[flight_index].uld_data[uld_index].awbs_in[awb_index] = data[0]
                      let uldArr = []
                      uldArr.push(inboundData[flight_index].uld_data[uld_index])

                      let uldData = updateUldData(uldArr, inboundData[flight_index], this.state.systemParameters, this.state.defaultSystemParameters, this.state.stationsData)
                      inboundData[flight_index].uld_data[uld_index] = uldData[0]
                      let awbs = uldData[0]?.awbs_in

                      // FILTERS CHECKED CHECK
                      this.setState({ inboundFlightsData: [] }, () => {
                        if(flightFlag) {
                          this.setState({ ULDData: [] }, () => this.setState({ ULDData: inboundData[flight_index].uld_data }))
                        }
                        if(uldFlag) {
                          this.setState({ AWBData: [] }, () => this.setState({ AWBData: awbs }))
                        }
                        this.setState({ inboundFlightsData: inboundData }, () => {
                          if(this.state.checkedGreen) {
                            this.showGreenFlights()
                          } else if(this.state.checkedRed) {
                            this.showRedFlights()
                          }
                        })
                      })
                    }
                  }
                }
              })
            }
          }
        }
      }
    }
  }
  bookingCheck = async (FLIGHT_ID, FLIGHT_STATUS, FLIGHT_STAT) => {
    let { flightRow, ULDRow, search } = this.state

    let inboundData = cloneDeep(this.state.inboundFlightsData)
    var index = inboundData?.findIndex(p => p.id == parseInt(FLIGHT_ID))
    if(index > -1) {
      if(FLIGHT_STAT?.toString()) {
        if(search && inboundData[index]?.id === parseInt(FLIGHT_ID)) {
          inboundData[index].stat = FLIGHT_STAT.toString() === 'true' ? true : false
        } else if(FLIGHT_STATUS?.toUpperCase() === 'COMPLETED' && FLIGHT_STAT.toString() === 'false') {
          // inboundData.splice(index, 1)
          // if(flightRow?.id === parseInt(FLIGHT_ID)) {
          //   this.clearState()
          // }
          if(this.state.checkedGreen || this.state.checkedRed) {
            inboundData.splice(index, 1)
          } else {
            inboundData[index].splice_countdown = this.createSpliceCountdown()
            inboundData[index].fstatus = 'COMPLETED'
          }
        } else if(inboundData[index]?.status?.toUpperCase() === 'COMPLETED' && FLIGHT_STAT.toString() === 'false') {
          // inboundData.splice(index, 1)
          // if(flightRow?.id === parseInt(FLIGHT_ID)) {
          //   this.clearState()
          // }
          if(this.state.checkedGreen || this.state.checkedRed) {
            inboundData.splice(index, 1)
          } else {
            inboundData[index].splice_countdown = this.createSpliceCountdown()
            inboundData[index].fstatus = 'COMPLETED'
          }
        } else {
          inboundData[index].stat = FLIGHT_STAT.toString() === 'true' ? true : false
        }
      } else {
        if(flightRow?.id === parseInt(FLIGHT_ID) || inboundData[index]?.uld_data?.length > 0) {
          let url = `ulds?flights_id=${FLIGHT_ID}`
          let headers = {
            station: this.state.station,
            page: PAGE.INBOUND,
          }
          await Http.get(url, { headers }).then((resp) => {
            if (resp && resp?.data && resp?.data?.data?.length > 0) {
              let data = resp?.data?.data.map((item, index) => {
                return {
                  ...item,
                  key: index
                }
              })
              let uldData = updateUldData(data, inboundData[index], this.state.systemParameters, this.state.defaultSystemParameters, this.state.stationsData)
              inboundData = this.state.inboundFlightsData
              index = inboundData?.findIndex(p => p.id == parseInt(FLIGHT_ID))
              if(index > -1) {
                inboundData[index].uld_data = uldData
                if(ULDRow?.id) {
                  var uld_index = inboundData[index]?.uld_data?.findIndex(p => p.id == parseInt(ULDRow.id))
                  if(uld_index > -1) {
                    this.setState({ AWBData: [] }, () => this.setState({ AWBData: inboundData[index]?.uld_data[uld_index]?.awbs_in }))
                  }
                  this.setState({ ULDData: inboundData[index].uld_data })
                }
              }
            } else {
              this.setState({
                ULDData: [],
                AWBData: [],
              })
            }
          })
          if(inboundData[index]?.uld_data?.length > 0) {
            let uldData = updateUldData(inboundData[index].uld_data, flightRow, this.state.systemParameters, this.state.defaultSystemParameters, this.state.stationsData)
            inboundData = this.state.inboundFlightsData
            inboundData[index].uld_data = uldData
            this.setState({ ULDData: [] }, () => this.setState({ ULDData: inboundData[index].uld_data }))

            if(ULDRow?.id) {
              var uld_index = inboundData[index]?.uld_data?.findIndex(p => p.id == parseInt(ULDRow.id))
              if(uld_index > -1) {
                this.setState({ AWBData: [] }, () => this.setState({ AWBData: inboundData[index]?.uld_data[uld_index]?.awbs_in }))
              }
            }
          }
        }
      }
      let datas = updateFlightData(inboundData, this.state.dynamicInfo)
      // FILTERS CHECKED CHECK
      this.setState({ inboundFlightsData: [] }, () => this.setState({ inboundFlightsData: datas }, () => {
        if(this.state.checkedGreen) {
          this.showGreenFlights()
        } else if(this.state.checkedRed) {
          this.showRedFlights()
        } else {
          if(!search && FLIGHT_STAT?.toString() && FLIGHT_STATUS?.toUpperCase() !== 'COMPLETED' && FLIGHT_STAT.toString() === 'true' && inboundData[index].uld_data && inboundData[index].uld_data.length> 0) {
            this.fetchULDsByFlightID(FLIGHT_ID, '')
          }
        }
      }))
    } else {
      this.updateFlightsElse(inboundData, FLIGHT_ID, FLIGHT_STATUS)
    }
  }
  updateFlightonFlightEvent = async (FLIGHT) => {
    let { flightRow, ULDRow, search } = this.state

    let inboundData = cloneDeep(this.state.inboundFlightsData)
    var index = inboundData?.findIndex(p => p.id == parseInt(FLIGHT?.flight?.id))
    if(index > -1) {
      //IF FLIGHT STATUS IS CANCELLED OR NIL, REMOVE FROM FLIGHTS DATA
      if(!search && (FLIGHT?.flight?.status === 'CANCEL' || FLIGHT?.flight?.status === 'NIL')) {
        if(this.state.checkedGreen || this.state.checkedRed) {
          inboundData.splice(index, 1)
        } else {
          inboundData[index].splice_countdown = this.createSpliceCountdown()
          inboundData[index].fstatus = 'COMPLETED'
        }
      }
      else {
        let flag = FLIGHT?.flight?.stat.toString() === 'true' ? true : false
        if(this.state.checkedGreen || this.state.checkedRed) {
          if(flag === true) {
            // inboundData.splice(index, 1)
            //inboundData[index].splice_countdown = this.createSpliceCountdown()
            inboundData[index].stat = flag
          }
        }
        inboundData[index].arr = FLIGHT?.flight?.arr
        if(FLIGHT.history !== 'false') {
          if(inboundData[index].flights_historys && inboundData[index].flights_historys.length > 0){
            inboundData[index].flights_historys = inboundData[index].flights_historys.push(FLIGHT?.history)
          }
          inboundData[index].history = FLIGHT?.history
          inboundData[index] = processIndividualFlight(inboundData[index], this.state.dynamicInfo)
          if(flightRow?.id === parseInt(FLIGHT?.flight?.id)) {
            if(inboundData[index]?.uld_data?.length > 0) {
              inboundData[index].uld_data.map((uld, uld_index) => {
                inboundData[index].uld_data[uld_index].ulds_historys = [{
                  ...FLIGHT?.history,
                  status: { stat: uld.handling_time_color ? false : true }
                } , ...uld.ulds_historys]

                let awb_in = []
                uld?.awbs_in?.map((awb) => {
                  awb_in.push({
                    ...awb,
                    goods_infos_histories: [{
                      ...FLIGHT?.history,
                      status: { stat: awb.handling_time_color === 'red' ? true : false }
                    } , ...awb.goods_infos_histories]
                  })
                })
                inboundData[index].uld_data[uld_index].awbs_in = awb_in
              })
              inboundData[index].uld_data = updateUldData(inboundData[index].uld_data, flightRow, this.state.systemParameters, this.state.defaultSystemParameters, this.state.stationsData)
              this.setState({ ULDData: [] }, () => this.setState({ ULDData: inboundData[index].uld_data }))

              if(ULDRow?.id) {
                var uld_index = inboundData[index]?.uld_data?.findIndex(p => p.id == parseInt(ULDRow.id))
                if(uld_index > -1) {
                  this.setState({ AWBData: [] }, () => this.setState({ AWBData: inboundData[index]?.uld_data[uld_index]?.awbs_in }))
                }
              }
            }
          }
        }
      }

      let datas = updateFlightData(inboundData, this.state.dynamicInfo)
      // FILTERS CHECKED CHECK
      this.setState({ inboundFlightsData: [] }, () => this.setState({ inboundFlightsData: datas }, () => {
        if(this.state.checkedGreen) {
          this.showGreenFlights()
        } else if(this.state.checkedRed) {
          this.showRedFlights()
        }
      }))
    } else {
      this.updateFlightsElse(inboundData, FLIGHT?.flight?.id, FLIGHT?.flight?.status)
    }
  }
  updateFlightonManifest = async (DETAILS) => {
    let { flightRow, search } = this.state

    let inboundData = cloneDeep(this.state.inboundFlightsData)
    var index = inboundData?.findIndex(p => p.id == parseInt(DETAILS?.flight?.id))
    let isFlightRangeExist = false

    if(!this.state.search) {
      let start = this.state.filterArray[0]['start']
      let end = this.state.filterArray[1]['end']
      let stime = this.state.filterArray[2]['stime']
      let etime = this.state.filterArray[3]['etime']
      start = moment(start.substring(start.indexOf('=') + 1).replace('&','')).format(dateFormat)
      end = moment(end.substring(end.indexOf('=') + 1).replace('&','')).format(dateFormat)
      stime = stime.substring(stime.indexOf('=') + 1).replace('&','')
      etime = etime.substring(etime.indexOf('=') + 1).replace('&','')

      let fromdate = moment.tz(start+' '+stime, this.state.timezone).utc().valueOf()
      let todate = moment.tz(end+' '+etime, this.state.timezone).utc().valueOf()

      let local_tz_arriv_date = ''
      if(DETAILS?.arr?.act || DETAILS?.arr?.eta || DETAILS?.arr?.schdl) {
        local_tz_arriv_date = DETAILS?.arr?.act ? moment(parseInt(DETAILS?.arr?.act)).valueOf() :
          DETAILS?.arr?.eta ? moment(parseInt(DETAILS?.arr?.eta)).valueOf() :
            DETAILS?.arr?.schdl ? moment(parseInt(DETAILS?.arr?.schdl)).valueOf() : ''
      } else {
        local_tz_arriv_date = moment.utc(DETAILS?.arriv_date).tz(this.state.timezone).valueOf()
      }
      if(local_tz_arriv_date > fromdate && local_tz_arriv_date < todate) {
        isFlightRangeExist = true
      }
    }

    if(index > -1) {
      //IF FLIGHT STATUS IS CANCELLED OR NIL, REMOVE FROM FLIGHTS DATA
      if(!search && (DETAILS?.flight?.status === 'CANCEL' || DETAILS?.flight?.status === 'NIL')) {
        if(this.state.checkedGreen || this.state.checkedRed) {
          inboundData.splice(index, 1)
        } else {
          inboundData[index].splice_countdown = this.createSpliceCountdown()
          inboundData[index].fstatus = 'COMPLETED'
        }
      } else {
        inboundData[index].history = DETAILS?.history
        inboundData[index] = processIndividualFlight(inboundData[index], this.state.dynamicInfo)
        inboundData[index].ulds = DETAILS.flight.ulds
        inboundData[index].awbs_in = DETAILS.flight.awbs_in

        if(flightRow?.id === parseInt(DETAILS?.flight?.id)) {
          let url = `inbound_flights?id=${parseInt(DETAILS?.flight?.id)}`
          let headers = {
            station: this.state.station,
            page: PAGE.INBOUND,
          }
          await Http.get(url, { headers }).then((resp) => {
            if (resp && resp?.data && resp?.data?.data?.length > 0) {
              let uldData = updateUldData(resp.data.data[0].ulds, flightRow, this.state.systemParameters, this.state.defaultSystemParameters, this.state.stationsData)
              inboundData = this.state.inboundFlightsData
              index = inboundData?.findIndex(p => p.id == parseInt(DETAILS?.flight?.id))
              inboundData[index].uld_data = uldData
              this.setState({ ULDData: [] }, () => this.setState({ ULDData: inboundData[index].uld_data }))
            }})
        }
      }

      let datas = updateFlightData(inboundData, this.state.dynamicInfo)
      // FILTERS CHECKED CHECK
      this.setState({ inboundFlightsData: [] }, () => this.setState({ inboundFlightsData: datas }, () => {
        if(this.state.checkedGreen) {
          this.showGreenFlights()
        } else if(this.state.checkedRed) {
          this.showRedFlights()
        }
      }))
    }
    else if(isFlightRangeExist) {
      let url = `inbound_flights?id=${parseInt(DETAILS?.flight?.id)}`
      let headers = {
        station: this.state.station,
        page: PAGE.INBOUND,
      }
      await Http.get(url, { headers }).then((resp) => {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          inboundData = this.state.inboundFlightsData
          if((!this.state.search && !this.state.completedFlightsCheck) && (resp?.data?.data[0]?.status?.toUpperCase() === 'COMPLETED' && resp?.data?.data[0]?.stat === false)) {
            //DO NOTHING
          } else {
            if(this.state.completedFlightsCheck && (resp?.data?.data[0]?.status?.toUpperCase() === 'COMPLETED' && resp?.data?.data[0]?.stat === false)) {
              inboundData?.push(processIndividualFlight(resp?.data?.data[0], this.state.dynamicInfo))
            } else if(!this.state.completedFlightsCheck) {
              //PUSH FLIGHT OBJECT BASED ON STAT COLOR FOR FILTERS
              inboundData?.push(processIndividualFlight(resp?.data?.data[0], this.state.dynamicInfo))
            }
          }
        }
      })

      let datas = updateFlightData(inboundData, this.state.dynamicInfo)
      // FILTERS CHECKED CHECK
      this.setState({ inboundFlightsData: [] }, () => this.setState({ inboundFlightsData: datas }, () => {
        if(this.state.checkedGreen) {
          this.showGreenFlights()
        } else if(this.state.checkedRed) {
          this.showRedFlights()
        }
      }))
    }
  }
  updateFlightAwbNotesCount = (DETAILS) => {
    let inboundData = cloneDeep(this.state.inboundFlightsData)

    var flight_index = inboundData?.findIndex(p => p.id == parseInt(DETAILS?.flights_id))
    if(flight_index > -1) {
      if(DETAILS?.type?.toUpperCase() === 'FLIGHT') {
        inboundData[flight_index].notes_count = DETAILS?.notes?.length || 0
      } else if(DETAILS?.type?.toUpperCase() === 'AWB') {
        if(inboundData[flight_index]?.uld_data?.length > 0) {
          let uld_index = inboundData[flight_index]?.uld_data?.findIndex((p) => p.id === parseInt(DETAILS?.ulds_id))
          if(uld_index > -1) {
            let uld_id = inboundData[flight_index].uld_data[uld_index]?.id
            if(inboundData[flight_index]?.uld_data[uld_index]?.awbs_in?.length > 0) {
              let awb_index = inboundData[flight_index].uld_data[uld_index].awbs_in.findIndex((p) => p.awbs_id === parseInt(DETAILS?.awbs_id))
              if(awb_index > -1) {
                inboundData[flight_index].uld_data[uld_index].awbs_in[awb_index].awb.notes = DETAILS?.notes
                if(this.state.ULDRowKey === uld_id) {
                  this.setState({ AWBData: [] }, () => this.setState({ AWBData: inboundData[flight_index].uld_data[uld_index].awbs_in }))
                }
              }
            }
          }
        }
      }

      this.setState({ inboundFlightsData: [] }, () => this.setState({ inboundFlightsData: inboundData }, () => {
        if(this.state.checkedGreen) {
          this.showGreenFlights()
        } else if(this.state.checkedRed) {
          this.showRedFlights()
        }
      }))
    }
  }
  //FETCH FLIGHT HISTORY
  fetchFlightHistory = async (flight) => {
    if(flight?.flights_historys?.length > 0) {
      //do nothing
    } else {
      let inboundData = cloneDeep(this.state.inboundFlightsData)
      let flight_index = inboundData?.findIndex((p) => p.id === flight?.id)

      if(flight_index > -1) {
        await Http.get(`flight_history?id=${inboundData[flight_index].id}`, { headers: '' }).then(resp => {
          if (resp && resp.status === 200) {
            if (resp && resp?.data && resp?.data?.data?.length > 0) {
              //STORE HISTORY IN FLIGHTS OBJECT
              inboundData = this.state.inboundFlightsData
              inboundData[flight_index].flights_historys = resp?.data?.data
              inboundData[flight_index].flights_historys = flightHistoryCalculator(inboundData[flight_index])

              // FILTERS CHECKED CHECK
              this.setState({ inboundFlightsData: inboundData }, () => {
                if(this.state.checkedGreen) {
                  this.showGreenFlights()
                } else if(this.state.checkedRed) {
                  this.showRedFlights()
                }
              })
            } else {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: 'No Data Found',
              })
            }
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message,
            })
          }
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({ servererror : true })
        })
      }
    }
  }
  //UPDATE GREEN/RED FLIGHT DATA IF FLIGHT NOT FOUND IN RED/GREEN STATE ARRAYS
  updateFlightsElse = (DATA, FLIGHT_ID, STATUS) => {
    if(!this.state.search && (STATUS !== 'CANCEL' || STATUS !== 'NIL')) {
      let inbound = this.state.inboundFlightsData
      let index = inbound?.findIndex(p => p.id == parseInt(FLIGHT_ID))
      if(index > -1) {
        //PUSH FLIGHT OBJECT BASED ON STAT COLOR FOR FILTERS
        if(this.state.checkedRed) {
          if(inbound[index]?.stat === true) {
            DATA?.push(inbound[index])
            this.setState({ redFlightsData: [] }, () => this.setState({ redFlightsData: DATA }))
          }
        } else if(this.state.checkedGreen) {
          if(inbound[index]?.stat === false) {
            DATA?.push(inbound[index])
            this.setState({ greenFlightsData: [] }, () => this.setState({ greenFlightsData: DATA }))
          }
        }
      }
    }
  }

  /********************************************************************************************** */

  //DISPLAY SEARCHED DATA FROM SEARCH FIELD
  getSearchedData = (search_string, data) => {
    search_string = search_string.split('=')[1]

    for(var i=0;i<data?.length;i++) {
      let flight = data[i]
      if(flight.flight_no === search_string) {
        this.setState({
          flightRow: flight,
          flightRowKey: flight.id
        })
        break
      }
      if(flight?.uld_data) {
        let ulds = flight?.uld_data
        for(var j=0;j<ulds?.length;j++) {
          let uld = ulds[j]
          if(uld.uld_no === search_string) {
            this.setState({
              ULDData: ulds,
              ULDRow: uld,
              ULDRowKey: uld.id,
              flightRow: flight,
              flightRowKey: flight.id
            })
            break
          }
          if(uld?.awbs_in) {
            let awbs = uld?.awbs_in
            for(var k=0;k<awbs?.length;k++) {
              let awb = awbs[k]
              if(awb.awb_no === search_string) {
                this.setState({
                  flightRowKey: flight.id,
                  flightRow: flight,
                  ULDData: ulds,
                  ULDRow: uld,
                  ULDRowKey: uld.id,
                  AWBData: awbs,
                  AWBRow: awb,
                  AWBRowKey: awb.id,
                })
                break
              }
            }
          }
        }
      }
    }
  }
  //SHOW NOTES
  showFlightNotes = (record) => {
    let type = record?.awb_no ? 'awb' : record?.flight_no ? 'flight' : null
    let ID = record?.awb_no ? record?.awbs_id : record?.id

    let note = record.awb_no ? record.awb_no : record.flight_no ? record.flight_no : null
    if(record.flight_no && record.flight_date){
      note = note+'/'+moment(record.flight_date,dateFormat).format('DDMMMYY')
    }
    let title  = record?.flight_no && record?.flight_date ? 'Flight Notes - '+formatFlightConnexNo(record.flight_no, record.flight_date) : record?.awb_no ? 'AWB Notes - '+formatAWBNo(record.awb_no) : ''

    this.setState({
      searchString: note,
      noteType: type,
      noteID: ID,
      flightNotes: true,
      notesTitle: title
    })
  }
  //UPDATE RECOVERY ACTION
  handleRecoveryActionChange = async (record, value, rebook, action) => {
    if(value && record?.handling_time_color !== 'green') {
      if(action === 'Other Reason' && (!this.state.other_record || (!this.state.other_reason || this.state.other_reason === '' || this.state.other_reason === null))){
        this.setState({ 
          show_otr_rsn: true,
          other_record: record,
          other_id: value,
        })
      } else {
        let awb_reason = null
        let isLocal = record?.flights_in?.dest?.arriv === this.state.station && record?.flights_in?.dest?.arriv === record?.dest ? true : false
        if(!isLocal && record?.info?.flights_out_date) {
          if(moment.utc(record?.info?.flights_out_date).tz(this.state.timezone).valueOf() < moment().valueOf()) {
            awb_reason = 'Oops! This flight has departed'
          } else {
            if(!record.booking_confirmed){
              awb_reason = 'Booking Unconfirmed'
            } else {
              awb_reason = 'Insufficient handling time'
            }
          } 
        } else if(!record.booking_confirmed){
          awb_reason = 'Booking Unconfirmed'
        }else if(isLocal && record?.flights_out || record?.segs_out ) {
          awb_reason = 'Insufficient handling time'
        } else {
          awb_reason = 'Flight Info Missing'
        }

        let formdata = {}
        formdata.recovery_actions_id = value
        formdata.is_gen_rebook = rebook
        formdata.awb_stat = record?.handling_time_color || record?.stat || null
        formdata.reason = awb_reason ? awb_reason : record?.message ? record?.message : record?.goods_infos_histories?.length > 0 ? record.goods_infos_histories[0]?.infos?.opt1 : 'N/A'
        formdata.connex_flt = record?.flights_out ? record.flights_out.flight_no : record?.segs_out ? record.segs_out.flight_no :  null
        formdata.connex_date = record?.flights_out ? record.flights_out.flight_date : record?.segs_out ? record.segs_out.flight_date : null
        formdata.rb_trigger = 'Inbound'
        formdata.remark = this.state.other_reason || null
        let headers = {
          station: this.state.station,
          page: PAGE.INBOUND,
          user_id: this.state.user_id,
          page_title: PAGE.inbound_flight_prep,
        }
  
        await Http.put(`/awb/${parseInt(record.id)}`, formdata, { headers: headers })
          .then((data) => {
            if (data && data?.status === 200) {
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data?.data?.message,
              })
              if(action === 'Other Reason' && this.state.other_record){
                this.setState({ 
                  show_otr_rsn: false,
                  other_record: null,
                  other_id: null,
                  other_reason: ''
                })
              }
            } else {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: data?.data?.message,
              })
            }
            this.setState({ loading: false })
          })
      }
    }
  }
  //UPDATE ULD
  handleULDChange = async (record, value, type) => {
    if(value) {
      let headers = {
        station: this.state.station,
        page: PAGE.INBOUND,
        user_id: this.state.user_id,
        page_title: PAGE.inbound_flight_prep,
      }
      let formdata = new FormData()
      if(type === 'uld_dispo') {
        formdata.append('uld_dispo', value)
        formdata.append('work_areas_id', record?.work_areas_id ? record.work_areas_id : '')
      }
      else if(type === 'deliver_to') {
        formdata.append('uld_dispo', record?.uld_dispo ? record.uld_dispo : '')
        formdata.append('work_areas_id', value)
      }

      await Http.put(`/uld/${record.id}`, formdata, { headers: headers })
        .then((data) => {
          if (data && data?.status === 200) {
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
          this.setState({ loading: false })
        })
    }
  }

  handleSelect = (date, type) => {
    if(type === 'stime') {
      this.setState({ start_time: date })
    } else if(type === 'etime'){
      this.setState({ end_time: date })
    }
  }
  handleTimeBlur = (open, type) =>{
    let stime = cloneDeep( this.state.start_time)
    let etime = cloneDeep( this.state.end_time )
    if(stime){
      stime = stime.format('HH:mm:ss')
    } else if(etime){
      etime = etime.format('HH:mm:ss')
    }

    if(!open && ( stime || etime )){
      this.setState({
        start_time : null,
        end_time: null,
      })
      if(type === 'stime'){
        //this.filterData(stime, type)
        this.handleFilter(null, stime, type)
      } else if(type === 'etime'){
        //this.filterData(etime, type)
        this.handleFilter(null, etime, type)
      }
    }
  }
  //BUILD FILTER ARRAY
  handleFilter = (moments, string, type) => {
    this.setState({
      loading: true,
      pageCount: 1,
      inboundFlightsData: [],
    }, () => {
      if(type === 'completed') {
        if(this.state.search && type === 'completed') {
          this.setState({
            filterArray : [
              { start: `start=${startdt.format('YYYY-M-DD') }&` },
              { end: `end=${enddt.format('YYYY-M-DD') }&` },
              { stime: `stime=${startdt.format(timeFormat)}&` },
              { etime: `etime=${enddt.format(timeFormat)}&` },
              { completed: false },
            ]
          }, () => {
            this.setState({
              completedFlightsCheck: string,
              checkedGreen: string === true ? false : this.state.checkedGreen,
              checkedRed: string === true ? false : this.state.checkedRed
            })
            this.filterData(string, type)
          })
        } else {
          this.setState({
            completedFlightsCheck: string,
            checkedGreen: string === true ? false : this.state.checkedGreen,
            checkedRed: string === true ? false : this.state.checkedRed
          })
          this.filterData(string, type)
        }
      } else {
        this.filterData(string, type)
      }
    })
  }
  filterData = (string, type) => {
    let { filterArray } = this.state
    for (let index = 0; index < filterArray.length; index++) {
      for (const key in filterArray[index]) {
        if(type === key)
          if(string !== '') filterArray[index][key] = type === 'completed' && string === false ? '' : `${type}=${string}&`
          else filterArray[index][key] = ''
        if(type === 'start' || type === 'end'){
          if(key === 'start') {
            if(type === 'end'){
              if(string === moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)){
                filterArray[index]['start'] = `${'start'}=${moment().tz(this.state.timezone).format(dateFormat)}&`
              }
              else {
                filterArray[index]['start'] = `${'start'}=${string}&`
              }
            } else {
              filterArray[index]['start'] = `${'start'}=${string}&`
            }
          }
          if(key === 'end') {
            if(type === 'start'){
              if(string === moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)){
                filterArray[index]['end'] = `${'end'}=${moment().tz(this.state.timezone).format(dateFormat)}&`
              }
              else {
                filterArray[index]['end'] = `${'end'}=${string}&`
              }
            }
            else {
              filterArray[index]['end'] = `${'end'}=${string}&`
            }
          }
          if(key === 'stime'){
            if(type === 'start' && string === moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)){
              filterArray[index]['stime'] = `${'stime'}=${'00:00:00'}&`
            }
            else if(string === moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat) || string === moment().tz(this.state.timezone).add(1, 'day').format(dateFormat) ) {
              filterArray[index]['stime'] = `${'stime'}=${moment().tz(this.state.timezone)
                .set({
                  minutes: 0,
                  second:0
                })
                .format(timeFormat)
              }&`
            } else {
              filterArray[index]['stime'] = `${'stime'}=${'00:00:00'}&`
            }
          }
          if(key === 'etime'){
            if(type === 'end' && string === moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat) ){
              filterArray[index]['etime'] = `${'etime'}=${'23:59:59'}&`
            }
            else if(string === moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat) || string === moment().tz(this.state.timezone).add(1, 'day').format(dateFormat) ) {
              filterArray[index]['etime'] = `${'etime'}=${moment().tz(this.state.timezone)
                .set({
                  minutes: 0,
                  second:0
                })
                .format(timeFormat)
              }&`
            } else {
              filterArray[index]['etime'] = `${'etime'}=${'23:59:59'}&`
            }
          }
        }
      }
    }
    this.setState({
      filterArray,
      search: '',
      ULDRow: null,
      AWBRow: null,
      flightRow: null,
      ULDRowKey: null,
      AWBRowKey: null,
      flightRowKey: null,
      ULDData: [],
      AWBData: [],
    }, ()=> this.formatURL(filterArray))
  }
  //GENERATE FILTER URL
  formatURL = (array, flightRow, ULDRow) => {
    let start = array[0]['start']
    let end = array[1]['end']
    let stime = array[2]['stime']
    let etime = array[3]['etime']
    let completed = array[4]['completed']
    start = moment(start.substring(start.indexOf('=') + 1).replace('&','')).format(dateFormat)
    end = moment(end.substring(end.indexOf('=') + 1).replace('&','')).format(dateFormat)
    stime = stime.substring(stime.indexOf('=') + 1).replace('&','')
    etime = etime.substring(etime.indexOf('=') + 1).replace('&','')

    let fromdate = moment.tz(start+' '+stime, this.state.timezone).utc()
    let todate = moment.tz(end+' '+etime, this.state.timezone).utc()
    let combined_string = `?start=${fromdate.format(dateFormat)}&end=${todate.format(dateFormat)}&stime=${fromdate.format(timeFormat)}&etime=${todate.format(timeFormat)}`
    if(completed) {
      combined_string = combined_string+`&completed=${true}`
    }
    this.fetchInboundFlights(combined_string, null)
  }
  //CHECK FOR EMPTY FILTER
  isFilterArrayEmpty = (array) => {
    let flag = true
    array && array.map((item) => {
      for (const key in item) {
        if(item[key] !== '') flag = false
      }
    })
    return flag
  }
  //ARRIVING FLIGHTS TABLE DATA
  dataSource = () => {
    return this.state.checkedGreen ? this.state.greenFlightsData :
      this.state.checkedRed ? this.state.redFlightsData : this.state.completedFlightsCheck ? this.state.inboundFlightsData : this.returnArray(this.state.inboundFlightsData)
  }
  //SEARCH FUNCTIONALITY
  getSearchResults = (url) => {
    if(url) {
      if(this.state.chacheFliterArray.length === 0) {
        this.setState({
          chacheFliterArray: cloneDeep(this.state.filterArray),
          pageCount: 1,
        }, () => {
          this.fetchInboundFlights(null, url)
          this.clearFilters()
        })
      } else {
        this.setState({ pageCount: 1 }, () => {
          this.fetchInboundFlights(null, url)
          this.clearFilters()
        })
      }
    } else {
      if(this.state.chacheFliterArray.length > 0) {
        this.setState({ filterArray: cloneDeep(this.state.chacheFliterArray) }, () => {
          this.setState({
            chacheFliterArray: [],
            inboundFlightsData: [],
            pageCount: 1,
          }, () => this.handleInitialFilter(this.state.filterArray))
        })
      } else {
        this.setState({
          filterArray : [
            { start: `start=${startdt.format('YYYY-M-DD') }&` },
            { end: `end=$${enddt.format('YYYY-M-DD') }&` },
            { stime: `stime=${startdt.format(timeFormat)}&` },
            { etime: `etime=${enddt.format(timeFormat)}&` },
            { completed: false },
          ],
          inboundFlightsData: [],
          pageCount: 1,
        }, () => this.handleInitialFilter(this.state.filterArray))
      }
    }
    this.setState({
      search: url ? url : '',
      checkedGreen: false,
      checkedRed: false,
      completedFlightsCheck: false,
    }, () => this.clearState())
  }
  //CLEAR FILTERS ON GLOBAL SEARCH
  clearFilters = () => {
    let { filterArray } = this.state
    for (let index = 0; index < filterArray.length; index++) {
      for (const key in filterArray[index]) {
        filterArray[index][key] = ''
      }
    }
    this.formRef.current.resetFields()
    this.setState({
      filterArray,
      ULDRowKey: null,
      AWBRowKey: null,
      flightRowKey: null
    })
  }
  //DISTRIBUTION PLAN POPUP
  distributionPlan = () => {
    let { distRecord, distPlan } = this.state
    let flightDataSource = distRecord //CONVERT OBJECT TO ARRAY
    let uldDataSource = flightDataSource && flightDataSource[0]?.uld_data //STORE ULD ARRAY

    const distPlanFlightColumns = [
      {
        title: 'FLT#',
        dataIndex: 'flight_no',
        key: 'flight_no',
        render: (_, record) => (
          <span>{record?.flight_no && record?.flight_date ? formatFlightConnexNo(record.flight_no, record.flight_date) : 'N/A'}</span>
        ),
      },
      {
        title: 'Aircraft',
        dataIndex: 'ac_type',
        key: 'ac_type',
        render: (_, record) => (
          <span>{record?.ac_type ? record.ac_type : 'N/A'}</span>
        ),
      },
      {
        title: 'Registration',
        dataIndex: 'call_sign',
        key: 'call_sign',
        render: (_, record) => (
          <span>{record?.info?.call_sign ? record.info.call_sign : 'N/A'}</span>
        ),
      },
      {
        title: 'ETA',
        dataIndex: 'eta',
        key: 'eta',
        render: (_, record) => (
          //<span>{record?.arriving_datetime ? moment(record.arriving_datetime).utc().format(dateTimeFormat) + ' UTC' : 'N/A'}</span>
          <span>{record?.arriving_datetime ? moment(record.arriving_datetime).utc().tz(this.state.timezone).format(dateTimeFormat) + ' ' + this.state.ext : 'N/A'}</span>
        ),
      },
      {
        title: 'Gate',
        dataIndex: 'gate',
        key: 'gate',
        render: (_, record) => (
          <span>{record?.arr?.gate ? record.arr.gate : 'N/A'}</span>
        ),
      },
      {
        title: 'O&D',
        dataIndex: 'ond',
        key: 'ond',
        render: (_, record) => (
          <span>{record?.org && record?.dest ? record.org.origin +'-'+ record.dest.arriv : 'N/A'}</span>
        ),
      },
      {
        title: 'ULDs',
        dataIndex: 'ulds',
        key: 'ulds',
        render: (_, record) => (
          <span>{record?.ulds ? record.ulds.filter((uld) => uld?.blk === false)?.length : 0}</span>
        ),
      },
      // {
      //   title: 'Tons',
      //   dataIndex: 'tons',
      //   key: 'tons',
      //   render: (_, record) => (
      //     <span>{this.state.weight_unit === 'KG' ? record?.weight_kg ? `${formatWeight(record?.weight_kg)}KG` : record?.weight_lbs ? `${formatWeight(record?.weight_lbs)}LB` : 'N/A' : 'N/A'}</span>
      //   ),
      // },
      {
        title: 'HT',
        dataIndex: 'handlingtime',
        key: 'handlingtime',
        render: (_, record) => (
          <span className='countdown black'>
            {record?.show_handling_time_after_tta === true ? <Countdown value={moment(record.handling_time)} format='HH:mm' /> : record.handling_time_as_timestamp}
          </span>
        ),
      },
      {
        title: 'Plan Update',
        dataIndex: 'plan_update',
        key: 'plan_update',
        align: 'center',
        render: (_, record) => (
          // <span>{record?.plan_updated ? moment(record.plan_updated).format(dateTimeFormat) + ' UTC' : 'N/A'}</span>
          <span>{record?.plan_updated ? moment.utc(record.plan_updated).tz(this.state.timezone).format(dateTimeFormat) + ' ' + this.state.ext : 'N/A'}</span>
        ),
      },
    ]
    const distPlanUldColumns = [
      {
        title: 'ULD#/AWB',
        dataIndex: 'uld_no',
        key: 'uld_no',
        render: (_, record) => (
          <span>{record?.uld_no ? record.uld_no : 'N/A'}</span>
        ),
      },
      {
        title: 'Deliver To',
        dataIndex: 'deliver_to',
        key: 'deliver_to',
        render: (_, record) => (
          <span>{record?.work_area?.area_name ? record.work_area.area_name : 'N/A'}</span>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'uld_type',
        key: 'uld_type',
        render: (_, record) => (
          <span>{record?.uld_type ? record.uld_type : 'N/A'}</span>
        ),
      },
      {
        title: 'Dispo',
        dataIndex: 'uld_dispo',
        key: 'uld_dispo',
        render: (_, record) => (
          <span>{record?.uld_dispo ? record.uld_dispo : 'N/A'}</span>
        ),
      },
      {
        title: 'Handling Time',
        dataIndex: 'handling_time',
        key: 'handling_time',
        render: (_, record) => (
          <span className={record.handling_time_color === true ? 'countdown green' : 'countdown red'}>
            {record?.show_handling_time_after_tta === true ? <Countdown value={moment(record.handling_time)} format='HH:mm' /> : record.handling_time_as_timestamp}
          </span>
        ),
      },
    ]

    return (
      <Modal
        title={`Distribution Plan - ${distRecord && distRecord[0]?.flight_no || ''}`}
        visible={distPlan}
        onCancel={() => this.setState({ distPlan: false })}
        footer={false}
        width={'80%'}
        centered
        className='custom-modal pb-0'
      >
        <Spin spinning={this.state.skeletonLoad}>
          <Row
            gutter={[
              {
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24,
              },
              {
                xs: 24,
                sm: 24,
                md: 12,
                lg: 12,
              },
            ]}
            className='with-table'
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div className='table-outer'>
                <Table
                  className='custom-table w-100'
                  dataSource={flightDataSource}
                  columns={distPlanFlightColumns}
                  pagination={false}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div className='table-outer'>
                <Table
                  className='custom-table w-100'
                  dataSource={uldDataSource}
                  columns={distPlanUldColumns}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Spin>
      </Modal>
    )
  }
  //SET ROW CLASSNAME ON SELECT
  setRowClassName = (record, type) => {
    return record.id === this.state[type] ? 'selected pointer' : 'pointer'
  }
  //SHOW GREEN FLIGHTS
  showGreenFlights = () => {
    this.setState({ 
      checkedRed: false, 
      greenFlightsData: [] 
    }, () => {
      let filtered = this.state.inboundFlightsData?.filter((item) => item.stat === false && (item?.ulds?.length > 0 || item.status === 'ACTIVE'))
      this.setState({ greenFlightsData : filtered?.length === 0 ? [] : filtered })
    })
  }
  //SHOW RED FLIGHTS
  showRedFlights = () => {
    this.setState({ 
      checkedGreen: false, 
      redFlightsData: [] 
    }, () => {
      let filtered = this.state.inboundFlightsData?.filter((item) => item.stat === true && (item?.ulds?.length > 0 || item.status === 'ACTIVE'))
      this.setState({ redFlightsData : filtered?.length === 0 ? [] : filtered })
    })
  }
  //SHOW FLIGHT HISTORY
  showFlightHistory = async (flight) => {
    let da = []
    if(flight?.flights_historys?.length > 0) {
      flight?.flights_historys?.map((item) => {
        if(item?.infos && item?.infos?.opt1 !== null && item?.infos?.opt2 !== null) {
          da.push(item)
        }
      })

      if(this.state.flightHistoryVisible && da?.length > 0) {
        return (
          <div>
            {da.map((item, index) => (
              <p
                key={index}>
                {item?.infos?.opt1}<br />{item?.infos?.opt2 ? `${item?.infos?.opt2}` : ''} {item?.infos?.status ? `${item?.infos?.status}` : ''}
              </p>
            ))}
          </div>
        )
      }
    }
  }
  //SHOW AWB/ULD HISTORY
  showULDAWBHistory = (history) => {
    let { flightHistoryVisible } = this.state
    let da = []
    history?.map((item) => {
      if(item?.infos && item?.infos?.opt1 !== null && item?.infos?.opt2 !== null) {
        da.push(item)
      }
    })
    //history?.filter((item) => item?.infos?.opt1 && item?.infos.opt2)
    if(flightHistoryVisible && da?.length > 0) {
      return (
        <div>
          {da.map((item, index) => (
            <p
              key={index}>
              {item?.infos?.opt1}<br />{item?.infos?.opt2 ? `${item?.infos?.opt2}` : ''} {item?.infos?.status ? `${item?.infos?.status}` : ''}
            </p>
          ))}
        </div>
      )
    }
  }
  //START HANDLING TIMES COUNTDOWN
  startCountdown = (record) => {
    let inboundData = cloneDeep(this.state.inboundFlightsData)
    let flight_index = inboundData?.findIndex((p) => p.id === record.id)

    if(flight_index > -1) {
      inboundData[flight_index].show_handling_time_after_tta = true
      inboundData[flight_index]?.uld_data?.map((uld) => {
        uld.show_handling_time_after_tta = true
        uld?.awbs_in?.map((awb) => {
          awb.show_handling_time_after_tta = true
        })
      })

      if(this.state.flightRow?.id === record.id) {
        let index = inboundData?.findIndex((p) => p.id === this.state.flightRow?.id)
        if(index > -1) {
          this.setState({ ULDData: [] }, () => this.setState({ ULDData: inboundData[index]?.uld_data }))
          let uld_index = inboundData[index]?.uld_data?.findIndex((p) => p.id === this.state.ULDRow?.id)
          if(uld_index > -1) {
            this.setState({ AWBData: [] }, () => this.setState({ AWBData: inboundData[index]?.uld_data[uld_index]?.awbs_in }))
          }
        }
      }
      this.setState({ inboundFlightsData: inboundData })
    } else {
      this.setState({ inboundFlightsData: inboundData })
    }
  }
  //ON SCROLL
  onScrollEvent = () => {
    let height = this.container?.current?.scrollHeight * (50/100)
    if (this.state.pageFlag === false && this.container?.current?.scrollTop + this.container?.current?.clientHeight >= height) {
      if((this.state.search === '' || this.state.search === null)) {
        this.formatURL(this.state.filterArray, null, null)
      }
    }
  }

  returnArray = (arr) => {
    if(this.state.search) {
      return arr
    } else {
      return arr?.filter((item) => {
        if(item?.status?.toUpperCase() === 'COMPLETED' && item?.stat === false) {
          //DO NOTHING
        } else {
          return item
        }
      })
    }
  }
  // SHOW DYNAMIC INFO MODAL
  showdynamicInfo = (record) => {
    let title  = record?.flight_no && record?.flight_date ? ' - '+formatFlightConnexNo(record.flight_no, record.flight_date) : ''
    this.setState({
      dynamicInfoTitle: title,
      dynamicInfoModal: true,
      dynamicInfoData: record.dynamic_info
    })}
  // HIDE DYNAMIC INFO MODAL
  hidedynamicInfo = () => {this.setState({
    dynamicInfoTitle: '',
    dynamicInfoModal: false,
    dynamicInfoData: []
  })}

  showPreview = (record, key) => {
    this.setState({
      ispreviewImgModal: true,
      previewImg: record?.dinfo?.details[key].path
    })
  }

  checkType = (record) => {
    if(record?.dinfo?.details?.attach_conf?.name.includes('.pdf')) {
      return (
        <span>
          <Tooltip title='Preview'>
            {record?.dinfo?.details?.attach_conf && <a
              href={record?.dinfo?.details?.attach_conf?.path}
              rel='noopener noreferrer'
              target='_blank'
            ><CheckOutlined /></a>}
          </Tooltip>
          {!record?.dinfo?.details?.attach_conf && (<CloseOutlined />)}
        </span>
      )
    } else {
      return (
        <span>
          <Tooltip title='Preview' onClick={() => this.showPreview(record,'attach_conf' ) }>
            {record?.dinfo?.details?.attach_conf && <CheckOutlined />}
          </Tooltip>
          {!record?.dinfo?.details?.attach_conf && (<CloseOutlined />)}
        </span>
      )
    }
  }

  //HT column tooltip
  showHandlingTimeHistory = (record, type) => {
    let isLocal = record?.flights_in?.dest?.arriv === this.state.station && record?.flights_in?.dest?.arriv === record?.dest ? true : false
    if(type === 'ULD') {
      return (
        <div>
          <p>PHT: {`${record?.info?.handling_time}`}<br />
          CNX: {`${record?.info?.handling_time}`}</p>
        </div>
      )
    } else if(type === 'AWB') {
      let flight_out = 'N/A', nfd = 'N/A'
      if(!isLocal && record?.info?.flights_out_date) {
        //CHECK IF CONNEX IS PASSED
        if(moment.utc(record?.info?.flights_out_date).tz(this.state.timezone).valueOf() < moment().valueOf()) {
          flight_out = 'Oops! This flight has departed'
        } else {
          //conn = moment.utc(record?.info?.flights_out_date).diff(moment.utc(record?.info?.eta), 'minutes')
          if(!record.booking_confirmed){
            flight_out = `${moment.utc(record?.info?.flights_out_date).tz(this.state.timezone).format(dateTimeFormat)} ${this.state.ext} Booking Unconfirmed`
          } else {
            flight_out = `${moment.utc(record?.info?.flights_out_date).tz(this.state.timezone).format(dateTimeFormat)} ${this.state.ext}`
          }
        }
      } else if(!record.booking_confirmed){
        flight_out = 'Booking Unconfirmed'
      } else {
        flight_out = 'Flight Info Missing' 

        //CHECK IF LOCAL FOR NFD
        if(isLocal && record?.info?.conn) {
          nfd = `${moment.utc(record?.info?.conn).tz(this.state.timezone).format(dateTimeFormat)} ${this.state.ext}`
        }
      }

      return (
        !isLocal ?
          <div>
            <p>
              PHT: {`${record?.info?.handling_time} mins`}<br />
              CNX: {flight_out}
            </p>
          </div> :
          <div>
            <p>
              PHT: {`${record?.info?.handling_time} mins`}<br />
              NFD: {nfd}<br />
            </p>
          </div>
      )
    }
  }
  clearState = () => {
    this.setState({
      ULDRow: null,
      AWBRow: null,
      flightRow: null,
      ULDRowKey: null,
      AWBRowKey: null,
      flightRowKey: null,
      ULDData: [],
      AWBData: [],
    })
  }

  getID = (wa_id, ids) => {
    let index = ids?.indexOf(wa_id)
    if(index > -1) {
      return ids[index]
    } else return false
  }

  createSpliceCountdown = () => {
    if(!this.state.completedFlightsCheck) {
      return moment().tz(this.state.timezone).add(10, 'seconds')
    } else {
      return null
    }
  }

  removeFlight = ({ id }) => {
    let flightsData = cloneDeep(this.state.inboundFlightsData)
    let flight_index = flightsData.findIndex((p) => p.id === id)
    if(flight_index > -1) {
      if(flightsData[flight_index]?.status === 'COMPLETED' || flightsData[flight_index]?.fstatus === 'COMPLETED') {
        if(this.state.flightRow?.id === id) {
          this.clearState()
        }
        if(this.state.checkedGreen){
          flightsData[flight_index].stat = false
        } else if (this.state.checkedRed) {
          flightsData[flight_index].stat = true
        } else {
          flightsData.splice(flight_index, 1)
        }
        this.setState({ inboundFlightsData: [] }, () => this.setState({ inboundFlightsData: flightsData }, () => {
          if(this.state.checkedGreen) {
            this.showGreenFlights()
          } else if(this.state.checkedRed) {
            this.showRedFlights()
          }
        }))
      } else {
        //DO NOTHING
        this.fetchFlightByID(id)
      }
    }
  }

  awbStatusMsg = (record) => {
    let message = 'Test123'
    let isLocal = record?.flights_in?.dest?.arriv === this.state.station && record?.flights_in?.dest?.arriv === record?.dest ? true : false
    if(record.handling_time_color !== 'green'){
      if(!isLocal && record?.info?.flights_out_date) {
        if(moment.utc(record?.info?.flights_out_date).tz(this.state.timezone).valueOf() < moment().valueOf()) {
          message = 'Oops! This flight has departed'
        } else {
          if(!record.booking_confirmed){
            message = 'Booking Unconfirmed'
          } else {
            message = 'Insufficient handling time'
          }
        } 
      } else if(!record.booking_confirmed){
        message = 'Booking Unconfirmed'
      }else if(isLocal && record?.flights_out || record?.segs_out ) {
        message = 'Insufficient handling time'
      // } else if(record?.goods_infos_histories?.length > 0){
      //   message = record.goods_infos_histories[0]?.infos?.opt1
      } else {
        message = 'Flight Info Missing'
      }
    } else if(record?.goods_infos_histories?.length > 0){
      message = record.goods_infos_histories[0]?.infos?.opt1 || 'test123'
    }
    return message
    //return record?.message ? record?.message : record?.goods_infos_histories?.length > 0 ? record.goods_infos_histories[0]?.infos?.opt1 : 'N/A'
  }

  render() {
    let { ULDData, AWBData, recoveryActions, workAreaLocations, loading, distPlan, filterArray, completedFlightsCheck, checkedGreen, checkedRed, servererror, filterOrg } = this.state
    const arrivinFlightColumns = [
      {
        title: '',
        dataIndex: 'stat',
        key: 'stat',
        align: 'center',
        className: 'status-column',
        render: (_, record) => (
          record?.ulds?.length > 0 ? record?.stat === true ? <Badge status='error' /> : record?.stat === false ? <Badge status='success' /> : <Badge status='default' /> : <Badge status='default' />
        ),
      },
      {
        title: 'FLT#',
        dataIndex: 'flight_no',
        key: 'flight_no',
        render: (_, record) => (
          <span>{record?.flight_no && record?.flight_date ? formatFlightConnexNo(record.flight_no, record.flight_date, record?.arriv_date) : 'N/A'}</span>
        ),
      },
      {
        title: 'AC Type',
        dataIndex: 'ac_type',
        key: 'ac_type',
        render: (_, record) => (
          <span>{record?.ac_type ? record.ac_type : 'N/A'}</span>
        ),
      },
      {
        title: 'O&D',
        dataIndex: 'ond',
        key: 'ond',
        filters: [...filterOrg].map(org => 
          ({ 
            text: org, 
            value: org.split('-')[0]
          })
        ),
        filterSearch: true,
        onFilter: (value, record) => {
          return record.org.origin.indexOf(value) === 0
        },
        render: (_, record) => (
          <span>{record?.org && record?.dest ? record.org.origin +'-'+ record.dest.arriv : 'N/A'}</span>
        ),
      },
      {
        title: 'ULDs',
        dataIndex: 'ulds',
        key: 'ulds',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{record?.ulds ? record.ulds.filter((uld) => uld?.blk === false)?.length : 0}</span>
        ),
      },
      {
        title: 'Awbs',
        dataIndex: 'awbs_in',
        key: 'awbs_in',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{record?.awbs_in ? record.awbs_in.length : 0}</span>
        ),
      },
      // {
      //   title: 'Wgt',
      //   dataIndex: 'weight_kg',
      //   key: 'weight_kg',
      //   render: (_, record) => (
      //     <span>{this.state.weight_unit === 'KG' && record?.weight_kg ? `${formatWeight(record?.weight_kg)}KG` :
      //       this.state.weight_unit === 'LB' && record?.weight_lbs ? `${formatWeight(record?.weight_lbs)}LB` :
      //         'N/A'}</span>
      //   ),
      // },
      {
        title: 'HT',
        dataIndex: 'handlingtime',
        key: 'handlingtime',
        render: (_, record) => (
          <span className='countdown black'>
            {record?.show_handling_time_after_tta === true ? <Countdown value={moment(record.handling_time)} format='HH:mm' /> : record.handling_time_as_timestamp}
          </span>
        ),
      },
      {
        title: 'TTA',
        dataIndex: 'tta',
        key: 'tta',
        render: (_, record) => (
          <span className='countdown'>{record?.arriving_datetime ? <Countdown onFinish={() => this.startCountdown(record)} value={record.arriving_datetime} format='HH:mm' /> : 'N/A'}</span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => (
          <Popover
            onVisibleChange={(e) => this.setState({ flightHistoryVisible: e } ,() => this.fetchFlightHistory(record, false))}
            placement='rightTop'
            content={record?.flights_historys?.length > 0 ? record?.flights_historys?.map((item, index) => (
              <p
                key={index}>
                {item?.infos?.opt1}<br />{item?.infos?.opt2 ? `${item?.infos?.opt2}` : ''} {item?.infos?.status ? `${item?.infos?.status}` : ''}
              </p>
            )) : <LoadingOutlined />}
            title='Flight history'
            trigger='click'
            overlayClassName='status-popover'>
            <span>{record?.history_status?.display ? record?.history_status?.display : 'N/A'}</span>
          </Popover>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (_, record) => (
          <div className='action-column inbound-action-col'>
            {record?.is_dynamic_info && <Tooltip
              title='Dynamic info present'
              overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
            >
              <img src={thunderboltIcon} alt='document' onClick={()=> this.showdynamicInfo(record)} />
            </Tooltip>}
            {record?.ulds?.length > 0 && <Tooltip
              title='Distribution Plan'
              overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
            >
              <Button
                className='p-icon fs-0'
                onClick={() => { this.fetchDistributionPlanByFlightID(record)
                }}>P</Button>
            </Tooltip>}
            <Badge size='small' count={record?.notes_count || 0} className='notes-badge'>
              <Tooltip
                title=''
                overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                onClick={() => this.showFlightNotes(record)}
              >
                <img src={documentIcon} alt='document' />
              </Tooltip>
            </Badge>
            {record?.splice_countdown && <Countdown className='hidden-countdown' onFinish={() => this.removeFlight(record)} value={record.splice_countdown} format='HH:mm' />}
          </div>
        ),
      },
    ]
    const uldColumns = [
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        className: 'status-column',
        render: (_, record) => (
          record?.awbs_in?.length === 0 ? <Badge status='default' /> :
            // !record?.work_areas_id ? <Badge status='error' /> :
            record?.handling_time_color === true ? <Badge status='success' /> :
              record?.handling_time_color === false ? <Badge status='error' /> :
                <Badge status='default' />
          // !record?.deliver_to ? <Badge status='error' /> : record?.handling_time_color === true ? <Badge status='success' /> : record?.handling_time_color === false ? <Badge status='error' /> : <Badge status='default' />
        ),
      },
      {
        title: 'ULD#/AWB',
        dataIndex: 'uld_no',
        key: 'uld_no',
        className: 'space-nowrap',
        render: (_, record) => (
          <span>{record?.uld_no ? record.uld_no : 'N/A'}</span>
        ),
      },
      {
        title: 'Pcs',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{record?.pcs ? record.pcs : 'N/A'}</span>
        ),
      },
      {
        title: 'Awbs',
        dataIndex: 'awbs_in',
        key: 'awbs_in',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{record?.awbs_in ? record.awbs_in.length : 0}</span>
        ),
      },
      {
        title: 'Wgt',
        dataIndex: 'weight_kg',
        key: 'weight_kg',
        render: (_, record) => (
          <span>{this.state.weight_unit === 'KG' && record?.weight_kg ? `${formatWeight(record?.weight_kg)}KG` :
            this.state.weight_unit === 'LB' && record?.weight_lbs ? `${formatWeight(record?.weight_lbs)}LB` :
              'N/A'}</span>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'uld_type',
        key: 'uld_type',
        render: (_, record) => (
          <span>{record?.uld_type ? record.uld_type : 'N/A'}</span>
        ),
      },
      {
        title: 'ULD Dispo',
        dataIndex: 'uld_dispo',
        key: 'uld_dispo',
        render: (_, record) => (
          <div>
            <Select
              placeholder='Select ULD Dispo'
              bordered={false}
              style={{ width: 120 }}
              size='small'
              onChange={(e) => this.handleULDChange(record, e, 'uld_dispo')}
              value={record?.uld_dispo}
              dropdownClassName='ib-tbl-dd'
            >
              <Option value='Breakdown'>Breakdown</Option>
              <Option value='Move as Thru Unit'>Move as Thru Unit</Option>
              <Option value='Keep Intact for Delivery'>Keep Intact for Delivery</Option>
            </Select>
          </div>
        ),
      },
      {
        title: 'HT',
        dataIndex: 'handlingtime',
        key: 'handlingtime',
        render: (_, record) => (
          <Popover
            //onVisibleChange={(e) => this.setState({ flightHistoryVisible: e })}
            placement='rightTop'
            //content={() => this.showHandlingTimeHistory(record, 'ULD')}
            title=''
            trigger='hover'
            overlayClassName='status-popover'
            showArrow={ false }
          >
            <span className={record.handling_time_color === true ? 'countdown green' : 'countdown red'}>
              {record?.show_handling_time_after_tta === true ? <Countdown value={moment(record.handling_time)} format='HH:mm' /> : record.handling_time_as_timestamp}
            </span>
          </Popover>

        ),
      },
      {
        title: 'Deliver To',
        dataIndex: 'work_areas_id',
        key: 'work_areas_id',
        render: (_, record) => (
          <div>
            {/* <div className={record?.work_areas_id === null ? 'deliver red-border' : 'deliver'}> */}
            <Select
              placeholder='Select Department'
              bordered={false}
              style={{ width: 120 }}
              size='small'
              onChange={(e) => this.handleULDChange(record, e, 'deliver_to')}
              value={record?.work_areas_id}
              dropdownClassName='ib-tbl-dd'
            >
              {workAreaLocations && workAreaLocations.map((item) => (
                <Option key={item.area_name} value={this.getID(record?.work_areas_id, item?.ids) || item.ids[0]} title={item.area_name}>{item.area_name}</Option>
              ))}
            </Select>
          </div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        className:'min-width-100',
        render: (_, record) => (
          <Popover
            onVisibleChange={(e) => this.setState({ flightHistoryVisible: e })}
            placement='rightTop'
            content={() => this.showULDAWBHistory(record?.ulds_historys)}
            title='ULD history'
            trigger='click'
            overlayClassName='status-popover'>
            <span>{record?.message ? record.message : 'N/A'}</span>
          </Popover>
        ),
      },
    ]
    const awbColumns = [
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        className: 'status-column',
        render: (_, record) => (
          record?.handling_time_color === 'red' ? <Badge status='error' /> : record?.handling_time_color === 'green' ? <Badge status='success' /> : <Badge status='default' />
        ),
      },
      {
        title: 'Awb #',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'space-nowrap',
        render: (_, record) => (
          <span>{record?.awb_no ? formatAWBNo(record.awb_no) : 'N/A'}</span>
        ),
      },
      {
        title: 'Pcs',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{record?.pcs ? record.pcs : 'N/A'}</span>
        ),
      },
      {
        title: 'Wgt',
        dataIndex: 'wgt',
        key: 'wgt',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{this.state.weight_unit === 'KG' && record?.weight_kg ? `${formatWeight(record?.weight_kg)}KG` :
            this.state.weight_unit === 'LB' && record?.weight_lbs ? `${formatWeight(record?.weight_lbs)}LB` :
              'N/A'}</span>
        ),
      },
      {
        title: 'ORG-DST',
        dataIndex: 'orgdst',
        key: 'orgdst',
        render: (_, record) => (
          <span>{record?.org ? record.org +'-'+ record.dest : 'N/A'}</span>
        ),
      },
      {
        title: 'PROD',
        dataIndex: 'product_code',
        key: 'product_code',
        render: (_, record) => (
          <span>{record?.awb && record?.awb?.prod_code ? record.awb.prod_code : 'N/A'}</span>
        ),
      },
      {
        title: 'Connex',
        dataIndex: 'connex',
        key: 'connex',
        render: (_, record) => (
          <span>{record?.flights_out ? formatFlightConnexNo(record.flights_out.flight_no, record.flights_out.flight_date) :
            record?.segs_out ? formatFlightConnexNo(record.segs_out.flight_no, record.segs_out.flight_date) :
              'N/A'}</span>
        ),
      },
      {
        title: 'HT',
        dataIndex: 'handling_time',
        key: 'handling_time',
        render: (_, record) => (
          <Popover
            onVisibleChange={(e) => this.setState({ flightHistoryVisible: e })}
            placement='leftTop'
            content={() => this.showHandlingTimeHistory(record, 'AWB')}
            title=''
            trigger='hover'
            overlayClassName='status-popover'>
            <span className={record.handling_time_color === 'red' ? 'countdown red' : 'countdown green'}>
              {record?.show_handling_time_after_tta === true ? <Countdown value={moment(record.handling_time_after_tta)} format='HH:mm' /> : record.handling_time_as_timestamp}
            </span>
          </Popover>
        ),
      },
      {
        title: 'Rec Action',
        dataIndex: 'recoveryaction',
        key: 'recoveryaction',
        className: 'rec-dropdown',
        render: (_, record) => (
          <div>
            <Select
              placeholder='Select Recovery Action'
              bordered={false}
              style={{ width: 120 }}
              size='small'
              onChange={(e, { rebook, action }) => this.handleRecoveryActionChange(record, e, rebook, action)}
              value={record && record.recovery_actions_id ? record.recovery_actions_id : null}
              dropdownClassName='ib-tbl-dd'
            >
              {recoveryActions && recoveryActions.map((item, index) => (
                <Option rebook={item.details.is_gen_rebook} action={item.details.action} key={index} value={item.id} title={item.details.action}>{item.details.action}</Option>
              ))}
            </Select>
          </div>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        className:'min-width-100',
        render: (_, record) => (
          <Popover
            onVisibleChange={(e) => this.setState({ flightHistoryVisible: e })}
            placement='rightTop'
            content={() => this.showULDAWBHistory(record?.goods_infos_histories)}
            title='AWB history'
            trigger='click'
            overlayClassName='status-popover'>
            <span>{this.awbStatusMsg(record) }</span> 
          </Popover>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, record) => (
          <div className='action-column'>
            <Badge size='small' count={record?.awb?.notes?.length || 0} className='notes-badge'>
              <Tooltip
                title=''
                overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                onClick={() => this.showFlightNotes(record)}
              >
                <img src={documentIcon} alt='document' />
              </Tooltip>
            </Badge>
          </div>
        ),
      },
    ]
    const dynamicInfoColumns = [
      {
        title: 'Trigger',
        dataIndex: 'trigger',
        key: 'trigger',
        className: 'space-nowrap',
        render: (_, record) => (
          <span>{record?.trigger ? record?.trigger : 'N/A'}</span>
        ),
      },
      {
        title: 'Reference',
        dataIndex: 'reference',
        key: 'reference',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{record?.reference ? record.reference : 'N/A'}</span>
        ),
      },
      {
        title: 'Remarks',
        key: 'remarks',
        render: (text, record) => (
          <span >{record?.dinfo?.details?.remarks}</span> )
      },
      {
        title: 'Attachment',
        key: 'attachment',
        dataIndex: 'attachment',
        width: '150px',
        className:'fleet-tableupload pfd-upload',
        align: 'center',
        render: (_, record) => {
          return (
            <span>
              {this.checkType(record)}
            </span>
          )
        }
      },
    ]

    return (
      <div>
        {
          servererror ?
            <p className='dis-server'>Disconnected from the server, Please refresh the page.</p> : null
        }
        {/* {loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)} */}
        <HeaderSearch searchResults={this.getSearchResults} page='INBOUND' />
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 24,
              lg: 24,
            },
            {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
            },
          ]}
          className='content-ht-full inbound-flights'
        >
          <Col
            xs={{
              span: 24,
              order: 1,
            }}
            sm={{
              span: 24,
              order: 1,
            }}
            md={{
              span: 24,
              order: 1,
            }}
            lg={{
              span: 12,
              order: 1,
            }}
            xl={{
              span: 11,
              order: 1,
            }}
            xxl={{
              span: 11,
              order: 1,
            }}
            className=''
          >
            {/* <Spin spinning={this.dataSource().length > 0 ? false : true } > */}
            <Spin spinning={ (!this.dataSource().length > 0 && this.state.isfetching) } >
              <Card className='custom-card h-100'>
                <Row
                  gutter={[
                    {
                      xs: 0,
                      sm: 12,
                      md: 24,
                      lg: 24,
                    },
                    {
                      xs: 24,
                      sm: 24,
                      md: 12,
                      lg: 12,
                    },
                  ]}
                  className='with-table'
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw'>
                    {/* {loading && (<div id='spinner' className='spinner-cont'>
                    <Spin />
                  </div>)} */}
                    <Title level={5} className='mb-0'>
                    Arriving Flights
                    </Title>
                    <Spin spinning={this.state.spinnerForFlights || this.state.spinnerForEvents} indicator={antIcon} />
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xxl={24} className=''>
                    <Form layout='vertical' ref={this.formRef}>
                      <Row
                        gutter={[
                          {
                            xs: 24,
                            sm: 24,
                            md: 24,
                            lg: 24,
                          },
                          {
                            xs: 0,
                            sm: 0,
                            md: 0,
                            lg: 0,
                          },
                        ]}
                      >
                        <Col
                          xs={24}
                          sm={12}
                          lg={24}
                          xl={12}
                          flex='auto'
                          className=''
                        >
                          <Form.Item label='From' className='inbound-label mb-2' name='dateTimepicker'>
                            <Row
                              gutter={[
                                {
                                  xs: 0,
                                  sm: 24,
                                  md: 24,
                                  lg: 24,
                                },
                                {
                                  xs: 24,
                                  sm: 24,
                                  md: 24,
                                  lg: 24,
                                },
                              ]}
                            >
                              <Col xs={24} sm={12} lg={12} xl={12} className=''>
                                <DatePicker
                                  allowClear={false}
                                  value={filterArray[0]['start'] && moment(filterArray[0]['start'], dateFormat)} size='small'
                                  onChange={(date, string) => this.handleFilter(date, string, 'start')}
                                  disabledDate={(current) => this.disabledDate(current) }
                                />
                              </Col>
                              <Col xs={24} sm={12} className=''>
                                <TimePicker
                                  //onChange={(date, string) => this.handleFilter(date, string, 'stime')}
                                  onSelect={(date) => this.handleSelect(date, 'stime')}
                                  onBlur={()=>this.handleTimeBlur(false, 'stime')}
                                  onOpenChange={(open)=>this.handleTimeBlur(open,'stime')}
                                  value={this.state.start_time || filterArray[2]['stime'] && moment(filterArray[2]['stime'], timeFormat)}
                                  disabledHours={()=>{
                                    var hours = []
                                    let start = this.state.filterArray[0]['start'] ? moment(this.state.filterArray[0]['start'], dateFormat).format(dateFormat) : null
                                    let atlstart = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
                                    if(start && atlstart && start === atlstart ){
                                      for(var i =0; i < moment().tz(this.state.timezone).hour(); i++){
                                        hours.push(i)
                                      }
                                    }
                                    return hours
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                        <Col
                          xs={24}
                          sm={12}
                          lg={24}
                          xl={12}
                          flex='auto'
                          className=''
                        >
                          <Form.Item label='To' className='inbound-label mb-2' name='dateTimepicker'>
                            <Row
                              gutter={[
                                {
                                  xs: 0,
                                  sm: 12,
                                  md: 24,
                                  lg: 24,
                                },
                                {
                                  xs: 24,
                                  sm: 24,
                                  md: 24,
                                  lg: 24,
                                },
                              ]}
                            >
                              <Col xs={24} sm={12} className=''>
                                <DatePicker
                                  allowClear={false}
                                  value={filterArray[1]['end'] && moment(filterArray[1]['end'], dateFormat)}
                                  size='small'
                                  onChange={(date, string) => this.handleFilter(date, string, 'end')}
                                  disabledDate={(current) => this.disabledDate(current) }
                                />
                              </Col>
                              <Col xs={24} sm={12} className=''>
                                <TimePicker
                                  //onChange={(date, string) => this.handleFilter(date, string, 'etime')}
                                  onSelect={(date) => this.handleSelect(date, 'etime')}
                                  onBlur={()=>this.handleTimeBlur(false,'etime')}
                                  onOpenChange={(open)=>this.handleTimeBlur(open,'etime')}
                                  value={this.state.end_time || filterArray[3]['etime'] && moment(filterArray[3]['etime'], timeFormat)}
                                  disabledHours={()=>{
                                    var hours = []
                                    let end = this.state.filterArray[1]['end'] ? moment(this.state.filterArray[1]['end'], dateFormat).format(dateFormat) : null
                                    let atlend = moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)
                                    if(end && atlend && end === atlend ){
                                      for(var i =23; i > moment().tz(this.state.timezone).hour(); i--){
                                        hours.push(i)
                                      }
                                    }
                                    return hours
                                  }}
                                />
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xxl={24} className='p-12 pl-0 fv-middle green-flights'>
                    <div className='label-outer'>
                      <Checkbox
                        disabled={completedFlightsCheck}
                        checked={checkedGreen}
                        onChange={(e) => this.setState({
                          checkedGreen: e.target.checked,
                          ULDRow: null,
                          AWBRow: null,
                          flightRow: null,
                          ULDRowKey: null,
                          AWBRowKey: null,
                          flightRowKey: null,
                          ULDData: [],
                          AWBData: [],
                        }, () => this.showGreenFlights())}>
                          Show Green Flights
                      </Checkbox>
                      <Checkbox
                        disabled={completedFlightsCheck}
                        checked={checkedRed}
                        onChange={(e) => this.setState({
                          checkedRed: e.target.checked,
                          ULDRow: null,
                          AWBRow: null,
                          flightRow: null,
                          ULDRowKey: null,
                          AWBRowKey: null,
                          flightRowKey: null,
                          ULDData: [],
                          AWBData: [],
                        }, () => this.showRedFlights())}>
                          Show Red Flights
                      </Checkbox>
                      <Checkbox
                        checked={completedFlightsCheck}
                        onChange={(e) => this.handleFilter(null, e.target.checked, 'completed')}>
                          Show Completed Flights
                      </Checkbox>
                    </div>
                  </Col>

                  <Modal
                    title= {'Specify Reason'}
                    visible={this.state.show_otr_rsn}
                    onCancel={() => this.setState({
                      show_otr_rsn: false,
                      other_reason: '',
                      other_record: null,
                      other_id: null,
                    })}
                    footer={false}
                    width={'clamp( 300px, 800px, 100%)'}
                    centered
                    className='custom-modal pb-0'
                    destroyOnClose={true}
                  >
                    <Row gutter={[24,24]}>
                      <Col span={24}>
                        <Input
                          size='small'
                          placeholder=''
                          value={this.state.other_reason}
                          onChange={(e) => this.setState({ other_reason: e.target.value })}
                        />
                      </Col>
                      <Col className='ml-auto'>
                        <Button onClick={() => this.handleRecoveryActionChange(this.state.other_record, this.state.other_id, true, 'Other Reason')} type='primary' className='custom-button'>Submit</Button>
                      </Col>
                    </Row>
                  </Modal>

                  <Modal
                    title= {this.state.notesTitle}
                    visible={this.state.flightNotes}
                    onCancel={() => this.setState({
                      flightNotes: false,
                      notesTitle: ''
                    })}
                    footer={false}
                    width={'80%'}
                    centered
                    className='custom-modal pb-0'
                    destroyOnClose={true}
                  >
                    <Notes type={this.state.noteType} id={this.state.noteID} />
                  </Modal>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='table-overflow'
                  >
                    {/* <div onScrollCapture={() => { this.onScrollEvent() }}> */}
                    <div className='table-outer'>
                      <Table
                        ref={this.container}
                        className='custom-table w-100 arriving-flight-table'
                        dataSource={this.dataSource()}
                        columns={arrivinFlightColumns}
                        pagination={false}
                        onRow={(record) => {
                          return {
                            onClick: () => {
                              this.setState({
                                flightRowKey: record.id,
                                flightRow: record,
                                ULDRowKey: null,
                                AWBRowKey: null,
                                ULDData: [],
                                AWBData: []
                              }, () => this.fetchULDsByFlightID(record.id, 'SELECT'))
                            }
                          }}
                        }
                        rowClassName={(record) => record?.flight_leg &&  record?.flight_leg?.cancel ? 'row-disabled' : (record?.splice_countdown ? 'splice-countdown' : this.setRowClassName(record, 'flightRowKey'))}
                      />
                    </div>
                    {/* </div> */}
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
          <Col
            xs={{
              span: 0,
              order: 1,
            }}
            sm={{
              span: 24,
              order: 2,
            }}
            md={{
              span: 24,
              order: 3,
            }}
            lg={{
              span: 12,
              order: 3,
            }}
            xl={{
              span: 13,
              order: 3,
            }}
            xxl={{
              span: 13,
              order: 2,
            }}
            className='content-ht-half '
          >
            <Row
              gutter={[
                {
                  xs: 0,
                  sm: 0,
                  md: 24,
                  lg: 24,
                },
                {
                  xs: 24,
                  sm: 24,
                  md: 24,
                  lg: 24,
                },
              ]}
              className=''
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='content-ht-half'>
                <Card className='custom-card h-100'>
                  <Row
                    gutter={[
                      {
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24,
                      },
                      {
                        xs: 24,
                        sm: 24,
                        md: 12,
                        lg: 12,
                      },
                    ]}
                    className='with-table'
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                      <Title level={5} className='mb-0'>
                        ULDs
                      </Title>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      className='table-overflow'
                    >
                      <div className='table-outer'>
                        <Table
                          className='custom-table w-100 content-tb-ht-half'
                          dataSource={ULDData}
                          columns={uldColumns}
                          pagination={false}
                          onRow={(record) => {
                            return {
                              onClick: () => {
                                this.setState({
                                  ULDRowKey: record.id,
                                  ULDRow: record,
                                  AWBData: record.awbs_in,
                                  AWBRowKey: null,
                                })
                              }
                            }}
                          }
                          rowClassName={(record) => this.setRowClassName(record, 'ULDRowKey')}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='content-ht-half'>
                <Card className='custom-card h-100'>
                  <Row
                    gutter={[
                      {
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24,
                      },
                      {
                        xs: 24,
                        sm: 24,
                        md: 12,
                        lg: 12,
                      },
                    ]}
                    className='with-table'
                  >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                      <Title level={5} className='mb-0'>
                        AWBs
                      </Title>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      className='table-overflow'
                    >
                      <div className='table-outer'>
                        <Table
                          className='custom-table w-100 content-tb-ht-half'
                          dataSource={AWBData}
                          columns={awbColumns}
                          pagination={false}
                          onRow={(record) => {
                            return {
                              onClick: () => {
                                this.setState({
                                  AWBRowKey: record.id,
                                  AWBRow: record,
                                })
                              }
                            }}
                          }
                          rowClassName={(record) => this.setRowClassName(record, 'AWBRowKey')}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        {distPlan ? this.distributionPlan() : null}
        {/* DYNAMIC INFO MODAL */}
        <Modal
          title= {'Dynamic Info'+ this.state.dynamicInfoTitle}
          visible={this.state.dynamicInfoModal}
          onOk={this.hidedynamicInfo}
          onCancel={this.hidedynamicInfo}
          destroyOnClose
          footer={false}
          centered
          className='custom-modal pb-0 dynamicinfo-modal'
        >
          <Row gutter={[{
            xs: 0,
            sm: 0,
            md: 24,
            lg: 24
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24
          },]} className='with-table'>
            <Col xs={24} sm={24} lg={24} xl={24} className='pb-3' >
              <div className='table-outer'>
                <Table
                  className='custom-table w-100 content-tb-ht-half'
                  dataSource={this.state.dynamicInfoData}
                  columns={dynamicInfoColumns}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal
          width={400}
          closable
          destroyOnClose
          footer={null}
          visible={this.state.ispreviewImgModal}
          onCancel={()=>{ this.setState({ previewImg: false }, () => this.setState({ ispreviewImgModal: false })) }}
          className='preview-modal'
        >
          {this.state.previewImg && ( <img className='prev-img' src= {this.state.previewImg} alt={this.state.previewImg} />)}
        </Modal>
      </div>
    )
  }
}

export default InboundFlight
