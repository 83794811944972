import React from 'react'
import { Row, Col, Button, InputNumber, Alert } from 'antd'
import planeTail from '../../../../../assets/images/plane-tail.png'
import planeFront from '../../../../../assets/images/plane-front.png'

const INPUTPROPS = {
  className: 'ac-ip',
  type: 'number',
  size: 'small',  
  bordered: false,
  // min: 1,
  // max: 10,
  controls: false,
}

const AcType764 = (props) => {
  const INPUTDISABLED = props.ccPlanModalVisible

  return (
    <div className='parent-div-764'>
      <div className='ac-alert c763'>{props?.optimal &&(<Alert type='success' message='Optimal Configuration' showIcon />)}{!props?.optimal &&(<Alert type='error' message='Suboptimal Configuration: The use of LD3s causes unuseable space on a 767' showIcon />)}</div>
      <Row gutter={[0]} >
        <div className='top-row-764'>
          <Col>
            <div className='empty-box'></div>
          </Col>
          <Col span={2}>
            <Button className='clear-bins h-100' onClick={props.clearBin}>
              Clear bins
            </Button>  
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r28 || props.position?.cargo?.l28 || props.position?.cargo?.r27 || props.position?.cargo?.l27? 'disable-col' : '' }>
            <div className='boxes764 bl-1'>
              <span className='box-text'>24P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p24}
                  disabled={INPUTDISABLED || props.position?.cargo?.r28 || props.position?.cargo?.l28 || props.position?.cargo?.r27 || props.position?.cargo?.l27}
                  onChange={(e) => props.onChange(e, 'p24', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.l26 || props.position?.cargo?.r26 || props.position?.cargo?.r25 || props.position?.cargo?.l25? 'disable-col' : '' }>
            <div className='boxes764'>
              <span className='box-text'>23P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p23}
                  disabled={INPUTDISABLED || props.position?.cargo?.r25 || props.position?.cargo?.l25 || props.position?.cargo?.r26 || props.position?.cargo?.l26}
                  onChange={(e) => props.onChange(e, 'p23', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r23 || props.position?.cargo?.l23 || props.position?.cargo?.l24 || props.position?.cargo?.r24? 'disable-col' : '' }>
            <div className='boxes764'>
              <span className='box-text'>22P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p22}
                  disabled={INPUTDISABLED || props.position?.cargo?.r23 || props.position?.cargo?.l23 || props.position?.cargo?.l24 || props.position?.cargo?.r24}
                  onChange={(e) => props.onChange(e, 'p22', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r21 || props.position?.cargo?.l21 || props.position?.cargo?.r22 || props.position?.cargo?.l22 ? 'disable-col' : '' } >
            <div className='boxes764'>
              <span className='box-text'>21P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p21}
                  disabled={INPUTDISABLED || props.position?.cargo?.r21 || props.position?.cargo?.l21 || props.position?.cargo?.r22 || props.position?.cargo?.l22 }
                  className={`${INPUTPROPS.className} ${(props.position?.pallet?.p21) > 6860 ? 'red-bkg' : '' }`}
                  onChange={(e) => props.onChange(e, 'p21', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r12 || props.position?.cargo?.l12 || props.position?.cargo?.l11 || props.position?.cargo?.r11 ? 'disable-col' : '' }>
            <div className='boxes764'>
              <span className='box-text'>11P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p11}
                  disabled={INPUTDISABLED || props.position?.cargo?.r12 || props.position?.cargo?.l12 || props.position?.cargo?.l11 || props.position?.cargo?.r11}
                  className={`${INPUTPROPS.className} ${(props.position?.pallet?.p11) > 6860 ? 'red-bkg' : '' }`}
                  onChange={(e) => props.onChange(e, 'p11', 'pallet')}
                />
              </div>
            </div>
          </Col>
        </div>
        
      </Row> 
     
      <Row gutter={[0]}>
        <div className='middle-row b764'>
          <Col className='tail'>
            <img className= 'plane-back ' src={planeTail} />
          </Col>
          <Col >
            <div className='boxes1 bl-1'>
              <span className='box-text'>Bulk</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={ props.position?.bulk} 
                  onChange={(e) => props.onChange(e, null, 'bulk')}
                  // disabled={INPUTDISABLED || props.position?.pallet?.p34} 
                />
              </div>
            </div>
          </Col> 
          <Col >
            <div className='boxes2'>
              <span className='box-text'>Crew Rest</span>
            </div>
          </Col> 
          <Col className={INPUTDISABLED || props.position?.pallet?.p41 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>41L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l41} 
                  onChange={(e) => props.onChange(e, 'l41', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l41 + props.position?.cargo?.r41) >= 2968 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p41} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>41R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r41} 
                  onChange={(e) => props.onChange(e, 'r41', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l41 + props.position?.cargo?.r41) >= 2968 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p41} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p41 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>37L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l37}
                  onChange={(e) => props.onChange(e, 'l37', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l37 + props.position?.cargo?.r37) >= 2968 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p41} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>37R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r37}
                  onChange={(e) => props.onChange(e, 'r37', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l37 + props.position?.cargo?.r37) >= 2968 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p41} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p34 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>36L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l36}
                  onChange={(e) => props.onChange(e, 'l36', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l36 + props.position?.cargo?.r36) >= 2968 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p34} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>36R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r36}
                  onChange={(e) => props.onChange(e, 'r36', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l36 + props.position?.cargo?.r36) >= 2968 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p34} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p33 || props.position?.pallet?.p34 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>35L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l35} 
                  onChange={(e) => props.onChange(e, 'l35', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l35 + props.position?.cargo?.r35) >= 2968 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p33 || props.position?.pallet?.p34} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>35R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r35} 
                  onChange={(e) => props.onChange(e, 'r35', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l35 + props.position?.cargo?.r35) >= 2968 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p33 || props.position?.pallet?.p34} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p33 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>34L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l34} 
                  onChange={(e) => props.onChange(e, 'l34', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p33} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>34R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r34} 
                  onChange={(e) => props.onChange(e, 'r34', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p33} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p32 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>33L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l33}
                  onChange={(e) => props.onChange(e, 'l33', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p32} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>33R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r33} 
                  onChange={(e) => props.onChange(e, 'r33', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p32} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p31 || props.position?.pallet?.p32 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>32L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l32} 
                  onChange={(e) => props.onChange(e, 'l32', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p31 || props.position?.pallet?.p32} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>32R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r32} 
                  onChange={(e) => props.onChange(e, 'r32', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p31 || props.position?.pallet?.p32} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p31 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>31L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l31} 
                  onChange={(e) => props.onChange(e, 'l31', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p31} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>31R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r31} 
                  onChange={(e) => props.onChange(e, 'r31', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p31} 
                />
              </div>
            </div>
          </Col>
          <Col >
            <div className='boxes2'>
            </div>
          </Col> 
          <Col className={INPUTDISABLED || props.position?.pallet?.p24 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>28L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l28}
                  onChange={(e) => props.onChange(e, 'l28', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p24} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>28R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r28} 
                  onChange={(e) => props.onChange(e, 'r28', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p24} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p24 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>27L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l27}
                  onChange={(e) => props.onChange(e, 'l27', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p24} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>27R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r27} 
                  onChange={(e) => props.onChange(e, 'r27', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p24} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p23 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>26L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l26} 
                  onChange={(e) => props.onChange(e, 'l26', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>26R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r26} 
                  onChange={(e) => props.onChange(e, 'r26', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p23 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>25L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l25} 
                  onChange={(e) => props.onChange(e, 'l25', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>25R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r25} 
                  onChange={(e) => props.onChange(e, 'r25', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p22 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>24L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l24} 
                  onChange={(e) => props.onChange(e, 'l24', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>24R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r24} 
                  onChange={(e) => props.onChange(e, 'r24', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p22 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>23L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l23} 
                  onChange={(e) => props.onChange(e, 'l23', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>23R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r23} 
                  onChange={(e) => props.onChange(e, 'r23', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>           
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p21 ? 'disable-col' : ''} >
            <div className='top-box'>
              <span className='box-text'>22L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l22} 
                  onChange={(e) => props.onChange(e, 'l22', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.pallet?.p21) > 6860 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>         
            </div>
            <div className='bottom-box'>
              <span className='box-text'>22R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r22} 
                  onChange={(e) => props.onChange(e, 'r22', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.pallet?.p21) > 6860 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>         
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p21 ? 'disable-col' : ''} >
            <div className='top-box'>
              <span className='box-text'>21L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l21} 
                  onChange={(e) => props.onChange(e, 'l21', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l21 || props.position?.cargo?.r21) > 3225 || (props.position?.pallet?.p21) > 6860 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>              
            </div>
            <div className='bottom-box'>
              <span className='box-text'>21R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r21} 
                  onChange={(e) => props.onChange(e, 'r21', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l21 || props.position?.cargo?.r21) > 3225 || (props.position?.pallet?.p21) > 6860 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>              
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p11 ? 'disable-col' : ''} >
            <div className='top-box'>
              <span className='box-text'>12L</span>
              <div className='box-line-bottom'>
                <InputNumber {...INPUTPROPS}
                  value={props.position?.cargo?.l12} 
                  onChange={(e) => props.onChange(e, 'l12', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l12 || props.position?.cargo?.r12) > 3225 || (props.position?.pallet?.p11) > 6860 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>12R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r12}
                  onChange={(e) => props.onChange(e, 'r12', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l12 || props.position?.cargo?.r12) > 3225 || (props.position?.pallet?.p11) > 6860 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p11 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>11L</span>
              <div className='box-line-bottom'>
                <InputNumber {...INPUTPROPS}
                  value={props.position?.cargo?.l11}
                  onChange={(e) => props.onChange(e, 'l11', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l11 || props.position?.cargo?.r11) > 3225 || (props.position?.pallet?.p11) > 6860 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>              
            </div>
            <div className='bottom-box'>
              <span className='box-text'>11R</span>
              <div className='box-line-bottom'>
                <InputNumber {...INPUTPROPS}
                  value={props.position?.cargo?.r11}
                  onChange={(e) => props.onChange(e, 'r11', 'cargo')}
                  className={`${INPUTPROPS.className} ${(props.position?.cargo?.l11 || props.position?.cargo?.r11) > 3225 || (props.position?.pallet?.p11) > 6860 ? 'red-bkg' : '' }`}
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>
              {/* <InputNumber  {...INPUTPROPS}
value={} type='number' className={`${INPUTPROPS.className} red`} placeholder='' disabled={props.position?.cargo?.lr11} /> */}
            </div>
          </Col> 
          <Col>
            <img className='plane-front' src={planeFront} />
          </Col>
        </div>
      </Row>
    </div>
  )
}
export default AcType764

