import React, { Component } from 'react'
import {
  Row,
  Col,
  Card,
  // Input,
  DatePicker,
  Space,
  Typography,
  Form,
  Radio,
  Tooltip,
  notification,
  Progress,
  Select,
  ConfigProvider
} from 'antd'
const { Option } = Select
import moment from 'moment'
import axios from 'axios'
import Cookies from 'universal-cookie'
import { InfoCircleOutlined } from '@ant-design/icons'
import { constants, timezone } from '../../../constants/constants'
import cssconstants from '../../../constants/cssconstants'
import './index.css'
import en_GB from 'antd/lib/locale-provider/en_GB'
import 'moment/locale/en-gb'

moment.locale('en-gb')

const { Text } = Typography

const stationTooltip = 'Select the dropdown in the header to change station'
const URL = process.env.REACT_APP_API_URL 
let ENV = process.env.REACT_APP_ENV
const weekFormat = 'MM/DD'

switch (ENV) {
case 'development':
  ENV = 'dev'.toUpperCase()
  break
case 'stage':
  ENV = 'stage'.toUpperCase()
  break
case 'production':
  ENV = 'prod'.toUpperCase()
}

class Report extends Component {
  constructor() {
    super()
    this.state = {
      type: 'W',
      module: 'rebooking',
      moduleLabel: 'Rebooking',
      station: new Cookies().get('station'),
      role: new Cookies().get('role'),
      startDate: moment().startOf('week').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      today: moment().format('YYYY-MM-DD'),
      flight: 'planned_flights',
      downloadProgress: 10,
      isFetchingData: false,
      isFetchingMeasData: false,
      monthSelect: moment().startOf('month'),
      yearSelect: moment().startOf('year')
    }
  }

  onChangeModule = (e) => {
    this.setState({
      module: e.target.value,
      moduleLabel: e.target.label,
      type: 'W',
    })
    let YMDformat = 'YYYY-MM-DD'
    let value = this.state.today
    let valueD = moment(this.state.today)
    let valueDate = valueD.clone().format(YMDformat)
    let weekStart = moment().startOf('week').format(YMDformat)
    let weekEnd = moment().endOf('week').format(YMDformat)
    let monthStart = valueD.clone().startOf('month').format(YMDformat)
    let startOfFirstWeek = valueD.clone().startOf('month').startOf('isoWeek')
    let endOfFirstWeek = startOfFirstWeek.clone().endOf('isoWeek').format(YMDformat)
    let endOfMonth = valueD.clone().endOf('month')
    let startOfLastWeek = endOfMonth.clone().startOf('isoWeek')
    if(weekStart <= valueDate && valueDate <= weekEnd) {
      this.setState({ 
        startDate: weekStart,
        endDate: this.state.today,
      })
    }
    else if(monthStart <= valueDate && valueDate <= endOfFirstWeek ) {
      this.setState({ 
        startDate: moment(value).startOf('month').format(YMDformat),
        endDate: moment(value).endOf('isoWeek').format(YMDformat),
      })
    }
    else if( startOfLastWeek.format(weekFormat) <= valueDate && valueDate <= endOfMonth.format(YMDformat)  ) {
      this.setState({ 
        startDate: moment(value).startOf('isoWeek').format(YMDformat),
        endDate: moment(value).endOf('month').format(YMDformat),
      })
    }
    else {
      this.setState({ 
        startDate: moment(value).startOf('week').format(YMDformat),
        endDate: moment(value).endOf('week').format(YMDformat),
      })
    }
  }

  onChangeRange = () => {
    this.setState({ 
      startDate: '',
      endDate: '',
    }, ()=>{})
    let today = this.state.today    
    if(this.state.type === 'M') {
      this.setState({ 
        startDate: '',
        endDate: '',
      })
      let currentDate =  this.state.monthSelect
      let monthStart = currentDate.startOf('month').format('YYYY-MM-DD')
      let monthEnd   = currentDate.endOf('month').format('YYYY-MM-DD')    
      let currentMonth = moment().month()
      let selectedMonth = currentDate.month()
      let today = this.state.today
      if( selectedMonth === currentMonth) {
        this.setState({ 
          startDate: monthStart,
          endDate: today,
        })
        // this.setState({ startDate: monthStart }, () => {
        // })
      }
      else {
        this.setState({ 
          startDate: monthStart,
          endDate: monthEnd,
        })
      }
    }
    else if(this.state.type === 'W') {
      let thisWeekStart = moment().startOf('week').format('YYYY-MM-DD')
      this.setState({ 
        startDate: thisWeekStart,
        endDate: today,
      })
      // this.setState({ startDate: thisWeekStart }, () => {
      // })
    }
    else if(this.state.type === 'Y') {
      this.setState({ 
        startDate: '',
        endDate: '',
      },()=>{})
      let currentDate =  this.state.yearSelect
      let yearStart = currentDate.startOf('year').format('YYYY-MM-DD')
      let yearEnd   = currentDate.endOf('year').format('YYYY-MM-DD')    
      let currentYear = moment().year()
      let selectedYear = currentDate.year()
      let today = this.state.today
      if( selectedYear === currentYear) {
        this.setState({ 
          startDate: yearStart,
          endDate: today, 
        })
        this.setState({ startDate: yearStart }, () => {
        })  
      }
      else {
        this.setState({ 
          startDate: yearStart,
          endDate: yearEnd,
        })
      }
    }
  }

  downloadReport = (e) => {
    //let reportURL = `${URL}${this.state.module}`
    let url = `${URL}get_report`
    document.body.style.pointerEvents = 'none'
    if(e.target.id === 'measurement'){
      this.setState({ isFetchingMeasData: true })
      switch (this.state.module) {
      case 'rebooking':
        url = url +'?type=rebooking_measurement'
        break
      case 'inbound':
        url = url +'?type=inbound_measurement'
        break
      case 'recovery':
        url = url +'?type=recovery_measurement'
        break
      case 'breakdown':
        url = url +'?type=breakdown_measurement'
        break
      case 'buildup':
        url = url +'?type=buildup_measurement'
        break
      case 'haulout':
        url = url +'?type=haulout_measurement'
        break
      }
    } else {
      this.setState({ isFetchingData: true })
      switch (this.state.module) {
      case 'rebooking':
        url = url +'?type=rebooking'
        break
      case 'inbound':
        url = url +'?type=inbound'
        break
      case 'recovery':
        url = url +'?type=recovery'
        break
      case 'breakdown':
        url = url +'?type=breakdown'
        break
      case 'buildup':
        url = url +'?type=buildup'
        break
      case 'fcp':
        url = url +'?type=fcp&&is_planned='+`${this.state.flight === 'planned_flights' ? true : false}`
        break
      case 'haulout':
        url = url +'?type=haulout'
        break
      case 'users':
        url = url +'?type=users'
        break
      }
    }
    url = url+'&&start='+this.state.startDate+'&&end='+this.state.endDate
    if(this.state.station !== 'ALL' ){
      url = url+'&&station='+this.state.station
    }
    url = url+'&&range='+this.state.type

    axios({
      //url: reportURL,
      url: url,
      method: 'GET',
      responseType: 'blob',
      // params: {
      //   ...(this.state.module === 'user_login_report'? {} : {
      //     start: this.state.startDate,
      //     end: this.state.endDate 
      //   }),
      //   station: this.state.station,
      //   ...(e.target.id === 'measurement' ? { is_measurement: true } : {} ),
      //   ...(this.state.module === 'fcp_report' ? {
      //     is_planned: this.state.flight === 'planned_flights' ? true : false ,
      //     is_nb: true  
      //   } : {} )
      // },
      onDownloadProgress: this.handleDownloadProgress,
    }).then(async (response) => {
      document.body.style.pointerEvents = 'auto'
      {e.target.id === 'measurement' ? this.setState({ isFetchingMeasData: false }) : this.setState({ isFetchingData: false }) }
      if(response && response?.status === 200) {
        var reportModule = this.state.module
        var reportName = reportModule.charAt(0).toUpperCase() + reportModule.slice(1)
        if(e.target.id === 'measurement') {
          var measurementArray = reportName.split('_')
          var measurementName = measurementArray[0]+ '_' + 'measurement' + '_' + measurementArray[1]
          reportName = measurementName
        }
        let fileName = ''
        let file = `${this.state.station}_${ENV}_${reportName}`
        if(this.state.module === 'user_login_report'){
          fileName = `${file}`
        }
        else{
          fileName = `${file}_${this.state.startDate}_${this.state.endDate}`
        }
        //check data type
        const isJsonBlob = (data) => data instanceof Blob && data.type === 'application/json'
        const responseData = isJsonBlob(response?.data) ? await (response?.data)?.text() : response?.data || {}
        let startDate = this.state.startDate
        let endDate = this.state.endDate
        if(typeof responseData ==='string'){
          const responseJson = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData
          if(responseJson && responseJson?.data?.location){
            //download file from s3
            axios({
              url: `${URL}download_file?key=${responseJson?.data?.location}`,
              method: 'GET',
              responseType: 'blob',
              onDownloadProgress: this.handleDownloadProgress,
            }).then(async (resp) => {
              document.body.style.pointerEvents = 'auto'
              {e.target.id === 'measurement' ? this.setState({ isFetchingMeasData: false }) : this.setState({ isFetchingData: false }) }
              if(response && response?.status === 200) {
                var reportModule = this.state.module
                var reportName = reportModule.charAt(0).toUpperCase() + reportModule.slice(1)
                if(e.target.id === 'measurement') {
                  var measurementArray = reportName.split('_')
                  var measurementName = measurementArray[0]+ '_' + 'measurement' + '_' + measurementArray[1]
                  reportName = measurementName
                }
                let fileName = ''
                let file = `${this.state.station}_${ENV}_${reportName}`
                if(this.state.module === 'user_login_report'){
                  fileName = `${file}`
                }
                else{
                  fileName = `${file}_${startDate}_${endDate}`
                } 
                let  extension = responseJson?.data?.location.split('.').pop() || null
                let fileType = extension? (fileName+'.'+extension) : (fileName+'.csv') //or any other extension
                // create file link in browser's memory
                const href = window.URL.createObjectURL(resp.data)
                // create "a" HTML element with href to file & click
                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', fileType) 
                document.body.appendChild(link)
                link.click()
                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link)
                window.URL.revokeObjectURL(href)
              }
            }).catch((error) => {
              // eslint-disable-next-line no-console
              console.log('error',error)
            })

          }
        } else {
          let fileType = `${fileName}.csv` //or any other extension
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response.data)
          // create "a" HTML element with href to file & click
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', fileType) 
          document.body.appendChild(link)
          link.click()
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link)
          window.URL.revokeObjectURL(href)
        }
        // this.setState({ 
        //   startDate: '',
        //   endDate: '',
        // })
      }
      else {
        // eslint-disable-next-line no-console
        console.log('error')
      }
    })
      .catch((error) => {
        document.body.style.pointerEvents = 'auto'
        {e.target.id === 'measurement' ? this.setState({ isFetchingMeasData: false }) : this.setState({ isFetchingData: false }) }
        // eslint-disable-next-line no-console
        console.error(error)
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.NO_DATA_AVAILABLE,
        })
      })
  }

  handleDownloadProgress = (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    this.setState({ downloadProgress: percentCompleted })
  }

  handleWeekChange = (date) => {
    this.setState({ 
      startDate: '',
      endDate: '',
    })
    let currentDate =  date
    let weekStart = currentDate.clone().startOf('week').format('YYYY-MM-DD')
    let weekEnd = currentDate.clone().endOf('week').format('YYYY-MM-DD')
    let thisWeekStart = moment().startOf('week').format('YYYY-MM-DD')
    let selectedDate = currentDate.format('YYYY-MM-DD')
    let startOfFirstWeek = currentDate.clone().startOf('month').startOf('isoWeek')
    let endOfFirstWeek = startOfFirstWeek.clone().endOf('isoWeek').format('YYYY-MM-DD')
    let thisMonthStart = currentDate.clone().startOf('month').format('YYYY-MM-DD')
    let endOfMonth = currentDate.clone().endOf('month')
    let startOfLastWeek = endOfMonth.clone().startOf('isoWeek')
    let today = this.state.today
    if( moment(thisWeekStart).format('MM')!== moment(today).format('MM')){
      if(moment(thisWeekStart).format('MM')== currentDate.format('MM')){
        today = currentDate.endOf('month').format('YYYY-MM-DD')
      } else if (moment(today).format('MM')== currentDate.format('MM')){
        thisWeekStart = currentDate.startOf('month').format('YYYY-MM-DD')
      }
    }
    if( moment(weekStart).format('MM')!== moment(weekEnd).format('MM')){
      if(moment(weekStart).format('MM')== currentDate.format('MM')){
        weekEnd = currentDate.endOf('month').format('YYYY-MM-DD')
      } else if (moment(weekEnd).format('MM')== currentDate.format('MM')){
        weekStart = currentDate.startOf('month').format('YYYY-MM-DD')
      }
    }
    if( thisWeekStart < selectedDate &&  selectedDate < today) {
      this.setState({ 
        startDate: thisWeekStart,
        endDate: today,
      })
    }
    if(thisMonthStart < selectedDate &&  selectedDate < endOfFirstWeek  ) {
      this.setState({ 
        startDate: thisMonthStart,
        endDate: endOfFirstWeek,
      })
    }
    if( startOfLastWeek < selectedDate &&  selectedDate < endOfMonth.format('YYYY-MM-DD')  ) {
      this.setState({ 
        startDate: startOfLastWeek,
        endDate: endOfMonth.format('YYYY-MM-DD'),
      })
    }
    else {
      this.setState({
        startDate: weekStart,
        endDate: weekEnd,
      })
    }
  }
  
  customWeekStartEndFormat = (value) => {
    let valueD = moment(value._d)
    let valueDate = valueD.clone().format(weekFormat)
    let weekStart = moment().startOf('week').format(weekFormat)
    let weekEnd = moment().endOf('week').format(weekFormat)
    let monthStart = valueD.clone().startOf('month').format(weekFormat)
    let startOfFirstWeek = valueD.clone().startOf('month').startOf('isoWeek')
    let endOfFirstWeek = startOfFirstWeek.clone().endOf('isoWeek').format(weekFormat)
    let endOfMonth = valueD.clone().endOf('month')
    let startOfLastWeek = endOfMonth.clone().startOf('isoWeek')
    if(weekStart <= valueDate && valueDate <= weekEnd) {
      return`${weekStart} ~ ${moment().format(weekFormat)}`
    }
    if(monthStart <= valueDate && valueDate <= endOfFirstWeek ) {
      return`${moment(value).startOf('month').format(weekFormat)} ~ ${moment(value)
        .endOf('isoWeek')
        .format(weekFormat)}`
    }
    if( startOfLastWeek.format(weekFormat) <= valueDate && valueDate <= endOfMonth.format(weekFormat)  ) {
      return`${moment(value).startOf('isoWeek').format(weekFormat)} ~ ${moment(value).endOf('month').format(weekFormat)}`
    }
    else {
      return`${moment(value).startOf('week').format(weekFormat)} ~ ${moment(value)
        .endOf('week')
        .format(weekFormat)}`
    }
  }
  
  handleMonthChange = (date) => {
    this.setState({ 
      startDate: '',
      endDate: '',
      monthSelect: date
    })
    let currentDate =  date
    let monthStart = currentDate.startOf('month').format('YYYY-MM-DD')
    let monthEnd   = currentDate.endOf('month').format('YYYY-MM-DD')    
    let currentMonth = moment().month()
    let selectedMonth = currentDate.month()
    let today = this.state.today
    if( selectedMonth === currentMonth) {
      this.setState({ 
        startDate: monthStart,
        endDate: today,
      })
    }
    else {
      this.setState({ 
        startDate: monthStart,
        endDate: monthEnd,
      })
    }
  }

  handleYearChange = (date) => {
    this.setState({ 
      startDate: '',
      endDate: '',
      yearSelect: date
    })
    let currentDate =  date
    let yearStart = currentDate.startOf('year').format('YYYY-MM-DD')
    let yearEnd   = currentDate.endOf('year').format('YYYY-MM-DD')    
    let currentYear = moment().year()
    let selectedYear = currentDate.year()
    let today = this.state.today
    if( selectedYear === currentYear) {
      this.setState({ 
        startDate: yearStart,
        endDate: today, 
      })
    }
    else {
      this.setState({ 
        startDate: yearStart,
        endDate: yearEnd,
      })
    }
  }

  render() {
    const { downloadProgress, isFetchingData, isFetchingMeasData } = this.state
    return (
      <div>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} className=''>
            <Card
              title={'Report - ' + this.state.station}
              className='custom-card'
            >
              <Row className='mb-4'>
                <Col xs={24} className=''>
                  <label className='custom-label label-border'>Module</label>
                  <Radio.Group
                    onChange={this.onChangeModule}
                    className='f-spacebtw f-wrap module-radio'
                    value={this.state.module}
                    label={this.state.moduleLabel}
                  >
                    <Radio value='rebooking' label='Rebooking'>
                      Rebooking
                    </Radio>
                    <Radio value='inbound' label='Inbound Flight Prep'>
                      Inbound Flight Prep
                    </Radio>
                    <Radio value='recovery' label='Recovery'>
                      Recovery
                    </Radio>
                    <Radio value='breakdown' label='Breakdown'>
                      Breakdown
                    </Radio>
                    <Radio value='buildup' label='Buildup'>
                      Buildup
                    </Radio>
                    <Radio value='fcp' label='Flight Capacity Planning'>
                      Flight Capacity Planning
                    </Radio>
                    <Radio value='haulout' label='Haulout'>
                      Haulout
                    </Radio>
                    {/* <Radio value='user_login_report' label='Station Usage'>
                      Station Usage
                    </Radio> */}
                    <Radio value='users' label='User Report'>
                      User Report
                    </Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24}>
            <Card
              className='custom-card'
              title={
                <>
                  <Text type='secondary rep-sub-title'>Module</Text>
                  <div className=''>{this.state.moduleLabel}</div>
                </>
              }
            >
              <Form
                layout='vertical'
              >
                <Row
                  gutter={[
                    {
                      xs: 0,
                      sm: 24,
                      md: 24,
                      lg: 24,
                    },
                    {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: 0,
                    },
                  ]}
                >
                  <Col xs={24} sm={6} md={5} lg={6} xl={6} xxl={6}>
                    <Form.Item
                      className='label-w100'
                      label={
                        <div className='f-spacebtw w-100'>
                          <span>Station</span>
                          {this.state.role !== 'Super Admin' ? 
                            <Tooltip
                              title={stationTooltip}
                              overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs, }}
                            >
                              <InfoCircleOutlined />
                            </Tooltip> : ''}
                        </div>
                      }
                    >
                      {/* <Input
                        size='small'
                        placeholder=''
                        disabled={this.state.role !== 'Super Admin' ? true : false}
                        value={this.state.station}
                      /> */}
                      <Select
                        //defaultValue='Select Station'
                        size='small'
                        value={this.state.station}
                        onChange={e=>this.setState({ station: e })}
                        //options={timezone}
                      >
                        <Option className='blue' key={'ALL'} value={'ALL'}>{'ALL'}</Option>
                        {timezone?.map((item,i) => (
                          <Option key={i} value={item.value}>{item.value}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  { this.state.module === 'fcp' ? 
                    <>
                      {/* <Col className=''>
                        <Form.Item>
                          <label className='custom-label label-border'>Narrow Body</label>
                          <Radio.Group
                            onChange={ e => this.setState(
                              { narrowBody: e.target.value }
                            )}
                            value={this.state.narrowBody}
                            className='radio-nowrap range-radio'
                          >
                            <Radio value='include'>Include</Radio>
                            <Radio value='exclude' disabled>Exclude</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col> */}
                      <Col flex={'280px'} className=''>
                        <Form.Item>
                          <label className='custom-label label-border'>Flights</label>
                          <Radio.Group
                            onChange={ e => this.setState(
                              { flight: e.target.value }
                            )}
                            value={this.state.flight}
                            className='radio-nowrap range-radio'
                          >
                            <Radio value='planned_flights'>Planned Flights</Radio>
                            <Radio value='all_flights'>All Flights</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col></>  
                    : ''
                  }

                  <Col flex={'200px'} className=''>
                    <Form.Item>
                      <label className='custom-label label-border'>Range</label>
                      <Radio.Group
                        onChange={(e) =>
                          this.setState(
                            { type: e.target.value },
                            this.onChangeRange
                          )
                        }
                        disabled={this.state.module === 'user_login_report' ? true : false}
                        value={this.state.type}
                        className='radio-nowrap range-radio'
                      >
                        <Radio value='W'>Weekly</Radio>
                        <Radio value='M'>Monthly</Radio>
                        <Radio value='Y'>Yearly</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={6} md={5} lg={6} xl={6} xxl={6}>
                    {this.state.type === 'M' ? (
                      <Form.Item label='Select Month'>
                        <DatePicker
                          onChange={this.handleMonthChange}
                          picker='month'
                          disabled={this.state.module === 'user_login_report' ? true : false}
                          defaultValue={moment()} 
                          value={this.state.monthSelect}
                          disabledDate={(current) => {
                            let customDate = moment().format('YYYY-MM-DD')
                            return current && current > moment(customDate, 'YYYY-MM-DD')
                          }} 
                        />
                      </Form.Item>
                    ) : this.state.type === 'W' ? (
                      <ConfigProvider locale={en_GB}>
                        <Form.Item label='Select Week'>
                          <DatePicker
                            onChange={this.handleWeekChange}
                            picker='week'
                            disabled={this.state.module === 'user_login_report' ? true : false}
                            defaultValue={moment()} format={this.customWeekStartEndFormat}
                            disabledDate={(current) => {
                              let customDate = moment().add(1,'day').format('YYYY-MM-DD')
                              return current && current > moment(customDate, 'YYYY-MM-DD')
                            }}
                            popupClassName='reports-weekpicker'
                          />
                        </Form.Item>
                      </ConfigProvider>
                    ) : <Form.Item label='Select Year'>
                      <DatePicker
                        onChange={this.handleYearChange}
                        picker='year'
                        value={this.state.yearSelect}
                        disabledDate={(current) => {
                          let currentYear = moment().year()
                          // return current && (current.year() < 2023 || current.year() > moment(currentYear).year())
                          return current && (current.year() < 2023 || current.year() > currentYear)
                        }}
                      />
                    </Form.Item>}
                  </Col>

                  <Col className='d-flex ml-auto'>
                    <Space direction='horizontal' className='ml-auto'>
                      <div className='position-relative'>
                        <button
                          type='primary'
                          id='report' 
                          className='custom-button pointer mr-0 download-btn'
                          onClick={this.downloadReport}
                        >
                          {isFetchingData ? 'Downloading... ' : 'Download Report'}
                        </button>
                        {isFetchingData ? 
                          <Progress percent={downloadProgress} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                          : ''
                        }
                      </div>
                      {this.state.module === 'recovery' || this.state.module === 'buildup' || this.state.module === 'inbound' || this.state.module === 'breakdown' || this.state.module === 'haulout' || this.state.module === 'rebooking' ?
                        <div className='position-relative'>
                          <button
                            type='primary'
                            id='measurement' 
                            className='custom-button pointer mr-0 download-btn'
                            onClick={this.downloadReport}
                          >
                            {isFetchingMeasData ? 'Downloading... ' : 'Download Measurements'}
                          </button>
                          {isFetchingMeasData ? 
                            <Progress percent={downloadProgress} status='active' strokeColor='fff' trailColor='transparent' strokeWidth={4} showInfo={false} className='red-progressbar' />
                            : ''
                          }
                        </div>
                        : ''
                      }
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Report