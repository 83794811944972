import React, { Component } from 'react'
import { List, Modal, notification, Spin } from 'antd'
import { constants } from '../../constants/constants'
import Http from '../../utils/http'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { getCookie, COOKIE } from '../../utils/cookie'
import { formatAWBNo } from '../../utils/common'

const pathnames = [
  {
    path: '/breakdown-control',
    type: 'break_down'
  },
  {
    path: '/inbound-flight',
    type: 'inbound'
  },
]

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      timezone: getCookie(COOKIE.TIMEZONE),
      visible: false,
      notificationType: '',
      loading: false,
      activityData: [],
      userList: [],
    }
  }

  componentDidMount() {
    this.setState({
      visible: this.props?.visible,
      loading: true,
      notificationType: pathnames?.find((pathname) => pathname.path?.includes(window.location.pathname))?.type || 'system_parameters'
    }, () => this.fetchUsers())
  }
  //FETCH USERS LIST
  fetchUsers = async () => {
    let url = '/users?sa=true'
    await Http.get(url, { headers: '' }).then(resp => {
      if(resp?.status === 200) {
        if(resp?.data?.data?.length > 0) {
          this.setState({ userList: resp.data.data })
        }
        this.fetchActivityLogs()
      } else {
        this.setState({ loading: false })
      }
    }).catch(() => { this.setState({ loading: false }, () => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
    }) })
  }
  fetchActivityLogs = async() => {
    let url = `activity_logs/${this.state.notificationType}`
    await Http.get(url, { headers: '' }).then(resp => {
      if (resp?.status === 200) {
        if(resp?.data?.data?.length > 0) {
          let data = resp.data.data.map((item) => { return JSON.parse(item) })
          data = data?.map((item) => { return { ...this.processData(item) } })
          this.setState({ activityData: data })
        } else {
          this.setState({ activityData: [] })
        }
        this.setState({ loading: false })
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ loading: false })
    })
  }

  processData = (item) => {
    //SET USER OBJECT
    let users = cloneDeep(this.state.userList)
    item.user_name = 'System'
    if(item?.user_id) {
      let userObj = users?.find((user) => user.id === parseInt(item.user_id))?.name || null
      if(userObj) {
        item.user_name = `${userObj?.first_name} ${userObj?.last_name}`
      }
    }
    //SET MESSAGE
    item.message = ''
    if(item.action) {
      item.message = item.action === 'create' ? 'created' : item.action === 'update' ? 'updated' : 'deleted'
      item.message = item.message + ` ${item.type?.toLowerCase()}`
    }

    if(item?.type === 'FLIGHT') {
      item.typedisp = 'Flight Moved to Completed'
      item.message = `${item?.flight?.flight_no}/${moment(item?.flight?.flight_date).format('DDMMMYY')}` + ' moved to completed list'
    } else if(item?.type === 'MANIFEST') {
      item.typedisp = 'Manifest Received'
      item.message = `FFM received for ${item?.flight?.flight_no}/${moment(item?.flight?.flight_date).format('DDMMMYY')}`
    } else if(item?.type === 'BOOKING') {
      item.typedisp = 'Booking Received'
      item.message = `Booking received for ${formatAWBNo(item?.awb?.awb_no)}`
    } else if(item?.type === 'RECOVERY') {
      item.typedisp = 'Recovery Action Initiated'
      item.message = `Recovery action initiated for ${formatAWBNo(item?.awb?.awb_no)}`
    } else if(item?.type === 'ULD') {
      item.typedisp = 'ULD Moved to Completed'
      item.message = `${item?.uld?.uld_no} moved to completed list`
    } else {
      item.typedisp = item.type
    }
    return item
  }

  render() {
    return (
      <>
        <Modal
          visible={this.state.visible}
          closable={false}
          maskClosable
          destroyOnClose
          footer={false}
          onCancel={() => this.props.closeModal()}
          width={480}
          className='noti-modal'
        >
          {this.state.loading && (<div id='spinner' className='spinner-cont'>
            <Spin />
          </div>)}
          <List
            itemLayout='horizontal'
            dataSource={this.state.activityData}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  title={<a>{item.typedisp} ({item.user_name})</a>}
                  description={`${item.message} on ${moment.utc(item?.date).tz(this.state.timezone).format('DD-MMM-YY')} at ${moment.utc(item?.date).tz(this.state.timezone).format('HH:mm')}`}
                />
              </List.Item>
            )}
          />
        </Modal>
      </>
    )
  }
}

export default Notifications