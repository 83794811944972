import React, { Component } from 'react'
import { Button, Input, Row, Col, Form, Card, Table, Checkbox, Tooltip, Popconfirm, Spin, Modal, Switch, Radio, InputNumber, notification, Select } from 'antd'
import constants, { systemParameterTabs as titles } from '../../../constants/constants'
import addIcon from '../../../assets/images/add.svg'
import Update from '../../../assets/images/input-tick.svg'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import cssconstants from '../../../constants/cssconstants'
import Http from '../../../utils/http'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'
import { weightCalculator, cacheRetrieve } from '../../../utils/common'

const { Option } = Select

class ULDMapping extends Component {
  constructor(props) {
    super(props)
    this.state = {
      duplicate: null,
      isListening: false,
      loading: false,
      data: [],
      station: getCookie(COOKIE.STATION),
      timezone: getCookie(COOKIE.TIMEZONE),
      user_id: getCookie(COOKIE.ID),
      weight_unit: getCookie(COOKIE.WEIGHT),
      unit: getCookie(COOKIE.WEIGHT) === 'KG' ? 'KG' : 'LB',
      checkDisabled: false,
      rows: [],
      cookieInterval: '',

      uld_prefix: '',
      contour: '',
      wgt: '',
      type: '',
      positions: '',

      editRecord: null,
      editUld_prefix: '',
      editContour: '',
      editWgt: '',
      editType: '',
      editPositions: '',

      acTypeList: [],
      disabledACList: [],
      selectedACTypeList: [],
      acTypeDisabled: false,
      displayPrefix: '',
    }
  }

  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    this.setState({ cookieInterval: setInterval(this.checkInterval, 1000) })
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${this.state.station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData?.TYPE === 'ULD MAPPING'){
          this.fetchULDs()
        }
      }
    }
    this.fetchFleet()
  }

  componentWillUnmount() {
    this.setState({ cookieInterval: clearInterval(this.state.cookieInterval) })
  }

  checkInterval = () => {
    let newCookie = getCookie(COOKIE.WEIGHT)
    if(newCookie !== this.state.weight_unit) {
      this.setState({
        weight_unit: newCookie,
        unit: newCookie === 'KG' ? 'KG' : 'LB',
      })
    }
  }
  fetchFleet = async () => {
    let callback = this.fetchFleetFn
    let pageURL =  'flights'
    let headers = { earch: this.state.search }
    cacheRetrieve(callback, pageURL, headers )
  }

  fetchFleetFn = async(resp) => {
    const respData = await resp.json()
    if(resp?.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let arr = []
        respData?.data?.map((item) => {
          if(item?.details?.ac_type === 'WB') {
            arr.push(
              {
                label: item?.details?.ac_code,
                value: item?.details?.ac_code,
              }
            )
          }
        })
        this.setState({ acTypeList: arr })
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: respData?.message,
        })
      }
      this.fetchULDs()
    }
  }
  fetchULDs = async () => {
    let callback = this.fetchULDsFn
    let pageURL =  'uld_mappings'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchULDsFn = async(resp) => {
    const respData = await resp.json()
    if (respData?.data?.length > 0) {
      let arr = respData?.data?.map((item) => {
        return { ...this.processMappingData(item) }
      })
      this.setState({ data: arr })
    } else {
      this.setState({ data: [] }, () => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: respData?.message,
        })
      })
    }
  }
  processMappingData = (uld) => {
    let weight = uld?.weight?.value || 0
    uld.weight_kg = 0
    uld.weight_lb = 0

    if(uld?.weight?.unit === 'KG') {
      uld.weight_kg = parseFloat(weight * 1)
      uld.weight_lb = weightCalculator(parseFloat(weight), 'KGM')?.toFixed(2)
    } else if(uld?.weight?.unit === 'LB') {
      uld.weight_lb = parseFloat(weight * 1)
      uld.weight_kg = weightCalculator(parseFloat(weight), 'LBS')?.toFixed(2)
    }
    return uld
  }
  addMapping = async () => {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.uld_mapping
    }

    let formdata = {
      weight: {
        unit: '',
        value: ''
      }
    }
    formdata.uld_prefix = this.state.uld_prefix
    formdata.contour = this.state.contour
    formdata.weight.unit = this.state.unit,
    formdata.weight.value = this.state.wgt,
    formdata.uldtype = this.state.type
    formdata.position = this.state.positions
    formdata.ac_types = this.state.selectedACTypeList
    formdata.station = this.state.station || null

    await Http.post('/uld_mapping', formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.setState({
            displayPrefix: '',
            selectedACTypeList: [],
            disabledACList: [],
            acTypeDisabled: false
          }, () => {
            this.clearForm()
            this.clearTableForm()
          })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          this.setState({ duplicate: data?.data?.data }, () => setTimeout(() => { this.setState({ duplicate: null }) }, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
  }
  updateMapping = async () => {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.uld_mapping
    }
    let formdata = {
      weight: {
        unit: '',
        value: ''
      }
    }
    formdata.uld_prefix = this.state.editUld_prefix
    formdata.contour = this.state.editContour
    formdata.weight.unit = this.state.weight_unit,
    formdata.weight.value = this.state.editWgt,
    formdata.uldtype = this.state.editType
    formdata.position = this.state.editPositions
    formdata.ac_types = this.state.selectedACTypeList
    formdata.station = this.state.station || null

    this.setState({ loading: true })
    await Http.put(`/uld_mapping/${this.state.editRecord.id}`, formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.setState({
            displayPrefix: '',
            selectedACTypeList: [],
            disabledACList: [],
            acTypeDisabled: false
          }, () => {
            this.clearForm()
            this.clearTableForm()
          })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          this.setState({ duplicate: data?.data?.data }, () => setTimeout(() => { this.setState({ duplicate: null }) }, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
  }

  deleteMapping = async (ID) => {
    let headers = {
      user_id: this.state.user_id,
      page_title: titles.uld_mapping,
      station: this.state.station,
    }
    let formdata = {}
    formdata.is_delete = true
    this.setState({ loading: true })
    await Http.put(`/uld_mapping/${ID}`, formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.setState({
            displayPrefix: '',
            selectedACTypeList: [],
            disabledACList: [],
            acTypeDisabled: false
          }, () => {
            this.clearForm()
            this.clearTableForm()
          })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          this.setState({ duplicate: data?.data?.data }, () => setTimeout(() => { this.setState({ duplicate: null }) }, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
  }

  editTableForm = (record) => {
    this.setState({
      editRecord: record,
      editUld_prefix: record.uld_prefix,
      editContour: record.contour,
      editWgt: this.state.weight_unit === 'KG' ? record?.weight_kg : record?.weight_lb,
      // editWgt: record.weight,
      editType: record.uldtype,
      editPositions: record.position,
      selectedACTypeList: record?.ac_types,
      // checkDisabled: true,
      // acTypeDisabled: false,
      displayPrefix: record.uld_prefix,
      rows: [],
    })
  }

  clearTableForm = () => {
    this.setState({
      editRecord: null,
      editUld_prefix: '',
      editContour: '',
      editWgt: '',
      editType: '',
      editPositions: '',
      checkDisabled: false,
    })
  }

  clearForm = () => {
    this.setState({
      uld_prefix: '',
      contour: '',
      wgt: '',
      type: '',
      positions: '',
      duplicate: null,
      unit: getCookie(COOKIE.WEIGHT) === 'KG' ? 'KG' : 'LB',
    })
  }
  //UPDATE WEIGHT ON TOGGLE
  updateWeightVal = (unit, value) => {
    if(value > 0) {
      if(unit === 'LB') {
        return weightCalculator(parseFloat(value), 'KGM')
      } else if(unit === 'KG') {
        return weightCalculator(parseFloat(value), 'LBS')
      }
    }
  }
  //CHECK FORM STATE
  onControlsChange = (type) => {
    if(type === 'form') {
      //IF RADIO IS SELECTED CLEAR
      if(this.state.rows?.length > 0 || this.state.editRecord) {
        this.setState({
          selectedACTypeList: [],
          disabledACList: [],
          acTypeDisabled: false,
          rows: [],
        }, () => {
          //IF EDIT IS TRUE CLEAR
          if(this.state.editRecord) {
            this.clearTableForm()
          }
        })
      }
    } else if(type === 'radio') {
      //IF FORM IS FILLED CLEAR
      this.clearForm()
      if(this.state.editRecord) {
        this.clearTableForm()
      }
    } else if(type === 'edit') {
      //IF FORM IS FILLED CLEAR
      this.setState({
        selectedACTypeList: [],
        acTypeDisabled: false,
        rows: [],
      }, () => this.clearForm())
    }
  }

  fetchDisabledPrefixes = async (record = null) => {
    let uld_prefix = !record ? this.state.uld_prefix : record?.uld_prefix
    let id = !record ? '' : record?.id
    await Http.get(`/uld_mappings_actypes?uld_prefix=${uld_prefix}&id=${id}`)
      .then((data) => {
        if (data?.status === 200) {
          this.setState({ disabledACList: data?.data?.data || [] }, () => {
            if(!record) {
              this.onControlsChange('form')
            } else {
              this.onControlsChange('edit')
              this.editTableForm(record)
            }
          })
        }
        this.setState({ loading: false })
      })
  }

  render() {
    const rowSelection = {
      onChange: (_) => this.setState({ rows: _ }),
      getCheckboxProps: () => ({ disabled: this.state.checkDisabled }),
      onSelect: (record) => this.setState({
        selectedACTypeList: record?.ac_types || [],
        displayPrefix: record?.uld_prefix || '',
        acTypeDisabled: true,
      }, () => this.onControlsChange('radio')),
      selectedRowKeys: this.state.rows
    }

    const columns = [
      {
        title: 'ULD Prefix',
        key: 'uld_prefix',
        width: '15%',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editUld_prefix}
              onChange={(e) => this.setState({ editUld_prefix: e.target.value })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.uld_prefix}</span>)
      },
      {
        title: 'Contour',
        key: 'contour',
        width:'150px',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editContour}
              onChange={(e) => this.setState({ editContour: e.target.value })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.contour}</span>)
      },
      {
        title: `Tare Wgt (in ${this.state.weight_unit === 'KG' ? 'Kgs' : 'Lbs'})`,
        key: 'weight',
        width: '15%',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              controls={false}
              size='small'
              placeholder=''
              value={this.state.editWgt || ''}
              onChange={(e) => this.setState({ editWgt: e })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>
              {this.state.weight_unit === 'KG' ? record?.weight_kg : record?.weight_lb}
            </span>
        )
      },
      {
        title: 'Type',
        key: 'uldtype',
        width: '20%',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Select style={{ width: '100%' }} onChange={(e) => this.setState({ editType: e })} value={this.state.editType}>
              <Option value='MD'>MD</Option>
              <Option value='P96'>P96</Option>
              <Option value='P88'>P88</Option>
              <Option value='LD3'>LD3</Option>
              <Option value='LD2'>LD2</Option>
            </Select>
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.uldtype}</span>
        )
      },
      {
        title: 'Nbr Positions',
        key: 'position',
        width: '15%',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editPositions}
              onChange={(e) => this.setState({ editPositions: e.target.value })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.position}</span>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (_, record) =>
          <div>
            {this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={this.updateMapping}><img src={Update} alt='Save' /></Tooltip>
                <Tooltip
                  title='Clear'
                  overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                  onClick={() => this.setState({
                    displayPrefix: '',
                    selectedACTypeList: [],
                    acTypeDisabled: false,
                    disabledACList:[],
                  }, () => {
                    this.clearTableForm()
                    this.clearForm()
                  })}>
                  <span className='form-clear'>x</span>
                </Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip 
                  title='Edit' 
                  overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} 
                  onClick={() => this.fetchDisabledPrefixes(record)}
                  // onClick={() => {
                  //   this.onControlsChange('edit')
                  //   this.editTableForm(record)
                  // }}
                >
                  <img src={editIcon} alt='edit' />
                </Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.deleteMapping(record.id)} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    return (
      <div>
        <Modal
          width={400}
          closable
          destroyOnClose
          footer={null}
          visible={this.state.isModalVisible}
          onCancel={()=>{ this.setState({ previewImg: false }, () => this.setState({ isModalVisible: false })) }}
          className='preview-modal'
        >
          {this.state.previewImg && ( <img className='prev-img' src= {this.state.previewImg} alt={this.state.previewImg} />)}
        </Modal>
        {this.state.loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <Row gutter={[24, 24]} >
          <Col xs={24} sm={24} md={24} lg={14} xl={15} xxl={13} className=''>
            <Card title='ULDs' className='custom-card card-body-pt-0'>
              <Form
                layout='vertical'
              >
                <Row gutter={[{
                  xs: 0,
                  sm: 24,
                  md: 24,
                  lg: 24
                },
                {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: 0
                }]}>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={3} className=''>
                    <Form.Item label='ULD Prefix'>
                      <Input
                        size='small'
                        placeholder=''
                        value={this.state.uld_prefix}
                        onChange={(e) =>  this.setState({
                          uld_prefix: e.target.value?.toUpperCase(),
                          displayPrefix: e.target.value?.toUpperCase()
                        }, () => this.fetchDisabledPrefixes())}
                        // }, () => this.onControlsChange('form'))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={3}>
                    <Form.Item label='Contour'>
                      <Input
                        size='small'
                        placeholder=''
                        value={this.state.contour}
                        onChange={(e) => this.setState({ contour: e.target.value }, () => this.onControlsChange('form'))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
                    <div className='f-spacebtw'>
                      <label className='custom-label'>Tare Wgt</label>
                      <div className='weight-toggle'>
                        <Switch
                          checkedChildren='LB'
                          unCheckedChildren='KG'
                          size='small'
                          className='mx-1'
                          checked={this.state.unit === 'KG' ? false : true}
                          onChange={((e) => this.setState({
                            unit: e === false ? 'KG' : 'LB',
                            wgt: this.updateWeightVal(e === false ? 'KG' : 'LB', this.state.wgt)
                          }, () => this.onControlsChange('form')))}
                        />
                      </div>
                    </div>
                    <div className='p-1'>
                      <InputNumber
                        min={0}
                        precision={0}
                        controls={false}
                        size='small'
                        placeholder=''
                        value={this.state.wgt || ''}
                        onChange={(e) => this.setState({ wgt: e }, () => this.onControlsChange('form'))}
                        className='formitem-mb'
                      />
                    </div>
                  </Col>
                  <Col flex='295px' className='' >
                    <label className='custom-label label-border'>Type</label>
                    <Radio.Group onChange={(e) => this.setState({ type: e.target.value }, () => this.onControlsChange('form'))} value={this.state.type} className='type-rd-wrap formitem-mb'>
                      <Radio value='MD'>MD</Radio>
                      <Radio value='P96'>P96</Radio>
                      <Radio value='P88'>P88</Radio>
                      <Radio value='LD3'>LD3</Radio>
                      <Radio value='LD2'>LD2</Radio>
                    </Radio.Group>
                  </Col>
                  <Col xs={21} sm={12} md={8} lg={8} xl={8} xxl={4}>
                    <Form.Item label='Nbr Positions'>
                      <Input
                        size='small'
                        placeholder=''
                        value={this.state.positions}
                        onChange={(e) => this.setState({ positions: e.target.value }, () => this.onControlsChange('form'))}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex='28px' className='text-right ml-auto pl-0'>
                    <label className='custom-label transparent uld-label'>-</label>
                    <Button type='ghost' className='image-btn' onClick={this.addMapping}>
                      <Tooltip title={this.state.is_edit ? 'Update' : 'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                    </Button>
                  </Col>
                </Row>
                {this.state.is_edit && (
                  <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                    <Button type='ghost' className='ml-auto image-btn clear-btn fleet-btn-placement' onClick={() => this.clearForm()}>
                      <span className='close-icon'>x</span>
                    </Button>
                  </Tooltip>
                )}
              </Form>
              <div className='table-outer'>
                <Table
                  className='custom-table uldmapping-table'
                  dataSource={this.state.data}
                  columns={columns}
                  pagination={false}
                  rowSelection={{
                    type: 'radio',
                    ...rowSelection,
                  }}
                  rowKey='id'
                />
              </div>
              
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={9} xxl={11} >
            <Card title='ULD / Aircraft Compatibility' className='custom-card h-100'>
              <Card 
                bordered={false} 
                title={
                  this.state.displayPrefix ? `${this.state.displayPrefix}:  Select all aircraft types compatible with this ULD Type.` :
                    'Select all aircraft types compatible with this ULD Type.'
                }
                size='default' 
                className='compt-card'
              >
                {/* <Row>
                  <Checkbox.Group
                    className='w-100 checkbox-item-30'
                    options={this.state.acTypeList}
                    value={this.state.selectedACTypeList}
                    onChange={(e) => this.setState({ selectedACTypeList: e })}
                    disabled={this.state.acTypeDisabled}
                  />
                </Row> */}
                <Checkbox.Group
                  className='w-100 checkbox-item-30 ac-type-wrapper'
                  value={this.state.selectedACTypeList}
                  onChange={(e) => this.setState({ selectedACTypeList: e })}
                >
                  {
                    this.state.acTypeList?.map((item, id) => (
                      <Checkbox key={id} disabled={this.state.disabledACList?.includes(item?.value) || this.state.acTypeDisabled} value={item?.value}>{item?.value}</Checkbox>
                    ))
                  }
                </Checkbox.Group>
              </Card>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ULDMapping