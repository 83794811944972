import React, { useState, useEffect } from 'react'
import Report from './tabs/report'
import User from './tabs/user'
// import ConsolidatedReport from './tabs/consolidatedreport'
import './index.css'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Reports = ({ match }) => {

  const [tab, setTab] = useState(false)

  useEffect(() => {
    if (match?.params.tab) {
      setTab('user')
    } else {
      setTab('data')
    }
  }, [])


  const tabHnadler = (t) => {
    setTab(t)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 3,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  return (
    <div>
      <Slider {...settings} className='custom-slider mb-24px'>
        <div className={`tabs ${tab == 'data' ? 'active-tab' : ''}`} onClick={() => tabHnadler('data')}>
          <span>Reports</span>
        </div>

        <div className={`tabs ${tab == 'user' ? 'active-tab' : ''}`} onClick={() => tabHnadler('user')}>
          <span>User Metrics</span>
        </div>

        {/* <div className={`tabs ${tab == 'cons_report' ? 'active-tab' : ''}`} onClick={() => tabHnadler('cons_report')}>
          <span>Consolidated Reports</span>
        </div> */}

      </Slider>

      {tab == 'data' &&
        <Report />
      }

      {tab == 'user' &&
        <User />
      }

      {/* {tab == 'cons_report' &&
        <ConsolidatedReport />
      } */}

    </div>
  )
}
Reports.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default Reports
