import React from 'react'
import { Row, Col, InputNumber, Button, Alert } from 'antd'
import planeTail from '../../../../../assets/images/plane-tail.png'
import planeFront from '../../../../../assets/images/plane-front.png'

const INPUTPROPS = {
  className: 'ac-ip',
  type: 'number',
  size: 'small',  
  bordered: false,
  // min: 1,
  // max: 10,
  controls: false,
}

const AcType763 = (props) => {
  const INPUTDISABLED = props.ccPlanModalVisible

  return (
    <div className='parent-div-763'>
      <div className='ac-alert c763'>{props?.optimal &&(<Alert type='success' showIcon message='Optimal Configuration' ></Alert>)}{!props?.optimal &&(<Alert type='error' showIcon message= 'Suboptimal Configuration: The use of LD3s causes unuseable space on a 767'></Alert>)}</div>
      <Row gutter={[0]} >
        <div className='top-row-763'>
          <Col>
            <div className='empty-box'></div>
          </Col>
          <Col flex='100px 'className='f-middle'>
            <Button className='clear-bins h-100' onClick={props.clearBin}>
              Clear bins
            </Button>  
          </Col>

          <Col className={INPUTDISABLED || props.position?.cargo?.l25 || props.position?.cargo?.r25 || props.position?.cargo?.r26 || props.position?.cargo?.l26? 'disable-col' : '' }>
            <div className='boxes764 bl-1'>
              <span className='box-text'>23P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p23}
                  disabled={INPUTDISABLED || props.position?.cargo?.r25 || props.position?.cargo?.l25 || props.position?.cargo?.r26 || props.position?.cargo?.l26}
                  onChange={(e) => props.onChange(e, 'p23', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r23 || props.position?.cargo?.l23 || props.position?.cargo?.l24 || props.position?.cargo?.r24? 'disable-col' : '' }>
            <div className='boxes764'>
              <span className='box-text'>22P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p22}
                  disabled={INPUTDISABLED || props.position?.cargo?.r23 || props.position?.cargo?.l23 || props.position?.cargo?.l24 || props.position?.cargo?.r24}
                  onChange={(e) => props.onChange(e, 'p22', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r22 || props.position?.cargo?.l22 || props.position?.cargo?.r21 || props.position?.cargo?.l21 ? 'disable-col' : '' } >
            <div className='boxes764'>
              <span className='box-text'>21P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p21}
                  disabled={INPUTDISABLED || props.position?.cargo?.r22 || props.position?.cargo?.l22 || props.position?.cargo?.r21 || props.position?.cargo?.l21 }
                  onChange={(e) => props.onChange(e, 'p21', 'pallet')}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.cargo?.r11 || props.position?.cargo?.l11 || props.position?.cargo?.l12 || props.position?.cargo?.r12 ? 'disable-col' : '' }>
            <div className='boxes764'>
              <span className='box-text'>11P</span>
              <div className='box-line'>
                <InputNumber  
                  {...INPUTPROPS}
                  value={props.position?.pallet?.p11}
                  // className={`${INPUTPROPS.className} ${(props.position?.cargo?.r11 || props.position?.cargo?.l11 || props.position?.cargo?.l12 || props.position?.cargo?.r12 || props.position?.pallet?.p11) >= 7900 ? 'red-bkg' : '' }`} 
                  disabled={INPUTDISABLED || props.position?.cargo?.r11 || props.position?.cargo?.l11 || props.position?.cargo?.l12 || props.position?.cargo?.r12}
                  onChange={(e) => props.onChange(e, 'p11', 'pallet')}
                />
              </div>
            </div>
          </Col>
        </div>
        
      </Row> 
     
      <Row gutter={[0]}>
        <div className='middle-row'>
          <Col>
            <img className= 'plane-back' src={planeTail} />
          </Col>
          <Col className={INPUTDISABLED ? 'disable-col' : '' }>
            <div className='boxes1'>
              <span className='box-text'>Bulk</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={ props.position?.bulk} 
                  onChange={(e) => props.onChange(e, null, 'bulk')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
          </Col> 
          <Col className={INPUTDISABLED ? 'disable-col' : '' }>
            <div className='top-box'>
              <span className='box-text'>41L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l41}
                  // className={`${INPUTPROPS.className} ${(props.position?.cargo?.l41 || props.position?.cargo?.r41) > 2700 ? 'red-bkg' : '' }`} 
                  onChange={(e) => props.onChange(e, 'l41', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>41R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r41}
                  // className={`${INPUTPROPS.className} ${(props.position?.cargo?.l41 || props.position?.cargo?.r41) > 2700 ? 'red-bkg' : '' }`} 
                  onChange={(e) => props.onChange(e, 'r41', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED ? 'disable-col' : '' }>
            <div className='top-box'>
              <span className='box-text'>35L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l35} 
                  onChange={(e) => props.onChange(e, 'l35', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>35R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r35} 
                  onChange={(e) => props.onChange(e, 'r35', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED ? 'disable-col' : '' }>
            <div className='top-box'>
              <span className='box-text'>34L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l34} 
                  onChange={(e) => props.onChange(e, 'l34', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>34R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r34} 
                  onChange={(e) => props.onChange(e, 'r34', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED ? 'disable-col' : '' }>
            <div className='top-box'>
              <span className='box-text'>33L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l33}
                  onChange={(e) => props.onChange(e, 'l33', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>33R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r33} 
                  onChange={(e) => props.onChange(e, 'r33', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED ? 'disable-col' : '' }>
            <div className='top-box'>
              <span className='box-text'>32L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l32} 
                  onChange={(e) => props.onChange(e, 'l32', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>32R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r32} 
                  onChange={(e) => props.onChange(e, 'r32', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED ? 'disable-col' : '' }>
            <div className='top-box'>
              <span className='box-text'>31L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l31} 
                  onChange={(e) => props.onChange(e, 'l31', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>31R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r31} 
                  onChange={(e) => props.onChange(e, 'r31', 'cargo')}
                  disabled={INPUTDISABLED}
                />
              </div>
            </div>
          </Col>
          <Col >
            <div className='boxes2'>
            </div>
          </Col> 
          <Col className={INPUTDISABLED || props.position?.pallet?.p23 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>26L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l26}
                  onChange={(e) => props.onChange(e, 'l26', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23 } 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>26R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r26} 
                  onChange={(e) => props.onChange(e, 'r26', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p23 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>25L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l25} 
                  onChange={(e) => props.onChange(e, 'l25', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>25R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r25} 
                  onChange={(e) => props.onChange(e, 'r25', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p23} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p22 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>24L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l24} 
                  onChange={(e) => props.onChange(e, 'l24', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>24R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r24} 
                  onChange={(e) => props.onChange(e, 'r24', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p22 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>23L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l23} 
                  onChange={(e) => props.onChange(e, 'l23', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>23R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r23} 
                  onChange={(e) => props.onChange(e, 'r23', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p22} 
                />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p21 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>22L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l22} 
                  onChange={(e) => props.onChange(e, 'l22', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>22R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r22} 
                  onChange={(e) => props.onChange(e, 'r22', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>           
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p21 ? 'disable-col' : ''} >
            <div className='top-box'>
              <span className='box-text'>21L</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.l21} 
                  onChange={(e) => props.onChange(e, 'l21', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>         
            </div>
            <div className='bottom-box'>
              <span className='box-text'>21R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r21} 
                  onChange={(e) => props.onChange(e, 'r21', 'cargo')}
                  disabled={INPUTDISABLED || props.position?.pallet?.p21} 
                />
              </div>         
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p11 ? 'disable-col' : ''} >
            <div className='top-box'>
              <span className='box-text'>12L</span>
              <div className='box-line-bottom'>
                <InputNumber {...INPUTPROPS}
                  value={props.position?.cargo?.l12} 
                  onChange={(e) => props.onChange(e, 'l12', 'cargo')}
                  // className={`${INPUTPROPS.className} ${(props.position?.cargo?.r11 || props.position?.cargo?.l11 || props.position?.cargo?.l12 || props.position?.cargo?.r12 || props.position?.pallet?.p11) >= 7900 ? 'red-bkg' : '' }`} 
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>
            </div>
            <div className='bottom-box'>
              <span className='box-text'>12R</span>
              <div className='box-line-bottom'>
                <InputNumber 
                  {...INPUTPROPS}
                  value={props.position?.cargo?.r12}
                  onChange={(e) => props.onChange(e, 'r12', 'cargo')}
                  // className={`${INPUTPROPS.className} ${(props.position?.cargo?.r11 || props.position?.cargo?.l11 || props.position?.cargo?.l12 || props.position?.cargo?.r12 || props.position?.pallet?.p11) >= 7900 ? 'red-bkg' : '' }`} 
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>
            </div>
          </Col>
          <Col className={INPUTDISABLED || props.position?.pallet?.p11 ? 'disable-col' : ''}>
            <div className='top-box'>
              <span className='box-text'>11L</span>
              <div className='box-line-bottom'>
                <InputNumber {...INPUTPROPS}
                  value={props.position?.cargo?.l11}
                  onChange={(e) => props.onChange(e, 'l11', 'cargo')}
                  // className={`${INPUTPROPS.className} ${(props.position?.cargo?.r11 || props.position?.cargo?.l11 || props.position?.cargo?.l12 || props.position?.cargo?.r12 || props.position?.pallet?.p11) >= 7900 ? 'red-bkg' : '' }`} 
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>              
            </div>
            <div className='bottom-box'>
              <span className='box-text'>11R</span>
              <div className='box-line-bottom'>
                <InputNumber {...INPUTPROPS}
                  value={props.position?.cargo?.r11}
                  onChange={(e) => props.onChange(e, 'r11', 'cargo')}
                  // className={`${INPUTPROPS.className} ${(props.position?.cargo?.r11  || props.position?.cargo?.l11 || props.position?.cargo?.l12  || props.position?.cargo?.r12  || props.position?.pallet?.p11) >= 7900 ? 'red-bkg' : '' }`} 
                  disabled={INPUTDISABLED || props.position?.pallet?.p11} />
              </div>
            </div>
          </Col> 
          <Col>
            <img className='plane-front' src={planeFront} />
          </Col>
        </div>
      </Row>
    </div>
  )
}
export default AcType763
