import axios from 'axios'
import { COOKIE, deleteCookie } from '../utils/cookie'

const URL = process.env.REACT_APP_API_URL
const ApiUrl = URL

function errorResponseHandler(error) {

  if (error?.response?.status === 401 && (error?.response?.config?.url !== '/otp_verify')) {
    deleteCookie(COOKIE.OTP)
    deleteCookie(COOKIE.STATION)
    window.location.href = '/login'
  }

  // check for errorHandle config
  // eslint-disable-next-line no-prototype-builtins
  if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
    return Promise.reject(error)
  }

  // if has response show the error
  if (error.response) {
    return (error.response)
  }
}

const http = axios.create({
  baseURL: ApiUrl,
  withCredentials: true
})

// apply interceptor on response
http.interceptors.response.use(
  response => response,
  errorResponseHandler
)

export default http