/* eslint-disable no-console */
import React, { Component, Fragment } from 'react'
import {
  Row, Col, Typography, Form, Card, Table, Modal, Select, Tooltip, Input, InputNumber, Button, Checkbox, Radio, DatePicker, TimePicker, Space, notification, Statistic, Spin, Badge, Popconfirm, Switch, Popover, Upload, /*Divider, message*/
  Tag
} from 'antd'
import {
  SearchOutlined, CloseCircleFilled, /*SaveOutlined, PlusOutlined,*/ CheckOutlined, CloseOutlined, CheckCircleFilled,  CheckCircleTwoTone, InfoCircleFilled, MehOutlined, SmileOutlined, FrownOutlined, CustomerServiceOutlined, ExclamationCircleOutlined, /*PaperClipOutlined*/ 
  InfoCircleOutlined, LoadingOutlined, CaretRightOutlined, CaretDownOutlined
} from '@ant-design/icons'
import download from '../../assets/images/download.svg'
import addIcon from '../../assets/images/add.svg'
import editIcon from '../../assets/images/edit.svg'
import deleteIcon from '../../assets/images/delete.svg'
import Update from '../../assets/images/input-tick.svg'
import documentIcon from '../../assets/images/document.svg'
import planeDarkIcon from '../../assets/images/plane-icon-dark.svg'
import planeLightIcon from '../../assets/images/plane-icon-light.svg'
import ULDicon from '../../assets/images/uld_icon.png'
import cssconstants from '../../constants/cssconstants'
import Http from '../../utils/http'
import Notes from '../notes/notes'
import Cookies from 'universal-cookie'
// import AftHold from '../../assets/images/conf-1.png'
// import ForwardHold from '../../assets/images/conf-2.png'
import { cloneDeep } from 'lodash'
import moment, { isMoment } from 'moment'
import { constants, timezone, cut_codes, audit_status, ldd_status, ch_wt_goals, /*station as org_stations*/ } from '../../constants/constants'
import { weightCalculator, formatWeight, cacheRetrieve } from '../../utils/common'
import './index.css'
import BuildPlan from './tabs/checklist/buildplan'
import HeaderSearch from '../../pages/search'
import WeightAndBalance from './tabs/checklist/weight-balance-planner'
import { pageHandler, getCookie, COOKIE } from '../../utils/cookie'
const cookies = new Cookies()

const { Title, Text } = Typography
const { Option } = Select
const { Countdown } = Statistic
const dateTimeFormat = 'DDMMMYY HH:mm'
const dateOnly = 'DDMMMYY'
const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm:ss'
const completeDateTime = 'YYYY-MM-DD HH:mm:ss'
const { Column, ColumnGroup } = Table
const country = ['USA', 'U.S. VIRGIN ISLANDS', 'PUERTO RICO']
const legendPopover = (
  <div className='width-250 fs-10'>
    <Row gutter={[8, 8]}>
      <Col span={7}>AS</Col>
      <Col span={17}>Cargo checked in at station</Col>

      <Col span={7}><img src={planeDarkIcon} width='12' height='12' alt='plane' /></Col>
      <Col span={17}>Cargo enroute to station</Col>

      <Col span={7}><Text type='success'>C</Text></Col>
      <Col span={17}>Cargo at station built up, ULD closed</Col>

      <Col span={7}><Text type='danger'>O</Text></Col>
      <Col span={17}>Cargo at station built up, ULD open</Col>

      <Col span={7}>1 | 111 | 1</Col>
      <Col span={17}>AWBS | WEIGHT | VOLUME</Col>
    </Row>
  </div>
)
const flightno_pattern = /^DL\d{3,4}[A-Za-z]?$/
const users = ['diksha@orgadynamics.com', 'noel@orgadynamics.com', 'craig@orgadynamics.com', 'jessio@orgadynamics.com', 'narayan@orgadynamics.com']
const emailregex = '^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*.[a-z]{2,})(,([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*.[a-z]{2,}))*$'
const const_ulds = [ 'PMC', 'PAG', 'LD2', 'LD3', 'LD4', 'LD8', 'AKE', 'PLA', 'PLB', 'AKN', 'AKX', 'AKY', 'DPA', 'DPE', 'DPL', 'DPN', 'DQL', 'DQP', 'DQF', 'FQA']

let ENV = process.env.REACT_APP_ENV

//FORMAT FLIGHT NO
function formatFlightNo(flight_no, flight_date) {
  let formatted = ''
  formatted = flight_no + '/' + moment(flight_date).format(dateOnly)
  return formatted
}

//FORMAT AWB NO
function formatAWBNo(awb) {
  let result = ''
  if (awb) {
    let b = '-'
    let position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}

// const dummy = [
//   {
//     location: '-',
//     awb: '006-12345678', 
//     pcs: 10,
//     grwt: 1000,
//     chwt: 1000,
//     vol: 14,
//     pri: 2,
//     dest: 'LAX',
//     prod: 'HUM',
//     spl: 'HUM', 
//     preBuilt: 'Y',
//     onHand: 'Y'
//   }
// ]

const uld_colors = [
  {
    name: 'blue',
    code: '#0000FF'
  },
  {
    name: 'purple',
    code: '#800080'
  },
  {
    name: 'cyan',
    code: '#00FFFF'
  },
  {
    name: 'magenta',
    code: '#FF00FF'
  },
  {
    name: 'red',
    code: '#FF0000'
  },
  {
    name: 'green',
    code: '#00FF00'
  },
  {
    name: 'yellow',
    code: '#FFFF00'
  },
  {
    name: 'orange',
    code: '#FFA500'
  },
  {
    name: 'pink',
    code: '#FFC0CB'
  },
  {
    name: 'brown',
    code: '#A52A2A'
  },
  {
    name: 'indigo',
    code: '#4B0082'
  },
  {
    name: 'teal',
    code: '#008080'
  },
  {
    name: 'lavender',
    code: '#E6E6FA'
  },
  {
    name: 'gold',
    code: '#FFD700'
  },
  {
    name: 'silver',
    code: '#C0C0C0'
  },
  {
    name: 'gray',
    code: '#808080'
  },
  {
    name: 'maroon',
    code: '#800000'
  },
  {
    name: 'olive',
    code: '#808000'
  },
  {
    name: 'turquoise',
    code: '#40E0D0'
  },
  {
    name: 'slate blue',
    code: '#6A5ACD'
  }
]

class FlightControl extends Component {
  constructor() {
    super()
    this.modalRef = React.createRef()
    this.state = {
      servererror: false,
      acChange: false,
      loading: false,
      modalLoading: false,
      loadplanvisible: false,
      station: new Cookies().get('station'),
      user_id: new Cookies().get('id'),
      timezone: new Cookies().get('timezone'),
      ext: timezone.find((item) => item.value === new Cookies().get('station'))?.ext || 'EST',
      start: moment().tz(new Cookies().get('timezone')).format(dateFormat),
      end: moment().tz(new Cookies().get('timezone')).add(12, 'hours').format(dateFormat),
      stime: moment().tz(new Cookies().get('timezone')).set({
        minutes: 0,
        second: 0,
      }),
      etime: moment().tz(new Cookies().get('timezone')).add(12, 'hours').set({
        minutes: 0,
        second: 0,
      }),
      start_checklist: moment().tz(new Cookies().get('timezone')).format(dateFormat),
      stime_checklist: moment().tz(new Cookies().get('timezone')).set({
        minutes: 0,
        second: 0,
      }),
      end_checklist: moment().tz(new Cookies().get('timezone')).add(12, 'hours').format(dateFormat),
      etime_checklist: moment().tz(new Cookies().get('timezone')).add(12, 'hours').set({
        minutes: 0,
        second: 0,
      }),
      page: 1,
      weight_unit: 'KG',
      cookieInterval: null,
      search: '',
      milestoneData: [],
      milestoneFBData: [],
      allQuestionsData: [],
      questionsData: [],
      tempquestionsData: [],
      openQuizModal: false,
      quizMilestonid: null,
      checklistTitle: '',
      quizModalFlight: null,
      milestonButton: 'Submit',
      hideCompleted: false,

      feedback: false,
      feedbackType: null,
      flightData: [],
      displayData: [],
      fleetData: [],
      userList: [],
      defaultSystemParameters: [],
      uldMappingData: [],
      stationsData: [],
      destStations: [],
      destFilters: [],
      sort: 'dept_time',
      dodsort: 'dept_time',
      departedFlightsCheck: false,
      amberFlightsCheck: false,
      redFlightsCheck: false,
      searchString: '',
      noteType: null,
      flightNotes: false,
      optimalBuild: false,
      showAlloc: false,
      notesTitle: '',
      isListening: false,
      searchFlightNo: '',
      latestTimestamp: '',
      searchDate: '',
      ccView: false,
      showSuccess: false,

      editRecord: null,
      editAllocRecord: null,
      deleteAllocID: null,
      mergeEditRecord: null,
      editRecordId: null,

      editFcst: 0,
      editBagP96: 0,
      editBagP88: 0,
      editBagLD3: 0,
      editBagLD2: 0,
      editCargoMD: 0,
      editCargoP96: 0,
      editCargoP88: 0,
      editCargoLD3: 0,
      editCargoLD2: 0,
      editWgtValue: 0,
      editWgtUnit: '',
      editBuPrio: false,
      editAllocArray: [],
      editIsExceeded: false,
      previewAft: null,
      previewFwd: null,
      lastUpdatedAt: '',
      lastUpdatedBy: '',

      editPos: '',
      editType: '',
      editWorkArea: '',
      editULDAWB: '',
      editProd: '',
      editComm1: '',
      editComm2: '',

      editTPos: '',
      editTType: '',
      editTWorkArea: '',
      editTULDAWB: '',
      editTProd: '',
      editTComm1: '',
      editTComm2: '',

      remainingP96: 0,
      remainingP88: 0,
      remainingLD3: 0,
      remainingLD2: 0,
      remainingMD: 0,

      SLUSummary: [],
      PTUSummary: [],
      BuiltSummary: [],
      BLKSummary: null,
      selected: [],
      bagSummary: [],
      bagSelected: null,
      isWideBody: false,
      hideMD: true,
      hints: '',
      notes: '',
      selectedRow: '',
      checkListOpen: false,
      checkListTitle: '',
      radioValue: 2,
      showBP: false,
      openPlanner: false,
      addULD: false,
      assignUld: {},
      assignedUldsData: [],
      flightDetails: null,
      editUlds: {},
      editAwbs: {},
      editAwbsRecord: null,
      bpIndex: null,

      openNoRideModal: false,
      noRideModalType: null,
      noRideTitle: null,
      norideData: [],
      noride_container_id: null,
      noride_type: null,
      noride_cutcode: null,
      cut_reasons: [],
      noride_cutreason: null,
      noride_cutby: null,
      noride_comment: null,
      noride_flight: null,
      noride_ldd: null,
      noride_ala: null,
      noride_attach_conf: false,
      noride_fileList: [],
      isModalVisible: false,

      edit_noride_id: null,
      edit_noride_container_id: null,
      edit_noride_type: null,
      edit_noride_cutcode: null,
      edit_cut_reasons: [],
      edit_noride_cutreason: null,
      edit_noride_cutby: null,
      edit_noride_comment: null,
      edit_noride_flight: null,
      edit_noride_attach_conf: false,
      edit_noride_fileList: [],

      openLDDModal: false,
      pallets_paired: null,
      pallets_paired_contact: null,
      pallets_paired_explain: null,
      conveyances_staged: null,
      conveyances_staged_contact: null,
      conveyances_staged_explain: null,


      addedULD: [],
      optiUld: {},
      editOptiUld: {},
      chblWgtGoal: null,
      optiLoader: false,
      clearLoader: false,
      switch: true,

      //audit form
      attach_conf: false,
      fileList: [],
      bin_config: null,
      audit_built_right: null,
      audit_reason: null,
      audit_explain: null,
      audit_was_optimal: null,
      audit_meet_wgt_goal: null,

      load_trac_conf: false,
      load_trac_conf_fileList: [],
      build_plan_conf: false,
      build_plan_conf_fileList: [],
      wandb_conf: false,
      wandb_conf_fileList: [],
      load_trac_conf_previewImg: false,
      build_plan_conf_previewImg: false,
      wandb_conf_previewImg: false,

      //feedback form
      fb_req_name: cookies.get('name'),
      fb_req_email: cookies.get('username').match(emailregex) ? cookies.get('username') : null,
      fb_type: null,
      fb_urgency: null,
      fb_milestone_arr: [],
      fb_milestone: false,
      fb_wgtnbal: false,
      fb_buildplan: false,
      fb_description: null,
      fb_rec_solution: null,

      //add flight form
      edit_flight_id: null,
      flightModalType: 'ADD',
      showFlightModal: false,
      flight_no: null,
      flight_date: null,
      dept_time: moment().startOf('day'),
      arr_time: moment().startOf('day'),
      org_stn: cookies.get('station'),
      dest_stn: null,
      aircrafttype: null,
      ack1: false,
      ack2: false,

      flightAuditImg: false,
      previewImgNoRide: false
    }
    this._queue = []
    this._isBusy = false
  }

  componentDidMount() {

    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    // const system_params_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    const flight_control_events = new EventSource(process.env.REACT_APP_API_URL + `events?pgtype=fc&stn=${this.state.station}`)

    flight_control_events.onmessage = (event) => {
      // console.log(event.data, 'event.data')
      const parsedData = JSON.parse(event.data)
      if (!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        this.enqueue(myFakeAsyncJob)
      }
    }
    flight_control_events.onerror = (error) => {
      if (error?.type === 'error') {
        this.setState({ servererror: true })
      }
    }

    // system_params_events.onmessage = (event) => {
    //   const parsedData = JSON.parse(event.data)
    //   if(!this.state.isListening) this.setState({ isListening: true })
    //   else {
    //     const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
    //     this.enqueue(myFakeAsyncJob)
    //   }
    // }
    // system_params_events.onerror = (error) => {
    //   if(error?.type === 'error') {
    //     this.setState({ servererror : true })
    //   }
    // }

    this.setState({ cookieInterval: setInterval(this.checkInterval, 1000) })
    // // radioValue == 1
    // this.fetchFleet()
    // radioValue == 2
    this.fetchAllQuestions()
    this.fetchMilestones()
    this.fetchStations()
  }

  componentWillUnmount() {
    this.setState({ cookieInterval: clearInterval(this.state.cookieInterval) })
  }

  handleToggle = () => {
    if (this.state.radioValue === 1) {
      this.fetchFleet()
    } else if (this.state.radioValue === 2) {
      this.fetchMilestones()
      this.fetchStations()
    }
  }
  openWB = (value) => {
    this.setState({ openPlanner: value })
  }

  opti = () => {
    this.setState({ optiLoader: true })
    Http.get(`/build_opti/${this.state.editRecord?.id}?priority=${this.state.priority || false}`)
      .then(() => {
        setTimeout(() => {
          this.setState({ optiLoader: false })
        }, 4000)
      })
  }
  opti_clear = () => {
    this.setState({ clearLoader: true })
    Http.get(`/build_opti_clear/${this.state.editRecord?.id}?stn=${cookies.get('station')}`)
      .then(() => {
        setTimeout(() => {
          this.setState({ clearLoader: false })
        }, 4000)
      })
  }
  
  showAwbs = (record, awbs) => {
    console.log(record,'record', awbs)
    let a = []
    if (record?.type == 'SLU') {
      record?.awbs_in?.map( awb => {
        let act = this.state.mergeEditRecord?.find(a1=> awb.awb_no == a1.awb_no)
        console.log(act, 'act', awb.awb_no, 'awb_no' )
        a.push({
          ...act || {},
          vol:{ value: awb?.vol?.value, },
          wgt: awb?.wgt?.value,
          pcs: awb?.pcs,
          ch_wgt: (act?.wgt?.value / act?.pcs) * awb?.pcs
        })
      })
    } else 
    {
      if(awbs?.length > 0 ) {
        awbs.map((awb) => {
        // console.log(awb.data, 'data')
          if(record.type =='BULK')
          {
            if(awb?.data && awb?.data?.blk && parseInt(awb?.data?.blk) > 0)
            {
              let act = this.state.mergeEditRecord?.find(a1=> awb.awb_no == a1.awb_no)
              let weight = (act.wgt.value/act.pcs)*awb.data.blk
              // console.log(act, 'act', awb, 'awb')
              a.push({
                ...act,
                t_pcs: act.pcs,
                pcs: awb.data.blk,
                wgt: weight,
                ch_wgt: awb.data.ch_wgt 
              })
            }
          }else
          {
            const data = awb?.ulds.find((u) => record.id == u.id)
            if (data) {
              let act = this.state.mergeEditRecord?.find(a1=> awb.awb_no == a1.awb_no)
              a.push({
                ...act,
                t_pcs: act.pcs,
                pcs: data.pcs ,
                t_wgt: act.wgt,
                wgt: data.wgt,
                ch_wgt: data.ch_wgt
              })
            }
          }
        })
      } 
    }
    return a
  }

  eventList = (parsedData) => {
    // console.log(parsedData, 'parsedData')
    if (parsedData?.TYPE === 'FLIGHT_CONTROL' && parsedData?.msg === 'UPDATE' && parsedData?.Action === 'UPDATE') {
      this.updateFlightDetails(parsedData?.DETAILS?.data, 'DETAILS')
    } else if (parsedData?.TYPE === 'FLIGHT_CONTROL' && parsedData?.msg === 'PLAN' && parsedData?.Action === 'UPDATE') {
      this.updateFlightDetails(parsedData?.DETAILS?.data, 'PLAN')
    } else if (parsedData?.TYPE === 'FLIGHT_CONTROL' && parsedData?.msg === 'wgt_bal_plan' && parsedData?.Action === 'UPDATE') {
      this.updateFlightDetails(parsedData?.DETAILS?.data, 'wgt_bal_plan')
    } else if (parsedData?.TYPE === 'FLIGHT_CONTROL' && (parsedData?.msg === 'BUILD_ULD' || parsedData?.msg === 'CREATE_ULD') && (parsedData?.Action === 'ADD' || parsedData?.Action === 'UPDATE' || parsedData?.Action === 'REMOVE')) {
      this.updateFlightDetails(parsedData?.DETAILS?.data, parsedData?.msg)
    } else if (parsedData?.TYPE === 'FLIGHT_CONTROL' && parsedData?.msg === 'BUILD_AWB' && (parsedData?.Action === 'UPDATE' || parsedData?.Action === 'ADD')) {
      this.updateFlightDetails(parsedData?.DETAILS?.data, 'BUILD_AWB')
    } else if (parsedData?.TYPE === 'NOTES') {
      this.updateFlightNotesCount(parsedData?.DETAILS)
    } else if (parsedData?.TYPE === 'FLIGHT_EVENT') {
      this.updateFlight(parsedData?.DETAILS, 'DEPARTURE')
    } else if (parsedData?.TYPE === 'FSU' && parsedData?.msg === 'MAN') {
      this.updateFlight(parsedData?.DETAILS?.data, 'BUILDUP')
    } else if (parsedData?.TYPE === 'FSU' && parsedData?.msg === 'GATE') {
      this.updateFlight(parsedData?.DETAILS?.data, 'GATE')
    } else if (parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'UPDATE') {
      this.updateFlight(parsedData?.DETAILS, 'BOOKING')
    } else if (parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'ADD') {
      this.addAWB_segment(parsedData?.DETAILS)
    } else if (parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'REMOVE') {
      this.removeAWB_segment(parsedData?.DETAILS)
    } else if (parsedData?.TYPE === 'FLEET') {
      this.fetchFleet('EVENT')
    } else if (parsedData?.TYPE === 'STATIONS') {
      this.fetchStations('EVENT')
    } else if (parsedData?.TYPE === 'DEFAULT_HANDLING_TIMES') {
      this.fetchDefaultSystemParameters('EVENT')
    } else if (parsedData?.TYPE === 'GLOBAL_DETAILS') {
      this.fetchGlobalDetails('EVENT')
    } else if (parsedData?.TYPE === 'NORIDE') {
      this.handleNoRideEvent(parsedData?.Action, parsedData?.DETAILS)
    } else if (parsedData?.TYPE === 'FLIGHTNORIDE') {
      this.updateFlight(parsedData?.DETAILS, 'FLIGHTNORIDE')
    } else if (parsedData?.TYPE === 'FLIGHTAUDIT') {
      this.updateFlight(parsedData?.DETAILS, 'FLIGHTAUDIT')
    } else if (parsedData?.TYPE === 'FLIGHTLDD') {
      this.updateFlight(parsedData?.DETAILS, 'FLIGHTLDD')
    } else if (parsedData?.TYPE === 'ANSWER') {
      this.fetchQuestions(parsedData?.DETAILS?.flight_id, parsedData?.DETAILS?.milestones_id, true)
    } else if (parsedData?.TYPE === 'MILESTONES') {
      this.fetchMilestones('EVENT')
    } else if (parsedData?.TYPE === 'FLIGHT') {
      this.fetchFlightsChecklist(null, parsedData?.DETAILS?.data?.flight_id)
    }
    // else if(parsedData?.TYPE === 'GLOBAL_DETAILS') {
    //   this.fetchGlobalDetails('EVENT')
    // }
  }

  /*************************************************************** */

  enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      this._queue.push({
        job,
        resolve,
        reject
      })
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    // if the array is empty shift() will return undefined
    if (next) {
      this._isBusy = true
      next
        .job()
        .then((value) => {
          this.eventList(value)
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 600)
      })
  }

  /*************************************************************** */

  checkInterval = () => {
    let newCookie = new Cookies().get('weight')
    if (newCookie !== this.state.weight_unit) {
      this.setState({ weight_unit: newCookie })
    }
  }
  //PROCESS FLIGHT DATA
  processData = () => {
    let flight_array = this.state.flightData?.map((flight) => {
      return { ...this.processIndividualFlight(flight) }
    })
    this.sort(flight_array, false)
  }

  //FETCH FLEET
  fetchFleet = async () => {
    let callback = this.fetchFleetFn
    let pageURL = 'flights'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers)
  }
  fetchFleetFn = async (resp, event) => {
    if (!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (respData && respData?.data?.length > 0) {
      let fleetData = respData?.data.filter((item) => item?.details?.ac_type === 'WB')
      this.setState({ fleetData: fleetData })
    } else {
      this.setState({ fleetData: [] })
    }

    if (!event && this.state.radioValue !== 2) { this.fetchULDMappingData() }
    else if (!event && this.state.radioValue === 2) { this.fetchFlightsChecklist() }
    else {
      this.processData()
    }
  }
  //FETCH ULD MAPPING DATA
  fetchULDMappingData = async () => {
    let callback = this.fetchULDMappingDataFn
    let pageURL = 'uld_mappings'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers)
  }
  fetchULDMappingDataFn = async (resp, event) => {
    const respData = await resp.json()
    if (resp?.status === 200) {
      if (respData?.data?.length > 0) {
        this.setState({ uldMappingData: respData?.data })
      }
      if (!event) this.fetchDefaultSystemParameters()
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData?.message ? respData?.message : constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH DEFAULT HANDLING TIMES
  fetchDefaultSystemParameters = async (event) => {
    let callback = this.fetchDefaultSystemParametersFn
    let pageURL = `handling_times?default=true&station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers, event)
  }

  fetchDefaultSystemParametersFn = async (resp, event) => {
    const respData = await resp.json()
    if (resp?.status === 200) {
      if (respData?.data?.length > 0) {
        this.setState({ defaultSystemParameters: respData?.data })
      } else {
        this.setState({ defaultSystemParameters: [] })
      }
      if (!event && this.state.radioValue !== 2) { this.fetchStations() }
      else if (!event && this.state.radioValue === 2) { this.fetchFleet() }
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }

  //FETCH STATIONS
  fetchStations = async () => {
    let callback = this.fetchStationsFn
    let pageURL = 'stations'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers)
  }
  fetchStationsFn = async (resp, event) => {
    const respData = await resp.json()
    if (resp?.status === 200) {
      if (respData?.data?.length > 0) {
        this.setState({ stationsData: respData?.data })
      } else {
        this.setState({ stationsData: [] })
      }
      if (!event) this.fetchUsers()
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  fetchUsers = async () => {
    let callback = this.fetchUsersFn
    let pageURL = 'users?sa=true'
    let headers = { station: this.state.station }
    cacheRetrieve(callback, pageURL, headers)
  }
  fetchUsersFn = async (resp, event) => {
    const respData = await resp.json()
    if (resp?.status === 200) {
      if (respData?.data?.length > 0) {
        this.setState({ userList: respData?.data })
      } else {
        this.setState({ userList: [] }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: respData?.message,
          })
        })
      }
      if (!event) this.fetchGlobalDetails()
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }

  //FETCH GLOBAL DETAILS
  fetchGlobalDetails = async () => {
    let callback = this.fetchGlobalDetailsFn
    let pageURL = 'global_details'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers)
  }
  fetchGlobalDetailsFn = async (resp, event) => {
    const respData = await resp.json()
    if (resp?.status === 200) {
      if (respData?.data?.length > 0) {
        let filtered = respData?.data?.find((item) => item?.details?.stations?.includes(this.state.station)) || null
        if (filtered) {
          this.setState({ hideMD: filtered?.show_md ? false : true })
        } else {
          this.setState({ hideMD: false })
        }
        if (!event && this.state.radioValue !== 2) this.fetchFlights()
      } else {
        this.setState({ loading: false })
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData?.message ? respData?.message : constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ loading: false })
    }
  }
  //UPDATE FLIGHT
  updateFlight = (DETAILS, TYPE) => {
    let ID = parseInt(DETAILS?.flight_id)
    let type = 'null'
    if (this.state.flightData && this.state.flightData.length > 0) {
      type = 'flight'
    } else {
      type = 'checklist'
    }
    let DATA = cloneDeep(this.state.flightData)
    let flight_index = DATA?.findIndex((p) => p.id === ID)
    if (flight_index > -1) {
      if (TYPE === 'DEPARTURE') {
        let dept_obj = {}
        dept_obj.act = DETAILS?.flight?.dept?.act ? parseInt(DETAILS.flight.dept.act) : null
        dept_obj.eta = DETAILS?.flight?.dept?.eta ? parseInt(DETAILS.flight.dept.eta) : null
        dept_obj.schdl = DETAILS?.flight?.dept?.schdl ? parseInt(DETAILS.flight.dept.schdl) : null
        DATA[flight_index].dept_date = DETAILS?.flight?.dept_date ? DETAILS?.flight?.dept_date : DATA[flight_index].dept_date
        DATA[flight_index].dept = dept_obj
        DATA[flight_index] = this.processIndividualFlight(DATA[flight_index])
      } else if (TYPE === 'BUILDUP') {
        let buildUpArray = DETAILS?.build_up
        DATA[flight_index].build_up = buildUpArray
        DATA[flight_index] = this.processIndividualFlight(DATA[flight_index])
      } else if (TYPE === 'BOOKING') {
        let bookingArray = DETAILS?.details
        DATA[flight_index].awb_segments = bookingArray
        DATA[flight_index] = this.processIndividualFlight(DATA[flight_index])
      } else if (TYPE === 'GATE') {
        let gateArray = DETAILS?.gate
        DATA[flight_index].awb_segments = gateArray
        DATA[flight_index] = this.processIndividualFlight(DATA[flight_index])
      } else if (TYPE === 'FLIGHTNORIDE') {
        let norideArray = DETAILS?.data
        DATA[flight_index].noride = norideArray
      } else if (TYPE === 'FLIGHTAUDIT') {
        let auditArray = DETAILS?.data
        DATA[flight_index].audit = auditArray
      } else if (TYPE === 'FLIGHTLDD') {
        if (!DATA[flight_index].ldd) {
          DATA[flight_index].ldd = DETAILS?.data
        } else {
          DATA[flight_index].ldd = DETAILS?.data
        }
      }

      this.setState({ flightData: [] }, () => {
        if (type === 'flight') {
          this.setState({ flightData: DATA }, () => {
            if (this.state.departedFlightsCheck) {
              this.departedFlightsFilter()
            }
          })
        } else {
          this.setState({ flightData: DATA }, () => {
            if (this.state.departedFlightsCheck) {
              this.departedFlightsFilter()
            }
          })
        }
      })
    }
  }
  addAWB_segment = (DETAILS) => {
    let ID = parseInt(DETAILS?.flight_id)
    let DATA = cloneDeep(this.state.flightData)
    let flight_index = DATA?.findIndex((p) => p.id === ID)
    if (flight_index > -1) {
      let awb_seg_index = DATA[flight_index].awb_segments.findIndex((p) => p.id === DETAILS?.details?.id)
      if (awb_seg_index > -1) {
        //do nothing
      } else {
        let obj = {
          id: DETAILS?.details?.id,
          awb_no: DETAILS?.details?.awb_no,
          space: DETAILS?.details?.space,
          awb: {
            weight_unit: DETAILS?.details?.weight_unit,
            vol_unit: DETAILS?.details?.vol_unit
          }
        }
        DATA[flight_index].awb_segments.push(obj)
        DATA[flight_index] = this.processIndividualFlight(cloneDeep(DATA[flight_index]))
        this.setState({ flightData: [] }, () => this.setState({ flightData: DATA }))
      }

    }
  }
  removeAWB_segment = (DETAILS) => {
    let ID = parseInt(DETAILS?.flight_id)
    let DATA = cloneDeep(this.state.flightData)
    let flight_index = DATA?.findIndex((p) => p.id === ID)
    if (flight_index > -1) {
      let awb_seg_index = DATA[flight_index].awb_segments.findIndex((p) => p.id === DETAILS?.details?.id)
      if (awb_seg_index > -1) {
        DATA[flight_index].awb_segments.splice(awb_seg_index, 1)
        DATA[flight_index] = this.processIndividualFlight(cloneDeep(DATA[flight_index]))
        this.setState({ flightData: [] }, () => this.setState({ flightData: DATA }))
      }
    }
  }
  //FETCH FLIGHT CONTROL DATA
  fetchFlights = async (search = null) => {
    let temp_timestamp = moment().valueOf()
    this.setState({
      loading: search || this.state.page === 1 ? true : false,
      latestTimestamp: temp_timestamp,
      page: search ? 1 : this.state.page,
    })
    let headers = { station: this.state.station }

    let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) + ' ' + this.state.stime.format(timeFormat), this.state.timezone).utc()
    let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) + ' ' + this.state.etime.format(timeFormat), this.state.timezone).utc()
    let start = this.state.start ? start_date.format(dateFormat) : ''
    let end = this.state.end ? end_date.format(dateFormat) : ''
    let stime = this.state.stime ? start_date.format(timeFormat) : ''
    let etime = this.state.etime ? end_date.format(timeFormat) : ''
    let url = 'flight_control_list'
    if (search) {
      url = url + search
    } else {
      url = url + `?start=${start}&end=${end}&stime=${stime}&etime=${etime}&page=${this.state.page}&count=50`
    }

    await Http.get(url, { headers }).then(resp => {
      if (this.state.latestTimestamp === temp_timestamp) {
        if (resp && resp.status === 200) {
          if (resp?.data?.data?.length > 0) {
            let data = []
            if (search === '' || search === null) {
              data = this.state.flightData?.concat(resp.data.data)
            } else {
              data = resp.data.data
            }

            //DUPLICATE DATA CHECK
            let newData = []
            data?.map((item, index) => {
              let id = newData.findIndex(p => p.id == parseInt(item.id))
              if (id > -1) {
                //DO NOTHING
              } else {
                item.key = index
                newData.push(item)
              }
            })

            newData = newData.map((flight) => {
              return { ...this.processIndividualFlight(flight) }
            })

            this.sort(newData, true)
            this.setState({
              loading: false,
              flightData: newData,
              page: search ? 1 : this.state.page + 1,
            }, () => {
              if (this.state.departedFlightsCheck) {
                this.departedFlightsFilter()
              }
              if (!search) {
                this.fetchFlights()
              }
            })
          } else {
            if (this.state.page > 1) {
              //DO NOTHING
            } else {
              this.setState({
                flightData: [],
                loading: false
              }, () => {
                notification.destroy()
                notification.error({
                  message: constants.NO_DATA_ERROR,
                  description: resp?.data?.message,
                })
              })
            }
          }
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror: true
      })
    })
  }
  //UPDATE INDIVIDUAL FLIGHT DETAILS
  updateFlightDetails = async (DETAILS, UPDATE_TYPE) => {
    let DATA = cloneDeep(this.state.flightData)

    if (UPDATE_TYPE === 'wgt_bal_plan') {
      let flight_index1 = DATA?.findIndex(p => p?.id == DETAILS?.id)
      if (flight_index1 > -1) {
        DATA[flight_index1].wgt_bal_plan = DETAILS?.wgt_bal_plan || {}
        DATA[flight_index1].wgt_bal_status = DETAILS?.wgt_bal_status || {}
        DATA[flight_index1].aft_index = DETAILS?.aft_index || {}
        this.setState({ flightData: DATA })
      }
    }

    if (UPDATE_TYPE === 'BUILD_AWB' || UPDATE_TYPE === 'BUILD_ULD') {
      if (UPDATE_TYPE === 'BUILD_ULD') {
        if (DETAILS && DETAILS?.ulds) {
          this.setState({ assignedUldsData: DETAILS?.ulds })
        }
      }
      let flight_index1 = DATA?.findIndex(p => p?.id == DETAILS?.flights_id)
      // console.log('flight_index1', flight_index1, DATA[flight_index1])
      if (flight_index1 > -1) {
        let build_plan = cloneDeep(DATA[flight_index1].build_plan || [])
        let awb_index = DATA[flight_index1].build_plan?.findIndex(p => p?.id == DETAILS?.id)
        // console.log('awb_index', awb_index)
        if (awb_index > -1) {
          build_plan[awb_index] = DETAILS
        }
        else {
          build_plan.push(DETAILS)
        }
        DATA[flight_index1].build_plan = build_plan
        this.setState({ flightData: DATA }, () => {
          // console.log('EDIT RECORD', this.state.editRecord, DETAILS?.flights_id)
          if (this.state.editRecord && DETAILS?.flights_id == this.state.editRecord?.id) {
            this.setState({ editRecord: DATA[flight_index1] }, () => {
              this.bookedULD()
              if (this.state.flightDetails?.id == DETAILS?.id) {
                this.setState({ flightDetails: DETAILS }, () => {
                  this.fetchUlds()
                })
              }
            })
          }
        })
      }
    }

    if (UPDATE_TYPE === 'CREATE_ULD') {
      let flight_index1 = DATA?.findIndex(p => p?.id == DETAILS?.flights_id)
      if (flight_index1 > -1) {
        let build_uld = cloneDeep(DATA[flight_index1].build_uld || [])
        if (DETAILS && DETAILS?.delete) {
          let uld_index = build_uld.findIndex(p => p?.id == DETAILS?.id)
          if (uld_index > -1) {
            build_uld.splice(uld_index, 1)
          }
        } else {
          let uld_index = build_uld.findIndex(p => p?.id == DETAILS?.id)
          if (uld_index > -1) {
            build_uld[uld_index] = DETAILS
          }
          else {
            build_uld.push(DETAILS)
          }
        }
        DATA[flight_index1].build_uld = build_uld
        this.setState({ flightData: DATA }, () => {
          if (this.state.editRecord && DETAILS?.flights_id == this.state.editRecord?.id) {
            this.setState({ editRecord: DATA[flight_index1] }, () => {
              this.bookedULD()
              if (this.state.flightDetails?.id == DETAILS?.id) {
                this.setState({ flightDetails: DETAILS }, () => {
                  this.fetchUlds()
                })
              }
            })
          }
        })
      }
    }

    //CHECK FLIGHT RANGE
    let start_date = moment.tz((isMoment(this.state.start) ? this.state.start.format(dateFormat) : this.state.start) + ' ' + this.state.stime.format(timeFormat), this.state.timezone).utc().valueOf()
    let end_date = moment.tz((isMoment(this.state.end) ? this.state.end.format(dateFormat) : this.state.end) + ' ' + this.state.etime.format(timeFormat), this.state.timezone).utc().valueOf()

    let local_tz_dept_date = 0
    if (DETAILS?.flight_date) {
      local_tz_dept_date = moment.utc(DETAILS?.flight_date).valueOf()
      if (local_tz_dept_date > start_date && local_tz_dept_date < end_date) {
        let flight_index = DATA?.findIndex(p => p.id == parseInt(DETAILS?.flight_id))
        //UPDATE FLIGHT DATA
        if (flight_index > -1) {
          if (UPDATE_TYPE === 'DETAILS') {
            DATA[flight_index] = this.processIndividualFlight(this.parseFlight(DATA[flight_index], DETAILS))
          } else if (UPDATE_TYPE === 'PLAN') {
            DATA[flight_index].details = DETAILS?.details || []
          }
          if (DETAILS?.last_updated?.length > 0) {
            let user_obj = this.state.userList?.find((item) => item?.id === parseInt(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.user_id))
            DATA[flight_index].last_updated = DETAILS?.last_updated
            DATA[flight_index].last_updated_by = `${user_obj?.name?.first_name} ${user_obj?.name?.last_name}` || ''
            DATA[flight_index].last_updated_at = moment.utc(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.updated_date).tz(this.state.timezone).format(dateTimeFormat) || ''
          }
          //CHECK IF EDITING RECORD MATCHES FETCHED FLIGHT RECORD
          if (this.state.editRecord && DATA[flight_index]?.id === this.state.editRecord?.id) {
            if (this.state.user_id !== DETAILS?.user_id) {
              let flight_no_date = ''
              if (DATA[flight_index]?.dept_time)
                flight_no_date = `${DATA[flight_index]?.flight_no}/${moment(DATA[flight_index]?.dept_time).format(dateOnly)}`
              else
                flight_no_date = `${DATA[flight_index]?.flight_no}/${moment(DATA[flight_index]?.flight_date).format(dateOnly)}`
              notification.destroy()
              if (UPDATE_TYPE === 'DETAILS') {
                notification.warning({ message: `Flight ${flight_no_date} details was updated.` })
              } else if (UPDATE_TYPE === 'PLAN') {
                notification.warning({ message: 'Load plan details were updated' })
              }
            } else {
              //SET RECORD EDIT IF OPEN (MAY NOT BE NEEDED)
              this.setEdit(DATA[flight_index], UPDATE_TYPE)
            }
          }

          this.sort(DATA, false)
        } else {
          //UPDATE RECORD IF NOT IN CURRENT LIST
          this.fetchIndividualFlight(parseInt(DETAILS?.flight_id)).then((item) => {
            if (item) {
              DATA = cloneDeep(this.state.flightData)
              DATA.push(this.processIndividualFlight(item))
              this.setState({ flightData: DATA }, () => this.updateFlightDetails(DETAILS, UPDATE_TYPE))
            }
          })
        }
      } else {
        //IF FLIGHT DOES NOT EXIST IN CURRENT LIST AND IS BEING EDITED ON SEARCH, THEN UPDATE DETAILS/PLAN
        let editingRecord = this.state.editRecord
        if (editingRecord?.id) {
          if (UPDATE_TYPE === 'DETAILS') {
            editingRecord = this.processIndividualFlight(this.parseFlight(this.state.editRecord, DETAILS))
          } else if (UPDATE_TYPE === 'PLAN') {
            editingRecord.details = DETAILS?.details || []
            //GET LAST UPDATED INFO
          }
          if (DETAILS?.last_updated?.length > 0) {
            let user_obj = this.state.userList?.find((item) => item?.id === parseInt(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.user_id))
            editingRecord.last_updated = DETAILS?.last_updated
            editingRecord.last_updated_by = `${user_obj?.name?.first_name} ${user_obj?.name?.last_name}` || ''
            editingRecord.last_updated_at = moment.utc(DETAILS.last_updated[DETAILS.last_updated.length - 1]?.updated_date).tz(this.state.timezone).format(dateTimeFormat) || ''
          }

          //CHECK IF EDITING RECORD MATCHES FETCHED FLIGHT RECORD
          if (editingRecord?.id === parseInt(DETAILS?.flight_id)) {
            if (this.state.user_id !== DETAILS?.user_id) {
              // let flight_no_date = `${DATA[flight_index]?.flight_no}/${moment(DATA[flight_index]?.flight_date).format(dateOnly)}`
              notification.destroy()
              if (UPDATE_TYPE === 'DETAILS') {
                // notification.warning({ message: `Flight ${flight_no_date} details was updated.` })
                notification.warning({ message: 'Flight details was updated.' })
              } else if (UPDATE_TYPE === 'PLAN') {
                notification.warning({ message: 'Load plan details were updated' })
              }
            } else {
              //SET RECORD EDIT IF OPEN (MAY NOT BE NEEDED)
              this.setEdit(editingRecord, UPDATE_TYPE)
            }
          }
        }
      }
    }
  }
  //FETCH INDIVIDUAL FLIGHT
  fetchIndividualFlight = async (ID) => {
    let headers = { station: this.state.station }
    let obj = null
    await Http.get(`flight_control_list?id=${ID}`, { headers }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          obj = resp?.data?.data[0]
        }
      }
    })
    return obj
  }
  //PARSE FLIGHT RECORD
  parseFlight = (flight, event_record) => {
    let flightObj = {
      bag: {},
      cargo: {},
      wgt: {},
    }

    flight.fcst = event_record?.fcst ? parseInt(event_record?.fcst) : 0
    flight.bu_prio = event_record?.bu_prio === 'true' || event_record?.bu_prio === true ? true : false
    flight.load_plan = event_record?.load_plan === 'true' || event_record?.load_plan === true ? true : false

    flightObj.bag.p96 = event_record?.bag?.p96 ? parseInt(event_record.bag.p96) : 0
    flightObj.bag.p88 = event_record?.bag?.p88 ? parseInt(event_record.bag.p88) : 0
    flightObj.bag.ld3 = event_record?.bag?.ld3 ? parseInt(event_record.bag.ld3) : 0
    flightObj.bag.ld2 = event_record?.bag?.ld2 ? parseInt(event_record.bag.ld2) : 0
    flight.bag = flightObj.bag

    flightObj.cargo.md = event_record?.cargo?.md ? parseInt(event_record.cargo.md) : 0
    flightObj.cargo.p96 = event_record?.cargo?.p96 ? parseInt(event_record.cargo.p96) : 0
    flightObj.cargo.p88 = event_record?.cargo?.p88 ? parseInt(event_record.cargo.p88) : 0
    flightObj.cargo.ld3 = event_record?.cargo?.ld3 ? parseInt(event_record.cargo.ld3) : 0
    flightObj.cargo.ld2 = event_record?.cargo?.ld2 ? parseInt(event_record.cargo.ld2) : 0
    flight.cargo = flightObj.cargo

    flightObj.wgt.value = event_record?.wgt?.value ? parseFloat(event_record.wgt.value) : 0
    flightObj.wgt.unit = event_record?.wgt?.unit
    flight.wgt = flightObj.wgt
    return flight
  }
  // Append DL is not present
  checkPrefix = async () => {
    let fltno = this.state.searchFlightNo
    if (fltno.includes('DL')) {
      //do nothing
    } else if (fltno) {
      this.setState({ searchFlightNo: 'DL' + fltno })
    }
  }
  //FETCH FLIGHT DATA ON SEARCH
  fetchFlightOnSearch = async () => {
    let headers = { station: this.state.station }
    if (this.state.searchFlightNo && this.state.searchDate) {
      let date = moment(this.state.searchDate).format(dateFormat)
      await Http.get(`flight_control_list?flight_no=${this.state.searchFlightNo}&flight_date=${date}`, { headers }).then((resp) => {
        this.setState({ modalLoading: false })
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          // console.log('Respnse', resp.data.data)
          this.setEdit(this.processIndividualFlight(resp?.data?.data[resp?.data?.data?.length - 1]))
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        }
      }).catch(() => {
        this.setState({
          servererror: true,
          modalLoading: false,
        }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
        })
      })
    } else {
      this.setState({ modalLoading: false }, () => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'Invalid Input',
        })
      })
    }
  }
  //PRODCESS INDIVDUAL FLIGHT
  processIndividualFlight = (flight) => {
    console.log(flight, 'flight before')
    //NOTES
    flight.notes = flight?.notes_count || 0
    flight.checklist_notes = flight?.checklist_notes_count || 0
    flight.notes_date = flight.notes_date ? moment.utc(flight.notes_date).tz(this.state.timezone).format(completeDateTime) : null
    //CALCULATE DEPARTURE TIME
    flight.dept_time = ''
    flight.schdl = ''
    flight.dept_prefix = 'S'
    flight.fpe = this.getFPE(flight)
    if (flight?.dept) {
      if (flight?.dept?.act) {
        flight.dept_prefix = 'A'
        flight.dept_time = moment.utc(flight.dept.act).tz(this.state.timezone).valueOf()
      }
      else if (flight?.dept?.eta) {
        flight.dept_prefix = 'E'
        flight.dept_time = moment.utc(flight.dept.eta).tz(this.state.timezone).valueOf()
      }
      else if (flight?.dept?.schdl) {
        flight.dept_prefix = 'S'
        flight.dept_time = moment.utc(flight.dept.schdl).tz(this.state.timezone).valueOf()
      } else if (flight?.dept_date) {
        flight.dept_time = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
      } else {
        flight.dept_time = moment(flight.flight_date).valueOf()
      }
    } else if (flight?.dept_date) {
      flight.dept_time = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
    } else {
      flight.dept_time = moment(flight.flight_date).valueOf()
    }

    if (flight?.dept_date) {
      flight.schdl = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
    } else if (flight?.dept?.schdl) {
      flight.schdl = moment.utc(flight.dept.schdl).tz(this.state.timezone).valueOf()
    }
    //CALCULATE BOOKED WEIGHT
    flight.booked_kg = 0
    flight.booked_lbs = 0

    let uniqueULDNo = []
    if (flight?.ulds_out?.length > 0) {
      flight.ulds_out.map((uld) => {
        if (uld?.awbs_in?.length > 0) {
          uld.awbs_in.map((item) => {
            uniqueULDNo.push(item?.awb_no)
            let unit = item?.wgt?.unit
            if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
              flight.booked_lbs += parseFloat(item?.wgt?.value)
              flight.booked_kg += weightCalculator(parseFloat(item?.wgt?.value), 'LBS')
            } else if (unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
              flight.booked_kg += parseFloat(item?.wgt?.value)
              flight.booked_lbs += weightCalculator(parseFloat(item?.wgt?.value), 'KGM')
            }
          })
        }
      })
    }

    if (flight?.awb_segments?.length > 0) {
      flight.awb_segments.map((seg) => {
        if (!uniqueULDNo?.includes(seg?.awb_no)) {
          uniqueULDNo.push(seg?.awb_no)
          let unit = seg?.awb?.weight_unit
          if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
            flight.booked_lbs += parseFloat(seg?.space?.wgt)
            flight.booked_kg += weightCalculator(parseFloat(seg?.space?.wgt), 'LBS')
          } else if (unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
            flight.booked_kg += parseFloat(seg?.space?.wgt)
            flight.booked_lbs += weightCalculator(parseFloat(seg?.space?.wgt), 'KGM')
          }
        }
      })
    }
    //CALCULATE BUILD UP WEIGHT
    flight.ready_kg = 0
    flight.ready_lbs = 0
    flight.gate_kg = 0
    flight.gate_lbs = 0
    if (flight?.ulds_out?.length > 0) {
      // console.log(flight?.ulds_out, 'flight?.ulds_out')
      flight.ulds_out.map((uld) => {
        uld.type = this.checkULDType(uld)
        if (uld?.awbs_in?.length > 0) {
          uld.awbs_in.map((awb) => {
            let unit = awb?.wgt?.unit
            let value = awb?.wgt?.value
            if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
              if (!uld?.blk) {
                flight.ready_lbs += parseFloat(value)
                flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              } else {
                if ((awb?.man && (uld?.type === 'BLK' || uld?.blk)) || (uld?.type === 'CART')) {
                  flight.ready_lbs += parseFloat(value)
                  flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
                }
              }
              // if(!uld?.blk) {
              //   if(uld?.rdy) {
              //     flight.ready_lbs += parseFloat(value)
              //     flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              //   }
              // } else {
              //   if(awb?.man || (uld?.uld_no?.startsWith('C') && uld?.uld_no?.endsWith('#'))) {
              //     flight.ready_lbs += parseFloat(value)
              //     flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              //   }
              // }

              if (awb?.location?.startsWith('0') && awb?.inbound && (awb?.prev_location !== null && awb?.prev_location !== 'UNKNOWN')) {
                flight.gate_lbs += parseFloat(value)
                flight.gate_kg += weightCalculator(parseFloat(value), 'LBS')
              }
            } else if (unit === 'K' || unit === 'KG' || unit === 'KGM') {
              if (!uld?.blk) {
                flight.ready_kg += parseFloat(value)
                flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              } else {
                if ((awb?.man && (uld?.type === 'BLK' || uld?.blk)) || (uld?.type === 'CART')) {
                  flight.ready_kg += parseFloat(value)
                  flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
                }
              }
              // if(!uld?.blk) {
              //   if(uld?.rdy) {
              //     flight.ready_kg += parseFloat(value)
              //     flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              //   }
              // } else {
              //   if(awb?.man || (uld?.uld_no?.startsWith('C') && uld?.uld_no?.endsWith('#'))) {
              //     flight.ready_kg += parseFloat(value)
              //     flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              //   }
              // }

              if (awb?.location?.startsWith('0') && awb?.inbound && (awb?.prev_location !== null && awb?.prev_location !== 'UNKNOWN')) {
                flight.gate_kg += parseFloat(value)
                flight.gate_lbs += weightCalculator(parseFloat(value), 'KGM')
              }
            }
          })
        }
      })
    }
    //CALCULATE COUNTDOWN TIMER
    let time_left = moment(flight.dept_time).diff(moment().tz(this.state.timezone), 'minutes')
    //SUBTRACT FPE
    time_left = time_left - (1 * (flight.fpe || 0))
    flight.time_to_dept = moment().add(time_left, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    //CALCULATE PAX CAPACITY, CONT TYPE, AFT AND FWS CONFIG.
    flight.pax_cap = 0
    flight.aft_conf = null
    flight.fwd_conf = null
    flight.cont_type = []
    flight.acpt_body = true
    if (this.state.fleetData?.length > 0) {
      let filter = this.state.fleetData.filter((item) => item?.details?.ac_code === flight?.ac_type)
      if (filter?.length > 0) {
        flight.fleet = filter[0]?.details?.fleet
        flight.pax_cap = filter[0]?.details?.pax_cap
        flight.aft_conf = filter[0]?.details?.aft_conf
        flight.fwd_conf = filter[0]?.details?.fwd_conf
        flight.cont_type = filter[0]?.details?.cont_type?.split(',') || []
        flight.acpt_body = filter[0]?.details?.ac_type === 'WB' ? true : false
      }
    }
    //CALC FLIGHT WEIGHT
    let unit = flight?.wgt?.unit
    let value = flight?.wgt?.value
    if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
      flight.weight_lbs = parseFloat(value) //ROUNDED TO NEAREST INTEGER
      flight.weight_kg = weightCalculator(parseFloat(value), 'LBS')
    } else if (unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
      flight.weight_kg = parseFloat(value) //ROUNDED TO NEAREST INTEGER
      flight.weight_lbs = weightCalculator(parseFloat(value), 'KGM')
    }
    //GET LAST UPDATED INFO
    flight.last_updated_by = ''
    flight.last_updated_at = ''
    if (flight?.last_updated?.length > 0) {
      let user_obj = this.state.userList?.find((item) => item?.id === parseInt(flight.last_updated[flight.last_updated.length - 1]?.user_id))
      flight.last_updated_by = `${user_obj?.name?.first_name} ${user_obj?.name?.last_name}` || ''
      flight.last_updated_at = moment.utc(flight.last_updated[flight.last_updated.length - 1]?.updated_date).tz(this.state.timezone).format(dateTimeFormat) || ''
    }
    //STORE ULDS FOR NOT TENDERED SUMMARY
    flight.not_tendered = {
      blk_count: 0,
      weight_lbs: 0,
      weight_kg: 0,
      pcs: 0,
      md: 0,
      udp: 0,
      ldc: 0,
      ldp: 0,
    }
    let notTenderedArr = []
    flight?.awb_segments?.filter(ar => {
      flight?.ulds_out?.find(uld => {
        if (!uld?.awbs_in?.find(awb => awb.awb_no === ar.awb_no)) {
          notTenderedArr.push(ar)
        }
      })
    })
    if (notTenderedArr?.length > 0) {
      let blk_count = 0
      let weight_lbs = 0
      let weight_kg = 0
      let pcs = 0

      notTenderedArr?.map((item) => {
        if (item?.space?.udp || item?.space?.ldc || item?.space?.ldp) {
          flight.not_tendered.udp = flight.not_tendered.udp + parseInt(item?.space?.udp || 0)
          flight.not_tendered.ldc = flight.not_tendered.ldc + parseInt(item?.space?.ldc || 0)
          flight.not_tendered.ldp = flight.not_tendered.ldp + parseInt(item?.space?.ldp || 0)
        } else {
          blk_count += 1
          pcs += parseFloat(item?.space?.vol || 0) || 0
          // if(item?.vol?.unit === 'FTQ') {
          //   pcs += parseFloat(item?.space?.vol || 0)
          // } else if(item?.vol?.unit === 'MTQ') {
          //   pcs += parseFloat((item?.space?.vol || 0) * 35.314667)
          // } else if(item?.vol?.unit === 'CF') {
          //   pcs += parseFloat(item?.space?.vol || 0)
          // }
          if (item.awb.weight_unit === 'L' || item.awb.weight_unit === 'LB' || item.awb.weight_unit === 'LBS' || item.awb.weight_unit === 'LBR') {
            weight_lbs += item.space.wgt //ROUNDED TO NEAREST INTEGER
            weight_kg += weightCalculator(item.space.wgt, 'LBS')
          } else if (item.awb.weight_unit === 'K' || item.awb.weight_unit === 'KG' || item.awb.weight_unit === 'KGS' || item.awb.weight_unit === 'KGM') {
            weight_kg += item.space.wgt //ROUNDED TO NEAREST INTEGER
            weight_lbs += weightCalculator(item.space.wgt, 'KGM')
          }
        }
      })
      flight.not_tendered.blk_count = blk_count
      flight.not_tendered.weight_lbs = weight_lbs
      flight.not_tendered.weight_kg = weight_kg
      flight.not_tendered.pcs = pcs?.toFixed(2) || 0
    }

    //GET ACTUAL ULD POSITIONS
    let ULDs = []
    flight.unique_ulds = []
    if (flight?.ulds_out?.length > 0) {
      flight.ulds_out.map((uld) => {
        uld.position_obj = this.state.uldMappingData?.find((item) => item?.uld_prefix === uld?.uld_no?.slice(0, 3) && item?.ac_types?.includes(flight?.ac_type)) || null
        ULDs.push(uld)
      })
    }

    //GET UNIQUE ULDS AND CALC ULD POSITIONS LOGIC
    flight.unique_ulds = [...new Map(ULDs.map(item => [item?.uld_no, item])).values()]
    let uldPositionsObj = this.calcULDPositions(flight)
    flight.actual = {
      MD: uldPositionsObj?.MD,
      P96: uldPositionsObj?.P96,
      P88: uldPositionsObj?.P88,
      LD3: uldPositionsObj?.LD3,
      LD2: uldPositionsObj?.LD2,
    }

    //FLIGHT TARE WEIGHT AND ADD TO READY WEIGHT
    flight.tare_wgt_kg = this.addTareWgt(flight)?.weight_kg || 0
    flight.tare_wgt_lbs = this.addTareWgt(flight)?.weight_lbs || 0
    flight.ready_kg += parseFloat(flight.tare_wgt_kg)
    flight.ready_lbs += parseFloat(flight.tare_wgt_lbs)
    flight.cccolour = ''
    flight.cccolour_css = ''
    let weight_assigned = this.state.weight_unit === 'KG' ? flight?.booked_kg : flight?.booked_lbs
    let weight_cargo = this.state.weight_unit === 'KG' ? flight?.weight_kg : flight?.weight_lbs
    if (!flight?.bag) {
      if (weight_cargo > 0) {
        if (weight_assigned > weight_cargo) {
          flight.cccolour_css = 'p-icon cc-icon fs-0 amber-cc' //SHOW YELLOW
          flight.cccolour = 'amber'
        } else {
          flight.cccolour_css = 'p-icon cc-icon fs-0 green-cc' //SHOW GREEN
          flight.cccolour = 'green'
        }
      } else {
        flight.cccolour_css = 'p-icon cc-icon fs-0' //SHOW BLUE
        flight.cccolour = 'blue'
      }
    } else if (flight?.bag && flight?.cargo && flight?.cargo?.md === 0 && flight?.cargo?.p96 === 0 && flight?.cargo?.p88 === 0 && flight?.cargo?.ld3 === 0 && flight?.cargo?.ld2 === 0) {
      if (weight_cargo > 0) {
        if (weight_assigned > weight_cargo) {
          flight.cccolour_css = 'p-icon cc-icon fs-0 amber-cc' //SHOW YELLOW
          flight.cccolour = 'amber'
        } else {
          flight.cccolour_css = 'p-icon cc-icon fs-0 green-cc' //SHOW GREEN
          flight.cccolour = 'green'
        }
      } else {
        flight.cccolour_css = 'p-icon cc-icon fs-0' //SHOW BLUE
        flight.cccolour = 'blue'
      }
    } else {
      if (((flight?.cargo?.md || 0) - (flight?.actual?.MD || 0)) < 0 ||
        ((flight?.cargo?.p96 || 0) - (flight?.actual?.P96 || 0)) < 0 ||
        ((flight?.cargo?.p88 || 0) - (flight?.actual?.P88 || 0)) < 0 ||
        ((flight?.cargo?.ld3 || 0) - (flight?.actual?.LD3 || 0)) < 0 ||
        ((flight?.cargo?.ld2 || 0) - (flight?.actual?.LD2 || 0)) < 0) {
        flight.cccolour_css = 'p-icon cc-icon fs-0 red-cc' //SHOW RED
        flight.cccolour = 'red'
      } else {
        if (weight_cargo > 0 && (weight_assigned > weight_cargo)) {
          flight.cccolour_css = 'p-icon fs-0 amber-cc' //SHOW YELLOW
          flight.cccolour = 'amber'
        } else if (((flight?.cargo?.md || 0) - (flight?.actual?.MD || 0)) > 0 ||
          ((flight?.cargo?.p96 || 0) - (flight?.actual?.P96 || 0)) > 0 ||
          ((flight?.cargo?.p88 || 0) - (flight?.actual?.P88 || 0)) > 0 ||
          ((flight?.cargo?.ld3 || 0) - (flight?.actual?.LD3 || 0)) > 0 ||
          ((flight?.cargo?.ld2 || 0) - (flight?.actual?.LD2 || 0)) > 0) {
          flight.cccolour_css = 'p-icon cc-icon fs-0 green-cc'
          flight.cccolour = 'green'
        } else {
          flight.cccolour_css = 'p-icon cc-icon fs-0' //SHOW Blue
          flight.cccolour = 'blue'
        }
      }
    }
    // console.log('after', flight)
    return flight
  }
  //ADDING TARE WEIGHT TO FLIGHT
  addTareWgt = (flight) => {
    let weight_kg = 0
    let weight_lbs = 0
    if (flight?.unique_ulds?.length > 0) {
      flight?.unique_ulds?.map((item) => {
        if ((item?.uld_type !== 'CART') && item?.position_obj?.ac_types?.includes(flight?.ac_type)) {
          let unit = item?.position_obj?.weight?.unit
          let value = item?.position_obj?.weight?.value
          if (unit === 'LBS' || unit === 'L' || unit === 'LB' || unit === 'LBR') {
            weight_lbs += value //ROUNDED TO NEAREST INTEGER
            weight_kg += weightCalculator(value, 'LBS')
          } else if (unit === 'K' || unit === 'KG' || unit === 'KGM') {
            weight_kg += value //ROUNDED TO NEAREST INTEGER
            weight_lbs += weightCalculator(value, 'KGM')
          }
        }
      })
    }
    return {
      weight_kg: weight_kg,
      weight_lbs: weight_lbs,
    }
  }
  //CALCULATE ULD POSITIONS
  calcULDPositions = (flight) => {
    let a_md = 0
    let a_p96 = 0
    let a_p88 = 0
    let a_ld3 = 0
    let a_ld2 = 0

    if (flight?.unique_ulds?.length > 0) {
      flight?.unique_ulds?.map((item) => {
        if ((!item?.uld_no?.startsWith('C') && !item?.uld_no?.endsWith('#')) && item?.position_obj?.ac_types?.includes(flight?.ac_type)) {
          switch (item?.position_obj?.uldtype) {
          case 'MD':
            a_md += item?.position_obj?.position
            break
          case 'P96':
            a_p96 += item?.position_obj?.position
            break
          case 'P88':
            a_p88 += item?.position_obj?.position
            break
          case 'LD3':
            a_ld3 += item?.position_obj?.position
            break
          case 'LD2':
            a_ld2 += item?.position_obj?.position
            break
          default:
            break
          }
        }
      })
    }
    return {
      MD: a_md,
      P96: a_p96,
      P88: a_p88,
      LD3: a_ld3,
      LD2: a_ld2,
    }
  }
  //CALCULATE FLIGHT FPE
  getFPE = (flight) => {
    let fpe = 0
    let dest_type = ''

    let details = this.state.stationsData?.find((stn) => stn?.code === flight?.dest?.arriv) || ''
    if (details) {
      if (country.includes(details?.country?.toUpperCase())) {
        dest_type = 'EXPORT-D'
      } else {
        dest_type = 'EXPORT-I'
      }

      let ht = this.state.defaultSystemParameters?.find((item) => item?.details?.process?.toUpperCase() === dest_type)
      if (ht && ht.dest_list && ht.dest_list.length > 0) {
        let index = ht.dest_list.findIndex((p) => p.dest === flight?.dest?.arriv)
        if (index > -1) {
          fpe = ht.dest_list[index].fpe
        } else {
          fpe = this.state.defaultSystemParameters?.find((item) => item?.details?.process?.toUpperCase() === dest_type)?.details?.fpe
        }
      } else {
        fpe = this.state.defaultSystemParameters?.find((item) => item?.details?.process?.toUpperCase() === dest_type)?.details?.fpe
      }
    }
    return fpe
  }
  //UPDATE NOTES COUNT
  updateFlightNotesCount = (DETAILS) => {
    let DATA = cloneDeep(this.state.flightData)
    let flight_index = DATA?.findIndex(p => p.id == parseInt(DETAILS?.type?.toUpperCase() === 'CHECKLIST' ? DETAILS?.checklist_id : DETAILS?.flights_id))
    if (flight_index > -1) {
      if (DETAILS?.type?.toUpperCase() === 'FLIGHT') {
        DATA[flight_index].notes = DETAILS?.notes?.length || 0
        DATA[flight_index].notes_date = DETAILS?.timestamp ? moment.utc(DETAILS.timestamp).tz(this.state.timezone).format(completeDateTime) : null
        this.sort(DATA, false)
      } else if (DETAILS?.type?.toUpperCase() === 'CHECKLIST') {
        DATA[flight_index].checklist_notes_count = DETAILS?.checklist_notes?.length || 0
        this.setState({ flightData: [] }, () => this.setState({ flightData: this.sortDodCC(DATA, false) }))
      }
    }
  }

  //DISABLE PRE DEPARTURE NOTES IF FLIGHT HAS DEPARTED
  disableNote = (dept_time) => {
    return moment.tz(dept_time, this.state.timezone).diff(moment().tz(this.state.timezone), 'minutes') < 0
  }
  //UPDATE WEIGHT ON TOGGLE
  updateWeightVal = (unit, value) => {
    if (value > 0) {
      if (unit === 'LB') {
        return weightCalculator(parseFloat(value || 0), 'KGM')?.toFixed(2)
      } else if (unit === 'KG') {
        return weightCalculator(parseFloat(value || 0), 'LBS')?.toFixed(2)
      }
    }
  }

  // Start of CheckList APIs
  //FETCH MILESTONES
  fetchMilestones = async () => {
    let callback = this.fetchMilestonesFn
    let pageURL = 'milestones'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers)
  }
  fetchMilestonesFn = async (resp, event) => {
    if (!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (respData && respData?.data?.length > 0) {
      respData.data = respData?.data.map((item) => {
        item.label = item.title
        item.value = item.title
        return item
      })
      let noride = {
        amber: null,
        cutoff: 0,
        id: 'No Ride Alert',
        priority: null,
        title: 'No Ride Alert',
        type: 'No Rides',
        label: 'No Ride Alert',
        value: 'No Ride Alert',
      }
      respData?.data.push(noride)
      let milestoneFBData = cloneDeep(respData?.data)
      milestoneFBData.push({
        id: 'Interacted with LDD',
        label: 'Interacted with LDD',
        value: 'Interacted with LDD',
      })
      milestoneFBData.push({
        id: 'Flight Audit',
        label: 'Flight Audit',
        value: 'Flight Audit',
      })
      this.setState({
        milestoneData: respData?.data,
        milestoneFBData: milestoneFBData,
      })
    } else {
      this.setState({
        milestoneData: [],
        milestoneFBData: [],
      })
    }
    if (!event) this.fetchDefaultSystemParameters()
    // else {
    //   this.processChecklistData()
    // }
  }

  fetchAllQuestions = async () => {
    let headers = { station: this.state.station }
    await Http.get('questions?type=all', { headers }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.length > 0) {
          let data = resp?.data?.data

          this.setState({ allQuestionsData: data })
        } else {
          this.setState({ allQuestionsData: [] })
        }
      }
    })
  }
  fetchQuestions = async (flight_id, ms_id, event) => {
    let headers = { station: this.state.station }
    Promise.all([
      await Http.get(`questions?&ms_id=${ms_id}`, { headers }),
      await Http.get(`answers?&flight_id=${flight_id}&milestones_id=${ms_id}`, { headers }),
    ])
      .then(([questions, answers]) => {
        let questionsArr = []
        questions = questions?.data?.data
        answers = answers?.data?.data
        if (questions && questions.length > 0 && answers && answers.answers.length > 0) {
          if (event) {
            let flights = this.state.flightData
            let flight_index = flights.findIndex((flight) => flight.id === flight_id)
            if (flight_index > -1) {
              if (!flights[flight_index].answers) {
                flights[flight_index].answers = []
              }
              let answers_index = flights[flight_index].answers.findIndex((answer) => answer.milestones_id === ms_id)
              if (answers_index > -1) {
                flights[flight_index].answers[answers_index] = answers
              } else {
                flights[flight_index].answers.push(answers)
              }
              flights[flight_index] = this.processIndividualFlightChecklist(flights[flight_index])
              this.setState({ flightData: [] }, () => this.setState({ flightData: flights }))
            }
          }
          questions.map((question, index) => {
            let answer = answers.answers.find((answer) => answer.question_id === question.id)
            questions[index].answer = answer?.answer || null
            questions[index].comment = answer?.comment || null
            questionsArr.push(questions[index])
            if (questions[index].details.nested === 'Yes') {
              questions[index].parent?.map((child) => {
                let nestedanswer = answers.answers.find((answer) => answer.question_id === child.id)
                child.answer = nestedanswer?.answer || null
                child.comment = nestedanswer?.comment || null
                child.showchild = answer?.answer === 'Yes' ? true : false
                questionsArr.push(child)
              })
            }
          })
          let quizModalFlight = cloneDeep(this.state.quizModalFlight)
          if (quizModalFlight) {
            if (!quizModalFlight.answers) {
              quizModalFlight.answers = []
            }
            quizModalFlight.answers = answers || []
          }
          this.setState({
            openQuizModal: event ? false : true,
            questionsData: event ? [] : questionsArr,
            tempquestionsData: event ? [] : questionsArr,
            quizModalFlight: event ? null : quizModalFlight,
            milestonButton: 'Resubmit'
          })
        } else if (questions && questions.length > 0) {
          let questionsArr = []
          questions.map((question) => {
            questionsArr.push(question)
            if (question.details.nested === 'Yes') {
              question.parent && question.parent.map((child) => {
                child.showchild = false
                questionsArr.push(child)
              })
            }
          })
          this.setState({
            openQuizModal: event ? null : true,
            questionsData: event ? [] : questionsArr,
            tempquestionsData: event ? [] : questionsArr,
            milestonButton: 'Submit'
          })
        } else {
          notification.destroy()
          notification.warning({
            message: 'Warning',
            description: 'No Questions Found',
          })
          this.setState({
            questionsData: [],
            tempquestionsData: []
          })
        }
      })
  }

  //FETCH FLIGHT CHECKLIST
  fetchFlightsChecklist = async (search = null, fid = null) => {
    let temp_timestamp = moment().valueOf()
    this.setState({
      loading: search || this.state.page === 1 ? true : false,
      latestTimestamp: temp_timestamp,
      page: search ? 1 : this.state.page,
    })
    let headers = { station: this.state.station }

    let start_date = moment.tz((isMoment(this.state.start_checklist) ? this.state.start_checklist.format(dateFormat) : this.state.start_checklist) + ' ' + this.state.stime_checklist.format(timeFormat), this.state.timezone).utc()
    let end_date = moment.tz((isMoment(this.state.end_checklist) ? this.state.end_checklist.format(dateFormat) : this.state.end_checklist) + ' ' + this.state.etime_checklist.format(timeFormat), this.state.timezone).utc()
    let start = this.state.start_checklist ? start_date.format(dateFormat) : ''
    let end = this.state.end_checklist ? end_date.format(dateFormat) : ''
    let stime = this.state.stime_checklist ? start_date.format(timeFormat) : ''
    let etime = this.state.etime_checklist ? end_date.format(timeFormat) : ''
    let url = 'flight_control_list'
    if (search) {
      url = url + search
    } else if (fid) {
      url = url + `?id=${fid}`
    } else {
      url = url + `?start=${start}&end=${end}&stime=${stime}&etime=${etime}&page=${this.state.page}&count=10&type=checklist`
    }

    await Http.get(url, { headers }).then(resp => {
      if (this.state.latestTimestamp === temp_timestamp) {
        if (resp && resp.status === 200) {
          if (resp?.data?.data?.length > 0) {
            // console.log('Response', resp.data.data)
            let data = []
            if (search === '' || search === null) {
              data = this.state.flightData?.concat(resp.data.data)
            } else {
              data = resp.data.data
            }
            //DUPLICATE DATA CHECK
            let newData = []
            data?.map((item, index) => {
              let id = newData.findIndex(p => p.id == parseInt(item.id))
              if (id > -1) {
                //DO NOTHING
                item.key = index
                newData[id] = item
              } else {
                item.key = index
                newData.push(item)
              }
            })
            newData = newData.map((flight) => {
              return { ...this.processIndividualFlightChecklist(flight) }
            })
            let destStations = newData?.map((flight) => flight?.dest?.arriv || flight?.dest_stn)
            let destStationsArr = [...new Set(destStations)]
            destStationsArr.sort()
            // this.sort(newData, true)
            this.setState({
              loading: false,
              flightData: this.sortDodCC(newData),
              destStations: destStationsArr,
              page: search ? 1 : fid ? this.state.page : this.state.page + 1,
            }, () => {
              if (this.state.departedFlightsCheck) {
                this.departedFlightsFilter()
              }
              if (!search && !fid) {
                this.fetchFlightsChecklist()
              }
            })
          } else {
            if (this.state.page > 1) {
              //DO NOTHING
            } else {
              this.setState({
                flightData: [],
                loading: false
              }, () => {
                notification.destroy()
                notification.error({
                  message: constants.NO_DATA_ERROR,
                  description: resp?.data?.message,
                })
              })
            }
          }
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror: true
      })
    })
  }

  //PRODCESS INDIVDUAL FLIGHT CHECKLIST
  processIndividualFlightChecklist = (flight) => {
    // console.log('Flight before', flight)
    //NOTES
    flight.notes = flight?.notes_count || 0
    flight.checklist_notes_count = flight?.checklist_notes_count || 0
    flight.notes_date = flight.notes_date ? moment.utc(flight.notes_date).tz(this.state.timezone).format(completeDateTime) : null
    //CALCULATE DEPARTURE TIME
    flight.dept_time = ''
    flight.arr_time = ''
    flight.schdl = ''
    flight.dept_prefix = 'S'
    flight.fpe = this.getFPE(flight)
    if (flight?.dept) {
      if (flight?.dept?.act) {
        flight.dept_prefix = 'A'
        flight.dept_time = moment.utc(flight.dept.act).tz(this.state.timezone).valueOf()
      }
      else if (flight?.dept?.eta) {
        flight.dept_prefix = 'E'
        flight.dept_time = moment.utc(flight.dept.eta).tz(this.state.timezone).valueOf()
      }
      else if (flight?.dept?.schdl) {
        flight.dept_prefix = 'S'
        flight.dept_time = moment.utc(flight.dept.schdl).tz(this.state.timezone).valueOf()
      } else if (flight?.dept_date) {
        flight.dept_time = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
      } else {
        flight.dept_time = moment(flight.flight_date).valueOf()
      }
    } else if (flight?.dept_date) {
      flight.dept_time = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
    } else {
      flight.dept_time = moment(flight.flight_date).valueOf()
    }


    if (flight?.dept_date) {
      flight.schdl = moment.utc(flight.dept_date).tz(this.state.timezone).valueOf()
    } else if (flight?.dept?.schdl) {
      flight.schdl = moment.utc(flight.dept.schdl).tz(this.state.timezone).valueOf()
    }
    if (flight?.arriv_date) {
      flight.arr_time = moment.utc(flight.arriv_date).tz(this.state.timezone).valueOf()
    } else {
      flight.arr_time = moment(flight.flight_date).valueOf()
    }
    //CALCULATE BOOKED WEIGHT
    flight.booked_kg = 0
    flight.booked_lbs = 0

    let uniqueULDNo = []
    if (flight?.ulds_out?.length > 0) {
      flight.ulds_out.map((uld) => {
        if (uld?.awbs_in?.length > 0) {
          uld.awbs_in.map((item) => {
            uniqueULDNo.push(item?.awb_no)
            let unit = item?.wgt?.unit
            if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
              flight.booked_lbs += parseFloat(item?.wgt?.value)
              flight.booked_kg += weightCalculator(parseFloat(item?.wgt?.value), 'LBS')
            } else if (unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
              flight.booked_kg += parseFloat(item?.wgt?.value)
              flight.booked_lbs += weightCalculator(parseFloat(item?.wgt?.value), 'KGM')
            }
          })
        }
      })
    }

    if (flight?.awb_segments?.length > 0) {
      flight.awb_segments.map((seg, sindex) => {
        if (!uniqueULDNo?.includes(seg?.awb_no)) {
          uniqueULDNo.push(seg?.awb_no)
          let unit = seg?.awb?.weight_unit
          if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
            flight.booked_lbs += parseFloat(seg?.space?.wgt)
            flight.booked_kg += weightCalculator(parseFloat(seg?.space?.wgt), 'LBS')
          } else if (unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
            flight.booked_kg += parseFloat(seg?.space?.wgt)
            flight.booked_lbs += weightCalculator(parseFloat(seg?.space?.wgt), 'KGM')
          }
          if (flight.build_plan.length > 0) {
            let build_plan = flight.build_plan.find((item) => item?.awb_no === seg?.awb_no)
            if (build_plan) {
              if (build_plan.ulds.length > 0) {
                let tpcs = 0
                build_plan.ulds.map((uld) => {
                  tpcs += uld.pcs
                })
                flight.awb_segments[sindex].showred = parseInt(seg?.space?.pcs) === tpcs ? false : true
              }
            }
          }
        }
      })
    }
    //CALCULATE BUILD UP WEIGHT
    flight.ready_kg = 0
    flight.ready_lbs = 0
    flight.gate_kg = 0
    flight.gate_lbs = 0
    if (flight?.ulds_out?.length > 0) {
      flight.ulds_out.map((uld, uindex) => {
        uld.type = this.checkULDType(uld)
        if (uld?.awbs_in?.length > 0) {
          uld.awbs_in.map((awb, aindex) => {
            let unit = awb?.wgt?.unit
            let value = awb?.wgt?.value
            if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
              if (!uld?.blk) {
                flight.ready_lbs += parseFloat(value)
                flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              } else {
                if ((awb?.man && (uld?.type === 'BLK' || uld?.blk)) || (uld?.type === 'CART')) {
                  flight.ready_lbs += parseFloat(value)
                  flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
                }
              }
              // if(!uld?.blk) {
              //   if(uld?.rdy) {
              //     flight.ready_lbs += parseFloat(value)
              //     flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              //   }
              // } else {
              //   if(awb?.man || (uld?.uld_no?.startsWith('C') && uld?.uld_no?.endsWith('#'))) {
              //     flight.ready_lbs += parseFloat(value)
              //     flight.ready_kg += weightCalculator(parseFloat(value), 'LBS')
              //   }
              // }

              if (awb?.location?.startsWith('0') && awb?.inbound && (awb?.prev_location !== null && awb?.prev_location !== 'UNKNOWN')) {
                flight.gate_lbs += parseFloat(value)
                flight.gate_kg += weightCalculator(parseFloat(value), 'LBS')
              }
            } else if (unit === 'K' || unit === 'KG' || unit === 'KGM') {
              if (!uld?.blk) {
                flight.ready_kg += parseFloat(value)
                flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              } else {
                if ((awb?.man && (uld?.type === 'BLK' || uld?.blk)) || (uld?.type === 'CART')) {
                  flight.ready_kg += parseFloat(value)
                  flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
                }
              }
              // if(!uld?.blk) {
              //   if(uld?.rdy) {
              //     flight.ready_kg += parseFloat(value)
              //     flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              //   }
              // } else {
              //   if(awb?.man || (uld?.uld_no?.startsWith('C') && uld?.uld_no?.endsWith('#'))) {
              //     flight.ready_kg += parseFloat(value)
              //     flight.ready_lbs += weightCalculator(parseFloat(value), 'KGM')
              //   }
              // }

              if (awb?.location?.startsWith('0') && awb?.inbound && (awb?.prev_location !== null && awb?.prev_location !== 'UNKNOWN')) {
                flight.gate_kg += parseFloat(value)
                flight.gate_lbs += weightCalculator(parseFloat(value), 'KGM')
              }
              if (flight.build_plan.length > 0) {
                let build_plan = flight.build_plan.find((item) => item?.awb_no === awb?.awb_no)
                if (build_plan) {
                  if (build_plan.ulds.length > 0) {
                    let tpcs = 0
                    build_plan.ulds.map((uld) => {
                      tpcs += uld.pcs
                    })
                    flight.ulds_out[uindex].awbs_in[aindex].showred = awb.pcs === tpcs ? false : true
                  }
                }
              }
            }
          })
        }
      })
    }
    //CALCULATE COUNTDOWN TIMER
    let time_left = moment(flight.dept_time).diff(moment().tz(this.state.timezone), 'minutes')
    //SUBTRACT FPE
    time_left = time_left - (1 * (flight.fpe || 0))
    flight.time_to_dept = moment().add(time_left, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    //CALCULATE PAX CAPACITY, CONT TYPE, AFT AND FWS CONFIG.
    flight.pax_cap = 0
    flight.aft_conf = null
    flight.fwd_conf = null
    flight.cont_type = []
    flight.acpt_body = true
    if (this.state.fleetData?.length > 0) {
      let filter = this.state.fleetData.filter((item) => item?.details?.ac_code === flight?.ac_type)
      if (filter?.length > 0) {
        flight.fleet = filter[0]?.details?.fleet
        flight.pax_cap = filter[0]?.details?.pax_cap
        flight.aft_conf = filter[0]?.details?.aft_conf
        flight.fwd_conf = filter[0]?.details?.fwd_conf
        flight.cont_type = filter[0]?.details?.cont_type?.split(',') || []
        flight.acpt_body = filter[0]?.details?.ac_type === 'WB' ? true : false
      }
    }
    //CALC FLIGHT WEIGHT
    let unit = flight?.wgt?.unit
    let value = flight?.wgt?.value
    if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
      flight.weight_lbs = parseFloat(value) //ROUNDED TO NEAREST INTEGER
      flight.weight_kg = weightCalculator(parseFloat(value), 'LBS')
    } else if (unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
      flight.weight_kg = parseFloat(value) //ROUNDED TO NEAREST INTEGER
      flight.weight_lbs = weightCalculator(parseFloat(value), 'KGM')
    }
    //GET LAST UPDATED INFO
    flight.last_updated_by = ''
    flight.last_updated_at = ''
    if (flight?.last_updated?.length > 0) {
      let user_obj = this.state.userList?.find((item) => item?.id === parseInt(flight.last_updated[flight.last_updated.length - 1]?.user_id))
      flight.last_updated_by = `${user_obj?.name?.first_name} ${user_obj?.name?.last_name}` || ''
      flight.last_updated_at = moment.utc(flight.last_updated[flight.last_updated.length - 1]?.updated_date).tz(this.state.timezone).format(dateTimeFormat) || ''
    }
    //STORE ULDS FOR NOT TENDERED SUMMARY
    flight.not_tendered = {
      blk_count: 0,
      weight_lbs: 0,
      weight_kg: 0,
      pcs: 0,
      md: 0,
      udp: 0,
      ldc: 0,
      ldp: 0,
    }
    let notTenderedArr = []
    flight?.awb_segments?.filter(ar => {
      flight?.ulds_out?.find(uld => {
        if (!uld?.awbs_in?.find(awb => awb.awb_no === ar.awb_no)) {
          notTenderedArr.push(ar)
        }
      })
    })
    if (notTenderedArr?.length > 0) {
      let blk_count = 0
      let weight_lbs = 0
      let weight_kg = 0
      let pcs = 0

      notTenderedArr?.map((item) => {
        if (item?.space?.udp || item?.space?.ldc || item?.space?.ldp) {
          flight.not_tendered.udp = flight.not_tendered.udp + parseInt(item?.space?.udp || 0)
          flight.not_tendered.ldc = flight.not_tendered.ldc + parseInt(item?.space?.ldc || 0)
          flight.not_tendered.ldp = flight.not_tendered.ldp + parseInt(item?.space?.ldp || 0)
        } else {
          blk_count += 1
          pcs += parseFloat(item?.space?.vol || 0) || 0
          // if(item?.vol?.unit === 'FTQ') {
          //   pcs += parseFloat(item?.space?.vol || 0)
          // } else if(item?.vol?.unit === 'MTQ') {
          //   pcs += parseFloat((item?.space?.vol || 0) * 35.314667)
          // } else if(item?.vol?.unit === 'CF') {
          //   pcs += parseFloat(item?.space?.vol || 0)
          // }
          if (item.awb.weight_unit === 'L' || item.awb.weight_unit === 'LB' || item.awb.weight_unit === 'LBS' || item.awb.weight_unit === 'LBR') {
            weight_lbs += item.space.wgt //ROUNDED TO NEAREST INTEGER
            weight_kg += weightCalculator(item.space.wgt, 'LBS')
          } else if (item.awb.weight_unit === 'K' || item.awb.weight_unit === 'KG' || item.awb.weight_unit === 'KGS' || item.awb.weight_unit === 'KGM') {
            weight_kg += item.space.wgt //ROUNDED TO NEAREST INTEGER
            weight_lbs += weightCalculator(item.space.wgt, 'KGM')
          }
        }
      })
      flight.not_tendered.blk_count = blk_count
      flight.not_tendered.weight_lbs = weight_lbs
      flight.not_tendered.weight_kg = weight_kg
      flight.not_tendered.pcs = pcs?.toFixed(2) || 0
    }

    //GET ACTUAL ULD POSITIONS
    let ULDs = []
    flight.unique_ulds = []
    if (flight?.ulds_out?.length > 0) {
      flight.ulds_out.map((uld) => {
        uld.position_obj = this.state.uldMappingData?.find((item) => item?.uld_prefix === uld?.uld_no?.slice(0, 3) && item?.ac_types?.includes(flight?.ac_type)) || null
        ULDs.push(uld)
      })
    }
    //GET UNIQUE ULDS AND CALC ULD POSITIONS LOGIC
    flight.unique_ulds = [...new Map(ULDs.map(item => [item?.uld_no, item])).values()]
    let uldPositionsObj = this.calcULDPositions(flight)
    flight.actual = {
      MD: uldPositionsObj?.MD,
      P96: uldPositionsObj?.P96,
      P88: uldPositionsObj?.P88,
      LD3: uldPositionsObj?.LD3,
      LD2: uldPositionsObj?.LD2,
    }

    //FLIGHT TARE WEIGHT AND ADD TO READY WEIGHT
    flight.tare_wgt_kg = this.addTareWgt(flight)?.weight_kg || 0
    flight.tare_wgt_lbs = this.addTareWgt(flight)?.weight_lbs || 0
    flight.ready_kg += parseFloat(flight.tare_wgt_kg)
    flight.ready_lbs += parseFloat(flight.tare_wgt_lbs)
    flight.cccolour = ''
    flight.cccolour_css = ''
    let weight_assigned = this.state.weight_unit === 'KG' ? flight?.booked_kg : flight?.booked_lbs
    let weight_cargo = this.state.weight_unit === 'KG' ? flight?.weight_kg : flight?.weight_lbs
    if (!flight?.bag) {
      if (weight_cargo > 0) {
        if (weight_assigned > weight_cargo) {
          flight.cccolour_css = 'p-icon cc-icon fs-0 amber-cc' //SHOW YELLOW
          flight.cccolour = 'amber'
        } else {
          flight.cccolour_css = 'p-icon cc-icon fs-0 green-cc' //SHOW GREEN
          flight.cccolour = 'green'
        }
      } else {
        flight.cccolour_css = 'p-icon cc-icon fs-0' //SHOW BLUE
        flight.cccolour = 'blue'
      }
    } else if (flight?.bag && flight?.cargo && flight?.cargo?.md === 0 && flight?.cargo?.p96 === 0 && flight?.cargo?.p88 === 0 && flight?.cargo?.ld3 === 0 && flight?.cargo?.ld2 === 0) {
      if (weight_cargo > 0) {
        if (weight_assigned > weight_cargo) {
          flight.cccolour_css = 'p-icon cc-icon fs-0 amber-cc' //SHOW YELLOW
          flight.cccolour = 'amber'
        } else {
          flight.cccolour_css = 'p-icon cc-icon fs-0 green-cc' //SHOW GREEN
          flight.cccolour = 'green'
        }
      } else {
        flight.cccolour_css = 'p-icon cc-icon fs-0' //SHOW BLUE
        flight.cccolour = 'blue'
      }
    } else {
      if (((flight?.cargo?.md || 0) - (flight?.actual?.MD || 0)) < 0 ||
        ((flight?.cargo?.p96 || 0) - (flight?.actual?.P96 || 0)) < 0 ||
        ((flight?.cargo?.p88 || 0) - (flight?.actual?.P88 || 0)) < 0 ||
        ((flight?.cargo?.ld3 || 0) - (flight?.actual?.LD3 || 0)) < 0 ||
        ((flight?.cargo?.ld2 || 0) - (flight?.actual?.LD2 || 0)) < 0) {
        flight.cccolour_css = 'p-icon cc-icon fs-0 red-cc' //SHOW RED
        flight.cccolour = 'red'
      } else {
        if (weight_cargo > 0 && (weight_assigned > weight_cargo)) {
          flight.cccolour_css = 'p-icon fs-0 amber-cc' //SHOW YELLOW
          flight.cccolour = 'amber'
        } else if (((flight?.cargo?.md || 0) - (flight?.actual?.MD || 0)) > 0 ||
          ((flight?.cargo?.p96 || 0) - (flight?.actual?.P96 || 0)) > 0 ||
          ((flight?.cargo?.p88 || 0) - (flight?.actual?.P88 || 0)) > 0 ||
          ((flight?.cargo?.ld3 || 0) - (flight?.actual?.LD3 || 0)) > 0 ||
          ((flight?.cargo?.ld2 || 0) - (flight?.actual?.LD2 || 0)) > 0) {
          flight.cccolour_css = 'p-icon cc-icon fs-0 green-cc'
          flight.cccolour = 'green'
        } else {
          flight.cccolour_css = 'p-icon cc-icon fs-0' //SHOW Blue
          flight.cccolour = 'blue'
        }
      }
    }
    if (flight.answers && flight.answers.length > 0) {
      let greymilestones = []
      let ignoremilestones = []
      flight.answers.map((item, i) => {
        if (item.answers && item.answers.length > 0) {
          item.answers.map((answer, j) => {
            let question = this.state.allQuestionsData.find((q) => q.id === answer.question_id) || null
            flight.answers[i].answers[j].question = question
            // handle greymilestone for type 'yes/no'
            if (question?.details.type === 'yes/no' && question?.details.actionout && answer.answer === question?.details.actionout) {
              // for greymilestone
              if (question?.details?.actiontype === 'Turn Grey') {
                flight.answers[i].answers[j].greyon = true
                if (question?.details.actionms && question?.details.actionms.length > 0) {
                  question?.details.actionms.map((ms) => {
                    let indx = greymilestones.findIndex((item) => item === ms)
                    if (indx === -1) {
                      greymilestones.push(ms)
                    }
                  })
                }
              }
              // for ignore action performed
              else if (question?.details?.actiontype === 'Do Nothing') {
                flight.answers[i].answers[j].ignore = true
                if (question?.details.actionms && question?.details.actionms.length > 0) {
                  question?.details.actionms.map((ms) => {
                    let indx = ignoremilestones.findIndex((item) => item === ms)
                    if (indx === -1) {
                      ignoremilestones.push(ms)
                    }
                  })
                }
              }
            }
            // handle greymilestone for type mcq
            else if (question.details.type === 'mcq' && question?.details?.options && question?.details?.options?.length > 0) {
              let option = question?.details?.options.find((o) => o.name === answer.answer) || null
              if (option && option?.actionon) {
                // for greymilestone
                if (option?.actiontype === 'Turn Grey') {
                  flight.answers[i].answers[j].greyon = option?.actionon
                  if (option.actionms && option.actionms.length > 0) {
                    option.actionms.map((ms) => {
                      let indx = greymilestones.findIndex((item) => item === ms)
                      if (indx === -1) {
                        greymilestones.push(ms)
                      }
                    })
                  }
                }
                // for ignore action performed
                else if (option?.actiontype === 'Do Nothing') {
                  flight.answers[i].answers[j].ignore = option?.actionon
                  if (option.actionms && option.actionms.length > 0) {
                    option.actionms.map((ms) => {
                      let indx = ignoremilestones.findIndex((item) => item === ms)
                      if (indx === -1) {
                        ignoremilestones.push(ms)
                      }
                    })
                  }
                }
              }
            }
          })
        }
      })
      flight.greymilestones = greymilestones
      flight.ignoremilestones = ignoremilestones
    } else {
      flight.greymilestones = []
      flight.ignoremilestones = []
    }
    // console.log('Flight after', flight)
    return flight
  }

  //Build Milestones Columns
  generateMilestonesColumns = () => {
    let milestonesColumns = [
      {
        title: (<span className='test'>  Flight #  </span>),
        dataIndex: 'flight',
        key: 'flight',
        className: '',
        render: (_, record) => (
          <span>{record?.flight_no ? record.flight_no : 'N/A'}</span>
        )
      },
      {
        title: (<span className='test'>  Flight Date  </span>),
        dataIndex: 'date',
        key: 'date',
        className: '',
        render: (_, record) => (
          <span>{record.flight_date ? `${moment(record.flight_date).format('DDMMMYY')?.toUpperCase()}` : record.dept_date ? `${moment(record.dept_date).format('DDMMMYY')?.toUpperCase()}` : record?.dept_time ? `${moment(record.dept_time).format('DDMMMYY')?.toUpperCase()}` : 'N/A'}</span>
        )
      },
      {
        title: (<span className='test'>  Dept Time EST  </span>),
        dataIndex: 'DeptTimeEST',
        key: 'DeptTimeEST',
        className: 'est-col space-nowrap ',
        align: 'center',
        render: (_, record) => (
          <Space className=''>
            <span className='px-1 fs-10 pa-badge'>{record?.dept_prefix ? record?.dept_prefix : ''}</span>
            <span>{record?.dept_time ? `${moment.tz(record.dept_time, this.state.timezone).format(dateTimeFormat)?.toUpperCase()}` : 'N/A'}</span>
          </Space>
        )
      },
      {
        title: (<span className='test'>  AC Type  </span>),
        dataIndex: 'ACType',
        key: 'ACType',
        className: 'checklist-cols-1',
        render: (_, record) => (
          <span
            onClick={() => record?.info?.remark === 'created by User' ? this.setState({
              edit_flight_id: record?.id,
              flightModalType: 'EDIT',
              showFlightModal: true,
              flight_no: record?.flight_no,
              flight_date: moment(record?.flight_date),
              dept_time: moment.tz(record.dept_time, this.state.timezone),
              arr_time: moment.tz(record.arr_time, this.state.timezone).utc(),
              org_stn: cookies.get('station'),
              dest_stn: record?.dest_stn,
              aircrafttype: record?.ac_type,
              ack1: false,
              ack2: false,
            }) : null}
          >{record?.ac_type ? record.ac_type : 'N/A'}</span>
        )
      },
      {
        title: (<span className='test'>  ORG-DST  </span>),
        dataIndex: 'OrgDst',
        key: 'OrgDst',
        className: 'checklist-cols-1 min-width-80',
        align: 'center',
        render: (_, record) => (
          <span>{record?.org_stn + ' - ' + record?.dest_stn}</span>
        ),
        filteredValue: this.state.destFilters,
        filterDropdown: () => (
          <div className='fliter-dd'>
            {
              this.state.destStations?.map((item, index) => {
                return (
                  <Checkbox key={index} checked={this.state.destFilters?.includes(item)} value={item} onChange={(e) => this.handleFilterChange(e)} >{item}</Checkbox>
                )
              })
            }
          </div>
        ),
        onFilter: (value, record) => {
          return record?.dest_stn?.startsWith(value)
        }
      },
      {
        title: (<span className='test'>Action</span>),
        dataIndex: 'BuildPlan',
        key: 'BuildPlan',
        className: 'checklist-cols-1 min-width-80',
        align: 'center',
        render: (_, record) => (
          <div className='action-column'>
            <Button
              min={0}
              precision={0}
              controls={false}
              size='small'
              placeholder=''
              className='p-icon cc-icon checklist-bp-btn'
              onClick={() => this.setEdit(record)}
            >
              BP
            </Button>
            <Badge size='small' count={record?.checklist_notes_count || 0} color={'var(--primaryblue'} className='sm-badge'>
              <Tooltip
                title='Notes'
                overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                onClick={() => this.showFlightNotes(record)}
              >
                <img src={documentIcon} alt='document' />
              </Tooltip>
            </Badge>
          </div>
        )
      },
    ]
    if (this.state.milestoneData?.length > 0) {
      let dod = this.state.milestoneData.filter((item) => item?.type === 'Dod')
      let rebooking = this.state.milestoneData.filter((item) => item?.type === 'Rebookings')
      let noride = this.state.milestoneData.filter((item) => item?.type === 'No Rides')
      //Dod Section
      let dodchildren = []
      dod.map((item) => {
        !item.parent_id || (item.parent_id && item.showchild) ?
          dodchildren.push(
            {
              title: (<>
                <Tooltip title={item.title} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                  <div className='title-overflow'>{item.title}</div>
                </Tooltip>
                <Tooltip title={this.formatCutoff(item.cutoff)} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                  <div className='title-overflow pt-1'>({this.formatCutoff(item.cutoff)})</div>
                </Tooltip>
              </>),
              dataIndex: 'BuildPlanSubmittedToWH',
              key: 'BuildPlanSubmittedToWH',
              className: 'checklist-cols color-cols',
              render: (_, record) => {
                return (
                  <div
                    className={this.checkStatusColour((record?.dept_date ?
                      moment.utc(record.dept_date).tz(this.state.timezone).subtract(this.getCutoff(item.cutoff, record), 'hours') : null),
                    this.getAct(record, item.id, 'YYYY-MM-DD HH:mm'), item.amber, record?.flight_leg, item, record.greymilestones, record.ignoremilestones
                    )}
                    onClick={() => this.showQuizModal(record, item)} >
                    {`SKD ${record?.dept_date ?
                      moment.utc(record.dept_date).tz(this.state.timezone).subtract(this.getCutoff(item.cutoff, record), 'hours').format('HH:mm') :
                      'N/A'} 
                      ACT ${['bg-grey', 'bg-red'].includes(this.checkStatusColour((record?.dept_date ?
                    moment.utc(record.dept_date).tz(this.state.timezone).subtract(this.getCutoff(item.cutoff, record), 'hours') : null),
                  this.getAct(record, item.id, 'YYYY-MM-DD HH:mm'), item.amber, record?.flight_leg, item, record.greymilestones, record.ignoremilestones
                  )) ? 'N/A' : this.getAct(record, item.id, 'HH:mm') || 'N/A'}`
                      // && this.getCountdown(record?.dept_date, this.getAct(record, item.id, 'YYYY-MM-DD HH:mm'), item.cutoff, item.amber)
                    }
                    <span className='countdown'>{this.getCountdown(record?.dept_date, this.getAct(record, item.id, 'YYYY-MM-DD HH:mm'), item.cutoff, item.amber)}</span>
                  </div>
                )
              }
            }

          )
          : null

      })
      let dodobj = {
        title: 'DoD MileStones',
        dataIndex: 'DoDMileStones',
        key: 'DoDMileStones',
        className: 'main-head',
        children: dodchildren,
      }
      milestonesColumns.push(dodobj)

      //Rebookings Section
      let rbchildren = []
      rebooking.map((item) => {
        rbchildren.push(
          {
            title: (<>
              <Tooltip title={item.title} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                <div className='title-overflow'>{item.title}</div>
              </Tooltip>
              <Tooltip title={this.formatCutoff(item.cutoff)} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                <div className='title-overflow pt-1'>
                  ({this.formatCutoff(item.cutoff)})
                </div>
              </Tooltip>
            </>),
            dataIndex: 'BuildPlanSubmittedToWH',
            key: 'BuildPlanSubmittedToWH',
            className: 'checklist-cols color-cols',
            render: (_, record) => {
              return (
                <div
                  className={this.checkStatusColour((record?.dept_date ?
                    moment.utc(record.dept_date).tz(this.state.timezone).subtract(this.getCutoff(item.cutoff, record), 'hours') : null),
                  this.getAct(record, item.id, 'YYYY-MM-DD HH:mm'), item.amber, record?.flight_leg, item, record.greymilestones, record.ignoremilestones
                  )}
                  onClick={() => this.showQuizModal(record, item)} >
                  {`SKD ${record?.dept_date ?
                    moment.utc(record.dept_date).tz(this.state.timezone).subtract(this.getCutoff(item.cutoff, record), 'hours').format('HH:mm') :
                    'N/A'} 
                    ACT ${['bg-grey', 'bg-red'].includes(this.checkStatusColour((record?.dept_date ?
                  moment.utc(record.dept_date).tz(this.state.timezone).subtract(this.getCutoff(item.cutoff, record), 'hours') : null),
                this.getAct(record, item.id, 'YYYY-MM-DD HH:mm'), item.amber, record?.flight_leg, item, record.greymilestones, record.ignoremilestones
                )) ? 'N/A' : this.getAct(record, item.id, 'HH:mm') || 'N/A'}`
                  }
                </div>
              )
            }
          }
        )
      })
      let rbobj = {
        title: 'Rebookings',
        dataIndex: 'Rebookings',
        key: 'Rebookings',
        children: rbchildren,
        className: 'main-head',
      }
      milestonesColumns.push(rbobj)

      //No Ride Section
      let nrchildren = []
      noride.map((item) => {
        nrchildren.push(
          {
            title: (<>
              <Tooltip title={item.title} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                <div className='title-overflow'>{item.title}</div>
              </Tooltip>
              <Tooltip title={this.formatCutoff(item.cutoff)} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                <div className='title-overflow pt-1'>({this.formatCutoff(item.cutoff)})</div>
              </Tooltip></>),
            dataIndex: 'BuildPlanSubmittedToWH',
            key: 'BuildPlanSubmittedToWH',
            className: 'checklist-cols color-cols',
            render: (_, record) => {
              return (
                <div
                  className={this.checkStatusColour((record?.dept_date ?
                    moment.utc(record.dept_date).tz(this.state.timezone).subtract(this.getCutoff(item.cutoff, record), 'hours') : null),
                  (record?.noride?.updatedAt ? moment(record?.noride?.updatedAt).format('YYYY-MM-DD HH:mm') : null), null, record?.flight_leg, item, record.greymilestones, record.ignoremilestones)
                  }
                  onClick={() => this.setState({
                    // openNoRideModal: true,
                    quizModalFlight: cloneDeep(record),
                    noride_flight: record.id,
                    noRideModalType: 'NORIDE',
                    noRideTitle: 'Send No-Ride Alert',
                  }, () => this.fetchNoride())} >
                  {`SKD ${record?.dept_date ?
                    moment.utc(record.dept_date).tz(this.state.timezone).subtract(this.getCutoff(item.cutoff, record), 'hours').format('HH:mm') :
                    'N/A'} 
                    ACT ${['bg-grey', 'bg-red'].includes(this.checkStatusColour((record?.dept_date ?
                  moment.utc(record.dept_date).tz(this.state.timezone).subtract(this.getCutoff(item.cutoff, record), 'hours') : null),
                this.getAct(record, item.id, 'YYYY-MM-DD HH:mm'), item.amber, record?.flight_leg, item, record.greymilestones, record.ignoremilestones
                )) ? 'N/A' : (record?.noride?.updatedAt ? moment(record?.noride?.updatedAt).format('HH:mm') : 'N/A') || 'N/A'}`
                  }
                </div>
              )
            }
          }
        )
      })
      let nrobj = {
        title: 'No Rides',
        dataIndex: 'No Rides',
        key: 'No Rides',
        align: 'center',
        children: nrchildren,
        className: 'main-head',
      }
      milestonesColumns.push(nrobj)
    }
    milestonesColumns.push(
      {
        title: 'LDD',
        dataIndex: 'LDD',
        align: 'center',
        key: 'LDD',
        className: 'checklist-cols main-head',
        children: [
          {
            title: (<span className=''>  Interacted with LDD  </span>),
            dataIndex: 'LDD',
            key: 'LDD',
            align: 'center',
            className: 'checklist-cols-1 min-width-80 th-top',
            render: (_, record) => (
              <div
                onClick={() => this.setState({
                  pallets_paired: record?.ldd?.pallets_paired || null,
                  pallets_paired_contact: record?.ldd?.pallets_paired_contact || null,
                  pallets_paired_explain: record?.ldd?.pallets_paired_explain || null,
                  conveyances_staged: record?.ldd?.conveyances_staged || null,
                  conveyances_staged_contact: record?.ldd?.conveyances_staged_contact || null,
                  conveyances_staged_explain: record?.ldd?.conveyances_staged_explain || null,
                  openLDDModal: true,
                  quizModalFlight: cloneDeep(record),
                })}
              >
                {this.getLDDStatus(record)}
              </div>
            )
          }
        ]
      },
      {
        title: 'Audit',
        dataIndex: 'Audit',
        align: 'center',
        key: 'Audit',
        className: 'checklist-cols main-head',
        children: [
          {
            title: (<span className='test'>  Flight Audit  </span>),
            dataIndex: 'FlightAudit',
            key: 'FlightAudit',
            align: 'center',
            className: 'checklist-cols-1 th-top',
            render: (_, record) => (
              <div
                onClick={() => this.setState({
                  // openNoRideModal: true,
                  quizModalFlight: cloneDeep(record),
                  noride_flight: record.id,
                  noRideModalType: 'AUDIT',
                  noRideTitle: 'Flight Audit',
                  bin_config: record?.audit?.bin_config ? record?.audit?.bin_config : (record?.cargo ? `PMC:- ${record?.cargo?.p96}, PAG:- ${record?.cargo?.p88}, LD2:- ${record?.cargo?.ld2}, LD3:- ${record?.cargo?.ld3}` : ''),
                  audit_built_right: record?.audit?.audit_built_right,
                  audit_reason: record?.audit?.audit_reason,
                  audit_explain: record?.audit?.audit_explain,
                  audit_was_optimal: record?.audit?.audit_was_optimal,
                  audit_meet_wgt_goal: record?.audit?.audit_meet_wgt_goal,
                  audit_attachment: record?.audit?.audit_attachment,
                  load_trac_conf: record.audit?.load_trac_conf ? JSON.stringify(record.audit?.load_trac_conf) : null,
                  load_trac_conf_fileList: record.audit?.load_trac_conf?.name ? [{ name: record.audit?.load_trac_conf?.name }] : [],
                  load_trac_conf_previewImg: record.audit?.load_trac_conf?.path || null,
                  build_plan_conf: record.audit?.build_plan_conf ? JSON.stringify(record.audit?.build_plan_conf) : null,
                  build_plan_conf_fileList: record.audit?.build_plan_conf?.name ? [{ name: record.audit?.build_plan_conf?.name }] : [],
                  build_plan_conf_previewImg: record.audit?.build_plan_conf?.path || null,
                  wandb_conf: record.audit?.wandb_conf ? JSON.stringify(record.audit?.wandb_conf) : null,
                  wandb_conf_fileList: record.audit?.wandb_conf?.name ? [{ name: record.audit?.wandb_conf?.name }] : [],
                  wandb_conf_previewImg: record.audit?.wandb_conf?.path || null

                }, () => this.fetchNoride())}
              >
                {this.getAuditStatus(record)}
              </div>
            )
          }
        ]
      }
    )
    return milestonesColumns
  }

  handleOptiUldChange = (e) => {
    let optiUld = this.state.optiUld
    optiUld[e.target.name] = e.target.value
    this.setState({ optiUld })
  }

  handleEditOptiUldChange = (e) => {
    let editOptiUld = this.state.editOptiUld
    editOptiUld[e.target.name] = e.target.value
    this.setState({ editOptiUld })
  }

  addOptiData = () => {
    let obj = {
      uld_no: this.state.optiUld?.uld_no,
      type: this.state.optiUld?.type,
      remark: this.state.optiUld?.remark,
      stn: cookies.get('station')
    }
    if (obj.type) {
      Http.post(`/build_uld/${this.state.editRecord.id}`, obj).then(() => {
        this.setState({ 
          optiUld: {},
          switch: false
        })
      })
    }
  }

  updateOptiData = () => {
    let obj = {
      id: this.state.editOptiUld?.id,
      uld_no: this.state.editOptiUld?.uld_no,
      type: this.state.editOptiUld?.type,
      remark: this.state.editOptiUld?.remark,
      stn: cookies.get('station')
    }
    if (obj.type && obj.id) {
      Http.put(`/build_uld/${this.state.editRecord.id}`, obj).then(() => {
        this.setState({ editOptiUld: {} })
      })
    }
  }

  removeOptiData = (id) => {
    Http.put(`/build_uld_delete/${this.state.editRecord.id}`, {
      id,
      stn: cookies.get('station')
    })
  }

  editOptiData = (record) => {
    this.setState({ editOptiUld: record })
  }

  clearOptiData = () => {
    this.setState({ editOptiUld: {} })
  }

  showQuizModal = (record, item) => {
    let milestoneData = this.state.milestoneData
    const order = ['Dod', 'Rebookings', 'No Rides']
    // get 'Freight at Gate' record id
    let freightAtGateId = milestoneData.find((item) => item?.title.includes('Freight at Gate'))?.id
    milestoneData = milestoneData.sort((a, b) => {
      return order.indexOf(a.type) - order.indexOf(b.type)
    })
    let current = null
    let prevprev = null
    let prev = null
    milestoneData.map((item2) => {
      if (item2?.id === item?.id) {
        current = item2
      } else if (!current) {
        if (freightAtGateId === item2?.id) {
          prevprev = prev
        }
        prev = item2
      }
    })
    let found = false
    if (prev) {
      if (record.answers.find((rec) => rec?.milestones_id === prev?.id)) {
        found = true
      }
    }
    if (prevprev) {
      if (record.answers.find((rec) => rec?.milestones_id === prevprev?.id) && found) {
        found = true
      } else {
        found = false
      }
    }
    if (!prev || (prev && found) || item?.title.includes('Freight at Gate')) {
      this.setState({
        // openQuizModal: true,
        quizMilestonid: item.id,
        quizModalFlight: cloneDeep(record),
        checklistTitle: item.title,
        questionsData: [],
        tempquestionsData: []
      }, () => this.fetchQuestions(record.id, item.id, false))
    } else {
      notification.destroy()
      notification.warning({
        message: 'Warning',
        description: 'Please complete previous milestone to proceed',
      })
    }
  }

  getAuditStatus = (record) => {
    let audit_submitted = record?.audit ? 'Yes' : 'No'
    let audit_built_right = record?.audit?.audit_built_right || null
    let audit_was_optimal = record?.audit?.audit_was_optimal || null
    let audit_meet_wgt_goal = record?.audit?.audit_meet_wgt_goal || null
    let noridelen = record?.norides?.length > 0 ? 'greater' : 'zero'
    let audit_row = audit_status.find((item) =>
      item?.audit_submitted == audit_submitted
      && item?.planned_accordingly === audit_built_right
      && item?.optimal === audit_was_optimal
      && item?.meet_wgt_goal === audit_meet_wgt_goal
      && item?.num_of_rides === noridelen
    )
    if (!audit_row && noridelen === 'zero') {
      return (
        <MehOutlined
          style={{
            fontSize: 'large',
            color: 'grey',
            cursor: 'pointer'
          }}
          min={0}
          precision={0}
          controls={false}
          className='text-center'
          size='large'
          placeholder=''
        />
      )
    } else if (audit_row?.emote === 'MehOutlined') {
      return (
        <MehOutlined
          style={{
            fontSize: 'large',
            color: audit_row.color,
            cursor: 'pointer'
          }}
          min={0}
          precision={0}
          controls={false}
          className='text-center'
          size='large'
          placeholder=''
        />
      )
    } else if (audit_row?.emote === 'SmileOutlined') {
      return (
        <SmileOutlined
          style={{
            fontSize: 'large',
            color: audit_row.color,
            cursor: 'pointer'
          }}
          min={0}
          precision={0}
          controls={false}
          className='text-center'
          size='large'
          placeholder=''
        />
      )
    } else if (audit_row?.emote === 'FrownOutlined') {
      return (
        <FrownOutlined
          style={{
            fontSize: 'large',
            color: audit_row.color,
            cursor: 'pointer'
          }}
          min={0}
          precision={0}
          controls={false}
          className='text-center'
          size='large'
          placeholder=''
        />
      )
    }
  }

  getLDDStatus = (record) => {
    let pallets_paired = record?.ldd?.pallets_paired || null
    let pallets_paired_contact = record?.ldd?.pallets_paired_contact || null
    let conveyances_staged = record?.ldd?.conveyances_staged || null
    let conveyances_staged_contact = record?.ldd?.conveyances_staged_contact || null

    let ldd_row = ldd_status.find((item) =>
      item?.pallets_paired === pallets_paired
      && item?.pallets_paired_contact === pallets_paired_contact
      && item?.conveyances_staged === conveyances_staged
      && item?.conveyances_staged_contact === conveyances_staged_contact
    )
    if (ldd_row) {
      if (ldd_row?.emote === 'MehOutlined') {
        return (
          <MehOutlined
            style={{
              fontSize: 'large',
              color: ldd_row.color,
              cursor: 'pointer'
            }}
            min={0}
            precision={0}
            controls={false}
            className='text-center'
            size='large'
            placeholder=''
          />
        )
      } else if (ldd_row?.emote === 'SmileOutlined') {
        return (
          <SmileOutlined
            style={{
              fontSize: 'large',
              color: ldd_row.color,
              cursor: 'pointer'
            }}
            min={0}
            precision={0}
            controls={false}
            className='text-center'
            size='large'
            placeholder=''
          />
        )
      } else if (ldd_row?.emote === 'FrownOutlined') {
        return (
          <FrownOutlined
            style={{
              fontSize: 'large',
              color: ldd_row.color,
              cursor: 'pointer'
            }}
            min={0}
            precision={0}
            controls={false}
            className='text-center'
            size='large'
            placeholder=''
          />
        )
      }
    } else {
      return (
        <MehOutlined
          style={{
            fontSize: 'large',
            color: 'grey',
            cursor: 'pointer'
          }}
          min={0}
          precision={0}
          controls={false}
          className='text-center'
          size='large'
          placeholder=''
        />
      )
    }
  }

  formatCutoff = (cutoff) => {
    let str = ''
    if (typeof cutoff === 'string' && cutoff.includes(',')) {
      let arr = cutoff.split(',')
      if (arr.length === 2) {
        str = `D-${(parseInt(arr[0]) / 60).toFixed(2)} Hrs Intl, D-${(parseInt(arr[1]) / 60).toFixed(2)} Hrs Dom`
        str = str.replace(/,/g, ',\n')
      } else if (arr.length === 3) {
        str = `D-${(parseInt(arr[0]) / 60).toFixed(2)} Hrs Intl, D-${(parseInt(arr[1]) / 60).toFixed(2)} Hrs Dom, D-${(parseInt(arr[2]) / 60).toFixed(2)} Hrs Hawaii `
        str = str.replace(/,/g, ',\n')
      }
    } else {
      str = `D-${(parseInt(cutoff) / 60).toFixed(2)} Hrs`
    }
    return str
  }
  getCutoff = (cutoff, flight) => {
    let Hawaii = ['HNL', 'OGG', 'KOA', 'MKK']
    if (typeof cutoff === 'string' && cutoff.includes(',')) {
      let arr = cutoff.split(',')
      let details = this.state.stationsData?.find((stn) => stn?.code === flight?.dest?.arriv) || ''
      if (details) {
        if (Hawaii.includes(flight?.dest?.arriv?.toUpperCase()) && arr.length === 3) {
          return (parseInt(arr[2]) / 60).toFixed(2)
        } else if (country.includes(details?.country?.toUpperCase())) {
          return (parseInt(arr[1]) / 60).toFixed(2)
        } else {
          return (parseInt(arr[0]) / 60).toFixed(2)
        }
      }
    } else {
      return (parseInt(cutoff) / 60).toFixed(2)
    }
  }
  //End of Checklist APIs

  //LOAD PLAN MODAL JSX
  loadPlanModal = () => {
    const planLoadColumns = [
      {
        title: '',
        dataIndex: 'pos',
        key: 'pos',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <InputNumber
              min={0}
              precision={0}
              controls={false}
              size='small'
              placeholder=''
              value={this.state.editTPos || ''}
              onChange={(e) => this.setState({ editTPos: e })}
            />
            : <span>{record?.pos}</span>)
      },
      {
        title: '',
        dataIndex: 'type',
        key: 'type',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Select size='small'
              placeholder='Select process'
              className='w-100'
              value={this.state.editTType || ''}
              onChange={(e) => this.setState({ editTType: e })}
            >
              <Option value='P96'>PMC</Option>
              <Option value='P88'>PAG</Option>
              <Option value='LD3'>LD3</Option>
              <Option value='LD2'>LD2</Option>
              <Option value='MD'>MD</Option>
            </Select>
            : <span>{record?.type}</span>)
      },
      {
        title: '',
        dataIndex: 'work_area',
        key: 'work_area',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Select size='small'
              placeholder='Select process'
              className='w-100'
              value={this.state.editTWorkArea || ''}
              onChange={(e) => this.setState({ editTWorkArea: e })}
            >
              <Option value='International'>International</Option>
              <Option value='Dash'>Dash</Option>
              <Option value='Breezeway'>Breezeway</Option>
              <Option value='T-Point'>T-Point</Option>
            </Select>
            : <span>{record?.work_area}</span>)
      },
      {
        title: '',
        dataIndex: 'uld_awb_no',
        key: 'uld_awb_no',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTULDAWB || ''}
              onChange={(e) => this.setState({ editTULDAWB: e.target.value?.toUpperCase() })}
            />
            : <span>{record?.uld_awb_no}</span>)
      },
      {
        title: '',
        dataIndex: 'prod_code',
        key: 'prod_code',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTProd || ''}
              onChange={(e) => this.setState({ editTProd: e.target.value?.toUpperCase() })}
            />
            : <span>{record?.prod_code}</span>)
      },
      {
        title: '',
        dataIndex: 'plan_comm',
        key: 'plan_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTComm1 || ''}
              onChange={(e) => this.setState({ editTComm1: e.target.value })}
            />
            : <span>{record?.comm1}</span>)
      },
      {
        title: '',
        dataIndex: 'dep_comm',
        key: 'dep_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTComm2 || ''}
              onChange={(e) => this.setState({ editTComm2: e.target.value })}
            />
            : <span>{record?.comm2}</span>)
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        width: '10%',
        render: (_, record) =>
          <div>
            {this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.submit('edit')} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearAllocTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.editAllocTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.setState({ deleteAllocID: record.id }, () => this.submit('delete'))} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    const CCcolumns = [
      {
        title: 'DOD Team Comments',
        dataIndex: 'plan_comm',
        key: 'plan_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              disabled={this.disableNote(this.state.editRecord?.dept_time)}
              size='small'
              placeholder=''
              value={this.state.editTComm1 || ''}
              onChange={(e) => this.setState({ editTComm1: e.target.value })}
            />
            : <span>{record?.comm1}</span>)
      },
      {
        title: 'Warehouse Team Comments',
        dataIndex: 'dep_comm',
        key: 'dep_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTComm2 || ''}
              onChange={(e) => this.setState({ editTComm2: e.target.value })}
              disabled
            />
            : <span>{record?.comm2}</span>)
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <div className='action-column'>
              <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.submit('edit')} ><img src={Update} alt='Save' /></Tooltip>
              <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearAllocTableForm()} ><span className='form-clear'>x</span></Tooltip>
            </div>
            :
            <div className='action-column'>
              {/* <Tooltip title='Attachment'>
                <Badge size='small' count={2}>
                  <Button type='text' shape='circle' className='clip' icon={<PaperClipOutlined />} />
                </Badge>
              </Tooltip> */}
              <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.editAllocTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
              <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.setState({ deleteAllocID: record.id }, () => this.submit('delete'))} okText='Yes' cancelText='No'>
                <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
              </Popconfirm>
            </div>
        ),
      },
    ]

    const get_bin_config = () => {
      let p_pmc = 0, p_pag = 0, p_ld2 = 0, p_ld3 = 0
      let Hint = ''
      let className = ''
      if (this.state.editRecord?.build_uld?.length > 0) {
        this.state.editRecord?.build_uld?.map((item) => {
          if (item?.type === 'PMC') {
            p_pmc++
          }
          if (item?.type === 'PAG') {
            p_pag++
          }
          if (item?.type === 'LD2' || item?.type === 'DPA' || item?.type === 'DPE' || item?.type === 'DPL' || item?.type === 'DPN') {
            p_ld2++
          }
          if (item?.type === 'LD3' || item?.type === 'AKE' || item?.type === 'AKN' || item?.type === 'AKX' || item?.type === 'AKY' || item?.type === 'PLA' || item?.type === 'FLA' || item?.type === 'PLB') {
            p_ld3++
          }
        })
        if (!(this.state.editCargoP96 || this.state.editRecord?.cargo?.p96) && !(this.state.editCargoP88 || this.state.editRecord?.cargo?.p88) && !(this.state.editCargoLD2 || this.state.editRecord?.cargo?.ld2) && !(this.state.editCargoLD3 || this.state.editRecord?.cargo?.ld3)) {
          Hint = 'Max Bin Configuration missing'
          className = 'table-row-warning'
        } else {
          if (((this.state.editCargoP96 || this.state.editRecord?.cargo?.p96 || 0) >= p_pmc) && ((this.state.editCargoP88 || this.state.editRecord?.cargo?.p88 || 0) >= p_pag) && ((this.state.editCargoLD2 || this.state.editRecord?.cargo?.ld2 || 0) >= p_ld2) && ((this.state.editCargoLD3 || this.state.editRecord?.cargo?.ld3 || 0) >= p_ld3)) {
            if (this.state.editRecord?.fleet == '763' || this.state.editRecord?.fleet == '764') {
              if (p_ld3 == 0) {
                Hint = 'Optimal Bin Configuration'
                className = 'table-row-success'
              } else {
                Hint = 'Suboptimal Bin Configuration'
                className = 'table-row-danger'
              }
            }
            if (this.state.editRecord?.fleet == '332' && p_pmc > 0) {
              if (p_pmc % 2 == 0) {
                Hint = 'Optimal Bin Configuration'
                className = 'table-row-success'
              } else {
                Hint = 'Suboptimal Bin Configuration (The sum of PMC\'s and PAG\'s is not even)'
                className = 'table-row-danger'
              }
            }
            if (this.state.editRecord?.fleet == '333' && (p_pmc > 0 || p_pag > 0)) {
              // eslint-disable-next-line no-undef
              console.log('333', p_pmc, p_pag, (p_pmc + p_pag) % 2)
              let sub_opti = false
              if ((p_pmc + p_pag) % 2 != 0) {
                sub_opti = '(The sum of PMC\'s and PAG\'s is not even)'
              }
              if (!(p_pmc <= 6 && p_pag <= 2)) {
                sub_opti = '(Doesn\'t meet the criteria)'
              }
              // eslint-disable-next-line no-undef
              // console.log(sub_opti)
              if(sub_opti)
              {
                Hint = `Suboptimal Bin Configuration ${sub_opti}`
                className = 'table-row-danger'
              } else {
                Hint = 'Optimal Bin Configuration'
                className = 'table-row-success'
              }
            }
            if (this.state.editRecord?.fleet == '339' && (p_pmc > 0 || p_pag > 0)) {
              let sub_opti = false
              if ((p_pmc + p_pag) % 2 != 0) {
                sub_opti = '(The sum of PMC\'s and PAG\'s is not even)'
              }
              if (!((p_pmc == 6 && p_pag == 0) || (p_pmc <= 5 && p_pag <= 1))) {
                sub_opti = '(Doesn\'t meet the criteria)'
              }
              if (sub_opti) {
                Hint = `Suboptimal Bin Configuration ${sub_opti}`
                className = 'table-row-danger'
              } else {
                Hint = 'Optimal Bin Configuration'
                className = 'table-row-success'
              }
            }
            if (this.state.editRecord?.fleet == '359' && (p_pmc > 0 || p_pag > 0)) {
              let sub_opti = false
              if ((p_pmc + p_pag) % 2 != 0) {
                sub_opti = '(The sum of PMC\'s and PAG\'s is not even)'
              }
              if (!((p_pmc == 8 && p_pag == 0) || (p_pmc == 6 && p_pag == 0) || (p_pmc <= 5 && p_pag <= 1))) {
                sub_opti = '(Doesn\'t meet the criteria)'
              }
              if (sub_opti) {
                Hint = `Suboptimal Bin Configuration ${sub_opti}`
                className = 'table-row-danger'
              } else {
                Hint = 'Optimal Bin Configuration'
                className = 'table-row-success'
              }
            }
          } else {
            Hint = 'Planned exceeds Max bin configuration'
            className = 'table-row-warning'
          }
        }
      }

      return [
        {
          key: '1',
          empty: 'Max Bin Configuration',
          PMC: this.state.editCargoP96 || this.state.editRecord?.cargo?.p96 || 0,
          PAG: this.state.editCargoP88 || this.state.editRecord?.cargo?.p88 || 0,
          LD2: this.state.editCargoLD2 || this.state.editRecord?.cargo?.ld2 || 0,
          LD3: this.state.editCargoLD3 || this.state.editRecord?.cargo?.ld3 || 0,
          Hint: this.state.hints || this.state.editRecord?.cargo?.hint,
          Notes: this.state.notes || this.state.editRecord?.cargo?.note,
        },
        {
          key: '2',
          empty: 'Planned Bin Configuration',
          PMC: p_pmc,
          PAG: p_pag,
          LD2: p_ld2,
          LD3: p_ld3,
          Hint: Hint,
          Notes: '-',
          className
        },
        // {
        //   key: '3',
        //   empty: 'Actual Bin Configuration',
        //   PMC: this.state.editRecord?.actual?.P96,
        //   PAG: this.state.editRecord?.actual?.P88,
        //   LD2: this.state.editRecord?.actual?.LD2,
        //   LD3: this.state.editRecord?.actual?.LD3,
        //   Hint: '-',
        //   Notes: '-',
        // }
      ]
    }

    // const configData = [
    //   {
    //     key: '1',
    //     empty: 'Max Bin Configuration',
    //     PMC: this.state.editCargoP96 || this.state.editRecord?.cargo?.p96 || 0,
    //     PAG: this.state.editCargoP88 || this.state.editRecord?.cargo?.p88 || 0,
    //     LD2: this.state.editCargoLD2 || this.state.editRecord?.cargo?.ld2 || 0,
    //     LD3: this.state.editCargoLD3 || this.state.editRecord?.cargo?.ld3 || 0,
    //     Hint: this.state.hints || this.state.editRecord?.cargo?.hint,
    //     Notes: this.state.notes || this.state.editRecord?.cargo?.note,
    //   },
    //   {
    //     key: '2',
    //     empty: 'Planned Bin Configuration',
    //     PMC: function (){
    //       let count = 0
    //       if(this.state.editRecord?.build_uld?.length > 0){
    //         this.state.editRecord?.build_uld?.map((item)=>{
    //           if(item?.type === 'PMC'){
    //             count++
    //           }
    //         })
    //       }
    //       return count
    //     },
    //     PAG: this.state.editRecord?.actual?.P88,
    //     LD2: this.state.editRecord?.actual?.LD2,
    //     LD3: this.state.editRecord?.actual?.LD3,
    //     Hint: '-',
    //     Notes: '-',
    //   },
    //   // {
    //   //   key: '3',
    //   //   empty: 'Actual Bin Configuration',
    //   //   PMC: this.state.editRecord?.actual?.P96,
    //   //   PAG: this.state.editRecord?.actual?.P88,
    //   //   LD2: this.state.editRecord?.actual?.LD2,
    //   //   LD3: this.state.editRecord?.actual?.LD3,
    //   //   Hint: '-',
    //   //   Notes: '-',
    //   // }
    // ]

    const configColumns = [
      {
        title: '',
        dataIndex: 'empty',
        key: 'empty',
        className: 'min-width-80',
        width: '10%',
        // render: (_, record, index) => (
        //   <span>{index === 0? 'Optimal Configuration': 'Final Configuration'}</span>
        // )
      },
      {
        title: (<span className='test'>  PMC </span>),
        dataIndex: 'PMC',
        key: 'PMC',
        align: 'center',
        className: 'min-width-40',
        width: '10%',
        // render: (_, record) => (
        //   <span>{record?.cargo?.hint}</span>
        // )
      },
      {
        title: (<span className='test'>  PAG </span>),
        dataIndex: 'PAG',
        key: 'PAG',
        align: 'center',
        className: 'min-width-40',
        width: '10%',
        // render: (_, record) => (
        //   <span>{record?.cargo?.hint}</span>
        // )
      },
      {
        title: (<span className='test'>  LD2 </span>),
        dataIndex: 'LD2',
        key: 'LD2',
        align: 'center',
        className: 'min-width-40',
        width: '10%',
        // render: (_, record) => (
        //   <span>{record?.cargo?.ld2}</span>
        // )
      },
      {
        title: (<span className='test'>  LD3 </span>),
        dataIndex: 'LD3',
        key: 'LD3',
        align: 'center',
        className: 'min-width-40',
        width: '10%',
        // render: (_, record) => (
        //   <span>{record?.cargo?.ld3}</span>
        // )
      },
      //   {
      //     title: (<span className='test'>  LD4/8/FQA </span>),
      //     dataIndex: 'LD4',
      //     key: 'LD4',
      //     className: 'checklist-cols-1'
      //   },
      {
        title: (<span className='test'>  Hint </span>),
        // dataIndex: 'Hint',
        key: 'Hint',
        align: 'center',
        className: 'min-width-100',
        render: (_, record) => {
          const obj = {
            children: record.Hint,
            props: {},
          }
          if (record.key != 1) {
            obj.props.colSpan = 2
            obj.children = <span>{record.Hint}</span>
          }
          return obj
        }
      },
      {
        title: (<span className='test'>  Notes </span>),
        dataIndex: 'Notes',
        // key: 'Notes',
        align: 'center',
        className: 'min-width-100',
        render: (_, record) => {
          const obj = {
            children: record.Notes,
            props: {},
          }
          if (record.key > 1) {
            obj.props.rowSpan = 0
          }
          return obj
        }
      },
    ]

    const optiColumns = [
      {
        title: 'ULD Type',
        dataIndex: 'type',
        key: 'type',
        className: 'min-width-100',
        width: '20%',
        render: (_, record, index) => (
          this.state.editOptiUld && this.state.editOptiUld?.id === record?.id ?
            <Select
              value={this.state.editOptiUld?.type}
              size='small'
              name='type'
              className='text-center w-100'
              onChange={(e) => this.handleEditOptiUldChange({
                target: {
                  name: 'type',
                  value: e
                }
              })}
            >
              {const_ulds?.map((c_u, index) => <Option key={index} value={c_u}>{c_u}</Option>)}
            </Select>
            :
            record.type ? <Tag color={uld_colors[index].name}>{record.type}</Tag> : '-'
        )
      },
      {
        title: 'ULD #',
        dataIndex: 'number',
        key: 'number',
        className: 'min-width-100',
        width: '20%',
        render: (_, record) => (
          this.state.editOptiUld && this.state.editOptiUld?.id === record?.id ?
            <Input
              size='small'
              placeholder=''
              name='uld_no'
              value={this.state.editOptiUld?.uld_no}
              onChange={this.handleEditOptiUldChange}
            />
            :
            record.uld_no ? record.uld_no : '-'
        )
      },
      {
        title: 'Remarks/Pri #',
        dataIndex: 'remarks',
        key: 'remarks',
        className: 'min-width-100',
        width: '20%',
        render: (_, record) => {
          let pri = []
          if (this.state.editRecord && this.state.editRecord?.build_plan && this.state.editRecord?.build_plan?.length > 0) {
            this.state.editRecord?.build_plan?.map((item) => {
              if (item?.ulds && item?.ulds?.length > 0) {
                item?.ulds?.map((uld) => {
                  if (uld.id && uld.id == record.id && uld.remark) {
                    pri.push(uld.remark)
                  }
                }
                )
              }
            })
          }
          // sort pri
          // console.log(record, pri)
          pri.sort((a, b) => a - b)
          return (
            <span>{pri && pri?.[0] ? pri[0] : ('')}</span>
          )
        }
      },
      {
        title: 'Est. Grs.Wgt(lbs)',
        dataIndex: 'wgt',
        key: 'wgt',
        className: 'min-width-100',
        width: '20%',
        render: (_, record) => {
          let wgt = record?.data?.tare_wgt
          if (this.state.editRecord && this.state.editRecord?.build_plan && this.state.editRecord?.build_plan?.length > 0) {
            this.state.editRecord?.build_plan?.map((item) => {
              if (item?.ulds && item?.ulds?.length > 0) {
                item?.ulds?.map((uld) => {
                  if (uld.id && uld.id == record.id) {
                    wgt = wgt + uld.wgt
                  }
                }
                )
              }
            })
          }
          return (
            <span>{wgt}</span>
          )
        }
      },
      {
        title: 'Postions Occupied',
        dataIndex: 'positions',
        key: 'positions',
        className: 'min-width-100',
        width: '10%',
        render: (_, record) => (
          <span>{record?.data?.ld3}</span>
        )
      },
      {
        title: 'Chbl lbs per LD3 position',
        dataIndex: 'chbl',
        key: 'chbl',
        className: 'min-width-100',
        width: '20%',
        render: (_, record) => {
          let wgt = 0
          let org_dest = this.state.editRecord?.org_stn + this.state.editRecord?.dest_stn
          let goal = ch_wt_goals.find((item) => item.lane == org_dest)
          if (this.state.editRecord && this.state.editRecord?.build_plan && this.state.editRecord?.build_plan?.length > 0) {
            this.state.editRecord?.build_plan?.map((item) => {
              if (item?.ulds && item?.ulds?.length > 0) {
                item?.ulds?.map((uld) => {
                  if (uld.id && uld.id == record.id) {
                    wgt = wgt + (uld?.ch_wgt || 0)
                  }
                }
                )
              }
            })
          }
          let props = {}
          let value = ''
          if (wgt) {
            if (goal) {
              if (parseFloat(goal?.lbs) <= (wgt / record?.data?.ld3)) {
                props.style = {
                  color: 'green',
                  marginRight: '2px',
                  textAlign: 'center'
                }
                value = (<> {Math.round(wgt / record?.data?.ld3)} <CheckCircleFilled twoToneColor='#52c41a' />  </>)
              } else {
                props.style = {
                  color: 'red',
                  marginRight: '2px',
                  textAlign: 'center'
                }
                value = (<> {Math.round(wgt / record?.data?.ld3)} <CloseCircleFilled style={{ color: 'red' }} />  </>)
              }
            } else {
              value = Math.round(wgt / record?.data?.ld3)
            }
          } else {
            value = ('')
          }
          return {
            props,
            children: value
          }
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        className: 'min-width-100',
        render: (_, record) =>
          <>
            {record?.id === this.state.editOptiUld?.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.updateOptiData()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearOptiData()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column d-flex'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.editOptiData(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.removeOptiData(record?.id)} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </>
      },
    ]

    const awbColumns = [
      {
        title: (<span className='test'>  Location </span>),
        dataIndex: 'location',
        key: 'location',
        className: 'min-width-100',
        render: (_, record) => (
          <span>{record?.location || '-'}</span>
        )
        // render: (_, record) => {
        //   record && record.awbs_in && record?.locations &&
        //     record?.locations.map((loc, index) => {

        //       return <div key={index}>{loc?.fromLocation}</div>
        //     }) 
        // }
      },
      {
        title: (<span className='test'>  AWB </span>),
        dataIndex: 'awb',
        key: 'awb',
        className: 'space-nowrap',
        render: (_, record) => (
          // <span className={record?.showred ? 'bg-red' : null}>{record?.awb_no?.replace(/(\d{3})(\d{8})/, '$1-$2')}</span>
          <span className={this.mismatchPcs(record?.pcs, record)}>{record?.awb_no?.replace(/(\d{3})(\d{8})/, '$1-$2')}</span>
        )
      },
      // {
      //   title: (<span className='test'>  Comments </span>),
      //   dataIndex: 'comments',
      //   key: 'comments',
      //   className: 'checklist-cols-1'
      // },
      {
        title: (<span className='test'>  Pcs </span>),
        dataIndex: 'pcs',
        key: 'pcs',
        render: (_, record) => (
          <span>{record?.pcs}</span>
        )
      },
      {
        title: (<span className='test'>  GrWt.({this.state.editWgtUnit === 'KG' ? 'kgs' : 'lbs'}) </span>),
        dataIndex: 'grwt',
        key: 'grwt',
        className: 'min-width-100',
        render: (_, record) => {
          let unit = record?.wgt?.unit
          {
            if (this.state.editWgtUnit === 'KG') {
              if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
                let kg = this.updateWeightVal('KG', record?.wgt?.value)
                return <span>{Math.round(kg)} kG</span>
              }
              else {
                return <span>{Math.round(record?.wgt?.value)} KG</span>
              }
            }
            else if (this.state.editWgtUnit === 'LB') {
              if (unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
                let lb = this.updateWeightVal('LB', record?.wgt?.value)
                return <span>{Math.round(lb)} LB</span>
              }
              else {
                return <span>{Math.round(record?.wgt?.value)} LB</span>
              }
            }
          }
        }
      },
      {
        title: (<span className='test'>  ChblWt.({this.state.editWgtUnit === 'KG' ? 'kgs' : 'lbs'}) </span>),
        dataIndex: 'chwt',
        key: 'chwt',
        render: (_, row) => {
          let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === row.awb_no)
          let ch_wgt = bpIndex !== -1 && this.state.editRecord.build_plan[bpIndex]?.data?.ch_wgt != null ? this.state.editRecord.build_plan[bpIndex]?.data?.ch_wgt : row.ch_wgt

          if (row?.awb_no === this.state.editAwbsRecord?.awb_no) {
            return (
              <Input
                size='small'
                placeholder=''
                name='ch_wgt'
                value={Math.round(this.state.editAwbs.ch_wgt || '')}
                onChange={(e) => this.handleEditAwb(e, row)}
              />
            )
          }
          else {
            if (this.state.editWgtUnit === 'KG') {
              let kg = this.updateWeightVal('KG', row.ch_wgt)
              return <span>{Math.round(kg || 0)} KG</span>
            }
            else {
              return (<span>{Math.round(ch_wgt)} LB</span>)
            }
          }

        }
      },
      {
        title: (<span className='test'>  Vol.(cbf) </span>),
        dataIndex: 'vol',
        key: 'vol',
        render: (_, record) => (
          <span>{Math.round(record?.vol?.value)}</span>
        )

        // render: (_, row) => {
        //   let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === row.awb_no)
        //   let ch_vol = bpIndex !== -1 && this.state.editRecord.build_plan[bpIndex]?.data?.ch_vol != null ? this.state.editRecord.build_plan[bpIndex]?.data?.ch_vol : row.ch_vol
        //   if (row?.awb_no === this.state.editAwbsRecord?.awb_no) {
        //     return (
        //       <Input
        //         size='small'
        //         placeholder=''
        //         name='ch_vol'
        //         value={this.state.editAwbs.ch_vol || ''}
        //         onChange={(e) => this.handleEditAwb(e, row)}
        //       />
        //     )
        //   }
        //   else {
        //     return (<span>{ch_vol}</span>)
        //   }

        // }
      },
      // {
      //   title: (<span className='test'>  Rev.(usd) </span>),
      //   dataIndex: 'rev',
      //   key: 'rev',
      //   className: 'checklist-cols-1'
      // },
      {
        title: (<span className='test'>  Pri # </span>),
        dataIndex: 'pri',
        key: 'pri',
        render: (_, row) => {
          let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === row.awb_no)
          let priority = bpIndex !== -1 && this.state.editRecord.build_plan[bpIndex]?.data?.pri != null ? this.state.editRecord.build_plan[bpIndex]?.data?.pri : row.priority
          if (row?.awb_no === this.state.editAwbsRecord?.awb_no) {
            return (
              <Input
                size='small'
                placeholder=''
                name='pri'
                value={this.state.editAwbs.pri}
                onChange={(e) => this.handleEditAwb(e, row)}
              />
            )
          }
          else {
            return (<span>{priority}</span>)
          }

        }

      },
      {
        title: (<span className='test'>  Dest </span>),
        dataIndex: 'dest',
        key: 'dest',
        render: (_, record) => (
          <span>{record?.dest}</span>
        )
      },
      {
        title: (<span className='test'>  Prod </span>),
        dataIndex: 'prod',
        key: 'prod',
        render: (_, record) => (
          <span>{record?.prod_code}</span>
        )
      },
      {
        title: (<span className='test'>  SPL </span>),
        dataIndex: 'spl',
        key: 'spl',
        className: 'min-width-100',
        render: (_, record) => (
          <span>{record?.spl_code}</span>
        )
      },
      {
        title: (<span className='test'>  Pre-Built? </span>),
        dataIndex: 'preBuilt',
        key: 'preBuilt',
        align: 'center',
        render: (_, record) => (
          <span>{record?.pre_build ? 'Y' : 'N'}</span>
        )
      },
      {
        title: (<span className='test'>  On Hand? </span>),
        dataIndex: 'onHand',
        key: 'onHand',
        align: 'center',
        render: (_, record) => (
          <span>{record?.on_hand ? 'Y' : 'N'}</span>
        )
      },
      {
        title: (<span className='test'>  Assigned ULD </span>),
        dataIndex: 'addUld',
        key: 'addUld',
        align: 'center',
        width: '10%',
        className: '',
        render: (_, row) => {
          let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === row.awb_no)
          return (
            <div>
              {row.uldAdded === false ?
                <p>-</p>
                :
                <Space size={[0, 8]} wrap className='tag-class' >
                  {this.state.editRecord.build_plan && this.state.editRecord.build_plan.length > 0 && this.state.editRecord.build_plan[bpIndex] && this.state.editRecord.build_plan[bpIndex]?.ulds?.length > 0 ?
                    this.state.editRecord.build_plan[bpIndex]?.ulds?.map((uld) => {
                      let index = this.state.editRecord.build_uld.sort((a, b) => b?.data?.max_wgt - a?.data?.max_wgt).findIndex((item) => item.id === uld.id)
                      let color = index !== -1 ? uld_colors[index].name : ''
                      return <Fragment key={uld.id}><Tooltip title={`pcs: ${uld.pcs}, grs.wt: ${uld.wgt}`} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Tag color={color}>{uld?.uld_type + (uld?.uld_no || '')}</Tag>
                      </Tooltip></Fragment>
                    }) : (row.slu_uld && row.slu_uld.length > 0 ? row.slu_uld.map((uld) => {
                      return <Fragment key={uld.id}><Tooltip title={`pcs: ${uld.pcs}, grs.wt: ${uld.wgt}`} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Tag color='blue'>{uld.uld_no}</Tag>
                      </Tooltip></Fragment>
                    }) : null)
                  }
                </Space>
              }
            </div>
          )
        }
      },
      {
        title: (<span className='test'>  Bulk Pcs</span>),
        dataIndex: 'bulk',
        key: 'bulk',
        align: 'center',
        width: '10%',
        className: '',
        render: (_, row) => {
          let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === row.awb_no)
          let blk = bpIndex !== -1 ? this.state.editRecord.build_plan[bpIndex]?.data?.blk : 0
          if (row?.awb_no === this.state.editAwbsRecord?.awb_no) {
            return (
              <Input
                size='small'
                placeholder=''
                name='blk'
                value={this.state.editAwbs.blk}
                onChange={(e) => this.handleEditAwb(e, row)}
              />
            )
          }
          else {
            return (<span>{blk}</span>)
          }
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        className: 'min-width-100',
        render: (_, record) =>
          <>
            {record?.awb_no === this.state.editAwbsRecord?.awb_no ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.updateAwbRecord()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column d-flex'>
                <Tooltip title='Assign to ULD' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                  <img className='uldicon' src={ULDicon} alt='uld' onClick={() => this.addULD(record)} />
                </Tooltip>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.editAwbs(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.deleteAwbRecord(record)} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </>
      },
    ]

    const nestedAwbColumns = [
      {
        title: (<span className='test'>  Location </span>),
        dataIndex: 'location',
        key: 'location',
        className: 'min-width-100',
        render: (_, record) => (
          <span>{record?.location || '-'}</span>
        )
        // render: (_, record) => {
        //   record && record.awbs_in && record?.locations &&
        //     record?.locations.map((loc, index) => {

        //       return <div key={index}>{loc?.fromLocation}</div>
        //     }) 
        // }
      },
      {
        title: (<span className='test'>  AWB </span>),
        dataIndex: 'awb',
        key: 'awb',
        className: 'space-nowrap',
        render: (_, record) => (
          // <span className={record?.showred ? 'bg-red' : null}>{record?.awb_no?.replace(/(\d{3})(\d{8})/, '$1-$2')}</span>
          <span className={this.mismatchPcs(record?.pcs, record)}>{record?.awb_no?.replace(/(\d{3})(\d{8})/, '$1-$2')}</span>
        )
      },
      {
        title: (<span className='test'>  Pcs </span>),
        dataIndex: 'pcs',
        key: 'pcs',
      },
      {
        title: (<span className='test'>  GrWt.({this.state.editWgtUnit === 'KG' ? 'kgs' : 'lbs'}) </span>),
        dataIndex: 'wgt',
        key: 'wgt',
        className: 'min-width-100',
      },
      {
        title: (<span className='test'>  ChblWt.({this.state.editWgtUnit === 'KG' ? 'kgs' : 'lbs'}) </span>),
        dataIndex: 'ch_wgt',
        key: 'ch_wgt',
      },
      {
        title: (<span className='test'>  Vol.(cbf) </span>),
        dataIndex: 'vol',
        key: 'vol',
        render: (_, record) => (
          <span>{Math.round(record?.vol?.value)}</span>
        )
      },
      {
        title: (<span className='test'>  Pri # </span>),
        dataIndex: 'priority',
        key: 'priority',
      },
      {
        title: (<span className='test'>  Dest </span>),
        dataIndex: 'dest',
        key: 'dest',
        render: (_, record) => (
          <span>{record?.dest}</span>
        )
      },
      {
        title: (<span className='test'>  Prod </span>),
        dataIndex: 'prod',
        key: 'prod',
        render: (_, record) => (
          <span>{record?.prod_code}</span>
        )
      },
      {
        title: (<span className='test'>  SPL </span>),
        dataIndex: 'spl',
        key: 'spl',
        className: 'min-width-100',
        render: (_, record) => (
          <span>{record?.spl_code}</span>
        )
      },
      {
        title: (<span className='test'>  Pre-Built? </span>),
        dataIndex: 'preBuilt',
        key: 'preBuilt',
        align: 'center',
        render: (_, record) => (
          <span>{record?.pre_build ? 'Y' : 'N'}</span>
        )
      },
      {
        title: (<span className='test'>  On Hand? </span>),
        dataIndex: 'onHand',
        key: 'onHand',
        align: 'center',
        render: (_, record) => (
          <span>{record?.on_hand ? 'Y' : 'N'}</span>
        )
      },
    ]

    return (
      <div>
        <Modal
          title={
            <Row>
              <Col xs={24} md={6} className='my-auto'>
                <span>Build Plan</span>
              </Col>
              <Space size={'middle'} className='ml-auto'>
                {/* <Button type='primary' disabled className='custom-button bp-button'>Run Optimization </Button> */}
                <Button type='primary' className='custom-button mr-0 bp-button' onClick={() => this.openWB(true)}
                  disabled={(ENV === 'stage' || ENV === 'production') && !users.includes(cookies.get('username'))}>WB Planner </Button>
              </Space>
              {this.bookedULD}
              {/* <Col xs={24} md={18} className='text-center'>
                <Form layout='inline'>
                  <Space wrap>
                    <Form.Item label='Enter Flight Number'>
                      <Input
                        size='small'
                        placeholder='Eg:DL123'
                        onChange={(e) => this.setState({ searchFlightNo: e.target.value?.toUpperCase() })}
                        onBlur={this.checkPrefix}
                        value={this.state.searchFlightNo}
                        className='min-width-120'
                      />
                    </Form.Item>
                    <Form.Item label='Flight Date'>
                      <DatePicker
                        size='small'
                        onChange={(mom) => this.setState({ searchDate: mom })}
                        value={this.state.searchDate}
                        placeholder='Select Date'
                      />
                    </Form.Item>
                    <Button
                      className='custom-icon-button mb-2'
                      size='small'
                      icon={<SearchOutlined />}
                      onClick={() => this.setState({ modalLoading: true }, () => this.fetchFlightOnSearch())}
                    />
                    <small className='color-red'>{this.state.editRecord && this.state.editRecord.ac_change && this.state.editRecord.ac_change.length > 0 ?
                      `This flight A/C is changed from ${this.state.editRecord.ac_change[this.state.editRecord.ac_change.length - 1].old} to ${this.state.editRecord?.ac_change[this.state.editRecord.ac_change.length - 1].ac} ` : ''
                    }</small>
                  </Space>
                </Form>
              </Col> */}
            </Row>
          }
          open={this.state.loadplanvisible}
          onOk={() => this.setState({
            loadplanvisible: false,
            showAlloc: false,
            ccView: false,
            SLUSummary: [],
            PTUSummary: [],
            BuiltSummary: [],
            BLKSummary: null,
            selected: [],
            bagSummary: [],
            remainingP96: 0,
            remainingP88: 0,
            remainingLD3: 0,
            remainingLD2: 0,
            remainingMD: 0,
          }, () => {
            this.clearEditAllocForm()
            this.clearEditFlightForm()
            this.clearAllocTableForm()
          })}
          onCancel={() => this.setState({
            loadplanvisible: false,
            showAlloc: false,
            ccView: false,
            SLUSummary: [],
            PTUSummary: [],
            BuiltSummary: [],
            BLKSummary: null,
            selected: [],
            bagSummary: [],
          }, () => {
            this.clearEditAllocForm()
            this.clearEditFlightForm()
            this.clearAllocTableForm()
          })}
          extra= {
            <Space size={'middle'}>
              {/* <Button type='primary' disabled className='custom-button bp-button'>Run Optimization </Button> */}
              <Button type='primary' className='custom-button mr-0 bp-button' onClick={() => this.openWB(true)}
                disabled={(ENV === 'stage' || ENV === 'production') && !users.includes(cookies.get('username'))}>WB Planner </Button>
            </Space>
          }
          closable={false}
          footer={[
            <>
              {this.state.showSuccess && <div className='mr-4'><b><CheckCircleTwoTone twoToneColor='#52c41a' /> Build Plan updated successfully</b></div>}
              <Space size={'middle'} className='py-2 pr-3 mr-1'>
                <Button
                  disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.editRecord}
                  onClick={this.submitData}
                  type='primary'
                  className='custom-button bp-button'
                >Update Build Plan
                </Button>
                {/* <Button
                  disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.editRecord}
                  // onClick={this.submitData}
                  type='primary'
                  className='custom-button bp-button'
                >Email BP
                </Button> */}
                <Button
                  onClick={this.emailLoadPlan}
                  type='primary'
                  className='custom-button bp-button'
                >Send Email
                </Button>
                <Button
                  onClick={() => this.setState({
                    loadplanvisible: false,
                    showSuccess: false,
                    switch: true
                  }, () => {
                    //CLEAR FLIGHT FORM
                    this.clearEditFlightForm()
                    //CLEAR ALLOCATION FORM
                    this.clearEditAllocForm()
                    //CLEAR ALLOCATION TABLE FORM
                    this.clearAllocTableForm()
                  })}
                  type='primary'
                  className='custom-button bp-button'
                >Close
                </Button>
              </Space>
            </>
          ]}
          width={'90%'}
          centered
          className='custom-modal pb-0 load-plan'
        >
          {this.state.modalLoading && (<div id='spinner' className='spinner-cont'>
            <Spin />
          </div>)}
          <Row gutter={[{
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24
          }, {
            xs: 24,
            sm: 24,
            md: 32,
            lg: 32
          },]} className='with-table'
          // ref={this.modalRef}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={14} >
              <Col className='cargo-cap-card bp'>
                <Row gutter={[{
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                }, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                },]} className='flight-ctrl'
                >  
                  <Col xs={24} sm={12} md={12} lg={4} xl={4} xxl={4} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Fight #</small>
                      <p className='info-data mb-2'>{this.state.editRecord?.flight_no || 'N/A'}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={4} xl={4} xxl={4} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Date</small>
                      <p className='info-data mb-2'>{this.state.editRecord?.flight_date ? moment(this.state.editRecord?.flight_date).format(dateOnly)?.toUpperCase() : 'N/A'}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={4} xl={3} xxl={4} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>AC</small>
                      <p className='info-data mb-2'>{this.state.editRecord?.ac_type || 'N/A'}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={4} xl={4} xxl={4} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Seg</small>
                      <p className='info-data mb-2'>{this.state.editRecord?.org && this.state.editRecord?.dest ? `${this.state.editRecord.org.origin}-${this.state.editRecord.dest.arriv}` : 'N/A'}</p>
                    </div>
                  </Col>
                  {/* </Row>
              </Card>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4} >
              <Card className='cargo-cap-card'>
                <Row gutter={[{
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12
                }, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                },]} className='flight-ctrl'
                > */}

                  <Col xs={24} sm={12} md={12} lg={4} xl={5} xxl={4} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Dept Time</small>
                      <p className='info-data mb-2'>{this.state.editRecord?.dept_time && `${this.state.editRecord?.dept_prefix} ${moment.tz(this.state.editRecord.dept_time, this.state.timezone).format(dateTimeFormat)?.toUpperCase()}` || 'N/A'}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={4} xl={3} xxl={4} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Ship #</small>
                      <p className='info-data mb-2 space-nowrap'>{this.state.editRecord?.wgt_bal_plan?.ship || 'N/A'}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={4} xl={3} xxl={4} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>FPE Time</small>
                      <p className='info-data mb-2 space-nowrap'>{this.state.editRecord?.fpe ? moment.utc(this.state.editRecord.dept_time).tz(this.state.timezone).subtract(this.state.editRecord.fpe, 'minutes').format('HH:mm') : '-'}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={4} xl={4} xxl={4} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Plan Due</small>
                      <p className='info-data mb-2 space-nowrap'>
                        {this.state.milestoneData && this.state.milestoneData.length > 0 ? (
                          (() => {
                            let planDue = this.state.milestoneData.find(
                              (item) => item?.type === 'Dod' && item.title === 'Build Plan Submitted to Warehouse'
                            )
                            if (planDue) {
                              const cutoffTime = this.state.editRecord?.dept_date ? moment
                                .utc(this.state.editRecord?.dept_date)
                                .tz(this.state.timezone)
                                .subtract(this.getCutoff(planDue.cutoff, this.state.editRecord), 'hours') : null

                              // Return the formatted time value or render it within a JSX element
                              // return `SKD ${cutoffTime.format('HH:mm')} ACT ${this.getAct(this.state.editRecord, planDue.id, 'HH:mm') || 'N/A'}`
                              return `SKD ${cutoffTime?.format('HH:mm')}`

                            } else {
                              return '-'
                            }
                          })()
                        ) : (
                          '-'
                        )}
                      </p>
                    </div>
                  </Col>
                  {/* </Row>
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} >
              <Card className='cargo-cap-card'>
                <Row gutter={[{
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12
                }, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                },]} className='flight-ctrl'
                >   */}
                  <Col xs={24} sm={12} md={12} lg={4} xl={5} xxl={4} className='text-center'>
                    <div className='recd-ht'><small className='info-title'>PAX CAP / FCST</small>
                      <Space direction='horizontal'>
                        <p className='info-data text-right'>{this.state.editRecord?.pax_cap || 0} </p> <span className='color-white'>/</span>
                        {<InputNumber
                          min={0}
                          precision={0}
                          controls={false}
                          size='small'
                          placeholder=''
                          value={this.state.editFcst || 0}
                          onChange={(e) => this.setState({ editFcst: e })}
                          disabled={this.disableNote(this.state.editRecord?.dept_time)}
                          className='w-40'
                        />}
                      </Space>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={5} xl={5} xxl={4} className='text-center'>
                    <div className='sub-head'>
                      <div><small className='info-title'>Bag Pstns</small></div>
                      <Space size='small'>
                        <div className='w-40'>
                          <small className='info-title'>LD3</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editBagLD3 || 0}
                            onChange={(e) => this.setState({ editBagLD3: e })}
                            disabled={!this.state.editRecord?.cont_type.includes('LD3') || this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>
                        <div className='w-40'>
                          <small className='info-title'>LD2</small>
                          <InputNumber
                            className='w-40'
                            min={0}
                            precision={0}
                            controls={false}
                            size='small'
                            placeholder=''
                            value={this.state.editBagLD2 || 0}
                            onChange={(e) => this.setState({ editBagLD2: e })}
                            disabled={!this.state.editRecord?.cont_type.includes('LD2') || this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                          />
                        </div>
                        <Button
                          className='custom-icon-button mt-4 disabled-bg-none'
                          ghost
                          icon={<SearchOutlined />}
                          onClick={() => this.fetchFlightSummary()}
                          disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                        />
                      </Space>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={4} xl={4} xxl={4} className='sub-head text-center'>
                    <small className='info-title space-nowrap'>Cargo Payload</small>
                    <div className=''>
                      <div className='weight-toggle'>
                        <Switch
                          checkedChildren='LB'
                          unCheckedChildren='KG'
                          size='small'
                          className='mx-1'
                          checked={this.state.editWgtUnit === 'KG' ? false : true}
                          onChange={((e) => this.setState({
                            editWgtUnit: e === false ? 'KG' : 'LB',
                            editWgtValue: this.updateWeightVal(e === false ? 'KG' : 'LB', this.state.editWgtValue)
                          }))}
                        />
                      </div>
                      <div className=''>
                        <InputNumber
                          min={0}
                          controls={false}
                          size='small'
                          placeholder=''
                          value={this.state.editWgtValue || 0}
                          onChange={(e) => this.setState({ editWgtValue: e })}
                          disabled={this.disableNote(this.state.editRecord?.dept_time)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={3} xl={3} xxl={4} className='text-center'>
                    <div className='recd-ht'>
                      <small className='info-title '>BU PRIO</small>
                      <Checkbox
                        className='light-checkbox mx-auto'
                        checked={this.state.editBuPrio}
                        onChange={(e) => this.setState({ editBuPrio: e.target.checked })}
                        disabled={this.disableNote(this.state.editRecord?.dept_time)}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Col>
            
            <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10} >
              <Row className='opti-input'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      id='config-table'
                      className='custom-table w-100 checklist-table1'
                      // dataSource={this.processIndividualFlight([this.state.editRecord])}
                      // dataSource={[this.state.editRecord]}
                      dataSource={get_bin_config()}
                      columns={configColumns}
                      pagination={false}
                      rowClassName={(record, index) => index != 0 ? record.className : ''}
                    />
                  </div>
                </Col>
                {/* <Col className='ml-auto'>
                  <Space size={'middle'}>
                    <Button type='primary' className='custom-button mr-0 bp-button' onClick={() => this.openWB(true)}
                      disabled={(ENV === 'stage' || ENV === 'production') && !users.includes(cookies.get('username'))}>WB Planner </Button>
                  </Space>
                </Col> */}
              </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}  >
              <Row className= 'opti-input'>
                <Col xs={24} className='switch'>
                  <Title level={5} className='mb-0' xs={12}>
                  ULD Optimization
                  </Title>
                  <div className='awb-switch ml-5'>
                    <Switch 
                      checkedChildren='AWBs' 
                      unCheckedChildren='ULDs' 
                      checked={this.state.switch}
                      onChange={(e) => this.setState({ switch: e })}
                    // className='awb-switch'
                    />
                  </div>
                  <Col className='ml-auto'>
                    <span className='goal'>
                      <p>Chbl Wgt Goal: {this.state.chblWgtGoal ? <b> {this.state.chblWgtGoal} </b> : 'N/A'} </p>
                    </span>
                  </Col>
                </Col>
              
                <Row gutter={[8, 16]} className='opti-row'>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} className='right-section'>
                    <label>ULD Type</label>
                    <Select
                      value={this.state.optiUld?.type}
                      size='small'
                      name='type'
                      className='text-center w-100'
                      onChange={(e) => this.handleOptiUldChange({
                        target: {
                          name: 'type',
                          value: e
                        }
                      })}
                    >
                      {const_ulds?.map((c_u, index) => <Option key={index} value={c_u}>{c_u}</Option>)}
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} className='right-section'>
                    <label>ULD Number</label>
                    <Input
                      name='uld_no'
                      value={this.state.optiUld?.uld_no}
                      onChange={this.handleOptiUldChange}
                    />
                  </Col>
                  <Col flex='154px' className='text-center p-0 right-section'>
                    <label className='custom-label transparent'>-</label>
                    <Button type='ghost' className='ml-auto image-btn' onClick={() => this.addOptiData()}>
                      <Tooltip title='Add' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                    </Button>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                    <div className='table-outer'>
                      {this.state.switch ? 
                        <Table
                          className='custom-table w-100 ex-tabl buildup-table'
                          dataSource={this.state.mergeEditRecord}
                          columns={awbColumns}
                          pagination={false}
                        /> : 
                        <Table
                          className='custom-table w-100 ex-tabl buildup-table'
                          dataSource={[...this.state.editRecord?.build_uld?.sort((a, b) => b?.data?.max_wgt - a?.data?.max_wgt) || [],
                            ...(
                              // eslint-disable-next-line no-unsafe-optional-chaining
                              this.state.editRecord?.ulds_out?.filter(u=>{
                                if(u.info?.remark?.includes('SLU'))
                                {
                                  return {
                                    id: u.id,
                                    type: u.uld_no,
                                    slu: true
                                  }
                                }
                              })
                            ),
                            {
                              id: 0,
                              type: 'BULK' 
                            } || []
                          ]}
                          columns={optiColumns}
                          pagination={false}
                          expandable={{
                            rowExpandable: () => this.state.editRecord?.ulds_out?.length > 0 || this.state.editRecord?.build_plan?.length > 0,
                            expandedRowRender: (record) => <Table 
                              className='custom-table w-100 ex-tabl buildup-table nested'
                              dataSource={this.showAwbs(record, this.state.editRecord?.build_plan || [])}
                              columns={nestedAwbColumns}
                              pagination={false}
                            // rowKey={this.state.editRecord.id}
                            />,
                            expandIcon: ({ expanded, onExpand, record }) =>
                              expanded?
                                <CaretDownOutlined onClick={e => onExpand(record, e)} />
                                :
                                <CaretRightOutlined onClick={e => onExpand(record, e)} />,
                          }}
                          rowKey='id'
                        />
                      }
                    </div>
                    {/* {JSON.stringify(this.state.mergeEditRecord)} */}
                  </Col> 
                  <Col xs={18}>

                  </Col>
                  <Col className='ml-auto'>
                    <Space size={'middle'}>
                      <Checkbox checked={this.state.priority} onChange={(e) => this.setState({ priority: e.target.checked })}>Include Booking Priority</Checkbox>
                      <Button
                        type='primary'
                        className='custom-button bp-button'
                        onClick={() => this.opti()}>
                        {this.state.optiLoader ? <LoadingOutlined style={{ fontSize: 18 }} /> : 'Run Optimization'}
                      </Button>
                      <Button
                        type='primary'
                        className='custom-button bp-button'
                        onClick={() => this.opti_clear()}>
                        {this.state.clearLoader ? <LoadingOutlined style={{ fontSize: 18 }} /> : 'Clear'}
                      </Button>
                      {/* <Button type='primary' className='custom-button mr-0 bp-button' onClick={() => this.openWB(true)}>WB Planner </Button> */}
                    </Space>
                  </Col>
                </Row>
              </Row>
            </Col>
            {this.state.showAlloc && <>
              <Col xs={24} sm={24} md={24} lg={8} xl={12} className= 'opti-input'>
                <Title level={5} className='mb-0'>Load Plan</Title>
              </Col>
              <Col xs={24} sm={24} md={24} lg={16} xl={12} className=''>
                <span className='rem-pos-title'>Remaining Positions: </span>
                <div className='d-flex f-wrap gap-10 rem_posn'>
                  <p className='fv-middle gap-10'>P96:
                    <InputNumber
                      size='small'
                      className={this.state.remainingP96 > 0 ? 'w-35px dis-blue' : this.state.remainingP96 < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                      value={this.state.remainingP96 < 0 ? this.state.remainingP96?.toString()?.split('-')[1] : this.state.remainingP96 || 0}
                      disabled
                    />
                  </p>
                  <p className='fv-middle gap-10'>P88:
                    <InputNumber
                      size='small'
                      className={this.state.remainingP88 > 0 ? 'w-35px dis-blue' : this.state.remainingP88 < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                      value={this.state.remainingP88 < 0 ? this.state.remainingP88?.toString()?.split('-')[1] : this.state.remainingP88 || 0}
                      disabled
                    />
                  </p>
                  <p className='fv-middle gap-10'>LD3:
                    <InputNumber
                      size='small'
                      className={this.state.remainingLD3 > 0 ? 'w-35px dis-blue' : this.state.remainingLD3 < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                      value={this.state.remainingLD3 < 0 ? this.state.remainingLD3?.toString()?.split('-')[1] : this.state.remainingLD3 || 0}
                      disabled
                    />
                  </p>
                  <p className='fv-middle gap-10'>LD2:
                    <InputNumber
                      size='small'
                      className={this.state.remainingLD2 > 0 ? 'w-35px dis-blue' : this.state.remainingLD2 < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                      value={this.state.remainingLD2 < 0 ? this.state.remainingLD2?.toString()?.split('-')[1] : this.state.remainingLD2 || 0}
                      disabled
                    />
                  </p>
                  <p className='fv-middle gap-10'>MD:
                    <InputNumber
                      size='small'
                      className={this.state.remainingMD > 0 ? 'w-35px dis-blue' : this.state.remainingMD < 0 ? 'w-35px dis-red' : 'w-35px dis-grey'}
                      value={this.state.remainingMD < 0 ? this.state.remainingMD?.toString()?.split('-')[1] : this.state.remainingMD || 0}
                      disabled
                    />
                  </p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='px-0 p-12'>
                <Form
                  layout='vertical'
                  className='position-relative'
                >
                  <Row
                    gutter={[{
                      xs: 0,
                      sm: 0,
                      md: 24,
                      lg: 24
                    }, {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: 0
                    },]}
                    className='product-ht'>

                    <Col xs={24} sm={24} md={8} lg={6} xl={2} xxl={2} className=''>
                      <Form.Item label='# Of Posns'>
                        <InputNumber
                          min={0}
                          precision={0}
                          controls={false}
                          size='small'
                          placeholder=''
                          value={this.state.editPos || ''}
                          onChange={(e) => this.setState({ editPos: e })}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={2} xxl={2} className=''>
                      <Form.Item label='Posn Type'>
                        <Select size='small'
                          placeholder='Select process'
                          className='w-100'
                          value={this.state.editType || []}
                          onChange={(e) => this.setState({ editType: e })}
                        >
                          <Option value='P96'>PMC</Option>
                          <Option value='P88'>PAG</Option>
                          <Option value='LD3'>LD3</Option>
                          <Option value='LD2'>LD2</Option>
                          <Option value='MD'>MD</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={3} className=''>
                      <Form.Item label='For Work Area'>
                        <Select size='small'
                          placeholder='Select process'
                          className='w-100'
                          value={this.state.editWorkArea || []}
                          onChange={(e) => this.setState({ editWorkArea: e })}
                        >
                          <Option value='International'>International</Option>
                          <Option value='Dash'>Dash</Option>
                          <Option value='Breezeway'>Breezeway</Option>
                          <Option value='T-Point'>T-Point</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={3} className=''>
                      <Form.Item label='ULD ID / AWB'>
                        <Input
                          size='small'
                          placeholder=''
                          value={this.state.editULDAWB || ''}
                          onChange={(e) => this.setState({ editULDAWB: e.target.value?.toUpperCase() })}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={3} xxl={3} className=''>
                      <Form.Item label='Prod'>
                        <Input
                          size='small'
                          placeholder=''
                          value={this.state.editProd || []}
                          onChange={(e) => this.setState({ editProd: e.target.value?.toUpperCase() })}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={5} className=''>
                      <Form.Item label='Planning Comments'>
                        <Input
                          size='small'
                          placeholder=''
                          value={this.state.editComm1 || ''}
                          onChange={(e) => this.setState({ editComm1: e.target.value })}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={5} className=''>
                      <Form.Item label='Post Departure Comments'>
                        <Input
                          size='small'
                          placeholder=''
                          value={this.state.editComm2 || ''}
                          onChange={(e) => this.setState({ editComm2: e.target.value })}
                        />
                      </Form.Item>
                    </Col>

                    <Col flex='44px' className='text-center ml-auto p-0'>
                      <label className='custom-label transparent'>-</label>
                      <Button type='ghost' className='ml-auto image-btn' onClick={() => this.submit('add')}>
                        <Tooltip title={this.state.isEdit ? 'Update' : 'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                className='table-overflow'
              >
                <Table
                  className='custom-table w-100 no-header-table'
                  dataSource={this.state.editAllocArray}
                  columns={planLoadColumns}
                  pagination={false}
                />
              </Col>
            </>}
            {/* <Row className='opti-row'> */}
            <Col span={24} >
              <Row className= 'opti-input last'>
                <Col className='switch'>
                  <Title level={5} className='mb-0' xs={12}>
                Load Planning Notes
                  </Title>
                  <div className='ml-auto'>
                    <Text type='secondary' strong>Last Update:</Text>
                    <Space size={[48, 0]} direction='horizontal'>
                      <Text strong>{this.state.lastUpdatedAt ? this.state.lastUpdatedAt + ' ' + this.state.ext : 'N/A'}</Text>
                      <Text strong>{this.state.lastUpdatedBy ? this.state.lastUpdatedBy : 'N/A'}</Text>
                    </Space>
                  </div>
                </Col>
                <Row className='mt-2'>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6} className='right-section'>
                    <label>Planning Comments</label>
                    <Input
                      disabled={this.disableNote(this.state.editRecord?.dept_time)}
                      size='small'
                      placeholder=''
                      value={this.state.editComm1 || ''}
                      onChange={(e) => this.setState({ editComm1: e.target.value })}
                    />
                  </Col>
                  <Col flex='154px' className='text-center p-0 right-section'>
                    <label className='custom-label transparent'>-</label>
                    <Button type='ghost' className='ml-auto image-btn' onClick={() => this.submit('add')}>
                      <Tooltip title={this.state.isEdit ? 'Update' : 'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                    </Button>
                  </Col>
                </Row>
                <Col className='table-outer'>
                  <Table
                    className='custom-table w-100'
                    dataSource={this.state.editAllocArray} columns={CCcolumns}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Col>
            {/* </Row> */}
          </Row>
        </Modal>

        <div ref={this.modalRef}>
          <div className='email-table' // class not applied to email
            style={{
              fontSize: '13px',
              textAlign: 'left',
              direction: 'ltr',
              verticalAlign: 'bottom',
              width: '100%'
            }}>

            <table align='center' border='0' cellPadding='0' cellSpacing='0' role='presentation'
              style={{
                background: '#fff',
                backgroundColor: '#fff',
                maxWidth: '600px',
                width: '100%'
              }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      borderBottom: '#333957 solid 5px',
                      backgroundColor: '#f2f2f2',
                      direction: 'ltr',
                      fontSize: 0,
                      padding: '20px 0',
                      textAlign: 'center',
                      verticalAlign: 'top',
                    }}
                  ></td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: '#dddddd solid 1px',
                      borderTop: '0px',
                      direction: 'ltr',
                      fontSize: 0,
                      padding: '20px 0',
                      textAlign: 'center',
                      verticalAlign: 'top',
                    }}
                  >
                    <table border='0' cellPadding='0' cellSpacing='0' role='presentation' style={{ verticalAlign: 'bottom' }}
                      width='100%'>

                      <tbody>

                        <tr>
                          <td align='center' style={{
                            padding: '25px',
                            paddingBottom: '40px',
                            wordBreak: 'break-word'
                          }}>

                            <div style={{
                              fontSize: '22px',
                              lineHeight: '1',
                              textAlign: 'center',
                              color: '#555',
                              borderBottom: '2px solid #df1931',
                              paddingBottom: '12px'
                            }}>
                              Build Plan: {this.state.editRecord?.flight_no || 'N/A'}/{this.state.editRecord?.org && this.state.editRecord?.dest ? `${this.state.editRecord.org.origin}-${this.state.editRecord.dest.arriv}` : 'N/A'} {this.state.editRecord?.flight_date ? moment(this.state.editRecord?.flight_date).format(dateOnly)?.toUpperCase() : 'N/A'}
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td align='left' style={{
                            padding: '10px 25px',
                            paddingBottom: '30px',
                            wordBreak: 'break-word'
                          }}>
                            <p style={{
                              fontSize: '18px',
                              color: '#013466'
                            }}>Hello User,</p>
                            <div style={{
                              fontSize: '16px',
                              lineHeight: '1',
                              color: '#555'
                            }}>
                              Build Plan: {this.state.editRecord?.flight_no || 'N/A'}/{this.state.editRecord?.org && this.state.editRecord?.dest ? `${this.state.editRecord.org.origin}-${this.state.editRecord.dest.arriv}` : 'N/A'} {this.state.editRecord?.flight_date ? moment(this.state.editRecord?.flight_date).format(dateOnly)?.toUpperCase() : 'N/A'}
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td align='left' style={{
                            fontSize: '0px',
                            padding: '10px 25px',
                            paddingBottom: '30px',
                            wordBreak: 'break-word'
                          }}>

                            <table width='100%' border='0' cellSpacing='0' cellPadding='0'>
                              <tr style={{ paddingBottom: '40px' }}>
                                <td valign='top' style={{
                                  color: '#666',
                                  fontSize: '14px'
                                }}>
                                  <div style={{
                                    width: '50%',
                                    display: 'inline-block',
                                    paddingBottom: '20px',
                                    borderBottom: '1px solid lightgrey',
                                    marginBottom: '20px',
                                  }}>
                                    <div style={{ color: 'rgb(151 152 153)' }}>Fight #</div><div style={{ fontSize: '18px' }}>{this.state.editRecord?.flight_no || 'N/A'}</div>
                                  </div>
                                  {/* </td>
                                <td width='33.33%' valign='top' style={{ color: '#666', }}> */}
                                  <div style={{
                                    width: '50%',
                                    display: 'inline-block',
                                    paddingBottom: '20px',
                                    borderBottom: '1px solid lightgrey',
                                    marginBottom: '20px',
                                    textAlign: 'right'
                                  }}>
                                    <div style={{ color: 'rgb(151 152 153)' }}>Date</div>
                                    <div style={{ fontSize: '18px' }}>{this.state.editRecord?.flight_date ? moment(this.state.editRecord?.flight_date).format(dateOnly)?.toUpperCase() : 'N/A'}</div>

                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td valign='top' style={{
                                  color: '#666',
                                  fontSize: '12px'
                                }}>
                                  <div style={{
                                    backgroundColor: 'whitesmoke',
                                    marginLeft: '-24px',
                                    marginRight: '-24px',
                                    padding: '24px'
                                  }}>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>AC</div>
                                      <div style={{ fontSize: '16px' }}>{this.state.editRecord?.ac_type || 'N/A'}</div>
                                    </div>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>Seg</div>
                                      <div style={{ fontSize: '16px' }}>{this.state.editRecord?.org && this.state.editRecord?.dest ? `${this.state.editRecord.org.origin}-${this.state.editRecord.dest.arriv}` : 'N/A'}</div>
                                    </div>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>Ship #</div>
                                      <div style={{ fontSize: '16px' }}>{this.state.editRecord?.wgt_bal_plan?.ship || 'N/A'}</div>
                                    </div>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>Dept Time</div>
                                      <div style={{ fontSize: '16px' }}>{this.state.editRecord?.dept_time && `${this.state.editRecord?.dept_prefix} ${moment.tz(this.state.editRecord.dept_time, this.state.timezone).format(dateTimeFormat)?.toUpperCase()}` || 'N/A'}</div>
                                    </div>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>FPE Time</div>
                                      <div style={{ fontSize: '16px' }}>
                                        {this.state.editRecord?.fpe ? moment.utc(this.state.editRecord.dept_time).tz(this.state.timezone).subtract(this.state.editRecord.fpe, 'minutes').format('HH:mm') : '-'}
                                      </div>
                                    </div>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>Plan Due</div>
                                      <div style={{ fontSize: '16px' }}>
                                        {this.state.milestoneData && this.state.milestoneData.length > 0 ? (
                                          (() => {
                                            let planDue = this.state.milestoneData.find(
                                              (item) => item?.type === 'Dod' && item.title === 'Build Plan Submitted to Warehouse'
                                            )
                                            if (planDue) {
                                              const cutoffTime = this.state.editRecord?.dept_date ? moment
                                                .utc(this.state.editRecord?.dept_date)
                                                .tz(this.state.timezone)
                                                .subtract(this.getCutoff(planDue.cutoff, this.state.editRecord), 'hours') : null

                                              // Return the formatted time value or render it within a JSX element
                                              // return `SKD ${cutoffTime.format('HH:mm')} ACT ${this.getAct(this.state.editRecord, planDue.id, 'HH:mm') || 'N/A'}`
                                              return `SKD ${cutoffTime?.format('HH:mm')}`

                                            } else {
                                              return '-'
                                            }
                                          })()
                                        ) : (
                                          '-'
                                        )}
                                      </div>
                                    </div>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>P CAP / FCST</div>
                                      <div style={{ fontSize: '16px' }}>
                                        {this.state.editRecord?.pax_cap || 0} / {this.state.editFcst || 0}
                                      </div>
                                    </div>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>Bag Posns</div>
                                      <div style={{ fontSize: '16px' }}>
                                        <p style={{
                                          margin: 0,
                                          display: 'inline-block',
                                          marginRight: '20px'
                                        }}>LD3:&nbsp;&nbsp;{this.state.editBagLD3 || 0}</p>
                                        <p style={{
                                          margin: 0,
                                          display: 'inline-block'
                                        }}>LD2:&nbsp;&nbsp;{this.state.editBagLD2 || 0}</p>
                                      </div>
                                    </div>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>Cargo Payload({this.state.editWgtUnit})</div>
                                      <div style={{ fontSize: '16px' }}>
                                        {this.state.editWgtValue || 0}
                                      </div>
                                    </div>
                                    <div style={{
                                      width: '25%',
                                      display: 'inline-block',
                                      paddingBottom: '14px'
                                    }}>
                                      <div style={{ color: 'rgb(151 152 153)' }}>BU PRIO</div>
                                      <div style={{ fontSize: '16px' }}>
                                        {this.state.editBuPrio === true ? 'Y' : 'N'}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </table>
                            <div>

                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td align='left' style={{
                            fontSize: '0px',
                            padding: '10px 25px',
                            paddingBottom: '30px',
                            wordBreak: 'break-word'
                          }}>

                            <div style={{
                              fontSize: '12px',
                              lineHeight: '1.5',
                              color: '#555'
                            }}>
                              <table align='center' border='0' cellPadding='6' cellSpacing='0' role='presentation'
                                style={{
                                  backgroundColor: '#fff',
                                  width: '100%',
                                  border: '1px solid grey'
                                }}>
                                <thead>
                                  <tr style={{
                                    backgroundColor: '#dddddd',
                                    textAlign: 'center'
                                  }}>
                                    <th style={{ whiteSpace: 'nowrap' }}></th>
                                    <th style={{ whiteSpace: 'nowrap' }}>PMC</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>PAG</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>LD2</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>LD3</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Hints</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Notes</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td style={{
                                      padding: '6px',
                                      whiteSpace: 'nowrap'
                                    }}>Optimal Configuration</td>
                                    <td align='center' style={{
                                      padding: '6px',
                                      textTransform: 'uppercase',
                                      whiteSpace: 'nowrap'
                                    }}>{this.state.editCargoP96 || this.state.editRecord?.cargo?.p96 || 0}</td>
                                    <td align='center' style={{
                                      padding: '6px',
                                      textTransform: 'uppercase'
                                    }}>{this.state.editCargoP88 || this.state.editRecord?.cargo?.p88 || 0}</td>
                                    <td align='center' style={{ padding: '6px' }}>{this.state.editCargoLD2 || this.state.editRecord?.cargo?.ld2 || 0}</td>
                                    <td align='center' style={{ padding: '6px' }}>{this.state.editCargoLD3 || this.state.editRecord?.cargo?.ld3 || 0}</td>
                                    <td align='center' style={{
                                      padding: '6px',
                                      textTransform: 'uppercase'
                                    }}>{this.state.hints || this.state.editRecord?.cargo?.hint}</td>
                                    <td align='center' style={{
                                      padding: '6px',
                                      textTransform: 'uppercase'
                                    }}>{this.state.notes || this.state.editRecord?.cargo?.note}</td>
                                  </tr>

                                  <tr>
                                    <td style={{
                                      padding: '6px',
                                      whiteSpace: 'nowrap'
                                    }}>Planned Bin Configuration</td>
                                    <td align='center' style={{
                                      padding: '6px',
                                      textTransform: 'uppercase',
                                      whiteSpace: 'nowrap'
                                    }}>{this.state.editRecord?.build_uld?.length > 0 ? (this.state.editRecord?.build_uld?.filter((u => u.type === 'PMC')))?.length : 0}</td>
                                    <td align='center' style={{
                                      padding: '6px',
                                      textTransform: 'uppercase'
                                    }}>{this.state.editRecord?.build_uld?.length > 0 ? (this.state.editRecord?.build_uld?.filter((u => u.type === 'PAG')))?.length : 0}</td>
                                    <td align='center' style={{ padding: '6px' }}>{this.state.editRecord?.build_uld?.length > 0 ? (this.state.editRecord?.build_uld?.filter((u => (u?.type === 'LD2' || u?.type === 'DPA' || u?.type === 'DPE' || u?.type === 'DPL' || u?.type === 'DPN'))))?.length : 0}</td>
                                    <td align='center' style={{ padding: '6px' }}>{this.state.editRecord?.build_uld?.length > 0 ? (this.state.editRecord?.build_uld?.filter((u => (u?.type === 'LD3' || u?.type === 'AKE' || u?.type === 'AKN' || u?.type === 'AKX' || u?.type === 'AKY' || u?.type === 'PLA' || u?.type === 'FLA' || u?.type === 'PLB'))))?.length : 0}</td>
                                    <td align='center' style={{
                                      padding: '6px',
                                      textTransform: 'uppercase'
                                    }}>-</td>
                                    <td align='center' style={{
                                      padding: '6px',
                                      textTransform: 'uppercase'
                                    }}>-</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </td>
                        </tr>


                        <tr>
                          <td align='left' style={{
                            fontSize: '0px',
                            padding: '10px 25px',
                            paddingBottom: '30px',
                            wordBreak: 'break-word'
                          }}>

                            <div style={{
                              fontSize: '12px',
                              lineHeight: '1.5',
                              color: '#555'
                            }}>
                              <table align='center' border='1' cellPadding='6' cellSpacing='0' role='presentation'
                                style={{
                                  backgroundColor: '#fff',
                                  width: '100%',
                                  // border:'1px solid #bebebe' 
                                }}>
                                <thead>
                                  <tr style={{
                                    backgroundColor: '#dddddd',
                                    textAlign: 'center'
                                  }}>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>Location</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>AWB</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '30px'
                                    }}>Pcs</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>GrWt</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>ChWt</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>Vol.(cbf)</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '30px'
                                    }}>Pri #</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>Dest</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>Prod</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      maxWidth: '100px',
                                      minWidth: '60px'
                                    }}>SPL</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>Pre-Built?</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>On Hand?</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>Assigned ULD</th>
                                    <th style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '50px'
                                    }}>Bulk</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.mergeEditRecord.map((record, index) => {
                                    return (<tr key={index}>
                                      <td style={{
                                        padding: '6px',
                                        whiteSpace: 'nowrap'
                                      }}>{record?.location || '-'}</td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase',
                                        whiteSpace: 'nowrap'
                                      }}>{record?.awb_no?.replace(/(\d{3})(\d{8})/, '$1-$2')}</td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase'
                                      }}>{record?.pcs}</td>
                                      <td style={{ padding: '6px' }}>
                                        {
                                          (() => {
                                            let unit = record?.wgt?.unit
                                            {
                                              if (this.state.editWgtUnit === 'KG') {
                                                if (unit === 'L' || unit === 'LB' || unit === 'LBS' || unit === 'LBR') {
                                                  let kg = this.updateWeightVal('KG', record?.wgt?.value)
                                                  return <span>{Math.round(kg)} kG</span>
                                                }
                                                else {
                                                  return <span>{Math.round(record?.wgt?.value)} KG</span>
                                                }
                                              }
                                              else if (this.state.editWgtUnit === 'LB') {
                                                if (unit === 'K' || unit === 'KG' || unit === 'KGM' || unit === 'KGS') {
                                                  let lb = this.updateWeightVal('LB', record?.wgt?.value)
                                                  return <span>{Math.round(lb)} LB</span>
                                                }
                                                else {
                                                  return <span>{Math.round(record?.wgt?.value)} LB</span>
                                                }
                                              }
                                            }
                                          })()
                                        }
                                      </td>
                                      <td style={{ padding: '6px' }}>
                                        {(() => {

                                          let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === record.awb_no)
                                          let ch_wgt = bpIndex !== -1 ? this.state.editRecord.build_plan[bpIndex]?.data?.ch_wgt : record.ch_wgt

                                          if (this.state.editWgtUnit === 'KG') {
                                            let kg = this.updateWeightVal('KG', record.ch_wgt)
                                            return <span>{Math.round(kg || 0)} KG</span>
                                          }
                                          else {
                                            return (<span>{Math.round(ch_wgt)} LB</span>)
                                          }

                                        })()}
                                      </td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase'
                                      }}>
                                        <span>{Math.round(record?.vol?.value)}</span>
                                      </td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase'
                                      }}>
                                        {
                                          (() => {
                                            let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === record.awb_no)
                                            let priority = bpIndex !== -1 && this.state.editRecord.build_plan[bpIndex]?.data?.pri != null ? this.state.editRecord.build_plan[bpIndex]?.data?.pri : record.priority

                                            return (<span>{priority}</span>)

                                          })()
                                        }
                                      </td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase'
                                      }}>{record?.dest}</td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase'
                                      }}>{record?.prod_code}</td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase',
                                      }}>{record?.spl_code && record?.spl_code.split(',').join(', ')}</td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase',
                                        textAlign: 'center'
                                      }}>{record?.pre_build ? 'Y' : 'N'}</td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase',
                                        textAlign: 'center'
                                      }}>{record?.on_hand ? 'Y' : 'N'}</td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase'
                                      }}>
                                        {
                                          (() => {
                                            let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === record.awb_no)
                                            return (
                                              <div>
                                                {record.uldAdded === false ?
                                                  <p>-</p>
                                                  :
                                                  <Space size={[0, 8]} wrap>
                                                    {this.state.editRecord.build_plan && this.state.editRecord.build_plan.length > 0 ?
                                                      this.state.editRecord.build_plan[bpIndex]?.ulds?.map((uld, i) => {
                                                        return <div key={i}>{uld?.uld_type + (uld?.uld_no || '')} | {uld.pcs} | {uld.wgt}</div>
                                                      }) : (record.slu_uld && record.slu_uld.length > 0 ? record.slu_uld.map((uld, i) => {
                                                        return <div key={i}>{uld.uld_no} | {uld.pcs} | {uld.wgt}</div>
                                                      }) : null)
                                                    }
                                                  </Space>
                                                }
                                              </div>
                                            )
                                          })()
                                        }
                                      </td>
                                      <td style={{
                                        padding: '6px',
                                        textTransform: 'uppercase',
                                        textAlign: 'center'
                                      }}>
                                        {
                                          (() => {
                                            let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === record.awb_no)
                                            let blk = bpIndex !== -1 ? this.state.editRecord.build_plan[bpIndex]?.data?.blk : 0
                                            return (<span>{blk}</span>)
                                          })()
                                        }
                                      </td>
                                    </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>

                          </td>
                        </tr>


                        <tr>
                          <td align='left' style={{
                            fontSize: '0px',
                            padding: '10px 25px',
                            wordBreak: 'break-word'
                          }}>
                            <p style={{
                              fontSize: '16px',
                              color: 'rgb(151,152,153)',
                              textAlign: 'left',
                              display: 'inline-block',
                              width: '50%',
                              marginBottom: 0
                            }}>Load Planning Notes</p>
                            <div style={{
                              display: 'inline-block',
                              textAlign: 'right',
                              width: '50%'
                            }}>
                              <p style={{
                                fontSize: '16px',
                                color: 'rgb(151,152,153)',
                                display: 'inline-block',
                                marginBottom: 0
                              }}>Last Update: </p>
                              <div style={{
                                fontSize: '16px',
                                display: 'inline-block'
                              }}>
                                <b style={{
                                  display: 'inline-block',
                                  color: 'rgb(102,102,102)',
                                  paddingRight: '10px'
                                }}>{this.state.lastUpdatedAt ? this.state.lastUpdatedAt + ' ' + this.state.ext : 'N/A'}</b>
                                <b style={{
                                  display: 'inline-block',
                                  color: 'rgb(102,102,102)'
                                }}>{this.state.lastUpdatedBy ? this.state.lastUpdatedBy : 'N/A'}</b>
                              </div>
                            </div>
                          </td>
                        </tr>


                        <tr>
                          <td align='left' style={{
                            fontSize: '0px',
                            padding: '10px 25px',
                            wordBreak: 'break-word'
                          }}>

                            <div style={{
                              fontSize: '12px',
                              lineHeight: '1.5',
                              color: '#555'
                            }}>
                              <table align='center' border='0' cellPadding='6' cellSpacing='0' role='presentation'
                                style={{
                                  backgroundColor: '#fff',
                                  width: '100%',
                                  border: '1px solid grey'
                                }}>
                                <thead>
                                  <tr style={{
                                    backgroundColor: '#dddddd',
                                    textAlign: 'center'
                                  }}>
                                    <th style={{ whiteSpace: 'nowrap' }}>DOD Team Comments</th>
                                    <th style={{ whiteSpace: 'nowrap' }}>Warehouse Team Comments</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.editAllocArray.map((record, index) => {
                                    return (<tr key={index}>
                                      <td style={{
                                        padding: '6px',
                                        whiteSpace: 'nowrap'
                                      }}>{record?.comm1}</td>
                                      <td style={{
                                        padding: '6px',
                                        whiteSpace: 'nowrap'
                                      }}>{record?.comm2}</td>
                                    </tr>
                                    )
                                  })
                                  }
                                </tbody>
                              </table>
                            </div>

                          </td>
                        </tr>


                        <tr>
                          <td align='left' style={{
                            fontSize: '0px',
                            padding: '10px 25px',
                            wordBreak: 'break-word'
                          }}>

                            <div style={{
                              fontSize: '14px',
                              lineHeight: '20px',
                              paddingTop: '30px',
                              textAlign: 'left',
                              color: '#525252'
                            }}>
                              Regards,<br /><br /> Cargo Optimization Team<br />
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td style={{
                            fontSize: '0px',
                            padding: '20px 0'
                          }}></td>
                        </tr>

                      </tbody>
                    </table>

                  </td>
                </tr>
              </tbody>
            </table>

          </div>


        </div>
      </div>
    )
  }

  //GENERATE SUMMARY URL
  summaryURL = () => {
    let contPresent = this.state.editRecord?.cont_type?.length > 0 ? this.state.editRecord?.cont_type : ''
    let selected = ''
    if (contPresent) {
      if (contPresent?.includes('LD2') && this.state.editBagLD2 > 0) {
        selected = 'LD2'
      } else if (contPresent?.includes('LD3') && this.state.editBagLD3 > 0) {
        selected = 'LD3'
      }
    }

    if (!selected) {
      if (this.state.editBagLD2 > 0) {
        selected = 'LD2'
      } else if (this.state.editBagLD3 > 0) {
        selected = 'LD3'
      }
    }

    let url = `summery?id=${this.state.editRecord?.id}&fleet=${this.state.editRecord?.ac_type}`
    //if(selected === 'LD2') {
    url += `&ld2=${this.state.editBagLD2 || 0}`
    //}
    //else if(selected === 'LD3') {
    url += `&ld3=${this.state.editBagLD3 || 0}`
    //}
    return url
  }
  //FETCH FLIGHT SUMMARY
  fetchFlightSummary = async () => {
    await Http.get(this.summaryURL()).then((resp) => {
      if (resp?.data?.data) {
        this.calcSummary(resp?.data?.data)
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.message,
        })
      }
    }).catch(() => {
      this.setState({ servererror: true }, () => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
      })
    })
  }
  //CALULATE SUMMARY DATA
  calcSummary = (data) => {
    let SLU3CharArr = []
    let SLUAll = []
    let PTU3CharArr = []
    let PTUAll = []
    let Built3CharArr = []
    let BuiltAll = []

    let blkObj = {}
    let blk_count_IT = 0
    let blk_count_CI = 0
    let weight_lbs_IT = 0
    let weight_lbs_CI = 0
    let weight_kg_IT = 0
    let weight_kg_CI = 0

    let vol_IT = 0
    let vol_CI = 0

    data?.summery?.map((item) => {
      if (item?.ulds_in?.blk) {
        if (item?.location) {
          blk_count_CI += 1
          // vol_CI += parseFloat(item?.vol?.value || 0) || 0
          if (item?.vol?.unit === 'FTQ') {
            vol_CI += parseFloat(item?.vol?.value || 0)
          } else if (item?.vol?.unit === 'MTQ') {
            vol_CI += parseFloat((item?.vol?.value || 0) * 35.314667)
          } else if (item?.vol?.unit === 'CF') {
            vol_CI += parseFloat(item?.vol?.value || 0)
          }

          if (item.wgt.unit === 'L' || item.wgt.unit === 'LB' || item.wgt.unit === 'LBR' || item.wgt.unit === 'LBS') {
            weight_lbs_CI += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_kg_CI += weightCalculator(parseFloat(item.wgt.value), 'LBS')
          } else if (item.wgt.unit === 'K' || item.wgt.unit === 'KG' || item.wgt.unit === 'KGM') {
            weight_kg_CI += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_lbs_CI += weightCalculator(parseFloat(item.wgt.value), 'KGM')
          }
        } else {
          blk_count_IT += 1
          // vol_IT += parseFloat(item?.vol?.value || 0) || 0
          if (vol_IT?.vol?.unit === 'FTQ') {
            vol_CI += parseFloat(item?.vol?.value || 0)
          } else if (item?.vol?.unit === 'MTQ') {
            vol_IT += parseFloat((item?.vol?.value || 0) * 35.314667)
          } else if (item?.vol?.unit === 'CF') {
            vol_IT += parseFloat(item?.vol?.value || 0)
          }

          if (item.wgt.unit === 'L' || item.wgt.unit === 'LB' || item.wgt.unit === 'LBR' || item.wgt.unit === 'LBS') {
            weight_lbs_IT += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_kg_IT += weightCalculator(item.wgt.value, 'LBS')
          } else if (item.wgt.unit === 'K' || item.wgt.unit === 'KG' || item.wgt.unit === 'KGM') {
            weight_kg_IT += item.wgt.value //ROUNDED TO NEAREST INTEGER
            weight_lbs_IT += weightCalculator(item.wgt.value, 'KGM')
          }
        }
        //ADD CART IN BUILD UP SUMMARY
        if (!item?.ulds_in?.master && this.checkULDType(item?.ulds_in) === 'CART') {
          if (!BuiltAll.includes(item?.ulds_in?.uld_no)) {
            BuiltAll.push(item?.ulds_in?.uld_no)
            Built3CharArr.push({
              uld_no: item?.ulds_in?.uld_no,
              ready: item?.ulds_in?.rdy === true ? true : false,
            })
          }
        }
      } else {
        let uldType = this.checkULDType(item?.ulds_in)
        if (uldType === 'SLU') {
          if (!SLUAll?.includes(item?.ulds_in?.uld_no)) {
            SLUAll.push(item?.ulds_in?.uld_no)
            SLU3CharArr.push({
              uld_no: item?.ulds_in?.uld_no,
              location: item?.ulds_in?.location,
            })
          }
        } else if (item?.ulds_in?.uld_dispo && item?.ulds_in?.master) {
          if (uldType === 'PTU') {
            if (!PTUAll?.includes(item?.ulds_in?.uld_no)) {
              PTUAll.push(item?.ulds_in?.uld_no)
              PTU3CharArr.push({
                uld_no: item?.ulds_in?.uld_no,
                location: item?.ulds_in?.location,
              })
            }
          }
        } else if (item?.ulds_in && !item?.ulds_in?.master) {
          if (!BuiltAll.includes(item?.ulds_in?.uld_no)) {
            BuiltAll.push(item?.ulds_in?.uld_no)
            Built3CharArr.push({
              uld_no: item?.ulds_in?.uld_no,
              ready: item?.ulds_in?.rdy === true ? true : false,
            })
          }
        }
      }
    })

    blkObj.blk_count_CI = blk_count_CI
    blkObj.blk_count_IT = blk_count_IT
    blkObj.weight_lbs_CI = weight_lbs_CI
    blkObj.weight_lbs_IT = weight_lbs_IT
    blkObj.weight_kg_CI = weight_kg_CI
    blkObj.weight_kg_IT = weight_kg_IT
    blkObj.vol_CI = vol_CI?.toFixed(2) || 0
    blkObj.vol_IT = vol_IT?.toFixed(2) || 0
    this.sortSummary(data.bag)

    this.setState({
      SLUSummary: SLU3CharArr || [],
      PTUSummary: PTU3CharArr || [],
      BuiltSummary: Built3CharArr || [],
      BLKSummary: blkObj || null,
      bagSummary: data?.bag?.length > 0 ? data.bag : []
    }, () => this.setState({ optimalBuild: true }))
  }
  //SORT
  sortSummary = (array) => {
    array && array.sort(function (a, b) {
      // Compare the fields
      if (a?.pmc > b?.pmc) return -1
      if (a?.pmc < b?.pmc) return 1
      return 0
    })
  }
  //SEARCH MODAL
  searchModal = () => {
    let SLU = this.state.SLUSummary
    let PTU = this.state.PTUSummary
    let BUILT = this.state.BuiltSummary
    let BLK = this.state.BLKSummary
    let bagSummary = this.state.bagSummary
    const obcolumns = [
      {
        title: 'MD',
        dataIndex: 'md',
        key: 'md',
        className: 'space-nowrap',
        render: (_, record) => <span>{record?.md || 0}</span>
      },
      {
        // title: 'P96',
        title: 'PMC',
        dataIndex: 'pmc',
        key: 'pmc',
        render: (_, record) => <span>{record?.pmc - this.state.editBagP96}</span>
      },
      {
        // title: 'P88',
        title: 'PAG',
        dataIndex: 'pag',
        key: 'pag',
        render: (_, record) => <span>{record?.pag - this.state.editBagP88}</span>
      },
      {
        title: 'LD3',
        dataIndex: 'ld3',
        key: 'ld3',
        render: (_, record) => <span>{record?.ld3 - this.state.editBagLD3}</span>
      },
      {
        title: 'LD2',
        dataIndex: 'ld2',
        key: 'ld2',
        render: (_, record) => <span>{record?.ld2 - this.state.editBagLD2}</span>
      },
    ]
    return (
      <Modal
        title={`Optimal Build - ${this.state.editRecord?.flight_no}/${moment(this.state.editRecord?.flight_date).format(dateOnly)} - ${this.state.editRecord?.ac_type}`}
        open={this.state.optimalBuild}
        onCancel={() => this.setState({ optimalBuild: false })}
        footer={false}
        width={1160}
        centered
        className='curved-modal pb-0'
        destroyOnClose={true}
        closeIcon={<Text type='secondary'><CloseCircleFilled /></Text>}
      >
        <Card size='small' className='custom-card custom-padding mb-4'
          title={
            <Space>
              <span>Cargo Summary</span>
              <Popover content={legendPopover} title='Legend'><InfoCircleFilled /></Popover>
            </Space>}>
          <Row gutter={[{
            xs: 8,
            sm: 16,
            lg: 24
          }, {
            xs: 8,
            sm: 16,
            lg: 24
          }]}>
            <Col xs={24} lg={16}>
              <Card title='On Hand' size='small' bordered={false} className='curved-card custom-padding'>
                <Row gutter={[8, 8]} className='p-2'>
                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>SLU</p>
                      {SLU?.map((key) => (
                        <p key={key}>
                          <span>{`${key?.uld_no}`}</span>
                          <span>
                            {!key?.location ?
                              <img src={planeLightIcon} width='15' height='15' className='float-right' alt='plane' /> :
                              <span className='float-right'>AS</span>
                            }
                          </span>
                        </p>
                      ))}
                    </div>
                  </Col>

                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>Potential TUs</p>
                      {PTU?.map((key) => (
                        <p key={key}>
                          <span>{`${key?.uld_no}`}</span>
                          <span>
                            {!key?.location ?
                              <img src={planeLightIcon} width='15' height='15' className='float-right' alt='plane' /> :
                              <span className='float-right'>AS</span>
                            }
                          </span>
                        </p>
                      ))}
                    </div>
                  </Col>

                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>Built Up</p>
                      {BUILT?.map((key) => (
                        <p key={key}>
                          <span>{`${key?.uld_no}`}</span>
                          <span>
                            {key?.ready === true ?
                              <span style={{ color: '#629d03' }} className='float-right'>C</span> :
                              <span style={{ color: '#D9001B' }} className='float-right'>O</span>
                            }
                          </span>
                        </p>
                      ))}
                    </div>
                  </Col>

                  <Col xs={24} sm={12} xl={6}>
                    <div className='cargo-summary'>
                      <p>Loose</p>
                      {/* IN TRANSIT */}
                      {BLK?.blk_count_IT > 0 && <p>
                        <span className='dib-w90'>
                          {`${BLK?.blk_count_IT} | ${formatWeight(this.state.weight_unit === 'KG' ?
                            BLK?.weight_kg_IT : this.state.weight_unit === 'LB' ? BLK?.weight_lbs_IT : 0) || 0}${this.state.weight_unit} | ${BLK?.vol_IT}CF`}
                        </span>
                        <span>
                          <img src={planeLightIcon} width='15' height='15' className='float-right' alt='plane' />
                        </span>
                      </p>}
                      {/* CHECKED IN */}
                      {BLK?.blk_count_CI > 0 && <p>
                        <span className='dib-w90'>
                          {`${BLK?.blk_count_CI} | ${formatWeight(this.state.weight_unit === 'KG' ?
                            BLK?.weight_kg_CI : this.state.weight_unit === 'LB' ? BLK?.weight_lbs_CI : 0) || 0} | ${BLK?.vol_CI}CF`}
                        </span>
                        <span>
                          <span className='float-right'>AS</span>
                        </span>
                      </p>}
                    </div>
                  </Col>

                </Row>
              </Card>
            </Col>
            <Col xs={24} lg={8}>
              <Card title='Booked, Not Tendered' size='small' bordered={false} className='curved-card custom-padding'>
                <Row gutter={[8, 8]} className='p-2'>
                  <Col xs={24} sm={12} md={12} lg={24} xl={12}>
                    <div className='cargo-summary'>
                      <p>SLU</p>
                      <p><span className='dib-w90'>MD</span><span>0</span></p>
                      <p><span className='dib-w90'>PMC/PAG</span><span>{this.state.editRecord?.not_tendered?.udp || 0}</span></p>
                      <p><span className='dib-w90'>LD3</span><span>{this.state.editRecord?.not_tendered?.ldc || 0}</span></p>
                      <p><span className='dib-w90'>LD2</span><span>{this.state.editRecord?.not_tendered?.ldp || 0}</span></p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={24} xl={12}>
                    <div className='cargo-summary'>
                      <p>Loose</p>
                      <p>
                        <span className='dib-w90'>
                          {`${this.state.editRecord?.not_tendered?.blk_count || 0} | ${formatWeight(this.state.weight_unit === 'KG' ?
                            this.state.editRecord?.not_tendered?.weight_kg || 0 :
                            this.state.weight_unit === 'LB' ? this.state.editRecord?.not_tendered?.weight_lbs || 0 :
                              0) || 0}${this.state.weight_unit} | ${this.state.editRecord?.not_tendered?.pcs || 0}CF`}
                        </span>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card size='small' className='custom-card custom-padding' title='Available Configurations'>
          <Row gutter={[{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12
          }, {
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12
          },]} className='p-12'>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='table-overflow'
            >
              <Table
                className='custom-table w-100'
                dataSource={bagSummary}
                columns={obcolumns}
                pagination={false}
                rowKey='id'
                rowSelection={{
                  type: 'checkbox',
                  onChange: (_, rows) => this.handleCheckboxChange(rows),
                  hideSelectAll: true,
                  selectedRowKeys: this.state.selected
                }}
              />
            </Col>
            <Col xs={24}>
              <Form layout='vertical' >
                <Form.Item label='Hints' className='hint-form'>
                  <Input.TextArea placeholder='Enter hints'
                    value={this.state.editHints}
                    onChange={(e) => this.setState({ editHints: e.target.value })}
                    disabled
                  />
                </Form.Item>
                <Form.Item label='Notes'>
                  <Input.TextArea placeholder='Enter notes'
                    value={this.state.editNotes}
                    onChange={(e) => this.setState({ editNotes: e.target.value })}
                    disabled
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col className='ml-auto'>
              <Button
                disabled={!this.state.bagSelected}
                type='primary'
                className='custom-button mr-4'
                onClick={this.updateBaggage}
              >Update
              </Button>
            </Col>
          </Row>
        </Card>
      </Modal>
    )
  }
  //UPDATE BAGGAGE POSITIONS
  updateBaggage = () => {
    this.setState({
      editCargoMD: this.state.bagSelected?.md,
      editCargoP96: this.state.editBagP96 > 0 ? this.state.bagSelected?.pmc - this.state.editBagP96 : this.state.bagSelected?.pmc,
      editCargoP88: this.state.editBagP88 > 0 ? this.state.bagSelected?.pag - this.state.editBagP88 : this.state.bagSelected?.pag,
      editCargoLD3: this.state.editBagLD3 > 0 ? this.state.bagSelected?.ld3 - this.state.editBagLD3 : this.state.bagSelected?.ld3,
      editCargoLD2: this.state.editBagLD2 > 0 ? this.state.bagSelected?.ld2 - this.state.editBagLD2 : this.state.bagSelected?.ld2,
      hints: this.state.editHints,
      notes: this.state.editNotes,
      selectedRow: this.state.bagSelected.id
      // remainingP96: 0,
      // remainingP88: 0,
      // remainingLD3: 0,
      // remainingLD2: 0,
      // remainingMD: 0,
    }, () => this.setState({
      selected: [],
      bagSelected: null,
      optimalBuild: false,
      //showAlloc: true,
    }))
  }
  //HANDLE CHECKBOX CHANGE
  handleCheckboxChange = (selectedRow) => {
    this.setState({
      selected: [selectedRow[selectedRow?.length - 1]?.id] || [],
      bagSelected: selectedRow[selectedRow?.length - 1] || null,
      editHints: selectedRow[selectedRow?.length - 1]?.hint || null,
      editNotes: selectedRow[selectedRow?.length - 1]?.note || null,
    })
  }
  //HANDLE CHECKBOX CHANGE
  handleFilterChange = (e) => {
    let destFilters = this.state.destFilters
    if (e.target.checked) {
      destFilters.push(e.target.value)
    } else {
      destFilters = destFilters.filter((item) => item !== e.target.value)
    }
    this.setState({ destFilters: destFilters })
  }
  //SAVE CHANGES
  submit = async (action) => {
    if (this.state.editRecord?.id) {
      let headers = {
        station: this.state.station,
        user_id: this.state.user_id,
      }
      let formdata = {}
      let url = ''

      if (action == 'add' || action === 'edit') {
        url = `/flight_plan/${action}/${parseInt(this.state.editRecord.id)}`
        if (this.state.editAllocRecord) {
          formdata.id = this.state.editAllocRecord?.id
        }
        formdata.comm1 = this.state.editAllocRecord ? this.state.editTComm1 || '' : this.state.editComm1 || ''
        formdata.comm2 = this.state.editAllocRecord ? this.state.editTComm2 || '' : this.state.editComm2 || ''
      } else if (action == 'delete') {
        url = `/flight_plan/${action}/${parseInt(this.state.editRecord?.id)}/${parseInt(this.state.deleteAllocID)}`
      }

      let awaitMehod = action == 'add' || action === 'edit' ? Http.post(url, formdata, { headers }) : Http.delete(url, { headers })
      await awaitMehod.then((data) => {
        if (data && data?.status === 200) {
          //CLEAR LOAD PLAN FORM
          if (action == 'add') this.clearEditAllocForm()
          //CLEAR LOAD PLAN TABLE FORM
          if (this.state.editAllocRecord) this.clearAllocTableForm()

          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: 'Invalid Input',
      })
    }
  }
  //SET RECORD EDITABLE
  setEdit = (record, type = '') => {
    // console.log('Record', record)
    let flight_index1 = this.state.flightData.findIndex((item) => item.id === record.id)
    this.setState({
      editRecordId: record?.id,
      flightDataIndex: flight_index1
    })
    let calcRemainingPos = record?.details?.length > 0 ? this.calcPos(record?.details) : ''
    let org_dst = record?.org_stn + record?.dest_stn
    let goal = ch_wt_goals.find((item) => item.lane == org_dst)
    let goal_lbs = Math.round(goal?.lbs)
    this.setState({ chblWgtGoal: goal_lbs })

    if (type) {
      if (type === 'DETAILS') {
        this.setState({
          editRecord: record,
          editFcst: record?.fcst || 0,
          editBagP96: record?.bag?.p96 || 0,
          editBagP88: record?.bag?.p88 || 0,
          editBagLD3: record?.bag?.ld3 || 0,
          editBagLD2: record?.bag?.ld2 || 0,
          editCargoMD: record?.cargo?.md || 0,
          editCargoP96: record?.cargo?.p96 || 0,
          editCargoP88: record?.cargo?.p88 || 0,
          editCargoLD3: record?.cargo?.ld3 || 0,
          editCargoLD2: record?.cargo?.ld2 || 0,
          editWgtValue: this.state.weight_unit === 'KG' ? parseFloat(record?.weight_kg || 0)?.toFixed(2) : parseFloat(record?.weight_lbs || 0)?.toFixed(2),
          editWgtUnit: this.state.weight_unit === 'KG' ? 'KG' : 'LB',
          editBuPrio: record?.bu_prio || false,
          editIsExceeded: record?.load_plan || false,
          previewFwd: record?.fwd_conf?.path,
          previewAft: record?.aft_conf?.path,
          lastUpdatedBy: record?.last_updated_by,
          lastUpdatedAt: record?.last_updated_at,
          isWideBody: record?.acpt_body,
          remainingP96: calcRemainingPos?.P96 ? (record?.cargo?.p96 || 0) - calcRemainingPos.P96 : (record?.cargo?.p96 || 0),
          remainingP88: calcRemainingPos?.P88 ? (record?.cargo?.p88 || 0) - calcRemainingPos.P88 : (record?.cargo?.p88 || 0),
          remainingLD3: calcRemainingPos?.LD3 ? (record?.cargo?.ld3 || 0) - calcRemainingPos.LD3 : (record?.cargo?.ld3 || 0),
          remainingLD2: calcRemainingPos?.LD2 ? (record?.cargo?.ld2 || 0) - calcRemainingPos.LD2 : (record?.cargo?.ld2 || 0),
          remainingMD: calcRemainingPos?.MD ? (record?.cargo?.md || 0) - calcRemainingPos.MD : (record?.cargo?.md || 0),
          searchFlightNo: record?.flight_no?.toUpperCase() || '',
          searchDate: record?.dept_date ? moment.utc(record.dept_date).tz(this.state.timezone) :
            record?.schdl ? moment.tz(record.schdl, this.state.timezone) :
              record?.flight_date ? moment(record.flight_date) : '',
        }, () => this.bookedULD())
      } else {
        this.setState({
          editRecord: record,
          lastUpdatedBy: record?.last_updated_by,
          lastUpdatedAt: record?.last_updated_at,
          isWideBody: record?.acpt_body,
          editAllocArray: record?.details || [],
          searchFlightNo: record?.flight_no?.toUpperCase() || '',
          searchDate: record?.dept_date ? moment.utc(record.dept_date).tz(this.state.timezone) :
            record?.schdl ? moment.tz(record.schdl, this.state.timezone) :
              record?.flight_date ? moment(record.flight_date) : '',
        }, () => this.bookedULD())
      }
    } else {
      this.setState({
        editRecord: record,
        editFcst: record?.fcst || 0,
        editBagP96: record?.bag?.p96 || 0,
        editBagP88: record?.bag?.p88 || 0,
        editBagLD3: record?.bag?.ld3 || 0,
        editBagLD2: record?.bag?.ld2 || 0,
        editCargoMD: record?.cargo?.md || 0,
        editCargoP96: record?.cargo?.p96 || 0,
        editCargoP88: record?.cargo?.p88 || 0,
        editCargoLD3: record?.cargo?.ld3 || 0,
        editCargoLD2: record?.cargo?.ld2 || 0,
        notes: record?.cargo?.notes || '',
        hints: record?.cargo?.hints || '',
        editHints: '',
        editNotes: '',
        editWgtValue: this.state.weight_unit === 'KG' ? parseFloat(record?.weight_kg || 0)?.toFixed(2) : parseFloat(record?.weight_lbs || 0)?.toFixed(2),
        editWgtUnit: this.state.weight_unit === 'KG' ? 'KG' : 'LB',
        editBuPrio: record?.bu_prio || false,
        editIsExceeded: record?.load_plan || false,
        previewFwd: record?.fwd_conf?.path,
        previewAft: record?.aft_conf?.path,
        lastUpdatedBy: record?.last_updated_by,
        lastUpdatedAt: record?.last_updated_at,
        isWideBody: record?.acpt_body,
        editAllocArray: record?.details || [],
        remainingP96: calcRemainingPos?.P96 ? (record?.cargo?.p96 || 0) - calcRemainingPos.P96 : (record?.cargo?.p96 || 0),
        remainingP88: calcRemainingPos?.P88 ? (record?.cargo?.p88 || 0) - calcRemainingPos.P88 : (record?.cargo?.p88 || 0),
        remainingLD3: calcRemainingPos?.LD3 ? (record?.cargo?.ld3 || 0) - calcRemainingPos.LD3 : (record?.cargo?.ld3 || 0),
        remainingLD2: calcRemainingPos?.LD2 ? (record?.cargo?.ld2 || 0) - calcRemainingPos.LD2 : (record?.cargo?.ld2 || 0),
        remainingMD: calcRemainingPos?.MD ? (record?.cargo?.md || 0) - calcRemainingPos.MD : (record?.cargo?.md || 0),
        searchFlightNo: record?.flight_no?.toUpperCase() || '',
        searchDate: record?.dept_date ? moment.utc(record.dept_date).tz(this.state.timezone) :
          record?.schdl ? moment.tz(record.schdl, this.state.timezone) :
            record?.flight_date ? moment(record.flight_date) : '',
      }, () => this.bookedULD())
    }
  }

  bookedULD = () => {
    this.setState({ loadplanvisible: true })
    // console.log(this.state.editRecord.ulds_out, 'ulds_out')
    // console.log('Before',this.state.mergeEditRecord)
    // this.setState({ 
    //   loadplanvisible: true,
    //   mergeEditRecord: [] 
    // })
    let data = cloneDeep(this.state.editRecord)
    let awbsIn = []
    data.ulds_out && data.ulds_out.map(uld => {
      let slu = uld?.info?.remark && uld?.info?.remark?.includes('SLU') ? true : false
      uld.awbs_in && uld.awbs_in?.map(awb => {
        if (cookies.get('station') != awb?.dest) {
          let ch_wgt = 0
          // let ch_vol = 0
          if (awb?.awb && awb?.awb?.info) {
            let sdctotals_wgt = awb?.awb?.info?.sdctotals && awb?.awb?.info?.sdctotals?.wgt ? parseFloat(awb?.awb?.info?.sdctotals?.wgt) : 0
            let dims_wgt = awb?.awb?.info?.dims && awb?.awb?.info?.dims?.wgt ? parseFloat(awb?.awb?.info?.dims?.wgt) : 0
            // let dims_vol = awb?.awb?.info?.dims && awb?.awb?.info?.dims?.vol ? parseFloat(awb?.awb?.info?.dims?.vol) : 0
            // let sdctotals_vol = awb?.awb?.info?.sdctotals && awb?.awb?.info?.sdctotals?.vol ? parseFloat(awb?.awb?.info?.sdctotals?.vol) : 0
            ch_wgt = sdctotals_wgt > dims_wgt ? sdctotals_wgt : dims_wgt
            // ch_vol = sdctotals_vol > dims_vol ? sdctotals_vol : dims_vol
          }

          const index = awbsIn.findIndex(x => x.awb_no === awb.awb_no)
          // if(awb.awb_no == '00630497073')
          // {
          //   console.log('awb5654', awb)
          // }
          if (index == -1) {
            // console.log('add', awb)
            awbsIn.push({
              ...awb,
              on_hand: true,
              pre_build: false,
              // ch_vol,
              ch_wgt,
              priority: awb?.awb?.info?.priority || null,
              slu_uld: slu ? [{
                uld_no: uld.uld_no,
                pcs: awb.pcs,
                wgt: awb.wgt?.value,
                remark: 'SLU',
                id: uld.id,
              }] : [],
            })
          } else {
            awb?.inbound ? awbsIn[index].location = [...new Set(awbsIn[index]?.location?.split(',').concat(awb?.location?.split(',')))].filter(Boolean).join(',') : {}
            awbsIn[index].pcs += awb.pcs
            // if(awb.awb_no == '00630497073')
            // {
            //   console.log('wgt before', awbsIn[index].wgt.value, awb?.wgt?.value)
            // }
            awbsIn[index].wgt.value += awb?.wgt?.value || 0
            // if(awb.awb_no == '00630497073')
            // {
            //   console.log('wgt after', awbsIn[index].wgt.value)
            // }
            awbsIn[index].vol.value += awb?.vol?.value || 0
            awbsIn[index].spl_code = [...new Set(awbsIn[index]?.spl_code?.split(',').concat(awb.spl_code?.split(',')))].join(','),
            awbsIn[index].slu_uld = slu ? [...awbsIn[index].slu_uld, {
              uld_no: uld.uld_no,
              pcs: awb.pcs,
              wgt: awb.wgt?.value,
              remark: 'SLU',
              id: uld.id,
            }] : awbsIn[index].slu_uld
          }
        }
      })
    })

    data.awb_segments && data.awb_segments.map(segment => {
      const pre_build = segment.space.ldc || segment.space.ldp || segment.space.udp ? true : false
      const index = awbsIn.findIndex(x => x.awb_no === segment.awb_no)
      let ch_wgt = 0
      // let ch_vol = 0
      if (segment?.awb && segment?.awb?.info) {
        let sdctotals_wgt = segment?.awb?.info?.sdctotals && segment?.awb?.info?.sdctotals?.wgt ? parseFloat(segment?.awb?.info?.sdctotals?.wgt) : 0
        let dims_wgt = segment?.awb?.info?.dims && segment?.awb?.info?.dims?.wgt ? parseFloat(segment?.awb?.info?.dims?.wgt) : 0
        // let dims_vol = segment?.awb?.info?.dims && segment?.awb?.info?.dims?.vol ? parseFloat(segment?.awb?.info?.dims?.vol) : 0
        // let sdctotals_vol = segment?.awb?.info?.sdctotals && segment?.awb?.info?.sdctotals?.vol ? parseFloat(segment?.awb?.info?.sdctotals?.vol) : 0
        ch_wgt = sdctotals_wgt > dims_wgt ? sdctotals_wgt : dims_wgt
        // ch_vol = sdctotals_vol > dims_vol ? sdctotals_vol : dims_vol
      }
      if (index == -1) {
        awbsIn.push({
          id: data.id,
          awb_no: segment.awb_no,
          awb_id: segment.awb_id,
          pcs: segment.space.pcs,
          avl_pcs: segment.space.pcs,
          wgt: {
            unit: segment.awb.weight_unit,
            value: segment.space.wgt
          },
          vol: {
            value: segment.space.vol,
            unit: segment.awb.vol_unit
          },
          dest: segment.awb.dest,
          prod_code: segment.awb.prod_code,
          spl_code: segment.awb.spl_code,
          on_hand: false,
          pre_build: pre_build,
          // ch_vol,
          ch_wgt,
          showred: segment.showred ? true : false,
          priority: segment.awb.info?.priority || null,
        })
      } else {
        awbsIn[index].pre_build = pre_build
      }
    })
    // console.log('After', awbsIn)
    this.setState({ mergeEditRecord: awbsIn }, this.fetchUlds)
  }

  addULD = (record) => {
    this.setState({
      addULD: true,
      flightDetails: record,
    }
    , () => this.fetchUlds()
    ) /*console.log(this.state.editRecord, 'editRecord') */

  }
  // //OPEN CHECKLIST MODAL 
  // showCheckList(record) {
  //   let title  = record?.flight_no && record?.dept_time ? 'Checklist for  - '+ formatFlightNo(record.flight_no, record.dept_time) : ''
  //   this.setState ({ 
  //     checkListOpen: true,
  //     checkListTitle : title
  //   })
  // }


  //CHECK FOR ULD TYPE
  checkULDType = (uld) => {
    let type = ''
    if (uld?.blk && (!uld?.uld_no?.startsWith('C') && !uld?.uld_no?.endsWith('#'))) {
      type = 'BLK'
    } else {
      if (uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
        type = 'SLU'
      } else if (!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.dest && uld?.dest !== this.state.station && uld?.master) {
        type = 'PTU'
      } else if (uld?.uld_no?.startsWith('C') || uld?.uld_no?.endsWith('#')) {
        type = 'CART'
      }
    }
    return type
  }
  //CALCULATE REMAINING POSITIONS
  calcPos = (details) => {
    let countp96 = 0
    let countp88 = 0
    let countld3 = 0
    let countld2 = 0
    let countmd = 0

    details?.map((item) => {
      switch (item?.type) {
      case 'P96':
        countp96 += parseInt(item?.pos || 0)
        break
      case 'P88':
        countp88 += parseInt(item?.pos || 0)
        break
      case 'LD3':
        countld3 += parseInt(item?.pos || 0)
        break
      case 'LD2':
        countld2 += parseInt(item?.pos || 0)
        break
      case 'MD':
        countmd += parseInt(item?.pos || 0)
        break
      default:
        break
      }
    })

    return {
      P96: countp96,
      P88: countp88,
      LD3: countld3,
      LD2: countld2,
      MD: countmd,
    }
  }
  //SET ALLOCATIONS VALUES
  editAllocTableForm = (record) => {
    this.setState({
      editAllocRecord: record,
      editTPos: record?.pos,
      editTType: record?.type,
      editTWorkArea: record?.work_area,
      editTULDAWB: record?.uld_awb_no,
      editTProd: record?.prod_code,
      editTComm1: record?.comm1,
      editTComm2: record?.comm2,
    })
  }
  //CLEAR FLIGHT FORM
  clearEditFlightForm = () => {
    this.setState({
      editRecord: null,
      editFcst: 0,
      editBagP96: 0,
      editBagP88: 0,
      editBagLD3: 0,
      editBagLD2: 0,
      editCargoMD: 0,
      editCargoP96: 0,
      editCargoP88: 0,
      editCargoLD3: 0,
      editCargoLD2: 0,
      editWgtValue: 0,
      editWgtUnit: this.state.weight_unit === 'KG' ? 'KG' : 'LB',
      editBuPrio: false,
      editIsExceeded: false,
      editAllocArray: [],
      previewFwd: null,
      previewAft: null,
      lastUpdatedAt: '',
      lastUpdatedBy: '',
      isWideBody: false,
      searchDate: '',
      searchFlightNo: '',
    })
  }
  //CLEAR ALLOCATIONS FORM
  clearEditAllocForm = () => {
    this.setState({
      editPos: '',
      editType: '',
      editWorkArea: '',
      editULDAWB: '',
      editProd: '',
      editComm1: '',
      editComm2: '',
    })
  }
  //CLEAR ALLOCATIONS TABLE FORM
  clearAllocTableForm = () => {
    this.setState({
      editAllocRecord: null,
      deleteAllocID: null,
      editTPos: '',
      editTType: '',
      editTWorkArea: '',
      editTULDAWB: '',
      editTProd: '',
      editTComm1: '',
      editTComm2: '',
    })
  }
  //SORT
  sort = (array, on_load) => {
    let sort = this.state.sort
    array && array.sort(function (keyA, keyB) {
      let a = sort === 'ac_type' ? keyA[sort] : keyA[sort] ? moment(keyA[sort]) : null
      let b = sort === 'ac_type' ? keyB[sort] : keyB[sort] ? moment(keyB[sort]) : null

      let va = (a === null) ? '' : '' + a
      let vb = (b === null) ? '' : '' + b

      if (sort === 'notes_date') {
        return va > vb ? -1 : (va === vb ? 0 : 1)
      } else {
        return va > vb ? 1 : (va === vb ? 0 : -1)
      }
    })

    if (!on_load) {
      this.setState({ flightData: [] }, () => this.setState({ flightData: array }, () => {
        if (this.state.departedFlightsCheck) {
          this.departedFlightsFilter()
        }
      }))
    }
  }
  sortDodCC = (flightData) => {
    let sort = this.state.dodsort
    flightData && flightData.sort(function (keyA, keyB) {
      let a = sort === 'dest_stn' || sort === 'flight_no' ? keyA[sort] : keyA[sort] ? moment(keyA[sort]) : null
      let b = sort === 'dest_stn' || sort === 'flight_no' ? keyB[sort] : keyB[sort] ? moment(keyB[sort]) : null
      let va = (a === null) ? '' : '' + a
      let vb = (b === null) ? '' : '' + b
      return va > vb ? 1 : (va === vb ? 0 : -1)
    })
    return flightData
  }
  //FILTER DEPARTED FLIGHTS
  departedFlightsFilter = () => {
    if (this.state.departedFlightsCheck || this.state.amberFlightsCheck || this.state.redFlightsCheck) {
      let filtered_data = cloneDeep(this.state.flightData)
      let filters = {}
      if (this.state.departedFlightsCheck) {
        filtered_data = filtered_data?.filter((flight) => moment(flight?.dept_time).valueOf() > moment().tz(this.state.timezone).valueOf())
      }

      if (this.state.amberFlightsCheck) {
        filters.amberEquals = item => item.cccolour === 'amber'
      }
      if (this.state.redFlightsCheck) {
        filters.redEquals = item => item.cccolour === 'red'
      }
      if (this.state.amberFlightsCheck || this.state.redFlightsCheck) {
        filtered_data = filtered_data?.filter(item => Object.values(filters)?.some(f => f(item)))
      }

      this.setState({ displayData: filtered_data })
    }
    else {
      this.setState({ displayData: [] })
    }
  }
  //SHOW NOTES
  showFlightNotes = (record) => {
    let note = record?.awb_no ? record.awb_no : record?.flight_no ? record.flight_no : null
    if (record.flight_no && record.flight_date) {
      note = note + '/' + moment(record.flight_date).format(dateOnly)
    }
    let title = record?.flight_no && record?.dept_time ? 'Flight Notes - ' + formatFlightNo(record.flight_no, record.dept_time) : record?.awb_no ? 'AWB Notes - ' + formatAWBNo(record.awb_no) :
      record?.flight_no && record?.flight_date ? 'Flight Notes - ' + formatFlightNo(record.flight_no, record.flight_date) : record?.awb_no ? 'AWB Notes - ' + formatAWBNo(record.awb_no) : ''
    this.setState({
      searchString: note,
      flightNotes: true,
      notesTitle: title,
      noteType: this.state.radioValue === 2 ? 'checklist' : null
    })
  }
  //SET FILTERS
  setFilters = (type) => {
    let current_date = moment().tz(this.state.timezone).format(dateFormat)
    let selected_date_time = ''
    if (type === 'start') {
      selected_date_time = moment(this.state.start).format(dateFormat)

      if (selected_date_time < current_date) {
        this.setState({
          flightData: [],
          page: 1,
          end: moment(this.state.start).add(1, 'day'),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
        }, () => this.fetchFlights())
      } else if (selected_date_time === current_date) {
        this.setState({
          flightData: [],
          page: 1,
          end: moment(this.state.start),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
        }, () => this.fetchFlights())
      } else if (selected_date_time > current_date) {
        this.setState({
          flightData: [],
          page: 1,
          end: moment(this.state.start),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
        }, () => this.fetchFlights())
      }
    } else if (type === 'end') {
      selected_date_time = moment(this.state.end).format(dateFormat)

      if (selected_date_time < current_date) {
        this.setState({
          flightData: [],
          page: 1,
          start: moment(this.state.end),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            second: 59,
          }),
        }, () => this.fetchFlights())
      } else if (selected_date_time === current_date) {
        this.setState({
          flightData: [],
          page: 1,
          start: moment(this.state.end),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
        }, () => this.fetchFlights())
      } else if (selected_date_time > current_date) {
        this.setState({
          flightData: [],
          page: 1,
          start: moment(this.state.end).subtract(1, 'day'),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
        }, () => this.fetchFlights())
      }
    } else if (type === 'stime' || type === 'etime') {
      this.setState({
        flightData: [],
        page: 1,
      }, () => this.fetchFlights())
    }
  }
  //SET DATES DISABLED ON CONDITION
  disabledDate = (current) => {
    let prev = moment().tz(this.state.timezone).subtract(1, 'month').format(dateFormat)
    let next = moment().tz(this.state.timezone).add(1, 'week').format(dateFormat)
    return current && (current < moment(prev, dateFormat) || current > moment(next, dateFormat))
  }

  openBP = () => {
    this.setState({ showBP: true })
  }

  submitData = async () => {
    let headers = {
      station: this.state.station,
      user_id: this.state.user_id,
    }
    let formdata = {
      bag: {},
      cargo: {},
      wgt: {},
    }
    formdata.fcst = this.state.editFcst || 0
    formdata.bag.p96 = this.state.editBagP96 || 0
    formdata.bag.p88 = this.state.editBagP88 || 0
    formdata.bag.ld3 = this.state.editBagLD3 || 0
    formdata.bag.ld2 = this.state.editBagLD2 || 0
    formdata.cargo.md = this.state.editCargoMD || 0
    formdata.cargo.p96 = this.state.editCargoP96 || 0
    formdata.cargo.p88 = this.state.editCargoP88 || 0
    formdata.cargo.ld3 = this.state.editCargoLD3 || 0
    formdata.cargo.ld2 = this.state.editCargoLD2 || 0
    formdata.wgt.value = this.state.editWgtValue || 0
    formdata.wgt.unit = this.state.editWgtUnit
    formdata.bu_prio = this.state.editBuPrio
    formdata.load_plan = this.state.editIsExceeded
    formdata.cargo.hint = this.state.hints
    formdata.cargo.note = this.state.notes
    formdata.cargo.id = this.state.selectedRow
    // formdata.details = this.state.editAllocArray

    await Http.put(`/flight_control_update/${parseInt(this.state.editRecord.id)}`, formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.setState({ showSuccess: true })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
  }
  checkFlightDate = (record) => {
    let hours = moment.utc(record.flight_date).format('HH')
    let mins = moment.utc(record.flight_date).format('mm')
    if (hours === '00' && mins === '00') {
      return moment(record.flight_date).format(dateOnly)
    } else {
      return moment.utc(record.flight_date).tz(this.state.timezone).format(dateOnly)
    }
  }

  emailLoadPlan = async () => {
    const divContent = this.modalRef.current.innerHTML
    // console.log('divContent',divContent)
    Http.post('/email_load_plan', {
      emailContent: divContent,
      emailSubject: `Build Plan: ${this.state.editRecord?.flight_no || 'N/A'}/${this.state.editRecord?.org && this.state.editRecord?.dest ? (this.state.editRecord.org.origin) + '-' + (this.state.editRecord.dest.arriv) : 'N/A'} ${this.state.editRecord?.flight_date ? moment(this.state.editRecord?.flight_date).format(dateOnly)?.toUpperCase() : 'N/A'}`,
      email: 'jessio@orgadynamics.com'
    })
      .then((data) => {
        if (data && data?.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      }).catch((err) => {
        console.log('err', err)
      })
  }

  handleAnswers = (e, question) => {
    let questionsData = this.state.questionsData
    if (e?.target?.type === 'textarea') {
      questionsData[question].answer = e?.target?.value || null
    } else {
      questionsData[question].answer = e
      questionsData[question].comment = null
      if (questionsData[question].details.nested === 'Yes') {
        let parent_id = questionsData[question].id
        questionsData.map((item, index) => {
          if (item?.parent_id === parent_id) {
            questionsData[index].showchild = e === 'Yes' ? true : false
            questionsData[index].answer = null
          }
        })
      }
    }
    this.setState({ questionsData })
  }
  handleComment = (e, question) => {
    let questionsData = this.state.questionsData
    questionsData[question].comment = e?.target?.value || null
    this.setState({ questionsData })
  }

  saveAnswers = async () => {
    let headers = {
      station: this.state.station,
      user_id: this.state.user_id,
    }
    let milestones_id = this.state.quizMilestonid
    let answers = []
    let isAnswered = true
    this.state.questionsData.map((question) => {
      if (!question?.answer || question?.answer === null || question?.answer === '') {
        if (!question.parent_id || question.parent_id && question?.showchild) {
          isAnswered = false
        }
      } if (question?.details?.type === 'yes/no' && question?.answer === question?.details?.comment && (question?.comment === null || question?.comment === '')) {
        if (!question.parent_id || question.parent_id && question?.showchild) {
          isAnswered = false
        }
      } if (question?.details?.type === 'mcq' && question.details.options && question.details.options.length > 0
        && question.details.options.find((option) => option?.name === question.answer)?.commentsReq
        && (question?.comment === null || question?.comment === '')) {
        if (!question.parent_id || question.parent_id && question?.showchild) {
          isAnswered = false
        }
      }
      answers.push({
        question_id: question?.id,
        answer: question?.answer,
        comment: question?.comment,
      })
    })
    if (!isAnswered) {
      notification.destroy()
      notification.warning({
        message: 'Warning',
        description: 'Please answer all questions',
      })
    } else {
      let data = {
        answers: answers,
        flight_id: this.state.quizModalFlight.id,
        milestones_id: milestones_id,
      }
      if (this.state.quizModalFlight.answers?.id) {
        Http.put(`/answer/${this.state.quizModalFlight.answers?.id}`, data, { headers })
          .then((resp) => {
            if (resp?.data?.data) {
              this.setState({ openQuizModal: false })
            } else {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: resp?.data?.message,
              })
            }
          })
      } else {
        Http.post('/answer', data, { headers })
          .then((resp) => {
            if (resp?.data?.data) {
              this.setState({ openQuizModal: false })
            } else {
              notification.destroy()
              notification.error({
                message: 'Failed',
                description: resp?.data?.message,
              })
            }
          })
      }
    }
  }

  getAct = (flight, ms_id, format) => {
    let answers = flight?.answers
    if (answers && answers.length > 0) {
      let answer = answers.find((item) => item?.milestones_id === ms_id || item?.type === ms_id)
      if (answer) {
        if (answer?.updatedAt)
          return moment.utc(answer?.updatedAt).tz(this.state.timezone).format(format)
        else
          return null
      } else {
        return null
      }
    } else {
      return null
    }
  }

  checkStatusColour = (dept, act, amber, flight_leg, milestone, greymilestones, ignoremilestones) => {
    let greyfound = false
    let ignorefound = false
    greymilestones.findIndex((item) => item === milestone.id) > -1 ? greyfound = true : greyfound = false
    ignoremilestones.findIndex((item) => item === milestone.id) > -1 ? ignorefound = true : ignorefound = false
    if (flight_leg?.cancel) {
      // eslint-disable-next-line no-console
      console.log('flight_let cancel', flight_leg?.cancel)
    }
    if (flight_leg && flight_leg?.cancel || greyfound) {
      return 'bg-grey'
    } else if (!act || ignorefound) {
      if ((moment().tz(this.state.timezone)).diff(dept, 'minutes') > 0) {
        return 'bg-red'
      } else {
        //console.log('dept.diff(moment().tz(this.state.timezone) ',dept.diff(moment().tz(this.state.timezone), 'minutes'))
        if (amber && amber !== undefined && dept.diff(moment().tz(this.state.timezone), 'minutes') > 0 && dept.diff(moment().tz(this.state.timezone), 'minutes') < parseInt(amber)) {
          return 'bg-amber'
        } else {
          return 'bg-white'
        }
      }
    } else {
      // if (moment(act).diff(dept, 'minutes') > 0) {
      //   return 'bg-red'
      // } else {
      //   return 'bg-green'
      // }
      return 'bg-green'
    }
  }

  getCountdown = (dept, act, cutoff, amber) => {
    let flightData = this.state.flightData
    let date = moment.utc(dept).tz(this.state.timezone).subtract(cutoff, 'minutes')
    let curr_diff = date.diff(moment().tz(this.state.timezone), 'minutes')
    if (!act) {
      if (curr_diff > 0) {
        //return curr_diff
        if (amber) {
          let amber_date = date.subtract(amber, 'minutes')
          let amber_curr_diff = amber_date.diff(moment().tz(this.state.timezone), 'minutes')
          if (amber_curr_diff > 0) {
            return <Countdown className='hidden-countdown' onFinish={() => {
              this.setState({ flightData: flightData })
            }} value={moment(amber_date)} format='HH:mm' />
          } else {
            return <Countdown className='hidden-countdown' onFinish={() => {
              this.setState({ flightData: flightData })
            }} value={moment(date)} format='HH:mm' />
          }
        } else {
          return <Countdown className='hidden-countdown' onFinish={() => {
            this.setState({ flightData: flightData })
          }} value={moment(date)} format='HH:mm' />
        }
      } else {
        return null
      }
    } else {
      return null
    }
  }

  handleNoRideEvent = (action, details) => {
    let flightData = this.state.flightData
    let index = flightData.findIndex((item) => item.id == details.flight_id)
    let norideData = []
    if (index > -1) {
      if (action === 'INSERT') {
        if (!Array.isArray(flightData[index].norides)) {
          flightData[index].norides = []
        }
        flightData[index].norides.push(details.data)
        norideData = flightData[index].norides
      } else if (action === 'UPDATE') {
        let norideIndex = flightData[index].norides.findIndex((item) => item.id === details.data.id)
        if (norideIndex > -1) {
          flightData[index].norides[norideIndex] = details.data
          norideData = flightData[index].norides
        }
      } else if (action === 'DELETE') {
        let norideIndex = flightData[index].norides.findIndex((item) => parseInt(item.id) === parseInt(details.noride_id))
        if (norideIndex > -1) {
          flightData[index].norides.splice(norideIndex, 1)
          norideData = flightData[index].norides
        }
      }
      this.setState({ flightData: flightData }, () => {
        if (parseInt(details?.flight_id) === parseInt(this.state.noride_flight)) {
          this.setState({ norideData: [] }, () => this.setState({ norideData: norideData }))
        }
      })
    }
  }

  fetchNoride = async () => {
    if (this.state.noride_flight) {
      await Http.get(`/norides/${this.state.noride_flight}`)
        .then((resp) => {
          if (resp?.data?.data) {
            this.setState({
              norideData: resp?.data?.data,
              openNoRideModal: true,
              noride_ldd: this.state.quizModalFlight?.noride?.ldd_name || null,
              noride_ala: this.state.quizModalFlight?.noride?.ala_name || null,
              questionsData: [],
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message,
            })
          }
        })
    }
  }
  addNoRide = async () => {
    if (this.state.noride_container_id && this.state.noride_type && this.state.noride_cutcode && this.state.noride_cutreason && this.state.noride_cutby /*&& this.state.noride_comment*/) {
      let headers = {
        station: this.state.station,
        user_id: this.state.user_id,
      }
      let formdata = new FormData()
      formdata.append('container_id', this.state.noride_container_id || '')
      formdata.append('type', this.state.noride_type || '')
      formdata.append('cut_code', this.state.noride_cutcode || '')
      formdata.append('cut_reason', this.state.noride_cutreason || '')
      formdata.append('cut_by', this.state.noride_cutby || '')
      formdata.append('comments', this.state.noride_comment || '')
      formdata.append('attach_conf', this.state.noride_attach_conf || '')
      formdata.append('flight_id', this.state.noride_flight || '')
      formdata.append('trigger', 'user')
      await Http.post('/noride', formdata, { headers })
        .then((resp) => {
          if (resp?.data?.data) {
            this.setState({
              // openNoRideModal: false,
              // quizModalFlight: null,
              // noride_flight: null,
              noride_container_id: null,
              noride_type: null,
              noride_cutcode: null,
              noride_cutreason: null,
              cut_reasons: [],
              noride_cutby: null,
              noride_comment: null,
              noride_attach_conf: false,
              noride_fileList: [],
            })
            notification.destroy()
            notification.success({
              message: 'Success',
              description: resp?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message,
            })
          }
        })
    } else {
      notification.destroy()
      notification.error({
        message: 'Warning',
        description: 'Please fill all the fields!',
      })
    }
  }
  editNoRide = async (record) => {
    let norideData = this.state.norideData
    let index = norideData.findIndex((item) => item.id === record.id)
    if (index > -1) {
      // norideData[index].edit = true
      this.setState({
        // norideData: norideData,
        edit_noride_id: norideData[index].id,
        edit_noride_container_id: record.container_id,
        edit_noride_type: record.type,
        edit_noride_cutcode: record.cut_code,
        edit_noride_cutreason: record.cut_reason,
        edit_cut_reasons: cut_codes.find((item) => item?.code === record.cut_code)?.reasons,
        edit_noride_cutby: record.cut_by,
        edit_noride_comment: record.comments,
        edit_noride_flight: record.flight_id,
        edit_noride_attach_conf: record.attach_conf ? JSON.stringify(record?.attach_conf) : [],
        edit_noride_fileList: record.attach_conf?.name ? [{ name: record.attach_conf?.name }] : []
      })
    }
  }
  clearNoRide = () => {
    this.setState({ edit_noride_id: null, })
  }

  saveNoRide = async (record) => {
    let headers = {
      station: this.state.station,
      user_id: this.state.user_id,
    }

    let formdata = new FormData()
    formdata.append('container_id', this.state.edit_noride_container_id || '')
    formdata.append('type', this.state.edit_noride_type || '')
    formdata.append('cut_code', this.state.edit_noride_cutcode || '')
    formdata.append('cut_reason', this.state.edit_noride_cutreason || '')
    formdata.append('cut_by', this.state.edit_noride_cutby || '')
    formdata.append('comments', this.state.edit_noride_comment || '')
    formdata.append('attach_conf', this.state.edit_noride_attach_conf || '')
    formdata.append('flight_id', this.state.edit_noride_flight || '')
    formdata.append('trigger', 'user')
    await Http.put(`/noride/${record.id}`, formdata, { headers })
      .then((resp) => {
        if (resp?.data?.data) {
          this.setState({
            edit_noride_id: null,
            edit_noride_container_id: null,
            edit_noride_type: null,
            edit_noride_cutcode: null,
            edit_noride_cutreason: null,
            edit_cut_reasons: [],
            edit_noride_cutby: null,
            edit_noride_comment: null,
            edit_noride_flight: null,
          })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: resp?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        }
      })

  }
  deleteNoRide = async (record) => {
    let headers = {
      station: this.state.station,
      user_id: this.state.user_id,
    }
    await Http.delete(`/noride/${record.id}`, { headers })
      .then((resp) => {
        if (resp?.data?.data) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: resp.data?.message,
          })
        }
        else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        }
      })

  }
  setNoRide = async () => {
    let headers = {
      station: this.state.station,
      user_id: this.state.user_id,
    }
    let formdata = {
      ldd_name: this.state.noride_ldd,
      ala_name: this.state.noride_ala,
    }
    await Http.put(`/flight_noride_update/${parseInt(this.state.quizModalFlight.id)}`, formdata, { headers })
      .then((data) => {
        if (data?.data?.data) {
          this.setState({
            openNoRideModal: false,
            quizModalFlight: null,
            noride_flight: null,
            noride_ldd: null,
            noride_ala: null,
            norideData: [],
          }, () => this.closeNoride())
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
  }
  saveAudit = async () => {
    let { bin_config, audit_built_right, audit_reason, audit_explain, audit_was_optimal, audit_meet_wgt_goal } = this.state
    if ((bin_config && audit_built_right === 'Yes' && audit_was_optimal && audit_meet_wgt_goal) || (bin_config && audit_built_right === 'No' && audit_reason && audit_was_optimal && audit_meet_wgt_goal)) {
      let headers = {
        station: this.state.station,
        user_id: this.state.user_id,
      }
      let formdata = new FormData()
      formdata.append('bin_config', bin_config || '')
      formdata.append('audit_built_right', audit_built_right || '')
      formdata.append('audit_reason', audit_reason || '')
      formdata.append('audit_explain', audit_explain || '')
      formdata.append('audit_was_optimal', audit_was_optimal || '')
      formdata.append('audit_meet_wgt_goal', audit_meet_wgt_goal || '')
      formdata.append('load_trac_conf', this.state.load_trac_conf || '')
      formdata.append('build_plan_conf', this.state.build_plan_conf || '')
      formdata.append('wandb_conf', this.state.wandb_conf || '')
      formdata.append('flight_id', this.state.noride_flight || '')
      formdata.append('trigger', 'user')
      await Http.put(`/flight_audit_update/${this.state.noride_flight}`, formdata, { headers })
        .then((resp) => {
          if (resp?.data?.data) {
            this.setState({
              // openNoRideModal: false,
              // quizModalFlight: null,
              // noride_flight: null,
              bin_config: null,
              audit_built_right: null,
              audit_reason: null,
              audit_explain: null,
              attach_conf: false,
              fileList: [],
              openNoRideModal: false,
            }, () => this.closeNoride())
            notification.destroy()
            notification.success({
              message: 'Success',
              description: resp?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message,
            })
          }
        })
    } else {
      notification.destroy()
      notification.error({
        message: 'Warning',
        description: 'Please fill all the fields!',
      })
    }
  }

  saveLDD = async () => {
    let { pallets_paired, pallets_paired_contact, pallets_paired_explain, conveyances_staged, conveyances_staged_contact, conveyances_staged_explain } = this.state
    if (pallets_paired === null || conveyances_staged === null) {
      // do nothing
    }
    else if (pallets_paired === 'No' && (pallets_paired_contact === null || (pallets_paired_contact === 'No' && (!pallets_paired_explain || pallets_paired_explain.trim() === '')))) {
      // do nothing
    } else if (conveyances_staged === 'Yes' && (conveyances_staged_contact === null || (conveyances_staged_contact === 'No' && (!conveyances_staged_explain || conveyances_staged_explain.trim() === '')))) {
      // do nothing
    } else {
      let headers = {
        station: this.state.station,
        user_id: this.state.user_id,
      }
      let formdata = {
        pallets_paired: pallets_paired,
        pallets_paired_contact: pallets_paired_contact,
        pallets_paired_explain: pallets_paired_explain,
        conveyances_staged: conveyances_staged,
        conveyances_staged_contact: conveyances_staged_contact,
        conveyances_staged_explain: conveyances_staged_explain,
      }
      await Http.put(`/flight_ldd_update/${parseInt(this.state.quizModalFlight.id)}`, formdata, { headers })
        .then((data) => {
          if (data?.data?.data) {
            this.setState({
              openLDDModal: false,
              pallets_paired: null,
              pallets_paired_contact: null,
              pallets_paired_explain: null,
              conveyances_staged: null,
              conveyances_staged_contact: null,
              conveyances_staged_explain: null,
            })
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
        })
    }
  }

  closeNoride = () => {
    this.setState({
      openNoRideModal: false,
      noRideModalType: null,
      noRideTitle: null,
      norideData: [],
      noride_container_id: null,
      noride_type: null,
      noride_cutcode: null,
      noride_cutreason: null,
      noride_cutby: null,
      cut_reasons: [],
      noride_comment: null,
      noride_flight: null,
      noride_ldd: null,
      noride_ala: null,

      edit_noride_id: null,
      edit_noride_container_id: null,
      edit_noride_type: null,
      edit_noride_cutcode: null,
      edit_noride_cutreason: null,
      edit_cut_reasons: [],
      edit_noride_cutby: null,
      edit_noride_comment: null,
      edit_noride_flight: null,
    })
  }

  onChangeFileHandler = (e, t) => {
    if (e?.fileList[0]) {
      if (t == 'noride') {
        this.setState({
          noride_attach_conf: e?.fileList[0].originFileObj,
          noride_fileList: e?.fileList,
        })
      } else {
        if (e?.fileList[0].lastModifiedDate) {
          this.setState({ [t + '_previewImg']: false, })
        }
        this.setState({
          [t]: e?.fileList[0].originFileObj,
          [t + '_fileList']: e?.fileList,
        })
      }
    } else {
      if (t == 'noride') {
        this.setState({
          noride_attach_conf: false,
          noride_fileList: [],
        })
      } else {
        this.setState({
          attach_conf: null,
          fileList: []
        })
      }
    }
  }

  onRemoveFileHandler = (t) => {
    if (t == 'noride') {
      this.setState({
        noride_attach_conf: false,
        noride_fileList: [],
      })
    } else {
      this.setState({
        attach_conf: false,
        fileList: [],
        previewImg: false,
      })
    }
  }
  onRemoveEditFileHandler = (t) => {
    if (t == 'noride') {
      this.setState({
        edit_noride_attach_conf: false,
        edit_noride_fileList: [],
      })
    }
  }

  onChangeEditFileHandler = (e) => {
    if (e?.fileList[0]) {
      this.setState({
        edit_noride_attach_conf: e?.fileList[0].originFileObj,
        edit_noride_fileList: e?.fileList
      })
    } else {
      this.setState({
        edit_noride_attach_conf: false,
        edit_noride_fileList: [],
      })
    }
  }

  handleAssignUldChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState(prevState => ({
      assignUld: {
        ...prevState.assignUld,
        [name]: value
      }
    }))
  }

  handleEditUldChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState(prevState => ({
      editUlds: {
        ...prevState.editUlds,
        stn: cookies.get('station'),
        awb_no: this.state.flightDetails.awb_no,
        [name]: value
      }
    }))
  }

  submitAssignUld = () => {
    let awb = this.state.mergeEditRecord?.find((item) => item?.awb_no === this.state.flightDetails?.awb_no)
    let uld = this.state.editRecord?.build_uld?.find((item) => item?.id === this.state.assignUld?.id)
    if (uld && awb) {
      let formData = {
        stn: cookies.get('station'),
        awb_no: this.state.flightDetails.awb_no,
        pcs: this.state.assignUld?.pcs,
        remark: awb?.priority,
        uld_no: uld?.uld_no,
        uld_type: uld?.type,
        wgt: this.state.assignUld?.wgt,
        id: uld?.id,
        ch_wgt: awb?.ch_wgt ? (awb?.ch_wgt / awb.pcs) * this.state.assignUld.pcs : 0,
      }
      Http.post(`/build_plan_uld/${this.state.editRecordId}`, formData)
        .then((data) => {
          if (data && data?.status === 200) {
            this.setState({ assignUld: {} })
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
        })
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: 'Please select correct ULD',
      })
    }
  }

  fetchUlds = () => {
    let awbs = this.state.editRecord?.build_plan?.filter((item) => item?.awb_no === this.state.flightDetails?.awb_no)
    if (awbs?.length > 0) {
      this.setState({ assignedUldsData: awbs[0]?.ulds || [] })
    }
    else {
      this.setState({ assignedUldsData: [] })
    }
  }

  editUlds = (record) => {
    this.setState({ editUlds: record })
  }

  updateUlds = async () => {
    let awb = this.state.mergeEditRecord?.find((item) => item?.awb_no === this.state.flightDetails?.awb_no)
    let uld = this.state.editRecord?.build_uld?.find((item) => item?.id === this.state.editUlds?.id)
    if (uld && awb) {
      let formData = {
        stn: cookies.get('station'),
        awb_no: this.state.flightDetails.awb_no,
        pcs: this.state.editUlds?.pcs,
        remark: awb?.priority,
        uld_no: uld?.uld_no,
        uld_type: uld?.type,
        wgt: this.state.editUlds?.wgt,
        id: uld?.id,
        ch_wgt: awb?.ch_wgt ? (awb?.ch_wgt / awb.pcs) * this.state.editUlds.pcs : 0,
        uld_id: this.state.editUlds?.uld_id,
      }
      await Http.put(`/build_plan_uld/${this.state.editRecordId}`, formData)
        .then(async (data) => {
          if (data && data?.status === 200) {
            this.setState({ editUlds: {}, })
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
          this.setState({ loading: false })
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({ servererror: true })
        })
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: 'Please select correct ULD',
      })
    }
  }

  deleteUlds = (record) => {
    let formdata = {
      awb_no: this.state.flightDetails?.awb_no,
      uld_id: record.uld_id,
      stn: cookies.get('station'),
    }
    Http.put(`/build_plan_uld_remove/${this.state.editRecordId}`, formdata)
      .then((data) => {
        if (data && data.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }


  editAwbs = (record) => {
    let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === record.awb_no)
    this.setState({
      editAwbs: bpIndex !== -1 ? this.state.editRecord?.build_plan[bpIndex]?.data ? {
        pri: this.state.editRecord?.build_plan[bpIndex]?.data?.pri || record.priority,
        ch_wgt: this.state.editRecord?.build_plan[bpIndex]?.data?.ch_wgt || record.ch_wgt,
        blk: this.state.editRecord?.build_plan[bpIndex]?.data?.blk || record?.blk || 0,
      } : {
        pri: record.priority,
        ch_wgt: record.ch_wgt,
        ch_vol: record.blk || 0
      } : {
        pri: record.priority,
        ch_wgt: record.ch_wgt,
        ch_vol: record.blk || 0
      },
      editAwbsRecord: record,
      bpIndex: bpIndex,
    })
  }

  handleEditAwb = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState(prevState => ({
      editAwbs: {
        ...prevState.editAwbs,
        stn: cookies.get('station'),
        awb_no: this.state.editAwbsRecord?.awb_no,
        [name]: value
      }
    }))
  }

  mismatchPcs = (pcs, row) => {
    let bpIndex = this.state.editRecord.build_plan.findIndex((item) => item.awb_no === row.awb_no)
    this.state.editRecord.build_plan && this.state.editRecord.build_plan.length > 0 && this.state.editRecord.build_plan[bpIndex] && this.state.editRecord.build_plan[bpIndex]?.ulds?.length > 0 ?
      this.state.editRecord.build_plan[bpIndex]?.ulds?.map((uld) => {
        return pcs - uld.pcs !== 0 ? 'bg-red' : null
      }) : (row.slu_uld && row.slu_uld.length > 0 ? row.slu_uld.map((uld) => {
        return pcs - uld.pcs !== 0 ? 'bg-red' : null
      }) : null)
  }

  updateAwbRecord = async () => {
    let formdata = {
      stn: cookies.get('station'),
      awb_no: this.state.editAwbsRecord?.awb_no,
      ch_wgt: this.state.editAwbs?.ch_wgt,
      blk: this.state.editAwbs?.blk,
      pri: this.state.editAwbs?.pri,
      // ch_wgt: this.state.editAwbs && this.state.editAwbs?.build_plan[this.state.bpIndex]?.data?.ch_wgt || '',
      // ch_vol: this.state.editAwbs && this.state.editAwbs?.build_plan[this.state.bpIndex]?.data?.ch_vol || '',
      // pri: this.state.editAwbs && this.state.editAwbs?.build_plan[this.state.bpIndex]?.data?.pri || '',
    }
    await Http.put(`/build_plan_awb/${this.state.editRecordId}`, formdata)
      .then(async (data) => {
        if (data && data?.status === 200) {
          this.setState({
            editAwbs: {},
            editAwbsRecord: {},
          })
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ servererror: true })
      })
  }

  blkAwbRecord = async (blk, record) => {
    let formdata = {
      stn: cookies.get('station'),
      awb_no: record?.awb_no,
      blk
    }
    await Http.put(`/build_plan_awb/${this.state.editRecordId}`, formdata)
      .then(async (data) => {
        if (data && data?.status != 200) {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ servererror: true })
      })
  }

  deleteAwbRecord = (record) => {
    let formdata = {
      awb_no: record?.awb_no,
      stn: cookies.get('station'),
    }
    Http.put(`/build_plan_uld/${this.state.editRecordId}`, formdata)
      .then((data) => {
        if (data && data.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  sendFeedback = async () => {
    if (!this.state.fb_req_name?.trim() || !this.state.fb_req_email?.trim() || !this.state.fb_type || !this.state.fb_urgency || !this.state.fb_description?.trim() || !this.state.fb_rec_solution?.trim() || (this.state.fb_milestone && this.state.fb_milestone_arr.length === 0)) {
      notification.destroy()
      notification.error({
        message: 'Warning',
        description: 'Please fill all the fields!',
      })
    } else {
      let headers = {
        station: this.state.station,
        user_id: this.state.user_id,
      }
      let formdata = {
        fb_req_name: this.state.fb_req_name,
        fb_req_email: this.state.fb_req_email,
        fb_type: this.state.fb_type,
        fb_urgency: this.state.fb_urgency,
        fb_milestone_arr: this.state.fb_milestone ? this.state.fb_milestone_arr : [],
        fb_milestone: this.state.fb_milestone,
        fb_wgtnbal: this.state.fb_wgtnbal,
        fb_buildplan: this.state.fb_buildplan,
        fb_description: this.state.fb_description,
        fb_rec_solution: this.state.fb_rec_solution
      }
      Http.post('/send_feedback', formdata, { headers })
        .then((data) => {
          if (data && data?.status === 200) {
            this.clearFeedbackForm()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
        }).catch((err) => {
          console.log('err', err)
        })
    }
  }

  clearFeedbackForm = () => {
    this.setState({
      fb_req_name: cookies.get('name'),
      fb_req_email: cookies.get('username').match(emailregex) ? cookies.get('username') : null,
      fb_type: null,
      fb_urgency: null,
      fb_milestone_arr: [],
      fb_milestone: false,
      fb_wgtnbal: false,
      fb_buildplan: false,
      fb_description: null,
      fb_rec_solution: null,
      feedback: false,
    })
  }
  clearTableForm = () => {
    this.setState({
      editUlds: {},
      editAwbs: {},
      editAwbsRecord: {},
    })
  }

  addFlight = async () => {
    let flight_no = this.state.flight_no
    if (!this.state.flight_no || !this.state.flight_date || !this.state.dept_time || !this.state.arr_time || !this.state.org_stn || !this.state.dest_stn || !this.state.aircrafttype) {
      notification.destroy()
      notification.warning({
        message: 'Warning',
        description: 'Please fill all the fields!',
      })
    } else if (flight_no.length <= 4 || flight_no.length >= 8 || !flightno_pattern.test(flight_no)) {
      notification.destroy()
      notification.warning({
        message: 'Warning',
        description: 'Please enter valid flight number!',
      })
    } else {
      let headers = {
        station: this.state.station,
        user_id: this.state.user_id,
      }
      let formdata = {
        flight_no: this.state.flight_no,
        flight_date: this.state.flight_date.format('YYYY-MM-DD'),
        dept_time: this.state.dept_time.format('YYYY-MM-DD HH:mm'),
        arr_time: this.state.arr_time.format('YYYY-MM-DD HH:mm'),
        org_stn: this.state.org_stn,
        dest_stn: this.state.dest_stn,
        aircrafttype: this.state.aircrafttype,
      }
      await Http.post('/create_flight', formdata, { headers })
        .then((data) => {
          if (data && data?.status === 200) {
            this.setState({
              flightModalType: 'ADD',
              showFlightModal: false,
              flight_no: null,
              flight_date: null,
              dept_time: moment().startOf('day'),
              arr_time: moment().startOf('day'),
              org_stn: cookies.get('station'),
              dest_stn: null,
              aircrafttype: null,
              ack1: false,
              ack2: false,
            })
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  putFlight = async () => {
    let flight_no = this.state.flight_no
    if (!this.state.flight_no || !this.state.flight_date || !this.state.dept_time || !this.state.arr_time || !this.state.org_stn || !this.state.dest_stn || !this.state.aircrafttype) {
      notification.destroy()
      notification.warning({
        message: 'Warning',
        description: 'Please fill all the fields!',
      })
    } else if (flight_no.length <= 4 || flight_no.length >= 8 || !flightno_pattern.test(flight_no)) {
      notification.destroy()
      notification.warning({
        message: 'Warning',
        description: 'Please enter valid flight number!',
      })
    } else {
      let headers = {
        station: this.state.station,
        user_id: this.state.user_id,
      }
      let formdata = {
        flight_no: this.state.flight_no,
        flight_date: this.state.flight_date.format('YYYY-MM-DD'),
        dept_time: this.state.dept_time.format('YYYY-MM-DD HH:mm'),
        arr_time: this.state.arr_time.format('YYYY-MM-DD HH:mm'),
        org_stn: this.state.org_stn,
        dest_stn: this.state.dest_stn,
        aircrafttype: this.state.aircrafttype,
      }
      await Http.put(`/update_flight/${this.state.edit_flight_id}`, formdata, { headers })
        .then((data) => {
          if (data && data?.status === 200) {
            this.setState({
              flightModalType: 'ADD',
              showFlightModal: false,
              flight_no: null,
              flight_date: null,
              dept_time: moment().startOf('day'),
              arr_time: moment().startOf('day'),
              org_stn: cookies.get('station'),
              dest_stn: null,
              aircrafttype: null,
              ack1: false,
              ack2: false,
            })
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  getFilteredFlights = () => {
    let milestoneData = this.state.milestoneData.filter((item) => item?.type !== 'No Rides')
    if (this.state.hideCompleted) {
      let flights = this.state.flightData.filter((item) =>
        !item.audit || !item.noride || milestoneData.filter(milestone => {
          return !item.answers.some(answer => answer.milestones_id === milestone.id)
        }).length > 0
      )
      flights = flights.filter((item) => item?.awb_segments?.length > 0 || item?.ulds_out?.length > 0)
      // console.log('filtered', flights)
      return flights
    } else {
      let flights = this.state.flightData.filter((item) => item?.awb_segments?.length > 0 || item?.ulds_out?.length > 0)
      // console.log('filtered', flights)
      return flights
    }
  }

  showPreview = (record) => {
    this.setState({
      isModalVisible: true,
      previewImgNoRide: record.attach_conf
    })
  }

  openPreview = (type) => {
    this.setState({
      flightAuditImg: true,
      previewType: type
    })
  }

  render() {
    const noridecols = [
      {
        title: 'Container ID',
        dataIndex: 'container_id',
        key: 'container_id',
        render: (_, record) => (
          record?.id === this.state.edit_noride_id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.edit_noride_container_id || ''}
              onChange={(e) => this.setState({
                edit_noride_container_id: e.target.value,
                edit_noride_type: e.target.value.substring(0, 3)
              })}
            />
            : <span>{record?.container_id}</span>
        )
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (_, record) => (
          record?.id === this.state.edit_noride_id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.edit_noride_type || ''}
              onChange={(e) => this.setState({ edit_noride_type: e.target.value })}
            />
            : <span>{record?.type}</span>
        )
      },
      {
        title: 'Cut Code',
        dataIndex: 'cut_code',
        key: 'cut_code',
        render: (_, record) => (
          record?.id === this.state.edit_noride_id ?
            <Select
              size='small'
              className='w-100'
              dropdownClassName='dropdown-options'
              placeholder={'Select Cut Code'}
              value={this.state.edit_noride_cutcode}
              onChange={(e) => this.setState({
                edit_noride_cutcode: e,
                edit_cut_reasons: cut_codes.find((item) => item?.code === e)?.reasons,
                edit_noride_cutreason: cut_codes.find((item) => item?.code === e)?.reasons.length === 1 ?
                  cut_codes.find((item) => item?.code === e)?.reasons[0] : null,
                edit_noride_cutby: cut_codes.find((item) => item?.code === e)?.cutby,
              })}
            >
              {
                cut_codes?.map((item, index) => (
                  <Option key={index} value={item?.code}>{item?.code}</Option>
                ))
              }
            </Select>
            : <span>{record?.cut_code}</span>
        )
      },
      {
        title: 'Reason',
        dataIndex: 'cut_reason',
        key: 'cut_reason',
        render: (_, record) => (
          record?.id === this.state.edit_noride_id ?
            <Select
              size='small'
              className='w-100'
              dropdownClassName='dropdown-options'
              placeholder={'Select Reason'}
              value={this.state.edit_noride_cutreason}
              onChange={(e) => this.setState({ edit_noride_cutreason: e })}
            >
              {
                this.state.edit_cut_reasons?.map((item, index) => (
                  <Option key={index} value={item}>{item}</Option>
                ))
              }
            </Select>
            : <span>{record?.cut_reason}</span>
        )
      },
      {
        title: 'Cut By',
        dataIndex: 'cut_by',
        key: 'cut_by',
        render: (_, record) => (
          record?.id == this.state.edit_noride_id ?
            <Input
              size='small'
              className='w-100'
              value={this.state.edit_noride_cutby}
              onChange={(e) => this.setState({ edit_noride_cutby: e.target.value })}
            />
            // <Select
            //   size='small'
            //   className='w-100'
            //   placeholder={'Select Cut By'}
            //   value={this.state.edit_noride_cutby}
            //   onChange={(e) => this.setState({ edit_noride_cutby: e })}
            // >
            //   <Option value='ALA'>ALA</Option>
            //   <Option value='LCC'>LCC</Option>
            //   <Option value='LDD'>LDD</Option>
            // </Select>
            : <span>{record?.cut_by}</span>
        )
      },
      {
        title: 'Comments',
        dataIndex: 'comments',
        key: 'comments',
        render: (_, record) => (
          parseInt(record?.id) === parseInt(this.state.edit_noride_id) ?
            <Input
              size='small'
              placeholder=''
              value={this.state.edit_noride_comment || ''}
              onChange={(e) => this.setState({ edit_noride_comment: e.target.value })}
            />
            : <span>{record?.comments}</span>
        )
      },
      {
        title: 'Attachment',
        dataIndex: 'attachment',
        key: 'attachment',
        align: 'center',
        render: (_, record) => {
          return (
            parseInt(record?.id) === parseInt(this.state.edit_noride_id) ?
              <div className='scaletbl-img'>
                <Upload
                  accept='image/jpg,image/jpeg,image/png'
                  fileList={this.state.edit_noride_fileList}
                  maxCount={1}
                  beforeUpload={() => false}
                  onChange={(e) => this.onChangeEditFileHandler(e)}
                  onRemove={() => this.onRemoveEditFileHandler('noride')}
                  className='upload-name'>
                  <Button type='ghost' className={`image-btn ${this.state.edit_noride_attach_conf ? 'green-fliter' : ''}`} >
                    <img src={download} alt='download' />
                  </Button>
                </Upload>
              </div>
              :
              <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>
                <Tooltip title='Preview' onClick={() => this.showPreview(record)}>{record?.attach_conf && (<CheckOutlined />)}</Tooltip>
                {!record?.attach_conf && (<CloseOutlined />)}
              </span>
          )
        }
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (record) =>
          <div>
            <div className='action-column'>
              {
                parseInt(record?.id) === parseInt(this.state.edit_noride_id) ?
                  <>
                    <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.saveNoRide(record)} ><img src={Update} alt='Save' /></Tooltip>
                    <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearNoRide()} ><span className='form-clear'>x</span></Tooltip>
                  </>
                  :
                  <>
                    <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.editNoRide(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                    <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={() => this.deleteNoRide(record)} >
                      <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                    </Popconfirm>
                  </>
              }
            </div>
          </div>
      },
    ]
    const uldColumns = [
      {
        title: (<span className='test'>  ULD </span>),
        dataIndex: 'uld_type',
        key: 'uld_type',
        className: 'checklist-cols-1',
        render: (_, record) => (
          record?.uld_id === this.state.editUlds.uld_id ?
            <Select
              size='small'
              className='w-100'
              value={this.state.editUlds.id}
              onChange={(e) => this.handleEditUldChange({
                target: {
                  name: 'id',
                  value: e
                }
              })}
            // popupClassName='mcq-popup other-popup'
            >
              {this.state.editRecord?.build_uld?.map((option, index) => option.type && option.uld_no && <Option key={index} value={option.id}>{option.type}{option.uld_no}</Option>)}
            </Select>
            : <span>{record?.uld_type}{record?.uld_no}</span>
        )
      },
      // {
      //   title: (<span className='test'>  ULD No. </span>),
      //   dataIndex: 'uld_no',
      //   key: 'uld_no',
      //   className: 'checklist-cols-1',
      //   render: (_, record) => (
      //     record?.id === this.state.editUlds.id ?
      //       <Select
      //         size='small'
      //         className='w-100'
      //         value={this.state.editUlds.uld_no}
      //         onChange={(e) => this.handleEditUldChange({
      //           target: {
      //             name: 'uld_no',
      //             value: e
      //           }
      //         })}
      //       // popupClassName='mcq-popup other-popup'
      //       >
      //         {this.state.editRecord?.build_uld?.map((option, index) => <Option key={index} value={option.uld_no}>{option.uld_no}</Option>)}
      //       </Select>
      //       : <span>{record?.uld_no}</span>
      //   )
      // },
      // {
      //   title: (<span className='test'>  Remarks/Pri# </span>),
      //   dataIndex: 'remark',
      //   key: 'remark',
      //   className: 'checklist-cols-1',
      //   render: (_, record) => (
      //     record?.id === this.state.editUlds.id ?
      //       <Input
      //         size='small'
      //         placeholder=''
      //         value={this.state.editUlds.remark || ''}
      //         name='remark'
      //         onChange={(e) => this.handleEditUldChange(e)}
      //       />
      //       : <span>{record?.remark}</span>
      //   )
      // },
      {
        title: (<span className='test'>  Est. GrWt(lbs) </span>),
        dataIndex: 'wgt',
        key: 'wgt',
        className: 'checklist-cols-1',
        render: (_, record) => (
          record?.uld_id === this.state.editUlds.uld_id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editUlds.wgt || ''}
              name='wgt'
              onChange={(e) => this.handleEditUldChange(e)}
            />
            : <span>{record?.wgt}</span>
        )
      },
      {
        title: (<span className='test'>  Awb Pcs </span>),
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'checklist-cols-1',
        render: (_, record) => (
          record?.uld_id === this.state.editUlds.uld_id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editUlds.pcs || ''}
              name='pcs'
              onChange={(e) => this.handleEditUldChange(e)}
            />
            : <span>{record?.pcs}</span>
        )
      },
      {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (record) =>
          <div>
            {record?.uld_id === this.state.editUlds?.uld_id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.updateUlds()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                {record?.manual && (<Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.editUlds(record)} ><img src={editIcon} alt='edit' /></Tooltip>)}
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.deleteUlds(record)} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    return (
      <div>
        {
          this.state.servererror ?
            <p className='dis-server'>Disconnected from the server, Please refresh the page.</p> : null
        }
        <Spin spinning={this.state.loading}>
          {this.state.loadplanvisible && this.loadPlanModal()}
          {this.state.optimalBuild && this.searchModal()}
          <HeaderSearch searchResults={this.state.radioValue === 2 ? this.fetchFlightsChecklist : this.fetchFlights} page='FCP' />
          <Row gutter={[{
            xs: 0,
            sm: 0,
            md: 24,
            lg: 24
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24
          },]} >
            {!this.state.showBP}
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
              {this.state.showBP ?
                <Button
                  className='custom-button close'
                  onClick={() => {
                    this.setState({ showBP: false })
                  }}>
                  Back
                </Button> : ''}
              {!this.state.showBP ?
                <Card className='custom-card h-100 dod-card'>
                  <Row gutter={[{
                    xs: 0,
                    sm: 0,
                    md: 12,
                    lg: 12
                  }, {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12
                  },]} className='with-table'>
                    <Col xs={24} flex={'140px'} className=''>
                      <Title level={5} className='mb-0' xs={12}>
                        Flights
                      </Title>
                    </Col>
                    <Col xs>
                      <div className='toggle-view'>
                        <Radio.Group
                          onChange={(e) => this.setState({
                            radioValue: e.target.value,
                            flightData: [],
                            page: 1,
                          }, () => this.handleToggle())}
                          className='custom-radio-group'
                          value={this.state.radioValue}
                          style={ENV === 'stage' && users.includes(cookies.get('username')) ? { display: 'block' } : ENV === 'development' || ENV === 'local' ? { display: 'block' } : { display: 'none' }}
                        >
                          <Space size={0}>
                            <Radio.Button value={1} className='radio-change' size='small'>Ops info </Radio.Button>
                            <Radio.Button value={2} className='radio-change' size='small'>Checklist</Radio.Button>
                          </Space>
                        </Radio.Group>
                      </div>
                    </Col>
                    {this.state.radioValue === 1 && !this.state.showBP ?
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=' mb-4'>
                        <Row gutter={[{
                          xs: 24,
                          sm: 24,
                          md: 24,
                          lg: 24
                        }, {
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 12
                        },]} className=''>
                          <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                            <div>
                              <label className='custom-label'>From</label>
                              <DatePicker
                                allowClear={false}
                                className=''
                                onChange={(moment) => this.setState({
                                  start: moment,
                                  flightData: [],
                                  page: 1,
                                }, () => this.fetchFlights())}
                                // onChange={(moment) => this.setState({ start: moment }, () => this.setFilters('start'))}
                                value={moment(this.state.start, dateFormat)}
                                disabledDate={(current) => this.disabledDate(current)}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                            <div>
                              <label className='custom-label transparent'>-</label>
                              <TimePicker
                                allowClear={false}
                                onChange={(moment) => this.setState({
                                  stime: moment,
                                  flightData: [],
                                  page: 1,
                                }, () => this.fetchFlights())}
                                // onChange={(moment) => this.setState({ stime: moment }, () => this.setFilters('stime'))}
                                value={moment(this.state.stime, timeFormat)}
                                disabledHours={() => {
                                  var hours = []
                                  let start = this.state.start ? moment(this.state.start, dateFormat).format(dateFormat) : null
                                  let atlstart = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
                                  if (start && atlstart && start === atlstart) {
                                    for (var i = 0; i < moment().tz(this.state.timezone).hour(); i++) {
                                      hours.push(i)
                                    }
                                  }
                                  return hours
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                            <div>
                              <label className='custom-label'>To</label>
                              <DatePicker
                                allowClear={false}
                                className=''
                                onChange={(moment) => this.setState({
                                  end: moment,
                                  flightData: [],
                                  page: 1,
                                }, () => this.fetchFlights())}
                                // onChange={(moment) => this.setState({ end: moment }, () => this.setFilters('end'))}
                                value={moment(this.state.end, dateFormat)}
                                disabledDate={(current) => this.disabledDate(current)}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                            <div>
                              <label className='custom-label transparent'>-</label>
                              <TimePicker
                                allowClear={false}
                                onChange={(moment) => this.setState({
                                  etime: moment,
                                  flightData: [],
                                  page: 1,
                                }, () => this.fetchFlights())}
                                // onChange={(moment) => this.setState({ etime: moment }, () => this.setFilters('etime'))}
                                value={moment(this.state.etime, timeFormat)}
                                disabledHours={() => {
                                  var hours = []
                                  let end = this.state.end ? moment(this.state.end, dateFormat).format(dateFormat) : null
                                  let atlend = moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)
                                  if (end && atlend && end === atlend) {
                                    for (var i = 23; i > moment().tz(this.state.timezone).hour(); i--) {
                                      hours.push(i)
                                    }
                                  }
                                  return hours
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                            <div>
                              <Form.Item className='mb-0'>
                                <label className='custom-label'>Sort By</label>
                                <Select
                                  value={this.state.sort}
                                  size='small'
                                  placeholder='Sort By'
                                  className='text-center'
                                  onChange={(e) => this.setState({ sort: e }, () => this.sort(this.state.flightData, false))}
                                >
                                  <Option value='dept_time'>Dept Time</Option>
                                  <Option value='ac_type'>AC Type</Option>
                                  <Option value='notes_date'>New Notes</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={17} lg={17} flex={'480px'} className='pr-0'>
                            <div className='mt-1'>
                              <label className='custom-label transparent'>-</label>
                              <Checkbox
                                checked={this.state.amberFlightsCheck}
                                onChange={(e) => this.setState({ amberFlightsCheck: e.target.checked }, () => this.departedFlightsFilter())}
                              >
                                Show Payload Critical Flights
                              </Checkbox>
                              <Checkbox
                                checked={this.state.redFlightsCheck}
                                onChange={(e) => this.setState({ redFlightsCheck: e.target.checked }, () => this.departedFlightsFilter())}
                              >
                                Show Build Conflicts
                              </Checkbox>
                              <Checkbox
                                checked={this.state.departedFlightsCheck}
                                onChange={(e) => this.setState({ departedFlightsCheck: e.target.checked }, () => this.departedFlightsFilter())}
                              >
                                Hide Departed Flights
                              </Checkbox>
                            </div>
                          </Col>
                          {/* <Col flex={'30px'} className='text-center ml-auto'>
                          <label className='custom-label transparent'>-</label>
                          <Button
                            type='ghost'
                            className='image-btn'
                            onClick={() => {
                              this.clearEditFlightForm()
                              this.clearEditAllocForm()
                              this.clearAllocTableForm()
                              this.setState({ loadplanvisible: true })
                            }}
                          >
                            <Tooltip title={'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                          </Button>
                          // <Switch
                          // checked={this.state.hideMD}
                          // checkedChildren='Hide MD'
                          // unCheckedChildren='Show MD'
                          // onChange={(e) => this.setState({ hideMD: e }) }
                          // /> 
                        </Col> */}
                        </Row>
                      </Col> : ''}
                    {this.state.radioValue === 1 ?
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        className='table-overflow'
                      >
                        <div className='table-outer'>
                          <Table
                            className='custom-table w-100 multi-head fc-table'
                            dataSource={this.state.departedFlightsCheck || this.state.amberFlightsCheck || this.state.redFlightsCheck ? this.state.displayData : this.state.flightData}
                            pagination={false}
                          >
                            <Column
                              title='Flight #'
                              dataIndex=''
                              key='flight_no'
                              className='space-nowrap'
                              render={(_, record) => (
                                <span>{record?.flight_no ? record.flight_no : 'N/A'}</span>
                              )}
                            />
                            <Column
                              title='Flight Date'
                              dataIndex=''
                              key='flight_date'
                              className='space-nowrap'
                              render={(_, record) => (
                                <span>{record?.flight_date ? this.checkFlightDate(record) :
                                  record?.dept_date ? moment.utc(record.dept_date).tz(this.state.timezone).format(dateOnly)?.toUpperCase() :
                                    record?.schdl ? moment.tz(record.schdl, this.state.timezone).format(dateOnly)?.toUpperCase() :
                                      'N/A'}</span>
                              )}

                            />
                            <Column
                              title='AC'
                              dataIndex=''
                              key='ac_type'
                              render={(_, record) => (
                                <span>{record?.ac_type ? record.ac_type : 'N/A'}</span>
                              )}
                            />
                            <Column
                              title='Routing'
                              dataIndex=''
                              key='routing'
                              render={(_, record) => (
                                <span>{record?.org && record?.dest ? `${record.org.origin}-${record.dest.arriv}` : 'N/A'}</span>
                              )}
                            />
                            <Column
                              title={`Dept Time (in ${this.state.ext})`}
                              dataIndex=''
                              key='dept_time'
                              render={(_, record) => (
                                <Space className=''>
                                  <span className='px-1 fs-10 pa-badge'>{record?.dept_prefix ? record?.dept_prefix : ''}</span>
                                  <span>{record?.dept_time ? `${moment.tz(record.dept_time, this.state.timezone).format(dateTimeFormat)?.toUpperCase()}` : 'N/A'}</span>
                                </Space>
                              )}
                            />
                            <Column
                              title='P CAP/FCST'
                              dataIndex='cap_fcst'
                              key='cap_fcst'
                              render={(_, record) => (
                                <span>{`${record?.pax_cap}/${record?.fcst || 0}`}</span>
                              )}
                            />
                            <ColumnGroup title='Bag Posns' align='center'>
                              {/* <Column
                     title= 'PMC'
                     dataIndex= 'bag_posns'
                     key= 'bag_posns'
                     className='psn'
                     render= {(_, record) => (
                       <span>{record?.bag?.p96 || 0}</span>
                     )}
                   />
                   <Column
                     title= 'PAG'
                     dataIndex= 'bag_posns'
                     key= 'bag_posns'
                     className='psn'
                     render= {(_, record) => (
                       <span>{record?.bag?.p88 || 0}</span>
                     )}
                   /> */}
                              <Column
                                title='LD3'
                                dataIndex='bag_posns'
                                key='bag_posns'
                                className='psn'
                                render={(_, record) => (
                                  <span>{record?.bag?.ld3 || 0}</span>
                                )}
                              />
                              <Column
                                title='LD2'
                                dataIndex='bag_posns'
                                key='bag_posns'
                                // width='70px'
                                align='left'
                                className='psn'
                                render={(_, record) => (
                                  <span>{record?.bag?.ld2 || 0}</span>
                                )}
                              />
                            </ColumnGroup>
                            <ColumnGroup title='Optimal Cargo Build' align='center' className=''>
                              <Column width={20}></Column>
                              {this.state.hideMD ? null : <Column
                                title='MD'
                                dataIndex=''
                                key=''
                                className='psn op-build'
                                render={(_, record) => (
                                  <span>{record?.cargo?.md || 0}</span>
                                )}
                              />}
                              <Column
                                title='PMC'
                                dataIndex=''
                                key=''
                                className='psn op-build'
                                render={(_, record) => (
                                  <span>{record?.cargo?.p96 || 0}</span>
                                )}
                              />
                              <Column
                                title='PAG'
                                dataIndex=''
                                key=''
                                className='psn op-build'
                                render={(_, record) => (
                                  <span>{record?.cargo?.p88 || 0}</span>
                                )}
                              />
                              <Column
                                title='LD3'
                                dataIndex=''
                                key=''
                                className='psn op-build'
                                render={(_, record) => (
                                  <span>{record?.cargo?.ld3 || 0}</span>
                                )}
                              />
                              <Column
                                title='LD2'
                                dataIndex=''
                                key=''
                                className='psn op-build'
                                render={(_, record) => (
                                  <span>{record?.cargo?.ld2 || 0}</span>
                                )}
                              />
                            </ColumnGroup>
                            <Column
                              title='Bkd + Asd'
                              dataIndex='booked'
                              key='booked'
                              render={(_, record) => (
                                <span>{this.state.weight_unit === 'KG' && record?.booked_kg ? `${formatWeight(record?.booked_kg)}KG` :
                                  this.state.weight_unit === 'LB' && record?.booked_lbs ? `${formatWeight(record?.booked_lbs)}LB` :
                                    'N/A'}</span>
                              )}
                            />
                            <Column
                              title='Ready'
                              dataIndex='built_up'
                              key='built_up'
                              align='center'
                              render={(_, record) => (
                                <span>{this.state.weight_unit === 'KG' && record?.ready_kg ? `${formatWeight(record?.ready_kg)}KG` :
                                  this.state.weight_unit === 'LB' && record?.ready_lbs ? `${formatWeight(record?.ready_lbs)}LB` :
                                    '-'}</span>
                              )}
                            />
                            <Column
                              title='At Gate'
                              dataIndex='at_gate'
                              key='at_gate'
                              render={(_, record) => (
                                <span>{this.state.weight_unit === 'KG' && record?.gate_kg ? `${formatWeight(record?.gate_kg)}KG` :
                                  this.state.weight_unit === 'LB' && record?.gate_lbs ? `${formatWeight(record?.gate_lbs)}LB` :
                                    '-'}</span>
                              )}
                            />
                            <Column
                              title='FPE'
                              dataIndex='dept_time'
                              key='dept_time'
                              render={(_, record) => (
                                <span className='countdown'>{record?.time_to_dept ? <Countdown value={moment(record.time_to_dept)} format='HH:mm' /> : 'N/A'}</span>
                              )}
                            />
                            <Column
                              title='BU PRIO'
                              dataIndex=''
                              key='priority'
                              align='center'
                              render={(_, record) => (
                                record?.bu_prio ? <CheckOutlined /> : <CloseOutlined />
                              )}
                            />
                            <Column
                              title='Actions'
                              dataIndex='action'
                              key='action'
                              render={(_, record) =>
                                <div className='action-column'>
                                  {/* <Tooltip title='DoD Checklist' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                                  <Button
                                    className='modal-btn'
                                    icon={<CheckSquareOutlined 
                                      className='modal-icon'
                                      onClick={() => this.showCheckList(record)}
                                    />}
                                  >
                                  </Button>
                                </Tooltip>  */}
                                  <Tooltip title='Cargo Capacity' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                                    <Button
                                      className={record.cccolour_css}
                                      onClick={() => this.setEdit(record)}
                                    >
                                      BP
                                    </Button>
                                  </Tooltip>
                                  <Badge size='small' count={record?.notes || 0} color={'#0a1227'}>
                                    <Tooltip
                                      title='Notes'
                                      overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                                      onClick={() => this.showFlightNotes(record)}
                                    >
                                      <img src={documentIcon} alt='document' />
                                    </Tooltip>
                                  </Badge>
                                </div>
                              }
                            />
                          </Table>
                        </div>
                      </Col> : ''}
                    {this.state.radioValue === 2 && !this.state.showBP ?
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=' mb-4'>
                        <Row gutter={[{
                          xs: 24,
                          sm: 24,
                          md: 24,
                          lg: 24
                        }, {
                          xs: 12,
                          sm: 12,
                          md: 12,
                          lg: 12
                        },]} className=''>
                          <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                            <div>
                              <label className='custom-label'>From</label>
                              <DatePicker
                                allowClear={false}
                                className=''
                                onChange={(moment) => this.setState({
                                  start_checklist: moment,
                                  flightData: [],
                                  page: 1,
                                }, () => this.fetchFlightsChecklist())}
                                // onChange={(moment) => this.setState({ fetchFlightsChecklist: moment }, () => this.setFilters('fetchFlightsChecklist'))}
                                value={moment(this.state.start_checklist, dateFormat)}
                                disabledDate={(current) => this.disabledDate(current)}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                            <div>
                              <label className='custom-label transparent'>-</label>
                              <TimePicker
                                allowClear={false}
                                onChange={(moment) => this.setState({
                                  stime_checklist: moment,
                                  flightData: [],
                                  page: 1,
                                }, () => this.fetchFlightsChecklist())}
                                // onChange={(moment) => this.setState({ stime_checklist: moment }, () => this.setFilters('stime_checklist'))}
                                value={moment(this.state.stime_checklist, timeFormat)}
                                disabledHours={() => {
                                  var hours = []
                                  let start = this.state.start_checklist ? moment(this.state.start_checklist, dateFormat).format(dateFormat) : null
                                  let atlstart = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
                                  if (start && atlstart && start === atlstart) {
                                    for (var i = 0; i < moment().tz(this.state.timezone).hour(); i++) {
                                      hours.push(i)
                                    }
                                  }
                                  return hours
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                            <div>
                              <label className='custom-label'>To</label>
                              <DatePicker
                                allowClear={false}
                                className=''
                                onChange={(moment) => this.setState({
                                  end_checklist: moment,
                                  flightData: [],
                                  page: 1,
                                }, () => this.fetchFlightsChecklist())}
                                // onChange={(moment) => this.setState({ end_checklist: moment }, () => this.setFilters('end_checklist'))}
                                value={moment(this.state.end_checklist, dateFormat)}
                                disabledDate={(current) => this.disabledDate(current)}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3} className='xl-auto'>
                            <div>
                              <label className='custom-label transparent'>-</label>
                              <TimePicker
                                allowClear={false}
                                onChange={(moment) => this.setState({
                                  etime_checklist: moment,
                                  flightData: [],
                                  page: 1,
                                }, () => this.fetchFlightsChecklist())}
                                // onChange={(moment) => this.setState({ end_checklist: moment }, () => this.setFilters('end_checklist'))}
                                value={moment(this.state.etime_checklist, timeFormat)}
                                disabledHours={() => {
                                  var hours = []
                                  let end = this.state.end_checklist ? moment(this.state.end_checklist, dateFormat).format(dateFormat) : null
                                  let atlend = moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)
                                  if (end && atlend && end === atlend) {
                                    for (var i = 23; i > moment().tz(this.state.timezone).hour(); i--) {
                                      hours.push(i)
                                    }
                                  }
                                  return hours
                                }}
                              />
                            </div>
                          </Col>
                          <Col xs={24} sm={12} md={6} lg={6} xl={5} xxl={3} className='xl-auto'>
                            <div>
                              <Form.Item className='mb-0'>
                                <label className='custom-label'>Sort By</label>
                                <Select
                                  value={this.state.dodsort}
                                  size='small'
                                  placeholder='Sort By'
                                  className='text-center'
                                  onChange={(e) => {
                                    let flightData = this.state.flightData
                                    this.setState({
                                      dodsort: e,
                                      flightData: []
                                    }, () => this.setState({ flightData: this.sortDodCC(flightData) })
                                    )
                                  }}
                                >
                                  <Option value='dept_time'>Dept Time</Option>
                                  <Option value='flight_no'>Flight#</Option>
                                  <Option value='dest_stn'>Org-Dest</Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={17} lg={17} flex={'0 0 180px'} className='pr-0'>
                            <div className='mt-1'>
                              <label className='custom-label transparent'>-</label>

                              <Checkbox
                                checked={this.state.hideCompleted}
                                onChange={
                                  (e) => {
                                    let flights = this.state.flightData
                                    this.setState({
                                      hideCompleted: e.target.checked,
                                      flightData: []
                                    }, () => this.setState({ flightData: flights }))
                                  }
                                }
                              >
                                Hide Completed Flights
                              </Checkbox>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={17} lg={17} flex={'0 0 200px'} className='pr-0'>
                            <div className=''>
                              <label className='custom-label transparent'>-</label>
                              <Button type='text' icon={<CustomerServiceOutlined />} onClick={() => this.setState({ feedback: true })} className='feedback-btn'>
                                Feedback Form
                              </Button>
                            </div>
                          </Col>
                          <Col flex={'30px'} className='text-center ml-auto'>
                            <label className='custom-label transparent'>-</label>
                            <Button
                              type='ghost'
                              className='image-btn'
                              onClick={() => this.setState({
                                flightModalType: 'ADD',
                                showFlightModal: true
                              })}
                            >
                              <Tooltip title={'Add Flight'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                            </Button>
                          </Col>
                        </Row>
                      </Col> : ''}
                    {this.state.radioValue === 2 && !this.state.showBP ?
                      <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                        <div className='table-outer'>
                          <Table
                            className='custom-table w-100 multi-head dod-multihead'
                            // dataSource={this.state.departedFlightsCheck ||this.state.amberFlightsCheck ||this.state.redFlightsCheck? this.state.displayData : this.state.flightData}
                            dataSource={this.getFilteredFlights()}
                            columns={this.generateMilestonesColumns()}
                            pagination={false}
                          />
                        </div>
                      </Col> : ''
                    }
                  </Row>
                </Card> : ''}
            </Col>
          </Row>
        </Spin>
        {this.state.showBP === true ?
          <BuildPlan /> : ''}
        <Modal
          title={this.state.notesTitle}
          open={this.state.flightNotes}
          onCancel={() => this.setState({
            flightNotes: false,
            notesTitle: ''
          })}
          footer={false}
          width={'80%'}
          centered
          className='custom-modal pb-0'
          destroyOnClose={true}
        >
          <Notes search={this.state.searchString} type={this.state.noteType} />
        </Modal>
        <Modal
          title={this.state.checkListTitle}
          open={this.state.checkListOpen}
          closable={true}
          onCancel={() => this.setState({ checkListOpen: false })}
          footer={false}
          width={'90%'}
          centered
          className='custom-modal pb-0'
        >
          {/* <Spin spinning={this.state.skeletonLoad}> */}
          <Row
            gutter={[
              {
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24,
              },
              {
                xs: 24,
                sm: 24,
                md: 12,
                lg: 12,
              },
            ]}
            className='with-table'
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='space-nowrap'>
              <div className='div-icons' style={{ FontSize: '150%' }}>
                <span>Feedback Form Link</span>
                <CustomerServiceOutlined
                  className='CsIcon'
                />
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div className='table-outer'>
                <Table
                  className='custom-table w-100 multi-head checklist-table'
                  dataSource={this.getFilteredFlights()}
                  columns={this.generateMilestonesColumns()}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={'Feedback Form'}
          open={this.state.feedback}
          closable={true}
          onCancel={() => this.clearFeedbackForm()}
          footer={false}
          width={'60%'}
          centered
          className='custom-modal pb-0'
        >
          <Row
            gutter={[
              {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 36,
                lg: 42
              },
            ]}
            className='with-table'
          >
            <Col xs={6} className='fields'>
              <label  >Requestor Name</label>
              <Input
                value={this.state.fb_req_name}
                size='small'
                placeholder=''
                onChange={(e) => this.setState({ fb_req_name: e.target.value })}

              />
            </Col>
            <Col xs={6} className='fields'>
              <label className>Requestor Email</label>
              <Input
                placeholder=''
                value={this.state.fb_req_email}
                onChange={(e) => this.setState({ fb_req_email: e.target.value })}
              />
            </Col>
            <Col xs={6} className='fields'>
              <label >Type of Feedback</label>
              <Select
                size='small'
                placeholder=''
                className='w-100'
                value={this.state.fb_type}
                onChange={(e) => this.setState({ fb_type: e })}
              >
                <Option value={'Report Issue'}>Report Issue</Option>
                <Option value={'Provide Feedback/Recommendations'}>Provide Feedback/Recommendations</Option>
              </Select>
            </Col>
            <Col xs={6} className='fields'>
              <label >Level of Urgency</label>
              <Select
                size='small'
                placeholder=''
                className='w-100'
                value={this.state.fb_urgency}
                onChange={(e) => this.setState({ fb_urgency: e })}
              >
                <Option value={'High'} >High - Impacts operations, no workaround available</Option>
                <Option value={'Medium'} >Medium - Impacts operations, but workaround avaiable</Option>
                <Option value={'Low'} >Low - minimal impact to operations</Option>
              </Select>
            </Col>
            <Col xs={24} xl={'500px'}>
              <label className='custom-label label-border'>Tools Impacted</label>
              <Checkbox
                className='light-checkbox mx-auto'
                checked={this.state.fb_buildplan}
                onChange={(e) => this.setState({ fb_buildplan: e.target.checked })}
              >Build Plan</Checkbox>
              <Checkbox
                className='light-checkbox mx-auto'
                checked={this.state.fb_wgtnbal}
                onChange={(e) => this.setState({ fb_wgtnbal: e.target.checked })}
              >Weight and Balance tool</Checkbox>
              <Checkbox
                className='light-checkbox mx-auto'
                checked={this.state.fb_milestone}
                onChange={(e) => this.setState({ fb_milestone: e.target.checked })}
              >Milestone</Checkbox>
            </Col>
            {this.state.fb_milestone ?
              <Col xs={24}>
                <p>Milestone list </p>
                <Checkbox.Group value={this.state.fb_milestone_arr} options={this.state.milestoneFBData} onChange={(e) => this.setState({ fb_milestone_arr: e })} />
              </Col>
              : ''
            }

            <Col xs={12} className='fields'>
              <label>Description of Issue/Feedback</label>
              <Input.TextArea
                value={this.state.fb_description}
                placeholder=''
                onChange={(e) => this.setState({ fb_description: e.target.value })}
                className='custom-textarea'
              />
            </Col>
            <Col xs={12} className='fields'>
              <label>Recommended Solution</label>
              <Input.TextArea
                value={this.state.fb_rec_solution}
                placeholder=''
                onChange={(e) => this.setState({ fb_rec_solution: e.target.value })}
                className='custom-textarea'
              />
            </Col>

            <Col className='noride-submit'>
              <button className='modal-submit1 submit' onClick={() => this.sendFeedback()}>Submit </button>
              <button className='modal-submit1' onClick={() => this.clearFeedbackForm()}> Close </button>
            </Col>
            {/* <Form.Item xs={6} >
              <label className='card-text'>Ac Type</label>
              <Input
                // value={this.state.sort}
                value='339'
                disabled={true}
                size='small'
                // placeholder='Sort By'
                className='text-center'
                // onChange={(e) => this.setState({ sort: e }, () => this.sort(this.state.flightData, false))}
              />
                  
            </Form.Item> */}
          </Row>
        </Modal>
        <Modal
          title={`${this.state.checklistTitle}`}
          open={this.state.openQuizModal}
          closable={true}
          onCancel={() => this.setState({
            openQuizModal: false,
            quizMilestonid: null,
            quizModalFlight: null,
            questionsData: this.state.tempquestionsData,
          })}
          footer={<div className='noride-submit'>
            <button className='modal-submit1 submit' onClick={() => this.saveAnswers()}>{this.state.milestonButton}</button>
            <button className='modal-submit1' onClick={() =>
              this.setState({
                openQuizModal: false,
                quizMilestonid: null,
                quizModalFlight: null,
                questionsData: this.state.tempquestionsData,
              })}>
              Close
            </button>
          </div>}
          width={'60%'}
          centered
          className='custom-modal pb-0 quiz-modal'
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <Col className='cargo-cap-card'>
              <div>
                <Row gutter={[{
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12
                }, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                },]} className='flight-ctrl'
                >
                  {/* <Col xs={24} xl={8} xxl={8}>
                    <Row> */}
                  <Col xs={24} sm={12} md={6} lg={5} xl={5} >
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Flight Number</small>
                      {/* <p className='info-data mb-2'>{this.state.editRecord?.ac_type || 'N/A'}</p> */}
                      <p className={this.state.editRecord?.ac_type === '75D' ? 'new-ac' : 'info-data mb-2'}>{this.state.quizModalFlight?.flight_no}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Flight Date</small>
                      <p className='info-data mb-2'>{
                        this.state.quizModalFlight?.flight_date ? moment(this.checkFlightDate(this.state.quizModalFlight)).format('DD/MM/YYYY') :
                          this.state.quizModalFlight?.dept_date ? moment.utc(this.state.quizModalFlight?.dept_date).tz(this.state.timezone).format('DD/MM/YYYY')?.toUpperCase() :
                            this.state.quizModalFlight?.schdl ? moment.tz(this.state.quizModalFlight?.schdl, this.state.timezone).format('DD/MM/YYYY')?.toUpperCase() :
                              'N/A'
                      }</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Origin</small>
                      <p className='info-data mb-2 space-nowrap'>{this.state.quizModalFlight?.org_stn}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht min-width-80'>
                      <small className='info-title mb-1'>Dest</small>
                      <p className='info-data mb-2'>{this.state.quizModalFlight?.dest_stn}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht min-width-80'>
                      <small className='info-title mb-1'>Aircraft</small>
                      <p className='info-data mb-2'>{this.state.quizModalFlight?.ac_type}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Col>

          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div style={{ 
                display:'flex',
                justifyContent: 'flex-end'
              }}>
                <button className='modal-submit'> Add Rows </button>
                <button className='modal-submit'> Clear Table </button>
              </div>   
              <div className='table-outer'>
                <Table
                  className='custom-table w-100 multi-head  checklist-table'
                  dataSource={noRideData}
                  columns={noRideCols}
                  pagination={false}
                />
              </div>
            </Col> */}
          {
            this.state.questionsData?.map((item, index) => (
              (item.parent_id && item.showchild === false) ? (
                null
              ) : (
                <Row key={index} gutter={[{
                  xs: 12,
                  sm: 12,
                  md: -140,
                  lg: -140
                }, {
                  xs: 12,
                  sm: 12,
                  md: 25,
                  xl: 50
                },]}
                className='modal-row1'>
                  <Col xs={24} xl={10}>
                    <p className='d-flex gap-10'>
                      <span>{`${index + 1}.`}</span>
                      <span>{`${item.details.question}`}</span>
                    </p>
                  </Col>
                  <Col xs={0} xl={1}></Col>
                  <Col xs={24} xl={5}>
                    {
                      item.details.type === 'yes/no' ?
                        <Select
                          size='small'
                          className='w-60'
                          value={item?.answer}
                          onChange={(e) => this.handleAnswers(e, index)}
                          popupClassName='mcq-popup'
                        >
                          <Option value='Yes'>Yes</Option>
                          <Option value='No'>No</Option>
                          <Option value='N/A'>N/A</Option>
                        </Select> :
                        item.details.type === 'other' ?
                          <Input.TextArea placeholder='Provide details' value={item?.answer} onChange={(e) => this.handleAnswers(e, index)} /> :
                          item.details.type === 'mcq' ?
                            <Select
                              size='small'
                              className='w-60'
                              value={item?.answer}
                              onChange={(e) => this.handleAnswers(e, index)}
                              popupClassName='mcq-popup other-popup'
                            >
                              {item.details.options?.map((option, index) => <Option key={index} value={option.name}>{option.name}</Option>)}
                            </Select>
                            : null
                    }
                  </Col>
                  <Col flex={'auto'}>
                    {
                      item.details.info ?
                        <>
                          <ExclamationCircleOutlined style={{ color: '#eaaa00' }} />
                          <span className='info-text'>{item.details.info}</span>
                        </>
                        : null
                    }
                    {
                      (item.details.type === 'yes/no' && item.details.comment && item.details.comment === item.answer)
                        || (item.details.type === 'mcq' && item.details.options && item.details.options.length > 0 && item.details.options.find((option) => option?.name === item.answer)?.commentsReq)
                        ?
                        <>
                          <Input.TextArea rows={1} placeholder={'If ' + (item.answer ? `${item.answer.toLowerCase()}` : '') + ', provide details'} value={item?.comment} onChange={(e) => this.handleComment(e, index)} />
                        </>
                        : null
                    }
                  </Col>
                </Row>
              )
            ))
          }
        </Modal>
        <Modal
          title={this.state.noRideTitle}
          open={this.state.openNoRideModal}
          closable={true}
          onCancel={() => this.closeNoride()}
          footer={<>
            {this.state.noRideModalType === 'AUDIT' ?
              <div className='noride-submit'>
                <button className='modal-submit1 submit' onClick={() => this.saveAudit()}>{this.state.quizModalFlight?.audit ? 'Resubmit' : 'Submit'}</button>
                <button className='modal-submit1' onClick={() => this.closeNoride()}> Close  </button>
              </div> : null}
            {
              this.state.noRideModalType === 'NORIDE' ?
                <>
                  <div className='noride-submit'>
                    <button className='modal-submit1 submit' onClick={() => this.setNoRide()}>{this.state.quizModalFlight?.noride ? 'Resubmit' : 'Submit'}</button>
                    <button className='modal-submit1' onClick={() => this.closeNoride()}> Close  </button>
                  </div>
                </ > : null
            }
          </>
          }
          width={'60%'}
          centered
          className='custom-modal pb-0 audit-modal'
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <Col className='cargo-cap-card'>
              <div>
                <Row gutter={[{
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                }, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                },]} className='flight-ctrl'
                >
                  {/* <Col xs={24} xl={8} xxl={8}>
                    <Row> */}
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Flight Number</small>
                      {/* <p className='info-data mb-2'>{this.state.editRecord?.ac_type || 'N/A'}</p> */}
                      <p className={this.state.editRecord?.ac_type === '75D' ? 'new-ac' : 'info-data mb-2'}>{this.state.quizModalFlight?.flight_no}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Flight Date</small>
                      <p className='info-data mb-2'>{
                        this.state.quizModalFlight?.flight_date ? moment(this.checkFlightDate(this.state.quizModalFlight)).format('DD/MM/YYYY') :
                          this.state.quizModalFlight?.dept_date ? moment.utc(this.state.quizModalFlight?.dept_date).tz(this.state.timezone).format('DD/MM/YYYY')?.toUpperCase() :
                            this.state.quizModalFlight?.schdl ? moment.tz(this.state.quizModalFlight?.schdl, this.state.timezone).format('DD/MM/YYYY')?.toUpperCase() :
                              'N/A'
                      }</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Origin</small>
                      <p className='info-data mb-2 space-nowrap'>{this.state.quizModalFlight?.org_stn}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht min-width-80'>
                      <small className='info-title mb-1'>Dest</small>
                      <p className='info-data mb-2'>{this.state.quizModalFlight?.dest_stn}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht min-width-80'>
                      <small className='info-title mb-1'>Gate</small>
                      <p className='info-data mb-2'>{this.state.quizModalFlight?.dept?.gate || 'N/A'}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Col>
          {
            this.state.noRideModalType === 'AUDIT' ?
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='audit-quiz' >
                <Row
                  gutter={[{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12
                  }, {
                    xs: 24,
                    sm: 24,
                    md: 36,
                    lg: 42
                  },]} className='flight-ctrl'
                >
                  {/* <Col xs={16}>
                    <Form.Item label='1. Optimal Bin Configuration chosen'>
                      <Input placeholder='Enter Configuration' value={this.state.bin_config} onChange={(e) => this.setState({ bin_config: e.target.value })} />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24}>
                    <Row gutter={[12, 12]} className=''>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <p className='d-flex gap-10'>
                          <span>1.</span>
                          <span>Optimal Bin Configuration chosen</span>
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                        <Input placeholder='Enter Configuration' value={this.state.bin_config} onChange={(e) => this.setState({ bin_config: e.target.value })} />
                      </Col>
                    </Row>
                  </Col>
                  {/* </Row>
                <Row
                  gutter={[{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12
                  }, {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 24
                  },]} className='flight-ctrl'
                > */}
                  {/* <Col xs={24}>
                    <Form.Item label='2. Was everything built according to the flight plan ?'> 
                      <Row gutter={16}>
                        <Col xs='4'>
                          <Select
                            size='small'
                            className='w-60'
                            value={this.state.audit_built_right}
                            placeholder={'Select Yes/No'}
                            onChange={(e) => this.setState({
                              audit_built_right: e,
                              audit_reason: null,
                              audit_explain: null
                            })}
                          >
                            <Option value='Yes'>Yes</Option>
                            <Option value='No'>No</Option>
                          </Select>
                        </Col>
                        <Col xs='6'>
                          <Select
                            size='small'
                            className='w-60'
                            value={this.state.audit_reason}
                            placeholder={'Select Reason'}
                            onChange={(e) => this.setState({
                              audit_reason: e,
                              audit_explain: null
                            })}
                            disabled={this.state.audit_built_right !== 'No'}
                          >
                            <Option value='Not tendered'>Not tendered</Option>
                            <Option value='Did not make the cut off time'>Did not make the cut off time</Option>
                            <Option value='Not built by Warehouse'>Not built by Warehouse</Option>
                            <Option value='Damaged'>Damaged</Option>
                            <Option value='On hold'>On hold</Option>
                            <Option value='Other'>Other</Option>
                          </Select>
                        </Col>
                        <Col xs='10'>
                          <Input placeholder='If other, explain' value={this.state.audit_explain} onChange={(e) => this.setState({ audit_explain: e.target.value })}
                            disabled={this.state.audit_built_right === 'Yes' || this.state.audit_reason !== 'Other'}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col> */}
                  <Col xs={24}>
                    <Row gutter={[12, 12]} className=''>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <p className='d-flex gap-10'>
                          <span>2.</span>
                          <span> Was everything built according to the flight plan ?</span>
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={6} xl={4}>
                        <Select
                          size='small'
                          className='w-100'
                          value={this.state.audit_built_right}
                          placeholder={'Select Yes/No'}
                          onChange={(e) => this.setState({
                            audit_built_right: e,
                            audit_reason: null,
                            audit_explain: null
                          })}
                        >
                          <Option value='Yes'>Yes</Option>
                          <Option value='No'>No</Option>
                        </Select>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <Select
                          size='small'
                          className='w-100'
                          value={this.state.audit_reason}
                          placeholder={'Select Reason'}
                          onChange={(e) => this.setState({
                            audit_reason: e,
                            audit_explain: null
                          })}
                          disabled={this.state.audit_built_right !== 'No'}
                        >
                          <Option value='Not tendered'>Not tendered</Option>
                          <Option value='Did not make the cut off time'>Did not make the cut off time</Option>
                          <Option value='Not built by Warehouse'>Not built by Warehouse</Option>
                          <Option value='Damaged'>Damaged</Option>
                          <Option value='On hold'>On hold</Option>
                          <Option value='Other'>Other</Option>
                        </Select>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={10} xl={4}>
                        <Input placeholder='If other, explain' value={this.state.audit_explain} onChange={(e) => this.setState({ audit_explain: e.target.value })}
                          disabled={this.state.audit_built_right === 'Yes' || this.state.audit_reason !== 'Other'}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {/* </Row>
                <Row
                  gutter={[{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12
                  }, {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 24
                  },]} className='flight-ctrl'
                > */}
                  {/* <Col xs={24}>
                    <Form.Item label='3 Was the Planned Bin Configuration shown as Optimal on the Build Plan ?'>
                      <Row gutter={16}>
                        <Col xs='4'>
                          <Select
                            size='small'
                            className='w-60'
                            value={this.state.audit_was_optimal}
                            placeholder={'Select Yes/No'}
                            onChange={(e) => this.setState({ audit_was_optimal: e })}
                          >
                            <Option value='Yes'>Yes</Option>
                            <Option value='No'>No</Option>
                          </Select>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col> */}
                  <Col xs={24}>
                    <Row gutter={[12, 12]} className=''>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <p className='d-flex gap-10'>
                          <span>3.</span>
                          <span> Was the Planned Bin Configuration shown as Optimal on the Build Plan ?</span>
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={4}>
                        <Select
                          size='small'
                          className='w-100'
                          value={this.state.audit_was_optimal}
                          placeholder={'Select Yes/No'}
                          onChange={(e) => this.setState({ audit_was_optimal: e })}
                        >
                          <Option value='Yes'>Yes</Option>
                          <Option value='No'>No</Option>
                        </Select>
                      </Col>
                      <Col xs={12}></Col>
                    </Row>
                  </Col>
                  {/* </Row>
                  {/* </Row>
                <Row
                  gutter={[{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12
                  }, {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 24
                  },]} className='flight-ctrl'
                > */}
                  {/* <Col xs={24}>
                    <Form.Item label='4 Did all containers meet/exceed chargeable weight goal ?'>
                      <Row gutter={16}>
                        <Col xs='4'>
                          <Select
                            size='small'
                            className='w-60'
                            value={this.state.audit_meet_wgt_goal}
                            placeholder={'Select Yes/No'}
                            onChange={(e) => this.setState({ audit_meet_wgt_goal: e })}
                          >
                            <Option value='Yes'>Yes</Option>
                            <Option value='No'>No</Option>
                          </Select>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col> */}
                  <Col xs={24}>
                    <Row gutter={[12, 12]} className=''>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <p className='d-flex gap-10'>
                          <span>4.</span>
                          <span>Did all containers meet/exceed chargeable weight goal ?</span>
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={4}>
                        <Select
                          size='small'
                          className='w-100'
                          value={this.state.audit_meet_wgt_goal}
                          placeholder={'Select Yes/No'}
                          onChange={(e) => this.setState({ audit_meet_wgt_goal: e })}
                        >
                          <Option value='Yes'>Yes</Option>
                          <Option value='No'>No</Option>
                        </Select>
                      </Col>
                      <Col xs={12}></Col>
                    </Row>
                  </Col>
                  {/* </Row>
                <Row
                  gutter={[{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12
                  }, {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 24
                  },]} className='flight-ctrl'
                > */}
                  {/* <Col xs={24}>
                    <Form.Item label='5. Upload LoadTrac image(.jpg, .jpeg or .png)'>
                      <div className='scaletbl-img'>
                        <Upload
                          accept='image/jpg,image/jpeg,image/png'
                          fileList={this.state.fileList}
                          maxCount={1} beforeUpload={() => false}
                          onChange={(e) => this.onChangeFileHandler(e)}
                          onRemove={() => this.onRemoveFileHandler()}>
                          <Button type='ghost' className={`image-btn ${this.state.attach_conf ? 'green-fliter' : ''}`} >
                            <img src={download} alt='download' />
                          </Button>
                        </Upload> */}
                  {/* {this.checkType(record)} */}
                  {/* {this.state.previewImg && (<img className='prev-img' src={this.state.previewImg} alt={this.state.previewImg} />)}
                      </div> */}

                  {/* <Input placeholder='Enter Configuration' value={this.state.bin_config} onChange={(e) => this.setState({ bin_config: e.target.value })} /> */}
                  {/* </Form.Item>
                  </Col> */}
                  <Col xs={24}>
                    <Row gutter={[12, 12]} className=''>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <p className='d-flex gap-10'>
                          <span>5.</span>
                          <span>Upload LoadTrac image (.jpg, .jpeg or .png)</span>
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={14}>
                        <div className='scaletbl-img d-flex'>
                          <Upload
                            accept='image/jpg,image/jpeg,image/png'
                            fileList={this.state.load_trac_conf_fileList}
                            maxCount={1} beforeUpload={() => false}
                            onChange={(e) => this.onChangeFileHandler(e, 'load_trac_conf')}
                            onRemove={() => this.onRemoveFileHandler()}
                            className='upload-name'>
                            <Button type='ghost' className={`image-btn ${this.state.load_trac_conf_fileList[0]?.lastModifiedDate ? 'green-fliter' : ''}`}>
                              {this.state.load_trac_conf_previewImg ?
                                <img className='prev-img' src={this.state.load_trac_conf_previewImg} alt={this.state.load_trac_conf_previewImg} width={'100px'} height={'100%'} />
                                :
                                <img src={download} alt='download' />}
                            </Button>
                          </Upload>
                          {this.state.load_trac_conf_previewImg && (
                            <>
                              <Tooltip title={'View Attachment'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                                <Button type='link' className='uploaded-img-btn ml-3' onClick={() => this.openPreview('load_trac_conf_previewImg')}>
                                  <InfoCircleOutlined style={{
                                    fontSize: '20px',
                                    color: 'grey'
                                  }} />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24}>
                    <Row gutter={[12, 12]} className=''>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <p className='d-flex gap-10'>
                          <span>6.</span>
                          <span>Upload Build Plan image (.jpg, .jpeg or .png)</span>
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={14}>
                        <div className='scaletbl-img d-flex'>
                          <Upload
                            accept='image/jpg,image/jpeg,image/png'
                            fileList={this.state.build_plan_conf_fileList}
                            maxCount={1} beforeUpload={() => false}
                            onChange={(e) => this.onChangeFileHandler(e, 'build_plan_conf')}
                            onRemove={() => this.onRemoveFileHandler()}
                            className='upload-name'>
                            <Button type='ghost' className={`image-btn ${this.state.build_plan_conf_fileList[0]?.lastModifiedDate ? 'green-fliter' : ''}`}>
                              {this.state.build_plan_conf_previewImg ?
                                <img className='prev-img' src={this.state.build_plan_conf_previewImg} alt={this.state.build_plan_conf_previewImg} width={'100px'} height={'100%'} />
                                :
                                <img src={download} alt='download' />}
                            </Button>
                          </Upload>
                          {this.state.build_plan_conf_previewImg && (
                            <>
                              <Tooltip title={'View Attachment'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                                <Button type='link' className='uploaded-img-btn ml-3' onClick={() => this.openPreview('build_plan_conf_previewImg')}>
                                  <InfoCircleOutlined style={{
                                    fontSize: '20px',
                                    color: 'grey'
                                  }} />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs={24}>
                    <Row gutter={[12, 12]} className=''>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                        <p className='d-flex gap-10'>
                          <span>7.</span>
                          <span>Upload Weight & Balance Tool image (.jpg, .jpeg or .png)</span>
                        </p>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={14}>
                        <div className='scaletbl-img d-flex'>
                          <Upload
                            accept='image/jpg,image/jpeg,image/png'
                            fileList={this.state.wandb_conf_fileList}
                            maxCount={1} beforeUpload={() => false}
                            onChange={(e) => this.onChangeFileHandler(e, 'wandb_conf')}
                            onRemove={() => this.onRemoveFileHandler()}
                            className='upload-name'>
                            <Button type='ghost' className={`image-btn ${this.state.wandb_conf_fileList[0]?.lastModifiedDate ? 'green-fliter' : ''}`}>
                              {this.state.wandb_conf_previewImg ?
                                <img className='prev-img' src={this.state.wandb_conf_previewImg} alt={this.state.wandb_conf_previewImg} width={'100px'} height={'100%'} />
                                :
                                <img src={download} alt='download' />}
                            </Button>
                          </Upload>
                          {this.state.wandb_conf_previewImg && (
                            <>
                              <Tooltip title={'View Attachment'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                                <Button type='link' className='uploaded-img-btn ml-3' onClick={() => this.openPreview('wandb_conf_previewImg')}>
                                  <InfoCircleOutlined style={{
                                    fontSize: '20px',
                                    color: 'grey'
                                  }} />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col xs={24}>
                    <button className='modal-submit1' onClick={() => this.saveAudit()}>Save Audit</button>
                  </Col> */}
                </Row>
              </Col>
              : null
          }
          {this.state.noRideModalType === 'AUDIT' ? <Col xs={24} className='no-ride title-text'>No Ride Alerts Submitted</Col> : ''}
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className='p-0'>
            <div className='no-ride'>
              <Row
                gutter={[{
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 12
                }, {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  xxl: 0
                },]} className={`flight-ctrl noride-cols mb-0 p-0 ${this.state.noRideModalType === 'AUDIT' ? 'mt-0' : ''}`}
                style={{ marginTop: '40px' }}
              >
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={3} className=''>
                  <Form.Item required label='Container ID' labelCol={{ span: 24 }} colon={false} className='custom-formitem'>
                    <Input placeholder='Container ID' value={this.state.noride_container_id} onChange={(e) => this.setState({
                      noride_container_id: e.target.value,
                      noride_type: e.target.value.substring(0, 3)
                    })} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} className='type-col'>
                  <Form.Item required label='Type' labelCol={{ span: 24 }} colon={false} className='custom-formitem'>
                    <Input placeholder='Type' value={this.state.noride_type} onChange={(e) => this.setState({ noride_type: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4} className=''>
                  <Form.Item required label='Cut Code' labelCol={{ span: 24 }} colon={false} className='custom-formitem'>
                    <Select
                      size='small'
                      className='w-60'
                      value={this.state.noride_cutcode}
                      placeholder={'Select Cut Code'}
                      dropdownClassName='dropdown-options'
                      onChange={(e) => this.setState({
                        noride_cutcode: e,
                        cut_reasons: cut_codes?.find((item) => item?.code === e)?.reasons,
                        noride_cutreason: cut_codes?.find((item) => item?.code === e)?.reasons.length == 1 ?
                          cut_codes?.find((item) => item?.code === e)?.reasons[0] : null,
                        noride_cutby: cut_codes?.find((item) => item?.code === e)?.cutby,
                      })}
                    >
                      {
                        cut_codes?.map((item, index) => (
                          <Option key={index} value={item?.code}>{item?.code}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4} className=''>
                  <Form.Item required label='Reason' labelCol={{ span: 24 }} colon={false} className='custom-formitem'>
                    <Select
                      size='small'
                      className='w-60'
                      dropdownClassName='dropdown-options'
                      placeholder={'Select Reason'}
                      value={this.state.noride_cutreason}
                      onChange={(e) => this.setState({ noride_cutreason: e })}
                    >
                      {
                        this.state.cut_reasons?.map((item, index) => (
                          <Option key={index} value={item}>{item}</Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} className='type-col'>
                  <Form.Item required label='Cut By' labelCol={{ span: 24 }} colon={false} className='custom-formitem'>
                    <Input
                      size='small'
                      className='w-60'
                      value={this.state.noride_cutby}
                      placeholder='Cut By'
                      onChange={(e) => this.setState({ noride_cutby: e.target.value })}
                    />
                    {/* <Select
                    size='small'
                    className='w-60'
                    value={this.state.noride_cutby}
                    placeholder={'Select Cut By'}
                    onChange={(e) => this.setState({ noride_cutby: e })}
                  >
                    <Option value='ALA'>ALA</Option>
                    <Option value='LCC'>LCC</Option>
                    <Option value='LDD'>LDD</Option>
                  </Select> */}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={4} className=''>
                  <Form.Item label='Comments' labelCol={{ span: 24 }} colon={false} className='custom-formitem'>
                    <Input placeholder='Comments' value={this.state.noride_comment} onChange={(e) => this.setState({ noride_comment: e.target.value })} />
                  </Form.Item>
                </Col>
                <Col flex={'104px'} className='text-center p-0'>
                  <label className='custom-label'>Attachment</label>
                  <div className='scaletbl-img'>
                    <Upload
                      accept='image/jpg,image/jpeg,image/png'
                      fileList={this.state.noride_fileList}
                      maxCount={1} beforeUpload={() => false}
                      onChange={(e) => this.onChangeFileHandler(e, 'noride')}
                      onRemove={() => this.onRemoveFileHandler()}
                      className='upload-name'
                    >
                      <Button type='ghost' className={`image-btn ${this.state.noride_attach_conf ? 'green-fliter' : ''}`} >
                        <img src={download} alt='download' />
                      </Button>
                    </Upload>
                  </div>
                </Col>
                <Col flex={'30px'} className='plus-btn pl-0'>
                  {/* <button className='modal-submit1 noride-btn' onClick={() => this.addNoRide()}>Add </button> */}
                  <label className='custom-label transparent'>-</label>
                  <Button type='ghost' className='ml-auto image-btn' onClick={() => this.addNoRide()}>
                    <Tooltip title={'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                  </Button>
                </Col>
                {/* <Col flex='44px' className='text-center ml-auto p-0'>
                    <label className='custom-label transparent'>-</label>
                    <Button type='ghost' className='ml-auto image-btn' onClick={() => this.submit('add')}>
                      <Tooltip title={this.state.isEdit ? 'Update' : 'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                    </Button>
                  </Col> */}
              </Row>
            </div>
          </Col>

          <Row gutter={[{
            xs: 12,
            sm: 12,
            md: -140,
            lg: -140
          }, {
            xs: 12,
            sm: 12,
            md: 25,
            lg: 50
          },]}
          className='modal-row1 mt-0'>
            {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div style={{ 
                display:'flex',
                justifyContent: 'flex-end'
              }}>
                <button className='modal-submit'> Add Rows </button>
                <button className='modal-submit'> Clear Table </button>
              </div>   
              <div className='table-outer'>
                <Table
                  className='custom-table w-100 multi-head  checklist-table'
                  dataSource={noRideData}
                  columns={noRideCols}
                  pagination={false}
                />
              </div>
            </Col> */}
            {
              this.state.questionsData?.map((item, index) => (
                (item.parent_id && item.showchild === false) ? (
                  null
                ) : (
                  <>
                    <Col xs={9}>
                      <p className='questions d-flex gap-10'>
                        <span>{`${index + 1}.`}</span>
                        <span>{`${item.details.question}`}</span></p>
                    </Col>
                    <Col xs={6}>
                      {
                        item.details.type === 'yes/no' ?
                          <Select
                            size='small'
                            className='w-60'
                            value={item?.answer}
                            onChange={(e) => this.handleAnswers(e, index)}
                          >
                            <Option value='Yes'>Yes</Option>
                            <Option value='No'>No</Option>
                            <Option value='N/A'>N/A</Option>
                          </Select> :
                          item.details.type === 'other' ?
                            <Input.TextArea placeholder='Provide details' value={item?.answer} onChange={(e) => this.handleAnswers(e, index)} /> :
                            item.details.type === 'mcq' ?
                              <Select
                                size='small'
                                className='w-60'
                                value={item?.answer}
                                onChange={(e) => this.handleAnswers(e, index)}
                              >
                                {item.details.options?.map((option, index) => <Option key={index} value={option.name}>{option.name}</Option>)}
                              </Select>
                              : null
                      }
                    </Col>
                    <Col xs={8}>
                      {
                        item.details.info ?
                          <>
                            <ExclamationCircleOutlined style={{ color: '#eaaa00' }} />
                            <span className='info-text'>{item.details.info}</span>
                          </>
                          : null
                      }
                      {
                        item.details.type === 'yes/no' && item.details.comment && item.details.comment === item.answer ?
                          <>
                            <Input.TextArea placeholder='If no, provide details' value={item?.comment} onChange={(e) => this.handleComment(e, index)} />
                          </>
                          : null
                      }
                    </Col>
                  </>
                )
              ))
            }
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div className='table-outer'>
                <Table
                  className='custom-table w-100 checklist-table'
                  dataSource={this.state.norideData}
                  columns={noridecols}
                  pagination={false}
                />
              </div>
            </Col>
            {
              this.state.noRideModalType === 'NORIDE' ?
                <>
                  <Form layout='inline' className='ldd-ala-section'>
                    <Col xs={10} >
                      {/* <Form layout='vertical'> */}
                      <Form.Item required label='LDD'>
                        <Input placeholder='LDD Name' value={this.state.noride_ldd} onChange={(e) => this.setState({ noride_ldd: e.target.value })} />
                      </Form.Item>
                      {/* </Form> */}
                    </Col>
                    <Col xs={10} >
                      {/* <Form layout='vertical' > */}
                      <Form.Item required label='ALA'>
                        <Input placeholder='ALA Name' value={this.state.noride_ala} onChange={(e) => this.setState({ noride_ala: e.target.value })} />
                      </Form.Item>
                    </Col>
                  </Form>
                </ > : null
            }
          </Row>
        </Modal>
        <Modal
          title='LDD Communication'
          open={this.state.openLDDModal}
          closable={true}
          onCancel={() => this.setState({
            openLDDModal: false,
            // quizMilestonid: null,
            // quizModalFlight: null,
            // questionsData: this.state.tempquestionsData,
          })}
          footer={<>
            <div className='noride-submit'>
              <button className='modal-submit1 submit' onClick={() => this.saveLDD()}>{this.state.quizModalFlight?.ldd ? 'Resubmit' : 'Submit'}</button>
              <button className='modal-submit1' onClick={() => this.setState({ openLDDModal: false })}> Close  </button>
            </div>
          </>
          }
          width={'60%'}
          centered
          className='custom-modal pb-0 audit-modal'
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} >
            <Col className='cargo-cap-card'>
              <div>
                <Row gutter={[{
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                }, {
                  xs: 12,
                  sm: 12,
                  md: 12,
                  lg: 24
                },]} className='flight-ctrl'
                >
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Flight Number</small>
                      <p className={this.state.editRecord?.ac_type === '75D' ? 'new-ac' : 'info-data mb-2'}>{this.state.quizModalFlight?.flight_no}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Flight Date</small>
                      <p className='info-data mb-2'>{
                        this.state.quizModalFlight?.flight_date ? moment(this.checkFlightDate(this.state.quizModalFlight)).format('DD/MM/YYYY') :
                          this.state.quizModalFlight?.dept_date ? moment.utc(this.state.quizModalFlight?.dept_date).tz(this.state.timezone).format('DD/MM/YYYY')?.toUpperCase() :
                            this.state.quizModalFlight?.schdl ? moment.tz(this.state.quizModalFlight?.schdl, this.state.timezone).format('DD/MM/YYYY')?.toUpperCase() :
                              'N/A'
                      }</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                    <div className='recd-ht'>
                      <small className='info-title mb-1'>Origin</small>
                      <p className='info-data mb-2 space-nowrap'>{this.state.quizModalFlight?.org_stn}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht min-width-80'>
                      <small className='info-title mb-1'>Dest</small>
                      <p className='info-data mb-2'>{this.state.quizModalFlight?.dest_stn}</p>
                    </div>
                  </Col>
                  <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <div className='recd-ht min-width-80'>
                      <small className='info-title mb-1'>Gate</small>
                      <p className='info-data mb-2'>{this.state.quizModalFlight?.dept?.gate || 'N/A'}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={24} className='audit-quiz' >
            <Row
              gutter={[{
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              }, {
                xs: 24,
                sm: 24,
                md: 36,
                lg: 42
              },]} className='flight-ctrl'
            >
              <Col xs={24}>
                <Row gutter={[12, 12]} className=''>
                  <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                    <p className='d-flex gap-10'>
                      <span>1.</span>
                      <span>In LoadTrac, were the Cargo pallets (PMC,PAG) paired?</span>
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                    <Select
                      size='small'
                      className='w-100'
                      value={this.state.pallets_paired}
                      placeholder={'Select Yes/No'}
                      onChange={(e) => this.setState({
                        pallets_paired: e,
                        pallets_paired_contact: null,
                        pallets_paired_explain: null
                      })}
                    >
                      <Option value='Yes'>Yes</Option>
                      <Option value='No'>No</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Row gutter={[12, 12]} className=''>
                  <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                    <p className='d-flex gap-10'>
                      <span>1a.</span>
                      <span> If no, did you contact the LDD to recommend pairing the pallets ?</span>
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={4}>
                    <Select
                      size='small'
                      className='w-100'
                      value={this.state.pallets_paired_contact}
                      placeholder={'Select Yes/No'}
                      disabled={this.state.pallets_paired !== 'No'}
                      onChange={(e) => this.setState({
                        pallets_paired_contact: e,
                        pallets_paired_explain: null
                      })}
                    >
                      <Option value='Yes'>Yes</Option>
                      <Option value='No'>No</Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                    <Input placeholder='If no, explain why not'
                      disabled={this.state.pallets_paired_contact !== 'No'}
                      value={this.state.pallets_paired_explain}
                      onChange={(e) => this.setState({ pallets_paired_explain: e.target.value })}
                    />
                  </Col>
                </Row>
              </Col>


              <Col xs={24}>
                <Row gutter={[12, 12]} className=''>
                  <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                    <p className='d-flex gap-10'>
                      <span>2.</span>
                      <span>In LoadTrac, were and conyeyances staged to No Ride?</span>
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                    <Select
                      size='small'
                      className='w-100'
                      value={this.state.conveyances_staged}
                      placeholder={'Select Yes/No'}
                      onChange={(e) => this.setState({
                        conveyances_staged: e,
                        conveyances_staged_contact: null,
                        conveyances_staged_explain: null,
                      })}
                    >
                      <Option value='Yes'>Yes</Option>
                      <Option value='No'>No</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col xs={24}>
                <Row gutter={[12, 12]} className=''>
                  <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                    <p className='d-flex gap-10'>
                      <span>2a.</span>
                      <span> If yes, did you contact the LDD to troubleshoot?</span>
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={6} xl={4}>
                    <Select
                      size='small'
                      className='w-100'
                      value={this.state.conveyances_staged_contact}
                      placeholder={'Select Yes/No'}
                      disabled={this.state.conveyances_staged !== 'Yes'}
                      onChange={(e) => this.setState({
                        conveyances_staged_contact: e,
                        conveyances_staged_explain: null,
                      })}
                    >
                      <Option value='Yes'>Yes</Option>
                      <Option value='No'>No</Option>
                    </Select>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                    <Input placeholder='If no, explain why not'
                      disabled={this.state.conveyances_staged_contact !== 'No'}
                      value={this.state.conveyances_staged_explain}
                      onChange={(e) => this.setState({ conveyances_staged_explain: e.target.value })}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Row gutter={[{
            xs: 12,
            sm: 12,
            md: -140,
            lg: -140
          }, {
            xs: 12,
            sm: 12,
            md: 25,
            lg: 50
          },]}
          className='modal-row1'>
            {
              this.state.questionsData?.map((item, index) => (
                (item.parent_id && item.showchild === false) ? (
                  null
                ) : (
                  <>
                    <Col xs={9}>
                      <p className='questions d-flex gap-10'>
                        <span>{`${index + 1}.`}</span>
                        <span>{`${item.details.question}`}</span></p>
                    </Col>
                    <Col xs={6}>
                      {
                        item.details.type === 'yes/no' ?
                          <Select
                            size='small'
                            className='w-60'
                            value={item?.answer}
                            onChange={(e) => this.handleAnswers(e, index)}
                          >
                            <Option value='Yes'>Yes</Option>
                            <Option value='No'>No</Option>
                            <Option value='N/A'>N/A</Option>
                          </Select> :
                          item.details.type === 'other' ?
                            <Input.TextArea placeholder='Provide details' value={item?.answer} onChange={(e) => this.handleAnswers(e, index)} /> :
                            item.details.type === 'mcq' ?
                              <Select
                                size='small'
                                className='w-60'
                                value={item?.answer}
                                onChange={(e) => this.handleAnswers(e, index)}
                              >
                                {item.details.options?.map((option, index) => <Option key={index} value={option.name}>{option.name}</Option>)}
                              </Select>
                              : null
                      }
                    </Col>
                    <Col xs={8}>
                      {
                        item.details.info ?
                          <>
                            <ExclamationCircleOutlined style={{ color: '#eaaa00' }} />
                            <span className='info-text'>{item.details.info}</span>
                          </>
                          : null
                      }
                      {
                        item.details.type === 'yes/no' && item.details.comment && item.details.comment === item.answer ?
                          <>
                            <Input.TextArea placeholder='If no, provide details' value={item?.comment} onChange={(e) => this.handleComment(e, index)} />
                          </>
                          : null
                      }
                    </Col>
                  </>
                )
              ))
            }
          </Row>
        </Modal>
        <Modal
          title={'Assign to ULD'}
          open={this.state.addULD}
          closable={true}
          onCancel={() => this.setState({
            addULD: false,
            editUlds: {}
          })}
          footer={false}
          width={'50%'}
          centered
          className='custom-modal pb-0'
          zIndex={1001}
        >
          <Row gutter={[{
            xs: 0,
            sm: 0,
            md: 24,
            lg: 24
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 24
          },]} className='with-table'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
              <Form
                layout='vertical'
                className='position-relative'
              >
                <Row
                  gutter={[{
                    xs: 24,
                    sm: 24,
                    md: 24,
                    lg: 24
                  }, {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 0
                  },]} >
                  <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4} className=''>
                    <Form.Item label='Awb'>
                      <Input
                        size='small'
                        placeholder=''
                        // name='awb'
                        disabled
                        value={this.state.flightDetails?.awb_no}
                      // onChange={(e) => this.handleAssignUldChange(e)}     
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4} className=''>
                    <Form.Item label='Uld'>
                      {/* <Input
                        size='small'
                        placeholder=''
                        name='uld_type'
                        value={this.state.assignUld.uld_type}
                        onChange={(e) => this.handleAssignUldChange(e)}
                      /> */}
                      <Select
                        size='small'
                        className='w-60'
                        value={this.state.assignUld.id}
                        onChange={(e) => this.handleAssignUldChange({
                          target: {
                            name: 'id',
                            value: e
                          }
                        })}
                      // onChange={(e) => console.log(e)}
                      // popupClassName='mcq-popup other-popup'
                      >
                        {this.state.editRecord?.build_uld?.map((option, index) => option.type && option.uld_no && <Option key={index} value={option.id}>{option.type}{option.uld_no}</Option>)}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4} className=''>
                    <Form.Item label='Uld No.'>
                      <Select
                        size='small'
                        className='w-60'
                        value={this.state.assignUld.uld_no}
                        onChange={(e) => this.handleAssignUldChange({
                          target:{
                            name: 'uld_no',
                            value: e
                          } 
                        })}
                        // popupClassName='mcq-popup other-popup'
                      >
                        {this.state.editRecord?.build_uld?.map((option, index) => <Option key={index} value={option.uld_no}>{option.uld_no}</Option>)}
                      </Select>
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={3} className=''>
                    <Form.Item label='Awb Pcs'>
                      <Input
                        size='small'
                        placeholder=''
                        name='pcs'
                        value={this.state.assignUld.pcs}
                        onChange={(e) => this.handleAssignUldChange(e)}

                      />
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={4} className=''>
                    <Form.Item label='Remarks/Pri#'>
                      <Input
                        size='small'
                        placeholder=''
                        name='remark'
                        value={this.state.assignUld.remark}
                        onChange={(e) => this.handleAssignUldChange(e)}

                      />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={3} className=''>
                    <Form.Item label='Est. GrWt(lbs)'>
                      <Input
                        size='small'
                        placeholder=''
                        name='wgt'
                        value={this.state.assignUld.wgt}
                        onChange={(e) => this.handleAssignUldChange(e)}

                      />
                    </Form.Item>
                  </Col>
                  <Col flex='40px' className='text-center ml-auto pl-0'>
                    <label className='custom-label transparent'>-</label>
                    <Button type='ghost' className='ml-auto image-btn' onClick={this.submitAssignUld}>
                      <Tooltip title={'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div className='table-outer'>
                <Table
                  className='custom-table exceptionlist-table w-100'
                  dataSource={this.state.assignedUldsData}
                  columns={uldColumns}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={this.state.flightModalType === 'ADD' ? 'Add New Flight' : 'Edit Flight'}
          open={this.state.showFlightModal}
          closable={true}
          onCancel={() => this.setState({
            flightModalType: 'ADD',
            showFlightModal: false,
            flight_no: null,
            flight_date: null,
            dept_time: moment().startOf('day'),
            arr_time: moment().startOf('day'),
            org_stn: cookies.get('station'),
            dest_stn: null,
            aircrafttype: null,
            ack1: false,
            ack2: false,
          })}
          footer={[
            <>
              <Space size={[24, 0]} >
                {/* <div>
                  <Checkbox className='modal-checkbox' />
                  <label> I acknowledge that the above information is correct</label>
                </div>
                <div>
                  <Checkbox className='modal-checkbox' />
                  <label>I acknowledge that the flight does not already appear in command</label>
                </div> */}

                <button className='modal-submit' onClick={
                  this.state.flightModalType === 'EDIT' ? this.putFlight : this.addFlight
                } disabled={!this.state.ack1 || !this.state.ack2 ? true : false}>Submit </button>
              </Space>
            </>
          ]}
          width={'850px'}
          centered
          className='custom-modal pb-0'
        >
          {/* <Spin spinning={this.state.skeletonLoad}> */}
          <Card className='modal-card'>
            <Row
              gutter={[24, 12]}
              className='with-table add-flight-details'
            >
              <Col xs={6} className='fields'>
                <label className='card-text' >Flight Number</label>
                <Input
                  size='small'
                  placeholder=''
                  onChange={(e) => this.setState({ flight_no: e.target.value })}
                  value={this.state.flight_no}
                />
              </Col>
              <Col xs={6} className='fields'>
                <label className='card-text'>Flight Date</label>
                <DatePicker
                  min={0}
                  precision={0}
                  controls={false}
                  size='small'
                  placeholder=''
                  value={this.state.flight_date || ''}
                  onChange={(e) => this.setState({ flight_date: e })}
                />
              </Col>

              <Col xs={6} className='fields'>
                <label className='card-text'>Origin</label>
                {/* <Select
                  onChange={(e) => this.setState({ org_stn: e })}
                  value={this.state.org_stn}
                >
                  {
                    org_stations?.map((item, index) => ( <Option key={index} value={item.value}>{item.label}</Option>))
                  }
                </Select> */}
                <Input className='card-text' value={this.state.org_stn} disabled />
              </Col>
              <Col xs={6} className='fields'>
                <label className='card-text'>Dest</label>
                <Input
                  size='small'
                  placeholder=''
                  onChange={(e) => this.setState({ dest_stn: e.target.value })}
                  value={this.state.dest_stn}
                />
              </Col>
              <Col xs={6} className='fields'>
                <label className='card-text'>Ac Type</label>
                <Select
                  value={this.state.aircrafttype}
                  size='small'
                  // placeholder='Sort By'
                  className='text-center w-100'
                  onChange={(e) => this.setState({ aircrafttype: e })}
                >
                  {this.state.fleetData?.map((option, index) => <Option key={index} value={option.details?.ac_code}>{option.details?.ac_code}</Option>)}
                </Select>
                {/* <Input
                  size='small'
                  placeholder=''
                  onChange={(e) => this.setState({ aircrafttype: e.target.value })}
                  value={this.state.aircrafttype}
                /> */}
              </Col>
              <Col xs={6} className='fields'>
                <label className='card-text'>Schd arriv time(Local)</label>
                <TimePicker
                  format='HH:mm'
                  allowClear={false}
                  onChange={(moment) => this.setState({ arr_time: moment })}
                  value={moment(this.state.arr_time, timeFormat)}
                />
              </Col>
              <Col xs={6} className='fields'>
                <label className='card-text'>Schd dept time(Local)</label>
                <TimePicker
                  format='HH:mm'
                  allowClear={false}
                  onChange={(moment) => this.setState({ dept_time: moment })}
                  value={moment(this.state.dept_time, timeFormat)}
                />
              </Col>
              <Col xs={6} className='add-flight-info'>
                <Tooltip
                  title='Note: FPE does not change for delayed flights. FPE is always based on original scheduled departure time'
                  overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}
                  placement='right'
                >
                  <ExclamationCircleOutlined style={{ color: '#eaaa00' }} />
                </Tooltip>
              </Col>
            </Row>
          </Card>
          <div className='info-section'>
            {/* <Col xs={4}></Col> */}
            <Col xs={12} /*className='info-section'*/>
              <Checkbox className='modal-checkbox' checked={this.state.ack1} onChange={(e) => this.setState({ ack1: e.target.checked })} />
              <label> I acknowledge that the above information is correct</label>
            </Col>
            {/* <Col xs={8}></Col> */}
            {/* <Col xs={4}></Col> */}
            <Col xs={12} /*className='info-section'*/>
              <Checkbox className='modal-checkbox' checked={this.state.ack2} onChange={(e) => this.setState({ ack2: e.target.checked })} />
              <label>I acknowledge that the flight does not already appear in command</label>
            </Col>
            {/* <Col xs={8}></Col> */}
          </div>

          {/* <Row className='modal-row' xs={24}>
            <Form layout='inline' > */}
          {/*               
                <div className='info-item'>
                  <ExclamationCircleOutlined style={{ color: '#eaaa00' }} />
                  <p className='info-text'>Text</p>
                </div>
         */}
          {/* <Form.Item label='I acknowledge that the above information is correct'>
                <Checkbox className='modal-checkbox' />
              </Form.Item>
              <Form.Item label='I acknowledge that the flight does not already appear in command'>
                <Checkbox className='modal-checkbox' />
              </Form.Item>
              <Form.Item> 
                <button className='modal-submit'>Submit </button>
              </Form.Item>
            </Form>
          </Row> */}
          {/* <Space className='info-section'>
            <ExclamationCircleOutlined style={{ color: '#eaaa00' }} />
            <p className='info-text'>Note: FPE does not change for delayed flights. FPE is always based on original scheduled departure time</p>
          </Space> */}
        </Modal>
        <Modal
          width={400}
          closable
          //maskClosable= {false}
          destroyOnClose
          footer={null}
          centered
          open={this.state.flightAuditImg}
          onCancel={() => this.setState({ flightAuditImg: false })}
          className='preview-modal'
        >
          {this.state.previewType ? this.state[this.state.previewType] && (<img className='prev-img' src={this.state[this.state.previewType]} alt={this.state.previewType} />) : null}
        </Modal>
        <Modal
          width={400}
          closable
          //maskClosable= {false}
          destroyOnClose
          footer={null}
          centered
          open={this.state.isModalVisible}
          onCancel={() => { this.setState({ previewImgNoRide: false }, () => this.setState({ isModalVisible: false })) }}
          className='preview-modal'
        >
          {this.state.previewImgNoRide && (<img className='prev-img' src={this.state.previewImgNoRide.path} alt={this.state.previewImgNoRide.name} />)}
        </Modal>
        {this.state.openPlanner &&
          <WeightAndBalance
            openWB={this.openWB}
            openPlanner={this.state.openPlanner}
            editRecordId={this.state.editRecordId}
            editRecord={this.state.editRecord}
          />
        }
      </div>
    )
  }
}

export default FlightControl