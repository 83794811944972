import React, { useState, useEffect } from 'react'
import { 
  Audit,
  ClearData, 
  UnprocessedMessages 
} from './tabs'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { developerDomainTabs } from '../../constants/constants'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import DashboardConfig from './dashboardConfig'

const DeveloperDomain = ({ match }) => {

  const [tab, setTab] = useState(false)

  useEffect(() => {
    if (match?.params.tab) {
      if (developerDomainTabs[match.params.tab]) {
        setTab(developerDomainTabs[match.params.tab])
      } else {
        setTab(developerDomainTabs.clear_data)
      }
    } else {
      setTab(developerDomainTabs.clear_data)
    }
  }, [])


  const tabHnadler = (t) => {
    setTab(t)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  return (
    <div>

      <DashboardConfig />
    
      <Slider {...settings} className='custom-slider mb-24px'>
        <div className={`tabs ${tab == developerDomainTabs.clear_data? 'active-tab' : ''}`} onClick={() => tabHnadler(developerDomainTabs.clear_data)}>
          <span>Clear Data</span>
        </div>

        <div className={`tabs ${tab == developerDomainTabs.unprocessed_msgs? 'active-tab' : ''}`} onClick={() => tabHnadler(developerDomainTabs.unprocessed_msgs)}>
          <span>Unprocessed Messages</span>
        </div>

        <div className={`tabs ${tab == developerDomainTabs.audit? 'active-tab' : ''}`} onClick={() => tabHnadler(developerDomainTabs.audit)}>
          <span>Audit Messages</span>
        </div>

      </Slider>

      {tab == developerDomainTabs.clear_data &&
        <ClearData />
      }
      {tab == developerDomainTabs.unprocessed_msgs &&
        <UnprocessedMessages />
      }
      {tab == developerDomainTabs.audit &&
        <Audit />
      }
    </div>
  )
}
DeveloperDomain.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default DeveloperDomain