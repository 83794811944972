import React, { Component } from 'react'
import { Row, Col, Typography, Card, Table, Form, DatePicker, Select, notification } from 'antd'
import Http from '../../../utils/http'
import { changeLogTabs, jsonTheme, changeLogLabels, timezone } from '../../../constants/constants'
import JSONTree from 'react-json-tree'
import './index.css'
import moment from 'moment'
import Cookies from 'universal-cookie'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

let format = 'DDMMMYY HH:mm'
const { Title } = Typography
const { Option } = Select

let system_parameters_strings = [
  {
    key: 'fleet',
    value: 'Fleet'
  },
  {
    key: 'recovery_action',
    value: 'Recovery Actions'
  },
  {
    key: 'rebooking_teams_sla',
    value: 'Rebooking Teams & SLA'

  },
  {
    key: 'dynamic_info',
    value: 'Dynamic Info'
  },
  {
    key: 'product_work_area_mapping_workarea',
    value: 'Work Area'
  },
  {
    key: 'product_work_area_mapping_workareaproducts',
    value: 'Products to Work Area'
  },
  {
    key: 'product_work_area_mapping_workarealocations',
    value: 'Locations to Work Area'
  },
  {
    key: 'product_work_area_mapping_exceptions',
    value: 'Work Area Exceptions'
  },
  {
    key: 'product_buildup_priority',
    value: 'Product Build Up Priority'
  },
  {
    key: 'handling_times',
    value: 'Handling Times'
  },
]

let module_one_strings = [
  {
    key: 'Inbound Flight Preparation',
    value: 'Inbound Flight Preparation'
  },
  {
    key: 'Breakdown Control',
    value: 'Breakdown Control'
  },
  {
    key: 'Rebooking Queue',
    value: 'Rebooking Queue'

  },
]

function get_diff(title, obj1, obj2) {
  var old_key = {}
  var new_key = {}

  Object.keys(obj1).forEach(function(key){
    if ((typeof obj2[key] === 'object' && obj2[key] !== null) && (typeof obj1[key] === 'object' && obj1[key] !== null)) {
      let result = get_diff(title, obj1[key], obj2[key])
      Object.keys(result?.old_key).forEach(function(k) {
        old_key[k] = result?.old_key[k]
        new_key[k] = result?.new_key[k]
      })
    } else if (obj2[key] != obj1[key]) {
      new_key[key] = obj2[key]
      old_key[key] = obj1[key]
    }
  })
  return {
    old_key: old_key,
    new_key: new_key,
  }
}

function return_details(details) {
  let all_data = {}
  Object.keys(details).forEach(function(key) {
    if (typeof details[key] === 'object' && details[key] !== null) {
      let result = return_details(details[key])
      Object.keys(result).forEach(function(k) {
        all_data[k] = result[k]
      })
    } else {
      all_data[key] = details[key]
    }
  })
  return all_data
}
class ChangeLogTab extends Component {
  constructor() {
    super()
    this.state = {
      station: new Cookies().get('station'),
      timezone: new Cookies().get('timezone'),
      ext: timezone.find((item) => item.value === new Cookies().get('station'))?.ext || 'EST',
      currentTab: changeLogTabs.admin_logs,
      data: [],
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().add(1, 'days').format('YYYY-MM-DD'),
      page_type: '',
    }
  }

  componentDidMount() {
    this.setState({ currentTab: this.props.prop }, () => this.fetchLogs())
  }

  fetchLogs = async() => {
    let tab = this.state.currentTab?.toUpperCase() === changeLogTabs.admin_logs?.toUpperCase() ? 'admin' : 'system'
    let url = `/change_logs?station=${this.state.station}&type=${tab}&start=${this.state.start_date}&end=${this.state.end_date}&page_title=${this.state.page_type}`
    await Http.get(url, { headers: '' }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        let processed_data = this.processLogs(resp?.data?.data)
        this.setState({ data: [] }, () => this.setState({ data: processed_data }))
      } else {
        this.setState({ data: [] })
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
      }
    })
  }

  processLogs = (data) => {
    data?.map((item) => {
      item.title = this.state.currentTab?.toUpperCase() === changeLogTabs.admin_logs?.toUpperCase() ?
        system_parameters_strings?.find((string) => string?.key === item?.page_title)?.value :
        module_one_strings?.find((string) => string?.key === item?.page_title)?.value
      item.prev_details = item?.prev_details ? this.delete_keys(item?.prev_details) : null
      item.new_details = item?.new_details ? this.delete_keys(item?.new_details) : null

      if(item?.operation?.toUpperCase() === 'ADD' || item?.operation?.toUpperCase() === 'UPDATE') {
        item.all_details = item?.new_details ? return_details(item?.new_details) : null
      } else {
        item.all_details = item?.prev_details ? return_details(item?.prev_details) : null
      }

      if(item?.prev_details && item?.new_details) {
        item.difference = get_diff(item?.id, item.prev_details, item.new_details)
      }
      item.message = item?.difference && item?.operation?.toUpperCase() === 'UPDATE' ? this.generateMessage(item?.difference).split('| ') : null
    })
    return data
  }

  delete_keys = (object) => {
    let ext = this.state.ext
    let timezone = this.state.timezone
    Object.keys(object).forEach(function(key) {
      if (key === 'id') {
        delete object[key]
      } else if (key === 'uuid') {
        delete object[key]
      } else if (key === 'createdAt') {
        delete object[key]
      } else if (key === 'ref_id') {
        delete object[key]
      } else if (key === 'is_deleted') {
        delete object[key]
      } else if (key === 'recovery_action_id') {
        delete object[key]
      }
      else if (key === 'updatedAt' || key === 'rebook_comp_datetime' || key === 'rebook_req_datetime') {
        object[key] = moment(object[key]).tz(timezone).format('DDMMMYY HH:mm') + ' ' + ext
      }
    })
    return object
  }

  generateMessage = (details) => {
    let message = ''
    let length = Object.keys(details?.old_key).length - 1

    Object.keys(details?.old_key).forEach(function(key, index) {
      if(length === index) {
        if((details?.old_key[key] && typeof details?.old_key[key] === 'object') && typeof details?.new_key[key] && details?.new_key[key] === 'object') {
          Object.keys(details?.old_key[key]).forEach(function(key2) {
            if(key2 === 'name') {
              message = message + `${changeLogLabels[key2]}; ${details?.old_key[key][key2]} -> ${details?.new_key[key][key2]}`
            }
          })
        } else if(details?.old_key[key] && typeof details?.old_key[key] === 'object') {
          Object.keys(details?.old_key[key]).forEach(function(key2) {
            if(key2 === 'name') {
              message = message + `${changeLogLabels[key2]}; ${details?.old_key[key][key2]} -> ${'N/A'}`
            }
          })
        } else if(details?.new_key[key] && typeof details?.new_key[key] === 'object') {
          Object.keys(details?.new_key[key]).forEach(function(key2) {
            if(key2 === 'name') {
              message = message + `${changeLogLabels[key2]}; ${'N/A'} -> ${details?.new_key[key][key2]}`
            }
          })
        } else {
          if((typeof details?.old_key[key] == 'boolean') && (typeof details?.new_key[key] == 'boolean')) {
            message = message + changeLogLabels[key]+'; '+ (details?.old_key[key] === true ? 'Checked' : 'Unchecked') +' -> '+ (details?.new_key[key] === true ? 'Checked' : 'Unchecked')
          } else {
            message = message + `${changeLogLabels[key]}; ${details?.old_key[key] || 'N/A'} -> ${details?.new_key[key] || 'N/A'}`
          }
        }
      } else {
        if((details?.old_key[key] && typeof details?.old_key[key] === 'object') && (details?.new_key[key] && typeof details?.new_key[key] === 'object')) {
          Object.keys(details?.old_key[key]).forEach(function(key2) {
            if(key2 === 'name') {
              message = message + `${changeLogLabels[key2]}; ${details?.old_key[key][key2]} -> ${details?.new_key[key][key2]}| `
            }
          })
        } else if(details?.old_key[key] && typeof details?.old_key[key] === 'object') {
          Object.keys(details?.old_key[key]).forEach(function(key2) {
            if(key2 === 'name') {
              message = message + `${changeLogLabels[key2]}; ${details?.old_key[key][key2]} -> ${'N/A'}| `
            }
          })
        } else if(details?.new_key[key] && typeof details?.new_key[key] === 'object') {
          Object.keys(details?.new_key[key]).forEach(function(key2) {
            if(key2 === 'name') {
              message = message + `${changeLogLabels[key2]}; ${'N/A'} -> ${details?.new_key[key][key2]}| `
            }
          })
        } else {
          if((typeof details?.old_key[key] == 'boolean') && (typeof details?.new_key[key] == 'boolean')) {
            message = message + changeLogLabels[key]+'; '+ (details?.old_key[key] === true ? 'Checked' : 'Unchecked') +' -> '+ (details?.new_key[key] === true ? 'Checked| ' : 'Unchecked| ')
          } else {
            message = message + `${changeLogLabels[key]}; ${details?.old_key[key] || 'N/A'} -> ${details?.new_key[key] || 'N/A'}| `
          }
        }
      }
    })
    return message
  }

  DateFilters = () => {
    return (
      <>
        <Col xs={24} sm={24} md={8} lg={7} xl={7} xxl={7} className=''>
          <Form.Item label='From'>
            <DatePicker
              size='small'
              onChange={(_, string) => this.setState({
                start_date: string,
                end_date: string > moment(this.state.end_date).format('YYYY-MM-DD') ? moment(string).add(1, 'days').format('YYYY-MM-DD') : moment(this.state.end_date).format('YYYY-MM-DD')
              }, () => this.fetchLogs())}
              value={this.state.start_date && moment(this.state.start_date)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={8} lg={7} xl={76} xxl={7} className=''>
          <Form.Item label='To'>
            <DatePicker
              size='small'
              onChange={(_, string) => this.setState({
                start_date: !this.state.start_date ? moment(string).subtract(1, 'days').format('YYYY-MM-DD') : this.state.start_date,
                end_date: string,
              }, () => this.fetchLogs())}
              disabledDate={current => {
                return current && current < (moment(this.state.start_date) || moment())
              }}
              value={this.state.end_date && moment(this.state.end_date)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={14} md={7} lg={7} xl={7} xxl={7} className='text-center'>
          <Form.Item label='Filter by' className=''>
            <Select
              allowClear
              size='small'
              placeholder='Select page'
              onChange={(e) => this.setState({ page_type: e ? e : '' }, () => this.fetchLogs())}
            >
              {this.state.currentTab?.toUpperCase() === changeLogTabs.admin_logs?.toUpperCase() ?
                system_parameters_strings?.map((string) => (
                  <Option key={string.key} value={string.key}>{string.value}</Option>
                )) : module_one_strings?.map((string) => (
                  <Option key={string.key} value={string.key}>{string.value}</Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
      </>
    )
  }

  render() {

    const awbColumns = [
      {
        title: 'Date & Time',
        dataIndex: 'date_time',
        key: 'date_time',
        className: 'space-nowrap',
        render: (_, record) => (
          <span>{record?.createdAt ? moment(record.createdAt).format(format) + ' UTC' : 'N/A'}</span>
        )
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        className:'',
        render: (_, record) => (
          <span>{record?.user?.name?.first_name ? `${record?.user?.name?.first_name} ${record?.user?.name?.last_name}` : 'N/A'}</span>
        )
      },
      {
        title: 'Page',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'New Record',
        dataIndex: 'title',
        key: 'title',
        width: 300,
        render: (_, record) => (
          <>
            {record?.all_details ? <JSONTree data={record?.all_details} theme={jsonTheme} invertTheme={true} className='json-tree' /> : 'N/A'}
          </>
        )
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        className:'',
        render: (_, record) => (
          record?.message?.length > 0 ?
            record.message.map((msg) => (
              <>
                <b>{msg?.split(';')[0]}</b>: {msg?.split('->')[0]?.split(';')[1]} <b>{'->'}</b> {msg?.split('->')[1]}
                <br />
              </>
            ))
            : 'N/A'
        )
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        align: 'center',
      },
    ]

    let { currentTab } = this.state
    return (
      <div>
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 24,
              lg: 24,
            },
            {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
            },
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
            {/* ADMIN LOG */}
            {currentTab === changeLogTabs.admin_logs ?
              <Card className='custom-card h-100'>
                <Row
                  gutter={[
                    {
                      xs: 0,
                      sm: 0,
                      md: 24,
                      lg: 24,
                    },
                    {
                      xs: 24,
                      sm: 24,
                      md: 24,
                      lg: 24,
                    },
                  ]}
                  className='with-table'
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                    <Title level={5} className='mb-0'>
                      Admin Log
                    </Title>
                  </Col>
                  {this.DateFilters()}
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='table-overflow'
                  >
                    <div className='table-outer'>
                      <Table
                        className='custom-table'
                        dataSource={this.state.data}
                        columns={awbColumns}
                        pagination={false}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
              : null
            }

            {/* SYSTEM LOG */}
            {currentTab === changeLogTabs.system_logs ?
              <Card className='custom-card h-100'>
                <Row
                  gutter={[
                    {
                      xs: 0,
                      sm: 0,
                      md: 24,
                      lg: 24,
                    },
                    {
                      xs: 24,
                      sm: 24,
                      md: 24,
                      lg: 24,
                    },
                  ]}
                  className='with-table'
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                    <Title level={5} className='mb-0'>
                      System Log
                    </Title>
                  </Col>
                  {this.DateFilters()}
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    className='table-overflow'
                  >
                    <div className='table-outer'>
                      <Table
                        className='custom-table'
                        dataSource={this.state.data}
                        columns={awbColumns}
                        pagination={false}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
              : null
            }
          </Col>
        </Row>
      </div>
    )
  }
}

export default ChangeLogTab
