import React, { Component } from 'react'
import { pages as PAGE } from '../../constants/constants'
import { Row, Col, Typography, Form, Card, Table, Select, notification, Spin, Statistic, InputNumber, Divider, Popover, Popconfirm, Tooltip } from 'antd'
import { weightCalculator, getLeastAWB, handlingTimes, sort } from '../../utils/common'
import cssconstants from '../../constants/cssconstants'
import Http from '../../utils/http'
import HeaderSearch from '../search'
import Cookies from 'universal-cookie'
import './index.css'
import moment from 'moment'
import { CountUp } from '../../utils/timer'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import { cloneDeep } from 'lodash'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// eslint-disable-next-line no-duplicate-imports
import { constants, timezone } from '../../constants/constants'
// import { pageHandler, getCookie, COOKIE } from '../../utils/cookie'

const dateFormat = 'DDMMMYY HH:mm'

const { Title } = Typography
const { Option } = Select
const { Countdown } = Statistic

function formatFlightConnexNo(flight_no, flight_date) {
  let formatted = ''
  formatted = flight_no +'/'+ moment.utc(flight_date).format('DDMMMYY')
  return formatted
}
function formatElapsedTime(info, status) {
  let today = moment().utc(), time_diff = ''

  if(info && info?.fps) {
    let fps_date = moment.utc(info.fps, 'YYYY-MM-DD HH:mm')
    let end_date = moment.utc(info.end, 'YYYY-MM-DD HH:mm')

    if(status?.toUpperCase() !== 'COMPLETED') {
      time_diff = Math.abs(today.diff(fps_date, 'minutes'))
    } else {
      time_diff = Math.abs(end_date.diff(fps_date, 'minutes'))
    }

    var hours = Math.floor(time_diff / 60)
    var minutes = time_diff % 60
    var seconds = minutes % 60
    today.add(hours, 'hours').add(minutes, 'minutes').add(seconds, 'seconds')

    return today.local().format('YYYY-MM-DD HH:mm:ss')
  } else return ''
}
function getProdCode(awbs, priorityData) {
  let prodCodes = []
  if(awbs?.length > 0) {
    if(awbs.length === 1) {
      return awbs[0].awb.prod_code
    } else {
      awbs.map((awb) => {
        if(awb?.awb?.prod_code) prodCodes.push(awb.awb.prod_code)
      })

      if(priorityData?.length === 0) return prodCodes[0]
      else {
        let topPriority = { name: '' }

        let checkedData = []
        for(var i=0;i<prodCodes.length;i++) {
          for(var j=0;j<priorityData.length;j++) {
            if(prodCodes[i] === priorityData[j].p_code) {
              checkedData.push(priorityData[j])
              break
            }
          }
        }

        for(var a=0;a<prodCodes.length;a++) {
          for(var b=0;b<checkedData.length;b++) {
            let object = checkedData[b]
            let top = checkedData[0].priority

            if(prodCodes[a] === object.p_code && object.priority <= top) {
              topPriority.name = object.p_code
            }
          }
        }
        return topPriority.name
      }
    }
  } else {
    return 'N/A'
  }
}
function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    var b = '-'
    var position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}
function formatWeightString(breakdown_wgt, usual_wgt, unit) {
  return `${breakdown_wgt.toFixed(1)+unit}/${usual_wgt.toFixed(1)+unit}`
}
function formatPcsString(breakdown_pcs, usual_pcs) {
  return `${breakdown_pcs}/${usual_pcs}`
}
function getFirstFlightOutDate(arrayOfDates) {
  arrayOfDates?.sort((a, b) => b.flight_date < a.flight_date ? 1: -1)
  return arrayOfDates[0]
}
function diff_minutes_without_abs(dt2, dt1) {
  var diff = (dt1 - dt2) / 1000
  diff = diff/60
  if(diff < 0) return 0
  else return Math.round(diff)
}

class BreakdownControlUnknown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      station: new Cookies().get('station'),
      timezone: new Cookies().get('timezone'),
      ext: timezone.find((item) => item.value === new Cookies().get('station'))?.ext || 'EST',
      weight_unit: 'KG',
      loading: false,
      isFilterChecked: false,
      filterValue: '',
      tempULDGridData: [],
      prodPriorityData: [],
      workAreaData: [],
      ULDGridData: [],
      AWBGridDataForBreakdown: [],
      AWBGridDataBrokendown: [],
      recoveryActionsData: [],
      systemParameters: [],
      defaultSystemParameters: [],
      stationsData: [],
      uldRow: null,
      uldRowId: null,
      awbRow: null,
      awbRowId: null,
      type: null,
      search: '',
      sort: 'handling_time',
      flightHistoryVisible: false,
      isSearch: false,
      cookieInterval: null,
      servererror: false,
      isCompleted: false,
      hours: 6,
    }
    this._queue = []
    this._isBusy = false
  }
  //CDM
  componentDidMount() {
    //pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    this.setState({ cookieInterval: setInterval(this.checkInterval, 1000) })
    this.fetchSystemParameters()
  }
  componentWillUnmount() {
    this.setState({ cookieInterval: clearInterval(this.state.cookieInterval) })
  }
  checkInterval = () => {
    let newCookie = new Cookies().get('weight')
    if(newCookie !== this.state.weight_unit) {
      this.setState({ weight_unit: newCookie })
    }
  }
  //FETCH HANDLING TIMES
  fetchSystemParameters = async (event) => {
    if(!event) this.setState({ loading: true })
    await Http.get(`handling_times?station=${this.state.station}`, { headers: '' }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ systemParameters: data })
        } else {
          this.setState({ systemParameters: [] })
        }
        if(!event) this.fetchProductPriority()
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.processData(this.state.ULDGridData, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH PRODUCT PRIORITY
  fetchProductPriority = async (event) => {
    Http.get(`/products_priority?station=${this.state.station}`)
      .then((data) => {
        if (data && data.status === 200) {
          if(data && data.data && data.data.data[0] && data.data.data[0].prod_prior_list.length>0){
            this.setState({ prodPriorityData : data.data.data[0].prod_prior_list })
          } else {
            this.setState({ prodPriorityData : [] },()=>{notification.destroy()
              notification.error({
                message: 'Failed',
                description: 'No Data Found',
              })})
          }
          if(!event) this.fetchDefaultSystemParameters()
          else {
            if(this.state.isSearch) this.getSearchResults(this.state.search)
            else this.processData(this.state.ULDGridData, this.state.uldRow, this.state.awbRow)
            this.setState({ loading: false })
          }
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
  }
  //FETCH DEFAULT HANDLING TIMES
  fetchDefaultSystemParameters = async (event) => {
    await Http.get(`handling_times?default=true&station=${this.state.station}`, { headers: '' }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ defaultSystemParameters: data })
        } else {
          this.setState({ defaultSystemParameters: [] })
        }
        if(!event) this.fetchRecoveryActionsData()
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.processData(this.state.ULDGridData, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH RECOVERY ACTIONS DATA
  fetchRecoveryActionsData = async (event) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BREAKDOWN
    }
    await Http.get('recovery_actions', { headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ recoveryActionsData: data })
        } else {
          this.setState({ recoveryActionsData: [] })
        }
        if(!event) this.fetchWorkAreas()
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.processData(this.state.ULDGridData, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH RECOVERY ACTIONS DATA
  fetchWorkAreas = async (event) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BREAKDOWN
    }
    await Http.get('work_areas', { headers }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ workAreaData: data })
        } else {
          this.setState({ workAreaData: [] })
        }
        if(!event) this.fetchStations()
        else {
          if(this.state.isSearch) this.getSearchResults(this.state.search)
          else this.processData(this.state.ULDGridData, this.state.uldRow, this.state.awbRow)
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH STATIONS
  fetchStations = async (event) => {
    await Http.get('stations', { headers: '' }).then(resp => {
      if (resp && resp.status === 200) {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          let data = resp?.data?.data.map((item, index) => {
            return {
              ...item,
              key: index
            }
          })
          this.setState({ stationsData: data })
        } else {
          this.setState({ stationsData: [] })
        }
        if(!event) this.fetchULDData(null, false)
        else {
          this.processData()
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp.data.message,
        })
        this.setState({ loading: false })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //UPDATE DATA
  processData = (ulds, uldRow, awbRow) => {
    if(ulds.length > 0) {
      ulds.map((uld, index) => {
        ulds[index] = this.processIndividualULD(uld)
      })
      sort(ulds, 'id')
      sort(ulds, this.state.sort)
    }

    let filteredULD = null, filteredAWB = null
    if(uldRow) filteredULD = ulds?.find((item) => item.id === uldRow.id)
    if(awbRow) filteredAWB = filteredULD?.awbs_in?.find((item) => item.id === awbRow.id)
    this.setState({
      ULDGridData: [],
      AWBGridDataForBreakdown: filteredULD ? filteredULD?.for_breakdown : [],
      AWBGridDataBrokendown: filteredULD ? filteredULD?.brokendown_awb : [],
      uldRow: filteredULD ? filteredULD : null,
      uldRowId: filteredULD ? filteredULD.id : null,
      awbRow: filteredAWB ? filteredAWB : null,
      awbRowId: filteredAWB ? filteredAWB.id : null,
      loading: false,
    }, () => this.setState({ ULDGridData: ulds?.length > 0 ? ulds : [] }))
  }
  //FETCH ULD DATA
  fetchULDData = async (last_id, is_completed) => {
    let { hours } = this.state
    let url = ''
    let respdata = []

    if (last_id){
      url = `unknown_list?&last_id=${last_id}`
      if(is_completed) {
        url = url + `&status=${is_completed}&hours=${hours}`
      }
    } else{
      url = 'unknown_list'
      if(is_completed) {
        url = url + `&status=${is_completed}&hours=${hours}`
      }
    }
    let headers = { station: this.state.station }
    await Http.get(url, { headers }).then(async resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        respdata = cloneDeep(resp?.data?.data)
        if((is_completed !== this.state.isCompleted) || (this.state.isSearch)){ // If work area was change, don't update or call next page
          //do nothing
        } else {
          let { uldRow, awbRow, ULDGridData } = this.state   //Defined let here inorder to get latest state after response comes from API
          if(ULDGridData?.length > 0 && last_id) {
            ULDGridData = ULDGridData?.concat(respdata)
          } else {
            ULDGridData = respdata
          }

          //CHECK FOR DUPLICATE ENTRIES
          let newData = []
          ULDGridData?.map((item) => {
            var id = newData.findIndex(p => p.id == parseInt(item.id))
            if(id > -1) {
              //DO NOTHING
            } else {
              newData.push(item)
            }
          })
          ULDGridData = newData

          await this.processData(ULDGridData, uldRow, awbRow)
          this.fetchULDData(respdata[respdata.length-1].id, is_completed)  // get next page
        }
      } else {
        if(!last_id) {
          this.setState({
            ULDGridData: [],
            AWBGridDataBrokendown: [],
            AWBGridDataForBreakdown: [],
            awbRow: null,
            awbRowId: null,
            uldRow: null,
            uldRowId: null,
            loading: false,
          })
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: 'No Data Found',
          })
        } else {
          this.setState({ loading: false })
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //FETCH INDIVIDUAL ULD
  fetchIndividualULD = async () => {
    let latestULDGridData = this.state.ULDGridData
    let uld_index = latestULDGridData?.findIndex(p => p.id === this.state.uldRowId)

    if(uld_index > -1) {
      let headers = { station: this.state.station }
      await Http.get(`ulds?id=${parseInt(latestULDGridData[uld_index]?.id)}`, { headers }).then((resp) => {
        if (resp && resp?.data && resp?.data?.data?.length > 0 && resp?.data?.data[0]?.blk === false) {
          latestULDGridData = this.state.ULDGridData
          uld_index = latestULDGridData?.findIndex(p => p.id === this.state.uldRowId)
          if(resp?.data?.data[0]?.status?.toUpperCase() !== 'COMPLETED') {
            latestULDGridData[uld_index] = this.processIndividualULD(resp?.data?.data[0])
            this.setState({
              ULDGridData: [],
              AWBGridDataBrokendown: [],
              AWBGridDataForBreakdown: [],
            }, () => this.setState({
              ULDGridData: latestULDGridData,
              AWBGridDataBrokendown: latestULDGridData[uld_index].brokendown_awb,
              AWBGridDataForBreakdown: latestULDGridData[uld_index].for_breakdown,
            }))
            sort(latestULDGridData, 'id')
            sort(latestULDGridData, this.state.sort)
          } else {
            latestULDGridData?.splice(uld_index, 1)
            this.setState({
              ULDGridData: [],
              AWBGridDataBrokendown: [],
              AWBGridDataForBreakdown: [],
              awbRow: null,
              awbRowId: null,
              uldRow: null,
              uldRowId: null,
            }, () => this.setState({ ULDGridData: latestULDGridData }))
          }
        }
      })
    }
  }
  //UPDATE INDIVIDUAL ULD
  processIndividualULD = (uld) => {
    uld.type = this.checkULDType(uld)
    uld.weight_kg = 0
    uld.weight_lbs = 0
    uld.weight_kg_breakdown = 0
    uld.weight_lbs_breakdown = 0
    uld.breakdown_pcs = 0
    uld.pcs = 0
    uld.prod_code = getProdCode(uld?.awbs_in, this.state.prodPriorityData)
    let flights_out_dates = [], array_of_dates = []
    let awbs = uld?.awbs_in?.length > 0 ? uld.awbs_in : []
    awbs.map((awb, awb_index) => {
      awbs[awb_index] = this.processIndividualAWB(awb)

      //IF ULD DELIVER TO IS EMPTY UPDATE AWB STATUS COLOR/MESSAGE
      if(!uld?.work_areas_id) {
        awb.handling_time_color = 'red'
      }

      if(awb?.first_flights_out) flights_out_dates.push(awb.first_flights_out)
      array_of_dates.push(awb.handling_time)
      uld.weight_lbs+=awb.weight_lbs
      uld.weight_kg+=awb.weight_kg
      uld.weight_kg_breakdown+=awb.breakdown_wgt_kg
      uld.weight_lbs_breakdown+=awb.breakdown_wgt_lbs

      uld.breakdown_pcs+=awb.breakdown_pcs //ADD ULD PCS
      uld.pcs+=awb.pcs
    })
    sort(awbs, 'id')
    sort(awbs, 'handling_time')

    uld.brokendown_awb = uld?.awbs_in?.length > 0 ? uld.awbs_in.filter((awb) => awb?.status?.toUpperCase() === 'COMPLETED') : []
    uld.for_breakdown = uld?.awbs_in?.length > 0 ? uld.awbs_in.filter((awb) => awb?.status?.toUpperCase() !== 'COMPLETED') : []

    //TO SET EARLIEST ULD TIME ON FRONT END(FIRST PRIORITY ARE THE AWBS WHICH ARE NOT COMPLETED)
    if(uld?.for_breakdown?.length > 0) {
      let least_awb = getLeastAWB(uld?.for_breakdown)
      uld.show_handling_time_after_tta = least_awb.flag
      uld.handling_time_as_timestamp = least_awb.time
      uld.handling_time = least_awb.ht
      uld.handling_time_color = uld?.for_breakdown.filter((item) => item.handling_time_color !== 'green')?.length > 0 ? false : true
    } else if(uld?.brokendown_awb?.length > 0) {
      let least_awb = getLeastAWB(uld?.brokendown_awb)
      uld.show_handling_time_after_tta = least_awb.flag
      uld.handling_time_as_timestamp = least_awb.time
      uld.handling_time = least_awb.ht
      uld.handling_time_color = uld?.brokendown_awb.filter((item) => item.handling_time_color !== 'green')?.length > 0 ? false : true
    }

    //ADD DATA TO SORT
    uld.sort_infos = {}
    uld.sort_infos.ex_flight = uld?.flights_in?.flight_no
    uld.sort_infos.handling_time = uld.handling_time
    uld.sort_infos.type = uld?.uld_type
    uld.time_since_created = formatElapsedTime(uld?.info, uld?.status)
    uld.first_flights_out = flights_out_dates?.length > 0 ? getFirstFlightOutDate(flights_out_dates) : '' //TO CHECK

    uld.ulds_historys = this.processULDHistory(uld?.ulds_historys, uld?.deliver_to)
    uld.incorrect_delivery = uld.ulds_historys?.length > 0 ? uld.ulds_historys[0]?.is_incorrect_location : false
    return uld
  }
  //CHECK FOR ULD TYPE
  checkULDType = (uld) => {
    let type = ''
    if(uld?.blk) {
      type = 'BLK'
    } else {
      if(uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
        type = 'SLU'
      } else if(!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.uld_dispo?.toUpperCase() !== 'BREAKDOWN') {
        type = 'PTU'
      }
    }
    return type
  }
  //PROCESS ULD HISTORY
  processULDHistory = (history, deliver_to) => {
    let history_arr = []
    if(history && history?.length > 0) {
      history.map((item, index) => {
        if(item?.event_type === 'FSU') {
          //NEW LOGIC
          if(index === 0) {
            //CHECK IF LATEST MESSAGE WORK AREA MATCHES CURRENT INBOUND WORK AREA
            if(item?.status?.work_location) {
              if((item?.status?.work_area !== item?.status?.work_area_fps) && (deliver_to === item?.status?.work_area_fps) && (item?.status.status?.toUpperCase() === 'NOT STARTED')) {
                item.is_incorrect_location = true
              } else {
                item.is_incorrect_location = false
              }
            } else {
              item.is_incorrect_location = false
            }
          }

          if(!item?.status?.work_location) {
            item.message = `${item?.createdAt ? moment.utc(item.createdAt).tz(this.state.timezone).format(dateFormat) +' '+this.state.ext : ''} at ${item?.status?.work_area_fps}/${item?.status?.location} - Breakdown ${item?.status?.status}`
          } else if((item?.status?.work_location) && (item?.status?.work_area !== item?.status?.work_area_fps) && (item?.status.status?.toUpperCase() === 'NOT STARTED')){
            item.message = `${item?.createdAt ? moment.utc(item.createdAt).tz(this.state.timezone).format(dateFormat) +' '+this.state.ext : ''} at ${item?.status?.work_area_fps}/${item?.status?.location} - Incorrect Delivery Location`
          } else if((item?.status?.work_location) && (item?.status?.work_area === item?.status?.work_area_fps)) {
            item.message = `${item?.createdAt ? moment.utc(item.createdAt).tz(this.state.timezone).format(dateFormat) +' '+this.state.ext : ''} at ${item?.status?.work_area_fps}/${item?.status?.location} - Breakdown ${item?.status?.status}`
          }
          history_arr.push(item)
        }
      })
    }
    return history_arr
  }
  //UPDATE INDIVIDUAL AWB
  processIndividualAWB = (awb) => {
    awb.first_flights_out = awb?.flights_out ? awb.flights_out : awb?.segs_out ? awb.segs_out : null
    awb.check_breakdown = awb?.status?.toUpperCase() === 'COMPLETED' ? true : false
    handlingTimes(awb,
      this.state.systemParameters && this.state.systemParameters.filter((item) => item.station === this.state.station),
      this.state.defaultSystemParameters && this.state.defaultSystemParameters.filter((item) => item.station === this.state.station), this.state.stationsData, 'BREAKDOWN')

    if(awb.wgt.unit === 'LBS' || awb.wgt.unit === 'LBR') {
      awb.weight_lbs = awb.wgt.value
      awb.weight_kg = weightCalculator(awb.wgt.value, 'LBS')
    } else if(awb.wgt.unit === 'KGM') {
      awb.weight_kg = awb.wgt.value
      awb.weight_lbs = weightCalculator(awb.wgt.value, 'KGM')
    }

    //CALC PCS, WEIGHT FOR AWB
    awb.breakdown_pcs = 0
    awb.breakdown_wgt_kg = 0
    awb.breakdown_wgt_lbs = 0
    if(awb?.break_down) {
      if(awb?.break_down?.wgt?.length > 0) {
        awb?.break_down?.wgt?.map((item) => {
          if(item?.unit === 'LBS' || item?.unit === 'LBR') {
            awb.breakdown_wgt_lbs = awb.breakdown_wgt_lbs + item.value
            awb.breakdown_wgt_kg = awb.breakdown_wgt_kg + weightCalculator(item.value, 'LBS')
          } else if(item?.unit === 'KGM') {
            awb.breakdown_wgt_kg = awb.breakdown_wgt_kg + item.value
            awb.breakdown_wgt_lbs = awb.breakdown_wgt_lbs + weightCalculator(item.value, 'KGM')
          }
        })
      }
      awb.breakdown_pcs = awb?.break_down?.pcs

      if(awb.breakdown_pcs !== awb?.pcs && awb?.status?.toUpperCase() === 'COMPLETED') {
        if(awb.wgt.unit === 'LBS' || awb.wgt.unit === 'LBR') {
          awb.breakdown_wgt_lbs = awb.wgt.value
          awb.breakdown_wgt_kg = weightCalculator(awb.wgt.value, 'LBS')
        } else if(awb.wgt.unit === 'KGM') {
          awb.breakdown_wgt_kg = awb.wgt.value
          awb.breakdown_wgt_lbs = weightCalculator(awb.wgt.value, 'KGM')
        }
      }
    } else if(!awb?.break_down && awb?.status?.toUpperCase() === 'COMPLETED') {
      if(awb.wgt.unit === 'LBS' || awb.wgt.unit === 'LBR') {
        awb.breakdown_wgt_lbs+= awb.wgt.value
        awb.breakdown_wgt_kg+= weightCalculator(awb.wgt.value, 'LBS')
      } else if(awb.wgt.unit === 'KGM') {
        awb.breakdown_wgt_kg+= awb.wgt.value
        awb.breakdown_wgt_lbs+= weightCalculator(awb.wgt.value, 'KGM')
      }
    }
    if(awb?.status?.toUpperCase() === 'COMPLETED') {
      awb.breakdown_pcs = awb?.pcs
    }
    return awb
  }
  //AWB RECOVERY ACTION CHANGE
  handleRecoveryActionChange = async (record, value, rebook) => {
    if(value) {
      let formdata = {}
      formdata.recovery_actions_id = value
      formdata.is_gen_rebook = rebook
      formdata.awb_stat = record?.handling_time_color || record?.stat || null
      formdata.reason = record?.handling_time_color || record?.stat ? 'Handling Time Elapsed' : null
      formdata.connex_flt = record?.flights_out ? record.flights_out.flight_no : record?.segs_out ? record.segs_out.flight_no :  null
      formdata.connex_date = record?.flights_out ? record.flights_out.flight_date : record?.segs_out ? record.segs_out.flight_date : null
      formdata.rb_trigger = 'Breakdown-Unknown'
      let headers = {
        station: this.state.station,
        page: PAGE.BREAKDOWN,
      }

      await Http.put(`/awb/${parseInt(record.id)}`, formdata, { headers: headers })
        .then(async (data) => {
          if (data && data?.status === 200) {
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
            this.fetchIndividualULD()
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
          this.setState({ loading: false })
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({
            loading: false,
            servererror : true
          })
        })
    }
  }
  //SEND AWB FOR BREAKDOWN
  sendForBreakdown = async (ID, status) => {
    let formdata = new FormData()
    formdata.append('status', status)

    await Http.put(`/awb/${parseInt(ID)}`, formdata)
      .then(async (data) => {
        if (data && data?.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
          this.fetchIndividualULD()
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
  }
  //SET AWB DATA BASED ON ULD ROW CLICKED
  handleRowClick = async (record) => {
    this.setState({
      AWBGridDataBrokendown: [],
      AWBGridDataForBreakdown: []
    }, () => this.setState({
      AWBGridDataBrokendown: record.brokendown_awb,
      AWBGridDataForBreakdown: record.for_breakdown,
    }))
  }
  //SET ROW CLASSNAME ON ROW CLICK
  setRowClassName = (record, type) => {
    return record.id === this.state[type] ? 'selected pointer' : 'pointer'
  }
  //SEARCH LOGIC
  getSearchedData = async (url) => {
    if(url) {
      this.setState({
        loading: true,
        search: url,
      })
      let headers = { station: this.state.station }
      await Http.get(`unknown_list${url}`, { headers }).then(resp => {
        if (resp && resp?.data && resp?.data?.data?.length > 0) {
          this.setState({
            search: url,
            isSearch: true,
            isCompleted: false,
          }, () => this.processData(resp?.data?.data, null, null))
        } else if(this.state.search) {
          this.setState({
            loading: false,
            isSearch: true,
          }, () => {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: 'ULD/AWB not found',
            })
          })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
    } else {
      this.setState({
        isSearch: false,
        isCompleted: false,
        search: '',
        ULDGridData: [],
        AWBGridDataBrokendown: [],
        AWBGridDataForBreakdown: [],
        awbRow: null,
        awbRowId: null,
        uldRow: null,
        uldRowId: null,
        loading: true,
      }, () => this.fetchULDData(null, false))
    }
  }
  //SORTER
  sortGrid = (array, sort, isDate, initial) => {
    array && array.sort(function(a, b) {
      let objA = a.sort_infos
      let objB = b.sort_infos
      var keyA = isDate ? moment(objA[sort]) : objA[sort]
      var keyB = isDate ? moment(objB[sort]) : objB[sort]
      // Compare the fields
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })
    if(initial === false) {
      this.setState({
        ULDGridData: [],
        AWBGridDataForBreakdown: [],
        AWBGridDataBrokendown: [],
      }, () => this.setState({ ULDGridData: array }))
    }
  }
  //GET FILTERED DATA BY HANDLING TIMES
  getFilteredData = (value) => {
    let { ULDGridData } = this.state
    var startTime = moment(moment(), 'DD-MM-YYYY hh:mm:ss')

    if(value !== null) {
      this.setState({
        tempULDGridData: ULDGridData?.filter((item) => diff_minutes_without_abs(startTime.valueOf(), moment(item?.handling_time).valueOf()) <= value),
        filterValue: value
      })
    } else {
      this.setState({
        ULDGridData: ULDGridData,
        tempULDGridData: [],
        filterValue: ''
      })
    }
    this.setState({
      AWBGridDataForBreakdown: [],
      AWBGridDataBrokendown: [],
      uldRow: null,
      awbRow: null,
      uldRowId: null,
      awbRowId: null,
    })
  }
  //SHOW FLIGHT HISTORY
  showFlightHistory = (history) => {
    let { flightHistoryVisible } = this.state
    if(flightHistoryVisible && history?.length > 0) {
      return (
        <div>
          {history.map((item, index) => (
            <p
              key={index}>
              {item.message}
            </p>
          ))}
        </div>
      )
    }
  }

  render() {
    let { ULDGridData, AWBGridDataBrokendown, AWBGridDataForBreakdown , recoveryActionsData, loading, sort, tempULDGridData } = this.state

    const uldColumns = [
      {
        title: 'ULD #',
        dataIndex: 'uld_no',
        key: 'uld_no',
        className: 'space-nowrap',
        render: (_, record) => (
          <span>{record?.uld_no ? record?.type ? record.type +' - '+ record.uld_no : record.uld_no : 'N/A'}</span>
        )
      },
      {
        title: 'Flt In',
        dataIndex: 'flight_in',
        key: 'flight_in',
        render: (_, record) => (
          <span>{record?.flights_in ? formatFlightConnexNo(record.flights_in.flight_no, record.flights_in.flight_date) : 'N/A'}</span>
        )
      },
      {
        title: '1st Flt Out',
        dataIndex: 'flight_out',
        key: 'flight_out',
        render: (_, record) => (
          <span>{record?.first_flights_out ? formatFlightConnexNo(record.first_flights_out.flight_no, record.first_flights_out.flight_date) : 'N/A'}</span>
        )
      },
      {
        title: 'Type',
        dataIndex: 'uld_type',
        key: 'uld_type',
        render: (_, record) => (
          <span>{record?.uld_type ? record.uld_type : 'N/A'}</span>
        )
      },
      {
        title: 'PROD',
        dataIndex: 'product',
        key: 'product',
        render: (_, record) => (
          <span>{record?.prod_code ? record.prod_code : 'N/A'}</span>
        )
      },
      {
        title: 'Pcs',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{formatPcsString(record?.breakdown_pcs, record?.pcs)}</span>
        )
      },
      {
        title: 'Awbs',
        dataIndex: 'awbs',
        key: 'awbs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{record?.awbs_in ? record.awbs_in.length : 0}</span>
        )
      },
      {
        title: 'Wgt',
        dataIndex: 'weight',
        key: 'weight',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{this.state.weight_unit === 'KG' ?
            formatWeightString(record?.weight_kg_breakdown, record?.weight_kg, 'KG') :
            formatWeightString(record?.weight_lbs_breakdown, record?.weight_lbs, 'LBS')}
          </span>
        )
      },
      {
        title: 'HT',
        dataIndex: 'handling_times',
        key: 'handling_times',
        render: (_, record) => (
          <span className={record.handling_time_color === true ? 'countdown green' : 'countdown red'}>
            {record?.show_handling_time_after_tta === true ? <Countdown value={moment(record.handling_time)} format='HH:mm' /> : record.handling_time_as_timestamp}
          </span>
        )
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => (
          <>
            {
              <Popover
                onVisibleChange={(e) => this.setState({ flightHistoryVisible: e })}
                placement='rightTop'
                content={() => this.showFlightHistory(record?.ulds_historys)}
                title='Status history'
                trigger='click'
                overlayClassName='status-popover'>
                <span>{record?.status === null ? 'Not started' : record.status}</span>
              </Popover>
            }
          </>
        ),
      },
      {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: (_, record) => (
          <span className='countdowns'>{record?.time_since_created ? <CountUp expiryTimestamp={moment(record?.time_since_created, 'YYYY-MM-DD HH:mm:ss')} /> : 'N/A'}</span>
        )
      },
    ]
    const awbColumns = [
      {
        title: 'Awb #',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'space-nowrap min-width-100',
      },

      {
        title: 'Pcs',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
      },
      {
        title: 'ORG-DST',
        dataIndex: 'org_dst',
        key: 'org_dst',
        className: 'min-width-80',
      },
      {
        title: 'PROD',
        dataIndex: 'spl_code',
        key: 'spl_code',
        className: 'min-width-80',
      },
      {
        title: 'Connex',
        dataIndex: 'connex',
        key: 'Connex',
        className: 'min-width-120',
      },
      {
        title: 'HT',
        dataIndex: 'handling_time',
        key: 'handling_time',
        className: 'min-width-80',
      },
      {
        title: 'Recovery Action',
        dataIndex: 'recovery_action',
        key: 'recovery_action',
        className: 'min-width-150',
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        className: 'min-width-80',
        align: 'center',
      },
    ]
    const awbColumnsBreakdown = [
      {
        title: '',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'space-nowrap min-width-100',
        render: (_, record) => (
          <span>{record?.awb_no ? formatAWBNo(record.awb_no) : 'N/A'}</span>
        )
      },

      {
        title: '',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{formatPcsString(record?.breakdown_pcs, record?.pcs)}</span>
        )
      },
      {
        title: '',
        dataIndex: 'org_dst',
        key: 'org_dst',
        className: 'min-width-80',
        render: (_, record) => (
          <span>{record?.org ? record.org +'-'+ record.dest : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'spl_code',
        key: 'spl_code',
        className: 'min-width-80',
        render: (_, record) => (
          <span>{record?.awb?.prod_code ? record.awb.prod_code : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'connex',
        key: 'Connex',
        className: 'min-width-120',
        render: (_, record) => (
          <span>{record?.flights_out ? formatFlightConnexNo(record.flights_out.flight_no, record.flights_out.flight_date) : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'handling_time',
        key: 'handling_time',
        className: 'min-width-80',
        render: (_, record) => (
          <span className={record.handling_time_color === 'green' ? 'countdown green' : 'countdown red'}>
            {record?.show_handling_time_after_tta === true ? <Countdown value={moment(record.handling_time_after_tta)} format='HH:mm' /> : record.handling_time_as_timestamp}
          </span>
        )
      },
      {
        title: '',
        dataIndex: 'recovery_action',
        key: 'recovery_action',
        align: 'center',
        className: 'min-width-150',
        render: (_, record) =>
          <div>
            <Select
              placeholder='Select Recovery Action'
              bordered={false}
              style={{ width: 120 }}
              size='small'
              onChange={(e, { rebook }) => this.handleRecoveryActionChange(record, e, rebook)}
              value={record?.recovery_actions_id}
            >
              {recoveryActionsData && recoveryActionsData.map((item, index) => (
                <Option rebook={item.details.is_gen_rebook} key={index} value={item.id} title={item.details.action}>{item.details.action}</Option>
              ))}
            </Select>
          </div>
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        className: 'min-width-80',
        align: 'center',
        render: (_, record) =>
          <div className='action-column'>
            <Popconfirm
              placement='topLeft'
              title='Are you sure you want to confirm this AWB as Brokendown?'
              okText='Yes'
              cancelText='No'
              onConfirm={() => this.sendForBreakdown(record?.id, 'completed')}
            >
              <Tooltip title='Mark as brokendown' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                <Checkbox checked={record?.check_breakdown} />
              </Tooltip>
            </Popconfirm>
          </div>
      },
    ]
    const awbColumnsBrokendown = [
      {
        title: '',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'space-nowrap min-width-100',
        render: (_, record) => (
          <span>{record?.awb_no ? formatAWBNo(record.awb_no) : 'N/A'}</span>
        )
      },

      {
        title: '',
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'min-width-45',
        render: (_, record) => (
          <span>{formatPcsString(record?.breakdown_pcs, record?.pcs)}</span>
        )
      },
      {
        title: '',
        dataIndex: 'org_dst',
        key: 'org_dst',
        className: 'min-width-80',
        render: (_, record) => (
          <span>{record?.org ? record.org +'-'+ record.dest : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'spl_code',
        key: 'spl_code',
        className: 'min-width-80',
        render: (_, record) => (
          <span>{record?.awb?.prod_code ? record.awb.prod_code : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'connex',
        key: 'Connex',
        className: 'min-width-120',
        render: (_, record) => (
          <span>{record?.flights_out ? formatFlightConnexNo(record.flights_out.flight_no, record.flights_out.flight_date) : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'handling_time',
        key: 'handling_time',
        className: 'min-width-80',
        render: (_, record) => (
          <span className={record.handling_time_color === 'green' ? 'countdown green' : 'countdown red'}>
            {record?.show_handling_time_after_tta === true ? <Countdown value={moment(record.handling_time_after_tta)} format='HH:mm' /> : record.handling_time_as_timestamp}
          </span>
        )
      },
      {
        title: '',
        dataIndex: 'recovery_action',
        key: 'recovery_action',
        align: 'center',
        className: 'min-width-150',
        render: (_, record) =>
          <div>
            <Select
              placeholder='Select Recovery Action'
              bordered={false}
              style={{ width: 120 }}
              size='small'
              onChange={(e, { rebook }) => this.handleRecoveryActionChange(record, e, rebook)}
              value={record?.recovery_actions_id}
            >
              {recoveryActionsData && recoveryActionsData.map((item, index) => (
                <Option rebook={item.details.is_gen_rebook} key={index} value={item.id} title={item.details.action}>{item.details.action}</Option>
              ))}
            </Select>
          </div>
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        className: 'min-width-80',
        align: 'center',
        render: (_, record) =>
          <div className='action-column'>
            <Popconfirm
              placement='topLeft'
              title='Are you sure you want to confirm?'
              okText='Yes'
              cancelText='No'
              onConfirm={() => this.sendForBreakdown(record?.id, 'not started')}
            >
              <Tooltip title='' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                <Checkbox checked={record?.check_breakdown} disabled={true} />
              </Tooltip>
            </Popconfirm>
          </div>
      },
    ]

    return (
      <div>
        {loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <HeaderSearch searchResults={this.getSearchedData} page='BREAKDOWN' />
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 12,
                lg: 12
              }, {
                xs: 24,
                sm: 12,
                md: 12,
                lg: 12
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>
                    ULDs
                  </Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='p-12 breakdown-filter'>
                  <Row gutter={[{
                    xs: 12,
                    sm: 12,
                    md: 24,
                    lg: 24
                  }, {
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 12
                  },]} className=''>
                    <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={8} className='space-nowrap'>
                      <div>
                        <Checkbox
                          disabled={this.state.isSearch}
                          checked={this.state.isCompleted}
                          onChange={(e) => this.setState({
                            isCompleted: e.target.checked,
                            loading: true,
                          }, () => this.fetchULDData(null, e.target.checked))}>
                          Show Completed ULDs for last
                        </Checkbox>
                        <Select disabled={!this.state.isCompleted} value={this.state.hours} onChange={(e) => {
                          this.setState({
                            hours: e,
                            loading: true,
                          }, () => this.fetchULDData(null, this.state.isCompleted))
                        }} className='min-width-70 small-input pl-2 uld-time'>
                          <Option value='6'>6 Hrs</Option>
                          <Option value='12'>12 Hrs</Option>
                          <Option value='18'>18 Hrs</Option>
                          <Option value='24'>24 Hrs</Option>
                        </Select>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={8} className=''>
                      <span><Checkbox
                        onChange={(e) => {
                          this.setState({ isFilterChecked: e.target.checked })
                          this.getFilteredData(null)
                        }}
                        checked={this.state.isFilterChecked}
                      > Filter ULDs with <InputNumber
                          disabled={!this.state.isFilterChecked}
                          className='w-50px small-input'
                          onChange={(e) => this.getFilteredData(e)}
                          controls={false}
                          min={0}
                        />
                        <span className='pl-1'> Mins or less handling time</span></Checkbox></span>
                    </Col>
                    <Col xs={24} sm={14} md={14} lg={24} xl={12} xxl={8} className='text-center'>
                      <Form.Item label='Sort By' className='mb-0 sort-select'>
                        <Select
                          allowClear
                          size='small'
                          placeholder='Sort By'
                          className='br-input '
                          onChange={(e, { type }) => {
                            sort = e
                            this.setState({
                              sort,
                              uldRowId: null,
                              uldRow: null
                            })
                            this.sortGrid(ULDGridData, e, type, false)
                          }}
                          value={sort}
                        >
                          <Option value='handling_time' type=''>Handling Time (default)</Option>
                          <Option value='ex_flight' type=''>Ex Flight</Option>
                          <Option value='type' type=''>Type</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className='table-overflow'
                >
                  <div className='table-outer'>
                    <Table
                      className='custom-table w-100 breakdown-uld-table'
                      dataSource={tempULDGridData?.length > 0 ? tempULDGridData : ULDGridData}
                      columns={uldColumns}
                      pagination={false}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            this.setState({
                              uldRowId: record.id,
                              uldRow: record,
                              awbRowId: null,
                              awbRow: null,
                            })
                            this.handleRowClick(record)
                          }
                        }}
                      }
                      rowClassName={(record) => this.setRowClassName(record, 'uldRowId')}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 12,
                lg: 12
              }, {
                xs: 24,
                sm: 12,
                md: 12,
                lg: 12
              },]} className='with-table awb-withtable'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>AWBs</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='awb-sync'>
                  <ScrollSync>
                    <div className='scrollsync-outer breakdown-scrollsync-outer'>
                      <ScrollSyncPane>
                        <div className='scrollsynctable-1 breakdown-scrollsynctable-1 overflow-auto'>
                          <Table
                            className='custom-table w-100 breakdown-awb-table no-data-table'
                            dataSource={''}
                            columns={awbColumns}
                          />
                        </div>
                      </ScrollSyncPane>
                      <ScrollSyncPane>
                        <div className='scrollsynctable-2 breakdown-scrollsynctable-2 overflow-auto'>
                          <h4 className='mb-0 sticky-title'>For Breakdown</h4>
                          <Table
                            className='custom-table w-100 breakdown-awb-table no-header-table'
                            dataSource={AWBGridDataForBreakdown}
                            columns={awbColumnsBreakdown}
                            pagination={false}
                            onRow={(record) => {
                              return {
                                onClick: () => {
                                  this.setState({
                                    awbRow: record,
                                    awbRowId: record.id
                                  })
                                }
                              }}
                            }
                            rowClassName={(record) => this.setRowClassName(record, 'awbRowId')}
                          />
                        </div>
                      </ScrollSyncPane>
                      <Divider className='my-0 bd-divider' />
                      <ScrollSyncPane>
                        <div className='scrollsynctable-3 breakdown-scrollsynctable-3 overflow-auto'>
                          <h4 className='mb-0 sticky-title'>Brokendown AWB</h4>
                          <Table
                            className='custom-table w-100 breakdown-awb-table no-header-table'
                            dataSource={AWBGridDataBrokendown}
                            columns={awbColumnsBrokendown}
                            pagination={false}
                            onRow={(record) => {
                              return {
                                onClick: () => {
                                  this.setState({
                                    awbRow: record,
                                    awbRowId: record.id
                                  })
                                }
                              }}
                            }
                            rowClassName={(record) => this.setRowClassName(record, 'awbRowId')}
                          />
                        </div>
                      </ScrollSyncPane>
                    </div>
                  </ScrollSync>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default BreakdownControlUnknown
