
import React, { useState, useEffect } from 'react'
import ChangeLogTab from './tabs/changelog'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { changeLogTabs } from '../../constants/constants'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ChangeLog = ({ match }) => {

  const [tab, setTab] = useState(false)

  useEffect(() => {
    if (match?.params.tab) {
      if (changeLogTabs[match.params.tab]) {
        setTab(changeLogTabs[match.params.tab])
      } else {
        setTab(changeLogTabs.admin_logs)
      }
    } else {
      setTab(changeLogTabs.admin_logs)
    }
  }, [])


  const tabHandler = (t) => {
    setTab(t)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  return (
    <div>
      <Slider {...settings} className='custom-slider'>
        <div className={`tabs ${tab == changeLogTabs.admin_logs? 'active-tab' : ''}`} onClick={() => tabHandler(changeLogTabs.admin_logs)}>
          <span>{changeLogTabs.admin_logs}</span>
        </div>
        <div className={`tabs ${tab == changeLogTabs.system_logs? 'active-tab' : ''}`} onClick={() => tabHandler(changeLogTabs.system_logs)}>
          <span>{changeLogTabs.system_logs}</span>
        </div>
      </Slider>

      {tab == changeLogTabs.admin_logs &&
        <ChangeLogTab prop={changeLogTabs.admin_logs} />
      }
      {tab == changeLogTabs.system_logs &&
        <ChangeLogTab prop={changeLogTabs.system_logs} />
      }

    </div>
  )
}
ChangeLog.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default ChangeLog
