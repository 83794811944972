import React, { Component } from 'react'
import { timezone } from '../../constants/constants'
import { Card, Col, Form, InputNumber, List, Row, Select } from 'antd'
import Checkbox from 'antd/lib/checkbox/Checkbox'

class DashboardConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Buildup: {},
      Recovery: {},
      Breakdown: {},
      Haulout: {},
      Buildup_defaultFontSize: 1.3,
      Recovery_defaultFontSize: 1.7,
      Breakdown_defaultFontSize: 2.2,
      Haulout_defaultFontSize: 1.6,
    }
  }

  handleChange = (e, title) => {
    this.isEqualToDefault(e, title)
    this.setState(prev => ({
      ...prev,
      [title]: {
        ...prev[title],
        [prev[`${title}_selectedStation`]]: e,
      },
      [`${title}_currentFontSize`]: e,
    }),
    () => {
      localStorage.setItem(`${title}_${this.state[`${title}_selectedStation`]}`, this.state[title][this.state[`${title}_selectedStation`]])
    })
  }

  handleStationChange = (e, title) => {
    const key = `${title}_${e}`
    let keyValue =''
    let isDefault = ''

    if(localStorage.getItem(key)) {
      if(localStorage.getItem(key) === null || localStorage.getItem(key) === 'null'){
        keyValue = ''
      }
      else keyValue = localStorage.getItem(key)
    }
    if(localStorage.getItem(`${key}_default`)){
      if(localStorage.getItem(`${key}_default`) === 'true'){
        keyValue = this.state[`${title}_defaultFontSize`]
        isDefault = true
      }
      else if(localStorage.getItem(`${key}_default`) === 'false'){
        keyValue = localStorage.getItem(key)
        // keyValue = this.state[`${title}_currentFontSize`]
        isDefault = false
      }
    }

    this.setState(prev => ({
      ...prev,
      [title]: {
        ...prev[title],
        [e]: '',
      },
      [`${title}_selectedStation`]: e,
      [`${title}_currentFontSize`]: keyValue,
      [`${key}_default`]: isDefault,
    }),
    () => { })
  }

  isEqualToDefault = (e, title) => {
    if (e !== this.state[`${title}_defaultFontSize`]){
      const selectedStationKey = `${title}_selectedStation`
      this.setState(prev => ({
        ...prev,
        [`${title}_${this.state[selectedStationKey]}_default`] : false
      }),
      () => {
        localStorage.setItem(`${title}_${this.state[selectedStationKey]}_default`, false)
      }
      )
    }
  }

  handleDefaultFontSize = (e, title) => {
    const selectedStationKey = `${title}_selectedStation`
    const key = `${title}_${selectedStationKey}` 
    let keyValue =''
    if(localStorage.getItem(key)) {
      if(localStorage.getItem(key) === null || localStorage.getItem(key) === 'null'){
        keyValue = ''
      }
      else keyValue = localStorage.getItem(key)
    }
    else if(localStorage.getItem(`${key}_default`)){
      keyValue = this.state[`${title}_defaultFontSize`]
    }
    else if (!localStorage.getItem(key)){
      keyValue = this.state[`${title}_defaultFontSize`]
    }


    this.setState(prev => ({
      ...prev,
      [`${title}_${this.state[selectedStationKey]}_default`]: e,
      [`${title}_currentFontSize`]: keyValue,

    }),
    () => { 
      localStorage.setItem(`${title}_${this.state[selectedStationKey]}`, this.state[`${title}_currentFontSize`])
      localStorage.setItem(`${title}_${this.state[selectedStationKey]}_default`, this.state[`${title}_${this.state[selectedStationKey]}_default`])
    })
  }

  render() {

    const data = [
      {
        id: 1,
        title: 'Buildup',
        label: 'Buildup Dashboard',
      },
      {
        id: 2,
        title: 'Recovery',
        label: 'Recovery Dashboard',
      },
      {
        id: 3,
        title: 'Breakdown',
        label: 'Breakdown Dashboard',
      },
      {
        id: 4,
        title: 'Haulout',
        label: 'Haulout Dashboard',
      },
    ]

    return (
      <List
        grid={{
          gutter: 16,
          column: 2,
        }}
        dataSource={data}
        renderItem={(item) => {
          const title = item.title
          const selectedStation = this.state[`${title}_selectedStation`]
          return (
            <List.Item>
              <Card 
                title={item.title}
                extra={
                  <Checkbox onChange={(e) => this.handleDefaultFontSize(e.target.checked, item.title)} 
                    checked={this.state[`${title}_${selectedStation}_default`]}>
                        Set Default for - {selectedStation}
                  </Checkbox>
                }>
                <Row gutter={[24,24]}>
                  <Col span={8}>
                    <Form.Item label='Select Station' labelCol={{ span: 24 }}>
                      <Select
                        defaultValue='Select Station'
                        className='w-100'
                        name='station'
                        value={this.state[`${item.title}_selectedStation`]}
                        onChange={e => this.handleStationChange(e, item.title)}
                        options={timezone}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}> 
                    <Form.Item label='Default Font Size in vw' labelCol={{ span: 24 }}>
                      <InputNumber 
                        value={this.state[`${item.title}_defaultFontSize`]}
                        placeholder='Default Font Size'
                        name='fontSize'
                        disabled={true} 
                        className='mb-2'
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label='Enter Required Font Size in vw' labelCol={{ span: 24 }}>
                      <InputNumber 
                        value={this.state[`${item.title}_currentFontSize`]}
                        onChange={(e) => this.handleChange(e, item.title)}
                        placeholder='Enter Font Size'
                        disabled={!this.state[`${item.title}_selectedStation`]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </List.Item>
          )}
        }
      />
    )
  }
}

export default DashboardConfig