import React, { Component } from 'react'
import { Row, Col, Select, Form, Table, Button, InputNumber, Checkbox, Drawer, Space, /*DatePicker, TimePicker,*/ notification, Statistic, Spin, Input, Typography } from 'antd'
import { FilterOutlined, PrinterOutlined, LogoutOutlined, ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons'
import '../warehouse/index.css'
import './index.css'
import Http from '../../utils/http'
import Cookies from 'universal-cookie'
import { cloneDeep } from 'lodash'
import { pages as PAGE, constants, timezone } from '../../constants/constants'
import { weightCalculator, calc, cacheRetrieve } from '../../utils/common'
import moment from 'moment'
import { logout, pageHandler, getCookie, COOKIE } from '../../utils/cookie'

const country = ['USA', 'U.S. VIRGIN ISLANDS', 'PUERTO RICO']
const { Option } = Select
const { Text } = Typography
const { Countdown } = Statistic
const dateFormat = 'YYYY-MM-DD'

//FORMAT AWB NO
function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    var b = '-'
    var position = 3
    result = [awb.slice(0, position), b, awb.slice(position)].join('')
  }
  return result
}
function formatWeightString(usual_wgt, unit) {
  return `${(usual_wgt?.toFixed(1) || 0)+unit}`
}
function getTimeDifferenceInMinutes (difference) {
  if(difference) {
    return moment().diff(difference, 'minutes')
  } else return 0
}
class BuildUpDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cookieInterval: null,
      visible: false,
      weight_unit: 'KG',
      station: new Cookies().get('station'),
      user_id: new Cookies().get('id'),
      timezone: new Cookies().get('timezone'),
      role: new Cookies().get('role'),
      logintype: getCookie(COOKIE.LOGINTYPE),
      timezone_cty: timezone.find((item) => item.value === new Cookies().get('station'))?.zone || 'America/New_York',
      clock: '',
      intervalID: null,
      page: 1,
      count: 25,
      buildup_list: [],
      workAreaData: [],
      systemParameters: [],
      defaultSystemParameters: [],
      prodPriorityData: [],
      stationsData: [],
      fleetData: [],
      exceptionList: [],
      hrs_to_fpe: '',
      workArea: '',
      wbCheck: false,
      nbCheck: false,
      rfsCheck: false,
      prodPrioCheck: false,
      is_assigned: true,
      is_not_assigned: true,
      is_local: false,
      is_transfer: false,
      is_dom: '',
      location: '',
      locationList: [],
      prod_codes: [],
      prodCodeList: [],
      latestTimestamp: null,
      searchSelect: false,
      searchValue: '',
      wasSearched: false,

      tempHrs_to_fpe: '',
      tempWorkArea: '',
      tempWbCheck: false,
      tempNbCheck: false,
      tempRfsCheck: false,
      tempProdPrioCheck: false,
      tempIs_assigned: false,
      tempIs_not_assigned: false,
      tempIs_local: false,
      tempIs_transfer: false,
      tempIs_dom: '',
      temp_prod_codes: '',
      temp_location: '',

      only_booked: false,
      red_locations: [],
      amber_locations: [],
      amber_value: 0,
      fpe_value: 0,
      isListening: false,

      hiddenInterval: null,
      hiddenMoment: null,
      is_clearfilters: false,
      tableBodyFontSize: localStorage.getItem(`Buildup_${new Cookies().get('station')}`),
      defaultFontSize: 'clamp(14px,1.3vw,1.3vw)',
    }
    this._queue = []
    this._isBusy = false
  }
  //clock added to dashboard 
  clockTimerLogic = () => {
    this.setState({ clock: moment().tz(this.state.timezone_cty).format('DD MMM YYYY | hh:mm A') })
  }

  clockTimerInterval = () => {
    let clockInterval = setInterval(() => {
      this.clockTimerLogic()
    }, 60000)
    this.setState({ intervalID: clockInterval })
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search)
    const workArea = queryParams.get('workArea')
    const hrsFpe = queryParams.get('hrsFpe')
    const wbCheck = queryParams.get('wbCheck')
    const nbCheck = queryParams.get('nbCheck')
    const rfsCheck = queryParams.get('rfsCheck')
    const prodPrioCheck = queryParams.get('prodPrioCheck')
    const isAssigned = queryParams.get('isAssigned')
    const isNotAssigned = queryParams.get('isNotAssigned')
    const isLocal = queryParams.get('isLocal')
    const isTransfer = queryParams.get('isTransfer')
    const isDomIntl = queryParams.get('isDomIntl')

    this.setState({ clock: moment().tz(this.state.timezone_cty).format('DD MMM YYYY | hh:mm A') }, () => {
      this.clockTimerLogic()
      this.clockTimerInterval()
    })

    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    this.setState({ cookieInterval: setInterval(this.checkInterval, 1000) })

    setInterval(() => this.setState({ hiddenInterval: this.handlePageVisibility() }), 10000)
    // this.fetchWorkAreas()
    this.setState({
      loading: true,
      hrs_to_fpe: hrsFpe,
      workArea: workArea,
      wbCheck: wbCheck === 'true' ? true : false,
      nbCheck: nbCheck === 'true' ? true : false,
      rfsCheck: rfsCheck === 'true' ? true : false,
      prodPrioCheck: prodPrioCheck === 'true' ? true : false,
      is_assigned: isAssigned === 'true' ? true : false,
      is_not_assigned: isNotAssigned === 'true' ? true : false,
      is_local: isLocal === 'true' ? true : false,
      is_transfer: isTransfer === 'true' ? true : false,
      is_dom: isDomIntl,

      tempHrs_to_fpe: hrsFpe,
      tempWorkArea: workArea,
      tempWbCheck: wbCheck === 'true' ? true : false,
      tempNbCheck: nbCheck === 'true' ? true : false,
      tempRfsCheck: rfsCheck === 'true' ? true : false,
      tempProdPrioCheck: prodPrioCheck === 'true' ? true : false,
      tempIs_assigned: isAssigned === 'true' ? true : false,
      tempIs_not_assigned: isNotAssigned === 'true' ? true : false,
      tempIs_local: isLocal === 'true' ? true : false,
      tempIs_transfer: isTransfer === 'true' ? true : false,
      tempIs_dom: isDomIntl,
    }, () => this.fetchWorkAreas())

    const buildupdashboard_events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=bu&stn=${this.state.station}`)
    buildupdashboard_events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        if(!this.state.wasSearched){
          this.enqueue(myFakeAsyncJob)
        }
      }
    }
  }

  componentDidUpdate() {
    const fontSize = localStorage.getItem(`Buildup_${new Cookies().get('station')}`)
    if (this.state.tableBodyFontSize !== fontSize) { 
      this.setState({ tableBodyFontSize: fontSize })
    }
  }

  handlePageVisibility = () => {
    //HANDLE TAB VISIBILITY
    if(this.state.hiddenMoment === null && window.document.visibilityState === 'hidden') {
      this.setState({ hiddenMoment: moment().format('YYYY-MM-DD HH:mm:ss') })
    } else if(window.document.visibilityState === 'visible' && (this.state.hiddenMoment && getTimeDifferenceInMinutes(this.state.hiddenMoment) < 5)) {
      this.setState({ hiddenMoment: null })
    }
    if((this.state.hiddenMoment && (getTimeDifferenceInMinutes(this.state.hiddenMoment) > 5)) && this._queue?.length > 80) {
      this.refreshPage()
    }
  }

  refreshPage = () => {
    let url = 'buildup-dashboard?'
    if(this.state.workArea){
      url = url + `workArea=${this.state.workArea}`
    }
    if(this.state.hrs_to_fpe){
      url = url + `&hrsFpe=${this.state.hrs_to_fpe}`
    }
    if(this.state.wbCheck){
      url = url + `&wbCheck=${this.state.wbCheck}`
    }
    if(this.state.nbCheck){
      url = url + `&nbCheck=${this.state.nbCheck}`
    }
    if(this.state.rfsCheck){
      url = url + `&rfsCheck=${this.state.rfsCheck}`
    }
    if(this.state.is_assigned){
      url = url + `&isAssigned=${this.state.is_assigned}`
    }
    if(this.state.is_not_assigned){
      url = url + `&isNotAssigned=${this.state.is_not_assigned}`
    }
    if(this.state.is_local){
      url = url + `&isLocal=${this.state.is_local}`
    }
    if(this.state.is_transfer){
      url = url + `&isTransfer=${this.state.is_transfer}`
    }
    if(this.state.is_dom){
      url = url + `&isDomIntl=${this.state.is_dom}`
    }
    window.location.href = url
  }

  showDrawer = () => {
    this.setState({ visible: true, })
  }
  onClose = () => {
    this.setState({
      visible: false,
      hrs_to_fpe: this.state.tempHrs_to_fpe,
      workArea: this.state.tempWorkArea,
      wbCheck: this.state.tempWbCheck,
      nbCheck: this.state.tempNbCheck,
      rfsCheck: this.state.tempRfsCheck,
      prodPrioCheck: this.state.tempProdPrioCheck,
      is_assigned: this.state.tempIs_assigned,
      is_not_assigned: this.state.tempIs_not_assigned,
      is_local: this.state.tempIs_local,
      is_transfer: this.state.tempIs_transfer,
      is_dom: this.state.tempIs_dom,
      start: this.state.tempStart,
      end: this.state.tempEnd,
      stime: this.state.tempStime,
      etime: this.state.tempEtime,
    })
  }

  componentWillUnmount() {
    this.setState({
      cookieInterval: clearInterval(this.state.cookieInterval),
      hiddenInterval: clearInterval(this.state.hiddenInterval),
    })
  }

  /*************************************************************** */

  enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      this._queue.push({
        job,
        resolve,
        reject
      })
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    // if the array is empty shift() will return undefined
    if (next) {
      this.setState({ spinnerForEvents: true })
      this._isBusy = true
      next
        .job()
        .then((value) => {
          if(!this.state.wasSearched){
            this.eventList(value)
          }
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          this.setState({ spinnerForEvents: false })
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    } else {
      this.setState({ spinnerForEvents: false })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 600)
      })
  }
  eventList = (parsedData) => {
    if(parsedData?.TYPE === 'BOOKING_UPDATE' && parsedData?.Msg === 'Flight_out') {
      this.handleBookingUpdate(parsedData?.DETAILS, parsedData?.DETAILS?.work_area, parsedData?.DETAILS?.seg_work_area)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg?.includes('RCF')) {
      this.handleAWBUpdate(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg?.includes('FPS')) {
      this.handleAWBUpdate(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.msg === 'add') {
      this.handleULDUpdateOnADD(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg?.includes('MAN')) {
      this.handleAWBUpdateOnMAN(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.Msg?.includes('PRE')) {
      this.handleAWBUpdateOnPRE(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.msg === 'update') {
      this.handleAWBUpdateOnUpdate(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    }
    else if(parsedData?.TYPE === 'AWB_UPDATE' && parsedData?.msg === 'Merge') {
      this.handleAWBUpdateOnMerge(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    }
    else if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.msg === 'status') {
      this.handleAWBUpdateOnStatus(parsedData?.DETAILS, parsedData?.DETAILS?.build_to)
    }
    else if(parsedData?.TYPE === 'ULD_HAUL') {
      this.handleAWBUpdateOnHaul(parsedData?.DETAILS, parsedData?.DETAILS?.build_to || parsedData?.DETAILS?.dash_build_to)
    }
    else if(parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'ADD') {
      this.handleBookingAdd(parsedData?.DETAILS, parsedData?.DETAILS?.details?.build_to)
    }
    else if(parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'REMOVE') {
      this.removeFlight(parsedData?.DETAILS)
    }
    else if(parsedData?.TYPE === 'BOOKING' && parsedData?.msg === 'UPDATE') {
      this.removeFlight(parsedData?.DETAILS)
    }
    else if(parsedData?.TYPE === 'STATION_DETAILS') {
      this.fetchAmberValue()
    }
    else if(parsedData?.TYPE === 'COUNTDOWN') {
      this.changeFPEColor(parsedData?.DETAILS)
    }
    else if(parsedData?.TYPE === 'PRODUCT_PRIORITY') {
      this.fetchProductPriority('EVENT')
    } else if(parsedData?.TYPE === 'DEFAULT_HANDLING_TIMES') {
      this.fetchDefaultSystemParameters('EVENT')
    }
  }

  /*************************************************************** */
  // shouldProcessAWB = (AWB) => {
  //   let flag = false
  //   if(!this.state.only_booked) {
  //     flag = true
  //   } else if(this.state.only_booked &&
  //     (!AWB?.man || (AWB?.man && (AWB?.segs_out?.flight_no === AWB?.flights_out?.flight_no) &&
  //     (moment(AWB?.segs_out?.flight_date).format('YYYY-MM-DD') === moment(AWB?.flights_out?.flight_date).format('YYYY-MM-DD'))))) {
  //     flag = true
  //   }
  //   return flag
  // }
  //FETCH INDIVIDUAL FLIGHT BY AWB NO
  fetchIndividualFlightByAWBNo = async (ID) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }
    let obj = null
    await Http.get(`dash_build_up_list?id=${ID}&booked_only=${this.state.only_booked}`, { headers }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data?.goods?.length > 0) {
          obj = resp?.data?.data?.goods[0]
        }
      }
    })
    return obj
  }
  //FETCH INDIVIDUAL FLIGHT BY SEG ID
  fetchIndividualFlightBySegId = async (ID) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }
    let obj = null
    await Http.get(`awb_seg?id=${ID}`, { headers }).then(resp => {
      if (resp?.status === 200) {
        if (resp?.data?.data) {
          obj = resp?.data?.data
        }
      }
    })
    return obj
  }
  //BOOKING UPDATE
  handleBookingUpdate = async (DETAILS, WORK_AREA, SEG_WORK_AREA) => {
    if(SEG_WORK_AREA){
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === SEG_WORK_AREA?.toUpperCase()) {
        let awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.seg_out) && p?.is_seg )
        if(awb_index > -1) {
          data?.splice(awb_index, 1)
          this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
        }
      }
    }
    if(DETAILS?.goods_info_id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase()) {
        let awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id) && !p?.is_seg)
        if(awb_index > -1) {
          this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id))
                if(awb_index > -1) {
                  if(item?.rdy) {
                    data?.splice(awb_index, 1)
                  } else {
                    data[awb_index] = this.processIndividualRow(item)
                  }
                  this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                }
              } else {
                awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id))
                if(awb_index > -1) {
                  data?.splice(awb_index, 1)
                  this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                }
              }
            }
          })
        } else {
          this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                if((this.state.only_booked && !item?.segs_out)) {
                  //DO NOTHING
                } else {
                  if(!item?.rdy) {
                    data.push(this.processIndividualRow(item))
                    this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                  }
                }
              }
            }
          })
        }
      }
    }
  }
  //AWB UPDATE EVENT
  handleAWBUpdate = (DETAILS, WORK_AREA) => {
    if(DETAILS?.goods_info_id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase()) {
        let awb_index = data?.findIndex((p) => p.id === parseInt(DETAILS?.goods_info_id))
        if(awb_index > -1) {
          if(DETAILS?.status?.toUpperCase() === 'COMPLETED') {
            data?.splice(awb_index, 1)
          } else {
            data[awb_index].location = DETAILS?.location || ''
          }
          this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
        } else {
          this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                if((this.state.only_booked && !item?.segs_out)) {
                  //DO NOTHING
                } else {
                  if(!item?.rdy) {
                    data.push(this.processIndividualRow(item))
                    this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                  }
                }
              }
            }
          })
        }
      } else {
        let awb_index = data?.findIndex((p) => p.id === parseInt(DETAILS?.goods_info_id))
        if(awb_index > -1) {
          data?.splice(awb_index, 1)
          this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
        }
      }
    }
  }
  //ULD UPDATE ON FSU EVENT
  handleULDUpdateOnADD = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.goods_info_id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase()) {
        let awb_index = data?.findIndex((p) => p.id === parseInt(DETAILS?.goods_info_id))
        if(awb_index > -1) {
          this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id))
                if(awb_index > -1) {
                  if(item?.rdy) {
                    data?.splice(awb_index, 1)
                  } else {
                    data[awb_index] = this.processIndividualRow(item)
                  }
                  this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                }
              } else {
                awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id))
                if(awb_index > -1) {
                  data?.splice(awb_index, 1)
                  this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                }
              }
            }
          })
        } else {
          this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                if((this.state.only_booked && !item?.segs_out)) {
                  //DO NOTHING
                } else {
                  if(!item?.rdy) {
                    data.push(this.processIndividualRow(item))
                    this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                  }
                }
              }
            }
          })
        }
      }
    }
  }
  //AWB UPDATE ON MAN EVENT
  handleAWBUpdateOnMAN = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.goods_info_id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase()) {
        if(DETAILS?.old_flight_out !== DETAILS?.flight_out && DETAILS?.status?.toUpperCase() !== 'COMPLETED') {
          let awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id))
          if(awb_index > -1) {
            //IF BOOKED ONLY THEN REMOVE
            if(this.state.only_booked) {
              //DO NOTHING
            } else {
              //ADD OR UPDATE
              this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
                if(!this.state.wasSearched){
                  if(item) {
                    awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id))
                    if(awb_index > -1) {
                      if(item?.rdy) {
                        data?.splice(awb_index, 1)
                      } else {
                        data[awb_index] = this.processIndividualRow(item)
                      }
                      this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                    }
                  } else {
                    awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id))
                    if(awb_index > -1) {
                      data?.splice(awb_index, 1)
                      this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                    }
                  }
                }
              })
            }
          } else {
            this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
              if(!this.state.wasSearched){
                if(item) {
                  if((this.state.only_booked && !item?.segs_out)) {
                    //DO NOTHING
                  } else {
                    if(!item?.rdy) {
                      data.push(this.processIndividualRow(item))
                      this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                    }
                  }
                }
              }
            })
          }
        } else {
          let awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id))
          if(awb_index > -1) {
            this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
              if(!this.state.wasSearched){
                if(item) {
                  awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.goods_info_id))
                  if(awb_index > -1) {
                    if(item?.rdy) {
                      data?.splice(awb_index, 1)
                    } else {
                      data[awb_index] = this.processIndividualRow(item)
                    }
                    this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                  }
                }
              }
            })
          }
        }
      }
    }
  }
  //AWB UPDATE ON PRE EVENT
  handleAWBUpdateOnPRE = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.goods_info_id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase()) {
        let awb_index = data?.findIndex((p) => p.id === parseInt(DETAILS?.goods_info_id))
        if(awb_index > -1 && DETAILS?.rdy) {
          // data[awb_index].rdy = DETAILS?.rdy
          // data[awb_index].ulds_in.rdy = DETAILS?.rdy
          data?.splice(awb_index, 1)
          this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
        }
      }
    }
  }
  //AWB UPDATE ON UPDATE EVENT
  handleAWBUpdateOnUpdate = (DETAILS, WORK_AREA) => {
    if(DETAILS?.goods_info_id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase()) {
        let awb_index = data?.findIndex((p) => p.id === parseInt(DETAILS?.goods_info_id))
        if(awb_index > -1) {
          if(DETAILS?.status?.toUpperCase() === 'COMPLETED') {
            data?.splice(awb_index, 1)
            this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
          } else {
            this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
              if(!this.state.wasSearched){
                awb_index = data?.findIndex((p) => p.id === parseInt(DETAILS?.goods_info_id))
                if(item) {
                  if((this.state.only_booked && !item?.segs_out)) {
                    //DO NOTHING
                  } else {
                    if(!item?.rdy) {
                      data[awb_index] = this.processIndividualRow(item)
                      this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                    }
                  }
                }
              }
            })
          }
        }
      }
    }
  }
  //AWB UPDATE ON Merge EVENT
  handleAWBUpdateOnMerge = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.goods_info_id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase()) {
        let awb_index = data?.findIndex((p) => p.id === parseInt(DETAILS?.goods_info_id))
        if(awb_index > -1) {
          if(DETAILS?.status?.toUpperCase() === 'COMPLETED') {
            data?.splice(awb_index, 1)
            this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
          } else {
            this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
              if(!this.state.wasSearched){
                if(item) {
                  awb_index = data?.findIndex((p) => p.id === parseInt(DETAILS?.goods_info_id))
                  //UPDATE AWB
                  if(awb_index > -1) {
                    if(item?.rdy || item?.status?.toUpperCase() === 'COMPLETED') {
                      data?.splice(awb_index, 1)
                    } else {
                      data[awb_index] = this.processIndividualRow(item)
                    }
                    this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                  }
                }
              }
            })
          }
        } else {
          this.fetchIndividualFlightByAWBNo(parseInt(DETAILS?.goods_info_id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                if((this.state.only_booked && !item?.segs_out)) {
                  //DO NOTHING
                } else {
                  if(!item?.rdy) {
                    data.push(this.processIndividualRow(item))
                    this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                  }
                }
              }
            }
          })
        }
      }
    }
  }
  //AWB UPDATE ON STATUS EVENT
  handleAWBUpdateOnStatus = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.uld_id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase()) {
        if(DETAILS?.status?.toUpperCase() === 'COMPLETED') {
          let filtered = data?.filter((item) => item?.uld_in === parseInt(DETAILS?.uld_id))
          if(filtered?.length > 0) {
            filtered.map((item) => {
              let index = data?.findIndex((p) => p.uld_in === item.uld_in)
              if(index > -1) {
                data?.splice(index, 1)
              }
            })
            this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
          }
        } else {
          //ADD OR UPDATE
          let headers = {
            station: this.state.station,
            page: PAGE.BUILDUP
          }
          await Http.get(`dash_build_up_list?u_id=${parseInt(DETAILS?.uld_id)}&booked_only=${this.state.only_booked}`, { headers }).then(resp => {
            if (resp?.status === 200) {
              if (resp?.data?.data?.length > 0) {
                data = cloneDeep(this.state.buildup_list)

                resp.data.data.map((awb) => {
                  let index = data?.findIndex((p) => p.id === awb?.id)

                  if(index > -1) {
                    // data?.splice(index, 1)
                    data[index] = this.processIndividualRow(awb)
                  } else {
                    data.push(this.processIndividualRow(awb))
                  }
                  this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                })
              }
            }
          })
        }
      } else {
        let filtered = data?.filter((p) => p?.uld_in === parseInt(DETAILS?.uld_id))
        if(filtered?.length > 0) {
          filtered?.map((item) => {
            let index = data?.findIndex((p) => p.id === item?.id)
            if(index > -1) {
              data?.splice(index, 1)
            }
          })
          this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
        }
      }
    }
  }
  //AWB UPDATE ON HAULOUT EVENT
  handleAWBUpdateOnHaul = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.haul && DETAILS?.uld_id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase()) {
        this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data?.filter((item) => item?.uld_in !== parseInt(DETAILS?.uld_id)) || [] }))
      }
    }
  }
  //ADD AWB ON BOOKING ADD
  handleBookingAdd = async (DETAILS, WORK_AREA) => {
    if(DETAILS?.details?.id) {
      let data = cloneDeep(this.state.buildup_list)
      if(this.state.workArea?.toUpperCase() === WORK_AREA?.toUpperCase() && DETAILS?.details?.org === this.state.station) {
        let awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.details?.id) && p?.is_seg)
        if(awb_index > -1) {
          this.fetchIndividualFlightBySegId(parseInt(DETAILS?.details?.id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.details?.id) && p?.is_seg)
                if(awb_index > -1) {
                  if(item?.rdy) {
                    data?.splice(awb_index, 1)
                  } else {
                    if(!item?.rdy) {
                      item.is_seg = true
                      item.flights_out = item.flight
                      item.wgt = {
                        value: parseFloat(item?.space?.wgt)|| 0,
                        unit: item?.awb?.wgt?.total?.unit || 'L'
                      }
                      data[awb_index] = this.processIndividualRow(item)
                    }
                  }
                  this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                }
              } else {
                awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.details?.id)&& p?.is_seg)
                if(awb_index > -1) {
                  data?.splice(awb_index, 1)
                  this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                }
              }
            }
          })
        } else {
          this.fetchIndividualFlightBySegId(parseInt(DETAILS?.details?.id)).then((item) => {
            if(!this.state.wasSearched){
              if(item) {
                if((this.state.only_booked && !item?.segs_out)) {
                  //DO NOTHING
                } else {
                  if(!item?.rdy) {
                    item.is_seg = true
                    item.flights_out = item.flight
                    item.wgt = {
                      value: parseFloat(item?.space?.wgt)|| 0,
                      unit: item?.awb?.wgt?.total?.unit || 'L'
                    }
                    data.push(this.processIndividualRow(item))
                    this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
                  }
                }
              }
            }
          })
        }
      }
    }
  }
  //REMOVE AWB ON BOOKING UPDATE, REMOVE
  removeFlight = async (DETAILS) => {
    if(DETAILS?.details?.id && DETAILS?.details?.org === this.state.station) {
      let data = cloneDeep(this.state.buildup_list)
      let awb_index = data?.findIndex((p) => p?.id === parseInt(DETAILS?.details?.id) && p.is_seg)
      if(awb_index > -1) {
        //splice
        data?.splice(awb_index, 1)
        this.setState({ buildup_list: [], }, () => this.setState({ buildup_list: data }))
      }
    }
  }

  /*************************************************************** */

  checkInterval = () => {
    let newCookie = new Cookies().get('weight')
    if(newCookie !== this.state.weight_unit) {
      this.setState({ weight_unit: newCookie })
    }
  }
  //FETCH USER FILTERS
  fetchFilters = async (flag) => {
    await Http.get(`/page_filter/${this.state.user_id}`).then(async (data) => {
      if (data && data?.status === 200) {
        let res_data = data?.data?.data?.page_filters?.buildupdash_filter && data?.data?.data?.page_filters?.buildupdash_filter[this.state.station]
        if(res_data) {
          this.setState({
            buildupFilter: data?.data?.data?.page_filters?.buildupdash_filter,
            workArea: res_data?.type || this.state.workArea,
            wbCheck: res_data?.wb || this.state.wbCheck,
            nbCheck: res_data?.nb || this.state.nbCheck,
            rfsCheck: res_data?.rfs || this.state.rfsCheck,
            is_assigned: res_data?.assigned !== null ? res_data?.assigned : this.state.is_assigned,
            is_not_assigned: res_data?.nassigned !== null ? res_data?.nassigned : this.state.is_not_assigned,
            is_local: res_data?.local || this.state.is_local,
            is_transfer: res_data?.transfer || this.state.is_transfer,
            is_dom: res_data?.dom_intl || this.state.is_dom,
            location: res_data?.location || this.state.location,
            prod_codes: res_data?.prod_codes || this.state.prod_codes,
            hrs_to_fpe: res_data?.hrs_to_fpe || this.state.hrs_to_fpe,
            prodPrioCheck: res_data?.prodPrioCheck || this.state.prodPrioCheck,

            tempWorkArea: res_data?.type || this.state.workArea,
            tempWbCheck: res_data?.wb || this.state.wbCheck,
            tempNbCheck: res_data?.nb || this.state.nbCheck,
            tempRfsCheck: res_data?.rfs || this.state.rfsCheck,
            tempIs_assigned: res_data?.assigned !== null ? res_data?.assigned :  this.state.is_assigned,
            tempIs_not_assigned: res_data?.nassigned !== null ? res_data?.nassigned :  this.state.is_not_assigned,
            tempIs_local: res_data?.local || this.state.is_local,
            tempIs_transfer: res_data?.transfer || this.state.is_transfer,
            tempIs_dom: res_data?.dom_intl || this.state.is_dom,
            temp_location: res_data?.location || this.state.location,
            temp_prod_codes: res_data?.prod_codes || this.state.prod_codes,
            tempHrs_to_fpe: res_data?.hrs_to_fpe || this.state.hrs_to_fpe,
            tempProdPrioCheck: res_data?.prodPrioCheck || this.state.prodPrioCheck,
          })
        } else {
          this.setState({
            buildupFilter: data?.data?.data?.page_filters?.buildupdash_filter || {},
            workArea: this.state.tempWorkArea,
          })
        }
        if(flag) {
          this.fetchDashBuildUp(null, false)
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: data?.data?.message,
        })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ servererror : true })
    })
  }
  //FETCH WORK AREAS
  fetchWorkAreas = async () => {
    let callback = this.fetchWorkAreasFn
    let pageURL =  'work_areas'
    let headers = {
      page: PAGE.BUILDUP,
      station: this.state.station,
      module: 'buildup'
    }
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchWorkAreasFn = async(resp, event) => {
    if(!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        this.setState({
          workAreaData: respData?.data,
          workArea: respData?.data[0]?.area_name,
          tempWorkArea: this.state.workArea ? this.state.workArea : respData?.data[0]?.area_name,
          // tempSelWorkArea: this.state.workArea ? this.state.workArea : data[0]?.area_name,
          // tempWorkAreaType: this.state.tempSelWorkAreaType ? this.state.tempSelWorkAreaType : '',
          // tempSelWorkAreaType: this.state.tempSelWorkAreaType ? this.state.tempSelWorkAreaType : '',
        }
        ,()=>this.fetchSystemParameters()
        )
      } else {
        this.setState({ workAreaData: [] })
        this.setState({ loading: false })
      }
    }
  }
  //CALCULATE FLIGHT FPE
  getFPE = (flight) => {
    let fpe = 0
    let dest_type = ''

    let details = this.state.stationsData?.find((stn) => stn?.code === flight?.dest_stn) || ''
    if(details) {
      if(country.includes(details?.country?.toUpperCase())) {
        dest_type = 'EXPORT-D'
      } else {
        dest_type = 'EXPORT-I'
      }
      let ht = this.state.defaultSystemParameters?.find((item) => item?.details?.process?.toUpperCase() === dest_type)
      if(ht && ht.dest_list && ht.dest_list.length >0){
        let index = ht.dest_list.findIndex((p) => p.dest === flight?.dest?.arriv)
        if(index > -1){
          fpe = ht.dest_list[index].fpe
        } else {
          fpe = this.state.defaultSystemParameters?.find((item) => item?.details?.process?.toUpperCase() === dest_type)?.details?.fpe
        }
      } else {
        fpe = this.state.defaultSystemParameters?.find((item) => item?.details?.process?.toUpperCase() === dest_type)?.details?.fpe
      }
    }
    return fpe
  }
  //FETCH HANDLING TIMES
  fetchSystemParameters = async (event) => {
    let callback = this.fetchSystemParametersFn
    let pageURL =  `handling_times?station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers, event )
  }
  fetchSystemParametersFn = async(resp, event) => {
    if(!event) this.setState({ loading: true })
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ systemParameters: data })
      } else {
        this.setState({ systemParameters: [] })
      }
      if(!event) this.fetchDefaultSystemParameters()
      else {
        this.processData()
        this.setState({ loading: false })
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH DEFAULT HANDLING TIMES
  fetchDefaultSystemParameters = async (event) => {
    let callback = this.fetchDefaultSystemParametersFn
    let pageURL =  `handling_times?default=true&station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers, event )
  }
  fetchDefaultSystemParametersFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ defaultSystemParameters: data })
      } else {
        this.setState({ defaultSystemParameters: [] })
      }
      if(!event) this.fetchStations()
      else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData.message,
      })
      this.setState({ loading: false })
    }
  }
  //FETCH STATIONS
  fetchStations = async () => {
    let callback = this.fetchStationsFn
    let pageURL =  'stations'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchStationsFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if (respData && respData?.data?.length > 0) {
        let data = respData?.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        })
        this.setState({ stationsData: data })
      } else {
        this.setState({ stationsData: [] })
      }
      if(!event) this.fetchFleet()
      else {
        this.processData()
      }
    } else {
      this.setState({ loading: false })
    }
  }
  //FETCH FLEET
  fetchFleet = async () => {
    let callback = this.fetchFleetFn
    let pageURL =  'flights'
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchFleetFn = async(resp, event) => {
    const respData = await resp.json()
    if (respData && respData?.data?.length > 0) {
      this.setState({ fleetData: respData?.data })
    }
    if(!event) this.fetchProductPriority()
    else {
      this.processData()
    }
  }
  //FETCH PRODUCT PRIORITY
  fetchProductPriority = async () => {
    let callback = this.fetchProductPriorityFn
    let pageURL =  `products_priority?station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchProductPriorityFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if(respData && respData.data[0] && respData.data[0].prod_prior_list.length>0){
        this.setState({ prodPriorityData : respData.data[0].prod_prior_list })
      } else {
        this.setState({ prodPriorityData : [] })
      }
      if(!event) this.fetchAmberValue()
      else {
        this.processData()
      }
    } else {
      this.setState({ loading: false })
    }
  }
  //FETCH HANDLING DATA
  fetchAmberValue = async () => {
    let callback = this.fetchAmberValueFn
    let pageURL =  `station_details?station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchAmberValueFn = async(resp, event) => {
    let old_only_booked = cloneDeep(this.state.only_booked)
    const respData = await resp.json()
    if(respData?.data?.length>0) {
      let result = respData.data[0]
      if(old_only_booked !== result?.details?.onlybooked) {
        this.setState({ buildup_list: [] })
      }
      this.setState({
        only_booked: result?.details?.onlybooked, //boolean
        red_locations: result?.details?.red_locations?.length > 0 ? result?.details?.red_locations?.split(',') : [], //comma seperated string
        amber_locations: result?.details?.amber_locations?.length > 0 ? result?.details?.amber_locations?.split(',') : [], //comma seperated string
        amber_value: result?.indicator_time || 0, //build up amber value
        fpe_value: result?.indicator_time2 || 0, //build up fpe value
      })
    }
    if(!event) this.fetchFilters(true)
    else {
      this.processData()
    }
  }
  //PROCESS DATA ON EVENT
  processData = () => {
    let awb_array = []
    this.state.buildup_list?.map((item) => {
      let obj = this.processIndividualRow(item)
      if(obj) {
        awb_array.push(obj)
      }
    })
    this.setState({
      buildup_list: [],
      loading: false,
    }, () => this.setState({ buildup_list: awb_array }))
  }
  //RESET
  resetPage = async () => {
    this.setState({
      visible: false,
      page: 1,
      buildup_list: []
    })
  }
  //FETCH BUILD UP DATA
  fetchDashBuildUp = async (event, is_search = false) => {
    let temp_timestamp = moment().valueOf()
    this.setState({ latestTimestamp: temp_timestamp })
    // let start_date = moment.tz((isMoment(this.state.tempStart) ? this.state.tempStart.format(dateFormat) : this.state.tempStart) +' '+ this.state.tempStime.format(timeFormat), this.state.timezone).utc()
    // let end_date = moment.tz((isMoment(this.state.tempEnd) ? this.state.tempEnd.format(dateFormat) : this.state.tempEnd) +' '+ this.state.tempEtime.format(timeFormat), this.state.timezone).utc()
    // let start = this.state.tempStart ? start_date.format(dateFormat) : ''
    // let end = this.state.tempEnd ? end_date.format(dateFormat) : ''
    // let stime = this.state.tempStime ? start_date.format(timeFormat) : ''
    // let etime = this.state.tempEtime ? end_date.format(timeFormat) : ''
    // let page = this.state.page
    // let count = this.state.count

    let headers = {
      page: PAGE.BUILDUP,
      station: this.state.station,
    }
    if(!event) this.setState({ loading: true })
    let url = `dash_build_up_list?booked_only=${this.state.only_booked}&`
    if(this.state.workArea){
      url = url+'work_area='+this.state.workArea+'&'
    }
    // url = url + `start=${start}&end=${end}&stime=${stime}&etime=${etime}&page=${page}&count=${count}`
    // url = url + `page=${page}&count=${count}`
    if(this.state.wbCheck){
      url = url + `&is_wb=${this.state.wbCheck}`
    }
    if(this.state.nbCheck){
      url = url + `&is_nb=${this.state.nbCheck}`
    }
    if(this.state.rfsCheck){
      url = url + `&is_trk=${this.state.rfsCheck}`
    }
    if(this.state.is_assigned){
      url = url + `&is_assigned=${this.state.is_assigned}`
    }
    if(this.state.is_not_assigned){
      url = url + `&is_not_assigned=${this.state.is_not_assigned}`
    }
    if(this.state.is_local){
      url = url + `&is_local=${this.state.is_local}`
    }
    if(this.state.is_transfer){
      url = url + `&is_transfer=${this.state.is_transfer}`
    }
    if(is_search ){
      this.setState({ wasSearched: true })
      url= `dash_build_up_list?booked_only=${this.state.only_booked}&search=${this.state.searchValue}`
    } else {
      this.setState({ wasSearched: false })
    }

    await Http.get(url, { headers: headers }).then(resp => {
      if(this.state.latestTimestamp === temp_timestamp) {
        if (resp && resp.status === 200) {
          let respdata = []
          if(resp && resp?.data && resp?.data?.data?.goods && resp?.data?.data?.goods.length>0 && resp?.data?.data?.segments.length>0){
            resp.data.data.segments.map((_,index)=>{
              resp.data.data.segments[index].is_seg = true
              resp.data.data.segments[index].flights_out = resp.data.data.segments[index].flight
              resp.data.data.segments[index].wgt = {
                value: parseFloat(resp.data.data.segments[index]?.space?.wgt) || 0,
                unit: resp.data.data.segments[index]?.awb?.wgt?.total?.unit || 'L'
              }
            })
            respdata = resp?.data?.data?.goods.concat(resp?.data?.data?.segments)
          } else if(resp && resp?.data && resp?.data?.data?.goods.length>0){
            respdata = cloneDeep(resp.data.data.goods)
          } else if(resp && resp?.data && resp?.data?.data?.segments.length>0) {
            resp.data.data.segments.map((_,index)=>{
              resp.data.data.segments[index].is_seg = true
              resp.data.data.segments[index].flights_out = resp.data.data.segments[index].flight
              resp.data.data.segments[index].wgt = {
                value: parseFloat(resp.data.data.segments[index]?.space?.wgt)|| 0,
                unit: resp.data.data.segments[index]?.awb?.wgt?.total?.unit || 'L'
              }
            })
            respdata = cloneDeep(resp.data.data.segments)
          }
          if (respdata && respdata.length > 0) {
            let buildup_list= cloneDeep(this.state.buildup_list)
            respdata.map((item,i) => {
              if((this.state.only_booked && item?.segs_out && !item?.ulds_in?.rdy) || !item?.ulds_in?.rdy) {
                let id = buildup_list?.findIndex(p => p.id == parseInt(item.id))
                if(id > -1) {
                  //DO NOTHING
                } else {
                  respdata[i] = this.processIndividualRow(item)
                  buildup_list.push(respdata[i])
                }
              }
            })
            this.setState({
              buildup_list: buildup_list,
              loading: false,
              // page: page+1
            }, () => {
              window.scrollTo({
                top: 500,
                behavior: 'smooth',
              })
            })
          } else {
            this.setState({ loading: false })
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: resp?.data?.message,
            })
          }
        } else if(resp?.data?.status === 'error') {
          this.fetchDashBuildUp(null, false)
        }
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({
        loading: false,
        servererror : true
      })
    })
  }
  //PROCESS INDIVIDUAL FLIGHT
  processIndividualRow = (flight) => {
    //SET DONE FLAG
    if(flight?.done) {
      //DO NOTHING
    } else {
      flight.done = false
    }

    flight.uld_type = this.checkULDType(flight?.ulds_in)
    //PUSH PRODUCT CODE
    if(flight?.awb?.prod_code) {
      this.pushProdCode(flight?.awb?.prod_code)
    }
    if(flight?.location && flight?.inbound) {
      this.pushLocation(flight.location)
    } else {
      this.pushLocation(flight?.flights_in?.flight_no)
    }
    //FOR SORTING
    flight.container = flight?.ulds_in?.uld_no
    flight.flight_no = flight?.flights_out?.flight_no
    //AWB STAGE
    let page = ''
    if(!flight?.inbound || (flight?.inbound && !flight?.location)) page = 'INBOUND'
    else page = 'BUILDUP'

    //GET FLIGHT DOM/INTL
    flight.dom_intl = 'INTL'
    let details = this.state.stationsData?.find((stn) => stn?.code === flight?.flights_out?.dest?.arriv) || ''
    if(details) {
      if(country.includes(details?.country?.toUpperCase())) {
        flight.dom_intl = 'DOM'
      }
    }
    //CALCULATE DEPARTURE TIME
    flight.fpe = ''
    flight.fpe_minus = ''
    flight.moments_till_indicator = ''
    flight.dept_time = ''
    flight.dept_prefix = 'S'

    let flight_out_obj = null
    if(this.state.only_booked) {
      if(flight?.segs_out) {
        if(flight?.segs_out?.flight) {
          flight_out_obj = flight?.segs_out?.flight
        } else {
          //BUILD FLIGHTS OUT OBJECT
          let f = {}
          f.org = flight?.segs_out?.org
          f.dest = flight?.segs_out?.dest
          f.prod_code = flight?.awb?.prod_code
          flight_out_obj = f
        }
      }
    } else {
      flight_out_obj = flight
    }
    let filteredsp = []
    if(this.state.defaultSystemParameters?.length > 0){
      filteredsp = this.state.defaultSystemParameters.filter((item) => {
        let mvt = item?.details?.process?.split('-')?.[0]?.trim()
        if(mvt == 'Export') {
          return item
        }
      })
    }
    flight.handling_time_object = calc(flight_out_obj, /*this.state.systemParameters?.length > 0 && this.state.systemParameters.filter((item) => item.station === this.state.station)*/ [] ,
      filteredsp?.length > 0 && filteredsp.filter((item) => item.station === this.state.station), this.state.stationsData, page,
      flight.flights_out)

    if(flight?.flights_out?.dept) {
      if(flight?.flights_out?.dept?.act) {
        flight.dept_prefix = 'A'
        flight.dept_time = moment.utc(flight.flights_out.dept.act).tz(this.state.timezone)
      }
      else if(flight?.flights_out?.dept?.eta) {
        flight.dept_prefix = 'E'
        flight.dept_time = moment.utc(flight.flights_out.dept.eta).tz(this.state.timezone)
      }
      else if(flight?.flights_out?.dept?.schdl) {
        flight.dept_prefix = 'S'
        flight.dept_time = moment.utc(flight.flights_out.dept.schdl).tz(this.state.timezone)
      } else if(flight?.flights_out?.dept_date) {
        flight.dept_time = moment.utc(flight.flights_out.dept_date).tz(this.state.timezone)
      } else {
        flight.dept_time = moment(flight.flights_out.flight_date)
      }
    } else if(flight?.flights_out?.dept_date) {
      flight.dept_time = moment.utc(flight.flights_out.dept_date).tz(this.state.timezone)
    } else if (flight?.flights_out?.flight_date){
      flight.dept_time = moment(flight.flights_out.flight_date)
    }
    //DIFFERENCE BETWEEN DEPARTURE TIME AND AWB FPE
    let fpe = this.getFPE(flight?.flights_out)
    //CALCULATE COUNTDOWN TIMER
    let time_left = moment(flight.dept_time).diff(moment().tz(this.state.timezone), 'seconds')
    //SUBTRACT FPE
    time_left = time_left - (1*((fpe || 0)*60))
    flight.time_to_dept = moment().add(time_left, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    flight.time_to_dept_tz = moment.tz(this.state.timezone).add(time_left, 'seconds').format('YYYY-MM-DD HH:mm:ss') //FOR FILTER

    let dept_minus_fpe = moment.tz(flight.dept_time, this.state.timezone).subtract(flight?.handling_time_object?.fpe || 0, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    flight.fpe = moment.tz(dept_minus_fpe, this.state.timezone).format('YYYY-MM-DD HH:mm:ss')
    let diff = moment(flight.time_to_dept).diff(moment(), 'seconds')
    flight.diff = diff
    if(flight?.flight_no?.match(/^DL7[0-9]{3,5}[A-Z]{1}/g)|| flight?.flight_no?.match(/^DL9[0-9]{3,5}[A-Z]{1}/g) ){
      flight.istrk = true
    } else {
      flight.istrk = false
    }
    if((diff/60) < this.state.fpe_value && (diff/60) > 0) {
      flight.fpe_minus = moment().add(diff, 'seconds').format('YYYY-MM-DD HH:mm:ss')
      flight.color = 'countdown amber'
    } else if((diff/60) < 1){
      flight.fpe_minus = moment().format('YYYY-MM-DD HH:mm:ss')
      flight.color = 'red'
    } else {
      flight.fpe_minus = moment().add(diff, 'seconds').format('YYYY-MM-DD HH:mm:ss')
      flight.moments_till_indicator = moment().add(moment(flight.fpe_minus).diff(moment().add(this.state.fpe_value, 'minutes'), 'minutes'), 'minutes').format('YYYY-MM-DD HH:mm:ss')
      flight.color = ''
    }
    flight.uld_type = this.checkULDType(flight?.ulds_in) || ''
    flight.body_type = this.state.fleetData?.find((item) => item?.details?.ac_code === flight?.flights_out?.ac_type)?.details?.ac_type || ''
    if(flight?.wgt) {
      if(flight?.wgt?.unit === 'LBS' || flight?.wgt?.unit === 'LBR' || flight?.wgt?.unit === 'L' || flight?.wgt?.unit === 'LB') {
        flight.awb.weight_lbs = flight?.wgt?.value
        flight.awb.weight_kg = weightCalculator(flight?.wgt?.value, 'LBS')
      } else if(flight?.wgt?.unit === 'KGM' || flight?.wgt?.unit === 'K' || flight?.wgt?.unit === 'KG') {
        flight.awb.weight_kg = flight?.wgt?.value
        flight.awb.weight_lbs = weightCalculator(flight?.wgt?.value, 'KGM')
      }
    }

    flight.uld_color = ''
    if(!flight?.ulds_in?.blk && flight?.body_type === 'NB') {
      flight.uld_color = 'red-bu'
    } else if((flight?.ulds_in?.uld_no?.endsWith('#') || flight?.ulds_in?.uld_no?.startsWith('C')) && (flight?.body_type === 'WB')) {
      flight.uld_color = 'red-bu'
    }

    flight.location_color = ''
    if(flight?.location && flight?.location?.toUpperCase()?.endsWith('INV')) {
      flight.location_color = 'red-bu'
    }

    if(this.state.prodPriorityData?.length > 0){
      let pp_index = this.state.prodPriorityData.findIndex((p) => p.p_code?.toUpperCase() === flight?.awb?.prod_code?.toUpperCase())
      if(pp_index > -1) {
        flight.prod_prior = this.state.prodPriorityData[pp_index].priority
      } else {
        flight.prod_prior = null
      }
    } else {
      flight.prod_prior = null
    }

    return flight
  }
  //PUSH PROD CODE FOR FILTER
  pushProdCode = (code) => {
    let prodCodeList = this.state.prodCodeList
    let index = prodCodeList?.findIndex((p) => p === code)
    if(index === -1) {
      prodCodeList.push(code)
      this.setState({ prodCodeList: [] }, () => this.setState({ prodCodeList: prodCodeList }))
    }
  }
  //PUSH LOCATION FOR FILTER
  pushLocation = (location) => {
    let workLocationList = this.state.locationList
    let index = workLocationList?.findIndex((p) => p.text === location)
    if(index === -1) {
      workLocationList.push({
        text: location,
        value: location,
      })
      this.setState({ locationList: [] }, () => this.setState({ locationList: workLocationList }))
    }
  }
  //CHECK FOR ULD TYPE
  checkULDType = (uld) => {
    let type = ''
    if(uld?.blk && (!uld?.uld_no?.startsWith('C') && !uld?.uld_no?.endsWith('#'))) {
      type = 'BLK'
    } else {
      if(uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
        type = 'SLU'
      } else if(!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.uld_dispo?.toUpperCase() !== 'BREAKDOWN' && uld?.master) {
        type = 'PTU'
      } else if(uld?.uld_no?.startsWith('C') || uld?.uld_no?.endsWith('#')) {
        type = 'CART'
      }
    }
    return type
  }
  //DATE FILTERS
  setFilters = (type) => {
    let current_date = moment().tz(this.state.timezone).format(dateFormat)
    let selected_date_time = ''
    if(type === 'start') {
      selected_date_time = moment(this.state.start).format(dateFormat)

      if(selected_date_time < current_date) {
        this.setState({
          page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          end: moment(this.state.start).add(1, 'day'),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          firstLoad: true,
        })
      } else if(selected_date_time === current_date) {
        this.setState({
          page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          end: moment(this.state.start),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
          firstLoad: true,
        })
      } else if(selected_date_time > current_date) {
        this.setState({
          page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          end: moment(this.state.start),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          firstLoad: true,
        })
      }
    } else if(type === 'end') {
      selected_date_time = moment(this.state.end).format(dateFormat)

      if(selected_date_time < current_date) {
        this.setState({
          page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          start: moment(this.state.end),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            second: 59,
          }),
          firstLoad: true,
        })
      } else if(selected_date_time === current_date) {
        this.setState({
          page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          start: moment(this.state.end),
          stime: moment().tz(this.state.timezone).set({
            hours: 0,
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            hours: 23,
            minutes: 59,
            seconds: 59,
          }),
          firstLoad: true,
        })
      } else if(selected_date_time > current_date) {
        this.setState({
          page: 1,
          flightGridData: [],
          ULDGridData: [],
          flightRow: null,
          awbRow: null,
          loading: true,
          start: moment(this.state.end).subtract(1, 'day'),
          stime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          etime: moment().tz(this.state.timezone).set({
            minutes: 0,
            second: 0,
          }),
          firstLoad: true,
        })
      }
    } else if(type === 'stime' || type === 'etime') {
      this.setState({
        page: 1,
        flightGridData: [],
        ULDGridData: [],
        flightRow: null,
        awbRow: null,
        loading: true,
        firstLoad: true,
      })
    }
  }
  //RENDER DATA
  renderData = (data) => {
    let d = []
    if(this.state.tempHrs_to_fpe) {
      d = data?.filter((item) => moment.tz(item.time_to_dept_tz, this.state.timezone).diff(moment().tz(this.state.timezone), 'hours') < this.state.tempHrs_to_fpe)
      if(d?.length > 0 && this.isUIFilters()) {
        d = this.otherFilters(d)
      }
    } else if(this.isUIFilters()) {
      d = this.otherFilters(data)
    } else {
      d = data
    }
    d = d?.filter((item) => item.diff > 0)
    if((this.state.tempIs_assigned && this.state.tempIs_not_assigned) || (!this.state.tempIs_assigned && !this.state.tempIs_not_assigned)){
      //do nothing
    } else if(this.state.tempIs_assigned){
      d = d?.filter((item) => item.man === true )
    } else if(this.state.tempIs_not_assigned) {
      d = d?.filter((item) => item.man === false )
    }
    if(this.state.wasSearched){
      d = data
    }
    this.sort(d, 'location')
    this.sort(d, 'container')
    if(this.state.prodPrioCheck) {
      this.sort(d, 'prod_prior')
    }
    this.sort(d, 'flight_no')
    this.sort(d, 'fpe_minus')
    return d
  }
  //RETURN TRUE IF UI FILTERS ARE SELECTED
  isUIFilters = () => {
    return (this.state.tempIs_dom ? true : false) ||
    (this.state.temp_location ? true : false) ||
    (this.state.tempProdPrioCheck ? true : false) ||
    (this.state.prod_codes?.length > 0 ? true : false)
    ||
    (this.state.tempWbCheck ? true : false) ||
    (this.state.tempNbCheck ? true : false) ||
    (this.state.tempRfsCheck ? true : false)
  }
  //RETURN TRUE IF API FILTERS ARE SELECTED
  isAPIFilters = () => {
    return this.state.tempWorkArea !== this.state.workArea ||
    this.state.tempWbCheck !== this.state.wbCheck ||
    this.state.tempNbCheck !== this.state.nbCheck ||
    this.state.tempRfsCheck !== this.state.rfsCheck ||
    this.state.tempIs_assigned !== this.state.is_assigned ||
    this.state.tempIs_not_assigned !== this.state.is_not_assigned ||
    this.state.tempIs_local !== this.state.is_local ||
    this.state.tempIs_transfer !== this.state.is_transfer
    // ||
    // moment(this.state.tempStart).format('YYYY-MM-DD') !== moment(this.state.start).format('YYYY-MM-DD') ||
    // moment(this.state.tempEnd).format('YYYY-MM-DD') !== moment(this.state.end).format('YYYY-MM-DD') ||
    // moment(this.state.tempStime).format('HH:mm') !== moment(this.state.stime).format('HH:mm') ||
    // moment(this.state.tempEtime).format('HH:mm') !== moment(this.state.etime).format('HH:mm')
  }
  //OTHER FILTERS
  otherFilters = (filtered_data) => {
    let filters = {}
    if(this.state.tempIs_dom) {
      filters.isDomIntl = item => item?.dom_intl === this.state.tempIs_dom
    }
    if(this.state.temp_location) {
      filters.isLocation = item => item?.location === this.state.temp_location || (!item?.inbound && item?.flights_in?.flight_no === this.state.temp_location)
    }
    if(this.state.temp_prod_codes?.length > 0) {
      filters.isProdCode = item => this.state.temp_prod_codes?.includes(item?.awb?.prod_code)
    }
    if(this.state.tempWbCheck && this.state.tempNbCheck) {
      filters.iswbCheck = item => {
        item?.body_type === 'WB' || 
        item?.body_type === 'NB'
      }
    }
    else if(this.state.tempWbCheck) {
      filters.iswbCheck = item => item?.body_type === 'WB'
    }
    else if(this.state.tempNbCheck) {
      filters.isnbCheck = item => item?.body_type === 'NB'
    }
    if(this.state.tempRfsCheck) {
      filters.isrfsCheck = item => item?.istrk === true
    }
    return filtered_data?.filter(item => Object.values(filters)?.every(f => f(item)))
  }
  //SET DATES DISABLED ON CONDITION
  disabledDate = (current) => {
    let prev = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
    let next = moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)
    return current && (current < moment(prev, dateFormat) || current > moment(next, dateFormat))
  }
  //RENDER FILTER SELECTION STRING
  renderFilterBlock = () => {
    let work_area = this.state.tempWorkArea ? false : true
    let equipment = (!this.state.tempWbCheck && !this.state.tempNbCheck && !this.state.tempRfsCheck)
    let cargo_at = (!this.state.tempIs_assigned && !this.state.tempIs_not_assigned)
    let arrived = (!this.state.tempIs_local && !this.state.tempIs_transfer)
    let dom_intl = this.state.tempIs_dom ? false : true
    let prod_prio = this.state.tempProdPrioCheck ? false : true
    let prod_codes = this.state.temp_prod_codes?.length > 0 ? false : true
    let location = this.state.temp_location ? false : true
    if(this.state.wasSearched){
      return (
        <><br />
          <Row className='mt-1vw pl-2vw sel-flt-rw'>
            <Col xs={12}><b> Search: </b><span>{this.state.searchValue}</span></Col> <></>
          </Row>
        </>
      )
    }
    return (
      <><br />
        <Row className='mt-1vw pl-2vw sel-flt-rw'>
          {/* <Col xs={12}><b> From: </b><span>{(moment.tz((isMoment(this.state.tempStart) ? this.state.tempStart.format(dateFormat) : this.state.tempStart) +' '+ this.state.tempStime.format(timeFormat), this.state.timezone)).format('DD-MM-YYYY HH:mm')}</span></Col>
          <Col xs={12}><b> To: </b><span>{(moment.tz((isMoment(this.state.tempEnd) ? this.state.tempEnd.format(dateFormat) : this.state.tempEnd) +' '+ this.state.tempEtime.format(timeFormat), this.state.timezone)).format('DD-MM-YYYY HH:mm')}</span></Col> */}
          { !work_area ? <Col xs={12}><b> Workarea: </b><span>{this.state.tempWorkArea}</span></Col> : <></> }
          <Col xs={12}><b> &lt; Hrs to FPE: </b><span>{this.state.tempHrs_to_fpe}</span></Col>
          { !dom_intl ? <Col xs={12}><b> DOM/INTL: </b><span>{this.state.tempIs_dom}</span></Col> : <></> }
          { !prod_codes ? <Col xs={12}><b> Product: </b><span>{this.state.temp_prod_codes?.toString()}</span></Col> : <></> }
          { !location ? <Col xs={12}><b> Location: </b><span>{this.state.temp_location}</span></Col> : <></>}
          { !equipment ? <Col xs={12}><b> Equipment: </b><span>{this.state.tempWbCheck ? 'WB' : ''} {this.state.tempNbCheck ? 'NB' : ''} {this.state.tempRfsCheck ? 'TRK' : ''}</span></Col> : <></> }
          { !arrived ? <Col xs={12}><b> Booked cargo yet to arrive: </b><span>{(this.state.tempIs_local && this.state.tempIs_transfer) ? 'LOCAL/TRANSFER' : this.state.tempIs_local ? 'LOCAL' : 'TRANSFER'}</span></Col> : <></> }
          { !cargo_at ? <Col xs={12}><b> Cargo at station: </b><span>{(this.state.tempIs_assigned && this.state.tempIs_not_assigned) ? 'ASSIGNED/NOT ASSIGNED' :this.state.tempIs_assigned ? 'ASSIGNED' : 'NOT ASSIGNED'}</span></Col> : <></> }
          { !prod_prio ? <Col xs={12}><b> Rank by Prod Prio: </b><span>{this.state.tempProdPrioCheck ? 'YES' : 'NO'}</span></Col> : <></> }
        </Row>
      </>
    )
  }
  //APPLY FILTER
  applyFilters = () => {
    let api_flag = this.isAPIFilters()
    let is_clearfilters = cloneDeep(this.state.is_clearfilters)
    this.setState({
      is_clearfilters: false,
      visible: false,
      tempHrs_to_fpe: this.state.hrs_to_fpe,
      tempWorkArea: this.state.workArea,
      tempWbCheck: this.state.wbCheck,
      tempNbCheck: this.state.nbCheck,
      tempRfsCheck: this.state.rfsCheck,
      tempProdPrioCheck: this.state.prodPrioCheck,
      tempIs_assigned: this.state.is_assigned,
      tempIs_not_assigned: this.state.is_not_assigned,
      tempIs_local: this.state.is_local,
      tempIs_transfer: this.state.is_transfer,
      // tempStart: this.state.start,
      // tempEnd: this.state.end,
      // tempStime: this.state.stime,
      // tempEtime: this.state.etime,
      tempIs_dom: this.state.is_dom,
      temp_location: this.state.location,
      temp_prod_codes: this.state.prod_codes,
    }, async () => {
      if(is_clearfilters || api_flag || (this.isUIFilters() || (this.state.tempHrs_to_fpe !== this.state.hrs_to_fpe))) {
        let buildupdash_filter = cloneDeep(this.state.buildupFilter)
        buildupdash_filter[this.state.station] = {
          type: this.state.workArea,
          wb: this.state.wbCheck,
          nb: this.state.nbCheck,
          rfs: this.state.rfsCheck,
          assigned: this.state.is_assigned,
          nassigned: this.state.is_not_assigned,
          local: this.state.is_local,
          transfer: this.state.is_transfer,
          dom_intl: this.state.is_dom,
          location: this.state.location,
          prod_codes: this.state.prod_codes,
          hrs_to_fpe: this.state.hrs_to_fpe,
          prodPrioCheck: this.state.prodPrioCheck,
        }
        let formdata = { buildupdash_filter: buildupdash_filter }
        await Http.put(`/page_filter/${this.state.user_id}`, formdata).then(async (data) => {
          if (data && data?.status === 200) {
            if(api_flag || is_clearfilters) {
              this.resetPage()
            }
            this.fetchFilters(api_flag || is_clearfilters)
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({ servererror : true })
        })
      }
    })
  }
  //CLEAR FILTERS
  clearFilters = () => {
    this.setState({
      is_clearfilters: true,
      hrs_to_fpe: '',
      // workArea: '',
      wbCheck: false,
      nbCheck: false,
      rfsCheck: false,
      prodPrioCheck: false,
      is_assigned: false,
      is_not_assigned: false,
      is_local: false,
      is_transfer: false,
      is_dom: '',
      location: '',
      prod_codes: [],

      tempHrs_to_fpe: '',
      // tempWorkArea: '',
      tempWbCheck: false,
      tempNbCheck: false,
      tempRfsCheck: false,
      tempProdPrioCheck: false,
      tempIs_assigned: false,
      tempIs_not_assigned: false,
      tempIs_local: false,
      tempIs_transfer: false,
      tempIs_dom: '',
      temp_location: '',
      temp_prod_codes: [],
    }, () => this.applyFilters())
  }
  //CHANGE ROW COLOR
  changeRowColor = (record) => {
    let red_filter = this.state.red_locations?.filter((loc1) => record?.location?.endsWith(loc1))
    let amber_filter = this.state.amber_locations?.filter((loc2) => record?.location?.endsWith(loc2))
    if(red_filter?.length > 0 || !record?.flights_out) {
      return 'red-bu-bg'
    }

    if(amber_filter?.length > 0) {
      return 'amber-bu-bg'
    }
  }
  //SORT
  sort = (array, type) => {
    if(type === 'prod_prior') {
      let sorted = array?.sort((a, b) => {
        if (a.prod_prior === null) {
          return 1
        }
        if (b.prod_prior === null) {
          return -1
        }
        if (a.prod_prior === b.prior) {
          return 0
        }
        return a.prod_prior < b.prod_prior ? -1 : 1
      })
      return sorted
    } else {
      if(type === 'fpe_minus') {
        array && array.sort(function(a, b) {
          // Compare the fields
          if (moment(a?.fpe_minus) < moment(b?.fpe_minus)) return -1
          if (moment(a?.fpe_minus) > moment(b?.fpe_minus)) return 1
          return 0
        })
      } else {
        array && array.sort(function(a, b) {
          // Compare the fields
          return ('' + a[type]).localeCompare(b[type])
        })
      }
    }
  }
  //CHANGE FPE COLOR
  changeFPEColor = (DETAILS) => {
    let data = cloneDeep(this.state.buildup_list)

    let awb_index = data?.findIndex((p) => p.id === DETAILS?.id)
    if(awb_index > -1) {
      if(DETAILS?.color === 'red') {
        data?.splice(awb_index, 1)
      } else {
        data[awb_index].color = DETAILS?.color
      }
      this.setState({ buildup_list: [] }, () => this.setState({ buildup_list: data }))
    }
  }
  //GET ADDITIONAL DAYS
  getAdditionalDays = (flight) => {
    if(moment.tz(flight.dept_time, this.state.timezone).diff(moment().tz(this.state.timezone), 'days') > 0) {
      return `+${moment.tz(flight.dept_time, this.state.timezone).diff(moment().tz(this.state.timezone), 'days')}`
    } else {
      return ''
    }
  }
  //PARSE COUNTDOWN DATA AND SEND TO QUEUE
  createCountdownPromise = (record, color) => {
    if(moment(record?.fpe_minus).add(5, 'seconds').valueOf() > moment().valueOf()) {
      let parsedData = {
        TYPE: 'COUNTDOWN',
        DETAILS: {
          id: record?.id,
          color: color,
        }
      }
      const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
      this.enqueue(myFakeAsyncJob)
    }
  }
  //REDIRECT LOGIC
  handleRedirect = () => {
    if(this.state.role?.toUpperCase() === 'WAREHOUSE TV') {
      logout()
    } else {
      pageHandler('', this.state.role)
    }
  }
  //HANDLE DONE
  done = (record) => {
    let data = cloneDeep(this.state.buildup_list)
    let awb_index = data?.findIndex((p) => p.id === record?.id)
    if(awb_index > -1) {
      data[awb_index].done = true
      this.setState({ buildup_list: [] }, () => this.setState({ buildup_list: data }))
    }
  }
  //HANDLE CONFIRM
  confirm = async (record) => {
    let headers = {
      station: this.state.station,
      page: PAGE.BUILDUP
    }
    let uld_id = record?.ulds_in?.id
    if(uld_id){
      // loading = true
      let buildup_list = cloneDeep(this.state.buildup_list)
      let index = buildup_list?.findIndex((p) => p?.id === record.id)
      if(index > -1) {
        buildup_list[index].loading = true
        this.setState({ buildup_list: buildup_list })
      }

      await Http.get(`/haul_uld/${uld_id}`, { headers }).then(async (data) => {
        if (data && data?.status === 200) {
          //DO NOTHING
        } else {
          let buildup_list = cloneDeep(this.state.buildup_list)
          let index = buildup_list?.findIndex((p) => p?.id === record.id)
          if(index > -1) {
            buildup_list[index].loading = true
            this.setState({ buildup_list: buildup_list })
          }
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      }).catch(() => {
        let buildup_list = cloneDeep(this.state.buildup_list)
        let index = buildup_list?.findIndex((p) => p?.id === record.id)
        if(index > -1) {
          buildup_list[index].loading = true
          this.setState({ buildup_list: buildup_list })
        }
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ servererror : true })
      })
    }
  }

  // AWB SEARCH
  setSearchValue (e){
    this.setState({ searchValue: e.target.value },() =>{
      if(this.state.wasSearched && !this.state.searchValue){
        this.setState({ buildup_list: [] },()=> this.fetchDashBuildUp(null,false))
      }
    })
  }
  onSearch = (e ) => {
    let old_searchValue = cloneDeep(this.state.searchValue)
    let fetch_flag = false
    if(old_searchValue.trim() !== e.trim())
      fetch_flag = true
    this.setState({ buildup_list: [] })
    if(!e){
      this.setState({
        buildup_list: fetch_flag? [] : this.state.buildup_list ,
        searchSelect: !this.state.searchSelect,
        searchValue: e
      },()=>{
        if(fetch_flag)
          this.fetchDashBuildUp(null,false)
      })
    }

    if(e){
      //hallout_list
      this.fetchDashBuildUp(null,true)
    }
  }

  render() {
    let { workAreaData /*, loading */ } = this.state

    const awb_columns = [
      {
        title: 'FLT',
        dataIndex: '',
        key: 'flt',
        align:'left',
        render: (_, record) => (
          <span>{ record?.is_seg ? `${record?.flights_out?.flight_no} ${this.getAdditionalDays(record)}` : this.state.only_booked ?
            `${record?.segs_out?.flight_no} ${this.getAdditionalDays(record)}` :
            `${record?.flights_out?.flight_no} ${this.getAdditionalDays(record)}`}</span>
        )
      },
      // {
      //   title: 'WB/NB',
      //   render: (_, record) => (
      //     <span>{record?.body_type}</span>
      //   )
      // },
      // {
      //   title: 'TRK',
      //   render: (_, record) => (
      //     <span>{record?.istrk ? 'YES' : 'No'}</span>
      //   )
      // },
      {
        title: 'AC',
        dataIndex: '',
        key: 'ac',
        render: (_, record) => (
          <span className={record.flights_out?.ac_change && record?.flights_out?.ac_change.length>0 ?  'ac-change' : null}>{record?.flights_out?.ac_type ? `${record.flights_out.ac_type}` : 'N/A'}</span>
        )
      },
      {
        title: 'AWB',
        dataIndex: '',
        key: 'awb',
        align:'center',
        render: (_, record) => (
          <span>{record?.awb?.awb_no ? formatAWBNo(record.awb.awb_no) : 'N/A'}</span>
        )
      },
      {
        title: 'Dest',
        dataIndex: 'dest',
        key: 'dest',
      },
      {
        title: 'O/C',
        dataIndex: 'o_c',
        key: 'o_c',
        align:'center',
        render: (_, record) => record?.uld_type === 'BLK' || record?.uld_type === 'CART' ? '-' : record?.is_seg ? '-' :  record?.rdy ? <span>C</span> : <span className='red-bu'>O</span>
      },
      {
        title: 'PCS',
        dataIndex: '',
        key: 'pcs',
        render: (_, record) => (
          <span>{record?.inbound ? record?.avl_pcs : record?.awb?.pcs?.total?.value ? record?.awb?.pcs?.total?.value : 'N/A'}</span>
        )
      },
      {
        title: 'WGT',
        dataIndex: 'wgt',
        key: 'wgt',
        render: (_, record) => (
          <span>{this.state.weight_unit === 'KG' ?
            formatWeightString(record?.awb?.weight_kg, 'KG') :
            formatWeightString(record?.awb?.weight_lbs, 'LBS')}
          </span>
        )
      },
      {
        title: 'LOCN',
        dataIndex: '',
        key: 'locn',
        render: (_, record) => (
          <span className={record?.location_color}>
            {record?.location ? record.location : !record?.inbound ? record?.flights_in?.flight_no : 'N/A'}
          </span>
        ),
      },
      {
        title: 'ULD',
        dataIndex: '',
        key: 'uld',
        align:'center',
        render: (_, record) => (
          <span className={record?.uld_type === 'BLK' ? '' : record?.uld_color}>
            {record?.uld_type === 'BLK' ? '-' : record?.is_seg ? '-' : record?.ulds_in?.uld_no ? `${record.ulds_in.uld_no}` : 'N/A'}
          </span>
        )
      },
      {
        title: 'PROD',
        dataIndex: '',
        key: 'prod',
        render: (_, record) => (
          <span>{record?.awb?.prod_code ? record.awb.prod_code : 'N/A'}</span>
        ),
      },
      {
        title: 'FPE',
        dataIndex: 'fpe',
        key: 'fpe',
        render: (_, record) => (
          <span className={record?.color}>
            <Countdown className='hidden-countdown' onFinish={() => this.createCountdownPromise(record, 'countdown amber')} value={record?.moments_till_indicator} format='HH:mm:ss' />
            {moment(record?.fpe).format('HH:mm')}
          </span>
        )
      },
      {
        title: '-FPE',
        dataIndex: 'fpe_',
        key: 'fpe_',
        render: (_, record) => (
          <span className={record?.color}>
            <Countdown className='hidden-countdown' onFinish={() => this.createCountdownPromise(record, 'red')} value={record?.fpe_minus} format='HH:mm:ss' />
            <Countdown value={record?.fpe_minus} format='HH:mm'  style={{ fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}` }} />
          </span>
        )
      },
      {
        title: 'Done',
        dataIndex: 'done',
        key: 'done',
        className: 'no-print',
        render: (_, record) => (
          <>
            {
              record?.man ? !record?.done ?
                <><Button onClick={() => this.done(record)} type='primary' className='warehouse-done'>Done</Button></> :
                <><Button onClick={() => this.confirm(record)} type='primary' className='warehouse-done warehouse-confirm' 
                  loading={record.loading}>{record.loading ? '' : 'Confirm'}</Button>
                </> : record.ulds_in? (!record?.done ?
                <><Button onClick={() => this.done(record)} type='primary' className='warehouse-done'>Clear</Button></> :
                <><Button onClick={() => this.confirm(record)} type='primary' className='warehouse-done warehouse-confirm' 
                  loading={record.loading}>{record.loading ? '' : 'Confirm'}</Button>
                </> ) : <></>         
            }
          </>
        )
      }
    ]
    return (
      <div className='warehouse-screen buildup-dash'>
        <div className='text-right f-spacebtw'>
          <div className='no-print'>
            {this.state.role?.toUpperCase() === 'WAREHOUSE TV' ?
              <div className='d-flex'>
                {
                  this.state.logintype && this.state.logintype === 'DashTV' ? null :
                    <div className='mr-1vw'>
                      <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<ArrowLeftOutlined />} size='large' onClick={()=>window.location.href = '/landing-page' } />
                      <p className='btn-text'>Back</p>
                    </div>
                }
                <div>
                  <Button className='custom-icon-button warehouse-filter rotate-180' type='primary' shape='circle' icon={<LogoutOutlined />} size='large' onClick={this.handleRedirect} />
                  <p className='btn-text'>Signout</p>
                </div>
              </div>
              :
              <div>
                <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<HomeOutlined />} size='large' onClick={this.handleRedirect} />
                <p className='btn-text'>Home</p>
              </div>
            }
          </div>
          <h1 className='dash-title mr-0 dash-loc'>{this.state.station}</h1>
        </div>
        <div className='clock-container'>
          <Text className='clock-text'>
            {this.state.clock}
          </Text>
        </div>
        <h1 className='text-center'><span className='dash-title'>Buildup Dashboard</span></h1>
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
          ]}
        >
          <div className='w-100 f-spacebtw mb-1p5'>
            <div className='d-flex flex-6'>
              <div className='selected-filter mb-0 '>Selected Filters:
                {this.renderFilterBlock()}
              </div>
            </div>
            <div span={2} className='row-1 pr-0 ml-auto bd-btns flex-1 no-print'>
              <Space direction='horizontal' className='w-action-btn'>
                <Input.Search
                  placeholder='Search AWB'
                  onSearch={this.onSearch}
                  onChange={(e) => this.setSearchValue(e)}
                  allowClear
                  className={'warehouse-search' + (this.state.searchSelect? ' search-w100' : ' search-w0')}
                />
                <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<PrinterOutlined />} size='large' onClick={()=>window.print()} />
                <Button disabled={this.state.wasSearched} className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<FilterOutlined />} size='large' onClick={this.showDrawer} />
              </Space>
            </div>
          </div>
          <Drawer
            title=''
            placement='top'
            width={500}
            closable={false}
            open={this.state.visible}
            className='warehouse-drawer dr-buildup tv-res'
          >
            <Row>
              <Button className='close-btn ml-auto' onClick={this.onClose} size='large' type='link'>X</Button>
            </Row>
            <Row
              gutter={[
                {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: 0,
                },
                {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: 0,
                },
              ]}
              className='warehouse-screen'
            >
              {/* <Col xs={24} sm={12} md={6} lg={4} xl={4} className='row-1' >
                <Form.Item label='From'>
                  <DatePicker
                    allowClear={false}
                    className=''
                    onChange={(moment) => this.setState({ start: moment }, () => this.setFilters('start'))}
                    value={moment(this.state.start, dateFormat)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6} lg={4} xl={4}className='row-1'>
                <Form.Item label='-' className='transparent'>
                  <TimePicker
                    allowClear={false}
                    onChange={(moment) => this.setState({ stime: moment }, () => this.setFilters('stime'))}
                    value={moment(this.state.stime, timeFormat)}
                    disabledHours={()=>{
                      var hours = []
                      let start = this.state.start ? moment(this.state.start, dateFormat).format(dateFormat) : null
                      let atlstart = moment().tz(this.state.timezone).subtract(1, 'day').format(dateFormat)
                      if(start && atlstart && start === atlstart ){
                        for(var i = 0; i < moment().tz(this.state.timezone).hour(); i++){
                          hours.push(i)
                        }
                      }
                      return hours
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6} lg={4} xl={4} className='row-1' >
                <Form.Item label='To'>
                  <DatePicker
                    allowClear={false}
                    className=''
                    onChange={(moment) => this.setState({ end: moment }, () => this.setFilters('end'))}
                    value={moment(this.state.end, dateFormat)}
                    disabledDate={(current) => this.disabledDate(current)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={6} lg={4} xl={4}className='row-1'>
                <Form.Item label='-' className='transparent'>
                  <TimePicker
                    allowClear={false}
                    onChange={(moment) => this.setState({ etime: moment }, () => this.setFilters('etime'))}
                    value={moment(this.state.etime, timeFormat)}
                    disabledHours={()=>{
                      var hours = []
                      let end = this.state.end ? moment(this.state.end, dateFormat).format(dateFormat) : null
                      let atlend = moment().tz(this.state.timezone).add(1, 'day').format(dateFormat)
                      if(end && atlend && end === atlend){
                        for(var i = 23; i > moment().tz(this.state.timezone).hour(); i--){
                          hours.push(i)
                        }
                      }
                      return hours
                    }}
                  />
                </Form.Item>
              </Col> */}
              <Col xs={24} sm={12} md={12} lg={5} xl={5} className='row-1'>
                <Form.Item label='Workarea'>
                  <Select
                    size='small'
                    value={this.state.workArea}
                    placeholder='Select Workarea'
                    popupClassName='warehouse-select'
                    onChange={(e) => this.setState({
                      loading: true,
                      workArea: e,
                      visible: false,
                      page: 1,
                      buildup_list: [],
                      wbCheck: false,
                      nbCheck: false,
                      rfsCheck: false,
                      prodPrioCheck: false,
                      is_assigned: false,
                      is_not_assigned: false,
                      is_local: false,
                      is_transfer: false,
                      is_dom: '',
                      location: '',
                      prod_codes: [],
                    }, () => this.applyFilters())}
                  >
                    {
                      workAreaData && workAreaData.map((item) => (
                        <Option key={item.area_name} value={item.area_name}>{item.area_name}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={5} xl={5} className='row-1'>
                <Form.Item label='Product'>
                  <Select
                    mode='multiple'
                    size='small'
                    value={this.state.prod_codes}
                    placeholder='Select product'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ prod_codes: e?.includes('RESET') ? [] : e })}
                  >
                    <Option className='blue' key={'RESET'} value={'RESET'}>{'RESET'}</Option>
                    {this.state.prodCodeList?.map((item) => (
                      <Option key={item} value={item}>{item}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Location' className='loc-search'>
                  <Select
                    allowClear
                    showSearch
                    size='small'
                    value={this.state.location}
                    placeholder='Select location'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ location: e && e !== undefined ? e : '' })}
                  >
                    {this.state.locationList?.map((item) => (
                      <Option key={item?.value} value={item?.value}>{item?.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={4} xl={4} className='row-1'>
                <Form.Item label='< Hrs to FPE'>
                  <InputNumber
                    size='small'
                    placeholder=''
                    className='w-100 m-0'
                    onChange={(e) => this.setState({ hrs_to_fpe: e || '' })}
                    value={this.state.hrs_to_fpe}
                    controls={false}
                  >
                  </InputNumber>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={4} xl={4} className='row-1'>
                <Form.Item label='DOM/INTL'>
                  <Select
                    size='small'
                    placeholder='Select DOM/INTL'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ is_dom: e })}
                    value={this.state.is_dom}
                  >
                    <Option value=''>All</Option>
                    <Option value='DOM'>DOM</Option>
                    <Option value='INTL'>INTL</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5} className='dash-checkbox'>
                <Form.Item label='Equipment'>
                  <Checkbox checked={this.state.wbCheck} onChange={(e) => this.setState({ wbCheck: e.target.checked })}>WB</Checkbox>
                  <Checkbox checked={this.state.nbCheck} onChange={(e) => this.setState({ nbCheck: e.target.checked })}>NB</Checkbox>
                  <Checkbox checked={this.state.rfsCheck} onChange={(e) => this.setState({ rfsCheck: e.target.checked })}>RFS</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={5} className='dash-checkbox'>
                <Form.Item label='Booked cargo yet to arrive'>
                  <Checkbox checked={this.state.is_local} onChange={(e) => this.setState({ is_local: e.target.checked })}>Local</Checkbox>
                  <Checkbox checked={this.state.is_transfer} onChange={(e) => this.setState({ is_transfer: e.target.checked })}>Transfer</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className='dash-checkbox'>
                <Form.Item label='Cargo at station'>
                  <Checkbox checked={this.state.is_assigned} onChange={(e) => this.setState({ is_assigned: e.target.checked })}>Assigned</Checkbox>
                  <Checkbox checked={this.state.is_not_assigned} onChange={(e) => this.setState({ is_not_assigned: e.target.checked })}>Not Assigned</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={4} xl={4} className='dash-checkbox'>
                <Form.Item label='Rank By'>
                  <Checkbox checked={this.state.prodPrioCheck} onChange={(e) => this.setState({ prodPrioCheck: e.target.checked })}>Product Priority</Checkbox>
                </Form.Item>
              </Col>
              <Col className='ml-auto mt-2vw'>
                <Space direction='horizontal'>
                  <Button onClick={this.clearFilters} type='primary' className='custom-button warehouse-button mr-2vw'>Clear filters</Button>
                  <Button onClick={() => this.applyFilters()} className='custom-button warehouse-button' type='primary'>Apply filters</Button>
                </Space>
              </Col>
            </Row>
          </Drawer>
          {/* <div className='clock-container'>
            <Text className='clock-text'>
              {this.state.clock}
            </Text>
          </div> */}
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Spin spinning={this.state.loading}>
              <Table
                className='components-table-demo-nested warehouse-table'
                columns={awb_columns}
                dataSource={this.renderData(this.state.buildup_list)}
                pagination={false}
                rowClassName={(record) => this.changeRowColor(record)}
                rowKey='id'
                onRow={() => {
                  return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}` }, }
                }}
                onHeaderRow={() => {
                  return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}`, }, }
                }}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    )
  }
}

export default BuildUpDashboard
