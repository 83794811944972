import React, { Component } from 'react'
import { COOKIE, getCookie, pageHandler } from '../../utils/cookie'

class Redirect extends Component {
  state = {  } 

  componentDidMount() {
    pageHandler('', getCookie(COOKIE.ROLE))
  }

  render() { 
    return (
      <></>
    )
  }
}
 
export default Redirect