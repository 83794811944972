import React, { Component } from 'react'
import Http from '../../../utils/http'
import JSONTree from 'react-json-tree'
import { jsonTheme } from '../../../constants/constants'
import moment from 'moment'
import { Row, Col, Card, Table, notification, Button } from 'antd'
import { RedoOutlined } from '@ant-design/icons'

class UnprocessedMessages extends Component {
  constructor() {
    super()
    this.state = {
      startDate: '',
      endDate: '',
      displayData: [],
      filteredData:[],
      displayJson: '',
      search: '',
      stationVal: [],
    }
  }

  componentDidMount() {
    this.fetchData()
    this.timer = setInterval(() => {
      this.fetchData()
    },30000)
  }
 
  componentWillUnmount() {
    clearInterval(this.timer)
  }  

  //FETCH UNPROCESSED MESSAGES
  fetchData = async () => {
    let headers = {
      // station: station,
      search: this.state.search
    }
    let url = 'cache/failed'
    await Http.get(url, { headers }).then(resp => {
      if(resp && resp?.data && resp?.data?.data?.length > 0 ) {
        let data = resp?.data?.data.map(item => {
          return { ...item }
        })
        let orgArray = []
        data.map((s) => {
          let valArr = []
          s.value.map(val => {
            let arr = JSON.parse(val)
            valArr.push(arr)
            orgArray.push(val.station)
            orgArray.filter(orgItem => orgArray.indexOf(orgItem))
            orgArray.sort()
          })
          s.valArr = valArr
        })
        this.setState({ 
          displayData: data,
          stationVal: orgArray,
        })
        notification.destroy()
        notification.success({
          message: 'Success',
          description: resp?.data?.data.message,
        })
      }
      else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: resp?.data?.data.message,
        })
      }
    }).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error)
    })
  }

  fetchMessage = async (awb, type, id) => {
    let url = ''
    if(type === 'ECIBOOK' || type.includes('FSU')) {
      url = `/replay/msg?awb=${awb}&id=${id}`
    }
    else {
      url = `/replay/msg?id=${id}`
    }
    await Http.get(url).then(resp => {
      if(resp && resp?.status === 200) {
        // eslint-disable-next-line no-console
        console.log('')
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }

  formatCreate = (val) => {
    let minutes = moment().utc().diff(moment.utc(val), 'minutes')
    let hours = Math.floor(minutes / 60)
    return hours + 'hrs' + ' ' + (minutes % 60) + 'mins'
  }

  render() {
    const columns = [
      {
        title: 'Awb Number',
        dataIndex: '',
        key: 'awb',
        width: '30%',              
        render: (_, record) => {
          let statArr = []
          record.valArr.map(val => {
            let arr = val.station
            statArr.push(arr)
            statArr = statArr.filter((item, index) => statArr.indexOf(item) === index)
          })
          return (
            <span>{record?.key.replace('Queue-','')} 
              <br />
              [{statArr.map((item, i, arr) => 
                <span key={i}>
                  {item} 
                  {i != (arr.length-1) ? ',' : ''}
                </span>
              )}]
            </span>
          )
        }
      },
      {
        title: 'Message',
        dataIndex: '',
        key: 'message',
        width: '30%',
        render: (_, record) => {
          return record.valArr.map((val) => (<>
            <b>Station:</b> {val.station} <br />
            <b>Type:</b> {val.type} <br />
            <b>TIme:</b> { this.formatCreate(val.msg.createdAt) }
            <JSONTree data={val} datatheme={jsonTheme} invertTheme={true} className='json-tree' />
            <hr className='hr-color' />
          </>)
          )
        }
      },
      {
        title: 'Reload',
        dataIndex: '',
        key: 'reload',
        width: '30%',
        render: (_, record) => {
          let awb = record?.key.replace('Queue-','')
          let type = record.valArr[0].type
          let id = record.valArr[0].id
          return <Button shape='circle' icon={<RedoOutlined />} type='text'
            onClick={() => this.fetchMessage(awb, type, id)} 
          />
        }
      },
    ]

    return (
      <Card className='custom-card h-100'>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 12,
          lg: 12
        }, {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12
        },]} className=''>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className='table-overflow'
          >
            <div className='table-outer'>
              <Table
                className='custom-table unpr-msg-table'
                dataSource={this.state.displayData}
                columns={columns}
                pagination={false}
              />
            </div>
          </Col>
        </Row>
      </Card>
    )
  }
}
export default UnprocessedMessages
