import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Layout } from 'antd'
//import * as serviceWorker from './serviceWorker'
//import { subscribeUser } from './subscription'

//import { getMessaging, getToken } from 'firebase/messaging'
// import { onMessageListener } from './firebaseinit.js'


ReactDOM.render(
  <React.StrictMode>
    <Layout className='site-layout main-layout'>
      <App />
    </Layout>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

//serviceWorker.register()
//subscribeUser()

// // Get registration token. Initially this makes a network call, once retrieved
// // subsequent calls to getToken will return from cache.
// const messaging = getMessaging()
// getToken(messaging, { vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY }).then((currentToken) => {
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//     // ...
//   } else {
//     // Show permission request UI
//     // eslint-disable-next-line no-console
//     console.log('No registration token available. Request permission to generate one.')
//     // ...
//   }
// }).catch((err) => {
//   // eslint-disable-next-line no-console
//   console.log('An error occurred while retrieving token. ', err)
//   // ...
// })

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('./firebase-messaging-sw.js')
//     .then(function(registration) {
//       // eslint-disable-next-line no-console
//       console.log('Registration successful, scope is:', registration.scope)
//     })
//     .catch(function(err) {
//       // eslint-disable-next-line no-console
//       console.log('Service worker registration failed, error:', err)
//     })
// }

// stackNavigator()
//   .then((payload) => {
//     // setShow(true)
//     // setNotification({
//     //   title: payload.notification.title,
//     //   body: payload.notification.body,
//     // })
//     // eslint-disable-next-line no-console
//     console.log('StackNavigator',payload)
//   })
//   .catch((err) => {
//     // eslint-disable-next-line no-console
//     console.log('failed: ', err)
//   })


/* Copy paste to page to start listener for firebase notifications */
// onMessageListener()
//   .then((payload) => {
//     // setShow(true)
//     // setNotification({
//     //   title: payload.notification.title,
//     //   body: payload.notification.body,
//     // })
//     // eslint-disable-next-line no-console
//     console.log('onMessageListener',payload)
//   })
//   .catch((err) => {
//     // eslint-disable-next-line no-console
//     console.log('failed: ', err)
//   })

