import { Card, Col, Row, Typography } from 'antd'
const { Title } = Typography
import React, { Component } from 'react'
import '../index.css'
import HeaderSearch from '../../search'
import http from '../../../utils/http'


class Audit extends Component {

  getSearchResults = async (search) => {
    if (search) {
      search = search.split('=')[1]
      const url = 'audit?search=' + search
      try {
        const resp = await http.get(url, { headers: '' })
        if (resp) {
          const blob = new Blob([resp.data], { type: 'text/csv' })
          const downloadUrl = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = downloadUrl
          a.download = 'audit.csv'
          a.click()
          window.URL.revokeObjectURL(downloadUrl) // Clean up the URL object
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching search results:', error)
      }
    }
  }

  render() {
    return (
      <Card className='custom-card'>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw header-messages'>
            <Title level={5} className='mb-0'>Search Messages</Title>
            <HeaderSearch placeholder='Search' className='position-static' searchResults={this.getSearchResults} page='GATEKEEPER' />
          </Col>
        </Row>
      </Card>
    )
  }
}

export default Audit