import React, { Component } from 'react'
import { Row, Col, Typography, Card, Table, Spin, Button, Modal, notification } from 'antd'
import { RedoOutlined } from '@ant-design/icons'
import { jsonTheme, constants, timezone } from '../../../constants/constants'
import JSONTree from 'react-json-tree'
import HeaderSearch from '../../search/index'
import Http from '../../../utils/http'
import './index.css'
import moment from 'moment'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'

let format = 'DDMMMYY HH:mm:ss'
// let dateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
const { Title } = Typography

const PrettyPrintJson = ({ data }) => (<div><pre>{JSON.stringify(data, null, 2)}</pre></div>)
class AuditTrail extends Component {
  constructor() {
    super()
    this.state = {
      displayData: [],
      loading: false,
      visible: false,
      record: null,
      search: '',
      user_ids_list: [],
      new_ids_list: [],
      users: [],
      disp_audit_error: true,
      timezone: getCookie(COOKIE.TIMEZONE),
      station: getCookie(COOKIE.STATION),
      ext: timezone.find((item) => item.value === getCookie(COOKIE.STATION))?.ext || 'EST',
      role: getCookie(COOKIE.ROLE),
    }
  }
  //CDM
  componentDidMount() {
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
  }
  //FETCH USERS LIST
  fetchUsers = async (count = -1) => {
    count = count + 1
    let user_ids = this.state.new_ids_list
    let user_list = this.state.users
    let data = this.state.displayData

    if (count < user_ids?.length) {
      let url = `/user/${user_ids[count]}`
      await Http.get(url, { headers: '' }).then(resp => {
        if (resp?.data?.data) {
          user_list.push(resp?.data?.data)
          this.setState({ users: user_list }, () => this.fetchUsers(count))
        }
      }).catch(() => {
        this.setState({ loading: false }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
        })
      })
    } else {
      if (user_list?.length > 0) {
        data?.map((item) => {
          if (item?.status?.user_id) {
            let index = user_list?.findIndex((p) => p.id === parseInt(item?.status?.user_id))
            if (index > -1) {
              item.trigger = `${user_list[index]?.name?.first_name} ${user_list[index]?.name?.last_name}`
            }
          }
        })
        this.sort(data)
        this.setState({
          displayData: data,
          loading: false,
        })
      } else {
        this.setState({ loading: false })
      }
    }
  }
  //FETCH AUDIT TRAIL BY AWB ID
  fetchAuditTrailByAWBNo = async () => {
    let data = this.state.displayData
    let ids = this.state.user_ids_list
    let new_ids = []
    let url = `/audit_awb?awb_no=${this.state.search}`
    let headers = { station: this.state.station }
    await Http.get(url, { headers }).then(resp => {
      if (resp?.data?.data?.length > 0) {
        resp?.data?.data.map((item) => {
          if (item?.locations?.length > 0) {
            item.locations.map((loc) => {
              loc.type = 'LOCATION CHANGE'
              loc.message = `Moved from ${loc?.fromLocation} to ${loc?.toLocation}.`
              loc.trigger = 'System Triggered'
              loc.createdAt = loc?.date
              data.push(loc)
            })
          }
          if (item?.goods_infos_histories?.length > 0) {
            item.goods_infos_histories.map((info) => {
              info.type = info?.event_type?.toUpperCase()
              info.message = info?.status?.msg ? info?.status?.msg : info?.event_name
              info.trigger = 'System Triggered'
              data.push(info)
              //CHECK IF USER ID PRESENT
              if (info?.status?.user_id && !ids?.includes(info?.status?.user_id)) {
                new_ids.push(info?.status?.user_id)
                ids.push(info?.status?.user_id)
              }
            })
          }
        })
        this.sort(data)
        this.setState({ displayData: [] }, () => this.setState({
          displayData: data,
          user_ids_list: ids,
          new_ids_list: new_ids
        }, () => this.fetchUsers()))
      } else {
        this.setState({ loading: false }, () => {
          if (this.state.disp_audit_error) {
            notification.destroy()
            notification.error({
              message: 'Failed',
              // description: resp?.data?.message,
              description: 'No records found',
            })
          }
        })
      }
    }).catch(() => {
      this.setState({ loading: false }, () => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
      })
    })
  }
  //FETCH MESSAGE LOGS
  fetchData = async () => {
    this.setState({ loading: true })
    let headers = { station: this.state.station }
    let url = `gatekeeper/search?search=${this.state.search}`
    await Http.get(url, { headers }).then(resp => {
      if (resp?.data?.data?.length > 0) {
        let data = resp?.data?.data.map((item) => {
          let info = this.generateInfo(item)
          return {
            ...item,
            trigger: 'System Triggered',
            info: info,
          }
        })
        this.setState({
          displayData: data,
          disp_audit_error: false
        }, () => this.fetchAuditTrailByAWBNo())
      } else {
        this.setState({ disp_audit_error: true }, () => this.fetchDataByOldUrl())
        //this.fetchAuditTrailByAWBNo()
      }
    }).catch(() => {
      this.setState({
        displayData: [],
        loading: false,
      })
    })
  }
  //GENERATE INFO OBJECT
  generateInfo = (data) => {
    let body = data?.msg
    let r = {}
    if (data?.type == 'ECIBOOK') {
      r.awb_no = body?.awbinfo?.awb || ''
      r.db_key = body?.awbinfo?.keyinfo?.dbkey || ''
      r.revision = body?.awbinfo?.keyinfo?.revision || null
      body?.bkginfo?.segs?.seg?.map(s => {
        if (s?.origin?.station == 'ATL' || s?.dest?.station == 'ATL' ||
          s?.origin?.station == 'LAX' || s?.dest?.station == 'LAX' ||
          s?.origin?.station == 'BOS' || s?.dest?.station == 'BOS' ||
          s?.origin?.station == 'JFK' || s?.dest?.station == 'JFK' ||
          s?.origin?.station == 'SEA' || s?.dest?.station == 'SEA' ||
          s?.origin?.station == 'DTW' || s?.dest?.station == 'DTW' ||
          s?.origin?.station == 'MSP' || s?.dest?.station == 'MSP' ||
          s?.origin?.station == 'SLC' || s?.dest?.station == 'SLC' ||
          s?.origin?.station == 'ORD' || s?.dest?.station == 'ORD' ||
          s?.origin?.station == 'HNL' || s?.dest?.station == 'HNL' ||
          s?.origin?.station == 'SEA' || s?.dest?.station == 'SEA') {
          r.origin = s?.origin?.station
          r.dest = s?.dest?.station
          r.flight_no = s?.fltinfo?.flight + s?.fltinfo?.date
          r.pcs = s?.space?.pcs
          r.wgt = s?.space?.wgt
          r.status = s?.status?.fct
          r.createdAt = moment.tz(body?.awbinfo?.datetime?.creation?.date?.content + ' ' + body?.awbinfo?.datetime?.creation?.time, 'DDMMMYY HHmm', this.state.timezone).utc().format(format) + ' UTC'
        }
      })
    } else if (data?.type == 'MANIFEST') {
      r = []
      let org_stn = body?.logisticsTransportMovement?.departureEvent?.occurrenceDepartureLocation?.id?.value
      data.org_stn = org_stn
      body?.arrivalEvent?.associatedTransportCargo?.map(uld => {
        uld?.includedMasterConsignment?.map(awb => {
          let d = {}
          if (awb?.transportContractDocument?.id?.value == this.state.search) {
            d.issueDateTime = body?.messageHeaderDocument?.issueDateTime ? moment.utc(body?.messageHeaderDocument?.issueDateTime).format(format) + ' UTC' : ''
            d.uld_no = uld?.typeCode?.value == 'BLK' ? 'bulk' : uld?.utilizedUnitLoadTransportEquipment?.characteristicCode?.value + uld?.utilizedUnitLoadTransportEquipment?.id?.value + uld?.utilizedUnitLoadTransportEquipment?.operatingParty?.primaryID?.value
            d.awb_no = awb?.transportContractDocument?.id?.value
            d.totalPieceQuantity = awb?.totalPieceQuantity?.value
            d.grossWeightMeasureValue = awb?.grossWeightMeasure?.value
            d.grossWeightMeasureUnit = awb?.grossWeightMeasure?.unitCode
            d.grossVolumeMeasureValue = awb?.grossVolumeMeasure?.value
            d.grossVolumeMeasureUnit = awb?.grossVolumeMeasure?.unitCode
            r.push(d)
          }
        })
      })
    } else if (data?.type == 'FSU/RCF' || data?.type == 'FSU/FPS' || data?.type == 'FSU/NFD' || data?.type == 'FSU/RCS' || data?.type == 'FSU/FOH' || data?.type == 'FSU/PRE' || data?.type == 'FSU/RCT' || data?.type == 'FSU/DLV' || data?.type == 'FSU/DIS') {
      let reportedStatus = body?.masterConsignment?.reportedStatus[0]
      let uldData = reportedStatus?.associatedStatusConsignment[0]?.utilizedUnitLoadTransportEquipment?.[0] || {}
      let uld_no = uldData?.characteristicCode?.value + uldData?.id?.value + uldData?.operatingParty?.primaryID?.value || null
      let fromLocation = reportedStatus?.associatedStatusConsignment[0]?.handlingInstructions?.fromLocation || 'UNKNOWN'
      let toLocation = reportedStatus?.associatedStatusConsignment[0]?.handlingInstructions?.toLocation || 'UNKNOWN'
      let spl = reportedStatus?.associatedStatusConsignment[0]?.handlingInstructions?.splCodes?.toString() || ''
      let dept = reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.departureEvent?.departureOccurrenceDateTime
      r.issueDateTime = body?.messageHeaderDocument?.issueDateTime ? moment.utc(body?.messageHeaderDocument?.issueDateTime).format(format) + ' UTC' : ''
      r.occurrenceDateTime = reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement[0]?.specifiedEvent?.occurrenceDateTime ? moment(reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement[0]?.specifiedEvent?.occurrenceDateTime).utc().format('YYYY-MM-DD HH:mm:ss') + ' L' : ''
      if (uld_no) {
        r.uld_no = uld_no
      }
      r.awb_no = body?.businessHeaderDocument?.id?.value
      r.pieceQuantity = reportedStatus?.associatedStatusConsignment[0]?.pieceQuantity?.value
      r.grossMeasureWeight = reportedStatus?.associatedStatusConsignment[0]?.grossWeightMeasure?.value
      r.grossMeasureUnit = reportedStatus?.associatedStatusConsignment[0]?.grossWeightMeasure?.unitCode
      r.spl = spl
      if (data?.type !== 'FSU/NFD') {
        r.specifiedLogisticsTransportMovement = data?.type == 'FSU/RCF' ? reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value + '/' + (dept ? moment(dept).utc().format('DDMMMYY HH:mm:ss') + ' L' : 'null') : ''
        r.fromLocation = fromLocation
        r.toLocation = toLocation
      }
      if (data?.type === 'FSU/DIS') {
        r.flight_no = reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value + '/' + (dept ? moment(dept).utc().format('DDMMMYY HH:mm:ss') + ' L' : 'null')
      }
    } else if (data?.type == 'FSU/MAN') {
      let reportedStatus = body?.masterConsignment?.reportedStatus[0]
      let uldData = reportedStatus?.associatedStatusConsignment[0]?.utilizedUnitLoadTransportEquipment?.[0] || {}
      let uld_no = uldData?.characteristicCode?.value + uldData?.id?.value + uldData?.operatingParty?.primaryID?.value || null
      let spl = reportedStatus?.associatedStatusConsignment[0]?.handlingInstructions?.splCodes?.toString()
      let dept = reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.departureEvent?.departureOccurrenceDateTime
      r.issueDateTime = body?.messageHeaderDocument?.issueDateTime ? moment.utc(body?.messageHeaderDocument?.issueDateTime).format(format) + ' UTC' : ''
      if (uld_no) {
        r.uld_no = uld_no
      }
      r.awb_no = body?.businessHeaderDocument?.id?.value
      r.pieceQuantity = reportedStatus?.associatedStatusConsignment[0]?.pieceQuantity?.value
      r.grossWeightMeasureValue = reportedStatus?.associatedStatusConsignment[0]?.grossWeightMeasure?.value
      r.grossWeightMeasureUnit = reportedStatus?.associatedStatusConsignment[0]?.grossWeightMeasure?.unitCode
      r.spl = spl
      r.flight_no = reportedStatus?.associatedStatusConsignment[0]?.specifiedLogisticsTransportMovement?.[0]?.id?.value + '/' + (dept ? moment(dept).utc().format('DDMMMYY HH:mm:ss') + ' L' : 'null')
    } else {
      r.createdAt = data?.createdAt
      r.type = data?.type
      r.err = data?.log?.err
    }
    return r
  }
  //FETCH MESSAGE LOGS WITH OLD URL
  fetchDataByOldUrl = async () => {
    let url = `/gatekeeper?search=${this.state.search}&stn=true`
    let headers = { station: this.state.station }
    await Http.get(url, { headers }).then(resp => {
      if (resp?.data?.data?.length > 0) {
        let data = resp?.data?.data.map((item) => {
          let info = this.generateInfo(item)
          return {
            ...item,
            trigger: 'System Triggered',
            info: info,
          }
        })
        this.setState({
          displayData: data,
          disp_audit_error: false
        }, () => this.fetchAuditTrailByAWBNo())
      } else {
        this.setState({ disp_audit_error: true }, () => this.fetchAuditTrailByAWBNo())
      }
    }).catch(() => {
      this.setState({
        displayData: [],
        loading: false,
      })
    })
  }
  //SORT
  sort = (array) => {
    array && array.sort(function (a, b) {
      // Compare the fields
      if (moment(a?.createdAt).valueOf() > moment(b?.createdAt).valueOf()) return -1
      if (moment(a?.createdAt).valueOf() < moment(b?.createdAt).valueOf()) return 1
      return 0
    })
  }
  getSearchResults = (URL) => {
    if (URL) {
      URL = URL.split('=')[1]
      this.setState({
        search: URL,
        displayData: [],
      }, () => this.fetchData())
    } else this.setState({ displayData: [] })
  }

  viewAsJSON = () => {
    return (
      <Modal visible={this.state.visible} width={1000} onCancel={() => this.setState({ visible: false })} footer={null}>
        <PrettyPrintJson data={this.state.record} />
      </Modal>
    )
  }

  // FETCH MESSAGE
  fetchMessage = async (type, id, awb) => {
    let url = ''
    if (type === 'ECIBOOK' || type.includes('FSU')) {
      url = `/replay/msg?awb=${awb}&id=${id}`
    }
    else {
      url = `/replay/msg?id=${id}`
    }
    await Http.get(url).then(resp => {
      if (resp && resp?.status === 200) {
        // eslint-disable-next-line no-console
        console.log('')
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
  }

  render() {
    let { displayData, loading } = this.state
    const columns = [
      {
        title: 'Type & Timestamp',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '10%',
        className: 'space-nowrap',
        render: (_, record) => (
          <>
            <span>{record?.type === 'MANIFEST' && record?.org_stn === this.state.station ? 'OUT MANIFEST' : record?.type}</span>
            <br />
            <span>{record?.createdAt ? moment(record.createdAt).format(format) + ' UTC' : 'N/A'}</span>
            <br />
            <span>{record?.createdAt ? moment.utc(record.createdAt).tz(this.state.timezone).format(format) + ` ${this.state.ext}` : 'N/A'}</span>
          </>
        )
      },
      {
        title: 'Event',
        dataIndex: '',
        key: 'message',
        width: '30%',
        render: (_, record) => (
          <>
            {record?.msg ? <JSONTree data={record?.msg} theme={jsonTheme} invertTheme={true} className='json-tree' /> : record?.message}
          </>
        )
      },
      {
        title: 'Info',
        dataIndex: 'info',
        key: 'info',
        width: '30%',
        className: 'min-width-200',
        render: (_, record) => (
          <>
            {record?.info ? <JSONTree data={record?.info} theme={jsonTheme} invertTheme={true} className='json-tree' /> : ''}
          </>
        )
      },
      {
        title: 'User/System Triggered',
        dataIndex: 'trigger',
        key: 'trigger',
        width: '30%',
      },
      {
        title: 'Message Data',
        dataIndex: 'action',
        key: 'action',
        width: '5%',
        className: 'space-nowrap',
        render: (_, record) => (
          <>
            {record?.msg && <>
              <Button type='link' className='link-btn' onClick={() => {
                this.setState({
                  visible: true,
                  record: record?.msg
                })
              }}>View as JSON</Button>
              {!record?.log && this.state.role === 'Super Admin' ?
                <Button shape='circle' icon={<RedoOutlined />} type='text'
                  onClick={() => {
                    let awb = null
                    if (record.type?.includes('FSU')) {
                      awb = record.msg.businessHeaderDocument.id.value
                    }
                    if (record.type == 'ECIBOOK') {
                      awb = record.msg.awbinfo.awb?.slice(0, 3) + '-' + record.msg.awbinfo.awb?.slice(3)
                    }
                    this.fetchMessage(record.type, record.id, awb)
                  }}
                />
                : ''}
            </>}
          </>
        )
      },
    ]

    return (
      <div>
        {this.state.visible && this.viewAsJSON()}
        {loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 24,
              lg: 24,
            },
            {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
            },
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
            <Card className='custom-card h-100'>
              <Row
                gutter={[
                  {
                    xs: 0,
                    sm: 0,
                    md: 24,
                    lg: 24,
                  },
                  {
                    xs: 24,
                    sm: 24,
                    md: 24,
                    lg: 24,
                  },
                ]}
                className='with-table'
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw header-messages'>
                  <Title level={5} className='mb-0'>Search Messages</Title>
                  <HeaderSearch placeholder='Search' className='position-static' searchResults={this.getSearchResults} page='GATEKEEPER' />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className='table-overflow'
                >
                  <div className='table-outer'>
                    <Table
                      className='custom-table msg-table td-top'
                      dataSource={!loading ? displayData : []}
                      columns={columns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default AuditTrail