import React, { Component } from 'react'
import { Row, Col, Card, Typography } from 'antd'
import { jsonTheme } from '../../../constants/constants'
import JSONTree from 'react-json-tree'
import { Map } from 'immutable'
import './index.css'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'

const { Title } = Typography
const json = {
  array: [1, 2, 3],
  bool: true,
  object: { foo: 'bar', },
  immutable: Map({ key: 'value' }),
}

class PerformanceInfo extends Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
  }

  render() {


    return(
      <div>
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 24,
              lg: 24,
            },
            {
              xs: 24,
              sm: 24,
              md: 24,
              lg: 24,
            },
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
            <Card className='custom-card h-100'>
              <Row
                gutter={[
                  {
                    xs: 0,
                    sm: 0,
                    md: 24,
                    lg: 24,
                  },
                  {
                    xs: 24,
                    sm: 24,
                    md: 24,
                    lg: 24,
                  },
                ]}
                className='with-table'
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>
                    <span>Performance Info</span>
                  </Title>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className='json-card-container'
                >
                  <Card title={''} className='json-card'>
                    <div>
                      <h3>FSU/RCF</h3>
                      <small>02Feb22 11:16</small>
                    </div>
                    <JSONTree data={json} theme={jsonTheme} invertTheme={true} className='json-tree' />
                  </Card>
                  <Card title={''} className='json-card'>
                    <div>
                      <h3>FSU/RCF</h3>
                      <small>02Feb22 11:16</small>
                    </div>
                    <JSONTree data={json} theme={jsonTheme} invertTheme={true} className='json-tree' />
                  </Card>
                  <Card title={''} className='json-card'>
                    <div>
                      <h3>FSU/RCF</h3>
                      <small>02Feb22 11:16</small>
                    </div>
                    <JSONTree data={json} theme={jsonTheme} invertTheme={true} className='json-tree' />
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default PerformanceInfo