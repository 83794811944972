import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import 'antd/dist/antd.min.css'
import './App.css'
// import { CTimeOut } from '../services/api/constants'

import { COOKIE, deleteCookie, getCookie } from './utils/cookie'
// import * as URLS from './constants.js'

// import * as JWT from "jwt-decode";

/**
 *
 * @param {Object} Route parameters
 * @description Route parameters must contain layout, component and other props. Layout has to be the master oage.
 */
export default ({ layout, component, ...rest }) => {
  let OTP_COOKIE = getCookie(COOKIE.OTP)
  let STATION_COOKIE = getCookie(COOKIE.STATION)
  let ROLE_COOKIE = getCookie(COOKIE.ROLE)
  if ((!OTP_COOKIE || !STATION_COOKIE || !ROLE_COOKIE) && !window.location.pathname.includes('dashboard')) {
    deleteCookie(COOKIE.OTP)
    deleteCookie(COOKIE.STATION)
    deleteCookie(COOKIE.TIMEZONE)
    deleteCookie(COOKIE.ROLE)
    deleteCookie(COOKIE.JOB_TITLE)
    deleteCookie(COOKIE.NAME)
    deleteCookie(COOKIE.USERNAME)
    deleteCookie(COOKIE.WEIGHT)
    deleteCookie(COOKIE.ID)
    deleteCookie(COOKIE.PPR)
    deleteCookie(COOKIE.EXPIRY)
    deleteCookie(COOKIE.LOGINTYPE)
    deleteCookie(COOKIE.LOGINPAGE)

    return <Redirect to={'/dashboard'} />
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        React.createElement(
          layout,
          {
            ...props,
            ...rest
          },
          React.createElement(component, {
            ...props,
            ...rest
          })
        )
      }
    />
  )
}
