/* eslint-disable no-console */
import React, { Component } from 'react'
import { Input, Row, Col, Typography, Form, Card, Table, Button, Tooltip, Popconfirm, notification, Spin, Select, Modal } from 'antd'
import addIcon from '../../assets/images/add.svg'
import editIcon from '../../assets/images/edit.svg'
import deleteIcon from '../../assets/images/delete.svg'
import Update from '../../assets/images/input-tick.svg'
import keyIcon from '../../assets/images/key.svg'
import copyIcon from '../../assets/images/cloud-down-arrow.svg'
import copyClipboardIcon from '../../assets/images/copy-clipboard.svg'
import cssconstants from '../../constants/cssconstants'
import constants from '../../constants/constants'
import Http from '../../utils/http'
import './index.css'
import { pageHandler, getCookie, COOKIE } from '../../utils/cookie'
import Cookies from 'universal-cookie'
import { cloneDeep } from 'lodash'

const { Title } = Typography
const { Search } = Input
const { Option } = Select
let cookies = new Cookies()
const station = cookies.get('station')

class ManageUsers extends Component {
  constructor() {
    super()
    this.state = {
      userList: [],
      is_edit: false,
      loading: false,
      search: '',

      password: '',
      managePassUser: null,
      showManagePassword: false,

      urlUserid: null,
      userURLid: null,
      userURLData: null,
      showURLModal: false,
      editDesctiption: '',
      
      showPrint: false,
      printData: null,
      userData: {},

      station: getCookie(COOKIE.STATION) || '',
      department: '',
      title: '',
      role: '',
      ppr: '',
      employee_id: '',
      name: '',
      division: '',
      email: '',

      editRecord: null,
      editStation: '',
      editDepartment: '',
      editTitle: '',
      editRole: '',
      editPpr: '',
      editEmployeeId: '',
      editName: '',
      editDivision: '',
      editEmail: '',
    }
  }
  componentDidMount() {
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    this.fetchUsers()
  }

  fetchUsers = async () => {
    let headers = {
      station: station,
      search: this.state.search
    }
    await Http.get('users', { headers }).then(resp => {
      if (resp && resp?.data && resp?.data?.data?.length > 0) {
        if(this.state.urlUserid){
          let record = resp?.data?.data?.find((item)=> item.id === this.state.urlUserid)
          if(record){
            this.setUserURLData(record)
          }
        }
        this.setState({ userList: resp?.data?.data })
      } else {
        this.setState({ userList: [] }, () => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: resp?.data?.message,
          })
        })
      }
    })
  }

  addUser = async () => {
    let formdata = new FormData()
    formdata.append('first_name', this.state.is_edit ? this.state.editName?.split(' ')[0] || '' : this.state.name?.split(' ')[0] || '')
    formdata.append('last_name', this.state.is_edit ? this.state.editName?.split(' ')[1] || '' : this.state.name?.split(' ')[1] || '')
    formdata.append('username', this.state.is_edit ? this.state.editEmail || '' : this.state.email || '')
    formdata.append('job_title', this.state.is_edit ? this.state.editTitle || '' : this.state.title || '')
    formdata.append('role', this.state.is_edit ? this.state.editRole || '' : this.state.role || '')
    formdata.append('station', this.state.is_edit ? this.state.editStation || '' : this.state.station || '')
    formdata.append('department', this.state.is_edit ? this.state.editDepartment || '' : this.state.department || '')
    formdata.append('division_code', this.state.is_edit ? this.state.editDivision || '' : this.state.division || '')
    formdata.append('employeeid', this.state.is_edit ? this.state.editEmployeeId || '' : this.state.employee_id || '')
    formdata.append('ppr', this.state.is_edit ? this.state.editPpr || '' : this.state.ppr || '')
    this.setState({ loading: true })
    let url = this.state.is_edit ? `/user/${this.state.editRecord?.id}` : '/user_add'
    let method = this.state.is_edit ? 'put' : 'post'
    await Http[method](url, formdata)
      .then((data) => {
        if (data && data?.status === 200) {
          this.fetchUsers()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
          if(this.state.is_edit) this.clearTableForm()
          else this.clearForm()
        } else {
          this.setState({ duplicate: data?.data?.data }, () => setTimeout(() => { this.setState({ duplicate: null }) }, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
        this.setState({ loading: false })
      })
  }

  deleteUser = async (id) => {
    await Http.put(`/user/${id}`, { is_delete: true })
      .then((data) => {
        if (data && data?.status === 200) {
          this.fetchUsers()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
          this.clearForm()
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
  }

  clearForm = () => {
    this.setState({
      is_edit: false,
      station: getCookie(COOKIE.STATION) || '',
      name: '',
      email: '',
      title: '',
      department: '',
      division: '',
      employee_id: '',
      ppr: '',
    })
  }

  editTableForm = (record) => {
    this.setState({
      is_edit: true,
      editRecord: record,
      editStation: record?.station,
      editName: record?.name?.first_name ? `${record?.name?.first_name} ${record?.name?.last_name}` : '',
      editTitle: record?.job_title,
      editRole: record?.role,
      editDepartment: record?.department,
      editEmployeeId: record?.preferred_username,
      editPpr: record?.ppr,
      editEmail: record?.username,
      editDivision: record?.division_code
    })
  }

  clearTableForm = () => {
    this.setState({
      editRecord: null,
      is_edit: false,
      editStation: '',
      editName: '',
      editEmail: '',
      editTitle: '',
      editRole: '',
      editDepartment: '',
      editDivision: '',
      editEmployeeId: '',
      editPpr: '',
    })
  }

  setSearchValue (e){
    this.setState({ search: e.target.value },() => {
      if(!e.target.value || e.target.value === null || e.target.value === '') {
        this.fetchUsers()
      }
    })
  }

  check = (ID) => {
    if(ID) {
      if(this.state.is_edit) {
        this.setState({ is_edit: false }, () => {
          this.clearTableForm()
          this.deleteUser(ID)
        })
      } else this.deleteUser(ID)
    }
    else if(this.state.is_edit) {
      this.setState({ is_edit: false }, () => {
        this.clearTableForm()
        this.addUser()
      })
    } else this.addUser()
  }

  change_password = async (record) => {
    if(this.state.password && this.state.password.trim().length >= 8) {
      this.setState({ userData: record })
      let formdata = { password: this.state.password }
      await Http.put(`/set_password/${record.id}`,formdata)
        .then((data) => {
          if (data && data?.status === 200) {
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })
            this.setState({ 
              showPrint: true,
              printData: data?.data?.data,
              password: '',
              managePassUser: null,
              showManagePassword: false,
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
        })
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: 'Password must be 8 characters long',
      })
    }
  }
  setUserURLData = async (record) => {
    this.setState({
      urlUserid: record?.id,
      userURLid: record?.page_filters?.id,
      userURLData: [
        // breakdown_filter: record.page_filters?.breakdown_filter,
        {
          data: record.page_filters?.warehouse_filter || null,
          type: 'BRKDWN_DSH',
          page: 'Breakdown Dashbaord',
          // description: record?.page_filters?.warehouse_filter?.description || '',
          id: record?.page_filters?.id || ''
        },
        {
          data: record.page_filters?.recoverydash_filter || null,
          type: 'REC_DSH',
          page: 'Recovery Dashbaord',
          // description: record?.page_filters?.recoverydash_filter?.description || '',
          id: record?.page_filters?.id || ''
        },
        {
          data: record.page_filters?.buildupdash_filter || null,
          type: 'BLDUP_DSH',
          page: 'Buildup Dashbaord',
          // description: record?.page_filters?.buildupdash_filter?.description || '',
          id: record?.page_filters?.id || ''
        },
        // buildupcontrol_filter: record.page_filters?.buildupcontrol_filter,
        {
          data: record.page_filters?.haulout_filter || null,
          type: 'HLT_DSH',
          page: 'Haulout Dashbaord',
          // description: record?.page_filters?.haulout_filter?.description || '',
          id: record?.page_filters?.id || ''
        },
      ],
      showURLModal: true
    })
  }
  //SAVE PAGER FILTER DESCRIPTION
  updateDesctiption = async () => {
    let formdata = {}
    if(this.state.editURLRecord.type === 'BRKDWN_DSH'){
      let warehouse_filter = cloneDeep(this.state.editURLRecord?.data)
      warehouse_filter[this.state.station].description = this.state.editDesctiption
      formdata = { warehouse_filter: warehouse_filter }
    } else if( this.state.editURLRecord.type === 'REC_DSH'){
      let recoverydash_filter = cloneDeep(this.state.editURLRecord?.data)
      recoverydash_filter[this.state.station].description = this.state.editDesctiption
      formdata = { recoverydash_filter: recoverydash_filter }
    } else if( this.state.editURLRecord.type === 'BLDUP_DSH'){
      let buildupdash_filter = cloneDeep(this.state.editURLRecord?.data)
      buildupdash_filter[this.state.station].description = this.state.editDesctiption
      formdata = { buildupdash_filter: buildupdash_filter }
    } else if( this.state.editURLRecord.type === 'HLT_DSH'){
      let haulout_filter = cloneDeep(this.state.editURLRecord?.data)
      haulout_filter[this.state.station].description = this.state.editDesctiption
      formdata = { haulout_filter: haulout_filter }
    }
    await Http.put(`/page_filter/${this.state.urlUserid}`, formdata).then(async (data) => {
      if (data && data?.status === 200) {
        this.setState({ 
          editURLRecord: null,
          editDesctiption: '', 
        },()=>this.fetchUsers())
        notification.destroy()
        notification.success({
          message: 'Success',
          description: data?.data?.message,
        })
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: data?.data?.message,
        })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ servererror : true })
    })
  }
  getPageurl = async (record) => {
    const currentURL = new URL(window.location.href)
    const baseURL = currentURL.origin // This will give you the base URL
    let url = `${baseURL}/dashboard-redirect/${this.state.station}-${record?.type}-${record?.id}`

    navigator.clipboard.writeText(url)
    notification.destroy()
    notification.success({
      message: 'Success',
      description: 'URL copied to clipboard',
    })

  }
  render() {
    const userColumns = [
      {
        title: 'Station',
        key: 'station',
        dataIndex: 'station',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editStation}
              disabled
              onChange={(e) => this.setState({ editStation: e.target.value.toUpperCase() })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.station}</span>)
      },
      {
        title: 'Department',
        key: 'department',
        dataIndex: 'department',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editDepartment}
              onChange={(e) => this.setState({ editDepartment: e.target.value })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.department}</span>)
      },
      {
        title: 'Role',
        key: 'role',
        dataIndex: 'role',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Select size='small'
              placeholder='Select role'
              onChange={(e)=>this.setState({ editRole:e })}
              value={this.state.editRole}
              className='w-100'
            >
              <Option value='Super Admin'>Super Admin</Option>
              <Option value='Station Leader'>Station Leader</Option>
              <Option value='Re-booking Team'>Re-booking Team</Option>
              <Option value='User'>User</Option>
              <Option value='DOD Agent'>DOD Agent</Option>
              <Option value='Warehouse TV'>Warehouse TV</Option>
            </Select>
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.role}</span>)
      },
      {
        title: 'Job Title',
        key: 'job_title',
        dataIndex: 'job_title',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTitle}
              onChange={(e) => this.setState({ editTitle: e.target.value })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.job_title}</span>)
      },
      {
        title: 'PPR (9 digits)',
        key: 'ppr',
        dataIndex: 'ppr',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editPpr}
              onChange={(e) => this.setState({ editPpr: e.target.value.toUpperCase() })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.ppr}</span>)
      },
      {
        title: 'Employee ID (6 characters)',
        key: 'employee_id ',
        dataIndex: 'employee_id',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editEmployeeId}
              onChange={(e) => this.setState({ editEmployeeId: e.target.value.toUpperCase() })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.preferred_username}</span>)
      },
      {
        title: 'First and Last Name',
        key: 'name',
        dataIndex: 'name',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editName}
              onChange={(e) => this.setState({ editName: e.target.value })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.name?.first_name ? `${record?.name?.first_name} ${record?.name?.last_name}` : ''}</span>)
      },
      {
        title: 'Division Code',
        key: 'division_code',
        dataIndex: 'division_code',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editDivision}
              onChange={(e) => this.setState({ editDivision: e.target.value.toUpperCase() })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.division_code}</span>)
      },
      {
        title: 'Email Address',
        key: 'email',
        dataIndex: 'email',
        render: (_, record) => (
          this.state.editRecord && this.state.editRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editEmail}
              onChange={(e) => this.setState({ editEmail: e.target.value.trim() })}
            />
            : <span className={record.id === this.state.duplicate?.id ? 'red-border' : ''}>{record?.username}</span>)
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, record) =>
          <div>
            {this.state.editRecord && this.state.editRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.addUser()} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearTableForm()} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column user-action'>
                {
                  record?.page_filters?.id && record?.role === 'Warehouse TV' ? 
                    <Tooltip title='Get Dashboard URL' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={copyIcon} alt='get dashboard url' className='geturl-icon'
                      onClick={ ()=> this.setUserURLData(record) } 
                    /></Tooltip>
                    : null
                }
                {
                  record?.role === 'Warehouse TV' ? 
                    <Tooltip title='Set Password' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                      <img src={keyIcon} alt='set password' className='key-icon' onClick={ ()=> this.setState({ 
                        managePassUser: record,
                        showManagePassword: true
                      }) } />
                    </Tooltip>
                    : null
                }
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=> this.editTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.check(record.id)} okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]
    const urlColumns = [
      {
        title: 'Page',
        key: 'page',
        dataIndex: 'station',
        render: (_, record) => (
          <span >{record?.page}</span>)
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
        render: (_, record) => (
          this.state.editURLRecord && this.state.editURLRecord.type === record.type ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editDesctiption}
              onChange={(e) => this.setState({ editDesctiption: e.target.value })}
            />
            : <span>{record?.data?.[this.state.station]?.description}</span>)
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text, record) =>
          <div>
            {this.state.editURLRecord && this.state.editURLRecord.type === record.type ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.updateDesctiption() } ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} 
                  onClick={() => this.setState({ 
                    editDesctiption: '',
                    editURLRecord: null
                  }) } ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column user-action'>
                {
                  record?.role === 'Warehouse TV' ? 
                    <Tooltip title='Set Password' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={keyIcon} alt='set password'  className='key-icon' onClick={ ()=> this.setState({ 
                      managePassUser: record,
                      showManagePassword: true
                    }) } /></Tooltip>
                    : null
                }
                {
                  record?.page_filters?.id ? 
                    <Tooltip title='Get Dashboard URL' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={copyIcon} alt='get dashboard url' className='geturl-icon' onClick={ ()=> this.setState({ 
                      userURLid: record?.page_filters?.id,
                      userURLData: {
                        // breakdown_filter: record.page_filters?.breakdown_filter,
                        warehouse_filter: record.page_filters?.warehouse_filter,
                        recoverydash_filter: record.page_filters?.recoverydash_filter,
                        buildupdash_filter: record.page_filters?.buildupdash_filter,
                        // buildupcontrol_filter: record.page_filters?.buildupcontrol_filter,
                        haulout_filter: record.page_filters?.haulout_filter
                      },
                      showURLModal: true
                    }) } /></Tooltip>
                    : null
                }{
                  record?.data?.[this.state.station]?
                    <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} 
                      onClick={()=> this.setState({ 
                        editDesctiption: record?.data?.[this.state.station]?.description,
                        editURLRecord: record
                      })}  ><img src={editIcon} alt='edit' /></Tooltip> : null
                }
                <Tooltip title='Copy URL' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=> this.getPageurl(record) } >
                  <img src={copyClipboardIcon} alt='copy' className='geturl-icon copy-icon' />
                </Tooltip>
              </div>
            }
          </div>
      },
    ]

    return (
      <div>
        {this.state.loading && (<div id='spinner' className='spinner-cont'>
          <Spin />
        </div>)}
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} className='no-print'>
          <Col xs={24} sm={24} md={24} lg={24} className='no-print'>
            <Card className='custom-card with-table'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} className=''>
                  <Title level={5} className=''>Add Users</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} className='p-12'>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={2} className=''>
                        <Form.Item label='Station'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.station}
                            disabled
                            onChange={(e) => this.setState({ station: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={3} className=''>
                        <Form.Item label='Department'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.department}
                            onChange={(e) => this.setState({ department: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={3} className=''>
                        <Form.Item label='Role' required>
                          <Select size='small'
                            placeholder='Select'
                            onChange={(e)=>this.setState({ role:e })}
                            value={this.state.role}
                          >
                            <Option value='Super Admin'>Super Admin</Option>
                            <Option value='Station Leader'>Station Leader</Option>
                            <Option value='Re-booking Team'>Re-booking Team</Option>
                            <Option value='User'>User</Option>
                            <Option value='DOD Agent'>DOD Agent</Option>
                            <Option value='Warehouse TV'>Warehouse TV</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={3} className=''>
                        <Form.Item label='Job Title'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.title}
                            onChange={(e) => this.setState({ title: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={2} className=''>
                        <Form.Item label='PPR (9 digits)'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.ppr}
                            onChange={(e) => this.setState({ ppr: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={2} className=''>
                        <Form.Item label='Employee ID'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.employee_id}
                            onChange={(e) => this.setState({ employee_id: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={3} className=''>
                        <Form.Item label='First and Last Name'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={2} className=''>
                        <Form.Item label='Division Code'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.division}
                            onChange={(e) => this.setState({ division: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={4} xxl={3} className=''>
                        <Form.Item label='Email Address' required>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.email}
                            onChange={(e) => this.setState({ email: e.target.value.trim() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='image-btn' onClick={() => this.check(null)}>
                          <Tooltip title={'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            <Card className='custom-card'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw'>
                  <Title level={5} className='mb-0'>User List</Title>
                  <Search allowClear className='search-input' placeholder='Search'
                    onChange={(e) => this.setSearchValue(e)}
                    onSearch={(e) => !e || e === null || e ==='' ? null : this.fetchUsers()}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table manage-table'
                      dataSource={this.state.userList}
                      columns={userColumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          title= {'Manage Password'}
          open={this.state.showManagePassword}
          onCancel={()=> this.setState({ 
            showManagePassword: false,
            password: '',
          })}
          className='custom-modal pb-0 add-mcq'
          centered 
          footer={false}
          zIndex={1001}
          wrapClassName='print-mcq'
        >
          <Row>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                First Name:
                </Col>
                <Col item xs={12} className='gridData'>
                  {this.state.managePassUser?.name?.first_name} 
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                  Last Name:
                </Col>
                <Col item xs={12} className='gridData'>
                  {this.state.managePassUser?.name?.last_name} 
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                  Email/Username:
                </Col>
                <Col item xs={12} className='gridData'>
                  {this.state.managePassUser?.username} 
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                  Password:
                </Col>
                <Col item xs={12} className='gridData'>
                  <Input value= {this.state.password} onChange={(e) => this.setState({ password: e.target.value.trim() })} />
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                 Role:
                </Col>
                <Col item xs={12} className='gridData'>
                  {this.state.managePassUser?.role} 
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Button type='primary' className='custom-button mr-0 mt-4 no-print d-block ml-auto' onClick={() => this.change_password(this.state.managePassUser) }>Save</Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          title= {'Get Dashboard URL'}
          open={this.state.showURLModal}
          onCancel={()=> this.setState({ 
            showURLModal: false,
            userURLid: null,
            userURLData: null,
            urlUserid: null,
          })}
          className='custom-modal pb-0 add-mcq'
          centered 
          footer={false}
          zIndex={1001}
          wrapClassName='print-mcq'
        >
          <div className='table-outer'>
            <Table
              className='custom-table manage-table'
              dataSource={this.state.userURLData}
              columns={urlColumns}
              pagination={false}
            />
          </div>
        </Modal>
        <Modal
          title= {'Password changed successfully'}
          open={this.state.showPrint}
          onCancel={()=> this.setState({ 
            showPrint: false,
            printData: null 
          })}
          className='custom-modal pb-0 add-mcq'
          centered 
          footer={false}
          zIndex={1001}
          wrapClassName='print-mcq'
        >
          <Row>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                First Name:
                </Col>
                <Col item xs={12} className='gridData'>
                  {this.state.userData?.name?.first_name} 
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                  Last Name:
                </Col>
                <Col item xs={12} className='gridData'>
                  {this.state.userData?.name?.last_name} 
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                  Email/Username:
                </Col>
                <Col item xs={12} className='gridData'>
                  {this.state.userData?.username} 
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                  Password:
                </Col>
                <Col item xs={12} className='gridData'>
                  <b>{this.state.printData?.password}</b>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row className='gridContainer'>
                <Col item xs={12} className='titleColor'>
                 Role:
                </Col>
                <Col item xs={12} className='gridData'>
                  {this.state.userData?.role} 
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Button type='primary' className='custom-button mr-0 mt-4 no-print d-block ml-auto' onClick={() => window.print()}>Print</Button>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default ManageUsers
