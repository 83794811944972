import React, { Component } from 'react'
import { Row, Col, Card, Table, Button, Modal, Menu, Pagination, Timeline, Progress, Badge, Tooltip } from 'antd'
import JSONTree from 'react-json-tree'
import Http from '../../../utils/http'
import './index.css'
import { jsonTheme, timezone } from '../../../constants/constants'
import cssconstants from '../../../constants/cssconstants'
import moment from 'moment'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'

let time_format = 'HH:mm:ss'

const PrettyPrintJson = ({ data }) => (<div><pre>{JSON.stringify(data, null, 2) }</pre></div>)
class ProcessedMessagesBull extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      menudata: [],
      counts: null,
      pagination: 0,
      visible:false,
      record: null,
      event_type: getCookie(COOKIE.STATION)+'_FLIGHT_EVENT',
      station: getCookie(COOKIE.STATION),
      timezone: getCookie(COOKIE.TIMEZONE),
      ext: timezone.find((item) => item.value === getCookie(COOKIE.STATION))?.ext || 'EST',
      status: 'completed',
      page: 1,
      fetchInterval: null,
    }
  }

  componentDidMount() {
    pageHandler(window.location.pathname, getCookie(COOKIE.ROLE))
    this.fetchAllData()
    this.fetchData()
    var interval = setInterval(() => {
      this.fetchAllData()
      this.fetchData()
    }, 5000)
    this.setState({ fetchInterval: interval })
  }

  componentWillUnmount() {
    clearInterval(this.state.fetchInterval)
  }

  //FETCH HANDLING TIMES
  fetchData = async () => {
    let url = `/parser/queues/api/queues?activeQueue=${this.state.event_type}&status=${this.state.status}&page=${this.state.page}`
    await Http.get(url, { headers: '' }).then(resp => {
      if (resp?.data?.queues?.length > 0) {
        let data = resp?.data?.queues?.find((item) => item?.name?.toUpperCase() === this.state.event_type) || null
        if(data) {
          this.setState({
            data: data?.jobs,
            counts: data?.counts,
            pagination: data?.pagination?.pageCount * 10 || 0,
          })
        }
      }
    }).catch(() => {
      this.setState({ data: [] })
    })
  }
  fetchAllData = async () => {
    let ecibook_url = '/parser/queues/api/queues?activeQueue=ECIBOOK&status=latest&page=1'
    let fltevent_url = `/parser/queues/api/queues?activeQueue=${this.state.station+'_FLIGHT_EVENT'}&status=latest&page=1`
    let manifest_url = `/parser/queues/api/queues?activeQueue=${this.state.station+'_MANIFEST'}&status=latest&page=1`
    let fsu_url = `/parser/queues/api/queues?activeQueue=${this.state.station+'_FSU'}&status=latest&page=1`
    Promise.all([
      Http.get(ecibook_url, { headers: '' }),
      Http.get(fltevent_url, { headers: '' }),
      Http.get(manifest_url, { headers: '' }),
      Http.get(fsu_url, { headers: '' })
    ]).then(([ecibook_data, fltevent_data, manifest_data, fsu_data]) => {
      let edata = ecibook_data?.data?.queues?.find((item) => item?.name?.toUpperCase() === 'ECIBOOK').jobs || null
      let fedata = fltevent_data?.data?.queues?.find((item) => item?.name?.toUpperCase() === this.state.station+'_FLIGHT_EVENT').jobs || null
      let mandata = manifest_data?.data?.queues?.find((item) => item?.name?.toUpperCase() === this.state.station+'_MANIFEST').jobs || null
      let fsudata = fsu_data?.data?.queues?.find((item) => item?.name?.toUpperCase() === this.state.station+'_FSU').jobs || null
      let menudata = {}
      if(edata && edata.length>0){
        menudata['ECIBOOK'] = edata[0].timestamp
      }
      if(fedata && fedata.length>0){
        menudata[this.state.station+'_FLIGHT_EVENT'] = fedata[0].timestamp
      }
      if(mandata && mandata.length>0){
        menudata[this.state.station+'_MANIFEST'] = mandata[0].timestamp
      }
      if(fsudata && fsudata.length>0){
        menudata[this.state.station+'_FSU'] = fsudata[0].timestamp
      }
      this.setState({ menudata: menudata })
    }).catch(() => {
      this.setState({ menudata: [] })
    })
  }

  viewAsJSON = () => {
    return (
      <Modal visible={this.state.visible} width={1000} onCancel={() => this.setState({ visible: false })} footer={null}>
        <PrettyPrintJson data={this.state.record} />
      </Modal>
    )
  }

  renderTileInfo = (title, row) => {
    let menudata = this.state.menudata
    if(menudata && menudata[row]) {
      let diff = (moment().tz(this.state.timezone)).diff(moment(menudata[row]).tz(this.state.timezone),'minutes')
      if(diff > 60){
        let hours = Math.floor(diff / 60)
        let mins = Math.floor(diff % 60)
        let time =  ((hours.toString().length === 1 ? ('0'+hours) : hours)+'h '+( mins.toString().length === 1 ? ('0'+mins) :mins)+'m')
        //Red
        return (
          <span className='fv-middle gap-10'>
            {title}
            <Tooltip title={`No message received in the last ${time}`} overlayInnerStyle={{
              fontSize: cssconstants.Tooltipfs,
              width: 'max-content'
            }} placement='right'>
              <span className='badge-circle badge-circle-sm badge-red'></span>
            </Tooltip>
          </span>
        )
      }
      else {
        //GREEN
        return (
          <span className='fv-middle gap-10'>
            {title}
            {/* <Tooltip title='Time' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}> */}
            <span className='badge-circle badge-circle-sm badge-green'></span>
            {/* </Tooltip> */}
          </span>
        )
      }
    } else {
      //GREY
      return (
        <span className='fv-middle gap-10'>
          {title}
          {/* <Tooltip title='Time' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}> */}
          <span className='badge-circle badge-circle-sm badge-grey'></span>
          {/* </Tooltip> */}
        </span>
      )
    }
  }

  render() {
    let { data, counts, pagination } = this.state
    const columns = [
      {
        title: '',
        dataIndex: '',
        key: 'key',
        width: '25%',
        render: (_, record) => (
          <span>
            <p className='mess-no'>{record?.id ? `#${record.id}` : ''}</p>
            <Timeline mode='right' className='msg-timeline'>
              <Timeline.Item color='grey'><p className='mb-0'>Added at </p> {record?.timestamp ? moment(record?.timestamp).tz(this.state.timezone).format(time_format) + ` ${this.state.ext}` : 'N/A'}</Timeline.Item>
              <Timeline.Item color='grey'><p className='mb-0'>Process started at </p>{record?.processedOn ? moment(record?.processedOn).tz(this.state.timezone).format(time_format) + ` ${this.state.ext}` : 'N/A'}</Timeline.Item>
              <Timeline.Item color='grey'><p className='mb-0'>Finished at </p>{record?.finishedOn ? moment(record?.finishedOn).tz(this.state.timezone).format(time_format) + ` ${this.state.ext}` : 'N/A'}</Timeline.Item>
            </Timeline>
          </span>
        )
      },
      {
        title: 'Details',
        dataIndex: '',
        key: 'details',
        width: '85%',
        className:'json-tree-td min-width-200',
        render: (_, record) => (
          <div className='pt-5'>
            <JSONTree data={record?.data} theme={jsonTheme} invertTheme={true} className='json-tree' />
          </div>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        className: '',
        render: (_, record) => (
          <div className='pt-5'>
            <Button type='link' className='link-btn' onClick={() => {
              this.setState({
                visible: true,
                record: record?.data
              })
            }}>View as JSON</Button>
            <Progress type='circle' percent={record?.progress} width={30} />
          </div>
        )
      },
    ]

    return(
      <div>
        {this.state.visible && this.viewAsJSON()}
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
            <Card className='custom-card h-100 mess-card'>
              <Row
                gutter={[
                  {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 0,
                  },
                  {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 0,
                  },
                ]}
                className=''
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Row gutter={[24,0]}>
                    <Col flex='330px' className='lhs-gatekeeper'>
                      <Menu
                        mode='inline'
                        theme='dark'
                        className='slider-menu msg-menu'
                        width='200'
                        selectedKeys={[this.state.event_type]}
                        onClick={(e) => this.setState({
                          event_type: e?.key || '',
                          page: 1,
                        }, () => this.fetchData())}
                      >
                        <Menu.Item key={'ECIBOOK'} className='sider-menu-item'>{this.renderTileInfo('ECIBOOK','ECIBOOK')}</Menu.Item>
                        <Menu.Item key={this.state.station+'_FLIGHT_EVENT'} className='sider-menu-item'>{this.renderTileInfo('FLIGHT_EVENT',`${this.state.station+'_FLIGHT_EVENT'}`)}</Menu.Item>
                        <Menu.Item key={this.state.station+'_MANIFEST'} className='sider-menu-item'>{this.renderTileInfo('MANIFEST',`${this.state.station+'_MANIFEST'}`)}</Menu.Item>
                        <Menu.Item key={this.state.station+'_FSU'} className='sider-menu-item'>{this.renderTileInfo('FSU',`${this.state.station+'_FSU'}`)}</Menu.Item>
                      </Menu>
                    </Col>
                    <Col flex='auto' className='rhs-gatekeeper'>
                      <Menu
                        mode='horizontal'
                        className='h-msg-menu'
                        selectedKeys={[this.state.status]}
                        onClick={(item) => this.setState({
                          status: item?.key || '',
                          page: 1,
                        }, () => this.fetchData())}
                      >
                        <Menu.Item key='latest'>
                        LATEST
                        </Menu.Item>
                        <Menu.Item key='active'>
                        ACTIVE <Badge
                            count={counts?.active}
                            style={{ backgroundColor: '#0a1227', }}
                          />
                        </Menu.Item>
                        <Menu.Item key='waiting'>
                        WAITING <Badge
                            count={counts?.waiting}
                            style={{ backgroundColor: '#0a1227', }}
                          />
                        </Menu.Item>
                        <Menu.Item key='completed'>
                        COMPLETED <Badge
                            count={counts?.completed}
                            style={{ backgroundColor: '#0a1227', }}
                          />
                        </Menu.Item>
                      </Menu>
                      <Table
                        className='custom-table msg-table p-msgtbl td-top'
                        dataSource={data}
                        columns={columns}
                        pagination={false}
                      />
                      {data?.length > 0 && <Pagination className='pr-4 text-right my-4' total={pagination} current={this.state.page} onChange={(e) => this.setState({ page: e }, () => this.fetchData())} />}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className='table-overflow'
                >
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ProcessedMessagesBull
