import React, { Component } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Checkbox,
  InputNumber,
  Space,
  Button,
  Switch,
  Table,
  Tooltip,
  Popconfirm,
  Input,
  Form,
  Modal,
  Tag,
} from 'antd'
// import { Link } from 'react-router-dom'
// import Logo from '../../../../assets/images/logo.png'
// import { timezone, timezones, pagelist } from '../constants/constants'
// import './header.css'
import cssconstants from '../../../../constants/cssconstants'
import { constants } from '../../../../constants/constants'
import { SearchOutlined } from '@ant-design/icons'
import addIcon from '../../../../assets/images/add.svg'
import editIcon from '../../../../assets/images/edit.svg'
import deleteIcon from '../../../../assets/images/delete.svg'
import Update from '../../../../assets/images/input-tick.svg'
import ULDicon from '../../../../assets/images/uld_icon.png'
// import { ContainerOutlined } from '@ant-design/icons'
// import Cookies from 'universal-cookie'
// import { COOKIE, getCookie, logout, createCookie, deleteCookie } from '../utils/cookie'
// import moment from 'moment'
// import Notifications from '../pages/notifications/notifications'
// const { Header, Sider } = Layout
const { Title, Text } = Typography
// const cookies = new Cookies()
class BuildPlan extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      addULD: false,
      openPlanner: false  ,
      replacementModalVisible: false
    }
  }

  componentDidMount() {
    
  }

  hideReplacementModal = () => {
    this.setState({ replacementModalVisible: false })
  }
 
  // showReplacementModal = () => {
  //   this.setState({ replacementModalVisible: true })
  // }

  closeNotificationsModal = () => {
    this.setState({ notificationsOpen: false })
  }


  addULD() {
    this.setState({ addULD: true })
  }

  openWB = (value) => {
    this.setState({ openPlanner: value })
    // eslint-disable-next-line no-console
    console.log('clicked')
  }

  fetchReplacementLoad() {
    this.setState({ replacementModalVisible: true })
  }

  render() {
    const configData = [
      {
        key: '1',
        empty: 'Optimal Configuration',
        PMC: 2,
        PAG: 0,
        LD2: 5,
        LD3: 1,
        LD4: 0, 
        Hint: 'Test Hint' ,
        Notes: 'Test Notes' ,
      },
      {
        key: '2',
        empty: 'Final Configuration',
        PMC: 2,
        PAG: 0,
        LD2: 5,
        LD3: 1,
        LD4: 0, 
        Hint: 'Test Hint' ,
        Notes: 'Test Notes' ,
      }
    ]
    const configColumns = [
      {
        title: '',
        dataIndex: 'empty',
        key: 'empty',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  PMC </span>),
        dataIndex: 'PMC',
        key: 'PMC',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  PAG </span>),
        dataIndex: 'PAG',
        key: 'PAG',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  LD2 </span>),
        dataIndex: 'LD2',
        key: 'LD2',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  LD3 </span>),
        dataIndex: 'LD3',
        key: 'LD3',
        className: 'checklist-cols-1'
      },
      //   {
      //     title: (<span className='test'>  LD4/8/FQA </span>),
      //     dataIndex: 'LD4',
      //     key: 'LD4',
      //     className: 'checklist-cols-1'
      //   },
      {
        title: (<span className='test'>  Hint </span>),
        dataIndex: 'Hint',
        key: 'Hint',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Notes </span>),
        dataIndex: 'Notes',
        key: 'Notes',
        className: 'checklist-cols-1'
      },
    ]
    const awbColumns = [
      {
        title: (<span className='test'>  Location </span>),
        dataIndex: 'location',
        key: 'location',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  AWB </span>),
        dataIndex: 'awb',
        key: 'awb',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Comments </span>),
        dataIndex: 'comments',
        key: 'comments',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Pcs </span>),
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  GrWt.(lbs) </span>),
        dataIndex: 'grwt',
        key: 'grwt',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  ChWt.(lbs) </span>),
        dataIndex: 'chwt',
        key: 'chwt',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Vol.(cbf) </span>),
        dataIndex: 'vol',
        key: 'vol',
        className: 'checklist-cols-1'
      },
      // {
      //   title: (<span className='test'>  Rev.(usd) </span>),
      //   dataIndex: 'rev',
      //   key: 'rev',
      //   className: 'checklist-cols-1'
      // },
      {
        title: (<span className='test'>  Pri # </span>),
        dataIndex: 'pri',
        key: 'pri',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Dest </span>),
        dataIndex: 'dest',
        key: 'dest',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Prod </span>),
        dataIndex: 'prod',
        key: 'prod',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  SPL </span>),
        dataIndex: 'spl',
        key: 'spl',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Pre-Built? </span>),
        dataIndex: 'preBuilt',
        key: 'preBuilt',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  On Hand? </span>),
        dataIndex: 'onHand',
        key: 'onHand',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Assigned ULD </span>) ,
        dataIndex: 'addUld',
        key: 'addUld',
        align:'center',
        width: '10%',
        className: 'checklist-cols',
        render: ( _, row ) => 
          <div>
            { row.uldAdded === false  ? 
            //   <a className='view-options' onClick ={ () => this.addULD()}> - </a>
              <p  onClick ={ () => this.addULD()}>-</p>
              : 
              <Tooltip title='pcs: 40, grs.wt: 1849, pri#: 8' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><Tag color='blue'>PMC12345DL</Tag>  </Tooltip> }
          </div>
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        // width: '10%',
        render: (_, record) =>
          <div>
            {this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  ><img  src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Assign to ULD' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img className = 'uldicon' src={ULDicon} alt='uld' /></Tooltip>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}  ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText}  okText='Yes' cancelText='No'>
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]
    const awbData = [
      {
        key: '1',
        location: 'T-Point',
        awb: '006-61898395',
        comments: '',
        pcs: '80',
        grwt: '3698',
        chwt: '3698', 
        vol: '102' ,
        rev: '2182' ,
        pri: '8',
        dest: 'GOI' ,
        prod: 'DIM' ,
        spl: 'INS EAP',
        preBuilt: 'N',
        onHand: 'Y',
        uldAdded: true,
        tags: 'PMC12345DL'
      },
      {
        key: '2',
        location: '4Dash',
        awb: '006-61342131',
        comments: '',
        pcs: '80',
        grwt: '3698',
        chwt: '3698', 
        vol: '102' ,
        rev: '2182' ,
        pri: '8',
        dest: 'GOI' ,
        prod: 'DIM' ,
        spl: 'INS EAP',
        preBuilt: 'N',
        onHand: 'Y',
        uldAdded: false,
        tags: []
      }
    ]
    const uldColumns = [
      {
        title: (<span className='test'>  ULD Type </span>),
        dataIndex: 'uldType',
        key: 'uldType',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  ULD No. </span>),
        dataIndex: 'uldNumber',
        key: 'uldNumber',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Remarks/Pri# </span>),
        dataIndex: 'pri',
        key: 'pri',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Est. GrWt(lbs) </span>),
        dataIndex: 'grwt',
        key: 'grwt',
        className: 'checklist-cols-1'
      },
      {
        title: (<span className='test'>  Awb Pcs </span>),
        dataIndex: 'pcs',
        key: 'pcs',
        className: 'checklist-cols-1'
      },
    ]
    const uldData = [
      {
        key: '1',
        uldType: 'PMC',
        uldNumber: 'PMC15375DL',
        pcs: '40',
        pri: '8',
        grwt: '3698',
      },
    ]
    const shipmentColumns = [
      {
        title: '',
        dataIndex: 'checkbox',
        key: 'checkbox',
        align: 'center',
        className: 'status-column',
        render: () =>
          <div>
            <Checkbox  />
          </div>
      },
      {
        title: 'Airwaybill',
        dataIndex: 'airwaybill',
        key: 'airwaybill',
        className: 'space-nowrap',
        // render: (_, record) => (
        //   // <span>{record?.format_awb ? record.format_awb : 'N/A'}</span>
        //   <span>
        //     {`${record?.format_awb || ''} | `}
        //     {`${record?.avl_pcs || 0} | `}
        //     {`${this.state.weight_unit === 'KG' ? formatWeight(record.wgt_kg) + 'KG' : this.state.weight_unit === 'LB' ? formatWeight(record.wgt_lbs) + 'LB' : ''} | `}
        //     {`${record?.vol?.value ? parseFloat(record?.vol?.value)?.toFixed(2) : 0}${record?.vol?.unit || ''} | `}
        //     {`${record?.org + '-' + record?.dest}`}
        //   </span>
        // ),
      },
      {
        title: 'Commodity',
        dataIndex: 'commodity',
        key: 'commodity',
        // render: (_, record) => (
        //   <span>{record?.shipment_type === 'Local' ? record?.awb?.book_rmks?.rmk1 || 'N/A' : record?.info?.loading_remark || 'N/A'}</span>
        // ),
      },
      {
        title: 'Current Flt',
        dataIndex: 'current_flt',
        key: 'current_flt',
        // render: (_, record) => (
        //   <span>{record?.flights_out?.flight_no && record?.flights_out?.flight_date ? `${record?.flights_out?.flight_no}/${moment(record?.flights_out?.flight_date).format(dateSFormat)}` : 'N/A'}</span>
        // ),
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        // render: (_, record) => (
        //   <span>{record?.location ? record.location : 'N/A'}</span>
        // ),
      },
      {
        title: 'Prod',
        dataIndex: 'prod',
        key: 'prod',
        // render: (_, record) => (
        //   <span>{record?.awb?.prod_code ? record?.awb?.prod_code : 'N/A'}</span>
        // ),
      },
      {
        title: 'Specials',
        dataIndex: 'specials',
        key: 'specials',
        // render: (_, record) => (
        //   <span>{record?.spl_code ? record.spl_code : 'N/A'}</span>
        // ),
      },
    ]
    const replacementData =[
      {
        key: '1',
        airwaybill: '006-12345678',
        commodity: 'comm',
        current_flt: 'DL040',
        location: 'T-Point',
        prod: 'DIM',
        specials: 'AVI'
      },
      {
        key: '2',
        airwaybill: '006-12345678',
        commodity: 'comm',
        current_flt: 'DL040',
        location: 'T-Point',
        prod: 'DIM',
        specials: 'AVI'
      },
    ]
    // const bagsColumns = [
    //   {
    //     title: 'Estimated Bags',
    //     dataIndex: 'bags',
    //     key: 'bags',
    //     className: 'checklist-cols',
    //     children:[
    //       {
    //         title: 'Bag Positions',
    //         dataIndex: 'positions',
    //         key: 'positions',
    //         align:'center',
    //         className: 'checklist-cols',          
    //       },
    //       {
    //         title: 'Bulk Bags',
    //         dataIndex: 'bulk',
    //         key: 'bulk',
    //         align:'center',
    //         className: 'checklist-cols',          
    //       }
    //     ]
    //   },
    // ]
    // const containerCols = [
    //   {
    //     title: 'Containers in Staging',
    //     dataIndex: 'type',
    //     key: 'type',
    //     className: 'checklist-cols-1',
    //     children: [
    //       {
    //         title: 'Container Type',
    //         dataIndex: 'type',
    //         key: 'type',
    //         className: 'checklist-cols-1'
    //       },
    //       {
    //         title: 'Est. Conv Weight',
    //         dataIndex: 'weight',
    //         key: 'weight',
    //         className: 'checklist-cols-1'
    //       },
    //       {
    //         title: 'Priority',
    //         dataIndex: 'pri',
    //         key: 'pri',
    //         className: 'checklist-cols-1'
    //       },
    //     ]
    //   }    
    // ]

    const CCcolumns = [
      {
        title: 'DOD Team Comments',
        dataIndex: 'plan_comm',
        key: 'plan_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              disabled={this.disableNote(this.state.editRecord?.dept_time)}
              size='small'
              placeholder=''
              value={this.state.editTComm1 || ''}
              onChange={(e) => this.setState({ editTComm1: e.target.value })}
            />
            : <span>{record?.comm1}</span>)
      },
      {
        title: 'Warehouse Team Comments',
        dataIndex: 'dep_comm',
        key: 'dep_comm',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editTComm2 || ''}
              onChange={(e) => this.setState({ editTComm2: e.target.value })}
              disabled
            />
            : <span>{record?.comm2}</span>)
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          this.state.editAllocRecord && this.state.editAllocRecord.id === record.id ?
            <div className='action-column'>
              <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.submit('edit')} ><img src={Update} alt='Save' /></Tooltip>
              <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.clearAllocTableForm()} ><span className='form-clear'>x</span></Tooltip>
            </div>
            :
            <div className='action-column'>
              {/* <Tooltip title='Attachment'>
                <Badge size='small' count={2}>
                  <Button type='text' shape='circle' className='clip' icon={<PaperClipOutlined />} />
                </Badge>
              </Tooltip> */}
              <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=> this.editAllocTableForm(record)} ><img src={editIcon} alt='edit' /></Tooltip>
              <Popconfirm placement='top' title={constants.DeleteText} onConfirm={() => this.setState({ deleteAllocID: record.id }, () => this.submit('delete'))} okText='Yes' cancelText='No'>
                <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
              </Popconfirm>
            </div>
        ),
      },
    ]

    return (
      <div>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 12,
                lg: 12
              }, {
                xs: 12,
                sm: 12,
                md: 12,
                lg: 12
              },]} >
                <Col xs={24} className='f-spacebtw'>
                  <Title level={5} className='my-3' xs={12}>
                      Build Plan
                  </Title>
                  <Col  >
                    <Button
                      type='primary'
                      className='custom-button'
                      size='small'
                      onClick ={ () => this.fetchReplacementLoad()}
                      // disabled={!this.state.flightRow?.id}
                    >Find replacement Load
                    </Button>
                    <Button type='primary' className='custom-button mr-0' onClick ={ () => this.openWB(true)}>WB Planner </Button>
                  </Col>
                  
                </Col> 
              </Row>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} >
             
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                  <Card className='cargo-cap-card'>
                    <div>
                      <Row gutter={[{
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 12
                      }, {
                        xs: 12,
                        sm: 12,
                        md: 12,
                        lg: 24
                      },]} className='flight-ctrl'
                      >
                        <Col xs={24} sm={1} md={1} lg={1} xl={1} xxl={1}>
                          <div className='recd-ht'>
                            <small className= 'info-title mb-1'>Fight #</small>
                            <p className='info-data mb-2'>070</p>
                          </div>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2} xxl={2}>
                          <div className='recd-ht'>
                            <small className= 'info-title mb-1'>Date</small>
                            <p className='info-data mb-2'>08/21/2023</p>
                          </div>
                        </Col>
                        <Col xs={24} sm={1} md={1} lg={1} xl={1} xxl={1}>
                          <div className='recd-ht'>
                            <small className= 'info-title mb-1'>AC</small>
                            <p className='info-data mb-2'>76Z</p>
                          </div>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2} xxl={2}>
                          <div className='recd-ht'>
                            <small className='info-title mb-1'>Seg</small>
                            <p className='info-data mb-2'>ATL-AMS</p>
                          </div>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2} xxl={2}>
                          <div className='recd-ht'>
                            <small className='info-title mb-1'>Ship #</small>
                            <p className='info-data mb-2 space-nowrap'>186</p>
                          </div>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2} xxl={2}>
                          <div className='recd-ht'>
                            <small className='info-title mb-1'>Dept Time</small>
                            <p className='info-data mb-2'> 09:45</p>
                          </div>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2} xxl={2}>
                          <div className='recd-ht'>
                            <small className='info-title mb-1'>FPE Time</small>
                            <p className='info-data mb-2 space-nowrap'>07:15</p>
                          </div>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2} xxl={2}>
                          <div className='recd-ht'>
                            <small className='info-title mb-1'>Plan Due</small>
                            <p className='info-data mb-2 space-nowrap'>01:45</p>
                          </div>
                        </Col>
                        <Col xs={24} sm={3} md={3} lg={3} xl={3} xxl={3}>
                          <div className='recd-ht'><small className='info-title'>P CAP / FCST</small>
                            <Space direction='horizontal'>
                              <p className='info-data '> 225 <span className='color-white'>/</span></p> 
                              {<InputNumber
                                min={0}
                                precision={0}
                                controls={false}
                                size='small'
                                placeholder=''
                                // value={this.state.editFcst || 0}
                                // onChange={(e) => this.setState({ editFcst: e })}
                                // disabled={this.disableNote(this.state.editRecord?.dept_time)}
                                className='w-40'
                              />}
                            </Space>
                          </div>
                        </Col>
                        <Col xs={24} sm={3} md={3} lg={3} xl={3} xxl={3}>
                          <div className='sub-head'>
                            <div><small className='info-title'>Bag Posns</small></div>
                            <Space size='small'>
                              <div className='w-40'>
                                <small className='info-title'>LD3</small>
                                <InputNumber
                                  className='w-40'
                                  min={0}
                                  precision={0}
                                  controls={false}
                                  size='small'
                                  placeholder=''
                                //   value={this.state.editBagLD3 || 0}
                                //   onChange={(e) => this.setState({ editBagLD3: e })}
                                //   disabled={!this.state.editRecord?.cont_type.includes('LD3') || this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                                />
                              </div>
                              <div className='w-40'>
                                <small className='info-title'>LD2</small>
                                <InputNumber
                                  className='w-40'
                                  min={0}
                                  precision={0}
                                  controls={false}
                                  size='small'
                                  placeholder=''
                                //   value={this.state.editBagLD2 || 0}
                                //   onChange={(e) => this.setState({ editBagLD2: e })}
                                //   disabled={!this.state.editRecord?.cont_type.includes('LD2') || this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                                />
                              </div>
                              <Button
                                className='custom-icon-button mt-4 disabled-bg-none'
                                ghost
                                icon={<SearchOutlined />}
                              // onClick={() => this.fetchFlightSummary()} 
                              // disabled={this.disableNote(this.state.editRecord?.dept_time) || !this.state.isWideBody}
                              />
                            </Space>
                          </div>
                        </Col>
                     
                        <Col xs={24} sm={2} md={2} lg={2} xl={2} xxl={2} className='sub-head text-center'>
                          <small className='info-title space-nowrap'>Cargo Payload</small>
                          <div className=''>
                            <div className='weight-toggle'>
                              <Switch
                                checkedChildren='LB'
                                unCheckedChildren='KG'
                                size='small'
                                className='mx-1'
                              // checked={this.state.editWgtUnit === 'KG' ? false : true}
                              // onChange={((e) => this.setState({
                              //   editWgtUnit: e === false ? 'KG' : 'LB',
                              //   editWgtValue: this.updateWeightVal(e === false ? 'KG' : 'LB', this.state.editWgtValue)
                              // }))}
                              />
                            </div>
                            <div className=''>
                              <InputNumber
                                min={0}
                                controls={false}
                                size='small'
                              // placeholder=''
                              // value={this.state.editWgtValue || 0}
                              // onChange={(e) => this.setState({ editWgtValue: e })}
                              // disabled={this.disableNote(this.state.editRecord?.dept_time)}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2} xxl={2} className='text-center'>
                          <div className='recd-ht'>
                            <small className='info-title '>BU PRIO</small>
                            <Checkbox
                              className='light-checkbox mx-auto'
                            //   checked={this.state.editBuPrio}
                            //   onChange={(e) => this.setState({ editBuPrio: e.target.checked })}
                            //   disabled={this.disableNote(this.state.editRecord?.dept_time)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div> 
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}> 
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                      
                      <div className='table-outer'>
                        <Table
                          className='custom-table w-100 checklist-table1'
                          dataSource={configData}
                          columns={configColumns}
                          pagination={false}
                        />
                      </div>
                    
                    </Col>
                    <Button type='primary' className='custom-button run'>Run Optimization </Button>
                  </Row>
                </Col>
                
                {/* </Row>
              <Row> */}
                
                
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
            <Card className='custom-card h-100'>
              <div className='table-outer'>
                <Table
                  className='custom-table w-100 checklist-table'
                  dataSource={awbData}
                  columns={awbColumns}
                  pagination={false}
                />
              </div>
            </Card>
          </Col>
          <Col xs={24} >
            <Card className='custom-card' >
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='px-0 p-12'>
                <Form
                  layout='vertical'
                  className='position-relative'
                >
                  <Row
                    gutter={[{
                      xs: 0,
                      sm: 0,
                      md: 24,
                      lg: 24
                    }, {
                      xs: 0,
                      sm: 0,
                      md: 0,
                      lg: 0
                    },]}
                    className='product-ht'>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8} className=''>
                      <Form.Item label='Planning Comments' className='mb-2'>
                        <Input
                        // disabled={this.disableNote(this.state.editRecord?.dept_time)}
                          size='small'
                          placeholder=''
                        // value={this.state.editComm1 || ''}
                        // onChange={(e) => this.setState({ editComm1: e.target.value })}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <label className='custom-label transparent'>-</label>
                      <Button type='ghost' className='ml-auto image-btn' onClick={() => this.submit('add')}>
                        <Tooltip title={this.state.isEdit ? 'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <div className='my-3'>
                <Row>
                  <Col xs={24} md={24} lg={6}>
                    <Text strong={true}>Load Planning Notes</Text>
                  </Col>
                  <Col xs={24} md={24} lg={18} className='text-right'>
                    <Space wrap>
                      <Text type='secondary' strong>Last Update:</Text>
                      <Space size={[48, 0]} direction='horizontal'>
                        <Text strong>{this.state.lastUpdatedAt ? this.state.lastUpdatedAt + ' ' + this.state.ext : 'N/A'}</Text>
                        <Text strong>{this.state.lastUpdatedBy ? this.state.lastUpdatedBy : 'N/A'}</Text>
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </div>
              <div className='table-outer'>
                <Table
                  className='custom-table w-100'
                  dataSource={this.state.editAllocArray} columns={CCcolumns}
                  pagination={false}
                />
              </div>
              <div className='save-container'>
                <Button type='primary' className='custom-button t' >Save and Exit </Button>
              </div>
              
            </Card>
          </Col>
        </Row>
        <Modal
          title={'Shipments available to AMS'}
          destroyOnClose
          visible={this.state.replacementModalVisible}
          onOk={this.hideReplacementModal}
          onCancel={this.hideReplacementModal}
          footer={false}
          width={'80%'}
          centered
          className='custom-modal pb-0'
        >
          <Row gutter={[{
            xs: 0,
            sm: 12,
            md: 12,
            lg: 12
          }, {
            xs: 24,
            sm: 12,
            md: 12,
            lg: 12
          },]} className='with-table'>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className='table-overflow'
            >
              <div className='table-outer'>
                <Table
                  className='custom-table w-100'
                  dataSource={replacementData}
                  columns={shipmentColumns}
                  pagination={false}
                />
              </div>
            </Col>
            <Col className='ml-auto'>
              <p>Total Selected: </p>
              <Button
              // disabled={!this.state.selectedReplacementData?.length > 0}
              // onClick={() => { this.setState({ loading: true } ,() => this.requestRebooking()) }}
                className='custom-button float-right'>Request Rebooking</Button>
            </Col>
          </Row>
        </Modal>
        <Modal
          title={'Assign to ULD'}
          visible={this.state.addULD}
          closable={true}
          onCancel={() => this.setState({ addULD: false })}
          footer={false}
          width={'50%'}
          centered
          className='custom-modal pb-0'
        >
          <Row gutter={[{
            xs: 0,
            sm: 0,
            md: 24,
            lg: 24
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 2
          },]} className='with-table'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
              <Form
                layout='vertical'
                className='position-relative'
              >
                <Row
                  gutter={[{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 24
                  }, {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 0
                  },]} >
                  <Col xs={24} sm={24} md={24} lg={12} xl={4} className=''>
                    <Form.Item label='Uld Type'>
                      <Input
                        size='small'
                        placeholder=''
                        value=''
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={4} className=''>
                    <Form.Item label='Uld No.'>
                      <Input
                        size='small'
                        placeholder=''
                        value=''
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={4} className=''>
                    <Form.Item label='Awb Pcs'>
                      <Input
                        size='small'
                        placeholder=''
                        value=''
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={4} className=''>
                    <Form.Item label='Remarks/Pri#'>
                      <Input
                        size='small'
                        placeholder=''
                        value=''
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={4} className=''>
                    <Form.Item label='Est. GrWt(lbs)'>
                      <Input
                        size='small'
                        placeholder=''
                        value=''
                      />
                    </Form.Item>
                  </Col>
                  <Col flex='40px' className='text-center ml-auto pl-0'>
                    <label className='custom-label transparent'>-</label>
                    <Button type='ghost' className='ml-auto image-btn' onClick={this.addException}>
                      <Tooltip title={'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div className='table-outer'>
                <Table
                  className='custom-table exceptionlist-table w-100'
                  dataSource={uldData}
                  columns={uldColumns}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Modal>

      </div>
    )
  }
}

export default BuildPlan
