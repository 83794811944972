import React, { Component } from 'react'
import { Button, Input, Select, Row, Col, Typography, Form, Card, Table, Tooltip, Popconfirm, notification, Modal, Badge } from 'antd'
import addIcon from '../../../assets/images/add.svg'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import Update from '../../../assets/images/input-tick.svg'
import cssconstants from '../../../constants/cssconstants'
import constants,{ errormsg, systemParameterTabs as titles }  from '../../../constants/constants'
import './tabs.css'
import Http from '../../../utils/http'
import { cloneDeep } from 'lodash'
import Cookies from 'universal-cookie'
import { cacheRetrieve } from '../../../utils/common'
import { pageHandler, getCookie, COOKIE } from '../../../utils/cookie'

const { Title } = Typography
const { Search } = Input
const { Option } = Select
let cookies = new Cookies()
const station = cookies.get('station')

class ProductWorkAreaMapping extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      workAreasList: [],
      areaList: [],
      workAreasProductsList: [],
      workAreasLocationsList: [],
      search: '',

      selworkArea: null,
      filterWorkArea: null,
      area_id: null,

      area_name: '',
      id_wa: null,
      isWAEdit: false,
      waEditRecord: null,
      editArea_name: null,

      p_code: '',
      transfer_type: 'Local',
      id_wap: null,
      isWAPEdit: false,
      prodWorkArea: '',
      wapEditRecord :null,
      editProdWorkArea: null,
      editP_code: null,
      editTransfer_type: '',
      editprodArea_id: null,

      locations: '',
      id_wal: null,
      isWALEdit: false,
      locWorkArea: '',
      walEditRecord :null,
      editLocations: null,
      editLocWorkArea: null,
      editlocArea_id: null,
      editlocArea_name: null,
      editlocArea_location_group: null,

      duplicate: null,
      pduplicate: null,
      lduplicate: null,
      eduplicate: null,

      selectedArea: '',
      isEdit: false,

      showExceptionsModal: false,
      exceptionList: [],
      exceptionName: null,
      exceptionValue: null,
      exceptionType: 'Local',
      exceptionWorkArea: null,

      editExceptionRecord: null,
      editExceptionName: null,
      editExceptionValue: null,
      editExceptionType: null,
      editExceptionWorkArea: null,
    }
  }

  componentDidMount() {
    let urlarr = window.location.pathname.split('/')
    pageHandler('/'+urlarr[1], getCookie(COOKIE.ROLE))
    const events = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=sp&stn=${station}`)
    events.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      /* check type of data and call necessary APIs */
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        if(parsedData.TYPE === 'WORK_AREA'){
          this.fetchWorkAreas()
        }
        if(parsedData.TYPE === 'WORK_AREA_PRODUCTS'){
          this.fetchWorkAreaProducts()
        }
        if(parsedData.TYPE === 'WORK_AREA_LOCATION'){
          this.fetchWorkAreaLocations()
        }
        if(parsedData.TYPE === 'WORK_AREA_EXCEPTIONS'){
          this.fetchExceptionList()
        }
      }
    }
    this.fetchWorkAreas()
    this.fetchWorkAreaProducts()
    this.fetchWorkAreaLocations()

    this.fetchExceptionList()
  }

  fetchWorkAreas = async () => {
    let callback = this.fetchWorkAreasFn
    let pageURL =  'work_areas'
    let headers = {
      station: station,
      module: 'inbound'
    }
    cacheRetrieve(callback, pageURL, headers )
  }

  fetchWorkAreasFn = async(resp) => {
    const respData = await resp.json()
    if(respData && respData?.data?.length>0){
      let areaList = []
      respData?.data?.map(d => {
        d.id = d?.ids?.[0]
        areaList.push({
          label: d.area_name,
          value: d.id,
        })
      })
      areaList = areaList.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.value === thing.value
        ))
      )
      this.setState({
        workAreasList : respData.data,
        areaList: areaList,
      },()=>{
        let found = false
        if(this.state.selworkArea){
          found = this.state.workAreasList.some(obj=> obj.id === this.state.selworkArea.id)
          if(!found){
            this.setState({ selworkArea: null },()=>{
              this.clearForm('work_areas')
              this.fetchWorkAreaProducts()
            })
          }
          else {
            this.clearForm('work_areas')
          }
        } else {
          this.clearForm('work_areas')
        }
      })
    } else {
      this.setState({
        workAreasList : [],
        areaList: [],
        selworkArea: null
      },()=>{
        this.clearForm('work_areas')
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: 'Work Areas '+respData.message,
        })})
    }

  }

  fetchWorkAreaProducts = async () => {
    let callback = this.fetchWorkAreaProductsFn
    let url = 'work_area_products'
    if(this.state.selworkArea?.ids) {
      let to_string = this.state.selworkArea?.ids?.toString()?.replaceAll(',','-')
      url= url+'?area_ids='+to_string
    }
    let headers = {
      station: station,
      module: 'inbound'
    }
    cacheRetrieve(callback, url, headers )
  }

  fetchWorkAreaProductsFn = async(resp) => {
    const respData = await resp.json()
    if(respData && respData.data.length>0){
      this.setState({ workAreasProductsList : respData.data })
    } else {
      this.setState({ workAreasProductsList : [] })
    }

  }

  fetchWorkAreaLocations = async () => {
    let callback = this.fetchWorkAreaLocationsFn
    let url = 'work_arealocations'
    // if(this.state.filterWorkArea)
    //   url= url+'?area_id='+this.state.filterWorkArea
    if(this.state.selworkArea?.id)
      url= url+'?area_name='+this.state.selworkArea.area_name

    let headers = {
      search: this.state.search,
      station: station,
      module: 'inbound'
    }
    cacheRetrieve(callback, url, headers )
  }

  fetchWorkAreaLocationsFn = async(resp) => {
    const respData = await resp.json()
    if(respData && respData.data && respData.data.length>0){
      respData?.data?.map(d => { d.id = d?.ids?.[0] })
      this.setState({ workAreasLocationsList : respData.data })
    } else {
      if(this.state.search){
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: respData.message,
        })
      }
      this.setState({ workAreasLocationsList : [] })
    }

  }

  changeFilter = (e, type) => {
    if(type === 'location_area'){
      this.setState({ filterWorkArea: e || [], },()=>this.fetchWorkAreaLocations())
    }
  }
  editHandler = (record, type) =>{
    if(type === 'work_areas'){
      this.setState({
        isWAEdit: true,
        area_name: record.area_name,
        id_wa: record.id
      })
    }
    else if(type === 'work_area_products'){
      this.setState({
        isWAPEdit: true,
        p_code: record.details.p_code,
        transfer_type: record.details.transfer_type,
        id_wap: record.id,
        prodWorkArea: record.station+' / '+record.work_area.area_name,
        area_id: record.area_id
      })
    }
    else if(type === 'work_area_locations'){
      this.setState({
        isWALEdit: true,
        locations: record.details.location,
        id_wal: record.id,
        locWorkArea: record.station+' / '+record.work_area.area_name,
        area_id: record.area_id
      })
    }
  }

  addWorkAreas = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.breakdown_work_area_mapping + '_workarea',
    }
    //eslint-disable-next-line
    let formdata= {
      station: station,
      area_name: this.state.area_name,
      module: 'inbound'
    }
    Http.post('/work_area', formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.setState({
            area_name: '',
            duplicate: null,
          },()=>{
            this.fetchWorkAreas()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          })
        } else {
          this.setState({ duplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  updateWorkAreas = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_work_area_mapping + '_workarea'
    }
    let formdata= {
      area_name: this.state.editArea_name,
      station: station,
      module: 'inbound'
    }
    Http.put('/work_area/'+this.state.waEditRecord.id, formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.clearTableForm()
          this.fetchWorkAreas()
          this.fetchWorkAreaLocations()
          this.fetchWorkAreaProducts()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data.data.message,
          })
        } else {
          var tempData = data && data.data && data.data.data ? data.data.data : null
          this.setState({ duplicate: tempData },()=>setTimeout(() => {this.setState({ duplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  addWorkAreaProducts  = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_work_area_mapping + '_workareaproducts'
    }
    if(!this.state.selworkArea || !this.state.selworkArea.id){
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: errormsg.sel_work_area,
      })
    }
    if(this.state.selworkArea && this.state.selworkArea.id){
      let formdata= {
        area_id: this.state.selworkArea.id,
        station: station,
        p_code: this.state.p_code,
        transfer_type: this.state.transfer_type,
        module: 'inbound'
      }
      Http.post('/work_area_product', formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.setState({
              area_name: '',
              pduplicate: null,
            },()=>{
              this.clearForm('work_area_products')
              if(this.state.selworkArea && this.state.selworkArea.area_name)
                this.setState({ prodWorkArea: station+' / '+this.state.selworkArea.area_name })
              this.fetchWorkAreaProducts()
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data.data.message,
              })
            })
          } else {
            this.setState({ pduplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ pduplicate: null })}, 4500))
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    }
  }

  updateWorkAreaProducts = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_work_area_mapping + '_workareaproducts'
    }
    let formdata= {
      p_code: this.state.editP_code,
      transfer_type: this.state.editTransfer_type,
      station: station,
      area_id: this.state.editprodArea_id,
      module: 'inbound'
    }
    Http.put('/work_area_product/'+this.state.wapEditRecord.id, formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.setState({
            p_code: '',
            transfer_type: 'Local',
            id_wap: null,
            isWAPEdit: false,
            prodWorkArea: '',
            pduplicate: null,
          },()=>{
            if(this.state.selworkArea && this.state.selworkArea.area_name)
              this.setState({ prodWorkArea: station+' / '+this.state.selworkArea.area_name })
            this.clearTableForm()
            this.fetchWorkAreaProducts()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          })
        } else {
          this.setState({ pduplicate:data?.data?.data },()=>setTimeout(() => {this.setState({ pduplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }

  addWorkAreaLocations  = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_work_area_mapping + '_workarealocations'
    }
    if(!this.state.selworkArea || !this.state.selworkArea.id){
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: errormsg.sel_work_area,
      })
    }
    if(this.state.selworkArea && this.state.selworkArea.id){
      let formdata= {
        area_name: this.state.selworkArea.area_name,
        station: station,
        location: this.state.locations,
        module: 'inbound'
      }
      Http.post('/work_arealocation', formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.setState({
              location: '',
              lduplicate: null,
            },()=>{
              this.clearForm('work_area_locations')
              if(this.state?.selworkArea?.area_name)
                this.setState({ locWorkArea: station+' / '+this.state.selworkArea.area_name })
              this.fetchWorkAreaLocations()
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data.data.message,
              })
            })
          } else {
            this.setState({ lduplicate:data?.data?.data ? data.data.data[0] : null },()=>setTimeout(() => {this.setState({ lduplicate: null })}, 4500))
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    }
  }

  updateWorkAreaLocations = () => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_work_area_mapping + '_workarealocations'
    }
    let formdata= {
      location: this.state.editLocations,
      station: station,
      area_name: this.state.editlocArea_name,
      location_group: this.state.editlocArea_location_group,
      module: 'inbound'
    }
    Http.put('/work_arealocation', formdata, { headers })
      .then((data) => {
        if(data && data.status === 200){
          this.setState({
            locations: '',
            id_wal: null,
            isWALEdit: false,
            locWorkArea: '',
            lduplicate: null,
          },()=>{
            if(this.state?.selworkArea?.area_name)
              this.setState({ locWorkArea: station+' / '+this.state.selworkArea.area_name })
            this.clearTableForm()
            this.fetchWorkAreaLocations()
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data.data.message,
            })
          })
        } else {
          this.setState({ lduplicate:data?.data?.data ? data.data.data[0] : null },()=>setTimeout(() => {this.setState({ lduplicate: null })}, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data.data.message,
          })
        }
      })
  }
  deleteHandler = (record, type) => {
    let title = type === 'work_areas' ? '_workarea' : type === 'work_area_products' ? '_workareaproducts' : type === 'work_area_locations' ? '_workarealocations' : ''
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_work_area_mapping + title
    }
    if(type === 'work_areas'){
      let formdata= {
        is_delete: true,
        station: station,
        area_name: record.area_name,
        module: 'inbound'
      }
      Http.put('/work_area/'+record.id, formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.setState({
              area_name: '',
              id_wa: null,
              isWAEdit: false,
            },()=>{
              // this.clearForm('work_areas')
              this.fetchWorkAreas()
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data.data.message,
              })
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    } else if (type === 'work_area_products') {
      let formdata= {
        is_delete: true,
        module: 'inbound'
      }
      Http.put('/work_area_product/'+record.id, formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.setState({
              p_code: '',
              transfer_type: 'Local',
              id_wap: null,
              isWAPEdit: false,
              prodWorkArea: ''
            },()=>{
              this.clearForm('work_area_products')
              this.fetchWorkAreaProducts()
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data.data.message,
              })
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })

    } else if (type === 'work_area_locations') {
      let formdata = {
        ids: [...record.ids],
        area_name: record.area_name,
        station: station,
        location_group: record.location_group,
        module: 'inbound'
      }
      Http.post('/work_arealocation_del', formdata, { headers })
        .then((data) => {
          if(data && data.status === 200){
            this.setState({
              locations: '',
              id_wal: null,
              isWALEdit: false,
              locWorkArea: '',
            },()=>{
              this.clearForm('work_area_locations')
              this.fetchWorkAreaLocations()
              notification.destroy()
              notification.success({
                message: 'Success',
                description: data.data.message,
              })
            })
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data.data.message,
            })
          }
        })
    }
  }

  clearForm = (type) =>{
    if(!type || type === 'work_areas'){
      this.setState({
        area_name: '',
        id_wa: null,
        isWAEdit: false,

        p_code: '',
        transfer_type: 'Local',
        id_wap: null,
        isWAPEdit: false,
        //prodWorkArea: 'ATL /',

        locations: '',
        id_wal: null,
        isWALEdit: false,
        //locationWorkArea: 'ATL /',
      })
      if(this.state.selworkArea && this.state.selworkArea.area_name)
        this.setState({
          prodWorkArea: station+' / '+this.state.selworkArea.area_name,
          locWorkArea: station+' / '+this.state.selworkArea.area_name
        })
      else {
        this.setState({
          prodWorkArea: '',
          locWorkArea: ''
        })
      }
    }
    if(!type ||  type === 'work_area_products'){
      this.setState({
        p_code: '',
        transfer_type: 'Local',
        id_wap: null,
        isWAPEdit: false,
        //prodWorkArea: 'ATL /',
      })
      if(this.state.selworkArea && this.state.selworkArea.area_name)
        this.setState({ prodWorkArea: station+' / '+this.state.selworkArea.area_name })
    }
    if(!type || type === 'work_area_locations'){
      this.setState({
        locations: '',
        id_wal: null,
        isWALEdit: false,
        //locationWorkArea: 'ATL /',
      })
      if(this.state.selworkArea && this.state.selworkArea.area_name)
        this.setState({ locWorkArea: station+' / '+this.state.selworkArea.area_name })
    }

  }

  editTableForm = (record, type) => {
    if( type === 'work_areas' ){
      this.setState({
        waEditRecord: record,
        editArea_name: record.area_name,
      })
    }
    if(type === 'work_area_products'){
      this.setState({
        wapEditRecord :record,
        editProdWorkArea: record.station+' / '+record.work_area.area_name,
        editP_code: record.details.p_code,
        editTransfer_type: record.details.transfer_type,
        editprodArea_id: record.work_area.id
      })
    }
    if(type === 'work_area_locations'){
      this.setState({
        walEditRecord :record,
        editLocations: record.location,
        editLocWorkArea: record.station+' / '+record.area_name,
        editlocArea_id: record.id,
        editlocArea_name: record.area_name,
        editlocArea_location_group: record.location_group,
      })
    }
  }

  setSearchValue (e){
    this.setState({ search: e.target.value },()=>{
      if( !e.target.value || e.target.value === null || e.target.value ==='' ){
        this.fetchWorkAreaLocations()
      }
    })
  }

  locationColumnSearch = () => ({
    filterDropdown: () => (
      <div />
    ),
    filterIcon: () =>
      <Form.Item label='' labelCol={{
        lg: { span: 24 },
        xl: { span: 0 },
      }} wrapperCol={{
        lg: { span: 24 },
        xl: { span: 24 },
      }}
      className='w-100 mb-0'
      >
        <Search
          placeholder='Search'
          allowClear
          // enterButton='Search'
          size='small'
          onChange={(e) => this.setSearchValue(e)}
          onSearch={(e)=> !e || e === null || e ==='' ? null : this.fetchWorkAreaLocations()}
          className='search-field w-100 br-input colheader-search'
        />
      </Form.Item>
  })

  clearTableForm = (type) => {
    if( !type || type === 'work_areas'){
      this.setState({
        waEditRecord: null,
        editArea_name: null,
      })
    }
    if(!type || type === 'work_area_products'){
      this.setState({
        wapEditRecord :null,
        editProdWorkArea: null,
        editP_code: null,
        editTransfer_type: '',
        editprodArea_id: null
      })
    }
    if(!type || type === 'work_area_locations'){
      this.setState({
        walEditRecord :null,
        editLocations: null,
        editLocWorkArea: null,
        editlocArea_id: null,
        editlocArea_name: null,
        editlocArea_location_group: null,
      })
    }
  }

  /**************************************************************************/

  fetchExceptionList = async() => {
    Http.get(`/work_area_exceptions?station=${station}`)
      .then((data) => {
        if(data?.data?.data?.length > 0) {
          this.setState({ exceptionList : data.data.data })
        } else {
          this.setState({ exceptionList : [] })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
      })
  }
  

  addException = async() => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_work_area_mapping + '_exceptions'
    }
    let formdata = new FormData()
    formdata.append('area_id', this.state.exceptionWorkArea || '')
    formdata.append('station', station)
    formdata.append('value', this.state.exceptionValue || '')
    formdata.append('exception', this.state.exceptionName || '')
    formdata.append('transfer_type', this.state.exceptionName?.toUpperCase() === 'DESTINATION' ? '' : this.state.exceptionType || '')
    await Http.post('/work_area_exception', formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.clearExceptionForm()
          this.clearExceptionTableForm()
          this.fetchExceptionList()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          this.setState({ eduplicate: data?.data?.data }, () => setTimeout(() => { this.setState({ eduplicate: null }) }, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
      })
  }

  updateException = async() => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_work_area_mapping + '_exceptions'
    }
    let formdata = new FormData()
    formdata.append('area_id', this.state.editExceptionWorkArea || '')
    formdata.append('station', station)
    formdata.append('value', this.state.editExceptionValue || '')
    formdata.append('exception', this.state.editExceptionName || '')
    formdata.append('transfer_type', this.state.editExceptionName?.toUpperCase() === 'DESTINATION' ? '' : this.state.editExceptionType || '')
    await Http.put(`/work_area_exception/${this.state.editExceptionRecord?.id}`, formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          this.clearExceptionTableForm()
          this.fetchExceptionList()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          this.setState({ eduplicate: data?.data?.data }, () => setTimeout(() => { this.setState({ eduplicate: null }) }, 4500))
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
      })
  }

  editExceptionTableForm = (record) => {
    this.setState({
      editExceptionRecord: record,
      editExceptionName: record?.details?.exception,
      editExceptionType: record?.details?.exception?.toUpperCase() === 'DESTINATION' ? 'Local' : record?.details?.transfer_type,
      editExceptionValue: record?.details?.value,
      editExceptionWorkArea: record?.work_area?.id,
    })
  }

  clearExceptionForm = () => {
    this.setState({
      exceptionName: null,
      exceptionValue: null,
      exceptionType: 'Local',
      exceptionWorkArea: null,
    })
  }

  clearExceptionTableForm = () => {
    this.setState({
      editExceptionRecord: null,
      editExceptionName: null,
      editExceptionValue: null,
      editExceptionType: null,
      editExceptionWorkArea: null,
    })
  }

  deleteException = async (id) => {
    let headers = {
      user_id: cookies.get('id'),
      page_title: titles.product_work_area_mapping + '_exceptions'
    }
    let formdata = {
      station: station,
      is_delete: true
    }
    await Http.put(`/work_area_exception/${id}`, formdata, { headers })
      .then((data) => {
        if (data && data?.status === 200) {
          //this.clearForm()
          this.fetchExceptionList()
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
  }

  // Exceptions Modal
  exceptionsAvailable = () => {
    this.setState({ showExceptionsModal: true })
  }
  exceptionsHide = () => {
    this.clearExceptionForm()
    this.clearExceptionTableForm()
    this.setState({ showExceptionsModal: false })
  }

  /**************************************************************************/

  render() {
    // product mapping

    const workAreaColumns = [
      {
        title: 'Work Area',
        key: 'workarea',
        render: (string, record) =>(
          this.state.waEditRecord && this.state.waEditRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editArea_name}
              onChange={(e) => this.setState({ editArea_name: e.target.value })}
            />
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{
              record.area_name? record.area_name : '-' }
            </span>
        )
      },
      {
        title: 'Action',
        // dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (string, record) =>
          <div>
            { this.state.waEditRecord && this.state.waEditRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateWorkAreas('work_areas')} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm('work_areas')} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record, 'work_areas')} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteHandler(record, 'work_areas')} >
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    const pworkAreaColumns = [
      {
        title: 'Station / Work Area',
        //dataIndex: 'workarea',
        key: 'workarea',
        render: (text, record) => (
          this.state.wapEditRecord && this.state.wapEditRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              disabled
              value={this.state.editProdWorkArea}
            />
            :
            <span className={record.id === this.state.pduplicate?.id ?'red-border': ''}>{
              record && record.work_area && record.station ? record.station+ ' / '+ record.work_area.area_name  : '-'}
            </span>
        )
      },
      {
        title: 'Product Code',
        //dataIndex: 'productcode',
        key: 'productcode',
        render: (text, record) => (
          this.state.wapEditRecord && this.state.wapEditRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editP_code}
              onChange={(e) => this.setState({ editP_code: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.pduplicate?.id ?'red-border': ''}>{
              record && record.details && record.details.p_code ? record.details.p_code  : '-'}
            </span>
        )
      },
      {
        title: 'Local/Transfer',
        key: 'transfer_type',
        render: (text, record) => (
          this.state.wapEditRecord && this.state.wapEditRecord.id === record.id ?
            <Select size='small' value={this.state.editTransfer_type} onChange={(e) => this.setState({ editTransfer_type: e })}>
              <Option value='Local'>Local</Option>
              <Option value='Transfer'>Transfer</Option>
            </Select>
            :
            <span className={record.id === this.state.duplicate?.id ?'red-border': ''}>{record?.details?.transfer_type}</span> )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (string, record) =>
          <div>
            { this.state.wapEditRecord && this.state.wapEditRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateWorkAreaProducts('work_area_products')} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm('work_area_products')} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record, 'work_area_products')} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteHandler(record, 'work_area_products')} >
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]
    const lworkAreaColumns = [
      {
        title: 'Work Area',
        key: 'workarea',
        width: '30%',
        render: (text, record) => (
          this.state.walEditRecord && this.state.walEditRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              disabled
              value={this.state.editLocWorkArea}
            />
            :
            <span className={record.id === this.state.lduplicate?.id ?'red-border': ''}>{
              record && record.area_name ? record.area_name  : '-'}
            </span>
        )
      },
      {
        title: 'Location',
        key: 'location',
        width: '60%',
        className:'table-header-search',
        render: (text, record) => (
          this.state.walEditRecord && this.state.walEditRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editLocations}
              onChange={(e) => this.setState({ editLocations: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.lduplicate?.id ?'red-border': ''}>{
              record && record.location ? record.location.split(',').join(', ') : '-'}
            </span>
        ),
        ...this.locationColumnSearch(),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: '10%',
        key: 'action',
        align: 'center',
        render: (string, record) =>
          <div>
            { this.state.walEditRecord && this.state.walEditRecord.id === record.id ?
              <div className='action-column'>
                <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.updateWorkAreaLocations('work_area_locations')} ><img src={Update} alt='Save' /></Tooltip>
                <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.clearTableForm('work_area_locations')} ><span className='form-clear'>x</span></Tooltip>
              </div>
              :
              <div className='action-column'>
                <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={()=>this.editTableForm(record, 'work_area_locations')} ><img src={editIcon} alt='edit' /></Tooltip>
                <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={()=>this.deleteHandler(record, 'work_area_locations')} >
                  <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                </Popconfirm>
              </div>
            }
          </div>
      },
    ]

    // Exceptions Table
    const excptionColumns = [
      {
        title: 'Exception',
        dataIndex: 'exception',
        key: 'exception',
        render: (_, record) => (
          this.state.editExceptionRecord && this.state.editExceptionRecord.id === record.id ?
            <Select
              placeholder='Select exception'
              value={this.state.editExceptionName}
              onChange={(e) => this.setState({ editExceptionName: e })}
            >
              <Option value='AWB Prefix'>AWB Prefix</Option>
              <Option value='Destination'>Destination</Option>
              <Option value='Flights'>Flights</Option>
            </Select>
            :
            <span className={record.id === this.state.eduplicate?.id ? 'red-border' : ''}>{!record?.details?.exception ? 'NA' : record?.details?.exception}</span>
        )
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        render: (_, record) => (
          this.state.editExceptionRecord && this.state.editExceptionRecord.id === record.id ?
            <Input
              size='small'
              placeholder=''
              value={this.state.editExceptionValue}
              onChange={(e) => this.setState({ editExceptionValue: e.target.value.toUpperCase() })}
            />
            :
            <span className={record.id === this.state.eduplicate?.id ? 'red-border' : ''}>{!record?.details?.value ? 'NA' : record?.details?.value}</span>
        )
      },
      {
        title: 'Transfer/Local',
        dataIndex: 'transfer_type',
        key: 'transfer_type',
        render: (_, record) => (
          this.state.editExceptionRecord && this.state.editExceptionRecord.id === record.id ?
            <Select
              placeholder='Select'
              value={this.state.editExceptionType}
              onChange={(e) => this.setState({ editExceptionType: e })}
              disabled={this.state.editExceptionName?.toUpperCase() === 'DESTINATION'}
            >
              <Option value='Local'>Local</Option>
              <Option value='Transfer'>Transfer</Option>
            </Select>
            :
            <span className={record.id === this.state.eduplicate?.id ? 'red-border' : ''}>{!record?.details?.transfer_type ? 'NA' : record?.details?.transfer_type}</span>
        )
      },
      {
        title: 'Deliver to work area',
        dataIndex: 'area_id',
        key: 'area_id',
        render: (_, record) => (
          this.state.editExceptionRecord && this.state.editExceptionRecord.id === record.id ?
            <Select placeholder='Select work area' value={this.state.editExceptionWorkArea} onChange={(e) => this.setState({ editExceptionWorkArea: e })}>
              {this.state.workAreasList?.map((wa) => (
                <Option key={wa.area_name} value={wa.ids[0]} title={wa.area_name}>{wa.area_name}</Option>
              ))}
            </Select>
            :
            <span className={record.id === this.state.eduplicate?.id ? 'red-border' : ''}>{!record?.work_area?.area_name ? 'NA' : record?.work_area?.area_name}</span>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (_, record) =>
          <div>
            <div className='action-column'>
              {this.state.editExceptionRecord && this.state.editExceptionRecord.id === record.id ?
                <div className='action-column'>
                  <Tooltip title='Save' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={this.updateException} ><img src={Update} alt='Save' /></Tooltip>
                  <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={this.clearExceptionTableForm} ><span className='form-clear'>x</span></Tooltip>
                </div> :
                <div className='action-column'>
                  <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} onClick={() => this.editExceptionTableForm(record)}><img src={editIcon} alt='edit' /></Tooltip>
                  <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No' onConfirm={() => this.deleteException(record.id)}>
                    <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
                  </Popconfirm>
                </div>
              }
            </div>
          </div>
      },
    ]

    // rowSelection object indicates the need for row selection
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.clearForm('work_areas')
        this.setState({
          selworkArea: cloneDeep(selectedRows[0]),
          prodWorkArea: station+' / '+cloneDeep(selectedRows[0]).area_name,
          locWorkArea: station+' / '+cloneDeep(selectedRows[0]).area_name
        },()=>{
          this.fetchWorkAreaProducts()
          this.fetchWorkAreaLocations()
        }
        )
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User', // Column configuration not to be checked
        name: record.name,
      })
    }
    return (
      <div>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          {/* <Col xs={24} sm={24} md={24} lg={6} xl={12} xxl={5} xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 1 }} lg={{ order: 1 }} xl={{order: 1}} xxl={{order: 2}} className=''> */}
          <Col xs={{
            span: 24,
            order: 1
          }} sm={{
            span: 24,
            order: 1
          }} md={{
            span: 10,
            order: 1
          }}
          lg={{
            span: 10,
            order: 1
          }} xl={{
            span: 10,
            order: 1
          }} xxl={{
            span: 5,
            order: 1
          }} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>Work Areas</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} flex='auto' className=''>
                        <Form.Item label='Work Area Name'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.area_name}
                            onChange={(e) => this.setState({ area_name: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='ml-auto image-btn' onClick={() => this.state.isWAEdit ? this.updateWorkAreas() : this.addWorkAreas()}>
                          <Tooltip title={this.state.isWAEdit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                    { this.state.isWAEdit ? (
                      <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Button type='ghost' className='ml-auto image-btn clear-btn fleet-btn-placement' onClick={() => this.clearForm('work_areas')}>
                          <span className='close-icon'>x</span>
                        </Button>
                      </Tooltip>
                    )
                      : null
                    }
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      rowKey='id'
                      className='custom-table workarea-table w-100'
                      dataSource={this.state.workAreasList}
                      columns={workAreaColumns}
                      pagination={false}
                      rowSelection={{
                        type: 'radio',
                        ...rowSelection,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* <Col xs={24} sm={24} md={24} lg={10} xl={24} xxl={10} xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 3 }} lg={{ order: 3 }} xl={{order: 3}} xxl={{order: 1}} className=''> */}
          <Col xs={{
            span: 24,
            order: 1
          }} sm={{
            span: 24,
            order: 2
          }} md={{
            span: 24,
            order: 3
          }} lg={{
            span: 24,
            order: 3
          }}
          xl={{
            span: 24,
            order: 3
          }} xxl={{
            span: 10,
            order: 2
          }} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='f-spacebtw'>
                  <Title level={5} className='mb-0'>Products to work area mapping</Title>
                  <Badge className='custom-badge' size='default' count={this.state.exceptionList?.length}><Button type='primary' className='custom-button mr-0' onClick={this.exceptionsAvailable}>Exceptions</Button></Badge>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} md={6} lg={5} xl={7} xxl={6} className=''>
                        <Form.Item label='Station / Work Area'>
                          <Input
                            size='small'
                            placeholder=''
                            disabled
                            value={this.state.prodWorkArea}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={5} xl={7} xxl={6} className=''>
                        <Form.Item label='Enter Product Code'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.p_code}
                            onChange={(e) => this.setState({ p_code: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='auto' className='local'>
                        <Form.Item label='Local/Transfer'>
                          <Select  value={this.state.transfer_type} onChange={(e) => this.setState({ transfer_type: e })}>
                            <Option value='Local'>Local</Option>
                            <Option value='Transfer'>Transfer</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='ml-auto image-btn' onClick={() => this.state.isWAPEdit ? this.updateWorkAreaProducts() : this.addWorkAreaProducts()} >
                          <Tooltip title={this.state.isWAPEdit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                    { this.state.isWAPEdit ? (
                      <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                        <Button type='ghost' className='ml-auto image-btn clear-btn fleet-btn-placement' onClick={() => this.clearForm('work_area_products')}>
                          <span className='close-icon'>x</span>
                        </Button>
                      </Tooltip>
                    )
                      : null
                    }
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table productlist-table w-100'
                      dataSource={this.state.workAreasProductsList}
                      columns={pworkAreaColumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* <Col xs={24} sm={24} md={24} lg={8} xl={12} xxl={9} xs={{ order: 1 }} sm={{ order: 1 }} md={{ order: 2 }} lg={{ order: 2 }} xl={{order: 2}} xxl={{order: 3}} className=''> */}
          <Col xs={{
            span: 24,
            order: 1
          }} sm={{
            span: 24,
            order: 2
          }} md={{
            span: 14,
            order: 2
          }}
          lg={{
            span: 14,
            order: 2
          }} xl={{
            span: 14,
            order: 2
          }} xxl={{
            span: 9,
            order: 3
          }} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>Locations to work area mapping</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} md={8} lg={9} xl={10} xxl={8} className=''>
                        <Form.Item label='Station / Work Area'>
                          <Input
                            size='small'
                            placeholder=''
                            disabled
                            value={this.state.locWorkArea}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} flex='auto' className=''>
                        <Form.Item label='Enter up to 10 locations comma separated'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.locations}
                            onChange={(e) => this.setState({ locations: e.target.value.toUpperCase() })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='ml-auto image-btn' onClick={() => this.state.isWALEdit ? this.updateWorkAreaLocations() : this.addWorkAreaLocations()} >
                          <Tooltip title={this.state.isWALEdit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                      {  this.state.isWALEdit ? (
                        <Tooltip title='Clear' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
                          <Button type='ghost' className='ml-auto image-btn clear-btn fleet-btn-placement' onClick={() => this.clearForm('work_area_locations')}>
                            <span className='close-icon'>x</span>
                          </Button>
                        </Tooltip>
                      )
                        : null
                      }
                      {/* <Col flex='auto' className='field-horizontal'>
                        <Form.Item label='Filter list by work area' labelCol={{
                          lg: { span: 24 },
                          xl: { span: 8 },
                        }} wrapperCol={{
                          sm: { span: 24 },
                          md: { span: 24 },
                          lg: { span: 24 },
                          xl: { span: 16 },
                        }}

                        rules={[{
                          required: true,
                          message: 'Please input your username!'
                        }]}
                        >
                          <Select allowClear size='small' options={this.state.areaList} value={this.state.filterWorkArea} onChange={(e)=>this.changeFilter(e, 'location_area')} placeholder='Select work area'>

                          </Select>
                        </Form.Item>
                      </Col> */}
                      {/* <Col xs={24} sm={24} md={24} lg={9} xl={10} xxl={8} className='f-align-end'>
                        <Form.Item label='' labelCol={{
                          lg: { span: 24 },
                          xl: { span: 0 },
                        }} wrapperCol={{
                          lg: { span: 24 },
                          xl: { span: 24 },
                        }}
                        className='w-100'>
                          <Search
                            placeholder='Search'
                            allowClear
                            // enterButton='Search'
                            size='small'
                            onChange={(e) => this.setSearchValue(e)}
                            onSearch={()=>this.fetchWorkAreaLocations()}
                            className='search-field w-100'
                          />
                        </Form.Item>
                      </Col> */}
                    </Row>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table location-table w-100'
                      dataSource={this.state.workAreasLocationsList}
                      columns={lworkAreaColumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          title='Product / Work Area Mapping Exceptions'
          visible={this.state.showExceptionsModal}
          onOk={this.exceptionsHide}
          onCancel={this.exceptionsHide}
          className='custom-modal pb-0'
          width={'50%'}
          centered
          footer={false}
        >
          <Row gutter={[{
            xs: 0,
            sm: 0,
            md: 24,
            lg: 24
          }, {
            xs: 24,
            sm: 24,
            md: 24,
            lg: 2
          },]} className='with-table'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
              <Form
                layout='vertical'
                className='position-relative'
              >
                <Row
                  gutter={[{
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 24
                  }, {
                    xs: 0,
                    sm: 0,
                    md: 0,
                    lg: 0
                  },]} >
                  <Col xs={24} sm={24} md={24} lg={12} xl={6} className='local'>
                    <Form.Item label='Select Exception'>
                      <Select
                        placeholder='Select exception'
                        value={this.state.exceptionName}
                        onChange={(e) => this.setState({ exceptionName: e })}
                      >
                        <Option value='AWB Prefix'>AWB Prefix</Option>
                        <Option value='Flights'>Flights</Option>
                        <Option value='Destination'>Destination</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={5} className=''>
                    <Form.Item label='Value'>
                      <Input
                        size='small'
                        placeholder=''
                        value={this.state.exceptionValue}
                        onChange={(e) => this.setState({ exceptionValue: e.target.value.toUpperCase() })}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={5} className='local'>
                    <Form.Item label='Transfer/Local'>
                      <Select
                        placeholder='Select'
                        value={this.state.exceptionType}
                        onChange={(e) => this.setState({ exceptionType: e })}
                        disabled={this.state.exceptionName?.toUpperCase() === 'DESTINATION'}
                      >
                        <Option value='Local'>Local</Option>
                        <Option value='Transfer'>Transfer</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={6} className='local'>
                    <Form.Item label='Deliver to work area​'>
                      <Select placeholder='Select work area' value={this.state.exceptionWorkArea} onChange={(e) => this.setState({ exceptionWorkArea: e })}>
                        {this.state.workAreasList?.map((wa) => (
                          <Option key={wa.area_name} value={wa.ids[0]} title={wa.area_name}>{wa.area_name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='40px' className='text-center ml-auto pl-0'>
                    <label className='custom-label transparent'>-</label>
                    <Button type='ghost' className='ml-auto image-btn' onClick={this.addException}>
                      <Tooltip title={'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
              <div className='table-outer'>
                <Table
                  className='custom-table exceptionlist-table w-100'
                  dataSource={this.state.exceptionList}
                  columns={excptionColumns}
                  pagination={false}
                />
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

export default ProductWorkAreaMapping
