import React, { useState, useEffect } from 'react'
import { /*ProcessedMessages, PerformanceInfo, */SearchMessages, ProcessedMessagesBull, AuditTrail } from './tabs'
import './index.css'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { gatekeeperTabs } from '../../constants/constants'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const IncomingMessages = ({ match }) => {

  const [tab, setTab] = useState(false)

  useEffect(() => {
    if (match?.params.tab) {
      if (gatekeeperTabs[match.params.tab]) {
        setTab(gatekeeperTabs[match.params.tab])
      } else {
        setTab(gatekeeperTabs.processed)
      }
    } else {
      setTab(gatekeeperTabs.processed)
    }
  }, [])


  const tabHnadler = (t) => {
    setTab(t)
    // history.push(`/gatekeeper/${t}`)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  return (
    <div>
      <Slider {...settings} className='custom-slider mb-24px'>
        <div className={`tabs ${tab == gatekeeperTabs.processed? 'active-tab' : ''}`} onClick={() => tabHnadler(gatekeeperTabs.processed)}>
          <span>Processed Messages</span>
        </div>
        {/* <div className={`tabs ${tab == gatekeeperTabs.processed? 'active-tab' : ''}`} onClick={() => tabHnadler(gatekeeperTabs.processed)}>
          <span>Processed Messages</span>
        </div> */}
        <div className={`tabs ${tab == gatekeeperTabs.audit_trail? 'active-tab' : ''}`} onClick={() => tabHnadler(gatekeeperTabs.audit_trail)}>
          <span>AWB Audit Trail</span>
        </div>
        <div className={`tabs ${tab == gatekeeperTabs.search? 'active-tab' : ''}`} onClick={() => tabHnadler(gatekeeperTabs.search)}>
          <span>Search Messages</span>
        </div>
        {/* <div className={`tabs ${tab == gatekeeperTabs.performance? 'active-tab' : ''}`} onClick={() => tabHnadler(gatekeeperTabs.performance)}>
          <span>Performance Info</span>
        </div> */}
      </Slider>

      {/* {tab == gatekeeperTabs.processed &&
        <ProcessedMessages />
      } */}

      {tab == gatekeeperTabs.search &&
        <SearchMessages />
      }

      {/* {tab == gatekeeperTabs.performance &&
        <PerformanceInfo />
      } */}

      {tab == gatekeeperTabs.processed &&
        <ProcessedMessagesBull name={'Hi'} />
      }

      {tab == gatekeeperTabs.audit_trail &&
        <AuditTrail />
      }

    </div>
  )
}
IncomingMessages.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default IncomingMessages
