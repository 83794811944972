import React from 'react'
import { Row, Col, Space, Input, Select, InputNumber } from 'antd'
const { Option } = Select

const AcDetails = (props) => {
  return (
    <div className=''>
      <Row justify='flex-start' gutter={[{
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xxl: 48
      }, {
        xs: 3,
        sm: 3,
        md: 3,
        xxl: 0
      },]} className='new-card'
      >
        <Col xs={24} sm={12} md={6} lg={4} xl={3} xxl={{
          span: 3,
          offset: 1
        }} className='grey-bkg'>
          <div className='WB-tiles WB-margin'>
            <small className= 'tile-header'>Ship #</small>
            <div className='tile-data'>
              <Input
                bordered={false}
                name='ship'
                value={props.ship}
                onChange={props.onChange}
                disabled={props.ccPlanModalVisible}
              />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={3} xxl={3} className='grey-bkg'>
          <div className='WB-tiles WB-margin'>
            <small className= 'tile-header'>DOM/INTL</small>
            <div className='tile-data'>
              <Select
                allowClear
                size='small'
                placeholder='DOM/INTL'
                bordered={false}
                className='w-100'
                value={props.type}
                disabled={props.ccPlanModalVisible}
                onChange={(v) => props.onChange({
                  target: {
                    name: 'type',
                    value: v
                  } 
                })}
              >
                <Option value='Domestic'>Domestic </Option>
                <Option value='International'>International</Option>
              </Select>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={5} xxl={4} className='grey-bkg'>
          <div className='diff-tile WB-margin'>
            <div><small className='title-tile'>Expected Pax Counts</small></div>
            <Space size={0} className='spl'>
              <div className='pax-count '>
                <small className='tile-header2'>C</small>
                <div className='tile-data1'>
                  <InputNumber
                    type= 'number'
                    size= 'small'
                    bordered= {false}
                    // min= {1}
                    // max={}
                    controls= {false}
                    disabled={props.ccPlanModalVisible}
                    value={props.pax?.c}
                    onChange={(v) => props.onChangePax({
                      target: {
                        name: 'c',
                        value: v
                      } 
                    })}
                  // onChange={(value) => this.setState({ p_25: value }) }
                  /></div>
              </div>
              <div className='pax-count'>
                <small className='tile-header2'>F</small>
                <div className='tile-data1'>
                  <InputNumber
                    type= 'number'
                    size= 'small'
                    bordered= {false}
                    // min= {1}
                    // max={}
                    controls= {false}
                    value={props.pax?.f}
                    disabled={props.ccPlanModalVisible}
                    onChange={(v) => props.onChangePax({
                      target: {
                        name: 'f',
                        value: v
                      } 
                    })}
                  /></div>
              </div>
              <div className='pax-count'>
                <small className='tile-header2'>W</small>
                <div className='tile-data1'>
                  <InputNumber  
                    type= 'number'
                    size= 'small' 
                    bordered= {false}
                    // min= {1}
                    // max={}
                    controls= {false}
                    value={props.pax?.w}
                    disabled={props.ccPlanModalVisible}
                    onChange={(v) => props.onChangePax({
                      target: {
                        name: 'w',
                        value: v
                      } 
                    })}                      
                  /></div>
              </div>
              <div className='pax-count'>
                <small className='tile-header2'>Y</small>
                <small className='tile-data1'>
                  <InputNumber  
                    type= 'number'
                    size= 'small' 
                    bordered= {false}
                    // min= {1}
                    // max={}
                    controls= {false}
                    value={props.pax?.y}
                    disabled={props.ccPlanModalVisible}
                    onChange={(v) => props.onChangePax({
                      target: {
                        name: 'y',
                        value: v
                      } 
                    })}
                  /></small>
              </div>
            </Space>
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={3} xxl={3} className='grey-bkg'>
          <div className='WB-tiles WB-margin'>
            <small className='tile-header'>Exp.Fuel(lbs)</small>
            <div className='tile-data'>
              <InputNumber  
                type= 'number'
                size= 'small' 
                bordered= {false}
                // min= {1}
                // max={}
                controls= {false}
                value={props.fuel}
                disabled={props.ccPlanModalVisible}
                onChange={(v) => props.onChange({
                  target: {
                    name: 'fuel',
                    value: v
                  } 
                })}
              /></div>
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={3} xxl={3} className='grey-bkg'>
          <div className='WB-tiles WB-margin'>
            <small className='tile-header'>Season</small>
            <div className='tile-data'>                    
              <Select
                allowClear
                size='small'
                placeholder='Season'
                bordered={false}
                className='w-100'
                value={props.season}
                disabled={props.ccPlanModalVisible}
                onChange={(v) => props.onChange({
                  target: {
                    name: 'season',
                    value: v
                  } 
                })}
              >
                <Option value='Summer'>Summer</Option>
                <Option value='Winter'>Winter</Option>
              </Select>
            </div>
          </div>
        </Col>
        <Col xs={0} sm={0} md={12} lg={0} xl={0} xxl={0} className='grey-bkg'></Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={3} xxl={3}  className='blue-bkg bl-1'>
          <div className='WB-tiles1 WB-margin'>
            <small className= 'tile-header tile-header1'>Cargo Margin</small>
            <p className='tile-data'>{props.cargo_margin}</p>
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} lg={4} xl={3} xxl={3}  className='blue-bkg'>
          <div className='WB-tiles1 WB-margin'>
            <small className= 'tile-header tile-header1'>Aft Index</small>
            <p style={props.aftIndexStyle(props.aft_index)} className='tile-data'>{props.aft_index? props.aft_index : props.wgt_bal_status}</p>
            {/* <p>{props.aftStatus(props.aft_index)}</p> */}
          </div>
        </Col>
        <Col xs={0} sm={0} md={12} lg={16} xl={0} xxl={1} className='blue-bkg'></Col>
      </Row>
    </div>
  )
}
export default AcDetails
