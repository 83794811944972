import React, { Component } from 'react'
import { Button, Input, Row, Col, Typography, Form, Card, Table, Tooltip, Popconfirm } from 'antd'
import addIcon from '../../../assets/images/add.svg'
import editIcon from '../../../assets/images/edit.svg'
import deleteIcon from '../../../assets/images/delete.svg'
// import Update from '../../../assets/images/input-tick.svg'
import cssconstants from '../../../constants/cssconstants'
import constants from '../../../constants/constants'
import './tabs.css'

const { Title } = Typography
// const { Search } = Input
// const { Option } = Select

class Grouping extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
  }

  render() {
    // product mapping

    const groupColumns = [
      {
        title: 'Group Name',
        key: 'workarea',
        render: () =>(
          <span>123</span>
        )
      },
      {
        title: 'Action',
        // dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: () =>
          <div className='action-column'>
            <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} ><img src={editIcon} alt='edit' /></Tooltip>
            <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No'>
              <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
            </Popconfirm>
          </div>
      },
    ]
    const data = []
    for (let i = 0; i < 20; i += 1) {
      data.push({
        key: i,
        // name: ['Light', 'Bamboo', 'Little'][i % 3],
        // description: 'Everything that has a beginning, has an end.',
      })
    }
    
    const productgroupColumns = [
      {
        title: 'Group Name',
        key: 'workarea',
        width: '30%',
        render: () => (
          <span>Group Name</span>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: '10%',
        key: 'action',
        align: 'center',
        render: () =>
          <div className='action-column'>
            <Tooltip title='Edit' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }} ><img src={editIcon} alt='edit' /></Tooltip>
            <Popconfirm placement='top' title={constants.DeleteText} okText='Yes' cancelText='No'>
              <Tooltip title='Delete' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={deleteIcon} alt='delete' /></Tooltip>
            </Popconfirm>
          </div>
      },
    ]
    const pgdata = []
    for (let i = 0; i < 20; i += 1) {
      pgdata.push({ key: i, })
    }

    return (
      <div>
        <Row gutter={[{
          xs: 0,
          sm: 0,
          md: 24,
          lg: 24
        }, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24
        },]} >
          <Col xs={24} sm={24} md={24} lg={10} xl={10} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>Group Name</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='px-0 p-12'>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} flex='auto' className=''>
                        <Form.Item label='Enter Group Name'>
                          <Input
                            size='small'
                            placeholder=''
                            value={this.state.area_name}
                            onChange={(e) => this.setState({ area_name: e.target.value })}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='ml-auto image-btn' onClick={() => this.state.isWAEdit ? this.updateWorkAreas() : this.addWorkAreas()}>
                          <Tooltip title={this.state.isWAEdit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      rowKey='id'
                      className='custom-table grp-table w-100'
                      dataSource={data}
                      columns={groupColumns}
                      pagination={false}
                      rowSelection={{
                        type: 'radio',
                        // ...rowSelection,
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={24} lg={14} xl={14} className=''>
            <Card className='custom-card h-100'>
              <Row gutter={[{
                xs: 0,
                sm: 0,
                md: 24,
                lg: 24
              }, {
                xs: 24,
                sm: 24,
                md: 24,
                lg: 24
              },]} className='with-table'>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                  <Title level={5} className='mb-0'>Products to Group Mapping</Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='px-0 p-12'>
                  <Form
                    layout='vertical'
                    className='position-relative'
                  >
                    <Row
                      gutter={[{
                        xs: 0,
                        sm: 0,
                        md: 24,
                        lg: 24
                      }, {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 0
                      },]} >
                      <Col xs={24} sm={24} md={8} lg={9} xl={10} xxl={8} className=''>
                        <Form.Item label='Group Name'>
                          <Input
                            size='small'
                            placeholder=''
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} flex='auto' className=''>
                        <Form.Item label='Enter up to 10 products comma separated'>
                          <Input
                            size='small'
                            placeholder=''
                          />
                        </Form.Item>
                      </Col>
                      <Col flex='50px' className='text-center ml-auto'>
                        <label className='custom-label transparent'>-</label>
                        <Button type='ghost' className='ml-auto image-btn' onClick={() => this.state.isWALEdit ? this.updateWorkAreaLocations() : this.addWorkAreaLocations()} >
                          <Tooltip title={this.state.isWALEdit ?'Update' :'Add'} overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}><img src={addIcon} alt='add' /></Tooltip>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className='table-overflow'>
                  <div className='table-outer'>
                    <Table
                      className='custom-table grp-table w-100'
                      dataSource={pgdata}
                      columns={productgroupColumns}
                      pagination={false}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Grouping
