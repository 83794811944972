import React, { Component } from 'react'
import { Row, Col, Table, Checkbox, Select, Form, notification, Spin, Space, Button, Drawer, InputNumber, Input, /*Popconfirm,*/ Switch, Typography } from 'antd'
import { FilterOutlined, PrinterOutlined, LogoutOutlined, ArrowLeftOutlined, HomeOutlined } from '@ant-design/icons'
import { CountUp } from '../../utils/timer'
import '../warehouse/index.css'
import Http from '../../utils/http'
import { COOKIE, getCookie, logout, pageHandler } from '../../utils/cookie'
import { constants, timezone } from '../../constants/constants'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { format_gate, cacheRetrieve } from '../../utils/common'
import Cookies from 'universal-cookie'

const { Option } = Select
const { Text } = Typography
const CHARNUM3COMMA = '^[0-9a-zA-Z]{3}(,([0-9a-zA-Z]{3}))*$'
const CHARNUM3COMMAerr = 'field must contain 3 character alpha-numeric value seperated by a comma'

let FSU_LIST = ['RCF', 'FPS', 'MAN']

function formatAWBNo(awb) {
  let result = ''
  if(awb) {
    result = [awb.slice(0, 3), '-', awb.slice(3)].join('')
  }
  return result
}

function getTimeDifferenceInMinutes (difference) {
  if(difference) {
    return moment().diff(difference, 'minutes')
  } else return 0
}
class RecoveryDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      station: getCookie(COOKIE.STATION),
      role: getCookie(COOKIE.ROLE),
      timezone: getCookie(COOKIE.TIMEZONE),
      user_id: getCookie(COOKIE.ID),
      logintype: getCookie(COOKIE.LOGINTYPE),
      timezone_cty: timezone.find((item) => item.value === new Cookies().get('station'))?.zone || 'America/New_York',
      clock: '',
      intervalID: null,
      data: [],
      updatedULDs: [],
      fetchInterval: '',
      isListening: false,
      filterChecked: false,
      filterValue: '',
      switch: false,
      tempSwitch: false,
      searchSelect: false,
      searchValue: '',
      wasSearched: false,

      //Filters
      workAreaType: '',
      locationType: '',
      prodPriorityData: [],
      codeList: [],
      codeListDisplay: [],
      locationList: [],
      gateListDisplay:[],

      tempWorkArea: '',
      tempWorkAreaType: '',
      tempProdList: [],
      tempLocationList: [],
      tempGateList: [],

      exclusionArray: [],
      exclusionList: [],
      exclusionSearch: '',

      tempSelCargoList: '',
      tempSelProductList: [],
      tempSelgateType: [],
      tempSelLocationList: '',
      tempSelExclusionList: [],
      tempSelWorkAreaType: '',
      tempSelfilterChecked: false,
      tempSelfilterValue: '',

      latestTimestamp: '',
      latestTimestampTwo: '',
      stopFlag: false,

      doneModalVisible: false,
      doneRecordID: '',
      recoveryFilter: {},

      hiddenInterval: null,
      hiddenMoment: null,

      expanded: [],
      checkBreakdown: {},
      tableBodyFontSize: localStorage.getItem(`Recovery_${new Cookies().get('station')}`),
      defaultFontSize: 'clamp(14px,1.7vw,1.7vw)',
    }
    this._queue = []
    this._isBusy = false
  }
  //clock added to dashboard 
  clockTimerLogic = () => {
    this.setState({ clock: moment().tz(this.state.timezone_cty).format('DD MMM YYYY | hh:mm A') })
  }

  clockTimerInterval = () => {
    let clockInterval = setInterval(() => {
      this.clockTimerLogic()
    }, 60000)
    this.setState({ intervalID: clockInterval })
  }

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(this.state.checkBreakdown, 'RECOVERY DASHBOARD')
    const queryParams = new URLSearchParams(window.location.search)
    const switched = queryParams.get('switched')
    const value = queryParams.get('value')
    const checked = queryParams.get('checked')

    this.setState({ clock: moment().tz(this.state.timezone_cty).format('DD MMM YYYY | hh:mm A') }, () => {
      this.clockTimerLogic()
      this.clockTimerInterval()
    })


    localStorage.removeItem('prod_list')
    localStorage.removeItem('location_list')
    localStorage.removeItem('gate_list')

    setInterval(() => this.setState({ hiddenInterval: this.handlePageVisibility() }), 10000)
    this.setState({
      loading: true,
      filterChecked: checked === 'true' ? true : false,
      tempSelfilterChecked: checked === 'true' ? true : false,
      switch: switched === 'true' ? true : false,
      tempSwitch: switched === 'true' ? true : false,
      filterValue: value || '',
      tempSelfilterValue: value || '',
    }, () => this.fetchProductPriority())

    const events3 = new EventSource(process.env.REACT_APP_API_URL+`events?pgtype=rd&stn=${this.state.station}`)
    events3.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      if(!this.state.isListening) this.setState({ isListening: true })
      else {
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        if(!this.state.wasSearched){
          this.enqueue(myFakeAsyncJob)
        }
      }
    }
  }

  componentDidUpdate() {
    const fontSize = localStorage.getItem(`Recovery_${new Cookies().get('station')}`)
    if (this.state.tableBodyFontSize !== fontSize) { 
      this.setState({ tableBodyFontSize: fontSize })
    }
  }

  handlePageVisibility = () => {
    //HANDLE TAB VISIBILITY
    if(this.state.hiddenMoment === null && window.document.visibilityState === 'hidden') {
      this.setState({ hiddenMoment: moment().format('YYYY-MM-DD HH:mm:ss') })
    } else if(window.document.visibilityState === 'visible' && (this.state.hiddenMoment && getTimeDifferenceInMinutes(this.state.hiddenMoment) < 5)) {
      this.setState({ hiddenMoment: null })
    }
    if((this.state.hiddenMoment && (getTimeDifferenceInMinutes(this.state.hiddenMoment) > 5)) && this._queue?.length > 80) {
      this.refreshPage()
    }
  }

  refreshPage = () => {
    window.location.href = `/recovery-dashboard?switched=${this.state.tempSwitch}&checked=${this.state.tempSelfilterChecked}&value=${this.state.tempSelfilterValue}`
  }

  componentWillUnmount() {
    clearInterval(this.state.fetchInterval)
    clearInterval(this.state.hiddenInterval)
    localStorage.removeItem('prod_list')
    localStorage.removeItem('location_list')
    localStorage.removeItem('gate_list')
  }

  eventList = (parsedData) => {
    if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.Msg === 'ULD UPDATE') {
      this.fetchIndividualULD(parsedData?.ULD_ID)
    } else if(parsedData?.TYPE === 'ULD_UPDATE' && parsedData?.msg === 'FSU') {
      this.fetchIndividualULDOnFSU(parsedData?.DETAILS)
    } else if(parsedData?.TYPE === 'ULD_REMOVE' || parsedData?.TYPE === 'ULD_RECOVERY') {
      this.spliceULD(parsedData?.DETAILS?.uld_id)
    } else if(parsedData?.TYPE === 'AWB_RECOVERY') {
      this.spliceAWB(parseInt(parsedData?.DETAILS?.uld_id), parseInt(parsedData?.DETAILS?.goods_info_id))
    } else if(parsedData?.TYPE === 'FLIGHT_EVENT') {
      this.updatedULDonEvent(parsedData?.DETAILS)
    } else if(parsedData?.TYPE === 'AWB_UPDATE' && FSU_LIST?.includes(parsedData?.Msg?.toUpperCase()?.split(' ')[0])) {
      this.updatedAWBonEvent(parseInt(parsedData?.DETAILS?.uld_id), parseInt(parsedData?.DETAILS?.goods_info_id), parsedData?.DETAILS?.prod_code)
    } else if(parsedData?.TYPE === 'PRODUCT_PRIORITY') {
      this.fetchProductPriority('EVENT')
    } else if(parsedData?.TYPE === 'BOOKING_UPDATE') {
      this.processBookingMsg(parsedData?.DETAILS)
    }
  }

  /*************************************************************** */


  enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve and reject functions in the job we'll enqueue, so we can control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      this._queue.push({
        job,
        resolve,
        reject
      })
    })
    // we'll add a nextJob function and call it when we enqueue a new job; we'll use _isBusy to make sure we're executing the next job sequentially
    this.nextJob()
  }
  nextJob = () => {
    if (this._isBusy) return
    const next = this._queue.shift()
    // if the array is empty shift() will return undefined
    if (next) {
      this._isBusy = true
      next
        .job()
        .then((value) => {
          if(!this.state.wasSearched){
            this.eventList(value)
          }
          next.resolve(value)
          this._isBusy = false
          this.nextJob()
        })
        .catch((error) => {
          next.reject(error)
          this._isBusy = false
          this.nextJob()
        })
    }
  }
  getFakeAsyncJob = (item) => {
    return () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(item)
        }, 600)
      })
  }

  /*************************************************************** */

  //FETCH DASHBOARD DATA
  fetchDashboardData = async (last_id, from_filter, is_search = false ) => {
    if(!this.state.stopFlag || from_filter) {
      let temp_timestamp = moment().valueOf()
      this.setState({
        latestTimestamp: temp_timestamp,
        stopFlag: false,
      })
      let respdata = []
      let url = 'recovery_list'

      if(last_id) {
        url = url + `?last_id=${last_id}&limit=20`
        if(this.state.tempSelfilterChecked && this.state.tempSelfilterValue) {
          url = url + `&min=${this.state.tempSelfilterValue}`
          url = this.state.tempSwitch === true ? url + '&type=less' : url + '&type=max'
        }
      } else {
        url = url + '?limit=10'
        if(this.state.tempSelfilterChecked && this.state.tempSelfilterValue) {
          url = url + `&min=${this.state.tempSelfilterValue}`
          url = this.state.tempSwitch === true ? url + '&type=less' : url + '&type=max'
        }
      }
      if(is_search ){
        this.setState({ wasSearched: true })
        url= `recovery_list?search=${this.state.searchValue}`
      } else {
        this.setState({ wasSearched: false })
      }
      let headers = { station: this.state.station }
      await Http.get(url, { headers: headers }).then(resp => {
        if((!this.state.stopFlag || from_filter) && this.state.latestTimestamp === temp_timestamp && (!last_id || (last_id && !this.state.wasSearched))) {
          if (resp?.status === 200) {
            if (resp?.data?.data?.length > 0) {
              respdata = cloneDeep(resp.data.data)
              let lastID = respdata[respdata.length - 1]?.id || null
              let uld_array = []
              respdata.map((item) => {
                let obj = this.processIndividualULD(item)
                if(obj) {
                  uld_array.push(obj)
                }
              })

              respdata = uld_array

              let data = cloneDeep(this.state.data)
              if(data?.length > 0 && last_id) {
                data = data?.concat(respdata)
              } else {
                data = respdata
              }

              //CHECK FOR DUPLICATE ENTRIES
              let newData = []
              data?.map((item) => {
                let id = newData.findIndex(p => parseInt(p.id) == parseInt(item.id))
                if(id > -1) {
                  //DO NOTHING
                } else {
                  newData.push(item)
                }
              })
              this.setState({
                data: [],
                loading: false,
              }, () => 
                this.setState({
                  data: newData,
                  codeList: JSON.parse(localStorage.getItem('prod_list')),
                  codeListDisplay: JSON.parse(localStorage.getItem('prod_list')),
                  gateListDisplay: JSON.parse(localStorage.getItem('gate_list')),
                  locationList: JSON.parse(localStorage.getItem('location_list')),
                }, () => {
                  this.exclusionFilter(false, newData)
                }))
              if(!is_search ){
                this.fetchDashboardData(lastID, false, false)
              }
            } else {
              //CALL UPDATED ULDS FN AFTER ALL DATA IS FETCHED AND SET TIMEOUT OF 10 SECONDS
              clearInterval(this.state.fetchInterval)
              this.fetchUpdatedULDs()
              let interval = setInterval(() => { this.fetchUpdatedULDs()}, 60000)
              this.setState({
                fetchInterval: interval,
                loading: false
              })
            }
          } else if(resp?.data?.status === 'error'){
            this.fetchDashboardData(last_id, from_filter, false)
          }
        } else {
          this.setState({ stopFlag: false })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
    } else {
      this.setState({ stopFlag: false })
    }
  }

  scrollOnLoad = () => {
    window.scrollTo({
      top: 425,
      behavior: 'smooth',
    })
  }

  //FETCH ULD DATA
  fetchULDData = async (last_id, from_filter, is_search = false ) => {
    let updatedULDs = cloneDeep(this.state.updatedULDs)
    if(!this.state.stopFlag || from_filter) {
      let temp_timestamp = moment().valueOf()
      this.setState({
        latestTimestamp: temp_timestamp,
        stopFlag: false,
      })
      let respdata = []
      let url = 'recovery_uld_data'
      let ids = []
      let formdata = {}
      if(last_id) {
        let indx = updatedULDs.findIndex(p => parseInt(p) == parseInt(last_id))
        indx = indx+1
        // console.log('indx',indx)
        if(indx > -1 && this?.state?.updatedULDs[indx]){
          ids = updatedULDs.slice((indx), (indx+35))
          formdata.ids= ids
        }
      } else {
        ids = this.state.updatedULDs.slice(0, 35)
        // url = url + `&ids=${ids}`
        formdata.ids= ids
      }
      let headers = { station: this.state.station }
      // console.log('ids',ids)
      if(ids && ids.length>0){
        await Http.post(url,formdata, { headers: headers }).then(resp => {
          if((!this.state.stopFlag || from_filter) && this.state.latestTimestamp === temp_timestamp && (!last_id || (last_id && !this.state.wasSearched))) {
            if (resp?.status === 200) {
              if (resp?.data?.data?.length > 0) {
                respdata = cloneDeep(resp.data.data)
                let lastID = respdata[respdata.length - 1]?.id || null
                let uld_array = []
                respdata.map((item) => {
                  let obj = this.processIndividualULD(item)
                  if(obj) {
                    uld_array.push(obj)
                  }
                })
                respdata = uld_array
                let data = cloneDeep(this.state.data)
                if(data?.length > 0 && last_id) {
                  data = data?.concat(respdata)
                } else {
                  data = respdata
                }
  
                //CHECK FOR DUPLICATE ENTRIES
                let newData = []
                data?.map((item) => {
                  let id = newData.findIndex(p => parseInt(p.id) == parseInt(item.id))
                  if(id > -1) {
                    //DO NOTHING
                  } else {
                    newData.push(item)
                  }
                })
                this.setState({
                  data: [],
                  loading: false,
                }, () => {
                  this.setState({
                    data: newData,
                    codeList: JSON.parse(localStorage.getItem('prod_list')),
                    codeListDisplay: JSON.parse(localStorage.getItem('prod_list')),
                    gateListDisplay: JSON.parse(localStorage.getItem('gate_list')),
                    locationList: JSON.parse(localStorage.getItem('location_list')),
                  }, () => {
                    this.exclusionFilter(false, newData)
                  })})
                if(!is_search ){
                  this.fetchULDData(lastID, false, false)
                }
              } else {
                //CALL UPDATED ULDS FN AFTER ALL DATA IS FETCHED AND SET TIMEOUT OF 10 SECONDS
                clearInterval(this.state.fetchInterval)
                this.fetchUpdatedULDs()
                let interval = setInterval(() => { this.fetchUpdatedULDs()}, 60000)
                this.setState({
                  fetchInterval: interval,
                  loading: false
                })
              }
            } else if(resp?.data?.status === 'error'){
              this.fetchULDData(last_id, from_filter, false)
            }
          } else {
            this.setState({ stopFlag: false })
          }
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({
            loading: false,
            servererror : true
          })
        })
      } else {
        this.scrollOnLoad()
        //CALL UPDATED ULDS FN AFTER ALL DATA IS FETCHED AND SET TIMEOUT OF 10 SECONDS
        clearInterval(this.state.fetchInterval)
        this.fetchUpdatedULDs()
        let interval = setInterval(() => { this.fetchUpdatedULDs()}, 60000)
        this.setState({
          fetchInterval: interval,
          loading: false
        })
      }
    } else {
      this.setState({ stopFlag: false })
    }
  }
  //FETCH ULDIDS
  fetchULDIDS = async (last_id, from_filter) => {
    let temp_timestamp = moment().valueOf()
    this.setState({ latestTimestampTwo: temp_timestamp })
    let url = 'recovery_uld_list?'
    if(this.state.tempSelfilterChecked && this.state.tempSelfilterValue) {
      url = url + `min=${this.state.tempSelfilterValue}`
      url = this.state.tempSwitch === true ? url + '&type=less' : url + '&type=max'
    }

    let headers = { station: this.state.station }
    await Http.get(url, { headers: headers })
      .then((data) => {
        if(this.state.latestTimestampTwo === temp_timestamp) {
          if (data && data?.status === 200) {
            if(data?.data?.data?.length>0) {
              this.setState({ updatedULDs : data.data.data }, () => this.fetchULDData(last_id, from_filter, false ))
            }
          }
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({
          loading: false,
          servererror : true
        })
      })
  }

  //FETCH UPDATED ULDS
  fetchUpdatedULDs = async () => {
    let temp_timestamp = moment().valueOf()
    this.setState({ latestTimestampTwo: temp_timestamp })
    let url = 'recovery_uld_list?'
    if(this.state.tempSelfilterChecked && this.state.tempSelfilterValue) {
      url = url + `min=${this.state.tempSelfilterValue}`
      url = this.state.tempSwitch === true ? url + '&type=less' : url + '&type=max'
    }
    if(this._queue.length < 30){
      let headers = { station: this.state.station }
      await Http.get(url, { headers: headers })
        .then((data) => {
          if(this.state.latestTimestampTwo === temp_timestamp) {
            if (data && data?.status === 200) {
              if(data?.data?.data?.length>0) {
                // this.scrollOnLoad()
                this.setState({ updatedULDs : data.data.data }, () => this.updateULDList())
              }
            }
          }
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({
            loading: false,
            servererror : true
          })
        })
    }
  }
  //UPDATE ULD LIST
  updateULDList = () => {
    let ULDs = cloneDeep(this.state.data)
    let newULDs = cloneDeep(this.state.updatedULDs)

    //IN NEW ULD LIST, IF ULD IS THERE, REMOVE
    //IN NEW ULD LIST, IF ULD IS NOT THERE, ADD.

    newULDs.map((id) => {
      let uld_index = ULDs?.findIndex((p) => parseInt(p.id) === parseInt(id))
      if(uld_index === -1) {
        let parsedData = {
          TYPE: 'ULD_UPDATE',
          Msg: 'ULD UPDATE',
          ULD_ID: parseInt(id)
        }
        const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
        this.enqueue(myFakeAsyncJob)
      }
    })

    // let newIDs = newULDs?.filter(item => !ULDs?.includes(item))
    // if(newIDs?.length > 0) {
    //   newIDs.map((id) => {
    //     let parsedData = {
    //       TYPE: 'ULD_UPDATE',
    //       Msg: 'ULD UPDATE',
    //       ULD_ID: id
    //     }
    //     const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
    //     this.enqueue(myFakeAsyncJob)
    //   })
    // }

    if(ULDs?.length > 0) {
      ULDs.map((item) => {
        if(!newULDs?.includes(parseInt(item.id))) {
          let parsedData = {
            TYPE: 'ULD_REMOVE',
            DETAILS: { uld_id: parseInt(item.id) }
          }
          const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
          this.enqueue(myFakeAsyncJob)
        }
      })
    }

    // let removeIDs = ULDs?.filter(item => !newULDs?.includes(item.id))
    // if(removeIDs?.length > 0) {
    //   removeIDs.map((id) => {
    //     let parsedData = {
    //       TYPE: 'ULD_REMOVE',
    //       ULD_ID: id
    //     }
    //     const myFakeAsyncJob = this.getFakeAsyncJob(parsedData)
    //     this.enqueue(myFakeAsyncJob)
    //   })
    // }
  }
  //FETCH INDIVIDUAL ULD
  fetchIndividualULD = async (ID) => {
    if(ID){
      let headers = { station: this.state.station }
      await Http.get(`/recovery_list?id=${ID}`, { headers: headers })
        .then((data) => {
          if (data && data?.status === 200) {
            if(data?.data?.data?.length>0){
              let ULDs = cloneDeep(this.state.data)
              let uld_index = ULDs?.findIndex((p) => parseInt(p.id) === parseInt(data.data.data[0].id))
              if(uld_index === -1){
                ULDs.push(this.processIndividualULD(data.data.data[0]))
                this.setState({ data: [] }, () => this.setState({
                  data: ULDs,
                  codeList: JSON.parse(localStorage.getItem('prod_list')),
                  codeListDisplay: JSON.parse(localStorage.getItem('prod_list')),
                  gateListDisplay: JSON.parse(localStorage.getItem('gate_list')),
                  locationList: JSON.parse(localStorage.getItem('location_list')),
                }))
              }
            }
          }
        }).catch(() => {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: constants.SERVER_CONNECTION_ERROR,
          })
          this.setState({
            loading: false,
            servererror : true
          })
        })
    }
  }
  //FETCH INDIVIDUAL ULD
  fetchIndividualULDOnFSU = async (DETAILS) => {
    let ULDs = cloneDeep(this.state.data)
    if(ULDs?.length > 0) {
      let uld_index = ULDs.findIndex((p) => parseInt(p.id) === parseInt(DETAILS?.uld_id))

      if(uld_index > -1) {
        // if(DETAILS?.work_area_fps) {
        if(DETAILS?.recovery) {
          ULDs.splice(uld_index, 1)
        } else {
          if(DETAILS?.location?.toUpperCase() === 'UNKNOWN') {
            ULDs[uld_index].location2 = 'CHECKED IN'
          } else {
            ULDs[uld_index].location2 = format_gate(DETAILS?.location)
          }
          this.pushLocation(ULDs[uld_index].location2)
        }
        this.setState({ data: [] }, () => this.setState({ data: ULDs }))
      }
    }
  }
  //REMOVE AWB FROM LIST
  spliceAWB = (ULD_ID, AWB_ID) => {
    let ULDs = cloneDeep(this.state.data)
    let uld_index = ULDs?.findIndex((p) => parseInt(p.id) === parseInt(ULD_ID))

    if(uld_index > -1) {
      if(ULDs[uld_index]?.awbs_in?.length > 0) {
        let awb_index = ULDs[uld_index]?.awbs_in?.findIndex((q) => parseInt(q.id) === parseInt(AWB_ID))
        if(awb_index > -1) {
          ULDs[uld_index]?.awbs_in.splice(awb_index, 1)

          if(ULDs[uld_index]?.awbs_in?.length === 0) {
            ULDs?.splice(uld_index, 1)
          }
          this.setState({ data: [] }, () => this.setState({ data: ULDs }, () => {
            this.exclusionFilter(false, ULDs)
          }))
        }
      }
    }
  }
  //REMOVE ULD FROM LIST
  spliceULD = (ID) => {
    let ULDs = cloneDeep(this.state.data)
    let uld_index = ULDs?.findIndex((p) => parseInt(p.id) === parseInt(ID))
    if(uld_index > -1) {
      ULDs.splice(uld_index, 1)
      this.setState({ data: [] }, () => this.setState({ data: ULDs }, () => {
        this.exclusionFilter(false, ULDs)
      }))
    }
  }
  //UPDATED ULD OBJECT ON EVENT
  updatedULDonEvent = (DETAILS) => {
    let ULDs = cloneDeep(this.state.data)

    if(ULDs?.length > 0) {
      ULDs.map((item, index) => {
        if(item?.flights_id === parseInt(DETAILS?.flight_id)) {
          if(DETAILS?.flight?.flight_leg && DETAILS?.flight?.flight_leg?.cancel)
          {
            ULDs.splice(index, 1)
          }else{
            if(DETAILS?.flight?.latest_arriv_date) ULDs[index].flights_in.latest_arriv_date = DETAILS?.flight?.latest_arriv_date
            if(DETAILS?.flight?.arriv_date) ULDs[index].flights_in.arriv_date = DETAILS?.flight?.arriv_date
            if(DETAILS?.flight?.arr?.gate) {
              if(!ULDs[index].flights_in.arr) {
                ULDs[index].flights_in.arr = { gate: '' }
              }
              ULDs[index].flights_in.arr.gate = DETAILS?.flight?.arr?.gate
            }
            ULDs[index] = this.processIndividualULD(ULDs[index])
          }
        }
      })
      this.setState({ data: [] }, () => this.setState({ data: ULDs }))
    }
  }
  //UPDATED ULD OBJECT ON EVENT
  updatedAWBonEvent = (ULD_ID, AWB_ID, PROD_CODE) => {
    let ULDs = cloneDeep(this.state.data)

    if(ULDs?.length > 0) {
      let uld_index = ULDs.findIndex((p) => parseInt(p.id) === parseInt(ULD_ID))
      if(uld_index > -1) {
        if(ULDs[uld_index]?.awbs_in?.length > 0) {
          let awb_index = ULDs[uld_index]?.awbs_in.findIndex((q) => parseInt(q.id) === parseInt(AWB_ID))
          if(awb_index > -1) {
            if(ULDs[uld_index]?.awbs_in[awb_index].awb) {
              ULDs[uld_index].awbs_in[awb_index].awb.prod_code = PROD_CODE
              this.setState({ data: [] }, () => this.setState({ data: ULDs }))
            }
          }
        }
      }
    }
  }
  //CHECK FOR ULD TYPE
  processBookingMsg = async (DETAILS) => {
    let ULDs = cloneDeep(this.state.data)
    if(ULDs?.length > 0) {
      let uld_index = ULDs.findIndex((p) => parseInt(p.id) === parseInt(DETAILS.uld_id))
      if(uld_index > -1) {
        if(ULDs[uld_index]?.awbs_in?.length > 0) {
          let awb_index = ULDs[uld_index]?.awbs_in.findIndex((q) => parseInt(q.id) === parseInt(DETAILS?.goods_info_id))
          if(awb_index > -1) {
            if(ULDs[uld_index]?.awbs_in[awb_index].awb) {
              if(!ULDs[uld_index]?.awbs_in[awb_index]?.awb?.prod_code || ULDs[uld_index]?.awbs_in[awb_index]?.awb?.prod_code === ''){
                ULDs[uld_index].awbs_in[awb_index].awb.prod_code = DETAILS?.prod_code
                ULDs[uld_index].prod_code = this.getProdCode(ULDs[uld_index].awbs_in)
                // if(DETAILS?.prod_code && DETAILS?.prod_code !== ''){
                //   this.pushProdCode(ULDs[uld_index].prod_code)
                // }
                this.setState({ data: [] }, () => this.setState({
                  data: ULDs,
                  codeList: JSON.parse(localStorage.getItem('prod_list')),
                  codeListDisplay: JSON.parse(localStorage.getItem('prod_list')),
                }))
              }
            }
          }
        }
      }
    }
  }

  //CHECK FOR ULD TYPE
  checkULDType = (uld) => {
    let type = 'ULD'
    if(uld?.blk) {
      type = 'BLK'
    } else {
      if(uld?.info?.remark?.toUpperCase()?.includes('SLU')) {
        type = 'SLU'
      } else if(!uld?.info?.remark?.toUpperCase()?.includes('SLU') && uld?.uld_dispo?.toUpperCase() !== 'BREAKDOWN') {
        type = 'PTU'
      }
    }
    return type
  }
  //PROCESS ULD
  processIndividualULD = (uld) => {
    uld.timer = ''
    uld.time_sorter = ''
    let difference = ''
    uld.type = this.checkULDType(uld)
    uld.prod_code = this.getProdCode(uld?.awbs_in) || []
    if(uld?.flights_in?.arr?.gate) {
      this.pushGate(uld?.flights_in?.arr?.gate)
    }
    if(uld?.location) {
      if(uld.location?.toUpperCase() === 'UNKNOWN') {
        uld.location2 = 'CHECKED IN'
      } else {
        uld.location2 = format_gate(uld?.location)
      }
      this.pushLocation(uld.location2)
    }
    if(uld?.flights_in?.latest_arriv_date) {
      uld.time_sorter = uld?.flights_in?.latest_arriv_date
      difference = moment().diff(moment.utc(uld?.flights_in?.latest_arriv_date, 'YYYY-MM-DD HH:mm:ss').tz(this.state.timezone),'seconds')
      uld.timer = moment().add(difference, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    } else if (uld?.flights_in?.arriv_date) {
      uld.time_sorter = uld?.flights_in?.arriv_date
      difference = moment().diff(moment.utc(uld?.flights_in?.arriv_date, 'YYYY-MM-DD HH:mm:ss').tz(this.state.timezone),'seconds')
      uld.timer = moment().add(difference, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    }

    if(uld?.awbs_in?.length > 0) {
      uld.awbs_in.map((awb) => {
        this.processIndividualAWB(awb)
      })
    }
    return uld
  }
  //PROCESS AWB
  processIndividualAWB = (awb) => {
    awb.check_breakdown = false
    return awb
  }
  //RENDER FILTER SELECTION STRING
  renderFilterBlock = () => {
    if(this.state.wasSearched){
      return (
        <><br />
          <Row className='mt-1vw pl-2vw sel-flt-rw'>
            <Col><b> Search: </b><span>{this.state.searchValue}</span></Col> <></>
          </Row>
        </>
      )
    }
    return (
      <>
        <b> Type</b>: {this.state.workAreaType || 'ALL'}
        <b> Cargo</b>: {this.state.cargoType || 'ALL'}
        {this.state.locationType && <span><b> Location: </b>{this.state.locationType}</span>}
      </>
    )
  }

  //SORT
  sort = (array, type) => {
    if(type === 'timer') {
      array && array.sort(function(a, b) {
        // Compare the fields
        if (moment(a?.time_sorter) < moment(b?.time_sorter)) return -1
        if (moment(a?.time_sorter) > moment(b?.time_sorter)) return 1
        return 0
      })
    } else {
      array && array.sort(function(a, b) {
        // Compare the fields
        return ('' + a[type]).localeCompare(b[type])
      })
    }
  }
  //AWB and ULD DONE CHECK
  doneCheck = async (id, type) => {
    let url = type === 'ULD' ? '/recovery_uld/' : type === 'AWB' ? '/recovery_awb/' : ''
    await Http.get(`${url}${parseInt(id)}`)
      .then(async (data) => {
        if (data && data?.status === 200) {
          notification.destroy()
          notification.success({
            message: 'Success',
            description: data?.data?.message,
          })
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ servererror : true })
      })
  }
  //FETCH PRODUCT PRIORITY
  fetchProductPriority = async () => {
    let callback = this.fetchProductPriorityFn
    let pageURL =  `products_priority?station=${this.state.station}`
    let headers = {}
    cacheRetrieve(callback, pageURL, headers )
  }
  fetchProductPriorityFn = async(resp, event) => {
    const respData = await resp.json()
    if (resp && resp.status === 200) {
      if(respData && respData.data[0] && respData.data[0].prod_prior_list.length>0){
        this.setState({ prodPriorityData : respData.data[0].prod_prior_list })
      }
      if(!event) {
        this.fetchFilters(false)
      } else {
        this.processData()
      }
    } else {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: respData?.message,
      })
      this.setState({ loading: false })
    }
  }
  IsValidJSONString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  getProdCode = (awbs) => {
    if(awbs?.length > 0) {
      let arr = []
      awbs.map(item => {
        if(item.awb?.prod_code) {
          arr.push(item.awb?.prod_code)
          this.pushProdCode(item.awb?.prod_code)
        }
      })
      return arr
    } else {
      return []
    }
  }
  //PUSH PROD CODE FOR FILTER
  pushProdCode = (code) => {
    let prodCodeList = []
    if(localStorage.getItem('prod_list') && this.IsValidJSONString(localStorage.getItem('prod_list'))) {
      prodCodeList = JSON.parse(localStorage.getItem('prod_list'))
    }
    let index = prodCodeList?.findIndex((p) => p === code)
    if(index === -1) {
      prodCodeList.push(code)
      localStorage.setItem('prod_list', JSON.stringify(prodCodeList))
    }
  }
  //PUSH PROD CODE FOR FILTER
  pushGate = (gate) => {
    let gateList = []
    if(localStorage.getItem('gate_list') && this.IsValidJSONString(localStorage.getItem('gate_list'))) {
      gateList = JSON.parse(localStorage.getItem('gate_list'))
    }
    let index = gateList?.findIndex((p) => p.text === gate)
    if(index === -1) {
      gateList.push({
        text: gate,
        value: gate,
      })
      localStorage.setItem('gate_list', JSON.stringify(gateList))
    }
  }
  //PUSH LOCATION FOR FILTER
  pushLocation = (location) => {
    let workLocationList = []
    if(localStorage.getItem('location_list') && this.IsValidJSONString(localStorage.getItem('location_list'))) {
      workLocationList = JSON.parse(localStorage.getItem('location_list'))
    }
    let index = workLocationList?.findIndex((p) => p.text === location)
    if(index === -1) {
      workLocationList.push({
        text: location,
        value: location,
      })
      localStorage.setItem('location_list', JSON.stringify(workLocationList))
    }
  }

  handleOnSelectExclusion = (value) => {
    let exclusions = cloneDeep(this.state.exclusionArray)
    if(value && !exclusions?.includes(value)) {
      exclusions.push(value)
      this.setState({
        exclusionSearch: '',
        exclusionArray: exclusions,
      })

      if(exclusions?.length > 0) {
        this.setState({ codeListDisplay: this.state.codeList?.filter((item) => !exclusions?.includes(item)) })
      } else {
        this.setState({ codeListDisplay: this.state.codeList })
      }
    } else {
      this.setState({ exclusionSearch: '' })
    }
  }
  popExclusion = (value) => {
    let exclusions = cloneDeep(this.state.exclusionArray)
    let index = exclusions?.findIndex((p) => p === value)
    if(index > -1) {
      exclusions?.splice(index, 1)
      this.setState({ exclusionArray: exclusions })

      if(exclusions?.length > 0) {
        this.setState({ codeListDisplay: this.state.codeList?.filter((item) => !exclusions?.includes(item)) })
      } else {
        this.setState({ codeListDisplay: this.state.codeList })
      }
    }
  }
  //OTHER FILTERS
  otherFilters = (filtered_data) => {
    //CARGO, PROD, GATE, LOCATION, TYPE
    let filters = {}
    if(this.state.tempSelCargoList) {
      filters.typeEquals = item => item?.type === this.state.tempSelCargoList
    }
    if(this.state.tempSelProductList?.length > 0) {
      filters.productIncludes = item => this.state.tempSelProductList?.some(value => item?.prod_code?.includes(value))
      // filters.productIncludes = item => this.state.tempSelProductList?.includes(item?.prod_code)
    }
    if(this.state.tempSelgateType?.length > 0) {
      filters.gateIncludes = item => this.state.tempSelgateType?.includes(item?.flights_in?.arr?.gate)
    }
    if(this.state.tempSelLocationList) {
      filters.locationEquals = item => item?.location2 === this.state.tempSelLocationList
    }
    if(this.state.tempSelWorkAreaType) {
      filters.areaEquals = item => item?.uld_type === this.state.tempSelWorkAreaType
    }
    return filtered_data?.filter(item => Object.values(filters)?.every(f => f(item)))
  }
  //SAVE USER FILTERS
  saveFilters = async (isAPICall, fromFilter) => {
    let tdata = cloneDeep(this.state.data)
    this.setState({ data: [] },()=> this.setState({ data: tdata }))
    let exclusions = this.state.exclusionArray?.toString()

    if(exclusions && !exclusions?.match(CHARNUM3COMMA)) {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: CHARNUM3COMMAerr,
      })
    } else {
      let recoverydash_filter = cloneDeep(this.state.recoveryFilter)
      recoverydash_filter[this.state.station] = {
        type: this.state.workAreaType,
        cargo: this.state.cargoType,
        product: this.state.prodType,
        gateType: this.state.gateType,
        location: this.state.locationType,
        exclusion: this.state.exclusionArray,
        filterChecked: this.state.filterChecked, 
        filterValue: this.state.filterValue,
        switch: this.state.switch,
      }
      let formdata = { recoverydash_filter: recoverydash_filter }
      await Http.put(`/page_filter/${this.state.user_id}`, formdata).then(async (data) => {
        if (data && data?.status === 200) {
          this.fetchFilters(!isAPICall, fromFilter)
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      }).catch(() => {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: constants.SERVER_CONNECTION_ERROR,
        })
        this.setState({ servererror : true })
      })
    }
  }
  //FETCH USER FILTERS
  fetchFilters = async (isAPICall, fromFilter) => {
    await Http.get(`/page_filter/${this.state.user_id}`).then(async (data) => {
      if (data && data?.status === 200) {
        let res_data = data?.data?.data?.page_filters?.recoverydash_filter && data?.data?.data?.page_filters?.recoverydash_filter[this.state.station]
        if(res_data) {
          this.setState({
            recoveryFilter: data?.data?.data?.page_filters?.recoverydash_filter,
            workArea: res_data?.workarea || this.state.tempWorkArea,
            workAreaType: res_data?.type || this.state.tempWorkAreaType,
            cargoType: res_data?.cargo ? res_data?.cargo : res_data?.cargo === '' ? '' : this.state.cargoType,
            prodType: res_data?.product || this.state.prodType,
            gateType: res_data?.gateType || this.state.gateType,
            locationType: res_data?.location ? res_data?.location : res_data?.location === '' ? '' : this.state.locationType,
            exclusionArray: res_data?.exclusion || this.state.exclusionArray,
            exclusionList: res_data?.exclusion || this.state.exclusionList,
            filterChecked: res_data?.filterChecked || this.state.filterChecked,
            filterValue: res_data?.filterValue || this.state.filterValue,
            switch: res_data?.switch || this.state.switch,

            tempSelCargoList: res_data?.cargo ? res_data?.cargo : res_data?.cargo === '' ? '' : this.state.tempSelCargoList,
            tempSelProductList: res_data?.product || this.state.tempSelProductList,
            tempSelgateType: res_data?.gateType || this.state.gateType,
            tempSelLocationList: res_data?.location ? res_data?.location : res_data?.location === '' ? '' : this.state.tempSelLocationList,
            tempSelExclusionList: res_data?.exclusion || this.state.tempSelExclusionList,
            tempSelfilterChecked: res_data?.filterChecked || this.state.tempSelfilterChecked,
            tempSelfilterValue: res_data?.filterValue || this.state.tempSelfilterValue,
            tempSwitch: res_data?.switch || this.state.tempSwitch,
            // tempSelWorkArea: res_data?.workarea || this.state.tempSelWorkArea,
            tempSelWorkAreaType: res_data?.type || this.state.tempSelWorkAreaType,
          }, () => {
            if(data?.data?.data?.page_filters?.recoverydash_filter?.exclusion?.length > 0) {
              this.setState({ codeListDisplay: this.state.codeList?.filter((item) => !data?.data?.data?.page_filters?.recoverydash_filter?.exclusion?.includes(item)) })
            } else {
              this.setState({ codeListDisplay: this.state.codeList })
            }
          })
        } else {
          this.setState({
            recoveryFilter: data?.data?.data?.page_filters?.recoverydash_filter || {},
            workArea: this.state.tempWorkArea,
            workAreaType: this.state.tempWorkAreaType,
          })
        }
        if(!isAPICall) {
          //this.fetchDashboardData(null, fromFilter)
          this.fetchULDIDS(null, fromFilter)
        }
      } else {
        notification.destroy()
        notification.error({
          message: 'Failed',
          description: data?.data?.message,
        })
      }
    }).catch(() => {
      notification.destroy()
      notification.error({
        message: 'Failed',
        description: constants.SERVER_CONNECTION_ERROR,
      })
      this.setState({ servererror : true })
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true,
      cargoType: this.state.tempSelCargoList,
      prodType: cloneDeep(this.state.tempSelProductList),
      gateType: cloneDeep(this.state.tempSelgateType),
      locationType: this.state.tempSelLocationList,
      exclusionArray: this.state.tempSelExclusionList,
      // workArea: this.state.tempSelWorkArea,
      workAreaType: this.state.tempSelWorkAreaType,
      filterChecked: this.state.tempSelfilterChecked,
      filterValue: this.state.tempSelfilterValue,
      switch: this.state.tempSwitch,
    })
  }
  onClose = () => {
    this.setState({
      visible: false,
      cargoType: this.state.tempSelCargoList,
      prodType: cloneDeep(this.state.tempSelProductList),
      gateType: cloneDeep(this.state.tempSelgateType),
      locationType: this.state.tempSelLocationList,
      exclusionArray: this.state.tempSelExclusionList,
      // workArea: this.state.tempSelWorkArea,
      workAreaType: this.state.tempSelWorkAreaType,
      filterChecked: this.state.tempSelfilterChecked,
      filterValue: this.state.tempSelfilterValue,
      switch: this.state.tempSwitch,
    })
  }
  isOtherFilters = () => {
    return this.state.tempSelCargoList || this.state.tempSelProductList?.length > 0 || this.state.tempSelgateType?.length > 0 || this.state.tempSelLocationList || this.state.tempSelWorkAreaType
  }
  clearFilters = () => {
    let tsw = cloneDeep(this.state.tempSwitch)
    let tfv = cloneDeep(this.state.tempSelfilterValue)

    this.setState({
      cargoType: '',
      prodType: [],
      gateType: [],
      locationType: '',
      exclusionArray: [],
      exclusionList: [],
      tempSelCargoList: '',
      tempSelProductList: [],
      tempSelgateType: [],
      tempSelLocationList: '',
      tempSelWorkAreaType: '',
      tempSelExclusionList: [],
      tempSelfilterChecked: false,
      filterChecked: false,
      filterValue: '',
      tempSelfilterValue: '',
      switch: false,
      tempSwitch: false,
    }, () => {
      if(tsw || tfv) {
        this.saveFilters(true, true)
      } else {
        this.saveFilters(false, false)
      }
    })
  }
  //EXCLUDE PRODUCT CODE FILTER
  exclusionFilter = (action, ULDs) => {
    // let ULDs = cloneDeep(this.state.displayData)
    let exclusions = this.state.tempSelExclusionList
    let arr = []
    if(action) {
      this.setState({
        visible: false,
        tempSelfilterChecked: this.state.filterChecked,
        tempSelLocationList: this.state.locationType,
        tempSelCargoList: this.state.cargoType,
        tempSelProductList: this.state.prodType,
        tempSelgateType: this.state.gateType,
        tempSelWorkAreaType: this.state.workAreaType,
      }, () => {
        if(this.state.tempSwitch !== this.state.switch || this.state.tempSelfilterValue !== this.state.filterValue) {
          clearInterval(this.state.fetchInterval)
          this.setState({
            stopFlag: true,
            tempSelfilterValue: this.state.filterValue,
            tempSwitch: this.state.switch,
            loading: true,
            data: [],
            updatedULDs: [],
            expanded: [],
          }, () => this.saveFilters(true, true))
        } else {
          this.saveFilters(false, false)
        }
      })
    } else {
      arr = ULDs.filter(function(el) {
        return !exclusions?.some(value => el?.prod_code?.includes(value))
        // return !exclusions?.includes(el?.prod_code)
      })
      return arr
    }
  }
  //REDIRECT LOGIC
  handleRedirect = () => {
    if(this.state.role?.toUpperCase() === 'WAREHOUSE TV') {
      logout()
    } else {
      pageHandler('', this.state.role)
    }
  }

  renderData = (data) => {
    let d = []
    if(this.state.tempSelfilterValue) {
      let filtered_data = []
      if(this.state.switch === true) {
        filtered_data = data?.filter((item) => moment(item.timer).diff(moment(), 'minutes') <= this.state.tempSelfilterValue)
      } else if(this.state.switch === false) {
        filtered_data = data?.filter((item) => moment(item.timer).diff(moment(), 'minutes') >= this.state.tempSelfilterValue)
      }
      if(filtered_data?.length > 0) {
        d = this.exclusionFilter(false, this.otherFilters(filtered_data))
      } else {
        d = this.exclusionFilter(false, filtered_data)
      }
    } else if(this.isOtherFilters()) {
      d = this.exclusionFilter(false, this.otherFilters(data)) 
    } else {
      d = this.exclusionFilter(false, data)
    }
    if(this.state.wasSearched){
      d = data
    }
    this.sort(d, 'uld_no')
    this.sort(d, 'timer')
    return d
  }

  renderCounter = (uld) => {
    let difference = ''
    if(uld?.flights_in?.latest_arriv_date) {
      difference = moment().diff(moment.utc(uld?.flights_in?.latest_arriv_date, 'YYYY-MM-DD HH:mm:ss').tz(this.state.timezone),'seconds')
      return moment().add(difference, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    } else if (uld?.flights_in?.arriv_date) {
      difference = moment().diff(moment.utc(uld?.flights_in?.arriv_date, 'YYYY-MM-DD HH:mm:ss').tz(this.state.timezone),'seconds')
      return moment().add(difference, 'seconds').format('YYYY-MM-DD HH:mm:ss')
    }
  }

  renderCodeList = (code_list) => {
    let exclusions = this.state.exclusionArray
    let codeList = []
    if(exclusions?.length > 0) {
      codeList = code_list?.filter((item) => !exclusions?.includes(item))
    } else {
      codeList = code_list
    }
    return (
      <>
        <Option className='blue' key={'RESET'} value={'RESET'}>{'RESET'}</Option>
        {codeList?.map((item) => (
          <Option key={item} value={item}>{item}</Option>
        ))}
      </>
    )
  }

  // AWB SEARCH
  setSearchValue (e){
    this.setState({ searchValue: e.target.value },() =>{
      if(this.state.wasSearched && !this.state.searchValue){
        this.setState({ wasSearched: false })
        // this.setState({ buildup_list: [] },()=> this.fetchDashboardData(null, false, false))
        this.fetchULDIDS(null, false)
      }
    })
  }
  onSearch = (e ) => {
    let old_searchValue = cloneDeep(this.state.searchValue)
    let fetch_flag = false
    if(old_searchValue.trim() !== e.trim())
      fetch_flag = true
    this.setState({ data: [] })
    if(!e){
      this.setState({
        data: fetch_flag? [] : this.state.data ,
        searchSelect: !this.state.searchSelect,
        searchValue: e,
        wasSearched: false
      },()=>{
        if(fetch_flag)
          //this.fetchDashboardData(null, false, false)
          this.fetchULDIDS(null, false)
      })
    }

    if(e){
      //hallout_list
      this.fetchDashboardData(null, false, true)
    }
  }

  showConfirm = (awb) => {
    this.setState((prev) => ({
      checkBreakdown: {
        ...prev.checkBreakdown,
        [awb.id]: true
      }
    }))
  }

  changeConfirm = ({ id }) => {
    this.doneCheck(id, 'ULD')
  }
  render() {

    const uld_columns = [
      {
        title: 'Flight #',
        dataIndex: 'flight_no',
        key: 'flight_no',
        align:'left',
        className: 'pl-1vw w-10vw',
        render: (_, record) => (
          <span>{record?.flights_in?.flight_no ? record?.flights_in?.flight_no : 'N/A'}</span>
        )
      },
      {
        title: 'ULD/AWB',
        dataIndex: 'uld_no',
        key: 'uld_no',
        className: 'w-22vw',
        render: (_, record) => (
          <span>{record?.type === 'ULD' ? record?.uld_no : record?.type +' - '+ record.uld_no}</span>
        )
      },
      {
        title: 'Prod',
        dataIndex: '',
        key: 'prod_code',
        render: (_, record) => (
          <span>{record?.type === 'BLK' ? record?.prod_code || 'N/A' : ''}</span>
        )
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        width: 270,
        render: (_, record) => (
          <span>{record?.location2 ? record?.location2 : record?.flights_in?.flight_no ? record?.flights_in?.flight_no : 'N/A'}</span>
        )
      },
      {
        title: 'Arrival time',
        dataIndex: 'arrival_time',
        key: 'arrival_time',
        width: 270,
        render: (_, record) => (
          <span>{record?.flights_in?.latest_arriv_date ?
            moment.utc(record?.flights_in?.latest_arriv_date).tz(this.state.timezone).format('DDMMMYY HH:mm') :
            record?.flights_in?.arriv_date ? moment.utc(record?.flights_in?.arriv_date).tz(this.state.timezone).format('DDMMMYY HH:mm') : 'N/A'}
          </span>
        )
      },
      {
        title: 'Gate #',
        dataIndex: 'gate_no',
        key: 'gate_no',
        render: (_, record) => (
          <span>{record?.flights_in?.arr?.gate ? record?.flights_in?.arr?.gate : 'N/A'}</span>
        )
      },
      {
        title: 'Timer',
        key: 'timer',
        render: (_, record) => (
          <span>{this.renderCounter(record) ? <CountUp expiryTimestamp={moment(this.renderCounter(record))} className='' /> : 'N/A'}</span>
        )
      },
      {
        title: 'Done',
        dataIndex: 'done',
        key: 'done',
        className: 'pr-1vw no-print',
        render: (_, record) => {
          let isDone = this.state.checkBreakdown[record.id]
          return (
            <><Button onClick={isDone ? () => this.changeConfirm(record) : () => this.showConfirm(record)} type='primary' className={`${'warehouse-done'} ${isDone ? 'warehouse-confirm' : ''}`}>{isDone ? 'Confirm' : 'Done'}</Button></>          )
        }
      }
    ]

    const awb_columns = [
      {
        title: '',
        dataIndex: '',
        key: '',
        align:'',
        className: 'w-10vw',
      },
      {
        title: '',
        dataIndex: 'awb_no',
        key: 'awb_no',
        className: 'w-22vw',
        render: (_, record) => (
          <span>{record?.awb_no ? formatAWBNo(record.awb_no) : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: 'product',
        key: 'product',
        width: '10%',
        render: (_, record) => (
          <span>{record?.awb?.prod_code ? record?.awb?.prod_code : 'N/A'}</span>
        )
      },
      {
        title: '',
        dataIndex: '',
        key: '',
        width: '14%',
      },
      {
        title: '',
        dataIndex: 'arrival_time',
        key: 'arrival_time',
        width: '16%',
      },
      {
        title: '',
        dataIndex: 'gate_no',
        key: 'gate_no',
        width: '10%',
      },
      {
        title: '',
        dataIndex: 'timer',
        key: 'timer',
        width: '14%',
      },
      {
        title: '',
        dataIndex: 'done',
        key: 'done',
        // render: (_, record) => (
        //   <Popconfirm
        //     placement='topLeft'
        //     title='Are you sure to mark the AWB as done?'
        //     okText='Yes'
        //     cancelText='No'
        //     onConfirm={() => this.doneCheck(record?.id, 'AWB')}
        //   >
        //     <Tooltip title='Mark as done' overlayInnerStyle={{ fontSize: cssconstants.Tooltipfs }}>
        //       <Checkbox checked={record?.check_breakdown} />
        //     </Tooltip>
        //   </Popconfirm>
        // )
      }
    ]

    return (
      <div className='warehouse-screen recovery-dashboard'>
        <div className='text-right f-spacebtw'>
          <div className='no-print'>
            {this.state.role?.toUpperCase() === 'WAREHOUSE TV' ?
              <div className='d-flex'>
                {
                  this.state.logintype && this.state.logintype === 'DashTV' ? null :
                    <div className='mr-1vw'>
                      <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<ArrowLeftOutlined />} size='large' onClick={()=>window.location.href = '/landing-page'} />
                      <p className='btn-text'>Back</p>
                    </div>
                }
                <div>
                  <Button className='custom-icon-button warehouse-filter rotate-180' type='primary' shape='circle' icon={<LogoutOutlined />} size='large' onClick={this.handleRedirect} />
                  <p className='btn-text'>Signout</p>
                </div>
              </div>
              :
              <div>
                <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<HomeOutlined />} size='large' onClick={this.handleRedirect} />
                <p className='btn-text'>Home</p>
              </div>
            }
          </div>
          <h1 className='dash-title mr-0 dash-loc'>{this.state.station}</h1>
        </div>
        <div className='clock-container'>
          <Text className='clock-text'>
            {this.state.clock}
          </Text>
        </div>
        <h1 className='text-center'><span className='dash-title'>Recovery Dashboard</span></h1>
        <Row
          gutter={[
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
            {
              xs: 0,
              sm: 0,
              md: 0,
              lg: 0,
            },
          ]}
        >
          <div className='w-100 f-spacebtw mb-1p5'>
            <div className='d-flex'>
              <div className='selected-filter mb-0 '>Selected Filters:
                {this.renderFilterBlock()}
              </div>
            </div>

            <div span={2} className='row-1 pr-0 ml-auto no-print'>
              <Space direction='horizontal' className='w-action-btn'>
                <Input.Search
                  placeholder='Search AWB'
                  onSearch={this.onSearch}
                  onChange={(e) => this.setSearchValue(e)}
                  allowClear
                  className={'warehouse-search' + (this.state.searchSelect? ' search-w100' : ' search-w0')}
                />
                <Button className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<PrinterOutlined />} size='large' onClick={()=>window.print()} />
                <Button disabled={this.state.wasSearched} className='custom-icon-button warehouse-filter' type='primary' shape='circle' icon={<FilterOutlined />} size='large' onClick={this.showDrawer} />
              </Space>
            </div>
          </div>
          <div className='w-100'>{this.state.exclusionList?.length > 0 && <p className='selected-filter'><b> Product Exclusion list</b>: {this.state.exclusionList?.toString()}</p>}</div>
          <div className='w-100'>{this.state.tempSelProductList?.length > 0 && <p className='selected-filter'><b> Product Codes list</b>: {this.state.tempSelProductList?.toString()}</p>}</div>
          <div className='w-100'>{this.state.tempSelgateType?.length > 0 && <p className='selected-filter'><b> Gate list</b>: {this.state.tempSelgateType?.toString()}</p>}</div>
          <Drawer
            title=''
            placement='top'
            width={500}
            closable={false}
            open={this.state.visible}
            className='warehouse-drawer'
          >
            <Row>
              <Button className='close-btn ml-auto' onClick={this.onClose} size='large' type='link'>X</Button>
            </Row>
            <Row
              gutter={[
                {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: 0,
                },
                {
                  xs: 0,
                  sm: 0,
                  md: 0,
                  lg: 0,
                },
              ]}
              className='warehouse-screen'
            >
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Type'>
                  <Select
                    size='small'
                    value={this.state.workAreaType}
                    placeholder='Select type'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ workAreaType: e })}>
                    <Option value='Transit'>Transit</Option>
                    <Option value='Local'>Local</Option>
                    <Option value=''>All</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Cargo'>
                  <Select
                    size='small'
                    value={this.state.cargoType}
                    placeholder='Select cargo'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ cargoType: e })}
                  >
                    <Option value=''>All</Option>
                    <Option value='BLK'>BLK</Option>
                    <Option value='SLU'>SLU</Option>
                    <Option value='PTU'>PTU</Option>
                    <Option value='ULD'>ULD</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Product'>
                  <Select
                    mode='multiple'
                    size='small'
                    value={this.state.prodType || []}
                    placeholder='Select product'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ prodType: e?.includes('RESET') ? [] : e })}
                  >
                    {this.renderCodeList(this.state.codeListDisplay)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Location' className='loc-search'>
                  <Select
                    allowClear
                    showSearch
                    size='small'
                    value={this.state.locationType || []}
                    placeholder='Select location'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ locationType: e && e !== undefined ? e : '' })}
                  >
                    {this.state.locationList?.map((item) => (
                      <Option key={item?.value} value={item?.value}>{item?.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Gate'>
                  <Select
                    mode='multiple'
                    size='small'
                    value={this.state.gateType || []}
                    placeholder='Select gate'
                    dropdownClassName='warehouse-select'
                    onChange={(e) => this.setState({ gateType: e?.includes('RESET') ? [] : e })}
                  >
                    <Option className='blue' key={'RESET'} value={'RESET'}>{'RESET'}</Option>
                    {this.state.gateListDisplay?.map((item) => (
                      <Option key={item?.value} value={item?.value}>{item?.value}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6} className='row-1'>
                <Form.Item label='Exclusion List'>
                  <Select
                    placeholder='Select product'
                    dropdownClassName='warehouse-select'
                    mode='tags'
                    size='small'
                    onSearch={(e) => this.setState({ exclusionSearch: e?.toUpperCase() })}
                    onSelect={(e) => this.handleOnSelectExclusion(e)}
                    onChange={() => this.handleOnSelectExclusion('')}
                    onDeselect={(e) => this.popExclusion(e)}
                    tokenSeparators={[',',' ','\t']}
                    value={this.state.exclusionArray}
                    searchValue={this.state.exclusionSearch}
                  >
                    {
                      this.state.prodPriorityData?.map((item) => (
                        <Option key={item?.p_code} value={item?.p_code}>{item?.p_code}</Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={11} xl={11} className='space-nowrap ml-neg8'>
                <div className='tv-res'>
                  <label className='transparent'>-</label>
                  <Form.Item>
                    <Checkbox
                      onChange={(e) => this.setState({
                        filterChecked: e.target.checked,
                        filterValue: e.target.checked === false ? '' : this.state.filterValue,
                      })}
                      checked={this.state.filterChecked}
                    >
                      Show ULDs with
                      <InputNumber
                        className='small-input pl-2 uld-time'
                        disabled={!this.state.filterChecked}
                        onChange={(e) => this.setState({ filterValue: e })}
                        controls={false}
                        min={0}
                        value={this.state.filterValue}
                      />
                      <span className='pl-1'> mins or</span>
                      <Switch
                        className='uld-switch warehouse-switch ml-2vw'
                        style={{ backgroundColor: '#0a1227' }}
                        disabled={!this.state.filterChecked}
                        onChange={(e) => this.setState({ switch: e })}
                        checked={this.state.switch}
                        unCheckedChildren='more'
                        checkedChildren='less'
                        size='default'
                      />
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
              <Col className='ml-auto'>
                <Space direction='horizontal'>
                  <Button type='primary' className='custom-button warehouse-button mr-2vw' onClick={this.clearFilters}>Clear filters</Button>
                  <Button className='custom-button warehouse-button' onClick={() => this.exclusionFilter(true, this.state.data)} type='primary'>Apply filters</Button>
                </Space>
              </Col>
            </Row>
          </Drawer>
          {/* <div className='clock-container'>
            <Text className='clock-text'>
              {this.state.clock}
            </Text>
          </div>            */}
          <Col xs={24} sm={24} md={24} lg={24} className=''>
            {
              <Spin spinning={this.state.loading}>
                {this.state.data?.length === 0 && <Table
                  className='components-table-demo-nested warehouse-table recovery-table nth-td-p-0'
                  columns={uld_columns}
                  expandable={{
                    expandedRowRender: record => <Table 
                      className='warehouse-table nested-table' 
                      columns={awb_columns} 
                      dataSource={record?.awbs_in} 
                      pagination={false} 
                      onRow={() => {
                        return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}` }, }
                      }}
                      onHeaderRow={() => {
                        return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}` }, }
                      }}
                    />,
                    defaultExpandAllRows: true,
                    rowExpandable: (record) => record?.type !== 'BLK',
                  }}
                  dataSource={this.state.data}
                  pagination={false}
                  // rowKey='id'
                  onRow={() => {
                    return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}` }, }
                  }}
                  onHeaderRow={() => {
                    return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}`, }, }
                  }}
                />}
                {this.state.data?.length > 0 && <Table
                  className='components-table-demo-nested warehouse-table recovery-table nth-td-p-0'
                  columns={uld_columns}
                  expandable={{
                    expandedRowRender: record => 
                      <Table 
                        className='warehouse-table nested-table' 
                        columns={awb_columns} 
                        dataSource={record?.awbs_in} 
                        pagination={false}
                        onRow={() => {
                          return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}` }, }
                        }}
                        onHeaderRow={() => {
                          return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}`, }, }
                        }}
                      />,
                    defaultExpandAllRows: true,
                    rowExpandable: (record) => record?.type !== 'BLK',
                    expandedRowKeys: this.state.expanded,
                    onExpandedRowsChange: (rows) => this.setState({ expanded: [] }, () => this.setState({ expanded: rows }))
                  }}
                  dataSource={this.renderData(this.state.data)}
                  pagination={false}
                  rowKey='id'
                  onRow={() => {
                    return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}` }, }
                  }}
                  onHeaderRow={() => {
                    return { style: { fontSize: `${this.state.tableBodyFontSize === null || this.state.tableBodyFontSize === 'null' ? this.state.defaultFontSize : `${this.state.tableBodyFontSize}vw`}`, }, }
                  }}
                />}
              </Spin>
            }
          </Col>
        </Row>
      </div>
    )
  }
}

export default RecoveryDashboard
