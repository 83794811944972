import React, { Component }  from 'react'
import { notification, Spin, Typography, Row, Col } from 'antd'
import Http from '../../utils/http'
import {
  COOKIE,
  createCookie,
} from '../../utils/cookie'
import moment from 'moment'
import Logo from '../../assets/images/logo.png'
import '../login/login.css'
import { timezones } from '../../constants/constants'

const { Title } = Typography
const LoginFailed = 'User Credentials do not match. Please contact HCS admin'
const RedirectingMsg = 'Redirecting to Delta single signon login page'
const LoggingIn = 'Logging In'

// const [searchParams] = useSearchParams()
class DeltaLogin extends Component {

  constructor() {
    super()
    this.state = { iscode : false }
  }

  componentDidMount() {
    this.login_redirect_check()
  }
  login_redirect_check = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')

    if (code){
      this.setState({ iscode : LoggingIn })
      let formdata = { code: code }
      Http.post('/get_user_token', formdata).then((data) => {
        if (data && data?.status === 200) {
          let ttl = ''
          if(data?.data?.data?.exp) {
            let exp = moment(data?.data?.data?.exp*1000).utc().format('YYYY-MM-DD HH:mm').toUpperCase()
            let curr = moment().utc().format('YYYY-MM-DD HH:mm').toUpperCase()
            ttl = Math.round(parseInt(moment(exp, 'YYYY-MM-DD HH:mm').diff(moment(curr,'YYYY-MM-DD HH:mm'), 'minutes')) / 60)
            ttl = 24
            if(data?.data?.data?.user?.role === 'Warehouse TV'){
              ttl = 24 * 6
            }
            if(data?.data?.data?.user) {
              let station = data?.data?.data?.user?.station ? data?.data?.data?.user?.station : 'ATL'
              createCookie(COOKIE.OTP, true, ttl,'/')
              createCookie(COOKIE.STATION, station, ttl)
              createCookie(COOKIE.TIMEZONE, timezones[station], ttl)
              createCookie(COOKIE.NAME, `${data?.data?.data?.user?.name?.first_name} ${data?.data?.data?.user?.name?.last_name}` , ttl,'/')
              createCookie(COOKIE.ROLE, data?.data?.data?.user?.role, ttl,'/')
              createCookie(COOKIE.JOB_TITLE, data?.data?.data?.user?.job_title, ttl,'/')
              createCookie(COOKIE.USERNAME, data?.data?.data?.user?.username, ttl,'/')
              createCookie(COOKIE.ID, data?.data?.data?.user?.id, ttl,'/')
              createCookie(COOKIE.PPR, data?.data?.data?.user?.ppr, ttl,'/')
              createCookie(COOKIE.WEIGHT, 'LB', ttl,'/')

              notification.destroy()
              notification.success({
                message: 'Success',
                description: data?.data?.message,
              })

              this.props.history.push('/system-parameter/fleet')
            }
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: 'Something went wrong, please try again later.',
            })
          }
        } else {
          this.setState({ iscode : LoginFailed })
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })

    } else {
      this.setState({ iscode : RedirectingMsg })
      Http.post('/get_user_token', {}).then((data) => {
        if (data && data?.status === 200) {
          if(data?.data?.data?.url) {
            notification.destroy()
            notification.success({
              message: 'Success',
              description: data?.data?.message,
            })

            window.location.href = data?.data?.data?.url
          } else {
            notification.destroy()
            notification.error({
              message: 'Failed',
              description: data?.data?.message,
            })
          }
        } else {
          notification.destroy()
          notification.error({
            message: 'Failed',
            description: data?.data?.message,
          })
        }
      })
    }
  }

  render() {
    return (
      <div>
        <div className='login-container-outer text-center m-auto delta-login'>
          <Row className='login-container'>
            <Col md={24} lg={12} className='login-content'>
              <Title level={2} className='text-left login-heading'>Welcome</Title>
              <Title level={2} className='login-heading delta-to mt-0'>To</Title>
              <div>
                <div>
                  <Title level={1} className='login-company-title text-center'>HUB CONTROL SYSTEM</Title>
                  <Title level={5} className='login-company-subtitle text-right'>CARGO</Title>
                </div>
                <div className='divider my-4 w-100'></div>
                <Row className='w-100 fv-middle logo-wrap-gap'>
                  <Col flex='100px'>
                    <img src={Logo} alt='Logo' className='login-logo' />
                  </Col>
                  <Col className='login-form-width'>
                    <Title level={4} className='text-left redirecting-subheading mb-0'><Spin />{this.state.iscode}</Title>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col flex='auto'lg={12}className='f-middle bck-img'>
            </Col>
          </Row>
        </div>
      </div>
    )
  }


}

export default DeltaLogin