import React, { useState, useEffect } from 'react'
import { Fleet, RecoveryAction, RebookingTeamSla, DynamicInfo, ProductWorkAreaMapping, ProductBuildUpPriority, HandlingTimes, ULDMapping, HauloutAreaMapping, DoDMilestones } from './tabs'
import './index.css'
import BuildUpAreaMapping from '../../pages/system-parameter/tabs/buildupareamapping'
import Grouping from '../../pages/system-parameter/tabs/group'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { systemParameterTabs } from '../../constants/constants'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import DoDMilestones from '../../../^untitled:Untitled-3'

const SystemParameter = ({ match, history }) => {

  const [tab, setTab] = useState(false)

  useEffect(() => {
    if (match?.params.tab) {
      if (systemParameterTabs[match.params.tab]) {
        setTab(systemParameterTabs[match.params.tab])
      } else {
        setTab(systemParameterTabs.fleet)
      }
    } else {
      setTab(systemParameterTabs.fleet)
    }
  }, [])


  const tabHnadler = (t) => {
    setTab(t)
    history.push(`/system-parameter/${t}`)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    // slidesToShow: 7,
    slidesToShow: 9,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  return (
    <div>
      <Slider {...settings} className='custom-slider sys-para-slider mb-24px'>
        <div className={`tabs ${tab == systemParameterTabs.fleet? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.fleet)}>
          <span>Fleet [G]</span>
        </div>
        <div className={`tabs ${tab == systemParameterTabs.product_buildup_priority? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.product_buildup_priority)}>
          <span>Product Build Up Priority [G]</span>
        </div>
        <div className={`tabs ${tab == systemParameterTabs.uld_mapping? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.uld_mapping)}>
          <span>ULD Mapping [G]</span>
        </div>
        <div className={`tabs ${tab == systemParameterTabs.dod_milestones? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.dod_milestones)}> 
          <span>DoD Milestones [G]</span>
        </div>
        <div className={`tabs ${tab == systemParameterTabs.rebooking_teams_sla? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.rebooking_teams_sla)}>
          <span>Rebooking Teams & SLA</span>
        </div>
        <div className={`tabs ${tab == systemParameterTabs.recovery_action? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.recovery_action)}>
          <span>Recovery Actions</span>
        </div>
        <div className={`tabs ${tab == systemParameterTabs.dynamic_info? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.dynamic_info)}>
          <span>Dynamic Info</span>
        </div>
        <div className={`tabs ${tab == systemParameterTabs.breakdown_work_area_mapping? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.breakdown_work_area_mapping)}>
          <span>Breakdown Work Area Mapping</span>
        </div>
        <div className={`tabs ${tab == systemParameterTabs.handling_times? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.handling_times)}>
          <span>Handling Times</span>
        </div>
        {/* <div className={`tabs ${tab == systemParameterTabs.grouping? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.grouping)}>
          <span>Grouping</span>
        </div> */}
        <div className={`tabs ${tab == systemParameterTabs.buildup_area_mapping? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.buildup_area_mapping)}>
          <span>Build Up Area Mapping</span>
        </div>
        <div className={`tabs ${tab == systemParameterTabs.haulout_area_mapping? 'active-tab' : ''}`} onClick={() => tabHnadler(systemParameterTabs.haulout_area_mapping)}>
          <span>Haulout Area Mapping</span>
        </div>
      </Slider>

      {tab == systemParameterTabs.fleet &&
        <Fleet />
      }

      {tab == systemParameterTabs.rebooking_teams_sla &&
        <RebookingTeamSla />
      }

      {tab == systemParameterTabs.recovery_action &&
        <RecoveryAction />
      }

      {tab == systemParameterTabs.dynamic_info &&
        <DynamicInfo />
      }

      {tab == systemParameterTabs.breakdown_work_area_mapping &&
        <ProductWorkAreaMapping />
      }

      {tab == systemParameterTabs.product_buildup_priority &&
        <ProductBuildUpPriority />
      }

      {tab == systemParameterTabs.handling_times &&
        <HandlingTimes />
      }

      {tab == systemParameterTabs.uld_mapping &&
        <ULDMapping />
      }
      {tab == systemParameterTabs.grouping &&
        <Grouping />
      }
      {tab == systemParameterTabs.buildup_area_mapping &&
        <BuildUpAreaMapping />
      }
      {tab == systemParameterTabs.haulout_area_mapping &&
        <HauloutAreaMapping />
      }
      {tab == systemParameterTabs.dod_milestones &&
        <DoDMilestones />
      }
    </div>
  )
}

SystemParameter.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
}

export default SystemParameter